import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  Grid,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { Autocomplete } from '@material-ui/lab';
import {
  ValuationExtraOption,
  ValuationRequest,
  ValuationVehicle,
  ValuationVehicleOptions,
  ValuationVehicles
} from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import MUIDataTable, { MUIDataTableColumnOptions, SelectableRows } from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { Label } from 'reactstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import NumberFormat from 'react-number-format';
import * as R from 'ramda';
import { IReferential } from '../../interfaces/IReferential';
import ValuationFiltersWizardContainer from '../cases/ValuationFiltersWizard/ValuationFiltersWizardContainer';
import { isNullOrUndefined } from 'util';
import { Utils } from '../../helpers/Utils';
import ImageNotAvailable from '../../assets/images/car-image-not-available.jpg';
import moment from 'moment';
import Loader from '../Loader';

type ParsedConditions = {
  mandatory: number[];
  atLeastOne: number[];
};

interface ICaseDetailsValuationState {
  vehicles: ValuationVehicle[];
  isLoading: boolean;
  viewOptions: boolean;
  selectedVehicle: ValuationVehicle | null;
  options: ValuationVehicleOptions | null;
  optionCodes: string[];
  optionsValue: number;
  selectedOptionIndexes: number[];
  excludedOptionsIndexes: number[];
  includedOptionsIndexes: number[];
  requiredOptionsIndexes: number[];
  showOtionalRequiredOptions: boolean;
  optinalRequiredOptionsIndexes: number[];
  isloadingIncludeExcludeOptions: boolean;
  tireWear: number;
  bodyDamage: number;
  hasGpl: boolean;
  valuationLoading: boolean;
  showWizard: boolean;
  hideVehicleSelection: boolean;
  kmNo: number;
  color: string | null;
  showCarTable: boolean;
  hideValuationWizzard: boolean;
}

export interface ExternalCaseDetailsValuationProps {
  vin: string | null;
  firstRegistrationDate: Date | null;
  onSelectedVehicle?: (selectedVehicle: any, options: string[]) => void;
  valuationVehicles?: ValuationVehicle[];
  optionCodes?: string[] | null;
  brandTypes?: IReferential[];
  modelTypes?: IReferential[];
  bodyTypes?: IReferential[];
  fuelTypes?: IReferential[];
  tractionTypes?: IReferential[];
  transmissionTypes?: IReferential[];
  colorTypes?: IReferential[];
  onChangeFirstRegistrationDate?: (date: Date | null) => void;
}

export const standardOptionsSchemaIds = [
  301, 2501, 3501, 3601, 23501, 23601, 23701, 23801, 23901, 24001, 31001, 32601, 34801, 38001,
  38701, 38801, 42101, 42901, 44501, 48401, 49001, 50001, 55601, 57901, 58201, 58301, 58401, 58501,
  58601, 59201, 59301, 59801, 63601, 63901
];

type ICCaseDetailsValuationProps = ExternalCaseDetailsValuationProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string; calcId: string | undefined; formId: string | undefined }>;

class DetailsValuation extends React.PureComponent<
  ICCaseDetailsValuationProps,
  ICaseDetailsValuationState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;
  state = {
    isLoading: true,
    vehicles: [],
    viewOptions: false,
    selectedVehicle: null,
    options: null,
    optionCodes: [],
    optionsValue: 0,
    selectedOptionIndexes: [],
    excludedOptionsIndexes: [],
    includedOptionsIndexes: [],
    requiredOptionsIndexes: [],
    showOtionalRequiredOptions: false,
    optinalRequiredOptionsIndexes: [],
    isloadingIncludeExcludeOptions: false,
    tireWear: 0,
    bodyDamage: 0,
    hasGpl: false,
    valuationLoading: false,
    showWizard: false,
    hideVehicleSelection: true,
    kmNo: 0,
    color: null,
    showCarTable: false,
    hideValuationWizzard: false
  } as ICaseDetailsValuationState;

  public async componentDidMount() {
    await this.loadData();
  }

  onWizardCallback = async (
    make: string,
    model: string,
    body: string,
    firstRegistrationDate: Date,
    generation: number
  ) => {
    try {
      this.setState({ isLoading: true });
      const result = await this.caseService.GetValuationVehiclesWithoutVin(
        make,
        model,
        body,
        R.isNil(firstRegistrationDate) ? new Date() : firstRegistrationDate,
        generation
      );

      if (result && !R.isEmpty(result.vehicles)) {
        this.setState({
          isLoading: false,
          showWizard: false,
          vehicles: result.vehicles,
          optionCodes: result.extraOptionCodes
        });
      } else {
        this.setState({
          isLoading: false,
          showWizard: false,
          vehicles: result.vehicles,
          optionCodes: result.extraOptionCodes
        });
      }
      return result.vehicles;
    } catch (error) {
      this.setState({ isLoading: false, vehicles: [] });
      return [];
    }
  };

  onShowCarList = (show: boolean) => {
    this.setState({ showCarTable: show });
  };

  onChangeFirstRegistrationDate = (date: Date | null) => {
    if (this.props.onChangeFirstRegistrationDate) {
      this.props.onChangeFirstRegistrationDate(date);
    }
  };

  onFilterChanged = (vehicles: ValuationVehicle[]) => {
    this.setState({ vehicles: vehicles });
  };

  loadData = async () => {
    const firstRegistrationDate = this.props.firstRegistrationDate;
    const vin = this.props.vin;
    const carOptionalSelectedOptions =
      this.props.vehicleState.vehicle?.vehicleOptions.filter(
        (o) => !o.isStandard && o.isSelected
      ) ?? [];
    const carSelectedOptionalCodes = carOptionalSelectedOptions.map((x) => x.code);
    const valuationVehicleId = !R.isNil(this.props.vehicleState.vehicle)
      ? this.props.vehicleState.vehicle!.valuationVehicleId
      : null;
    if (!R.isNil(valuationVehicleId)) {
      const valuationVehicle = await this.caseService.GetValuationVehicle(valuationVehicleId);
      this.setState(
        {
          vehicles: [valuationVehicle],
          selectedVehicle: valuationVehicle,
          optionCodes: carSelectedOptionalCodes,
          viewOptions: true,
          showWizard: false,
          hideVehicleSelection: true
        },
        this.loadOptions
      );
    } else {
      let result = {
        vehicles: this.props.valuationVehicles,
        extraOptionCodes: this.props.optionCodes ?? []
      } as ValuationVehicles;

      if (R.isNil(this.props.valuationVehicles) && !R.isNil(vin)) {
        const body = {
          registration_date: R.isNil(firstRegistrationDate) ? new Date() : firstRegistrationDate,
          ignore_date: false,
          override_existing_match: false
        };

        result = await this.caseService.GetValuationVehiclesInfo(vin, null, body);
      }

      const vehicleState = this.props.vehicleState;
      if (!isNullOrUndefined(vehicleState)) {
        const valuationVehicles = result.vehicles.filter((v) => {
          return v.version === vehicleState.vehicle?.version;
        });
        if (valuationVehicles.length !== 0) {
          result.vehicles = valuationVehicles;
        } else {
          // vehicle is not found on GTValuation by VIN so we have to get a list of results by make/model/body and version
          const resultCustomValuationVehicles =
            await this.caseService.GetValuationVehiclesWithoutVin(
              vehicleState.vehicle?.make?.name || undefined,
              vehicleState.vehicle?.model?.name || undefined,
              vehicleState.vehicle?.bodyType?.name || undefined,
              !vehicleState.vehicle?.firstRegistrationDate
                ? new Date()
                : new Date(vehicleState.vehicle.firstRegistrationDate),
              vehicleState.vehicle?.generation || undefined
            );
          const perfectMatchFoundByVersionList = resultCustomValuationVehicles.vehicles.filter(
            (v) => {
              return v.version === vehicleState.vehicle?.version;
            }
          );
          if (perfectMatchFoundByVersionList.length === 1) {
            result.vehicles = perfectMatchFoundByVersionList;
          }
        }
      }

      if (result && R.isEmpty(result.vehicles)) {
        this.setState({
          isLoading: false,
          showWizard: true,
          hideVehicleSelection: false,
          hideValuationWizzard: false
        });

        return;
      }

      if (result) {
        if (result.vehicles.length === 1) {
          this.setState(
            {
              vehicles: result.vehicles,
              selectedVehicle: result.vehicles[0],
              optionCodes: result.extraOptionCodes,
              viewOptions: true,
              showWizard: false,
              hideVehicleSelection: true,
              hideValuationWizzard: true
            },
            this.loadOptions
          );
        } else {
          this.setState({
            vehicles: result.vehicles,
            isLoading: false,
            hideVehicleSelection: false,
            optionCodes: result.extraOptionCodes,
            hideValuationWizzard: true
          });
          this.onShowCarList(true);
        }
      }
    }
  };

  checkIfImageExists = (url: string | undefined) => {
    if (url === undefined || url.includes('undefined') || url.includes('null')) {
      return ImageNotAvailable;
    }

    return url;
  };

  getVehicleColumns = () => {
    const columns = [
      {
        name: 'vehicleId',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'manufacturer',
        label: this.translatorService.Tranlate('CASE_VALUATION_BRAND_LABEL', 'Marca'),
        options: { filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'model',
        label: this.translatorService.Tranlate('CASE_VALUATION_MODEL_LABEL', 'Model'),
        options: { filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'version',
        label: this.translatorService.Tranlate('CASE_VALUATION_VERSION_LABEL', 'Versiune'),
        options: { filter: true } as MUIDataTableColumnOptions
      },
      {
        name: 'trimLevel',
        label: this.translatorService.Tranlate('CASE_VALUATION_TRIMLEVEL_LABEL', 'Nivel echipare'),
        options: { filter: true } as MUIDataTableColumnOptions
      },
      {
        name: 'modelYear',
        label: this.translatorService.Tranlate('MODEL_YEAR', 'An model'),
        options: {
          filter: true
        } as MUIDataTableColumnOptions
      },
      {
        name: 'fuelType',
        label: this.translatorService.Tranlate('CASE_VALUATION_FUEL_TYPE_LABEL', 'Combustibil'),
        options: { filter: true } as MUIDataTableColumnOptions
      },
      //{
      //  name: 'transmission',
      //  label: this.translatorService.Tranlate(
      //    'CASE_VALUATION_GEARBOX_TYPE_LABEL',
      //    'Cutie de viteze'
      //  ),
      //  options: { filter: false } as MUIDataTableColumnOptions
      //},
      {
        name: 'powerHP',
        label: this.translatorService.Tranlate('CASE_VALUATION_KW_HP_TYPE_LABEL', 'Putere KW/HP'),
        options: {
          display: 'false',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'kw',
        label: this.translatorService.Tranlate('CASE_VALUATION_KW_HP_TYPE_LABEL', 'Putere KW/HP'),
        options: {
          filter: true,
          customBodyRender: (value, tableMeta) => {
            return <div>{value + '/' + tableMeta.rowData[7]}</div>;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'transmission',
        label: this.translatorService.Tranlate(
          'CASE_VALUATION_FUEL_TRANSMISSION_LABEL',
          'Transmisie'
        ),
        options: { filter: true } as MUIDataTableColumnOptions
      },
      {
        name: 'drivenWheels',
        label: this.translatorService.Tranlate('CASE_VALUATION_DRIVEN_WHEELS_LABEL', 'Tractiune'),
        options: { filter: true } as MUIDataTableColumnOptions
      },

      {
        name: 'cc',
        label: this.translatorService.Tranlate(
          'CASE_VALUATION_CC_TYPE_LABEL',
          'Capacitate cilindrica'
        ),
        options: { filter: true } as MUIDataTableColumnOptions
      },
      {
        name: 'convertedStartSellingDate',
        label: this.translatorService.Tranlate(
          'CASE_VALUATION_DATE_OF_FABRICATION_LABELssss',
          'data start comerc'
        ),
        options: { filter: false, display: 'excluded' } as MUIDataTableColumnOptions
      },
      {
        name: 'convertedEndSellingDate',
        label: this.translatorService.Tranlate(
          'CASE_VALUATION_CAR_SALES_INTERVAL_LABEL',
          'Interval comercializare'
        ),
        options: {
          filter: false,
          customBodyRender: (value, tableMeta) => {
            const startSellingDate = tableMeta.rowData[12];
            const endSellingDate =
              value == '9999-12-31'
                ? this.translatorService.Tranlate('VALUATION_ON_GOING_LABEL', 'prezent')
                : value;
            return (
              <div>
                {startSellingDate} -
                <br />
                {endSellingDate}
              </div>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'basePrice',
        label: this.translatorService.Tranlate(
          'VALUATION_BASE_PRICE_LABEL',
          'Pret de Baza (incl. TVA)'
        ),
        options: { filter: false } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };

  geOptionsColumns = () => {
    const columns = [
      {
        name: 'optionCode',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'description',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowData = tableMeta.rowData;
            const rowIndex = tableMeta.rowIndex;
            const optionCode = rowData[0];
            const description = rowData[1];
            const price = rowData[2];
            const isExcluded = this.state.excludedOptionsIndexes.includes(rowIndex);
            const isIncluded = this.state.includedOptionsIndexes.includes(rowIndex);
            const isRequired = this.state.requiredOptionsIndexes.includes(rowIndex);
            const style = {
              color: '#fff',
              borderRadius: '0.15rem',
              padding: '2px 5px',
              marginLeft: '7px',
              fontSize: '12px',
              fontWeight: 'bold'
            };
            const excludedStyle = {
              backgroundColor: '#6c757d'
            };
            const includedStyle = {
              backgroundColor: '#28a745'
            };
            const mandatoryStyle = {
              backgroundColor: '#007bff'
            };

            return (
              <div
                style={isExcluded || isIncluded || isRequired ? { color: 'rgba(0,0,0,.54)' } : {}}
              >
                {`[${optionCode}] ${description} - ${price} €`}
                {isExcluded && <span style={{ ...style, ...excludedStyle }}>Indisponibila</span>}
                {isIncluded && (
                  <span style={{ ...style, ...includedStyle }}>Inclusa in alt pachet</span>
                )}
                {isRequired && (
                  <span style={{ ...style, ...mandatoryStyle }}>Obligatorie din alt pachet</span>
                )}
              </div>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'price',
        options: { display: 'excluded', filter: false, sort: false } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };

  getStandardOptionsColumns = () => {
    const columns = [
      {
        name: 'content',
        label: ' ',
        options: {
          filter: false,
          sort: false
        } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };

  loadOptions = async () => {
    if (this.state.selectedVehicle === null) {
      return;
    }
    const options = await this.caseService.GetValuationVehicleOptions(
      this.state.selectedVehicle!.vehicleId
    );

    const selectedOptionIndexes = this.getSelectedRows(options.extraOptions);

    const valLanguageId = Utils.GetValuationLanguageId(this.props.appState.language);

    options.standardOptions = options.standardOptions.filter(
      (option) =>
        option.languageId === valLanguageId && !standardOptionsSchemaIds.includes(option.schemaId)
    );

    this.setState({
      isLoading: false,
      options: options,
      selectedOptionIndexes: selectedOptionIndexes,
      optionsValue: this.getExtraOptionsPrice(options, selectedOptionIndexes)
    });
  };

  getSelectedRows = (extraoptions: ValuationExtraOption[]) => {
    const indexArray: number[] = [];
    this.state.optionCodes.forEach((item) => {
      const index = extraoptions.findIndex((o) => o.optionCode === item);
      if (index !== undefined && index !== -1) {
        indexArray.push(index);
      }
    });

    return indexArray;
  };

  getVehicleOptions = () => {
    return {
      viewColumns: false,
      filter: true,
      search: false,
      pagination: false,
      selectableRows: 'single' as SelectableRows,
      selectableRowsHeader: false,
      disableToolbarSelect: true,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1,
      onRowClick: (rowData: any, rowMeta: any) => {
        if (this.props.vin !== null && !R.isNil(this.props.onSelectedVehicle)) {
          this.props.onSelectedVehicle(
            this.state.vehicles[rowMeta.dataIndex],
            this.state.optionCodes
          );
          // return;
        }

        this.setState(
          {
            hideVehicleSelection: true,
            viewOptions: true,
            isLoading: true,
            selectedVehicle: this.state.vehicles[rowMeta.dataIndex]
          },
          this.loadOptions
        );
      },
      onRowsSelect: (rowSelected: any) => {
        if (this.props.vin !== null && !R.isNil(this.props.onSelectedVehicle)) {
          this.props.onSelectedVehicle(
            this.state.vehicles[rowSelected[0].dataIndex],
            this.state.optionCodes
          );
          // return;
        }

        this.setState(
          {
            hideVehicleSelection: true,
            viewOptions: true,
            isLoading: true,
            selectedVehicle: this.state.vehicles[rowSelected[0].dataIndex]
          },
          this.loadOptions
        );
      },
      customToolbar: () => {
        return (
          <Button
            style={{
              border: 'none',
              backgroundColor: '#fff',
              color: 'rgba(0, 0, 0, 0.54)',
              pointerEvents: 'none',
              padding: '0'
            }}
          >
            <span>{this.translatorService.Tranlate('FILTER_TABLE', 'Filtreaza')}</span>
          </Button>
        );
      }
    };
  };

  getExtraOptionsPrice = (options: ValuationVehicleOptions | null, selectedIndexes: number[]) => {
    if (options === null) {
      return 0;
    }
    if (options.extraOptions === null) {
      return 0;
    }

    const vatValue = 0;
    let sum = 0;
    selectedIndexes.forEach((item) => {
      sum += options.extraOptions[item].price;
    });

    return Utils.getPriceWithVat(sum, vatValue);
  };

  getOptionsOptions = () => {
    return {
      viewColumns: false,
      filter: false,
      search: false,
      pagination: false,
      selectableRows: 'multiple' as SelectableRows,
      rowsSelected: this.state.selectedOptionIndexes,
      selectableRowsHeader: false,
      disableToolbarSelect: true,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1,

      onRowsSelect: async (rowSelected: any, rowsSelected: any[]) => {
        const selected = rowsSelected.map((item) => item.index).filter((item) => item !== -1);
        const lastSelectedIndex = rowSelected[0].dataIndex;
        const lastSelectedOptionIndex =
          this.state.options!.extraOptions[lastSelectedIndex].optionId;
        const { includedOptionIds, excludedOptionIds, requiredOptionIds } =
          await this.fetchIncludeAndExcludeOptions(
            lastSelectedOptionIndex,
            this.state.selectedVehicle!.vehicleId
          );
        let finalSelectedIndexes = [...selected];
        let finalIncludedIndexes = Array.from(new Set([...this.state.includedOptionsIndexes]));
        let finalExcludedIndexes = Array.from(new Set([...this.state.excludedOptionsIndexes]));
        let finalRequiredIndexes = Array.from(new Set([...this.state.requiredOptionsIndexes]));

        // if the selected option is selected
        if (selected.includes(lastSelectedIndex)) {
          if (includedOptionIds) {
            const includedIndexes = this.findIndexes(
              this.state.options!.extraOptions,
              includedOptionIds
            );

            finalIncludedIndexes = Array.from(
              new Set([...finalIncludedIndexes, ...includedIndexes])
            );

            finalSelectedIndexes = this.removeIndexes(finalSelectedIndexes, includedIndexes);
          }

          if (requiredOptionIds) {
            // if there are mandatory options
            if (requiredOptionIds.mandatory.length > 0) {
              const mandatoryIndexes = this.findIndexes(
                this.state.options!.extraOptions,
                requiredOptionIds.mandatory
              );

              finalRequiredIndexes = Array.from(
                new Set([...finalRequiredIndexes, ...mandatoryIndexes])
              );
              finalSelectedIndexes = Array.from(
                new Set([...finalSelectedIndexes, ...mandatoryIndexes])
              );
            }

            // if user has to select at least one option from the mandatory ones
            if (requiredOptionIds.atLeastOne.length > 0) {
              this.setState({
                showOtionalRequiredOptions: true,
                optinalRequiredOptionsIndexes: requiredOptionIds.atLeastOne
              });
            }
          }

          if (excludedOptionIds) {
            const excludedIndexes = this.findIndexes(
              this.state.options!.extraOptions,
              excludedOptionIds
            );

            finalExcludedIndexes = Array.from(
              new Set([...finalExcludedIndexes, ...excludedIndexes])
            );

            finalSelectedIndexes = this.removeIndexes(finalSelectedIndexes, excludedIndexes);
          }
        }
        // if the selected option is deselected
        else {
          if (includedOptionIds) {
            const includedIndexes = this.findIndexes(
              this.state.options!.extraOptions,
              includedOptionIds
            );

            finalIncludedIndexes = this.removeIndexes(finalIncludedIndexes, includedIndexes);
            finalSelectedIndexes = this.removeIndexes(finalSelectedIndexes, includedIndexes);
          }

          if (requiredOptionIds) {
            if (requiredOptionIds.mandatory.length > 0) {
              const mandatoryIndexes = this.findIndexes(
                this.state.options!.extraOptions,
                requiredOptionIds.mandatory
              );

              finalRequiredIndexes = this.removeIndexes(finalRequiredIndexes, mandatoryIndexes);
              finalSelectedIndexes = this.removeIndexes(finalSelectedIndexes, mandatoryIndexes);
              finalExcludedIndexes = this.removeIndexes(finalExcludedIndexes, mandatoryIndexes);
            }

            if (requiredOptionIds.atLeastOne.length > 0) {
              const atLeastOneIndexes = this.findIndexes(
                this.state.options!.extraOptions,
                requiredOptionIds.atLeastOne
              );

              finalRequiredIndexes = this.removeIndexes(finalRequiredIndexes, atLeastOneIndexes);
              finalSelectedIndexes = this.removeIndexes(finalSelectedIndexes, atLeastOneIndexes);
              finalExcludedIndexes = this.removeIndexes(finalExcludedIndexes, atLeastOneIndexes);
            }
          }

          if (excludedOptionIds) {
            const excludedIndexes = this.findIndexes(
              this.state.options!.extraOptions,
              excludedOptionIds
            );

            finalExcludedIndexes = this.removeIndexes(finalExcludedIndexes, excludedIndexes);
            finalSelectedIndexes = this.removeIndexes(finalSelectedIndexes, excludedIndexes);
          }
        }

        finalSelectedIndexes = this.removeIndexes(finalSelectedIndexes, finalExcludedIndexes);
        finalSelectedIndexes = Array.from(
          new Set([...finalSelectedIndexes, ...finalRequiredIndexes])
        );
        finalExcludedIndexes = this.removeIndexes(finalExcludedIndexes, finalRequiredIndexes);
        finalIncludedIndexes = this.removeIndexes(finalIncludedIndexes, finalRequiredIndexes);

        this.setState({
          selectedOptionIndexes: finalSelectedIndexes,
          optionsValue: this.getExtraOptionsPrice(this.state.options, finalSelectedIndexes),
          excludedOptionsIndexes: finalExcludedIndexes,
          includedOptionsIndexes: finalIncludedIndexes,
          requiredOptionsIndexes: finalRequiredIndexes
        });
      },
      isRowSelectable: (dataIndex: any) =>
        !this.state.excludedOptionsIndexes.includes(dataIndex) &&
        !this.state.includedOptionsIndexes.includes(dataIndex) &&
        !this.state.requiredOptionsIndexes.includes(dataIndex)
    };
  };

  fetchIncludeOptions = async (optionId: number, vehicleId: number): Promise<number[] | null> => {
    let includeOptionsRequest = null;

    try {
      includeOptionsRequest = await this.caseService.GetIncludeOptions(optionId, vehicleId);
    } catch (error) {}

    if (includeOptionsRequest) {
      const extractedIndexes = this.extractIndexes(includeOptionsRequest.rule);
      return extractedIndexes;
    }

    return includeOptionsRequest;
  };

  fetchExcludeOptions = async (optionId: number, vehicleId: number): Promise<number[] | null> => {
    let excludeOptionsRequest = null;

    try {
      excludeOptionsRequest = await this.caseService.GetExcludeOptions(optionId, vehicleId);
    } catch (error) {}

    if (excludeOptionsRequest) {
      const extractedIndexes = this.extractIndexes(excludeOptionsRequest.rule);
      return extractedIndexes;
    }

    return excludeOptionsRequest;
  };

  fetchRequiredOptions = async (
    optionId: number,
    vehicleId: number
  ): Promise<ParsedConditions | null> => {
    let requiredOptionsRequest = null;

    try {
      requiredOptionsRequest = await this.caseService.GetRequiredOptions(optionId, vehicleId);
    } catch (error) {}

    if (requiredOptionsRequest) {
      const parsedConditions = this.parseRequiredConditions(requiredOptionsRequest.rule);
      return parsedConditions;
    }

    return requiredOptionsRequest;
  };

  fetchIncludeAndExcludeOptions = async (optionId: number, vehicleId: number) => {
    let includedOptionIds = null;
    let excludedOptionIds = null;
    let requiredOptionIds = null;

    try {
      this.setState({ isloadingIncludeExcludeOptions: true });

      includedOptionIds = await this.fetchIncludeOptions(optionId, vehicleId);
      excludedOptionIds = await this.fetchExcludeOptions(optionId, vehicleId);
      requiredOptionIds = await this.fetchRequiredOptions(optionId, vehicleId);
    } catch (error) {
      this.setState({ isloadingIncludeExcludeOptions: false });
    }

    this.setState({ isloadingIncludeExcludeOptions: false });
    return {
      includedOptionIds,
      excludedOptionIds,
      requiredOptionIds
    };
  };

  findIndexes = (extraOptions: ValuationExtraOption[] | null, codes: number[]): number[] => {
    if (extraOptions === null) return [];
    const indexes: number[] = [];

    extraOptions.forEach((object, index) => {
      if (codes.includes(object.optionId)) {
        indexes.push(index);
      }
    });

    return indexes;
  };

  removeIndexes(excludedOptionsIndexes: number[], excludedIndexes: number[]): number[] {
    return excludedOptionsIndexes.filter((item) => !excludedIndexes.includes(item));
  }

  extractIndexes = (input: string): number[] => {
    // Use regular expression to match all numbers within braces
    const matches = input.match(/\{(\d+)\}/g);

    // If no matches found, return an empty array
    if (!matches) {
      return [];
    }

    // Extract the numbers and convert them to integers
    const indexes = matches.map((match) => parseInt(match.replace(/[{}]/g, ''), 10));

    return indexes;
  };

  parseRequiredConditions(input: string): ParsedConditions {
    const mandatory: number[] = [];
    const atLeastOne: number[] = [];

    // Regex for extracting all conditions (e.g., {1234})
    const allConditionsRegex = /{(\d+)}/g;
    let match;

    // Collect all numbers within curly braces
    const allConditions: number[] = [];
    while ((match = allConditionsRegex.exec(input)) !== null) {
      allConditions.push(parseInt(match[1], 10));
    }

    // Check if the input contains 'AND' or 'OR'
    if (input.includes('AND')) {
      // Regex for extracting mandatory conditions before 'AND'
      const mandatoryPart = input.split(' AND ')[0];
      const mandatoryRegex = /{(\d+)}/g;
      let mandatoryMatch;
      while ((mandatoryMatch = mandatoryRegex.exec(mandatoryPart)) !== null) {
        mandatory.push(parseInt(mandatoryMatch[1], 10));
      }

      // Regex for extracting optional conditions within parentheses
      const optionalPartMatch = input.match(/\(([^)]+)\)/);
      if (optionalPartMatch) {
        const optionalRegex = /{(\d+)}/g;
        let optionalMatch;
        while ((optionalMatch = optionalRegex.exec(optionalPartMatch[1])) !== null) {
          atLeastOne.push(parseInt(optionalMatch[1], 10));
        }
      }
    } else if (input.includes('OR')) {
      // If there are no 'AND' conditions, treat all as optional
      for (const num of allConditions) {
        atLeastOne.push(num);
      }
    } else {
      // If no logical operator is present, all conditions are mandatory
      mandatory.push(...allConditions);
    }

    return {
      mandatory,
      atLeastOne
    };
  }

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator={false}
        decimalScale={2}
        isNumericString
      />
    );
  };
  handleBodyDamageTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      bodyDamage: event.target.value === null ? 0 : parseFloat(event.target.value)
    });
  };

  handleTireWearTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      tireWear: event.target.value === null ? 0 : parseFloat(event.target.value)
    });
  };

  handleKmNoTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      kmNo:
        event.target.value === null || isNaN(Number(event.target.value))
          ? 0
          : parseFloat(event.target.value)
    });
  };

  handleGplChange = (checked: boolean) => {
    this.setState({
      hasGpl: checked
    });
  };

  handleColorChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: IReferential | null
  ) => {
    if (value) {
      this.setState({
        color: value?.code
      });
    } else {
      this.setState({
        color: ''
      });
    }
  };

  checkIfIsValidDate = (date: Date | null) => {
    const registrationTimestamp = date!.getTime();

    if (isNaN(registrationTimestamp)) {
      const newDate = new Date();
      return moment(newDate).format('YYYY-MM-DD');
    }

    return date;
  };

  performValuation = async () => {
    try {
      const options: ValuationExtraOption[] = [];

      this.state.selectedOptionIndexes.forEach((index) =>
        options.push(this.state.options!.extraOptions[index])
      );
      const valuationRequest = {
        attritionValue: this.state.tireWear,
        bodyDamageValue: this.state.bodyDamage,
        extraOptionsPrice: this.state.optionsValue,
        gpl: this.state.hasGpl,
        numberOfKilometers: this.state.kmNo,
        priceAsNew: this.state.selectedVehicle!.basePrice,
        vehicleId: this.state.selectedVehicle!.vehicleId,
        options: options,
        registrationDate: moment(this.props.firstRegistrationDate).isValid()
          ? moment(this.props.firstRegistrationDate).startOf('month').format('YYYY-MM-DD')
          : null,
        fabricationDate: this.state.selectedVehicle?.fabricationDate
          ? this.state.selectedVehicle?.fabricationDate
          : null,
        imageUrl: this.state.selectedVehicle?.imageUrl ? this.state.selectedVehicle?.imageUrl : '',
        vin: this.props.vin,
        version: this.state.selectedVehicle!.version,
        color: this.state.color,
        equipmentVersion: this.state.selectedVehicle!.trimLevel,
        make: this.state.selectedVehicle!.manufacturer,
        model: this.state.selectedVehicle!.model,
        fuelType: this.state.selectedVehicle!.fuelType,
        bodyType: this.state.selectedVehicle!.body,
        horsePower: this.state.selectedVehicle!.powerHP,
        nrOfDoors: this.state.selectedVehicle!.noOfDoors,
        transmissionType: this.state.selectedVehicle!.transmission
      } as ValuationRequest;

      this.setState({ valuationLoading: true });

      const result = await this.caseService.PerformValuation(valuationRequest, null);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });

      this.setState({ valuationLoading: false });
      document.getElementById('valuation-dialog-close-button')?.click();

      if (result) this.props.history.push(`/valuation/file/${result.id}`);
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      this.setState({ valuationLoading: false });
    }
  };

  renderOptions = () => {
    const basePrice = this.state.selectedVehicle?.basePrice || 0;
    const optionsPrice = this.state.optionsValue;
    const totalPrice = basePrice + optionsPrice;

    const basePriceLabel = this.translatorService.Tranlate(
      'VALUATION_BASE_PRICE_LABEL',
      'Pret de Baza (incl. TVA)'
    );
    const optionsPriceLabel = this.translatorService.Tranlate(
      'VALUATION_OPTIONS_PRICE_LABEL',
      'Pret Optionale (incl. TVA)'
    );
    const totalPriceLabel = this.translatorService.Tranlate(
      'VALUATION_TOTAL_LABEL',
      'Total (incl. TVA)'
    );

    return (
      <div className="m-1">
        <h4>
          {`${this.state.selectedVehicle!.manufacturer} ${this.state.selectedVehicle!.model} ${
            this.state.selectedVehicle!.version
          }`}
        </h4>
        <br></br>

        <Grid container spacing={1} alignItems="stretch" className="h-100 mb-2">
          <Grid item xs={6}>
            <Card elevation={1} className="p-4 mb-2">
              <CardContent>
                <Grid container>
                  <Grid item container>
                    <Grid item xs={4} className="mr-2">
                      <div style={{ marginBottom: '4px' }}>
                        <Label>{basePriceLabel}</Label>
                      </div>
                      <div style={{ marginBottom: '4px' }}>
                        <Label>{optionsPriceLabel}</Label>
                      </div>
                      <div style={{ marginBottom: '4spx' }}>
                        <Label>{totalPriceLabel}</Label>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <h5>{basePrice.toFixed(2)} €</h5>
                      <h5>{optionsPrice.toFixed(2)} €</h5>
                      <h5>{totalPrice.toFixed(2)} €</h5>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card elevation={1} className="p-4">
              <ValidatorForm onSubmit={this.performValuation} instantValidate={true}>
                {this.state.selectedVehicle?.fabricationDate ? (
                  <TextValidator
                    fullWidth
                    disabled
                    name="fabricationDate"
                    className="m-2"
                    id="fabricationDate"
                    value={this.state.selectedVehicle?.fabricationDate}
                    label={this.translatorService.Tranlate(
                      'VALUATION_FABRICATION_DATE_LABEL',
                      'Data fabricatie'
                    )}
                  />
                ) : null}
                {moment(this.props.firstRegistrationDate).isValid() ? (
                  <TextValidator
                    fullWidth
                    disabled
                    name="registrationDate"
                    className="m-2"
                    id="registrationDate"
                    value={moment(this.props.firstRegistrationDate).format('YYYY-MM')}
                    label={this.translatorService.Tranlate(
                      'VALUATION_REGISTRATION_DATE_LABEL',
                      'Data inmatriculare'
                    )}
                  />
                ) : null}
                <TextValidator
                  fullWidth
                  name="km"
                  className="m-2"
                  id="km"
                  value={this.state.kmNo}
                  onChange={this.handleKmNoTextChange}
                  label={this.translatorService.Tranlate('VALUATION_KILOMETERS_LABEL', 'Kilometri')}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom
                  }}
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
                <TextValidator
                  fullWidth
                  name="tires"
                  className="m-2"
                  id="tires"
                  value={this.state.tireWear}
                  onChange={this.handleTireWearTextChange}
                  label={this.translatorService.Tranlate(
                    'VALUATION_TIRE_ATTRITION_LABEL',
                    'Uzura Anvelope'
                  )}
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom
                  }}
                />
                <TextValidator
                  fullWidth
                  className="m-2"
                  id="bodyDamage"
                  name="bodyDamage"
                  value={this.state.bodyDamage}
                  onChange={this.handleBodyDamageTextChange}
                  label={this.translatorService.Tranlate(
                    'VALUATION_BODY_DAMAGE_LABEL',
                    'Daune Caroserie'
                  )}
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom
                  }}
                />
                <Autocomplete
                  id="car-color"
                  className="ml-2 mb-3"
                  options={this.props.colorTypes}
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  onChange={this.handleColorChange}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="color"
                      value={this.state.color}
                      label={this.translatorService.Tranlate('CAR_DETAILS_COLOR', 'Culoare')}
                      fullWidth
                      // validators={['required']}
                      // errorMessages={[
                      //   this.translatorService.Tranlate(
                      //     'VALIDATORS_REQUIRED',
                      //     'Campul este obligatoriu'
                      //   )
                      // ]}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.hasGpl}
                      className="ml-2"
                      onChange={(e, checked) => {
                        this.handleGplChange(checked);
                      }}
                      color="primary"
                      id="hasGpl"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label={this.translatorService.Tranlate('VALUATION_GPL_LABEL', 'GPL')}
                />
                <div className="text-right">
                  {this.state.vehicles.length > 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      className="mr-2"
                      onClick={() => {
                        this.setState({
                          isLoading: false,
                          hideVehicleSelection: false,
                          hideValuationWizzard: true,
                          viewOptions: false,
                          selectedVehicle: null,
                          kmNo: 0,
                          tireWear: 0,
                          bodyDamage: 0,
                          hasGpl: false,
                          color: null,
                          options: null,
                          selectedOptionIndexes: [],
                          includedOptionsIndexes: [],
                          excludedOptionsIndexes: []
                        });
                        this.onShowCarList(true);
                      }}
                    >
                      {this.translatorService.Tranlate(
                        'VALUATION_FILE_CHANGE_VEHICLE_LABEL',
                        'Schimba vehicul'
                      )}
                    </Button>
                  ) : null}
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={this.state.valuationLoading}
                  >
                    {this.translatorService.Tranlate('VALUATION_ACTION_START_LABEL', 'Evalueaza')}
                  </Button>
                </div>
              </ValidatorForm>
            </Card>
          </Grid>
          <Grid item xs={6} className="h-100">
            <Card elevation={1} className="p-4">
              <CardContent>
                <div className="d-flex flex-row text-center flex-wrap justify-content-center"></div>
                <CardMedia
                  style={{
                    height: 'auto',
                    width: '100%',
                    maxWidth: '380px',
                    margin: '0 auto',
                    minHeight: '230px'
                  }}
                  className="mb-2"
                  image={this.checkIfImageExists(this.state.selectedVehicle?.imageUrl)}
                  title="Car Image"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MUIDataTable
              title={this.translatorService.Tranlate(
                'VALUATION_STANDARD_OPTIONS_COLUMN_HEADER',
                'Optionale standard'
              )}
              data={this.state.options!.standardOptions}
              columns={this.getStandardOptionsColumns()}
              options={{
                viewColumns: false,
                filter: false,
                search: false,
                pagination: false,
                selectableRows: 'none' as SelectableRows,
                selectableRowsHeader: false,
                disableToolbarSelect: true,
                print: false,
                download: false,
                textLabels: MUITranslations.GetTranslations(this.translatorService),
                elevation: 1
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={
                this.state.isloadingIncludeExcludeOptions
                  ? {
                      zIndex: 100000,
                      pointerEvents: 'none'
                    }
                  : {}
              }
            >
              <MUIDataTable
                title={this.translatorService.Tranlate(
                  'VALUATION_EXTRA_OPTIONS_COLUMN_HEADER',
                  'Optiuni extra'
                )}
                data={this.state.options!.extraOptions}
                columns={this.geOptionsColumns()}
                options={this.getOptionsOptions()}
              />
              {this.state.showOtionalRequiredOptions && this.renderMandatoryOptionsDialog()}
            </div>
          </Grid>
          <Grid
            style={{
              position: 'fixed',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
              width: '100%',
              textAlign: 'center'
            }}
          >
            {this.state.isloadingIncludeExcludeOptions ? <Loader /> : null}
          </Grid>
        </Grid>
      </div>
    );
  };

  renderMandatoryOptionsDialog = () => {
    const data = this.state.options?.extraOptions.filter((option) => {
      return this.state.optinalRequiredOptionsIndexes.includes(option.optionId);
    });

    return (
      <Dialog open={this.state.showOtionalRequiredOptions}>
        <DialogTitle id="alert-dialog-title">
          {'Pentru selectia anterioara este necesara adaugarea unei optiuni din lista'}
        </DialogTitle>
        <DialogContent>
          <MUIDataTable
            title=""
            data={data!}
            columns={[
              {
                name: 'optionCode',
                options: {
                  display: 'excluded',
                  filter: false,
                  sort: false
                } as MUIDataTableColumnOptions
              },
              {
                name: 'description',
                label: 'Optiune',
                options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value, tableMeta) => {
                    const rowData = tableMeta.rowData;

                    return `[${rowData[0]}] ${value} - ${rowData[2]} €`;
                  }
                }
              },
              {
                name: 'price',
                label: 'Pret',
                options: {
                  display: 'excluded',
                  filter: false,
                  sort: false
                }
              }
            ]}
            options={{
              viewColumns: false,
              filter: false,
              search: false,
              pagination: false,
              selectableRows: 'single' as SelectableRows,
              selectableRowsHeader: false,
              disableToolbarSelect: true,
              print: false,
              download: false,
              textLabels: MUITranslations.GetTranslations(this.translatorService),
              elevation: 1,

              onRowsSelect: (rowSelected: any, allRows: any) => {
                const { dataIndex } = rowSelected[0];
                const rowData = this.state.options?.extraOptions.filter((option) => {
                  return this.state.optinalRequiredOptionsIndexes.includes(option.optionId);
                })[dataIndex];
                const selectedOptionIndexInExtraOptions = this.findIndexes(
                  this.state.options!.extraOptions,
                  [rowData!.optionId]
                );
                const unselectedOptionsToBeRemoved =
                  this.state.optinalRequiredOptionsIndexes.filter(
                    (item) => item !== rowData!.optionId
                  );
                const findOptionsToBeExcluded = this.findIndexes(
                  this.state.options!.extraOptions,
                  unselectedOptionsToBeRemoved
                );
                let finalSelectedIndexes = Array.from(
                  new Set([
                    ...this.state.selectedOptionIndexes,
                    ...selectedOptionIndexInExtraOptions
                  ])
                );
                const finalRequiredIndexes = Array.from(
                  new Set([
                    ...this.state.requiredOptionsIndexes,
                    ...selectedOptionIndexInExtraOptions
                  ])
                );
                let finalExcludedIndexes = Array.from(
                  new Set([...this.state.excludedOptionsIndexes, ...findOptionsToBeExcluded])
                );
                let finalIncludedIndexes = Array.from(
                  new Set([...this.state.includedOptionsIndexes])
                );

                finalSelectedIndexes = this.removeIndexes(
                  finalSelectedIndexes,
                  finalExcludedIndexes
                );
                finalSelectedIndexes = Array.from(
                  new Set([...finalSelectedIndexes, ...finalRequiredIndexes])
                );
                finalExcludedIndexes = this.removeIndexes(
                  finalExcludedIndexes,
                  finalRequiredIndexes
                );
                finalIncludedIndexes = this.removeIndexes(
                  finalIncludedIndexes,
                  finalRequiredIndexes
                );

                this.setState({
                  ...this.state,
                  showOtionalRequiredOptions: false,
                  selectedOptionIndexes: finalSelectedIndexes,
                  requiredOptionsIndexes: finalRequiredIndexes,
                  optionsValue: this.getExtraOptionsPrice(this.state.options, finalSelectedIndexes),
                  excludedOptionsIndexes: finalExcludedIndexes,
                  includedOptionsIndexes: finalIncludedIndexes,
                  optinalRequiredOptionsIndexes: []
                });
              }
            }}
          />
        </DialogContent>
      </Dialog>
    );
  };

  renderVehicleSelection = () => {
    return (
      <div>
        {!this.state.hideValuationWizzard && (
          <ValuationFiltersWizardContainer
            brandTypes={this.props.brandTypes}
            modelTypes={this.props.modelTypes}
            bodyTypes={this.props.bodyTypes}
            vehicles={this.state.vehicles}
            fuelTypes={this.props.fuelTypes}
            tractionTypes={this.props.tractionTypes}
            transmissionTypes={this.props.transmissionTypes}
            firstRegistrationDate={this.props.firstRegistrationDate}
            onWizardCallback={this.onWizardCallback}
            onFilterChanged={this.onFilterChanged}
            onShowCarList={this.onShowCarList}
            onChangeFirstRegistrationDate={this.onChangeFirstRegistrationDate}
            isFromValuationModule={true}
          />
        )}

        {this.state.showCarTable && !this.state.isLoading ? (
          <div className="m-1">
            <MUIDataTable
              title={''}
              data={this.state.vehicles}
              columns={this.getVehicleColumns()}
              options={this.getVehicleOptions()}
            />
          </div>
        ) : null}
      </div>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <Fragment>
        {this.state.hideVehicleSelection ? null : this.renderVehicleSelection()}
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading &&
        this.state.viewOptions &&
        this.state.options &&
        !this.state.showWizard
          ? this.renderOptions()
          : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsValuationProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(DetailsValuation as any));
