import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { CaseVehicleHistory, Model, ModelData, UndocumentedModel } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { WorkflowSectionStep, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import Form from '@rjsf/material-ui';
import { ISubmitEvent, FormValidation, IChangeEvent } from '@rjsf/core';
import { FormButton } from '../../helpers/forms/FormButton';
import moment from 'moment';
import { GTErrorCode, ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { AppUser } from '../../interfaces/AppUser';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DatePicker from '../_shared/DatePicker';
import _ from 'lodash';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as R from 'ramda';

interface ICaseDetailsCaseTabCarDetailsFormState {
  formData: CaseVehicleHistory;
  isLoading: boolean;
  carCategoryList: IReferential[];
  modelList: Model[];
  brandList: IReferential[];
  filteredModelList: IReferential[];
  hasRights: boolean;
  executing: boolean;
  undocumentedModelList: UndocumentedModel[];
  isDialogOpen: boolean;
  modelData: ModelData[];
  selectedModelData: ModelData | null;
  carClassList: IReferential[];
  initialNumberOfKilometers: number | undefined;
  step: WorkflowSectionStep | null;

}
export interface ExternalCaseDetailsCaseTabCarDetailsFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabCarDetailsFormProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ExternalCaseDetailsCaseTabCarDetailsFormProps &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabCarDetailsForm extends React.PureComponent<
  ICaseDetailsCaseTabCarDetailsFormProps,
  ICaseDetailsCaseTabCarDetailsFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    formData: {} as CaseVehicleHistory,
    isLoading: true,
    carCategoryList: [],
    modelList: [],
    brandList: [],
    filteredModelList: [],
    hasRights: false,
    executing: false,
    undocumentedModelList: [],
    isDialogOpen: false,
    modelData: [],
    selectedModelData: null,
    carClassList: [],
    step: null
  } as unknown as ICaseDetailsCaseTabCarDetailsFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);

    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const refCarCateg = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarCategory
    );
    const refCarBrand = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarBrands
    );
    const refCarClass = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarClass
    );

    const caseVehicle = this.props.caseSettingsState.case!.caseVehicle;
    let brandId = 0;
    if (!isNullOrUndefined(caseVehicle) && !isNullOrUndefined(caseVehicle.brandId)) {
      brandId = caseVehicle.brandId;
    }

    const [carCategoryList, brandList, modelList, undocumentedModelList, carClassList] = await Promise.all([
      this.appReferentialService.Get(refCarCateg!.baseUrl),
      this.appReferentialService.Get(refCarBrand!.baseUrl),
      this.caseService.GetCarModelsByBrand(brandId),
      this.caseService.GetCarUndocumentedModelsByBrand(brandId),
      this.appReferentialService.Get(refCarClass!.baseUrl)
    ]);

    let modelList1: Model[] = [];
    if (modelList.length === 0 && brandList.length !== 0) {
      modelList1 = await this.caseService.GetCarModelsByBrand(brandList[0].id);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    const step = this.getWorkflowSectionStep(this.props.workflowForm.workflowSectionStepId);

    if (isNullOrUndefined(this.props.caseSettingsState.case!.caseVehicle)) {
      this.setState({
        formData: {
          caseId: caseId,
          id: 0
        } as CaseVehicleHistory,
        isLoading: false,
        carCategoryList: carCategoryList,
        brandList: brandList,
        modelList: modelList1,
        undocumentedModelList: undocumentedModelList,
        hasRights: hasRights,
        carClassList: carClassList,
        step: step
      });

      return;
    }

    if (!isNullOrUndefined(caseVehicle.firstPlateDate)) {
      caseVehicle.firstPlateDate = moment(caseVehicle.firstPlateDate).format(moment.HTML5_FMT.DATE);
    }
    if (!isNullOrUndefined(caseVehicle.registrationDate)) {
      caseVehicle.registrationDate = moment(caseVehicle.registrationDate).format(
        moment.HTML5_FMT.DATE
      );
    }
    if (!isNullOrUndefined(caseVehicle.motDate)) {
      caseVehicle.motDate = moment(caseVehicle.motDate).format(
        moment.HTML5_FMT.DATE
      );
    }

    for (const [key, value] of Object.entries(caseVehicle)) {
      if (isNullOrUndefined(value)) {
        caseVehicle[key] = undefined;
      }
    }

    this.setState({
      formData: caseVehicle,
      isLoading: false,
      carCategoryList: carCategoryList,
      brandList: brandList,
      modelList: modelList,
      undocumentedModelList: undocumentedModelList,
      hasRights: hasRights,
      carClassList: carClassList,
      initialNumberOfKilometers: caseVehicle.numberOfKilometers,
      step: step
    });
  };

  handleArraySchema = (schema: any) => {
    if (isNullOrUndefined(schema.properties)) {
      return schema;
    }

    for (const key of Object.keys(schema.properties)) {
      if (schema.properties[key].anyOf === undefined) {
        continue;
      }
      if (schema.properties[key].anyOf.length === 0) {
        continue;
      }

      if (
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf.length != 0 &&
        schema.properties[key].anyOf[0].title === 'CAR_BRAND_ENUM'
      ) {
        schema.properties[key].anyOf = this.state.brandList.map((item, index) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
      }
      if (
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf.length != 0 &&
        schema.properties[key].anyOf[0].title === 'CAR_MODEL_ENUM'
      ) {
        const models = this.state.modelList.map((item, index) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
        schema.properties[key].anyOf = models;
      }

      if (
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf.length != 0 &&
        schema.properties[key].anyOf[0].title === 'CAR_CATEGORY_ENUM'
      ) {
        schema.properties[key].anyOf = this.state.carCategoryList.map((item) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
      }

      if (
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf.length != 0 &&
        schema.properties[key].anyOf[0].title === 'CAR_CLASS_ENUM'
      ) {
        schema.properties[key].anyOf = this.state.carClassList.map((item, index) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
      }
    }

    return schema;
  };
  
  getWorkflowSectionStep = (workflowSectionStepId: number): WorkflowSectionStep | null => {
    const sections = this.props.caseSettingsState.caseSettings!.workflow.workflowSections!;
    for (const section of sections) {
      for (const step of section.workflowSectionSteps) {
        if (step.id == workflowSectionStepId) {
          return step;
        }
      }
    }
    return null;
  };

  validate = (formData: any, errors: FormValidation, formCode: string) => {
    if (new Date(formData.firstPlateDate) >= new Date()) {
      errors.firstPlateDate.addError(
        this.translatorService.Tranlate(
          'FRM_CAR_DATA_FIRST_DATE_PLATE_LESS_CURRENT_DATE_ERROR',
          'Data primei inmatriculari trebuie sa fie mai mica decat data curenta!'
        )
      );
    }
    if (new Date(formData.registrationDate) >= new Date(formData.firstPlateDate)) {
      errors.registrationDate.addError(
        this.translatorService.Tranlate(
          'FRM_CAR_DATA_REGISTRATION_DATE_LESS_FIRST_DATE_PLATE_ERROR',
          'Data fabricatiei trebuie sa fie mai mica decat data primei inmatriculari!'
        )
      );
    }
    if (!R.isNil(formData.vin)) {
      if (formData.vin.length < 17) {
        errors.vin.addError(
          this.translatorService.Tranlate('FRM_MINIMUM_LENGTH_ERROR', "nu poate fi mai mică de {0} caractere").replace(
            '{0}',
            '17'
          )
        );
      }
    }

    if (!R.isNil(this.state.step)) {
      const step = this.state.step;
      if (step.isCurrent(this.props.caseSettingsState.case!.caseStatus.caseStatusId)) {
        if (!isNullOrUndefined(formData.numberOfKilometers) && !isNullOrUndefined(this.state.initialNumberOfKilometers)) {
          if (formData.numberOfKilometers <= this.state.initialNumberOfKilometers) {
            errors.numberOfKilometers.addError(
              this.translatorService.Tranlate('INITIAL_NUMBER_OF_KILOMETERS', ' Numarul de kilometri la interventia anterioara :') + ' ' + this.state.initialNumberOfKilometers + ' km'
            );
          }
        }
      }
    }


    return errors;
  };

  onSubmit = async (e: ISubmitEvent<any>, code: string) => {
    try {
      this.setState({ executing: true });

      let data = e.formData as CaseVehicleHistory;
      const brand = _.find(this.state.brandList, function(object) {
        return object.id == data.brandId;
      });
      const model = _.find(this.state.modelList, function(object) {
        return object.id == data.modelId;
      });

      const undocumentedModel =  _.find(this.state.undocumentedModelList, function(object) {
        return object.code == model?.code;
      });
      if (undocumentedModel) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('UNDOCUMENTED_MAKE_AND_MODELS', 'Autovehiculul este documentat parțial și este posibil să lipsească informațiile pentru unele piese. Dacă ai nevoie de ajutor te rugăm să ne trimiți un mesaj la suport@gtmotive.ro.'), {
          variant: 'warning',
          autoHideDuration: 5000
        });
      }

      const makeWithNoVinQuery =  _.find(this.state.undocumentedModelList, function(object) {
        return object.brand == brand?.displayName && !object.hasVinQuery;
      });
      if (makeWithNoVinQuery) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('MAKE_WITH_NO_VIN_QUERY', 'Pentru auto selectat nu se poate face identificarea conform VIN - Vă rugăm să verificaţi datele auto la deschiderea calculaţiei!'), {
          variant: 'warning',
          autoHideDuration: 5000
        });

      }
      
      data.caseId = this.props.caseSettingsState.case!.id;
      if (isNullOrUndefined(data.id)) {
        data.id = 0;
      }

      if (data.id === 0) {
        data = await this.caseService.AddCaseVehicle(data);
      } else {
        await this.caseService.UpdateCaseVehicle(data);
      }

      await this.submitForm(data.caseId, this.props.workflowForm.id, null);

      this.props.SetCaseVehicle(data);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);
    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }
    this.props.SetCaseStatus(newStatus);

    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  CustomDatePicker = (props: any) => {
    const { value, label, onChange, required } = props;
    const { language, dateFormat } = this.props.appState;
    return (
      <DatePicker
        locale={language}
        label={label}
        value={value || null}
        onChange={(date: MaterialUiPickersDate) => onChange(date?.format('YYYY-MM-DD'))}
        required={required}
        format={dateFormat.toUpperCase()}
        disabled={!this.state.hasRights}
      />
    );
  };


  closeDialog = async () => {
    this.setState({
      isDialogOpen: false
    });
  };

  handleAutocompleteModelDataChange = async (newValue: ModelData | null) => {
    this.setState({
      selectedModelData: newValue
    });
  };
  
  submitSelectedModelData = async () => {
    try {
      console.log(this.state.selectedModelData);

      const model = this.state.modelList.find(
        (item) => item.code === this.state.selectedModelData!.umc
      );
      if (isNullOrUndefined(model)) {
        return;
      }

      this.setState({
        formData: {
          ...this.state.formData,
          modelId: model.id
        },
        isDialogOpen: false
      });

    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderAddModelForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitSelectedModelData();
        }}
      >
        <div className="text-center m-0">
          <Autocomplete
            id="supplier"
            className="mb-3"
            options={this.state.modelData}
            value={this.state.selectedModelData}
            onChange={(e: any, newValue: ModelData | null) =>
              this.handleAutocompleteModelDataChange(newValue)
            }
            getOptionLabel={(option: ModelData) => option.modelDescription || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="supplier"
                value={this.state.selectedModelData}
                label={this.translatorService.Tranlate('FRM_CAR_DATA_MODEL', 'Model Autovehicul')}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
        </div>
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  };

  validateCaseCarDetails = (schema: any) => {
    if (!R.isNil(this.state.step)) {
      const step = this.state.step;
      if (step.isCurrent(this.props.caseSettingsState.case!.caseStatus.caseStatusId)) {
        if (!isNullOrUndefined(this.state.formData.numberOfKilometers) && !isNullOrUndefined(this.state.initialNumberOfKilometers)) {
          if (this.state.formData.numberOfKilometers <= this.state.initialNumberOfKilometers) {
            this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'NUMBER_OF_KILOMETERS_VALIDATION',
                'Vă rugăm să verificați numărul de kilometri  intoduși!'
              ),
              {
                variant: 'warning'
              }
            );
          }
        }
      }
    }
  };

  renderFormData = (form: WorkflowSectionStepForm) => {
    let jsonSchema = {};
    let jsonGUI = {};
    const widgets = {
      DateWidget: this.CustomDatePicker
    };

    try {
      jsonSchema = JSON.parse(form.json1);
      jsonGUI = JSON.parse(form.json2);
    } catch {
      console.log('json schema/gui error');
    }

    let schema = this.translatorService.TranslateSchema(jsonSchema);
    schema = this.handleArraySchema(jsonSchema);
    return (
      <div className="mx-5 px-5 pb-3 car-details-form">
        <Form
          schema={schema}
          disabled={!this.state.hasRights}
          uiSchema={jsonGUI}
          validate={(formData: any, errors: FormValidation) =>
            this.validate(formData, errors, form.form.code)
          }
          liveValidate
          showErrorList={false}
          formData={this.state.formData}
          onBlur={this.onBlur}
          onChange={async (e) => await this.onFormChange(e)}
          onSubmit={async (e: ISubmitEvent<any>) => await this.onSubmit(e, form.form.code)}
          transformErrors={this.translatorService.TranslateErrors}
          widgets={widgets}
        >
          <div className="text-center"
            onClick={() => {
              this.validateCaseCarDetails(schema);
            }}>
            {FormButton.GetButtons(
              form.form.code,
              this.translatorService,
              this.state.hasRights,
              this.state.executing
            )}
          </div>
        </Form>
        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={false}
            disableBackdropClick
            fullWidth={true}
          >
              <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
          >
            <span>{this.translatorService.Tranlate('FRM_CAR_DATA_MODEL', 'Model Autovehicul')}</span>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="p-3" dividers style={{ backgroundColor: '#fafafa' }}>
            {this.renderAddModelForm()}
          </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </div>
    );
  };

  onChangeVin = async (value:string) =>{
    if (R.test(/^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/, value)) {
      const [result, latestCaseVehicleHistory, vehicle] = await Promise.all([
        this.caseService.DecodeVin(value),
        this.caseService.GetLatestCaseVehicleHistory(value),
        this.caseService.GetVehicleFromVQRepo(value)
      ]);
      if (latestCaseVehicleHistory !== null) {
        this.setState({
          formData: {
            ...this.state.formData,
            numberOfKilometers: latestCaseVehicleHistory.numberOfKilometers
          },
          initialNumberOfKilometers: latestCaseVehicleHistory.numberOfKilometers
        });
      }
      if (result.error !== null) {
        if (result.error.errorInfo.code == GTErrorCode.THE_VIN_NUMBER_SENT_DOES_NOT_DECODE_ANY_MODEL) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('THE_VIN_NUMBER_SENT_DOES_NOT_DECODE_ANY_MODEL', 'Din păcate autovehiculul selectat nu poate fi identificat în aplicație !'), {
            variant: 'warning',
            autoHideDuration: 5000
          });
        }
        return;
      }

      if (result.responseInfo.vehicleInfoDec.modelList.modelData.length === 0) {
        return;
      }
      const makeCode = result.responseInfo.vehicleInfoDec.modelList.modelData[0].makeCode;
      const brand = this.state.brandList.find((item) => makeCode.startsWith(item.code!, 0));
      if (isNullOrUndefined(brand)) {
        return;
      }

      const [modelList, undocumentedModelList] = await Promise.all([
        this.caseService.GetCarModelsByBrand(brand.id),
        this.caseService.GetCarUndocumentedModelsByBrand(brand.id)
      ]);
      if (result.responseInfo.vehicleInfoDec.modelList.modelData.length > 1) {
        console.log('vehicle', vehicle);
        if (vehicle === null) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SELECT_MODEL_DIALOG', 'Va rugam selectati modelul auto!'), {
            variant: 'warning',
            autoHideDuration: 5000
          });
          this.setState({
            isDialogOpen: true,
            modelList: [...modelList],
            undocumentedModelList: undocumentedModelList,
            formData: {
              ...this.state.formData,
              brandId: brand.id
            },
            modelData: result.responseInfo.vehicleInfoDec.modelList.modelData
          });
        } else {
          const model = modelList.find(
            (item) => item.code === vehicle.umc
          );
          if (isNullOrUndefined(model)) {
            this.setState({
              modelList: [...modelList],
              undocumentedModelList: undocumentedModelList,
              formData: {
                ...this.state.formData,
                brandId: brand.id
              }
            });
  
            return;
          }
  
          this.setState({
            modelList: [...modelList],
            undocumentedModelList: undocumentedModelList,
            formData: {
              ...this.state.formData,
              brandId: brand.id,
              modelId: model.id
            }
          });
        }
      } else {
        const model = modelList.find(
          (item) => item.code === result.responseInfo.vehicleInfoDec.modelList.modelData[0].umc
        );
        if (isNullOrUndefined(model)) {
          this.setState({
            modelList: [...modelList],
            undocumentedModelList: undocumentedModelList,
            formData: {
              ...this.state.formData,
              brandId: brand.id
            }
          });

          return;
        }

        this.setState({
          modelList: [...modelList],
          undocumentedModelList: undocumentedModelList,
          formData: {
            ...this.state.formData,
            brandId: brand.id,
            modelId: model.id
          }
        });
      }
    } else {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('INVALID_CAR_VIN_IDENTIFICATOR', "Te rugăm să te asiguri că seria de șasiu introdusă  are 17 caractere alfanumerice și nu conține spații sau literele 'O' sau 'I'!"), {
        variant: 'warning',
        autoHideDuration: 6000
      });
    }
  }

  onBlur = async (id: string, value: any) => {
    if (id === 'root_plateNumber' && this.props.caseSettingsState.caseSettings!.workflow.isDgpciIntegration && value.length > 0){
      try {
        const vinRequest = await this.caseService.GetVinByPlateNumberRequest({PlateNumber:value, externalClient: false});

        if (vinRequest.success) {
          this.setState({
            formData: {
              ...this.state.formData,
              vin: vinRequest.vin
            },
          });
          this.onChangeVin(vinRequest.vin)
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
        } else {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('ERROR_MSG_VIN', 'Eroare la obtinerea VIN'),
            {
              variant: 'error'
            }
          );
        }
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
    if (id === 'root_vin') {
      this.onChangeVin(value);
    } 
  };
  
  onFormChange = async (e: IChangeEvent<CaseVehicleHistory>) => {
    if (
      e.formData.brandId !== this.state.formData.brandId &&
      !isNullOrUndefined(e.formData.brandId)
    ) {
      const modelList = await this.caseService.GetCarModelsByBrand(e.formData.brandId);
      const undocumentedModelList = await this.caseService.GetCarUndocumentedModelsByBrand(e.formData.brandId);

      const formData = e.formData;
      if (modelList.length != 0) {
        formData.modelId = modelList[0].id;
      }

      this.setState({
        formData: e.formData,
        modelList: modelList,
        undocumentedModelList: undocumentedModelList
      });
      return;
    }

    if (e.formData.numberOfKilometers !== this.state.formData.numberOfKilometers) {
      const element = document.querySelector('input#root_numberOfKilometers');
      if (element !== null) {
        element.classList.add('number-of-kilometers-changed');
      }
    }

    this.setState({
      formData: e.formData
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? <div>{this.renderFormData(this.props.workflowForm)}</div> : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabCarDetailsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabCarDetailsForm as any));
