import React, { useMemo, useContext } from 'react';
import { MarketData } from '../../interfaces/Radar/MarketData';
import ReactApexChart from 'react-apexcharts';
import isNil from 'lodash/isNil';
import { fuelTypes } from './fuelTypes';
import { ApplicationContext } from '../../context/Contexts';

interface Props {
  title: string;
  filteredMarketData: MarketData[];
  currentVehicleMileage?: number;
  handleScatterClick(dataIndex?: number): void;
}

const ScatterChart: React.FC<Props> = ({
  title,
  filteredMarketData,
  currentVehicleMileage,
  handleScatterClick
}) => {
  const context = useContext(ApplicationContext);
  const series = useMemo(() => {
    return [
      {
        name: 'Pret',
        data: filteredMarketData.map((item) => [item.kmNo, item.price])
      }
    ];
  }, [filteredMarketData]);

  const options = useMemo(() => {
    return {
      chart: {
        id: 'radarScatterChart',
        type: 'scatter',
        events: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            if (!isNil(config.dataPointIndex)) {
              handleScatterClick(config.dataPointIndex);
            }
          }
        },
        zoom: {
          enabled: true
        }
      },
      tooltip: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          const item = filteredMarketData[dataPointIndex];
          return `<div class="d-flex">
        <div>
          <img src="${item.image}" height="180" />
        </div>
        <div class="pl-4 pr-4 pt-4">
          <h5>${item.name}, ${item.offeredBy}, ${item.source}</h5>
          <p>${item.manufacturerYear}, ${fuelTypes[item.fuelType]}, ${item.kmNo} km${
            item.transmissionType ? ', ' + item.transmissionType : ''
          }
          <br />
          ${item.county}${item.postalCode ? ', ' + item.postalCode : ''}
        </p> 
          <h5>${item.price} EUR</h5>
        </div>
      </div>`;
        }
      },
      annotations: {
        xaxis: [
          {
            x: currentVehicleMileage,
            borderColor: '#775DD0',
            label: {
              style: {
                color: '#000'
              },
              text: context.translatorService.Tranlate('RADAR_MY_VEHICLE', 'Vehiculul meu')
            }
          }
        ]
      }
    };
  }, [currentVehicleMileage, filteredMarketData, handleScatterClick]);

  return (
    <div className="pb-2">
      <h5 className="mt-3 ml-3 mb-0">{title}</h5>
      <ReactApexChart options={options} series={series} type="scatter" height={500} />
    </div>
  );
};

export default ScatterChart;
