import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { withSnackbar } from 'notistack';
import { Card, Tabs, Tab, CardContent, Box } from '@material-ui/core';
import TabPanel from '../../../_shared/Tabs';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import OrganizationCaseTypeTab from './OrganizationCaseTypeTab';
import OrganizationCaseTypeStepTab from './OrganizationCaseTypeStepTab';
import OrganizationCaseTypeStepFormTab from './OrganizationCaseTypeStepFormTab';

interface ICaseState {
  selectedTab: number;
}
class OrganizationNewCaseType extends React.PureComponent<any, ICaseState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = { selectedTab: 0 };

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={this.state.selectedTab}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                onChange={this.onTabChange}
              >
                <Tab
                  className="text-capitalize "
                  label={this.translatorService.Tranlate(
                    'ORGANIZATION_CASE_TAB_CASE_TYPE',
                    'Tip dosar'
                  )}
                />
                <Tab
                  className="text-capitalize "
                  label={this.translatorService.Tranlate('ORGANIZATION_CASE_TAB_STEPS', 'Steps')}
                />
                <Tab
                  className="text-capitalize "
                  label={this.translatorService.Tranlate('ORGANIZATION_CASE_TAB_FORMS', 'Forms')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <TabPanel value={this.state.selectedTab} index={0}>
          <OrganizationCaseTypeTab {...this.props}></OrganizationCaseTypeTab>
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <OrganizationCaseTypeStepTab {...this.props}></OrganizationCaseTypeStepTab>
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={2}>
          <OrganizationCaseTypeStepFormTab {...this.props}></OrganizationCaseTypeStepFormTab>
        </TabPanel>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationNewCaseType as any));
