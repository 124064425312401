import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsState, AppState } from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Grid, Divider } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { CaseCalculationHistory, CaseCalculationPart } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferential } from '../../interfaces/IReferential';

export interface ExternalCaseDetailsCaseTabCompareCalculationsProps {
  calculationHistoryIds: number[];
}

interface ICaseDetailsCaseTabCompareCalculationsState {
  firstCalculation: CaseCalculationHistory;
  secondCalculation: CaseCalculationHistory;
  calculationSectionList: IReferential[];
  calculationParts: CaseCalculationPart[];
  isLoading: boolean;
}

type ICaseDetailsCaseTabCompareCalculationsProps =
  ExternalCaseDetailsCaseTabCompareCalculationsProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabCompareCalculations extends React.PureComponent<
  ICaseDetailsCaseTabCompareCalculationsProps,
  ICaseDetailsCaseTabCompareCalculationsState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;
  state = {
    firstCalculation: {} as CaseCalculationHistory,
    secondCalculation: {} as CaseCalculationHistory,
    calculationSectionList: [],
    calculationParts: [],
    isLoading: true
  } as ICaseDetailsCaseTabCompareCalculationsState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (caseSettings === null || caseSettings === undefined || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (caseSettings === null || caseSettings === undefined || Number.isNaN(caseId)) {
      return;
    }
    const casee = this.props.caseSettingsState.case;

    if (casee === null) {
      return;
    }

    const [
      first,
      second,
      calculationSections,
      caseFirstCalculationParts,
      caseSecondCalculationParts
    ] = await Promise.all([
      this.caseService.GetCaseCalculationHistoryDetails(this.props.calculationHistoryIds[0]),
      this.caseService.GetCaseCalculationHistoryDetails(this.props.calculationHistoryIds[1]),
      this.caseService.GetCalculationSections(),
      this.caseService.GetCaseCalculationParts(this.props.calculationHistoryIds[0]),
      this.caseService.GetCaseCalculationParts(this.props.calculationHistoryIds[1])
    ]);

    this.setState({
      firstCalculation: first,
      secondCalculation: second,
      calculationSectionList: calculationSections,
      calculationParts: caseFirstCalculationParts
        .concat(caseSecondCalculationParts)
        .sort(function (a, b) {
          return a.calculationSectionId - b.calculationSectionId;
        }),
      isLoading: false
    });
  };

  textColor = (x: number | undefined, y: number | undefined, backgroud: boolean) => {
    return backgroud === false
      ? x === undefined
        ? 'text-danger'
        : y === undefined
        ? ''
        : y < x
        ? 'text-success'
        : x < y
        ? 'text-danger'
        : ''
      : x === undefined
      ? 'bg-danger'
      : y === undefined
      ? ''
      : y < x
      ? 'bg-success'
      : x < y
      ? 'bg-danger'
      : '';
  };

  renderCalculationParts = (sectionId: number) => {
    const calculationPartsForSectionId = [...this.state.calculationParts].filter(
      (item) => item.calculationSectionId === sectionId
    );
    const distinctCalculationPartCode = Array.from(
      new Set(calculationPartsForSectionId.map((item) => item.guideNumber))
    );
    return distinctCalculationPartCode.map((item) => {
      const firstCalculationPart = calculationPartsForSectionId.find(
        (calcPart) =>
          calcPart.caseCalculationHistoryId === this.state.firstCalculation.id &&
          calcPart.guideNumber === item
      );
      const secondCalculationPart = calculationPartsForSectionId.find(
        (calcPart) =>
          calcPart.caseCalculationHistoryId === this.state.secondCalculation.id &&
          calcPart.guideNumber === item
      );

      return (
        <div key={item}>
          <Grid container spacing={2} className="my-2">
            <Grid item xs={4} className="m-auto">
              <div className="font-weight-bold text-black text-center">
                {
                  calculationPartsForSectionId.find((calcPart) => calcPart.guideNumber === item)!
                    .name
                }
              </div>
              <div className="font-weight-bold text-black text-center">{item}</div>
            </Grid>
            <Grid item xs={4}>
              {isNullOrUndefined(firstCalculationPart) ? null : (
                <div>
                  <div className="text-black text-center">
                    {this.dynamicPrice(firstCalculationPart).toFixed(2) +
                      ' ' +
                      this.state.firstCalculation.currencyCode}
                  </div>
                  <div className="text-black text-center">{firstCalculationPart.pieceNr}</div>
                  <div className="text-black text-center">
                    {(isNullOrUndefined(this.dynamicDiscount(firstCalculationPart))
                      ? '0'
                      : this.dynamicDiscount(firstCalculationPart).toFixed(2)) + ' %'}
                  </div>
                  <div className="text-black text-center">
                    {isNullOrUndefined(firstCalculationPart)
                      ? ''
                      : (
                          this.dynamicPrice(firstCalculationPart) *
                          firstCalculationPart.pieceNr *
                          (1 - this.dynamicDiscount(firstCalculationPart) / 100)
                        ).toFixed(2) +
                        ' ' +
                        this.state.firstCalculation.currencyCode}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              {isNullOrUndefined(secondCalculationPart) ? null : (
                <div>
                  <div
                    className={
                      'text-black text-center ' +
                      this.textColor(
                        isNullOrUndefined(firstCalculationPart)
                          ? undefined
                          : this.dynamicPrice(firstCalculationPart),
                        this.dynamicPrice(secondCalculationPart),
                        false
                      )
                    }
                  >
                    {isNullOrUndefined(secondCalculationPart)
                      ? ''
                      : this.dynamicPrice(secondCalculationPart).toFixed(2) +
                        ' ' +
                        this.state.secondCalculation.currencyCode}
                  </div>
                  <div
                    className={
                      'text-black text-center ' +
                      this.textColor(
                        isNullOrUndefined(firstCalculationPart)
                          ? undefined
                          : firstCalculationPart.pieceNr,
                        secondCalculationPart.pieceNr,
                        false
                      )
                    }
                  >
                    {secondCalculationPart.pieceNr}
                  </div>
                  <div
                    className={
                      'text-black text-center ' +
                      this.textColor(
                        this.dynamicDiscount(secondCalculationPart),
                        isNullOrUndefined(firstCalculationPart)
                          ? undefined
                          : this.dynamicDiscount(firstCalculationPart),
                        false
                      )
                    }
                  >
                    {(isNullOrUndefined(this.dynamicDiscount(secondCalculationPart))
                      ? '0.00'
                      : this.dynamicDiscount(secondCalculationPart).toFixed(2)) + ' %'}
                  </div>
                  <div
                    className={
                      'text-black text-center ' +
                      this.textColor(
                        isNullOrUndefined(firstCalculationPart)
                          ? undefined
                          : this.dynamicPrice(firstCalculationPart) *
                              firstCalculationPart.pieceNr *
                              (1 - this.dynamicDiscount(firstCalculationPart) / 100),
                        this.dynamicPrice(secondCalculationPart) *
                          secondCalculationPart.pieceNr *
                          (1 - this.dynamicDiscount(secondCalculationPart) / 100),
                        false
                      )
                    }
                  >
                    {isNullOrUndefined(secondCalculationPart)
                      ? ''
                      : (
                          this.dynamicPrice(secondCalculationPart) *
                          secondCalculationPart.pieceNr *
                          (1 - this.dynamicDiscount(secondCalculationPart) / 100)
                        ).toFixed(2) +
                        ' ' +
                        this.state.secondCalculation.currencyCode}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <Divider />
        </div>
      );
    });
  };

  dynamicPrice = (calculation: any) => {
    let price = calculation.originalPrice;
    if (calculation.alternativePartQualityId !== null) {
      price = calculation.alternativePrice;
    }
    return price;
  };

  dynamicDiscount = (calculation: any) => {
    let generalDiscount = calculation.discount;
    if (calculation.alternativePartQualityId !== null) {
      generalDiscount = calculation.alternativePartDiscount;
    }
    return generalDiscount;
  };

  renderCalculation = () => {
    const calculationSections = Array.from(
      new Set(this.state.calculationParts.map((item) => item.calculationSectionId))
    ).sort(function (a, b) {
      return a - b;
    });

    return calculationSections.map((item: number, index: number) => {
      const textColor =
        item === 1
          ? this.textColor(
              this.state.firstCalculation.amountPiecesWithDiscount,
              this.state.secondCalculation.amountPiecesWithDiscount,
              true
            )
          : item === 2
          ? this.textColor(
              this.state.firstCalculation.amountLaborWithDiscount,
              this.state.secondCalculation.amountLaborWithDiscount,
              true
            )
          : item === 3
          ? this.textColor(
              this.state.firstCalculation.amountPaintingMaterialsWithDiscount,
              this.state.secondCalculation.amountPaintingMaterialsWithDiscount,
              true
            )
          : this.textColor(
              this.state.firstCalculation.amountPaintingLaborWithDiscount,
              this.state.secondCalculation.amountPaintingLaborWithDiscount,
              true
            );

      return (
        <div key={index}>
          <Grid
            container
            spacing={2}
            className={index === 0 ? 'bg-primary mb-2' : 'bg-primary my-2'}
          >
            <Grid item xs={4}>
              <div className="text-white text-center ">
                {
                  this.state.calculationSectionList.find((section) => section.id === item)!
                    .displayName
                }
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="text-white text-center">
                {(item === 1
                  ? this.state.firstCalculation.amountPiecesWithDiscount.toFixed(2)
                  : item === 2
                  ? this.state.firstCalculation.amountLaborWithDiscount.toFixed(2)
                  : item === 3
                  ? this.state.firstCalculation.amountPaintingMaterialsWithDiscount.toFixed(2)
                  : this.state.firstCalculation.amountPaintingLaborWithDiscount.toFixed(2)) +
                  ' ' +
                  this.state.firstCalculation.currencyCode}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={'text-white text-center ' + textColor}>
                {(item === 1
                  ? this.state.secondCalculation.amountPiecesWithDiscount.toFixed(2)
                  : item === 2
                  ? this.state.secondCalculation.amountLaborWithDiscount.toFixed(2)
                  : item === 3
                  ? this.state.secondCalculation.amountPaintingMaterialsWithDiscount.toFixed(2)
                  : this.state.secondCalculation.amountPaintingLaborWithDiscount.toFixed(2)) +
                  ' ' +
                  this.state.secondCalculation.currencyCode}
              </div>
            </Grid>
          </Grid>

          {this.renderCalculationParts(item)}
        </div>
      );
    });
  };

  renderTotalCalculation = () => {
    return (
      <div>
        <Grid container spacing={2} className="bg-primary mt-2">
          <Grid item xs={4}>
            <div className="text-white text-center">
              {this.translatorService.Tranlate('CALCULATION_COMPARE_TOTAL', 'Total')}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="text-white text-center">
              {(
                this.state.firstCalculation.amountLaborWithDiscount +
                this.state.firstCalculation.amountPiecesWithDiscount +
                this.state.firstCalculation.amountPaintingLaborWithDiscount +
                this.state.firstCalculation.amountPaintingMaterialsWithDiscount
              ).toFixed(2) +
                ' ' +
                this.state.firstCalculation.currencyCode}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className={
                'text-white text-center ' +
                this.textColor(
                  this.state.firstCalculation.amountLaborWithDiscount +
                    this.state.firstCalculation.amountPiecesWithDiscount +
                    this.state.firstCalculation.amountPaintingLaborWithDiscount +
                    this.state.firstCalculation.amountPaintingMaterialsWithDiscount,
                  this.state.secondCalculation.amountLaborWithDiscount +
                    this.state.secondCalculation.amountPiecesWithDiscount +
                    this.state.secondCalculation.amountPaintingLaborWithDiscount +
                    this.state.secondCalculation.amountPaintingMaterialsWithDiscount,
                  true
                )
              }
            >
              {(
                this.state.secondCalculation.amountLaborWithDiscount +
                this.state.secondCalculation.amountPiecesWithDiscount +
                this.state.secondCalculation.amountPaintingLaborWithDiscount +
                this.state.secondCalculation.amountPaintingMaterialsWithDiscount
              ).toFixed(2) +
                ' ' +
                this.state.secondCalculation.currencyCode}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div>
            {this.renderCalculation()}
            {this.renderTotalCalculation()}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabCompareCalculationsProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabCompareCalculations as any));
