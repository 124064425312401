import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Tooltip,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { ReferentialCode } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import * as R from 'ramda';
import { MaintenanceProducer } from '../../../interfaces/Case';

interface IMaintenanceProducerComponentState {
  newMaintenanceProducer: MaintenanceProducer;
  maintenancePackageList: IReferential[];
  maintenanceProducerList: MaintenanceProducer[];
  isLoading: boolean;
  isDialogOpen: boolean;
  newMaintenanceProducerId: number | null;
  countryList: IReferential[];
}
type IMaintenanceProducerComponentProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;
class MaintenanceProducerComponent extends React.PureComponent<
  IMaintenanceProducerComponentProps,
  IMaintenanceProducerComponentState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  static contextType = ApplicationContext;
  private newMaintenanceProducer = {
    id: 0,
    countryId: 0,
    country: null,
    countryCode: '',
    maintenancePackageId: 0,
    maintenancePackage: null,
    producer: ''
  } as MaintenanceProducer;

  state = {
    maintenanceProducerList: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    newMaintenanceProducer: this.newMaintenanceProducer,
    maintenancePackageList: [],
    newMaintenanceProducerId: null,
    countryList: [] as IReferential[]
  } as IMaintenanceProducerComponentState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadMaintenanceProducerList();
      }
    );
  }

  loadMaintenanceProducerList = async () => {
    const maintenanceProducerList = await this.caseService.MaintenanceProducerList();
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.MaintenancePackage
    );
    const countryRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Country
    );
    const [maintenancePackageList, countryList] = await Promise.all([
      await this.appReferentialService.Get(ref!.baseUrl),
      this.appReferentialService.Get(countryRef!.baseUrl)
    ]);

    maintenanceProducerList.forEach(item => {
      const maintenancePackage = maintenancePackageList.find(packageItem => !R.isNil(item.maintenancePackageId) && packageItem.id === item.maintenancePackageId);
      const country = countryList.find(countryItem => !R.isNil(item.countryId) && countryItem.id === item.countryId);
      item.country = R.isNil(country) ? null : country;
      item.maintenancePackage = R.isNil(maintenancePackage) ? null : maintenancePackage;
    });  

    this.setState({
      maintenanceProducerList: maintenanceProducerList,
      isLoading: false,
      maintenancePackageList: maintenancePackageList,
      newMaintenanceProducer: { ...this.newMaintenanceProducer },
      countryList: countryList
    });
  };
  private handleCountryChange = (newValue: IReferential | null) => {
    this.setState({
      newMaintenanceProducer: {
        ...this.state.newMaintenanceProducer,
        country: newValue,
        countryId: newValue !== null ? newValue!.id : 0,
        countryCode: newValue !== null ? newValue!.code ?? '' : ''
      }
    });
  };
  private handleMaintenancePackageChange = (newValue: IReferential | null) => {
    this.setState({
      newMaintenanceProducer: {
        ...this.state.newMaintenanceProducer,
        maintenancePackage: newValue,
        maintenancePackageId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  private handleProducerChange = (value: string) => {
    this.setState({
      newMaintenanceProducer: {
        ...this.state.newMaintenanceProducer,
        producer: value
      }
    });
  };
  

  public saveMaintenanceProducer = async () => {
    if (this.state.newMaintenanceProducerId === null) {
      try {
        await this.caseService.AddMaintenanceProducer(this.state.newMaintenanceProducer);
        await this.loadMaintenanceProducerList();
        this.closeDialog();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    } else {
      try {
        await this.caseService.UpdateMaintenanceProducer(this.state.newMaintenanceProducer);
        await this.loadMaintenanceProducerList();
        this.closeDialog();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };
  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveMaintenanceProducer} instantValidate={true}>
        <Autocomplete
          id="country"
          className="m-2"
          options={this.state.countryList}
          value={this.state.newMaintenanceProducer.country}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleCountryChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="country"
              value={this.state.newMaintenanceProducer.country || ''}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_COUNTRY_LABEL',
                'Tara'
              )}
              fullWidth
              validators={[]}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        />
        <Autocomplete
          id="mainenancePackage"
          className="m-2"
          options={this.state.maintenancePackageList}
          value={this.state.newMaintenanceProducer.maintenancePackage}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleMaintenancePackageChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="mainenancePackage"
              value={this.state.newMaintenanceProducer.maintenancePackage}
              label={this.translatorService.Tranlate(
                'ORGANIZATIONS_TAB_MAINTENANCE_PACKAGE',
                'Pachet Mentenanta'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        />
        <TextValidator
          fullWidth
          name="producer"
          className="m-2"
          id="producer"
          value={this.state.newMaintenanceProducer.producer || ''}
          onChange={(e: any) => this.handleProducerChange(e.target.value)}
          label={this.translatorService.Tranlate(
            'CALCULATION_DETAILS_PRODUCER_HEADER',
            'Producator'
          )}
          validators={['required']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
        />

        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }
  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'country',
        label: this.translatorService.Tranlate('ORGANIZATIONS_COUNTRY_HEADER', 'Tara'),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            debugger;
            return a.name!.localeCompare(b.name!);
          },
          customBodyRender: (value: IReferential) => {
            return value ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'maintenancePackage',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_TAB_MAINTENANCE_PACKAGE',
          'Pachet Mentenanta'
        ),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            return a.name!.localeCompare(b.displayName!);
          },
          customBodyRender: (value: IReferential) => {
            return value ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'producer',
        label: this.translatorService.Tranlate(
          'CALCULATION_DETAILS_PRODUCER_HEADER',
          'Producator'
        ) 
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newMaintenanceProducerPopup(tableMeta.rowData[0])}
                >
                    <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteMaintenanceProducer(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];
    return columns;
  };

  deleteMaintenanceProducer = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];
      await this.caseService.RemoveMaintenanceProducer(id);
      await this.loadMaintenanceProducerList();
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  newMaintenanceProducerPopup = async (maintenanceProducerId: number | null) => {
    let newMaintenanceProducer: MaintenanceProducer;
    if (maintenanceProducerId == null) {
      newMaintenanceProducer = { ...this.state.newMaintenanceProducer } as MaintenanceProducer;
    } else {
      newMaintenanceProducer = this.state.maintenanceProducerList.find((item) => item.id === maintenanceProducerId)!;
    }
    this.setState({
      isDialogOpen: true,
      newMaintenanceProducer: newMaintenanceProducer,
      newMaintenanceProducerId: maintenanceProducerId
    });
  };

  closeDialog = async () => {
    this.setState(
        { 
          isDialogOpen: false, 
          newMaintenanceProducer: this.newMaintenanceProducer 
        }
      );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      // responsive: "stacked",
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newMaintenanceProducerPopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };
    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.maintenanceProducerList}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullWidth={true}
            maxWidth={'sm'}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography variant="h4">
                  {!this.state.newMaintenanceProducerId ? 
                    this.translatorService.Tranlate(
                        'MAINTENANCE_PRODUCER_ADD_MODAL_TITLE',
                        'Adauga Producator Mentenanta'
                      ) : this.translatorService.Tranlate(
                        'MAINTENANCE_PRODUCER_UPDATE_MODAL_TITLE',
                        'Actualizeaza Producator Mentenanta'
                      )}               
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>{this.renderAddForm()}</DialogContent>
          </Dialog>) : null}
      </Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(MaintenanceProducerComponent as any));