import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  AppState,
  CaseSettingsActionCreators,
  CaseSettingsState,
  VehicleActionCreators,
  VehicleState
} from '../../store';
import * as R from 'ramda';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Box, Tabs, Tab, Button, Typography, Modal, Dialog, DialogContent, DialogActions, DialogContentText,
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { RouteComponentProps } from 'react-router';
import { PageTitle } from '../../layout-components';
import { Card } from 'reactstrap';
import TabPanel from '../_shared/Tabs';
import CaseDetailsCaseTab from './CaseDetailsCaseTab';
import CaseDetailsCommentsTab from './CaseDetailsCommentsTab';
import CaseDetailsHistoryTab from './CaseDetailsHistoryTab';
import { isNullOrUndefined } from 'util';
import { ScaleLoader } from 'react-spinners';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { setLastOfferRejected } from '../agenda/agenda.requests';
import { CaseCalculationHistory } from '../../interfaces/Case';
import _ from 'lodash';
import { CalculationTypeCode } from '../../helpers/Constants';
import { getDay } from '../../utils/appointments';
import moment from 'moment';
import { Utils } from '../../helpers/Utils';

interface ICaseDetailsState {
  selectedTab: number;
  rejectedOfferModal: boolean;
  caseCalculations: CaseCalculationHistory[]
}

type ICaseDetailsProps = {
  caseSettingsState: CaseSettingsState;
  vehicleState: VehicleState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  typeof VehicleActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetails extends React.PureComponent<ICaseDetailsProps, ICaseDetailsState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;
  state = {
    selectedTab: 0,
    rejectedOfferModal: false,
    caseCalculations: []
  } as ICaseDetailsState;

  public async componentDidMount() {
    const caseId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(caseId)) {
      return;
    }

    await this.props.SetCaseSettings(caseId);
    await this.props.setVehicleObject(null);
    let resultCaseCalculations: CaseCalculationHistory[] = [];
    if (this.props.caseSettingsState.caseSettings?.workflow?.isCarfixIntegration) {
      const res = await this.caseService.GetCaseCalculationHistories(caseId)
      const caseCalculationCopy = _.sortBy(_.cloneDeep(res), 'date').reverse();
      resultCaseCalculations = _.cloneDeep(_.filter(caseCalculationCopy, function (caseCalculation) {
        return caseCalculation.calculationType.code == CalculationTypeCode.ALT;
      }));
    }
    if (!isNullOrUndefined(this.props.caseSettingsState.case!.vehicleId)) {
      await this.props.setVehicle(this.props.caseSettingsState.case!.vehicleId);
    }

    let selectedTab = 0;
    if (this.props.match.url.toLowerCase() === '/cases/' + caseId + '/comments') {
      selectedTab = 1;
    }

    this.setState({ selectedTab: selectedTab, rejectedOfferModal: this?.props?.caseSettingsState?.case?.lastOfferRejected || false, caseCalculations: resultCaseCalculations });
  }

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  private getTitleDescription(): string | null {
    const { make, model, version, plateNumber } = this.props.vehicleState?.vehicle || {};
    const { brand, displayName } = this.props.caseSettingsState.case?.caseVehicle?.model || {};
    const { plateNumber: casePlateNumber } = this.props.caseSettingsState.case?.caseVehicle || {};

    const carMake = make?.name || brand || '';
    const carModel = model?.name || '';
    const carVersion = version || displayName || '';
    const carPlateNumber = plateNumber || casePlateNumber || '';

    return carMake && carVersion ? `${carMake} ${carModel} ${carVersion} ${carPlateNumber}` : null;
  }

  private getStatusDescription(): string | null {
    const { caseStatus } = this.props.caseSettingsState.case?.caseStatus || {};
    return caseStatus ? `${caseStatus.displayName}` : null;
  }

  renderOfferPriceAndDate = (calculation: CaseCalculationHistory) => {
    if (!calculation) {
      return ''
    }
    let result = '0';
    if (!R.isNil(calculation.amount)) {
      result = calculation.amount?.toFixed(2);
    }
    const price = Utils.countryNumberFormat(result, this.props.appState.appUser?.organization?.country ?? null) + ' ';
    const date = moment.utc(calculation.date).local().format(this.props.appState.longDateFormat);
    return `${price} ${calculation.currencyCode} (${this.translatorService.Tranlate('GENERATE_IN', 'generata in')} ${date})`
  }

  renderLastOfferApproved = () => {
    const indexOfLastOfferApproved = this.state.caseCalculations?.findIndex((calculation: CaseCalculationHistory) => calculation.hasBeenApproved);
    if (indexOfLastOfferApproved !== -1) {
      return <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
        {`${this.translatorService.Tranlate(
          'LAST_OFFER_APPROVED',
          'Reparatia va continua cu ultima calculatie acceptata, in valoare de'
        )} ${this.state.caseCalculations[indexOfLastOfferApproved] ? this.renderOfferPriceAndDate(this.state.caseCalculations[indexOfLastOfferApproved]) : ''}`}

      </DialogContentText>
    }
    return <></>
  }

  renderlastOfferRejectedModal = () => {
    return <Modal open={this.state.rejectedOfferModal} onClose={() => this.setState({ rejectedOfferModal: false })}>
      <Dialog open={this.state.rejectedOfferModal} onClose={() => this.setState({ rejectedOfferModal: false })}>
        <DialogContent>
          <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            {`${this.translatorService.Tranlate(
              'LAST_OFFER_REJECTED',
              'Clientul a REFUZAT oferta cu pretul'
            )} ${this.state?.caseCalculations?.length > 0 && !this.state.caseCalculations[0]?.hasBeenApproved ? this.renderOfferPriceAndDate(this.state.caseCalculations[0]) : ''}`}
          </DialogContentText>
          {this.renderLastOfferApproved()}
        </DialogContent>
        <DialogActions>
          <Button className="m-2" variant="contained" color="primary" onClick={async () => {
            if (this.props.match.params.id) {
              await setLastOfferRejected(this.props.match.params.id, false);
            }
            this.setState({ rejectedOfferModal: false })
          }
          }>
            {this.translatorService.Tranlate('CASE_CALCULATION_FORM_CLIENT_COMMENT_MODAL_YES', 'Am inteles')}
          </Button>
        </DialogActions>
      </Dialog>
    </Modal >
  }

  renderLastStatus = () => {
    const text = this.getStatusDescription();
    return <Box style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'space-between', height: '90%' }}>
      <div>
        <Box
          style={{
            padding: '10px 10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#81C784'
          }}
        >
          <Typography variant="h6" style={{ color: 'white' }}>
            {text}
          </Typography>
        </Box>
      </div>
    </Box>
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.caseService = (this.context as AppContext).caseService;
    const description = this.getTitleDescription();

    if (isNullOrUndefined(this.props.caseSettingsState.caseSettings)) {
      return (
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.props.caseSettingsState.isLoading} />
        </div>
      );
    }

    return (
      <Fragment>
        <PageTitle
          titleHeading={
            this.translatorService.Tranlate('CASES_CASE_TAB', 'Dosar') +
            ' ' +
            this.props.caseSettingsState.case!.id
          }
          titleDescription={description}
          actionButton={
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
              <Typography variant="h5" style={{ marginRight: '10px', color: 'black' }}>
                {this.translatorService.Tranlate('CASE_STATUS_LABEL', 'Status: ')}
              </Typography>
              {this.renderLastStatus()}
            </Box>
          }
        />
        {this?.state.rejectedOfferModal ? this.renderlastOfferRejectedModal() : null}
        {!this.props.caseSettingsState.isLoading ? (
          <Box>
            <Card>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    height: '6px'
                  }
                }}
                value={this.state.selectedTab}
                indicatorColor="primary"
                textColor="secondary"
                variant="fullWidth"
                centered={true}
                color="primary"
                onChange={this.onTabChange}
                aria-label="disabled tabs example"
              >
                <Tab label={this.translatorService.Tranlate('CASES_CASE_TAB', 'Dosar')} />
                {!this.props.caseSettingsState.caseSettings.workflow.hideComments && (
                  <Tab label={this.translatorService.Tranlate('CASES_COMMENTS_TAB', 'Comentarii')} />
                )}
                <Tab label={this.translatorService.Tranlate('CASES_HISTORY_TAB', 'History')} />
              </Tabs>
            </Card>
          </Box>
        ) : null}
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.props.caseSettingsState.isLoading} />
        </div>
        {!this.props.caseSettingsState.isLoading && !this.props?.vehicleState?.vehicle?.loading ? (
          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <CaseDetailsCaseTab {...this.props} />
            </TabPanel>
            {!this.props.caseSettingsState.caseSettings.workflow.hideComments && (
              <TabPanel value={this.state.selectedTab} index={1}>
                <CaseDetailsCommentsTab {...this.props} />
              </TabPanel>
            )}
            <TabPanel value={this.state.selectedTab} index={this.props.caseSettingsState.caseSettings.workflow.hideComments ? 1 : 2}>
              <CaseDetailsHistoryTab {...this.props} />
            </TabPanel>
          </div>
        ) : null}

      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => {
    return { caseSettingsState: state.caseSettings, vehicleState: state.vehicle, appState: state.app };
  },
  { ...CaseSettingsActionCreators, ...VehicleActionCreators }
)(withSnackbar(CaseDetails as any));
