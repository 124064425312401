import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { OrganizationCaseTypeStepForm } from '../../../../interfaces/Organization';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import { Box, Button, Card, CardContent, Grid } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import { RouteComponentProps } from 'react-router';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { ControlledEditor } from '@monaco-editor/react';
import { WrapperSimple } from '../../../../layout-components';
import JSONSchemaForm from '@rjsf/material-ui';

interface IOrganizationFormDesignState {
  organizationForm: OrganizationCaseTypeStepForm;
  formData: any;
  isLoading: boolean;
}
export interface ExternalOrganizationFormDesignProps {
  organizationFormId: number;
}

type IOrganizationFormDesignProps = ExternalOrganizationFormDesignProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string }>;

class EditOrganizationFormDesign extends React.PureComponent<
  IOrganizationFormDesignProps,
  IOrganizationFormDesignState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    organizationForm: {},
    formData: {},
    isLoading: false
  } as IOrganizationFormDesignState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationFormDesign();
      }
    );
  }

  loadOrganizationFormDesign = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    let organizationForm = {} as OrganizationCaseTypeStepForm;
    if (this.props.organizationFormId !== 0) {
      organizationForm = await this.organizationService.GetOrganizationCaseTypeStepForm(
        this.props.organizationFormId
      );
    }

    // organizationForm.json2 = JSON.stringify(json2, null, 2);
    this.setState({
      organizationForm: organizationForm,
      isLoading: false
    });
  };
  handleEditorDidMountJsonSchema = (
    ev: monacoEditor.editor.IModelContentChangedEvent,
    value: string | undefined
  ) => {
    const orgFormDesign = { ...this.state.organizationForm };
    //= value ? value : "";
    const BAD_WORD = 'eval';
    const WORNING_MESSAGE = " <- hey man, what's this?";
    orgFormDesign.json1 =
      value !== undefined && value.includes(BAD_WORD) && !value.includes(WORNING_MESSAGE)
        ? value.replace(BAD_WORD, BAD_WORD + WORNING_MESSAGE)
        : value!.includes(WORNING_MESSAGE) && !value!.includes(BAD_WORD)
        ? value!.replace(WORNING_MESSAGE, '')
        : value!;

    this.setState({ organizationForm: orgFormDesign });
  };
  handleEditorDidMountUISchema = (
    ev: monacoEditor.editor.IModelContentChangedEvent,
    value: string | undefined
  ) => {
    const orgFormDesign = { ...this.state.organizationForm };
    orgFormDesign.json2 = value ? value : '';
    this.setState({ organizationForm: orgFormDesign });
  };
  // handleChandeFormData = (e: IChangeEvent<any>) => {
  //     // const formData = value ? value : "";
  //     debugger
  //     this.setState({ formData: e.formData })
  // }
  getSchema = () => {
    let json = {};

    if (Object.keys(this.state.organizationForm).length !== 0) {
      try {
        json = JSON.parse(this.state.organizationForm.json1);
      } catch (error) {}
    }
    return json;
  };

  getUISchema = () => {
    let json = {};

    if (Object.keys(this.state.organizationForm).length !== 0) {
      try {
        json = JSON.parse(this.state.organizationForm.json2);
      } catch (error) {}
    }
    return json;
  };

  save = async () => {
    try {
      await this.organizationService.UpdateOrganizationCaseTypeStepForm(
        this.state.organizationForm
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <Grid container spacing={2}>
              <Grid item sm={12} className="text-right">
                <Button className="m-2" variant="contained" color="primary" onClick={this.save}>
                  {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
                </Button>
              </Grid>
              <Grid item sm={6}>
                <WrapperSimple
                  sectionHeading={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_DESIGN_JSON_SCHEMA',
                    'Schema JSON'
                  )}
                >
                  <ControlledEditor
                    height="50vh"
                    language="json"
                    value={this.state.organizationForm.json1}
                    onChange={this.handleEditorDidMountJsonSchema}
                    options={
                      {
                        selectOnLineNumber: true,
                        lineNumbersMinChars: 0,
                        glyphMargin: false,
                        readOnly: false,
                        minimap: {
                          enabled: false
                        },
                        hover: {
                          enabled: false
                        }
                      } as monacoEditor.editor.IDiffEditorConstructionOptions
                    }
                  />
                </WrapperSimple>
                <Grid container>
                  <Grid item sm={12}>
                    <WrapperSimple
                      sectionHeading={this.translatorService.Tranlate(
                        'EDIT_ORGANIZATION_FORM_DESIGN_UI_SCHEMA',
                        'Schema UI'
                      )}
                    >
                      <ControlledEditor
                        height="50vh"
                        language="json"
                        value={this.state.organizationForm.json2}
                        onChange={this.handleEditorDidMountUISchema}
                        options={
                          {
                            selectOnLineNumber: true,
                            lineNumbersMinChars: 0,
                            glyphMargin: false,
                            readOnly: false,
                            minimap: {
                              enabled: false
                            },
                            hover: {
                              enabled: false
                            }
                          } as monacoEditor.editor.IDiffEditorConstructionOptions
                        }
                      />
                    </WrapperSimple>
                  </Grid>
                  {/* <Grid item sm={6}>
                                        <WrapperSimple sectionHeading={this.translatorService.Tranlate("EDIT_ORGANIZATION_FORM_DESIGN_FORM_DATA", "Form Data")}  >
                                            <Editor
                                                height="50vh"
                                                language="json"
                                                value={JSON.stringify(this.state.formData, null, 2)}
                                                //onChange={this.handleEditorDidMountFormData}
                                                options={{
                                                    selectOnLineNumber: true,
                                                    lineNumbersMinChars: 0,
                                                    glyphMargin: false,
                                                    readOnly: false,
                                                    minimap: {
                                                        enabled: false
                                                    },
                                                    hover: {
                                                        enabled: false
                                                    }
                                                } as monacoEditor.editor.IDiffEditorConstructionOptions}
                                            />
                                        </WrapperSimple>
                                    </Grid> */}
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Card>
                  <CardContent>
                    <JSONSchemaForm
                      schema={this.getSchema()}
                      uiSchema={this.getUISchema()}
                      showErrorList={false}
                      liveValidate={true}
                    />
                    {/* formData={this.state.formData} onChange={(e) => this.handleChandeFormData(e)} */}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item sm={12} className="text-right">
                <Button className="m-2" variant="contained" color="primary" onClick={this.save}>
                  {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalOrganizationFormDesignProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null,
  mergeProps
)(withSnackbar(EditOrganizationFormDesign as any));
