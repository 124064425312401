import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { OrganizationInvoiceRange} from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, Card, CardContent, Tabs, Tab, IconButton, Dialog, DialogTitle, Grid, Typography, DialogContent, Tooltip } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined } from 'util';
import _ from 'lodash';
import { InvoiceRangeCodeEnum, RefConstants } from '../../../helpers/Constants';
import { IReferential } from '../../../interfaces/IReferential';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';

interface IOrganizationInvoiceRangesState {
  organizationInvoiceRanges: OrganizationInvoiceRange[];
  isLoading: boolean;
  invoiceRangeTypes: IReferential[];
  invoiceRangeStatuses: IReferential[];
  organizationId: number;
  newOrganizationInvoiceRange: OrganizationInvoiceRange;
  newOrganizationInvoiceRangeId: number | null;
  isDialogOpen: boolean;
}

type IOrganizationInvoiceRangesProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string;}>;

class OrganizationInvoiceRanges extends React.PureComponent<IOrganizationInvoiceRangesProps, IOrganizationInvoiceRangesState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  private newOrganizationInvoiceRange = {
    id: 0,
    organizationId: 0,
    invoiceRangeTypeId: null,
    invoiceRangeType:  null,
    firstSeries: null,
    startingNumber: null,
    currentNumber: null,
    endingNumber: null,
    lastSeries: null,
    invoiceRangeStatusId: null,
    invoiceRangeStatus: null
  } as unknown as OrganizationInvoiceRange;

  state = {
    organizationInvoiceRanges: [],
    isLoading: false,
    invoiceRangeTypes: [],
    invoiceRangeStatuses: [],
    organizationId: 0,
    newOrganizationInvoiceRange: this.newOrganizationInvoiceRange,
    newOrganizationInvoiceRangeId: null,
    isDialogOpen: false,
  } as IOrganizationInvoiceRangesState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationInvoiceRanges();
      }
    );
  }

  loadOrganizationInvoiceRanges = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }
    const { invoiceRangeTypeRef, invoiceRangeStatusRef } = RefConstants;

    const [invoiceRangeTypes, invoiceRangeStatuses, organizationInvoiceRangesList] = await Promise.all([
      this.appReferentialService.Get(invoiceRangeTypeRef!.baseUrl),
      this.appReferentialService.Get(invoiceRangeStatusRef!.baseUrl),
      this.organizationService.GetOrganizationInvoiceRanges(hoId),
    ]);

    organizationInvoiceRangesList.forEach((ojt) => {
      const invoiceRangeType = invoiceRangeTypes.find((item) => item.id === ojt.invoiceRangeTypeId);
      const invoiceRangeStatus = invoiceRangeStatuses.find((item) => item.id === ojt.invoiceRangeStatusId);

      ojt.invoiceRangeType = isNullOrUndefined(invoiceRangeType) ? null : invoiceRangeType;
      ojt.invoiceRangeStatus = isNullOrUndefined(invoiceRangeStatus) ? null : invoiceRangeStatus;
    });

    this.setState({
      organizationInvoiceRanges: organizationInvoiceRangesList,
      invoiceRangeTypes: invoiceRangeTypes.filter((item) => item!.code === InvoiceRangeCodeEnum.FACTURA_SERVICE.toString()),
      invoiceRangeStatuses: invoiceRangeStatuses,
      isLoading: false,
      organizationId: hoId
    });
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  private handleOrganizationInvoiceRangeTypeChange = (newValue: IReferential | null) => {
    this.setState({
      newOrganizationInvoiceRange: {
        ...this.state.newOrganizationInvoiceRange,
        invoiceRangeType: newValue,
        invoiceRangeTypeId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  
  private handleOrganizationInvoiceRangeStatusChange = (newValue: IReferential | null) => {
    this.setState({
      newOrganizationInvoiceRange: {
        ...this.state.newOrganizationInvoiceRange,
        invoiceRangeStatus: newValue,
        invoiceRangeStatusId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  public saveOrganizationInvoiceRange = async () => {
    if (!R.isNil(this.state.newOrganizationInvoiceRange.startingNumber) && !R.isNil(this.state.newOrganizationInvoiceRange.endingNumber)) {
      if (this.state.newOrganizationInvoiceRange.endingNumber < this.state.newOrganizationInvoiceRange.startingNumber) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('INVOICE_RANGE_ENDING_NUMBER_ERROR', 'Numarul de sfarsit trebuie sa fie mai mare decat numarul de inceput!'), {
          variant: 'error'
        });
        return;
      }
    }

    if (this.state.newOrganizationInvoiceRangeId === null) {
      try {
        await this.organizationService.AddOrganizationInvoiceRange({
          ...this.state.newOrganizationInvoiceRange,
          organizationId: this.state.organizationId
        });

        await this.loadOrganizationInvoiceRanges();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    } else {
      try {

        await this.organizationService.UpdateOrganizationInvoiceRange({
          ...this.state.newOrganizationInvoiceRange,
          organizationId: this.state.organizationId
        });

        await this.loadOrganizationInvoiceRanges();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };
  
  
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };
  
  onChange =
  (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => {
    isNumber
      ? this.setState({
        newOrganizationInvoiceRange: {
            ...this.state.newOrganizationInvoiceRange,
            [fieldName]: parseFloat(newValue || e.target.value)
          }
        })
      : this.setState({
        newOrganizationInvoiceRange: {
            ...this.state.newOrganizationInvoiceRange,
            [fieldName]: newValue || e.target.value
          }
        });
  };

  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveOrganizationInvoiceRange} instantValidate={true}>
        <Autocomplete
          id="invoiceRangeType"
          className="m-2"
          options={this.state.invoiceRangeTypes}
          value={this.state.newOrganizationInvoiceRange.invoiceRangeType}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleOrganizationInvoiceRangeTypeChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="invoiceRangeType"
              value={this.state.newOrganizationInvoiceRange.invoiceRangeType}
              label={this.translatorService.Tranlate(
                'ORGANIZATION_INVOICE_RANGE_TYPE',
                'Tip'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        /> 

        <TextValidator
          fullWidth
          id="firstSeries"
          className="m-2 pr-3 mt-0"
          name="firstSeries"
          placeholder={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_FIRST_SERIES', 'Serie 1')}
          value={this.state.newOrganizationInvoiceRange.firstSeries}
          onChange={(e: any) =>
            this.onChange('firstSeries', false)(e)
          }
          validators={['required']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
          label={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_FIRST_SERIES', 'Serie 1')}
        />

        <TextValidator
          fullWidth
          id="startingNumber"
          className="m-2 pr-3"
          name="startingNumber"
          placeholder={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_STARTING_NUMBER', 'Numar inceput')}
          value={this.state.newOrganizationInvoiceRange.startingNumber || '' }
          onChange={(e: any) => this.onChange('startingNumber', true)(e)}
          validators={['required']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
          label={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_STARTING_NUMBER', 'Numar inceput')}
          InputProps={{
            inputComponent: this.NumberFormatCustom
          }}
        />
        <TextValidator
          fullWidth
          id="endingNumber"
          className="m-2 pr-3"
          name="endingNumber"
          placeholder={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_ENDING_NUMBER', 'Numar sfarsit')}
          value={this.state.newOrganizationInvoiceRange.endingNumber || ''}
          onChange={(e: any) => this.onChange('endingNumber', true)(e)}
          validators={['required']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
          label={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_ENDING_NUMBER', 'Numar sfarsit')}
          InputProps={{
            inputComponent: this.NumberFormatCustom
          }}
          error={!R.isNil(this.state.newOrganizationInvoiceRange.startingNumber) && !R.isNil(this.state.newOrganizationInvoiceRange.endingNumber) ? 
            (this.state.newOrganizationInvoiceRange.endingNumber < this.state.newOrganizationInvoiceRange.startingNumber ? true : false) : false}
          helperText={
            !R.isNil(this.state.newOrganizationInvoiceRange.startingNumber) && !R.isNil(this.state.newOrganizationInvoiceRange.endingNumber) ? 
            (this.state.newOrganizationInvoiceRange.endingNumber < this.state.newOrganizationInvoiceRange.startingNumber ? 
              this.translatorService.Tranlate(
              'ORGANIZATION_INVOICE_RANGE_ENDING_NUMBER_ERROR',
              'Numarul de sfarsit trebuie sa fie mai mare decat numarul de inceput!'
            ) : '') : ''
            }
        />

        <TextValidator
          fullWidth
          id="lastSeries"
          className="m-2 pr-3 mt-0"
          name="lastSeries"
          placeholder={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_LAST_SERIES', 'Serie 2')}
          value={this.state.newOrganizationInvoiceRange.lastSeries}
          onChange={(e: any) => this.onChange('lastSeries', false)(e)}
          label={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_LAST_SERIES', 'Serie 2')}
        />

        <Autocomplete
          id="invoicRangeStatus"
          className="m-2"
          options={this.state.invoiceRangeStatuses}
          value={this.state.newOrganizationInvoiceRange.invoiceRangeStatus}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleOrganizationInvoiceRangeStatusChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="invoicRangeStatus"
              value={this.state.newOrganizationInvoiceRange.invoiceRangeStatus}
              label={this.translatorService.Tranlate(
                'ORGANIZATION_INVOICE_RANGE_STATUS',
                'Status'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        /> 

        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        label: this.translatorService.Tranlate('INVOICE_RANGE_NR', 'NR'),
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'invoiceRangeType',
        label: this.translatorService.Tranlate(
          'ORGANIZATION_INVOICE_RANGE_TYPE',
          'Tip'
        ),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            return a.displayName!.localeCompare(b.displayName!);
          },
          customBodyRender: (value: IReferential) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'firstSeries',
        label: this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_FIRST_SERIES', 'Serie 1'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'startingNumber',
        label: this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_STARTING_NUMBER', 'Numar inceput'),
        options: {
          filter: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'currentNumber',
        label: this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_CURRENT_NUMBER', 'Numar curent'),
        options: {
          filter: false,
          customBodyRender: (value: number | null, tableMeta: MUIDataTableMeta) => {
            return R.isNil(value) ? '' : value;
          }
        }
      },
      {
        name: 'endingNumber',
        label: this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_ENDING_NUMBER', 'Numar sfarsit'),
        options: {
          filter: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'lastSeries',
        label: this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE_LAST_SERIES', 'Serie 2'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'invoiceRangeStatus',
        label: this.translatorService.Tranlate(
          'ORGANIZATION_INVOICE_RANGE_STATUS',
          'Status'
        ),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            return a.displayName!.localeCompare(b.displayName!);
          },
          customBodyRender: (value: IReferential) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            const invoiceRange = this.state.organizationInvoiceRanges.find((item) => item.id === tableMeta.rowData[0]);

            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newOrganizationInvoiceRatePopup(tableMeta.rowData[0])}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteOrganizationInvoiceRate(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];

    return columns;
  };

  deleteOrganizationInvoiceRate = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.organizationService.RemoveOrganizationInvoiceRange(id);

      await this.loadOrganizationInvoiceRanges();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  newOrganizationInvoiceRatePopup = async (organizationInvoiceRangeId: number | null) => {
    let newOrganizationInvoiceRange: OrganizationInvoiceRange;
    if (organizationInvoiceRangeId == null) {
      newOrganizationInvoiceRange = { ...this.state.newOrganizationInvoiceRange } as OrganizationInvoiceRange;
    } else {
      newOrganizationInvoiceRange = this.state.organizationInvoiceRanges.find((item) => item.id === organizationInvoiceRangeId)!;
    }
    this.setState({
      isDialogOpen: true,
      newOrganizationInvoiceRange: newOrganizationInvoiceRange,
      newOrganizationInvoiceRangeId: organizationInvoiceRangeId
    });
  };

  closeDialog = async () => {
    this.setState(
      { 
        isDialogOpen: false, 
        newOrganizationInvoiceRange: this.newOrganizationInvoiceRange ,
        newOrganizationInvoiceRangeId: null
      }
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newOrganizationInvoiceRatePopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('ORGANIZATION_INVOICE_RANGE', 'Plaja facturi')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizationInvoiceRanges}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={false}
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography className="MuiTypography-h4">
                  {!this.state.newOrganizationInvoiceRangeId ? 
                  this.translatorService.Tranlate(
                      'ORGANIZATION_INVOICE_RANGE_NEW_MODAL_TITLE',
                      'Adauga plaja facturi'
                    ) : this.translatorService.Tranlate(
                      'ORGANIZATION_INVOICE_RANGE_UPDATE_MODAL_TITLE',
                      'Actualizeaza plaja facturi'
                    )}       
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderAddForm()}
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationInvoiceRanges as any));
