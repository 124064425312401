import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import {
  Box,
  Button,
  Card,
  CardContent,
  Tabs,
  Tab,
  Switch,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { WorkflowPartner, WorkflowTariff } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { Tariff } from '../../../interfaces/Tariff';
import { ITariffService } from '../../../services/Interfaces/ITariffService';
import EditTariff from '../tariffs/EditTariff';
import CloseIcon from '@material-ui/icons/Close';
import { isNullOrUndefined } from 'util';

interface IWorkflowTariffsState {
  workflowTariffs: WorkflowTariff[];
  isLoading: boolean;
  workflowPartnerList: WorkflowPartner[];
  selectedWorkflowPartner: WorkflowPartner | null;
  tariffList: Tariff[];
  selectedTariff: Tariff | null;
  workflowId: number;
  isOpenDialog: boolean;
  selectedTariffId: number;
}

type IWorkflowTariffsProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowTariffs extends React.PureComponent<IWorkflowTariffsProps, IWorkflowTariffsState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private tariffService!: ITariffService;

  static contextType = ApplicationContext;

  state = {
    workflowTariffs: [],
    isLoading: false,
    workflowPartnerList: [],
    selectedWorkflowPartner: null,
    tariffList: [],
    selectedTariff: null,
    workflowId: 0,
    isOpenDialog: false,
    selectedTariffId: 0
  } as IWorkflowTariffsState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowTariffs();
      }
    );
  }

  loadWorkflowTariffs = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const [tariffList, workflowTariffs, workflowPartnerList] = await Promise.all([
      this.tariffService.GetTariffs(hoId),
      this.workflowService.GetWorkflowTariffs(workflowId),
      this.workflowService.GetWorkflowPartners(workflowId)
    ]);

    const orgPartners = await this.organizationService.GetOrganizationsByIds(
      workflowPartnerList.map((item) => item.partnerId)
    );

    workflowPartnerList.forEach((wpItem) => {
      const part = orgPartners.find((item) => item.id === wpItem.partnerId);
      wpItem.partner = isNullOrUndefined(part) ? null : part;
    });

    workflowTariffs.forEach((wt) => {
      const tariff = tariffList.find((t) => t.id === wt.tariffId);
      wt.tariff = isNullOrUndefined(tariff) ? null : tariff;
    });

    this.setState({
      workflowTariffs: workflowTariffs,
      workflowPartnerList: workflowPartnerList.filter((item) => item.isActive === true),
      isLoading: false,
      workflowId: workflowId
    });
  };

  reloadWorkflowTariffs = async (workflowPartnerId: number | null) => {
    let [tariffList, workflowTariffs] = await Promise.all([
      this.tariffService.GetTariffs(Number.parseInt(this.props.match.params.id)),
      this.workflowService.GetWorkflowTariffs(this.state.workflowId)
    ]);

    const organizations = await this.organizationService.GetOrganizationsByIds(
      tariffList.map((item) => item.partnerId)
    );
    tariffList.forEach((tariff) => {
      const part = organizations.find((t) => t.id === tariff.partnerId);
      tariff.partner = isNullOrUndefined(part) ? null : part;
    });
    workflowTariffs.forEach((wt) => {
      const tariff = tariffList.find((t) => t.id === wt.tariffId);
      wt.tariff = isNullOrUndefined(tariff) ? null : tariff;
    });

    if (workflowPartnerId !== null) {
      workflowTariffs = workflowTariffs.filter(
        (item: WorkflowTariff) =>
          item.tariff !== null &&
          (item.tariff!.partner!.hoId === workflowPartnerId ||
            item.tariff!.partnerId === workflowPartnerId)
      );
    }
    tariffList =
      workflowPartnerId === null
        ? []
        : tariffList.filter(
            (item: Tariff) =>
              item.partner!.hoId === workflowPartnerId || item.partnerId === workflowPartnerId
          );

    this.setState({
      tariffList: tariffList.filter((item) => item.isActive === true),
      workflowTariffs: workflowTariffs,
      isLoading: false
    });
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const workflowTariffs = [...this.state.workflowTariffs];
    const updatedWorkflowTariffIndex = workflowTariffs.findIndex((o) => o.id === id);

    if (updatedWorkflowTariffIndex >= 0) {
      workflowTariffs[updatedWorkflowTariffIndex] = {
        ...workflowTariffs[updatedWorkflowTariffIndex],
        isActive: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowTariff(
          workflowTariffs[updatedWorkflowTariffIndex]
        );

        this.setState({ workflowTariffs: workflowTariffs });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  editTariff = (id: number) => {
    this.setState({ isOpenDialog: true, selectedTariffId: id });
  };

  onRowClick = (e: any, id: number) => {
    this.editTariff(id);
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'tariff',
        label: this.translatorService.Tranlate('WORKFLOW_TARIFFS_TAB_TARIFF_HEADER', 'Tarif'),
        options: {
          sort: false,
          customBodyRender: (value: Tariff) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(e, value.id)}
              >
                <span>{value.name}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      // {
      //     name: "workflowPartner",
      //     label: this.translatorService.Tranlate("WORKFLOW_TARIFFS_TAB_WORKFLOW_PARTNER_HEADER", "Partener"),
      //     options: {
      //         sort: false,
      //         customBodyRender: (value: WorkflowPartner) => { return (value.workflowPartner!.name) }
      //     } as MUIDataTableColumnOptions
      // },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_TARIFFS_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value ? value : false}
                onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      }
    ];

    return columns;
  };

  addTariff = async () => {
    try {
      if (
        this.state.workflowTariffs.findIndex(
          (item) =>
            item.workflowPartnerId === this.state.selectedWorkflowPartner!.id ||
            item.tariffId === this.state.selectedTariff!.id
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_MSG_WORKFLOW_TARIFF_EXISTS',
            'Tariful exista deja!'
          ),
          { variant: 'error' }
        );
        return;
      }

      await this.workflowService.AddWorkflowTariff({
        // workflowPartnerId: this.state.selectedWorkflowPartner!.id,
        tariffId: this.state.selectedTariff!.id,
        workflowId: this.state.workflowId,
        isActive: true,
        id: 0
      } as WorkflowTariff);

      this.setState(
        {
          isLoading: true,
          selectedTariff: null
        },
        async () => {
          await this.reloadWorkflowTariffs(this.state.selectedWorkflowPartner!.partnerId);
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleWorkflowPartnerChange = (newValue: WorkflowPartner | null) => {
    this.setState({ selectedWorkflowPartner: newValue }, () => {
      this.reloadWorkflowTariffs(newValue === null ? null : newValue.partnerId);
    });
  };

  handleWorkflowTariffChange = (newValue: Tariff | null) => {
    this.setState({ selectedTariff: newValue });
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addTariff}>
              <Autocomplete
                id="partner"
                className="m-2"
                options={this.state.workflowPartnerList.sort(function (a, b) {
                  return a.partner!.displayName.localeCompare(b.partner!.displayName);
                })}
                value={this.state.selectedWorkflowPartner}
                onChange={(e: any, newValue: WorkflowPartner | null) =>
                  this.handleWorkflowPartnerChange(newValue)
                }
                getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName || ''}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option: WorkflowPartner) => option.partner!.displayName
                })}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="partner"
                    value={this.state.selectedWorkflowPartner}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_TARIFF_TAB_WORKFLOW_PARTNER_LABEL',
                      'Partener tarif'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="tariff"
                className="m-2"
                options={this.state.tariffList}
                value={this.state.selectedTariff}
                onChange={(e: any, newValue: Tariff | null) =>
                  this.handleWorkflowTariffChange(newValue)
                }
                getOptionLabel={(option: Tariff) => option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="tariff"
                    value={this.state.selectedTariff}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_TARIFF_TAB_TARIFF_LABEL',
                      'Tarif'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  handleClose = (reload: boolean) => {
    if (reload) {
      this.setState({ isOpenDialog: false }, async () => {
        await this.reloadWorkflowTariffs(this.state.selectedWorkflowPartner!.partnerId);
      });
    } else {
      this.setState({ isOpenDialog: false });
    }
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.tariffService = (this.context as AppContext).tariffService;
    this.workflowService = (this.context as AppContext).workflowService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('WORKFLOWS_TAB_TARIFFS', 'Tarife')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowTariffs}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isOpenDialog}
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate('TARIFFS_EDIT_MODAL_TITLE', 'Editare Tarif')}
                </Typography>
              </Grid>
              <Grid item xs={1} className="text-right">
                <IconButton
                  aria-label="close"
                  onClick={() => this.handleClose(false)}
                  size={'small'}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <EditTariff
              {...this.props}
              tariffId={this.state.selectedTariffId}
              tariffChanged={() => this.handleClose(true)}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(WorkflowTariffs as any));
