import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Box, Select, MenuItem } from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Organization,
  OrganizationParameter,
  OrganizationToken
} from '../../../interfaces/Organization';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { withSnackbar, ProviderContext } from 'notistack';
import { OrganizationTypeCode, RoleClaimsEnum } from '../../../helpers/Constants';
import { ScaleLoader } from 'react-spinners';
import MaterialTable, { Column, EditComponentProps, Icons } from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { forwardRef } from 'react';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

interface IOrganizationTokensState {
  tokens: OrganizationToken[];
  isLoading: boolean;
  supplierList: Organization[];
}

export interface ExternalOrganizationTokenProps {
  organizationId: number;
}
type OrganizationTokensProps = { appState: AppState } & ExternalOrganizationTokenProps &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class OrganizationTokens extends React.PureComponent<
  OrganizationTokensProps,
  IOrganizationTokensState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {
    tokens: [],
    isLoading: true,
    supplierList: []
  } as IOrganizationTokensState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData();
      }
    );
  };

  loadData = async () => {
    try {
      const [data, supplierList] = await Promise.all([
        this.organizationService.OrganizationToken.Get(this.props.organizationId),
        this.organizationService.GetHOOrganizationsByTypes([OrganizationTypeCode.SUPPLIER])
      ]);

      this.setState({
        tokens: data,
        isLoading: false,
        supplierList: supplierList.filter((item) => item.isActive === true)
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const lookup = this.state.supplierList.reduce(function (
      acc: any,
      cur: Organization,
      i: number
    ) {
      acc[cur.id] = cur.displayName;
      return acc;
    },
    {});

    return [
      {
        field: 'partnerId',
        title: this.translatorService.Tranlate('ORGANIZATION_TOKENS_PARTNER_HEADER', 'Partener'),
        searchable: true,
        lookup: lookup,
        render: (rowData: OrganizationToken) => {
          return rowData.partner!.displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              fullWidth={true}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.supplierList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'token',
        title: this.translatorService.Tranlate('ORGANIZATION_TOKENS_TOKEN_HEADER', 'Token'),
        searchable: true
      } as Column<any>,
      {
        field: 'idLocation',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_TOKENS_TOKEN_HEADER_IDLOCATION',
          'Id Locatie'
        ),
        searchable: true
      } as Column<any>,
      {
        field: 'serviceCode',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_TOKENS_TOKEN_HEADER_SERVICECODE',
          'Code Service'
        ),
        searchable: true
      } as Column<any>,
      {
        field: 'dateModification',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_PARAMETERS_MODIFIED_DATE_HEADER',
          'Data modificare'
        ),
        searchable: true,
        render: (rowData: any) => {
          return moment
            .utc(rowData.dateModification)
            .local()
            .format(this.props.appState.longDateFormat);
        },
        editComponent: (props: EditComponentProps<any>) => {
          return <Label>{moment(new Date()).format(this.props.appState.longDateFormat)}</Label>;
        }
      } as Column<any>
    ];
  };

  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        deleteTooltip: this.translatorService.Tranlate('DELETE', 'Sterge'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza'),
          deleteText: this.translatorService.Tranlate(
            'DELETE_TEXT',
            'Sigur doriti sa stergeti aceasta inregistrare?'
          )
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };

  onRowUpdate = (newData: OrganizationToken, oldData?: OrganizationToken): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          newData.partner = null;
          await this.organizationService.OrganizationToken.Update(newData);
          await this.loadData();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowDelete = (oldData: OrganizationParameter): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.organizationService.OrganizationToken.Remove(oldData.id);
          await this.loadData();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });

          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowAdd = (newData: OrganizationToken): Promise<void> =>
    new Promise(async (resolve, reject) => {
      try {
        newData.organizationId = this.props.organizationId;

        await this.organizationService.OrganizationToken.Add(newData);
        await this.loadData();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });

  public renderTable = () => {
    if (this.hasRight(RoleClaimsEnum.AdminService)) {
      return (
        <MaterialTable
          icons={tableIcons}
          columns={this.getColumns()}
          data={this.state.tokens}
          title=" "
          options={{
            actionsColumnIndex: -1,
            addRowPosition: 'first'
          }}
          localization={this.getLocalization()}
        />
      )
    }

    return (
      <MaterialTable
        icons={tableIcons}
        columns={this.getColumns()}
        data={this.state.tokens}
        title=" "
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: any) => this.onRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData),
          onRowDelete: (oldData: any) => this.onRowDelete(oldData)
        }}
      />
    );
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderTable() : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: OrganizationTokensProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(OrganizationTokens as any));
