import { isNil } from 'lodash';
import React, { useContext, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApplicationContext } from '../../../context/Contexts';

import { DashboardItem } from '../../../interfaces/Dashboard/DashboardItem';

interface Props {
  title: string;
  items: DashboardItem[];
  handleBarClick(dataIndex?: number): void;
}
const VehicleAverageAgeBarChart: React.FC<Props> = ({ title, items, handleBarClick }) => {
  const context = useContext(ApplicationContext);

  const vehicleCount = context.translatorService.Tranlate(
    'DASHBOARD_VEHICLE_COUNT',
    'Numar vehicule'
  );
  const lessThan = context.translatorService.Tranlate('DASHBOARD_LESS_THAN', 'mai putin de');
  const days = context.translatorService.Tranlate('DASHBOARD_DAYS', 'zile');
  const series = useMemo(() => {
    return [
      {
        name: vehicleCount,
        data: items.slice(0, -1).map((i) => i.value)
      }
    ];
  }, [items, vehicleCount]);

  const options = useMemo(() => {
    const categories = items.slice(0, -1).map((i) => `${lessThan} ${i.displayName} ${days}`);
    return {
      chart: {
        type: 'bar',
        events: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            if (!isNil(config.dataPointIndex)) {
              handleBarClick(config.dataPointIndex);
            }
          }
        },
        height: 500,
        toolbar: {
          show: false
        }
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return val;
          }
        }
      }
    };
  }, [days, handleBarClick, items, lessThan]);

  return (
    <div>
      <h6 className="mt-3 ml-3 mb-0">{title}</h6>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default VehicleAverageAgeBarChart;
