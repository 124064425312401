import React, { ChangeEvent } from 'react';
import { Box, Typography, TextField } from '@material-ui/core';
import { TranslatorService } from '../../services/TranslatorService';

interface MileageInputProps {
  onChange: (mileage: string) => void;
  error?:boolean
}

const MileageInput: React.FC<MileageInputProps> = ({ onChange, error }) => {
  const translatorService = new TranslatorService();
  const handleMileageChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box>
      <Typography variant="h5" style={{ marginBottom: '10px', marginTop: '15px', color: error ? '#f83245' : 'black'  }}>
        Mileage (km)
      </Typography>
      <TextField
        id="mileage"
        onChange={handleMileageChange}
        variant="outlined"
        label="Type mileage here..."
        name="mileage"
        helperText={error ? translatorService.Tranlate(
          'VALIDATORS_REQUIRED',
          'Campul este obligatoriu'
        ) : ''}
        error={error}
        style={{ width: 300 }}
      />
    </Box>
  );
};

export default MileageInput;
