import React, { Fragment } from 'react';
import { AppUserAdminActionCreators, ApplicationState, AppActionCreators } from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Card, Button, Box, IconButton, CardContent, Tabs, Tab } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { AppUserRole, AppUserRoleClaim } from '../../../interfaces/AppUser';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  SelectableRows,
  MUIDataTableOptions,
  MUIDataTableMeta
} from 'mui-datatables';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { RoleClaimsEnum } from '../../../helpers/Constants';
import TabPanel from '../../_shared/Tabs';
import NavigationIcon from '@material-ui/icons/Navigation';
import { MUITranslations } from '../../../helpers/MUITableTranslations';

type EditRoleProps = ProviderContext & RouteComponentProps<{ id: string }>;

interface IEditRoleState {
  isLoading: boolean;
  roleClaims: AppUserRoleClaim[];
  selectedClaim: AppUserRoleClaim | null;
  role: AppUserRole | null;
  claims: AppUserRoleClaim[];
}

class EditRole extends React.PureComponent<EditRoleProps, IEditRoleState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    roleClaims: [] as AppUserRoleClaim[],
    selectedClaim: {} as AppUserRoleClaim,
    role: {
      id: null,
      name: '',
      dateCreation: new Date(),
      isActive: true
    } as AppUserRole,
    claims: [] as AppUserRoleClaim[]
  };

  public async componentDidMount() {
    if (this.props.match.params.id.toUpperCase() !== 'NEW') {
      try {
        const role = await this.appUserService.GetAppUserRole(this.props.match.params.id);

        this.setState(
          {
            role: role,
            isLoading: true
          },
          () => {
            this.loadRoleClaims();
          }
        );
      } catch (error) {}
    }
  }

  loadRoleClaims = async () => {
    const roleClaimsList = await this.appUserService.GetAppUserRoleClaims(
      this.state.role.id,
      1,
      10000
    );

    const newClaims = this.filterClaims(roleClaimsList.claims);
    this.setState({
      roleClaims: roleClaimsList.claims,
      isLoading: false,
      claims: newClaims,
      selectedClaim: null
    });
  };

  getColumns = () => {
    const columns = [
      {
        name: 'claimId',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'claimValue',
        label: ' ', //this.translatorService.Tranlate("ROLE_CLAIM_NAME_HEADER", "Nume"),
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' })
        }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteClaim(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  getOptions = () => {
    const options = {
      print: false,
      download: false,
      filter: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      viewColumns: false,
      filterType: 'checkbox',
      textLabels: MUITranslations.GetTranslations(this.translatorService)
    } as MUIDataTableOptions;

    return options;
  };

  addClaim = async () => {
    const newClaim = this.state.selectedClaim;
    newClaim.roleId = this.state.role.id;

    try {
      await this.appUserService.AddRoleClaim(newClaim);
      this.setState({ isLoading: true }, async () => {
        await this.loadRoleClaims();
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  saveRole = async () => {
    try {
      const roles = await this.appUserService.GetAppUserRoles(this.state.role.name);

      if (roles.totalCount > 1) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_ROLE_EXISTS', 'Rolul exista deja!'),
          { variant: 'error' }
        );
        return;
      }
      if (roles.totalCount === 1 && roles.roles[0].id !== this.state.role.id) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_ROLE_EXISTS', 'Rolul exista deja!'),
          { variant: 'error' }
        );
        return;
      }
      if (this.state.role.id !== null) {
        await this.appUserService.UpdateRole(this.state.role);
      } else {
        const newRole = await this.appUserService.AddRole(this.state.role);
        this.props.history.push('/admin/roles/' + newRole.id);
        this.setState({ role: newRole }, () => this.loadRoleClaims());
      }
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  deleteClaim = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const index = this.state.roleClaims.findIndex((i) => i.claimValue === tableMeta.rowData[1]);
      const deletedClaim = this.state.roleClaims[index];
      const newRoleClaimsArray = [...this.state.roleClaims];
      newRoleClaimsArray.splice(index, 1);

      await this.appUserService.DeleteRoleClaim(deletedClaim);

      this.setState({
        roleClaims: newRoleClaimsArray,
        claims: this.filterClaims(newRoleClaimsArray)
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteChange = (newValue: AppUserRoleClaim | null) => {
    this.setState({ selectedClaim: newValue });
  };

  filterClaims = (appuserRoles: AppUserRoleClaim[]) => {
    const values = Object.values(RoleClaimsEnum);

    const roleClaims = values.map((item) => {
      return { claimType: 'role', claimValue: item };
    });

    return roleClaims.filter(
      (item) => appuserRoles.find((r) => r.claimValue === item.claimValue) === undefined
    ) as AppUserRoleClaim[];
  };

  rolNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      role: {
        ...this.state.role,
        name: e.target.value
      }
    });
  };

  public renderEditRoleForm() {
    return (
      <Box m={1} p={1}>
        <Card className="card-box  overflow-visible">
          <CardContent className="p-3">
            <ValidatorForm onSubmit={this.saveRole}>
              <TextValidator
                fullWidth
                name="roleName"
                className="m-2"
                id="filled-basic"
                label={this.translatorService.Tranlate(
                  'ROLE_EDIT_LABEL',
                  'Denumire Grup Permisiuni'
                )}
                variant="outlined"
                value={this.state.role === null ? '' : this.state.role!.name}
                onChange={(e: any) => this.rolNameChange(e)}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ROLE_EDIT_BTN_SAVE', 'Salveaza')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }
  public renderAddForm() {
    if (this.state.role.id === null) {
      return;
    }

    return (
      <Box m={1} p={1}>
        <Card className="card-box  overflow-visible">
          <CardContent>
            <ValidatorForm onSubmit={this.addClaim}>
              <Autocomplete
                id="claim"
                className="m-2"
                options={this.state.claims}
                value={this.state.selectedClaim}
                onChange={(e: any, newValue: AppUserRoleClaim | null) =>
                  this.handleAutocompleteChange(newValue)
                }
                getOptionLabel={(option: AppUserRoleClaim) => option.claimValue || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="claim"
                    value={this.state.selectedClaim}
                    label={this.translatorService.Tranlate(
                      'ROLE_EDIT_ADD_CLAIM_SECTION_HEADER',
                      'Aduga Permisiune'
                    )}
                    fullWidth
                    variant="outlined"
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ROLE_EDIT_CLAIM_BTN_ADD', 'Adauga')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public renderClaims() {
    if (this.state.role.id === null) {
      return;
    }

    return (
      <Box m={1} p={1}>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {this.state.isLoading ? null : (
          <MUIDataTable
            title={this.translatorService.Tranlate('ROLE_EDIT_CLAIMS_TABLE_HEADER', 'Permisiuni')}
            data={this.state.roleClaims}
            columns={this.getColumns()}
            options={this.getOptions()}
          />
        )}
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <Fragment>
        <Box m={1} p={1}>
          <Card className="card-box">
            <CardContent className="card-header border-0">
              <div className="card-header--actions">
                <Tabs value={0} indicatorColor="primary" textColor="secondary" variant="standard">
                  <Tab
                    className="text-capitalize"
                    label={this.translatorService.Tranlate(
                      'ROL_TAB_DETAILS',
                      'Detalii Grup Permisiuni'
                    )}
                  />
                </Tabs>
              </div>
              <div className="card-header--title text-right">
                <Button
                  className="m-2"
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.props.history.push('/admin/roles');
                  }}
                >
                  <NavigationIcon />
                  {this.translatorService.Tranlate(
                    'EDIT_ROL_BACK_TO_ROLS_BTN',
                    'Grupuri Permisiuni'
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        </Box>
        <TabPanel value={0} index={0}>
          {this.renderEditRoleForm()}
          {this.renderAddForm()}
          {this.renderClaims()}
        </TabPanel>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.appUserAdmin, // Selects which state properties are merged into the component's props
  { ...AppUserAdminActionCreators, ...AppActionCreators } // Selects which action creators are merged into the component's props
)(withSnackbar(EditRole as any));
