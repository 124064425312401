import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppUserAdminActionCreators, AppUserAdminState } from '../../../store';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import NavigationIcon from '@material-ui/icons/Navigation';
import { RouteComponentProps } from 'react-router';
import TabPanel from '../../_shared/Tabs';
import { Card, Tab, Tabs, Button, CardContent, Box } from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import EditUser from './EditUser';
import EditUserRoles from './EditUserRoles';
import ResetPassword from './ResetPassword';
import { AppUser } from '../../../interfaces/AppUser';
import MavoEditUser from './MavoEditUser';

type UserDetailsProps = AppUserAdminState &
  typeof AppUserAdminActionCreators &
  RouteComponentProps<{ id: string }>;

interface IUserDetailsState {
  user: AppUser;
  selectedTab: number;
}

class MavoUserDetails extends React.PureComponent<UserDetailsProps, IUserDetailsState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {
    selectedTab: 0,
    user: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      isActive: false,
      organization: {},
      organizationId: 0,
      jobFunction: '',
      id: null
    } as AppUser
  };

  public async componentDidMount() {
    await this.loadUser();
  }

  loadUser = async () => {
    let user = this.state.user;

    if (this.props.match.params.id.toUpperCase() !== 'NEW') {
      try {
        user = await this.appUserService.GetAppUser(this.props.match.params.id);
      } catch (error) {}
    }
    this.setState({
      user: user
    });
  };

  handleChangeTab = async (event: React.ChangeEvent<any>, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <Fragment>
        <Box m={1} p={1}>
          <Card className="card-box">
            <CardContent className="card-header border-0">
              <div className="card-header--actions">
                <Tabs
                  value={this.state.selectedTab}
                  indicatorColor="primary"
                  textColor="secondary"
                  variant="standard"
                  onChange={this.handleChangeTab}
                >
                  <Tab
                    className="text-capitalize"
                    label={this.translatorService.Tranlate(
                      'ADMIN_USERS_TAB_USER_DETAILS',
                      'Detalii generale utilizator'
                    )}
                  />
                  <Tab
                    className="text-capitalize"
                    label={this.translatorService.Tranlate(
                      'ADMIN_USERS_TAB_USER_ROLS',
                      'Roluri utilizator'
                    )}
                  />
                  <Tab
                    className="text-capitalize"
                    label={this.translatorService.Tranlate(
                      'ADMIN_USERS_TAB_USER_PASSWORD',
                      'Schimba parola'
                    )}
                  />
                </Tabs>
              </div>

              <div className="card-header--title text-right">
                <Button
                  className="m-2"
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.props.history.push('/admin/mavo/users');
                  }}
                >
                  <NavigationIcon />
                  {this.translatorService.Tranlate('EDIT_USER_BACK_TO_USERS_BTN', 'Utilizatori')}
                </Button>
              </div>
            </CardContent>
          </Card>
        </Box>
        <TabPanel value={this.state.selectedTab} index={0}>
          <MavoEditUser {...this.props} userChanged={() => this.loadUser()} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <EditUserRoles
            {...{ history: this.props.history, match: this.props.match, appUser: this.state.user }}
          />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={2}>
          <ResetPassword
            {...{ history: this.props.history, match: this.props.match, appUser: this.state.user }}
          ></ResetPassword>
        </TabPanel>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.appUserAdmin, // Selects which state properties are merged into the component's props
  AppUserAdminActionCreators // Selects which action creators are merged into the component's props
)(MavoUserDetails as any);
