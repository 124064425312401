import React, { useContext, useMemo } from 'react';

import MaterialTable from '@material-table/core';
import { getLocalization, MaterialTableIcons } from '../../_shared/TableSettings';
import { ApplicationContext } from '../../../context/Contexts';
import { Paper } from '@material-ui/core';
import { DashboardGroupedItemDto } from '../../../interfaces/Dashboard/VehicleResult';

interface Props {
  items: DashboardGroupedItemDto[];
  technicalConditionId?: number;
  setTechnicalCondtionId(technicalConditionId?: number): void;
}

const VehicleConditionTable: React.FC<Props> = ({
  items,
  technicalConditionId,
  setTechnicalCondtionId
}) => {
  const context = useContext(ApplicationContext);

  const exterior = context.translatorService.Tranlate('DASHBOARD_EXTERIOR', 'Exterior');
  const interior = context.translatorService.Tranlate('DASHBOARD_INTERIOR', 'Interior');
  const mechanical = context.translatorService.Tranlate('DASHBOARD_MECHANICAL', 'Mecanica');

  const columns = useMemo(() => {
    return [
      { title: '', field: 'displayName' },
      {
        title: interior,
        field: 'byInConditionValue'
      },
      {
        title: exterior,
        field: 'byOutCondition'
      },
      {
        title: mechanical,
        field: 'byTehnicalConditionValue'
      }
    ];
  }, [exterior, interior, mechanical]);

  return (
    <div style={{ maxWidth: '100%', height: '100%' }}>
      <MaterialTable
        icons={MaterialTableIcons}
        localization={getLocalization(context.translatorService)}
        columns={columns}
        data={items}
        title={context.translatorService.Tranlate(
          'DASHBOARD_TECHNICAL_INVENTORY_STATE',
          'Stare tehnica stoc'
        )}
        options={{
          search: false,
          rowStyle: (rowData) => ({
            backgroundColor: technicalConditionId === rowData.id ? '#267DC3' : '#FFF',
            color: technicalConditionId === rowData.id ? '#FFF' : '#000'
          })
        }}
        components={{
          Container: (props) => <Paper {...props} className="h-100" />,
          Pagination: () => null
        }}
        onRowClick={(evt, selectedRow) => setTechnicalCondtionId(selectedRow?.id)}
      />
    </div>
  );
};

export default VehicleConditionTable;
