import React, { useMemo, useRef, useState } from 'react';
import {
  Button,
  IconButton,
  Modal,
  Paper,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core';
import MaterialTable from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as R from 'ramda';

import { getLocalization, MaterialTableIcons } from '../_shared/TableSettings';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SimpleVehicle } from '../../interfaces/Vehicle';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CSVReader from 'react-csv-reader';

const dateFormat = 'DD.MM.YYYY';
const dateFomratToSend = 'YYYY-MM-DD';
const vatOptions = [19, 0];

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  translatorService: ITranslatorService;
  vehicleService: IVehicleService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enqueueSnackbar(param1: any, param2: any): void;
}

interface ImportVehicle {
  id: string;
  plateNumber: string;
  firstRegistrationDate: Moment;
  kilometersNr: number;
  vin: string;
  purchasePrice: number;
}

class LocalizedUtils extends MomentUtils {}

const VehicleImportModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  translatorService,
  vehicleService,
  enqueueSnackbar
}) => {
  const [vehicles, setVehicles] = useState<ImportVehicle[]>([]);
  const [purchaseVatRate, setPurchaseVatRate] = useState<number | undefined>(vatOptions[0]);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const columns = useMemo(() => {
    return [
      {
        field: 'plateNumber',
        title: translatorService.Tranlate('VEHICLES_VEHICLE_PLATE_NUMBER', 'Numar inmatriculare'),
        editComponent: (props: any) => (
          <TextField
            error={!props.value}
            fullWidth
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        )
      },
      {
        field: 'firstRegistrationDate',
        title: translatorService.Tranlate(
          'VEHICLES_VEHICLE_FIRST_REGISTRATION_DATE',
          'Data primei inmatriculari'
        ),
        render: (props: ImportVehicle) => {
          return <div>{props.firstRegistrationDate?.format(dateFormat)}</div>;
        },
        editComponent: (props: any) => (
          <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils}>
            <DatePicker
              variant="inline"
              fullWidth
              format={dateFormat}
              value={props.value}
              onChange={(value) => {
                props.onChange(value);
              }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      {
        field: 'kilometersNr',
        title: translatorService.Tranlate('VEHICLES_VEHICLE_NR_OF_KM', 'KM'),
        editComponent: (props: any) => (
          <TextField
            type="number"
            error={!props.value}
            fullWidth
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        )
      },
      {
        field: 'vin',
        title: translatorService.Tranlate('VEHICLES_VEHICLE_VIN', 'VIN'),
        editComponent: (props: any) => (
          <TextField
            fullWidth
            error={!props.value || !R.test(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/, props.value)}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        )
      },
      {
        field: 'purchasePrice',
        title: translatorService.Tranlate(
          'VEHICLES_VEHICLE_PURCHASE_EUR_PRICE',
          'Pret Achizitie EUR cu TVA'
        ),
        editComponent: (props: any) => (
          <TextField
            fullWidth
            error={!props.value}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        )
      }
    ];
  }, [translatorService]);

  const handleImport = async () => {
    setDialogVisible(false);
    const simpleVehicles: SimpleVehicle[] = vehicles.map((vehicle) => {
      return {
        vin: vehicle.vin,
        kilometersNr: vehicle.kilometersNr,
        plateNumber: vehicle.plateNumber,
        firstRegistrationDate: vehicle.firstRegistrationDate.format(dateFomratToSend),
        purchasePrice: vehicle.purchasePrice,
        purchaseVatRate: purchaseVatRate ?? vatOptions[0]
      };
    });

    try {
      const result = await vehicleService.SaveImportedVehicles(simpleVehicles);

      enqueueSnackbar(
        <div>
          <p>
            {translatorService.Tranlate('VEHICLES_VEHICLE_IMPORTED', 'Vehicule importate: ')}{' '}
            {result.importedVehicleVins.length
              ? result.importedVehicleVins.join(', ')
              : translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
          </p>
          <p>
            {translatorService.Tranlate('VEHICLES_VEHICLE_COPIED', 'Vehicule copiate: ')}{' '}
            {result.copiedVehicleVins.length
              ? result.copiedVehicleVins.join(', ')
              : translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
          </p>
          <p>
            {translatorService.Tranlate('VEHICLES_VEHICLE_DUPLICATED', 'Vehicule duplicate: ')}{' '}
            {result.duplicatedVehicleVins.length
              ? result.duplicatedVehicleVins.join(', ')
              : translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
          </p>
        </div>,
        {
          variant: 'info',
          autoHideDuration: 6000
        }
      );

      setVehicles([]);
    } catch {
      enqueueSnackbar('There was an unexpected error while importing the vehicles', {
        variant: 'error',
        autoHideDuration: 6000
      });
    }
  };

  const handleAutoImport = (value: any) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      return;
    }

    let results = trimmedValue.split(' ');

    const invalidValues: string[] = [];

    try {
      const temp = [];
      for (let i = 1; i < results.length + 1; i++) {
        if (i < 5) continue;
        if (i % 5 == 0) {
          temp.push(
            `${results[i - 5]},${results[i - 4]},${results[i - 3]},${results[i - 2]},${
              results[i - 1]
            }`
          );
        }
      }
      results = temp;

      const importedVehicles: ImportVehicle[] = [];

      results.forEach((element: string) => {
        if (element) {
          const newVehicleArr = element.split(',');

          const date = moment(newVehicleArr[1], dateFormat, true);

          if (!date.isValid()) {
            invalidValues.push(newVehicleArr[1]);
          }

          if (!R.test(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/, newVehicleArr[3])) {
            invalidValues.push(newVehicleArr[3]);
          }

          const km = parseInt(newVehicleArr[2], 10);

          if (isNaN(km)) {
            invalidValues.push(newVehicleArr[2]);
          }

          const purchasePrice = parseInt(newVehicleArr[4], 10);

          if (!invalidValues.length) {
            importedVehicles.push({
              id: uuidv4(),
              plateNumber: newVehicleArr[0],
              firstRegistrationDate: date,
              kilometersNr: km,
              vin: newVehicleArr[3],
              purchasePrice
            });
          }
        }
      });

      if (!invalidValues.length) {
        setVehicles([...vehicles, ...importedVehicles]);
      } else {
        throw 'error';
      }
    } catch {
      enqueueSnackbar(`There are some invalid values: ${invalidValues.join(', ')}`, {
        variant: 'error',
        autoHideDuration: 10000
      });
    }
  };

  const checkIfDataIsValid = (data: ImportVehicle): boolean => {
    if (
      data &&
      data.id &&
      data.firstRegistrationDate &&
      data.kilometersNr &&
      data.plateNumber &&
      data.vin &&
      R.test(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/, data.vin) &&
      data.purchasePrice
    ) {
      return true;
    }

    return false;
  };

  const handleRowAdd = (newData: ImportVehicle) => {
    return new Promise((resolve, reject) => {
      if (!newData.firstRegistrationDate) {
        newData.firstRegistrationDate = moment();
      }

      if (!newData.id) {
        newData.id = uuidv4();
      }

      if (checkIfDataIsValid(newData)) {
        setVehicles([newData, ...vehicles]);
        resolve(newData);
      }

      reject();
    });
  };

  const handleRowUpdate = (newData: ImportVehicle) => {
    return new Promise((resolve, reject) => {
      if (checkIfDataIsValid(newData)) {
        const newVehicles = [...vehicles];
        const vehicleIndex = newVehicles.findIndex(({ id }) => id === newData.id);
        if (vehicleIndex >= 0) {
          newData.firstRegistrationDate = moment(newData.firstRegistrationDate);
          newVehicles[vehicleIndex] = newData;
          setVehicles(newVehicles);
          resolve(newData);
        }
      }

      reject();
    });
  };

  const handleRowDelete = (oldData: ImportVehicle) => {
    return new Promise((resolve, reject) => {
      if (oldData.id) {
        setVehicles(vehicles.filter(({ id }) => id !== oldData.id));
        resolve(1);
      }

      reject();
    });
  };

  const handleCloseConfirmationDialog = () => {
    setDialogVisible(false);
  };

  const handleOpenConfirmationDialog = () => {
    setDialogVisible(true);
  };

  const handleCloseModal = () => {
    setVehicles([]);
    setPurchaseVatRate(vatOptions[0]);
    handleClose();
  };

  const handleAutoImportFromCsv = (value: any) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      return;
    }

    let results = trimmedValue.split(' ');

    const invalidValues: string[] = [];

    try {
      const temp = [];
      for (let i = 1; i < results.length + 1; i++) {
        if (i < 5) continue;
        if (i % 5 == 0) {
          temp.push(
            `${results[i - 5]},${results[i - 4]},${results[i - 3]},${results[i - 2]},${
              results[i - 1]
            }`
          );
        }
      }
      results = temp;

      const importedVehicles: ImportVehicle[] = [];

      results.forEach((element: string) => {
        if (element) {
          const newVehicleArr = element.split(',');

          const date = moment(newVehicleArr[1], dateFormat, true);

          if (!date.isValid()) {
            invalidValues.push(newVehicleArr[1]);
          }

          if (!R.test(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/, newVehicleArr[3])) {
            invalidValues.push(newVehicleArr[3]);
          }

          const km = parseInt(newVehicleArr[2], 10);

          if (isNaN(km)) {
            invalidValues.push(newVehicleArr[2]);
          }

          const purchasePrice = parseInt(newVehicleArr[4], 10);

          if (!invalidValues.length) {
            importedVehicles.push({
              id: uuidv4(),
              plateNumber: newVehicleArr[0],
              firstRegistrationDate: date,
              kilometersNr: km,
              vin: newVehicleArr[3],
              purchasePrice
            });
          }
        }
      });

      if (!invalidValues.length) {
        setVehicles([...vehicles, ...importedVehicles]);
      } else {
        setVehicles([...vehicles, ...importedVehicles]);
        throw 'error';
      }
    } catch {
      enqueueSnackbar(`There are some invalid values: ${invalidValues.join(', ')}`, {
        variant: 'error',
        autoHideDuration: 10000
      });
    }
  };

  const importCars = (data: any) => {
    let importedValues = '';
    for (let i = 0; i < data.length; i++) {
      importedValues += data[i].join(' ') + ' ';
    }
    console.log('Value', importedValues);
    handleAutoImportFromCsv(importedValues);
    const e = (document.getElementById('CSVReader') as HTMLInputElement);
    console.log(e);
    if (e) {
      e.value = '';
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleCloseModal}>
        <div className="vehicle-import-modal">
          <Dialog open={dialogVisible} onClose={handleCloseConfirmationDialog}>
            <DialogContent>
              <DialogContentText className="text-primary">
                {`${translatorService.Tranlate(
                  'VEHICLE_AUTO_IMPORT_CONFIRMATION_MESSAGE',
                  'Sunteti sigur ca doriti salvarea cu TVA'
                )} ${purchaseVatRate}%?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleImport}>
                {translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
              </Button>
              <Button onClick={handleCloseConfirmationDialog}>
                {translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
              </Button>
            </DialogActions>
          </Dialog>
          <ValidatorForm onSubmit={handleOpenConfirmationDialog} instantValidate={true}>
            <div className="text-right">
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="mt-2 mb-2">
              <p>
                {translatorService.Tranlate(
                  'VEHICLE_VEHICLE_AUTO_IMPORT_FORMAT',
                  'Format acceptat: '
                )}
                <br />
                BN71ARD 27.02.2021 20000 5NPDH4AE8FH607111 25000
                <br />
                CJ22FRM 21.01.2020 60325 1GCCS13E388107108 13200
              </p>
              <label
                  className="d-flex justify-content-center align-items-center btn"
                  style={{ height: 'fit-content' }}
                >
                  <div className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary">
                    {translatorService.Tranlate('VEHICLES_VEHICLE_IMPORT_VEHICLES_CSV', 'Importa')}
                  </div>
                  <CSVReader
                    cssClass="csv-reader-button"
                    inputId="CSVReader"
                    inputStyle={{ display: 'none' }}
                    onFileLoaded={(data) => {
                      console.log('CSV Data', data);
                      importCars(data);
                    }}
                  />
                </label>
              <TextValidator
                className="mt-4"
                name="importText"
                fullWidth
                placeholder={translatorService.Tranlate(
                  'VEHICLES_VEHICLE_AUTO_IMPORT',
                  'Copiaza valoarea in formatul corect aici pentru a importa vehiculele automat'
                )}
                value=""
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleAutoImport(e.target.value)
                }
              />
              <Autocomplete
                id="vatImportModal"
                size="small"
                className="mt-4"
                options={vatOptions}
                disableClearable
                value={purchaseVatRate}
                getOptionLabel={(option) => `${option}`}
                onChange={(e: any, newValue: any | null) => setPurchaseVatRate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="vatImportModal"
                    value={purchaseVatRate}
                    label={translatorService.Tranlate('VEHICLES_VEHICLE_IMPORT_VAT', 'TVA')}
                  />
                )}
              />
            </div>
            <MaterialTable
              columns={columns}
              data={vehicles}
              title=""
              icons={MaterialTableIcons}
              localization={getLocalization(translatorService)}
              options={{
                actionsColumnIndex: -1,
                addRowPosition: 'first',
                maxBodyHeight: 400,
                paging: false
              }}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
                Pagination: () => null
                // Toolbar: () => null
              }}
              editable={{
                onRowAdd: (newData) => handleRowAdd(newData),
                onRowUpdate: (newData) => handleRowUpdate(newData),
                onRowDelete: (oldData) => handleRowDelete(oldData)
              }}
            />
            <div className="text-center mt-2 mb-2">
              <Button variant="contained" color="primary" type="submit" disabled={!vehicles.length}>
                {translatorService.Tranlate('VEHICLES_VEHICLE_IMPORT_VEHICLES', 'Importa Vehicule')}
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </Modal>
    </div>
  );
};

export default VehicleImportModal;
