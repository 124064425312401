import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import {
  WorkflowSectionStepForm
} from '../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import 'react-dropdown-tree-select/dist/styles.css';
import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { AppUser } from '../../interfaces/AppUser';
import { FormControlLabel,  Switch } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { rest } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { ChangeCaseStatusBasedCodeRequest } from '../../interfaces/Case';
import { CaseStatusCodeEnum } from '../../helpers/Constants';

interface ICaseDetailsCarInServiceFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  carInService: boolean;
}

export interface ExternalCaseDetailsCarInServiceFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCarInServiceFormProps =
  ExternalCaseDetailsCarInServiceFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCarInServiceForm extends React.PureComponent<
  ICaseDetailsCarInServiceFormProps,
  ICaseDetailsCarInServiceFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;
  state = {
    isLoading: false,
    hasRights: false,
    isUserHidden: false,
    executing: false,
    carInService: true
  } as ICaseDetailsCarInServiceFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const caseId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(caseId)) {
      return;
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    console.log('case', this.props.caseSettingsState.case!);

    this.setState(
      {
        isLoading: false,
        hasRights: hasRights,
        carInService: !this.props.caseSettingsState.case!.carInServiceDate ? true : this.props.caseSettingsState.case!.carInService 
      }
    );
  };

  continue = async (e: any) => {
    try {
      this.setState({ executing: true });

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });

      this.setState({ executing: false });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };


  submitCaseCarInServiceData = async (e: any) => {
    try {
      this.setState({ executing: true });

      const response = await this.caseService.SetCarInService(this.props.caseSettingsState.case!.id, this.state.carInService);

      this.props.SetCarInService(response.carInService, response.carInServiceDate);

      if (!response.carInService) {
        const caseStatusRequest = {
          caseId: this.props.caseSettingsState.case!.id,
          statusCode: CaseStatusCodeEnum.CAR_LEAVE_WORKSHOP.toString()
        } as ChangeCaseStatusBasedCodeRequest;
        const currentStatus = await this.caseService.ChangeCaseStatusBasedCode(caseStatusRequest);

        this.props.SetCaseStatus(currentStatus);
      } else {
        await this.submitForm(
          this.props.caseSettingsState.case!.id,
          this.props.workflowForm.id,
          null
        );
      }
      await this.loadData();

      this.setState({ executing: false });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  displayInfo = (info: string) => {
    return (
      <Tooltip
        onClick={(e) => { e.preventDefault() }}
        {...rest}
        disableHoverListener={!this.state.hasRights || this.state.executing}
        title={info}
        placement='right'
      >
        <div style={{marginLeft:12}}>
          <FontAwesomeIcon icon={['fas', 'info-circle']} />
        </div>
      </Tooltip>
    );
  };
  
  handleSwitchChange = (e: any) => {
    this.setState({
      carInService: e.currentTarget.checked
    });
  };

  rendeCarInServiceForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitCaseCarInServiceData(e);
        }}
      >
        <div className="m-3 text-center">
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography component="div" variant="h4" align="left" className="mt-3">
                {this.translatorService.Tranlate('APPOINTMENT_CAR_REPAIR_TITLE', 'Reparație mașină')}
              </Typography>
              <Grid item xs={12}>
                <div className="text-left">
                  <FormControlLabel
                    name="isCarInService"
                    control={
                      <Switch
                        disabled={!this.state.hasRights}
                        checked={this.state.carInService}
                        onChange={(e: any) => this.handleSwitchChange(e)}
                        color="primary"
                      />
                    }
                    label={
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="h6">
                          {this.state.carInService ? this.translatorService.Tranlate('CASE_CAR_IS_IN_SERVICE', 'Mașina este în service') 
                            : this.translatorService.Tranlate('CASE_CAR_IS_NOT_IN_SERVICE', 'Masina nu ramane in service') }
                        </Typography>
                        {this.displayInfo(this.translatorService.Tranlate('CASE_CAR_IS_IN_SERVICE_INFO', 'Selectează dacă mașina rămâne în service'))}
                      </div>
                    }
                    labelPlacement="end"
                    className="mt-3 mr-0"
                  />
                </div>
             </Grid>
            </Grid>
          </Grid>
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendeCarInServiceForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCarInServiceFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCarInServiceForm as any));
