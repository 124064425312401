import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Chip, Grid } from '@material-ui/core';
import {
  VehicleFiltersState,
  VehicleFilterStateKeys
} from '../../../interfaces/Dashboard/VehicleFiltersState';
import isNil from 'lodash/isNil';
import { VehicleResult } from '../../../interfaces/Dashboard/VehicleResult';
import { DashboardFilter } from '../../../interfaces/Dashboard/DashboardFilter';
import { ApplicationContext } from '../../../context/Contexts';

interface Props {
  filters: VehicleFiltersState;
  data?: VehicleResult;
  setFiltersValueByName(name: string, value: any): void;
  resetInventoryFilters(): void;
}

const VehicleDashboardAppliedFilters: React.FC<Props> = ({
  filters,
  data,
  setFiltersValueByName,
  resetInventoryFilters
}) => {
  const [orgName, setOrgName] = useState<string | undefined>();
  const context = useContext(ApplicationContext);

  const lessThan = context.translatorService.Tranlate('DASHBOARD_LESS_THAN', 'mai putin de');
  const days = context.translatorService.Tranlate('DASHBOARD_DAYS', 'zile');

  const appliedFilters = useMemo(() => {
    if (!data || !filters) {
      return;
    }
    const result: DashboardFilter[] = [];
    Object.values(VehicleFilterStateKeys).forEach((value) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const id = filters[value];
      if (!isNil(id)) {
        result.push({ id: id, propertyName: value });
      }
    });

    for (const item of result) {
      switch (item.propertyName) {
        case VehicleFilterStateKeys.MAKE_ID:
          item.displayName = data.byMaker.find(({ id }) => id === item.id)?.displayName;
          break;
        case VehicleFilterStateKeys.LOCATION_ID:
          item.displayName = orgName;
          break;
        case VehicleFilterStateKeys.MODEL_ID:
          item.displayName = data.byModel.find(({ id }) => id === item.id)?.displayName;
          break;
        case VehicleFilterStateKeys.TECHNICAL_CONDITION_ID:
          item.displayName = data.byTechnicalCondition.find(
            ({ id }) => id === item.id
          )?.displayName;
          break;
        case VehicleFilterStateKeys.STATUS_ID:
          item.displayName = data.byStatusType.find(({ id }) => id === item.id)?.displayName;
          break;
        case VehicleFilterStateKeys.INVENTORY_AGE_FILTER:
          item.displayName = `${lessThan} ${
            data.byInventoryAge.find(({ id }) => id === item.id)?.displayName
          } ${days}`;
          break;
        case VehicleFilterStateKeys.FUEL_ID:
          item.displayName = data.byFuelType.find(({ id }) => id === item.id)?.displayName;
          break;
        case VehicleFilterStateKeys.TRANSMISSION_TYPE_ID:
          item.displayName = data.byTransmissionType.find(({ id }) => id === item.id)?.displayName;
          break;
        case VehicleFilterStateKeys.TRACTION_TYPE_ID:
          item.displayName = data.byTractionType.find(({ id }) => id === item.id)?.displayName;
          break;
      }
    }

    return result;
  }, [data, days, filters, lessThan, orgName]);

  useEffect(() => {
    const getOrgName = async () => {
      if (!isNil(filters.locationId)) {
        const organization = await context.organizationService.GetOrganization(filters.locationId);

        if (organization) {
          setOrgName(organization.displayName);
        }
      } else {
        setOrgName(undefined);
      }
    };

    getOrgName();
  }, [context.organizationService, filters.locationId]);

  const handleDelete = useCallback(
    (propertyName: string) => {
      setFiltersValueByName(propertyName, undefined);
    },
    [setFiltersValueByName]
  );

  if (!appliedFilters?.length) {
    return null;
  }

  return (
    <Grid item xs={6}>
      <Card className="h-100 pl-2">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h5>
                {context.translatorService.Tranlate(
                  'DASHBOARD_APPLIED_FILTERS',
                  'Filtre aplicate1'
                )}
              </h5>
            </div>
            <div>
              <Button
                className="m-2"
                variant="outlined"
                color="secondary"
                onClick={resetInventoryFilters}
              >
                {context.translatorService.Tranlate('DASHBOARD_RESET_FILTERS', 'Reseteaza filtre1')}
              </Button>
            </div>
          </div>
          {appliedFilters.map((item: DashboardFilter, index) => {
            return (
              <Chip
                key={index}
                label={item.displayName}
                variant="outlined"
                onDelete={() => handleDelete(item.propertyName)}
              />
            );
          })}
        </div>
      </Card>
    </Grid>
  );
};

export default VehicleDashboardAppliedFilters;
