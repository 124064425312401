import * as React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Card,
} from '@material-ui/core';
import { LanguageApi } from '../../../interfaces/Case';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';

interface Props {
  language: LanguageApi;
}

class LanguageApiCard extends React.Component<Props> {
  private translatorService!: ITranslatorService;

  static contextType = ApplicationContext;

  processCountryFlag = (languageCode: string) => {
    let countryCode = '';
    if (languageCode === 'en') {
      countryCode = 'GB';
    } else {
      countryCode = languageCode.toUpperCase();
    }
    return `https://flagsapi.com/${countryCode}/shiny/32.png`
  }

  render() {
    this.translatorService = (this.context as AppContext).translatorService;

    return (
      <Card style={{ padding: '0 10px' }}>
        <List style={{ display: 'flex', alignItems: 'center' }}>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemText primary={`${this.props.language.name} (${this.props.language.code})`} />
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <img src={this.processCountryFlag(this.props.language.code)} alt={`Flag ${this.props.language.name}`} />
          </ListItem>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemText primary={`${this.translatorService.Tranlate('PERCENTAGE', 'Procent')}: ${this.props.language.percentage}%`} />
          </ListItem>
        </List>
      </Card>
    );
  }
}

export default LanguageApiCard;
