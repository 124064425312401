import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Grid, Button, Box } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import WorkflowGeneralData from './WorkflowGeneralData';
import WorkflowParameters from './WorkflowParameters';
import WorkflowOrganizationTypes from './WorkflowOrganizationTypes';
import WorkflowPartners from './WorkflowPartners';
import WorkflowTariffs from './WorkflowTariffs';
import WorkflowCaseStructure from '../workflows/WorkflowCaseStructure';
import WorkflowSuppliers2 from '../workflows/WorkflowSuppliers2';
import WorkflowReminders from '../workflows/WorkflowNotifications';
import { RouteComponentProps } from 'react-router';
import { PageTitle } from '../../../layout-components';
import WorkflowInterfaces from './WorkflowInterfaces';
import WorkflowCostCenters from './WorkflowCostCenters';

interface IOrganizationAdminStateState {
  selectedTab: number;
}

type IOrganizationAdminProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class OrganizationAdmin extends React.PureComponent<
  IOrganizationAdminProps,
  IOrganizationAdminStateState
> {
  private translatorService!: ITranslatorService;

  static contextType = ApplicationContext;
  state = { selectedTab: 0 };

  onButtonClick = (newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;

    return (
      <Fragment>
        <PageTitle
          titleHeading={this.props.location.state}
          backButtonName={this.translatorService.Tranlate(
            'EDIT_WORKFLOW_BACK_TO_ORGANIZATION_BTN',
            'Organizatie'
          )}
          backButtonHref={'/admin/hoorganizations/' + this.props.match.params.id}
          {...this.props}
        ></PageTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2}>
            <Box height={700}>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 0 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={
                    this.state.selectedTab === 0 ? 'text-white h-100' : 'text-dark h-100 h-100'
                  }
                  onClick={() => this.onButtonClick(0)}
                >
                  <b>
                    {this.translatorService.Tranlate('WORKFLOWS_TAB_GENERAL_DATA', 'Date Generale')}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 1 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 1 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(1)}
                >
                  <b>{this.translatorService.Tranlate('WORKFLOWS_TAB_PARAMETERS', 'Parametri')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 2 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 2 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(2)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'WORKFLOWS_TAB_ORGANIZATION_TYPES',
                      'Tip organizatie'
                    )}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 3 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 3 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(3)}
                >
                  <b>{this.translatorService.Tranlate('WORKFLOWS_TAB_PARTNERS', 'Parteneri')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 4 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 4 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(4)}
                >
                  <b>{this.translatorService.Tranlate('WORKFLOWS_TAB_TARIFFS', 'Tarife')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 5 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 5 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(5)}
                >
                  <b>{this.translatorService.Tranlate('WORKFLOWS_TAB_SUPPLIERS', 'Furnizori')}</b>
                </Button>
              </Box>
              {/* <Box height={1 / 12} border={1} borderColor="text.disabled" borderBottom={0} borderRadius="0%" bgcolor={this.state.selectedTab === 6 ? "secondary.main" : ""}>
                                <Button fullWidth className={this.state.selectedTab === 6 ? "text-white h-100" : "text-dark h-100"} onClick={() => this.onButtonClick(6)}><b>{this.translatorService.Tranlate("WORKFLOWS_TAB_OPTIMIZATION", "Optimizari")}</b></Button>
                            </Box> */}
              {/* <Box height={1 / 12} border={1} borderColor="text.disabled" borderBottom={0} borderRadius="0%" bgcolor={this.state.selectedTab === 7 ? "secondary.main" : ""}>
                                <Button fullWidth className={this.state.selectedTab === 7 ? "text-white h-100" : "text-dark h-100"} onClick={() => this.onButtonClick(7)}><b>{this.translatorService.Tranlate("WORKFLOWS_TAB_DOCUMENT_TYPES", "Tip documente")}</b></Button>
                            </Box>                             */}
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 8 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 8 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(8)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'WORKFLOWS_TAB_CASE_STRUCTURE',
                      'Structura dosar'
                    )}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 9 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 9 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(9)}
                >
                  <b>{this.translatorService.Tranlate('WORKFLOWS_TAB_REMINDERS', 'Notificari')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={1}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 10 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 10 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(10)}
                >
                  <b>{this.translatorService.Tranlate('WORKFLOW_TAB_INTERFACES', 'Interfete')}</b>
                </Button>
              </Box>
              <Box
                height={1 / 12}
                border={1}
                borderColor="text.disabled"
                borderBottom={1}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 11 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 11 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(11)}
                >
                  <b>{this.translatorService.Tranlate('WORKFLOW_TAB_COST_CENTERS', 'Centre de Cost')}</b>
                </Button>
              </Box>
              {/* <Box height={1 / 12} border={1} borderColor="text.disabled"  borderBottom={0} borderRadius="0%" bgcolor={this.state.selectedTab === 10 ? "secondary.main" : ""}>
                                <Button fullWidth className={this.state.selectedTab === 10 ? "text-white h-100" : "text-dark h-100"} onClick={() => this.onButtonClick(10)}><b>{this.translatorService.Tranlate("WORKFLOWS_TAB_CALCULATIONS", "Calculatii")}</b></Button>
                            </Box> */}
              {/* <Box height={1 / 12} border={1} borderColor="text.disabled" borderRadius="0%" bgcolor={this.state.selectedTab === 11 ? "secondary.main" : ""}>
                                <Button fullWidth className={this.state.selectedTab === 11 ? "text-white h-100" : "text-dark h-100"} onClick={() => this.onButtonClick(11)}><b>{this.translatorService.Tranlate("WORKFLOWS_TAB_PERMISSIONS", "Permisiuni")}</b></Button>
                            </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12} lg={10}>
            {this.state.selectedTab === 0 ? <WorkflowGeneralData {...this.props} /> : null}
            {this.state.selectedTab === 1 ? <WorkflowParameters {...this.props} /> : null}
            {this.state.selectedTab === 2 ? <WorkflowOrganizationTypes {...this.props} /> : null}
            {this.state.selectedTab === 3 ? <WorkflowPartners {...this.props} /> : null}
            {this.state.selectedTab === 4 ? <WorkflowTariffs {...this.props} /> : null}
            {this.state.selectedTab === 5 ? <WorkflowSuppliers2 {...this.props} /> : null}

            {this.state.selectedTab === 8 ? <WorkflowCaseStructure {...this.props} /> : null}
            {this.state.selectedTab === 9 ? <WorkflowReminders {...this.props} /> : null}
            {this.state.selectedTab === 10 ? <WorkflowInterfaces {...this.props} /> : null}
            {this.state.selectedTab === 11 ? <WorkflowCostCenters {...this.props} /> : null}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationAdmin as any));
