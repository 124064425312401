import React, { useContext } from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button
} from '@material-ui/core';
import { ApplicationContext } from '../../context/Contexts';
import { IReferential } from '../../interfaces/IReferential';
import ImageNotAvailable from '../../assets/images/car-image-not-available.jpg';

interface Props {
  option: IReferential;
  fieldName: string;
  onChange: (fieldName: string) => (event: any, option: IReferential) => void;
}

const CarCard: React.FC<Props> = ({ option, fieldName, onChange }) => {
  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;

  const checkIfImageExists = (url: string) => {
    if (url.includes('undefined') || url.includes('null')) {
      return ImageNotAvailable;
    }

    return url;
  };

  return (
    <>
      {option.extraData && (
        <Card
          key={option.id}
          style={{
            minWidth: '310px'
          }}
        >
          <CardContent className="pt-4 pr-4 pb-2 pl-4">
            {option.extraData && (
              <Typography gutterBottom variant="h5" component="h2">
                {option.extraData?.manufacturer} {option.extraData?.model}
              </Typography>
            )}

            {option.extraData?.minPrice &&
              option.extraData?.maxPrice &&
              option.extraData?.currency && (
                <Typography variant="body2" color="textSecondary" component="p" className="mb-2">
                  {option.extraData?.minPrice} {option.extraData?.currency === 'EUR' ? '€' : ''} -{' '}
                  {''}
                  {option.extraData?.maxPrice} {option.extraData?.currency === 'EUR' ? '€' : ''}
                  {''} {translatorService.Tranlate('GROSS_VALUE', 'brut')}
                </Typography>
              )}
            <CardMedia
              style={{
                height: '150px',
                width: '95%',
                margin: '0 auto'
              }}
              className="mb-2"
              image={
                option.extraData?.imageUrl
                  ? checkIfImageExists(option.extraData?.imageUrl)
                  : ImageNotAvailable
              }
            />
            {option.extraData?.generation && option.extraData?.modelYear && (
              <Typography variant="body2" component="p" className="mb-2">
                {translatorService.Tranlate('GENERATION', 'Generatia')}{' '}
                {option.extraData?.generation} -{''}
                {option.extraData?.modelYear}
              </Typography>
            )}
            {option.extraData?.body && option.extraData?.doorsNo && (
              <Typography variant="body2" component="p" className="mb-2">
                {option.extraData?.body} - {option.extraData?.doorsNo}{' '}
                {translatorService.Tranlate('DOORS', 'usi')}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              style={{
                border: '1px solid rgb(224 224 224)'
              }}
              onClick={() => {
                onChange(fieldName)(null, option);
              }}
            >
              {translatorService.Tranlate('SELECT_CAR_BUTTON', 'Selecteaza')}
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default CarCard;
