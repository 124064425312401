import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { Button, Card, Grid} from '@material-ui/core';
import moment from 'moment';
import { withSnackbar, ProviderContext } from 'notistack';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import FileSaver from 'file-saver';
import { LanguageCode } from '../../helpers/Constants';
import { ScaleLoader } from 'react-spinners';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import { OrderPartsHistoryFilters } from '../../interfaces/Order';


type OrderPartsHistoryProps = {
    appState: AppState;
    caseSettingsState: CaseSettingsState;
  } & ProviderContext &
    RouteComponentProps;

interface IOrderPartsHistoryState {
  isLoading: boolean;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
  startDateError: string;
  endDateError: string;
  vin: string;
}

class LocalizedUtils extends MomentUtils {
}

class OrderPartsHistory extends React.PureComponent<OrderPartsHistoryProps, IOrderPartsHistoryState> {
  private translatorService!: ITranslatorService;
  private orderService!: IOrderService;
  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    filterStartDate: null,
    filterEndDate: null,
    startDateError: '',
    endDateError: '',
    vin: ''
  }  as IOrderPartsHistoryState;

  public async componentDidMount() {
    this.setState(
      {
        isLoading: false
      }
    );
  }

  exportInfo = async () => {
    if (this.state.startDateError || this.state.endDateError) {
      return;
    }

    const language  = this.props.appState.language == LanguageCode.RO ? LanguageCode.RO : LanguageCode.EN;
    try {
      this.setState({ isLoading: true });

      const formatedStartDate = this.state.filterStartDate ? moment(this.state.filterStartDate).format("DD.MM.YYYY") : null;
      const formatedEndDate = this.state.filterEndDate ? moment(this.state.filterEndDate).format("DD.MM.YYYY") : null;
      const requestPayload = {
        startDate: this.state.filterStartDate ? new Date(this.state.filterStartDate.setHours(0, 0, 0, 0)) : null,
        endDate: this.state.filterEndDate ? new Date(this.state.filterEndDate.setHours(23, 59, 59, 999)) : null,
        vin: this.state.vin,
        language: language
      }  as OrderPartsHistoryFilters;
  
      const fileBlob = await this.orderService.GetOrderPartsHistory(
        requestPayload
      );
      FileSaver.saveAs(fileBlob, language ==  LanguageCode.RO ? 'Istoric Piese Auto_' + this.state.vin + '_' + formatedStartDate + '-' + formatedEndDate + '.xlsx' : 'Auto Parts History_' + this.state.vin + '_' + formatedStartDate + '-' + formatedEndDate + '.xlsx');
      
      this.setState({ isLoading: false });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err: any) {
      this.setState({ isLoading: false });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  validateDates = (startDate: Date | null, endDate: Date | null): void => {
    /*
    Validate start date
    */
    let startDateError = '';
    if (!startDate) {
      startDateError = this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
    } else {
      if (endDate && startDate > endDate) {
        startDateError = this.translatorService.Tranlate(
          'EXPORT_START_DATE_ERROR',
          'De la trebuie sa fie mai mica decat pana la'
        );
      }
    }

    /*
    Validate end date
    */
    let endDateError = '';
    if (!endDate) {
      endDateError = this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
    }

    this.setState({
      startDateError: startDateError,
      endDateError: endDateError
    });

  };


  handlefilterStartDateChange = async (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        filterStartDate: date === null || !date.isValid ? null : date!.toDate(),
        startDateError: ''
      }
      
    );
  };

  handlefilterEndDateChange = async (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        filterEndDate: date === null || !date.isValid ? null : date!.toDate(),
        endDateError: ''
      }
    );
  };

  renderFilters = () => {
    return (
      <ValidatorForm
        onSubmit={() => {
          this.exportInfo();
        }}
      >
      <div className="m-3 text-center">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextValidator
              fullWidth
              id="vin"
              className="mt-2 ml-2 pr-2"
              name="color"
              placeholder={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_VIN',
                'Vin'
              )}
              value={this.state.vin}
              onChange={(e: any) => {
                this.setState({
                  vin: e.target.value
                });
              }}
              validators={['required', 'matchRegexp:^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                ),
                this.translatorService.Tranlate('INVALID_CAR_VIN_IDENTIFICATOR', "Te rugăm să te asiguri că seria de șasiu introdusă  are 17 caractere alfanumerice și nu conține spații sau literele 'O' sau 'I'!")
              ]}          
              label={this.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'Vin')}
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="date-picker-inline-from"
                  label={this.translatorService.Tranlate('ORDERS_DATE_LABEL_FROM', 'De la')}
                  value={this.state.filterStartDate}
                  onChange={this.handlefilterStartDateChange}
                  autoOk={true}
                  error={this.state.startDateError ? true : false}
                  helperText={
                    this.state.startDateError
                  }
                  required={true}
                />
              </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="date-picker-inline-to"
                  label={this.translatorService.Tranlate('ORDERS_DATE_LABEL_TO', 'Pana la')}
                  value={this.state.filterEndDate}
                  onChange={this.handlefilterEndDateChange}
                  autoOk={true}
                  error={this.state.endDateError ? true : false}
                  helperText={
                    this.state.endDateError
                  }
                  required={true}
                />
              </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <div className="m-3 text-center">
          <Button
            className="mt-3"
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              this.validateDates(this.state.filterStartDate, this.state.filterEndDate);
            }}
          >
            {this.translatorService.Tranlate('EXPORT_DATA', 'Export')}
          </Button>
        </div>
      </div>
    </ValidatorForm>
    );
  };

  
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.orderService = (this.context as AppContext).orderService;

    return (
      <React.Fragment>
        <Card>
          <div>
            {this.renderFilters()}
          </div>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    caseSettingsState: state.caseSettings
  }),
  null
)(withSnackbar(OrderPartsHistory as any));
