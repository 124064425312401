import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import React, { useEffect, useState } from 'react';
import { CaseAttachment, CaseAttachments } from '../../interfaces/Case';
import VisibilityIcon from '@material-ui/icons/Visibility';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileViewer from 'react-file-viewer';
import CloseIcon from '@material-ui/icons/Close';

import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import FileSaver from 'file-saver';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';

interface Props {
  vehicleId: number;
  vehicleService: IVehicleService;
  caseService: ICaseService;
  organizationService: IOrganizationService;
  translatorService: ITranslatorService;
}

interface PreviewFile {
  url: string;
  extension: string;
}

export const imageExtensions = ['png', 'jpeg', 'jpg', 'bmp'];

const VehicleAttachments: React.FC<Props> = ({
  vehicleService,
  caseService,
  vehicleId,
  translatorService,
  organizationService
}) => {
  const [caseAttachments, setCaseAttachments] = useState<CaseAttachments[]>([]);
  const [previewFile, setPreviewFile] = useState<PreviewFile>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const setVehicleAttachmens = async () => {
    const caseAttachments = await vehicleService.GetCaseAttachmentsForVehicle(vehicleId);

    for (const caseAttachment of caseAttachments) {
      if (!caseAttachment.organizationOwnerName) {
        caseAttachment.picturesExpanded = false;
        caseAttachment.documentsExpanded = false;
        const org = await organizationService.GetOrganization(caseAttachment.organizationOwnerId);
        if (org) {
          caseAttachment.organizationOwnerName = org.name;
        }
      }
      for (const attachment of caseAttachment.attachments)
        if (!attachment.url) {
          const blob = await getImage(attachment.fileName);
          attachment.url = URL.createObjectURL(blob);
        }
    }

    setCaseAttachments(caseAttachments);
  };

  const handlePreviewAttachment = async (originalFileName: string, fileName: string) => {
    const fileBlob = await getImage(fileName);
    const url = URL.createObjectURL(fileBlob);
    const extension = originalFileName.split('.').pop();

    if (!extension) {
      return;
    }

    setPreviewFile({
      url,
      extension
    });

    setIsDialogOpen(true);
  };

  const downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await getImage(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  const renderPreviewAttachment = () => {
    return previewFile ? (
      <FileViewer fileType={previewFile.extension} filePath={previewFile?.url} />
    ) : null;
  };

  const getImage = async (fileName: string) => {
    const fileBlob = await caseService.GetCaseAttachment(fileName);
    return fileBlob;
  };

  const expandPictures = (caseId: number) => {
    const newAttachments = [...caseAttachments];

    const index = newAttachments.findIndex((c) => c.caseId === caseId);
    if (index >= 0) {
      newAttachments[index].picturesExpanded = !newAttachments[index].picturesExpanded;
    }

    setCaseAttachments(newAttachments);
  };

  const expandDocuments = (caseId: number) => {
    const newAttachments = [...caseAttachments];

    const index = newAttachments.findIndex((c) => c.caseId === caseId);
    if (index >= 0) {
      newAttachments[index].documentsExpanded = !newAttachments[index].documentsExpanded;
    }

    setCaseAttachments(newAttachments);
  };

  useEffect(() => {
    setVehicleAttachmens();
  }, [vehicleId]);

  if (!caseAttachments.length) {
    return null;
  }

  return (
    <div className="pl-4 pr-4">
      <div>
        {caseAttachments.map(
          (
            {
              attachments,
              caseId,
              organizationOwnerName,
              caseTypeDisplayName,
              picturesExpanded,
              documentsExpanded
            },
            index
          ) => {
            const pictureAttachments: CaseAttachment[] = [];
            const documentAttachments: CaseAttachment[] = [];

            for (const attachment of attachments) {
              const ext = attachment.fileName.split('.').pop();
              if (ext && imageExtensions.includes(ext)) {
                pictureAttachments.push(attachment);
              } else {
                documentAttachments.push(attachment);
              }
            }

            return (
              <div key={index}>
                <div>
                  <h5
                    className="bg-theme-primary text-left mt-2 p-1 cursor-pointer"
                    style={{ color: 'white' }}
                  >
                    {`${translatorService.Tranlate(
                      'VEHICLES_VEHICLE_ATTACHMENTS_CASE',
                      'Atasamente dosar'
                    )} ${caseId} ${caseTypeDisplayName} ${organizationOwnerName}`}
                  </h5>
                </div>

                {pictureAttachments.length ? (
                  <div
                    className="border mb-2 mt-2 pt-1 pb-1"
                    style={{ color: '#333e65', fontSize: '16px', cursor: 'pointer' }}
                    onClick={() => expandPictures(caseId)}
                  >
                    <IconButton
                      aria-label="preview"
                      color="inherit"
                      size="small"
                      className="text-primary"
                    >
                      {picturesExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    <span>
                      {translatorService.Tranlate('VEHICLES_VEHICLE_PICTURES', 'Fotografii')}
                    </span>
                  </div>
                ) : null}
                {picturesExpanded && (
                  <Grid container spacing={2} xs={12} className="pl-4">
                    {pictureAttachments.map((attachment) => {
                      return (
                        <Grid item xs={4} key={index}>
                          <div className="border h-100">
                            <Grid container xs={12} spacing={0}>
                              <Grid item xs={12}>
                                <div
                                  className="d-flex align-items-center pt-1 pb-1 justify-content-center w-100"
                                  style={{ color: '#333e65', fontSize: '16px' }}
                                >
                                  {attachment.attachmentType?.displayName}
                                </div>
                              </Grid>
                              <Grid item xs={10}>
                                <div className="d-flex align-items-center h-100">
                                  <Button
                                    fullWidth
                                    color="primary"
                                    onClick={() =>
                                      downloadFile(attachment.fileName, attachment.originalFileName)
                                    }
                                  >
                                    <span>{attachment.originalFileName}</span>
                                  </Button>{' '}
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className="d-flex justify-content-end h-100">
                                  <IconButton
                                    aria-label="preview"
                                    color="inherit"
                                    className="text-primary"
                                    onClick={(e) =>
                                      handlePreviewAttachment(
                                        attachment.originalFileName,
                                        attachment.fileName
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                {
                                  <div style={{ flexBasis: '100%' }}>
                                    <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                      <img
                                        src={attachment.url}
                                        alt={attachment.fileName}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                }
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {documentAttachments.length ? (
                  <div
                    className="border mb-2 mt-2 pt-1 pb-1"
                    style={{ color: '#333e65', fontSize: '16px', cursor: 'pointer' }}
                    onClick={() => expandDocuments(caseId)}
                  >
                    <IconButton
                      aria-label="preview"
                      color="inherit"
                      size="small"
                      className="text-primary"
                    >
                      {documentsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    <span>
                      {translatorService.Tranlate('VEHICLES_VEHICLE_DOCUMENTS', 'Documente')}
                    </span>
                  </div>
                ) : null}
                {documentsExpanded && (
                  <Grid container spacing={2} xs={12}>
                    {documentAttachments.map((attachment) => {
                      return (
                        <Grid item xs={3} key={index}>
                          <div className="border h-100">
                            <Grid container xs={12} spacing={0}>
                              <div
                                className="d-flex align-items-center pt-1 pb-1 justify-content-center w-100"
                                style={{ color: '#333e65', fontSize: '16px' }}
                              >
                                {attachment.attachmentType?.displayName}
                              </div>
                              <Grid item xs={12}>
                                <div className="d-flex align-items-center h-100">
                                  <Button
                                    fullWidth
                                    color="primary"
                                    onClick={() =>
                                      downloadFile(attachment.fileName, attachment.originalFileName)
                                    }
                                  >
                                    <span>{attachment.originalFileName}</span>
                                  </Button>{' '}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </div>
            );
          }
        )}
      </div>
      <Dialog
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
        maxWidth="md"
        fullWidth={true}
        fullScreen={false}
        classes={{ paper: 'dialogPaper' }}
        scroll="body"
      >
        <DialogTitle id="customized-dialog-title">
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h4">
                {translatorService.Tranlate('CASE_PREVIEW_DOC', 'Previzualizare document')}
              </Typography>
            </Grid>
            <Grid className="text-right" item xs={1}>
              <IconButton aria-label="close" onClick={() => setIsDialogOpen(false)} size={'small'}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container alignContent="center">
            <Grid item xs={12}>
              <Box height="65vh" className="text-center">
                {renderPreviewAttachment()}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VehicleAttachments;
