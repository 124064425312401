import React, { useMemo, useRef, useContext } from 'react';
import { MarketData } from '../../interfaces/Radar/MarketData';
import isNil from 'lodash/isNil';
import ReactApexChart from 'react-apexcharts';
import meanBy from 'lodash/meanBy';
import minBy from 'lodash/minBy';
import moment from 'moment';
import { ApplicationContext } from '../../context/Contexts';

interface Props {
  title: string;
  period: number;
  filteredMarketData: MarketData[];
  handleLineClick(dataIndex?: number): void;
}
const RadarLineChart: React.FC<Props> = ({
  title,
  period,
  filteredMarketData,
  handleLineClick
}) => {
  const meanKmArray = useRef<number[]>([]);
  const context = useContext(ApplicationContext);

  const monthMap = useMemo(
    () =>
      new Map([
        ['01', context.translatorService.Tranlate('RADAR_JANUARY', 'Ianuarie')],
        ['02', context.translatorService.Tranlate('RADAR_FEBRUARY', 'Februarie')],
        ['03', context.translatorService.Tranlate('RADAR_MARCH', 'Martie')],
        ['04', context.translatorService.Tranlate('RADAR_APRIL', 'Aprilie')],
        ['05', context.translatorService.Tranlate('RADAR_MAY', 'Mai')],
        ['06', context.translatorService.Tranlate('RADAR_JUNE', 'Iunie')],
        ['07', context.translatorService.Tranlate('RADAR_JULY', 'Iulie')],
        ['08', context.translatorService.Tranlate('RADAR_AUGUST', 'August')],
        ['09', context.translatorService.Tranlate('RADAR_SEPTEMBER', 'Septembrie')],
        ['10', context.translatorService.Tranlate('RADAR_OCTOBER', 'Octombrie')],
        ['11', context.translatorService.Tranlate('RADAR_NOVEMBER', 'Noiembrie')],
        ['12', context.translatorService.Tranlate('RADAR_DECEMBER', 'Decembrie')]
      ]),
    [context]
  );

  const labels = useMemo(() => {
    const dateEnd = moment();
    const dateStart = moment().add(-period, 'month');

    const labels: string[] = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      const month = dateStart.format('MM');
      if (monthMap.has(month)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        labels.push(monthMap.get(month)!);
      }
      dateStart.add(1, 'month');
    }

    return labels;
  }, [period, monthMap]);

  const series = useMemo(() => {
    const dateEnd = moment();
    const dateStart = moment().add(-period, 'month');

    const months: string[] = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      const month = dateStart.format('MM');
      months.push(month);
      dateStart.add(1, 'month');
    }

    const dataMin = [];
    for (const month of months) {
      const results = filteredMarketData.filter((item) => {
        return moment(item.addDate, 'YYYY-MM-DD').format('MM') === month;
      });

      if (results.length) {
        const min = minBy(results, 'price');
        if (!isNil(min)) {
          dataMin.push(+min.price.toFixed(2));
        }
      } else {
        dataMin.push(null);
      }
    }

    const dataMed = [];
    for (const month of months) {
      const results = filteredMarketData.filter((item) => {
        return moment(item.addDate, 'YYYY-MM-DD').format('MM') === month;
      });

      if (results.length) {
        const mean = meanBy(results, 'price');
        const meanKm = meanBy(results, 'kmNo');
        if (!isNil(mean)) {
          dataMed.push(+mean.toFixed(2));
        }
        if (!isNil(meanKm)) {
          meanKmArray.current.push(+meanKm.toFixed(2));
        }
      } else {
        dataMed.push(null);
      }
    }

    return [
      {
        name: context.translatorService.Tranlate('RADAR_MINIMUM_PRICE', 'Pret minim'),
        data: dataMin
      },
      {
        name: context.translatorService.Tranlate('RADAR_AVERAGE_PRICE', 'Pret mediu'),
        data: dataMed
      }
    ];
  }, [filteredMarketData, period, context]);

  const options = useMemo(() => {
    return {
      chart: {
        type: 'line',
        events: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            if (!isNil(config.dataPointIndex)) {
              handleLineClick(config.dataPointIndex);
            }
          }
        },
        toolbar: {
          show: false
        }
      },
      tooltip: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          const minPriceSeries = series[0].filter((item: any) => item !== null);
          const mediumPriceSeries = series[1].filter((item: any) => item !== null);
          return `<div class="pt-2 pl-2 pr-2">
          <p>${context.translatorService.Tranlate('RADAR_AVERAGE_PRICE', 'Pret mediu')}: <strong>${
            mediumPriceSeries[seriesIndex]
          }</strong> EUR</p>
          <p>${context.translatorService.Tranlate('RADAR_MINIMUM_PRICE', 'Pret minim')}: <strong>${
            minPriceSeries[seriesIndex]
          }</strong> EUR</p>
          <p>${context.translatorService.Tranlate(
            'RADAR_AVERAGE_TURNOVER',
            'Rulaj mediu'
          )}: <strong>${meanKmArray.current[seriesIndex]}</strong> km</p>
      </div>`;
        }
      },
      xaxis: {
        categories: labels
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLineClick, labels, filteredMarketData]);

  return (
    <div className="pb-2">
      <h5 className="mt-3 ml-3 mb-0">{title}</h5>
      <ReactApexChart options={options} series={series} type="line" height={600} />
    </div>
  );
};

export default RadarLineChart;
