import { Card, CardContent, CardMedia, Grid, Paper, Tooltip } from '@material-ui/core';
import React, { useContext } from 'react';
import { MarketData } from '../../interfaces/Radar/MarketData';
import InfoIcon from '@material-ui/icons/Info';
import { fuelTypes } from './fuelTypes';
import { ApplicationContext } from '../../context/Contexts';

interface Props {
  filteredMarketData: MarketData[];
}

const RecentAdds: React.FC<Props> = ({ filteredMarketData }) => {
  const context = useContext(ApplicationContext);
  return (
    <Paper>
      <div className="p-2">
        <h5>
          {context.translatorService.Tranlate('RADAR_MOST_RECENT_ADS', 'Cele mai recente anunturi')}
        </h5>
      </div>
      <div style={{ maxHeight: '600px', overflowY: 'auto' }} className="p-2">
        <Grid container spacing={2}>
          {filteredMarketData.slice(0, 18).map((item) => {
            return (
              <Grid item xs={4} key={item.id}>
                <Card style={{ height: '226px' }}>
                  <CardContent>
                    <div className="d-flex p-1">
                      <div>
                        <img alt={item.source} src={item.image} height="186" />
                      </div>
                      <div className="pl-4 w-100">
                        <h5
                          style={{
                            color: 'rgb(54, 162, 235)',
                            cursor: 'pointer',
                            textDecoration: 'underline'
                          }}
                          onClick={() => {
                            if (item.addId) {
                              window.open(item.addId, '_blank');
                            }
                          }}
                        >{`${item.name}, ${item.offeredBy}, ${item.source}`}</h5>
                        {item.description && (
                          <Tooltip title={item.description.substring(0, 1200)}>
                            <InfoIcon style={{ float: 'right' }} />
                          </Tooltip>
                        )}
                        <p>
                          {`${item.manufacturerYear}, ${fuelTypes[item.fuelType]},
                        ${item.kmNo} km${
                            item.transmissionType ? ', ' + item.transmissionType : ''
                          }`}
                          <br />
                          {`${item.county}${item.postalCode ? ', ' + item.postalCode : ''}`}
                        </p>
                        <div className="mt-4">
                          <h5>{`${item.price} EUR`}</h5>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Paper>
  );
};

export default RecentAdds;
