import React from 'react';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { IAuthService } from '../../services/Interfaces/IAuthService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { AppState, ApplicationState, AppActionCreators } from '../../store';
import { connect } from 'react-redux';
import { Organization, OrganizationType } from '../../interfaces/Organization';
import { ITariffService } from '../../services/Interfaces/ITariffService';
import { ProviderContext, withSnackbar } from 'notistack';

type LogInProps = AppState & typeof AppActionCreators & ProviderContext;

class Login extends React.PureComponent<
  LogInProps,
  { user: any; api: Organization[]; organization: any }
> {
  private authService!: IAuthService;
  private organizationService!: IOrganizationService;
  private tariffService!: ITariffService;

  static contextType = ApplicationContext;

  constructor(props: any) {
    super(props);
  }

  public componentDidMount() {
    this.authService = (this.context as AppContext).authenticationService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.tariffService = (this.context as AppContext).tariffService;

    this.getUser();
  }

  public login = () => {
    this.authService!.loginAsync();
  };

  public callApi = async () => {
    try {
      const result = await this.tariffService.GetTariffTypes(1);
      //  this.setState({ api: result });

      console.log('Api return successfully data, check in section - Api response');
    } catch (error) {
      console.log('msg - ' + error);
    }
  };

  public addApi = async () => {
    try {
      //let result = await this.organizationService.AddOrganizationType(orgType);
      //this.setState({ organization: result });

      console.log('Api return successfully data, check in section - Api response');
    } catch (error) {
      console.log('msg - ' + error);
    }
  };

  public updateApi = async () => {
    try {
      const orgType: OrganizationType = {
        code: 'TEST',
        gtId: 1,
        id: 6,
        isActive: true,
        name: 'TEST',
        translations: [
          { language: 'ro-RO', name: 'TEST', id: 0 },
          { language: 'en-EN', name: 'TEST', id: 0 }
        ],
        displayName: ''
      };

      await this.organizationService.UpdateOrganizationType(orgType);
      // this.setState({ api: result });

      console.log('Api return successfully data, check in section - Api response');
    } catch (error) {
      console.log('msg - ' + error);
    }
  };

  public renewToken = () => {
    this.authService
      .renewTokenAsync()
      .then((user) => {
        console.log('Token has been sucessfully renewed. :-)');
        this.props.SetUser(this.props.enqueueSnackbar);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  public logout = () => {
    this.authService.logoutAsync();
  };

  public getUser = () => {
    this.authService.getUserAsync().then((user) => {
      if (user) {
        console.log(user);
        console.log(user!.expires_at);
        console.log('User has been successfully loaded from store.');
      } else {
        console.info('You are not logged in.');
      }
    });
  };

  public render() {
    return (
      <div className="row">
        <div className="col-md-12 text-center" style={{ marginTop: '30px' }}>
          <span> {this.props.user != null ? this.props.user.expires_at : ''}</span>
          <span> {this.props.user != null ? this.props.user.profile.name : ''}</span>
          <button
            className="btn btn-primary btn-login"
            style={{ margin: '10px' }}
            onClick={this.login}
          >
            Login
          </button>
          <button
            className="btn btn-secondary btn-getuser"
            style={{ margin: '10px' }}
            onClick={this.getUser}
          >
            Get User info
          </button>
          <button
            className="btn btn-warning btn-getapi"
            style={{ margin: '10px' }}
            onClick={this.callApi}
          >
            Call API
          </button>

          <button
            className="btn btn-warning btn-getapi"
            style={{ margin: '10px' }}
            onClick={this.addApi}
          >
            Add API
          </button>

          <button
            className="btn btn-warning btn-getapi"
            style={{ margin: '10px' }}
            onClick={this.updateApi}
          >
            Update API
          </button>
          <button
            className="btn btn-success btn-renewtoken"
            style={{ margin: '10px' }}
            onClick={this.renewToken}
          >
            Renew Token
          </button>
          <button
            className="btn btn-dark btn-logout"
            style={{ margin: '10px' }}
            onClick={this.logout}
          >
            Logout
          </button>
        </div>
      </div>
    );
  }
}

export default connect((state: ApplicationState) => state.app, AppActionCreators)(withSnackbar(Login as any));
