import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Tooltip,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { ReferentialCode } from '../../../helpers/Constants';
import Referentials from '../../../helpers/Referentials.json';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { CarClassRentPrice } from '../../../interfaces/Case';
import NumberFormat from 'react-number-format';

interface ICarClassRentalPriceState {
  newCarClassRentPrice: CarClassRentPrice;
  carClasses: IReferential[];
  carClassRentPrices: CarClassRentPrice[];
  isLoading: boolean;
  isDialogOpen: boolean;
  newCarClassRentPriceId: number | null;
}

type ICarClassRentalPriceProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class CarClassRentalPrice extends React.PureComponent<
  ICarClassRentalPriceProps,
  ICarClassRentalPriceState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;


  static contextType = ApplicationContext;
  private newCarClassRentPrice = {
    id: 0,
    carClassId: 0,
    price: null
  } as unknown as CarClassRentPrice;

  state = {
    carClassRentPrices: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    newCarClassRentPrice: this.newCarClassRentPrice,
    carClasses: [],
    newCarClassRentPriceId: null
  } as ICarClassRentalPriceState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCarClassRentPrices();
      }
    );
  }

  loadCarClassRentPrices = async () => {
    const carClassPrices = await this.caseService.GetCarClassRentPrices();
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarClass
    );
    const carClasses = (await this.appReferentialService.Get(ref!.baseUrl)).filter((item) => item.isActive === true);

    this.setState({
      carClassRentPrices: carClassPrices,
      isLoading: false,
      carClasses: carClasses,
      newCarClassRentPrice: { ...this.newCarClassRentPrice }
    });
  };


 

  private handlePriceChange = (value: string) => {
    this.setState({
      newCarClassRentPrice: {
        ...this.state.newCarClassRentPrice,
        price: parseFloat(value)
      }
    });
  };

  private handleCarClassChange = (newValue: IReferential | null) => {
    this.setState({
      newCarClassRentPrice: {
        ...this.state.newCarClassRentPrice,
        carClass: newValue,
        carClassId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  public saveCarClassRentalPrice = async () => {
    if (this.state.newCarClassRentPriceId === null) {
      try {

        await this.caseService.AddCarClassRentPrice(this.state.newCarClassRentPrice);

        await this.loadCarClassRentPrices();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    } else {
      try {

        await this.caseService.UpdateCarClassRentPrice(this.state.newCarClassRentPrice);

        await this.loadCarClassRentPrices();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };
  
  
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  

  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveCarClassRentalPrice} instantValidate={true}>
        <TextValidator
          fullWidth
          name="price"
          className="m-2"
          id="price"
          value={this.state.newCarClassRentPrice.price || ''}
          onChange={(e: any) => this.handlePriceChange(e.target.value)}
          label={this.translatorService.Tranlate(
            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_PRICE_LABEL',
            'Pret'
          )}
          validators={['required', 'minNumber:1']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
          InputProps={{
            inputComponent: this.NumberFormatCustom,
            style: { fontWeight: 500 }
          }}
        />

        <Autocomplete
          id="carClass"
          className="m-2"
          options={this.state.carClasses}
          value={this.state.newCarClassRentPrice.carClass}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleCarClassChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName || ''}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="carClass"
              value={this.state.newCarClassRentPrice.carClass}
              label={this.translatorService.Tranlate(
                'CAR_CLASS',
                'Clasa masina'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          )}
        />
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'carClass',
        label: this.translatorService.Tranlate(
          'CAR_CLASS',
          'Clasa masina'
        ),
        options: {
          sort: true,
          customSort: (a: IReferential, b: IReferential) => {
            return a.name!.localeCompare(b.name!);
          },
          customBodyRender: (value: IReferential) => {
            return value.name;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'price',
        label: this.translatorService.Tranlate(
          'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_PRICE_LABEL',
          'Pret'
        ),
        options: {
          filter: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            if (value) {
              return value.toFixed(2);
            } 
            return '';
          }
        }
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newCarClassPopup(tableMeta.rowData[0])}
                >
                    <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteCarClassRentPrice(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];

    return columns;
  };

  deleteCarClassRentPrice = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.caseService.RemoveCarClassRentPrice(id);
      await this.loadCarClassRentPrices();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  newCarClassPopup = async (carClassRentalPriceId: number | null) => {

    let newCarClassRentPrice: CarClassRentPrice;
    if (carClassRentalPriceId == null) {
      newCarClassRentPrice = { ...this.state.newCarClassRentPrice } as CarClassRentPrice;
    } else {
      newCarClassRentPrice = this.state.carClassRentPrices.find((item) => item.id === carClassRentalPriceId)!;
    }
    this.setState({
      isDialogOpen: true,
      newCarClassRentPrice: newCarClassRentPrice,
      newCarClassRentPriceId: carClassRentalPriceId

    });
  };

  closeDialog = async () => {
    this.setState(
        { 
          isDialogOpen: false, 
          newCarClassRentPrice: this.newCarClassRentPrice 
        }
      );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      // responsive: "stacked",
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newCarClassPopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.carClassRentPrices}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                {!this.state.newCarClassRentPriceId ? 
                  this.translatorService.Tranlate(
                      'CAR_CLASS_RENT_PRICE_NEW_MODAL_TITLE',
                      'Adauga preturi inchiriere/zi'
                    ) : this.translatorService.Tranlate(
                      'CAR_CLASS_RENT_PRICE_UPDATE_MODAL_TITLE',
                      'Actualizeaza preturi inchiriere/zi'
                    )}               
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{this.renderAddForm()}</DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(CarClassRentalPrice as any));
