import { IReferentialService } from './Interfaces/IReferentialService';
import { IReferential } from '../interfaces/IReferential';
import { API } from '../helpers/API';
import { ICache } from '../interfaces/Icache';

export class ReferentialService implements IReferentialService {
  private cache: ICache[];

  constructor(cache: ICache[]) {
    this.cache = cache;
  }

  public async Get(url: string): Promise<IReferential[]> {
    const res = this.cache.find((item) => item.url === url);
    if (res !== undefined) {
      return res.values;
    }

    const response = await API.GetAsync<IReferential[]>(url);

    this.cache.push({ url: url, values: response.data });

    return response.data;
  }
  public async GetFromDB(url: string): Promise<IReferential[]> {
    const response = await API.GetAsync<IReferential[]>(url);
    return response.data;
  }

  public async Add(referential: IReferential, url: string): Promise<IReferential> {
    return (await API.PostAsync<IReferential>(url, referential)).data;
  }
  public async Update(referential: IReferential, url: string): Promise<void> {
    await API.PutAsync<IReferential>(url, referential);
  }
  Delete(referencial: IReferential, url: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
