import { AppThunkAction } from '.././';
import * as ActionTypes from './ActionTypes';

interface ThemeSetSidebarToggleAction {
  type: typeof ActionTypes.THEME_SET_SIDEBAR_TOGGLE;
  toggle: boolean;
}

interface ThemeSetSidebarToggleMobileAction {
  type: typeof ActionTypes.THEME_SET_SIDEBAR_TOGGLE_MOBILE;
  toggle: boolean;
}

interface ThemeSetSidebarHoverAction {
  type: typeof ActionTypes.THEME_SET_SIDEBAR_HOVER;
  hover: boolean;
}

export type ThemeAction =
  | ThemeSetSidebarHoverAction
  | ThemeSetSidebarToggleAction
  | ThemeSetSidebarToggleMobileAction;

export const ThemeActionCreators = {
  setSidebarToggle:
    (toggle: boolean): AppThunkAction<ThemeAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.THEME_SET_SIDEBAR_TOGGLE, toggle: toggle });
    },
  setSidebarToggleMobile:
    (toggle: boolean): AppThunkAction<ThemeAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.THEME_SET_SIDEBAR_TOGGLE_MOBILE, toggle: toggle });
    },

  setSidebarHover:
    (hover: boolean): AppThunkAction<ThemeAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.THEME_SET_SIDEBAR_HOVER, hover: hover });
    }
};
