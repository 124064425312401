import React, { useContext } from 'react';
import { Card, Grid } from '@material-ui/core';
import { ApplicationContext } from '../../../context/Contexts';

interface Props {
  count: number;
  averageMileage: number;
  averageVehicleAge: number;
}

const VehicleAverages: React.FC<Props> = ({ count, averageMileage, averageVehicleAge }) => {
  const context = useContext(ApplicationContext);

  return (
    <>
      <Grid item xs={2}>
        <Card className="h-100">
          <div className="p-2 text-center">
            <h5>{context.translatorService.Tranlate('DASHBOARD_TOTAL_INVENTORY', 'Total stoc')}</h5>
            <h2>{count}</h2>
          </div>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card className="h-100">
          <div className="p-2 text-center">
            <h5>
              {context.translatorService.Tranlate('DASHBOARD_AVERAGE_MILEAGE', 'Rulaj mediu')}
            </h5>
            <h2>{`${averageMileage} km`}</h2>
          </div>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card className="h-100">
          <div className="p-2 text-center">
            <h5>
              {context.translatorService.Tranlate('DASHBOARD_AVERAGE_VEHICLE_AGE', 'Varsta medie')}
            </h5>
            <h2>{`${averageVehicleAge} ani`}</h2>
          </div>
        </Card>
      </Grid>
    </>
  );
};

export default VehicleAverages;
