import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  IconButton,
  GridList,
  GridListTile,
  GridListTileBar,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { CaseAttachment } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import { ReferentialCode, AttachmentTypeCode } from '../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { DropzoneArea } from 'material-ui-dropzone';
import FileSaver from 'file-saver';
import Carousel, { Modal, ModalGateway } from 'react-images';
import GetAppIcon from '@material-ui/icons/GetApp';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { AppUser } from '../../interfaces/AppUser';
import { IReferential } from '../../interfaces/IReferential';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import CloseIcon from '@material-ui/icons/Close';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

interface ICaseDetailsCaseTabCarPhotosFormState {
  caseCarPhotos: CaseAttachment[];
  imgType: IReferential;
  isLoading: boolean;
  isUploading: boolean;
  currentImage: number;
  viewerIsOpen: boolean;
  hasRights: boolean;
  executing: boolean;
  isDialogOpen: boolean;
}

export interface ExternalCaseDetailsCaseTabCarPhotosFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabCarPhotosFormProps = ExternalCaseDetailsCaseTabCarPhotosFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabCarPhotosForm extends React.PureComponent<
  ICaseDetailsCaseTabCarPhotosFormProps,
  ICaseDetailsCaseTabCarPhotosFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    caseCarPhotos: [],
    attachmentTypeList: [],
    imgType: {} as IReferential,
    isLoading: false,
    currentImage: 0,
    viewerIsOpen: false,
    hasRights: false,
    executing: false,
    isDialogOpen: false,
    isUploading: false
  } as ICaseDetailsCaseTabCarPhotosFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseDocumentsForm();
      }
    );
  }

  loadCaseDocumentsForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);

    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );

    const [caseCarPhotos, attachmentTypes] = await Promise.all([
      this.caseService.GetCaseAttachments(caseId, [AttachmentTypeCode.IMG]),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    const photosProm = [] as any[];
    caseCarPhotos.forEach((element) => {
      photosProm.push(this.caseService.GetCaseAttachment(element.fileName));
    });

    const photos = await Promise.all([...photosProm]);

    caseCarPhotos.forEach((elem, index) => {
      elem.file = photos[index];
    });
    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    this.setState({
      caseCarPhotos: caseCarPhotos.sort(function (a: any, b: any) {
        return b.uploadDate - a.uploadDate;
      }),
      imgType: attachmentTypes.find((item) => item.code === AttachmentTypeCode.IMG)!,
      isLoading: false,
      hasRights: hasRights
    });
  };

  handleUploadAttachment = async (files: File[]) => {
    try {
      if (files.length === 0) {
        return;
      }
      this.setState({ isUploading: true });

      const attachments: CaseAttachment[] = [];
      files.forEach((file) => {
        attachments.push({
          id: 0,
          caseId: this.props.caseSettingsState.case!.id,
          attachmentTypeId: this.state.imgType.id,
          attachmentType: this.state.imgType,
          file: file,
          isSelection: false
        } as CaseAttachment);
      });

      const newFiles = await this.caseService.AddCaseAttachment(attachments);
      const caseCarPhotos = [...this.state.caseCarPhotos];

      for (const item of newFiles) {
        item.file = (await this.caseService.GetCaseAttachment(item.fileName)) as File;
      }

      caseCarPhotos.push(...newFiles);

      this.setState({
        caseCarPhotos: caseCarPhotos,
        isUploading: false
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  downloadFile = async (file: File, origFileName: string) => {
    FileSaver.saveAs(file, origFileName);
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      await this.caseService.RemoveCaseAttachment(fileName);

      const caseAttachments = [...this.state.caseCarPhotos];
      const index = caseAttachments.findIndex((item) => item.id === id);
      caseAttachments.splice(index, 1);

      this.setState({
        caseCarPhotos: caseAttachments
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  handleConfirmChanges = async () => {
    try {
      if (this.state.caseCarPhotos.length === 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'CASE_CAR_PHOTOS_FORM_AT_LEAST_ONE_PICTURE_ERROR',
            'Trebuie sa incarcati cel putin o poza!'
          ),
          { variant: 'error' }
        );
        return;
      }

      this.setState({ executing: true });

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.translatorService.Tranlate(ex.response.data, 'Eroare incarcare poze!'),
        { variant: 'error' }
      );
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }
    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  openLightbox = (index: number) => {
    this.setState({ currentImage: index, viewerIsOpen: true });
  };

  closeLightbox = () => {
    this.setState({ currentImage: 0, viewerIsOpen: false });
  };

  downloadAll = async () => {
    const fileBlob = await this.caseService.GetCaseAttachmentsArchive(
      this.props.caseSettingsState.case!.id,
      null,
      [AttachmentTypeCode.IMG]
    );
    FileSaver.saveAs(
      fileBlob,
      this.props.caseSettingsState.case!.id + '_' + this.props.workflowForm.displayName + '.zip'
    );
  };

  handleTakePhoto = async (dataUri: any) => {
    const arr = dataUri.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    let i = n;

    while (i--) {
      u8arr[i] = bstr.charCodeAt(i);
    }

    const files = [
      new File([u8arr], 'captured_image_' + Math.random().toString(36).slice(2) + '.png', {
        type: mime
      })
    ];
    this.handleUploadAttachment(files);
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div>
            {this.state.hasRights ? (
              <div className="m-3 text-center">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                      <ScaleLoader color={'var(--primary)'} loading={this.state.isUploading} />
                    </div>
                    {!this.state.isUploading ? (
                      <DropzoneArea
                        dropzoneClass="custom-dropzone m-0 w-100"
                        dropzoneText={this.translatorService.Tranlate(
                          'CASE_CAR_PHOTOS_FORM_UPLOAD_IMAGE',
                          'Drag and drop an image here or click!'
                        )}
                        showPreviews={false}
                        showPreviewsInDropzone={false}
                        clearOnUnmount={true}
                        filesLimit={25}
                        onChange={(files: File[]) => this.handleUploadAttachment(files)}
                        previewText=""
                        showAlerts={true}
                        acceptedFiles={['image/*']}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      onClick={(e) => this.setState({ isDialogOpen: true })}
                      style={{ borderRadius: '10px', cursor: 'pointer', borderStyle: 'dashed' }}
                      className="h-100 border-2"
                    >
                      <p
                        style={{
                          marginTop: '24px',
                          marginBottom: '24px',
                          fontSize: '16px',
                          fontWeight: 500
                        }}
                      >
                        {this.translatorService.Tranlate(
                          'CASE_DATA_TAKE_PHOTO_TITLE',
                          'Fotografiaza'
                        )}
                      </p>
                      <IconButton aria-label="photo" color="inherit" className="p-0">
                        <PhotoCameraOutlinedIcon style={{ fontSize: '48px' }} />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ) : (
              ''
            )}
            <div className="d-flex flex-wrap justify-content-around mx-3">
              <GridList cellHeight={180}>
                {this.state.caseCarPhotos.map((item: CaseAttachment, index: number) => (
                  <GridListTile key={item.id} className="py-3 px-3">
                    <div className="d-flex align-items-center w-100 h-100">
                      <img
                        src={URL.createObjectURL(item.file)}
                        alt=""
                        className="img-fluid"
                        onClick={(e) => this.openLightbox(index)}
                      />
                      <GridListTileBar
                        subtitle={item.originalFileName}
                        className="h-25"
                        style={{ backgroundColor: '#000000' }}
                        actionIcon={
                          <div>
                            <IconButton
                              aria-label="download"
                              color="inherit"
                              className="text-success"
                              size="small"
                              onClick={(e) => this.downloadFile(item.file, item.originalFileName)}
                            >
                              <GetAppIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              color="inherit"
                              className="text-danger"
                              size="small"
                              onClick={(e) => this.handleDeleteAttachment(item.id, item.fileName)}
                              hidden={!this.state.hasRights}
                            >
                              <DeleteOutlineTwoToneIcon />
                            </IconButton>
                          </div>
                        }
                      />
                    </div>
                  </GridListTile>
                ))}
              </GridList>
              <ModalGateway>
                {this.state.viewerIsOpen ? (
                  <Modal onClose={this.closeLightbox}>
                    <Carousel
                      currentIndex={this.state.currentImage}
                      views={this.state.caseCarPhotos.map((x) => ({
                        ...x,
                        source: URL.createObjectURL(x.file)
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
            <div className="text-left mb-2">
              <Button
                className="m-4"
                variant="contained"
                color="secondary"
                onClick={this.downloadAll}
                disabled={this.state.executing}
                hidden={this.state.caseCarPhotos.length === 0}
              >
                <GetAppIcon />{' '}
                {this.translatorService.Tranlate('CASE_DOCUMENTS_FORM_DOWNLOAD_ALL', 'Descarca')}
              </Button>
            </div>
            <div className="text-center">
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                onClick={this.handleConfirmChanges}
                disabled={!this.state.hasRights || this.state.executing}
              >
                {this.translatorService.Tranlate('CASE_CAR_PHOTOS_FORM_CONFIRM_BUTTON', 'Confirma')}
              </Button>
            </div>
          </div>
        ) : null}
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate('CASE_DATA_TAKE_PHOTO_TITLE', 'Fotografiaza')}
                </Typography>
              </Grid>
              <Grid className="text-right" item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Camera
              onTakePhoto={(dataUri: any) => {
                this.handleTakePhoto(dataUri);
              }}
              onTakePhotoAnimationDone={(dataUri: any) => {
                this.closeDialog();
              }}
              mirrored={true}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabCarPhotosFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabCarPhotosForm as any));
