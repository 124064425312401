export const FormCode = {
  SEARCH_NOTICE: 'SEARCH_NOTICE',
  SEARCH_VEHICLE: 'SEARCH_VEHICLE',
  SEARCH_CLIENT: 'SEARCH_CLIENT',
  SEARCH_CLAIM_REVIEW: 'SEARCH_CLAIM_REVIEW',
  SELECT_WORKFLOW: 'SELECT_WORKFLOW',
  CASE_SUMMARY: 'F1',
  CASE_HISTORY: 'F7',
  CASE_CHANGE_WORKFLOW: 'F6',
  CASE_ATTACHMENTS: 'F4',
  CASE_CAR_PHOTOS: 'F5',
  CASE_CAR_DATA: 'F2',
  CASE_EVENT_DATA: 'F3',
  CASE_CALCULATIONS: 'F8',
  CASE_BACK_TO_SERVICE: 'F9',
  CASE_OTHER_SERVICE: 'F10',
  CASE_PAYMENT_DETAILS_SERVICE: 'F11',
  CASE_ORDER: 'F12',
  CASE_SERVICE_APPOINTMENT: 'F13',
  CASE_CLIENT_DATA: 'F14',
  CASE_CAR_DETAILS: 'F15',
  CASE_CAR_REPAIR_COST: 'F16',
  CASE_SALE_OFFER: 'F17',
  CASE_BUY_OFFER: 'F18',
  CASE_PAYMENT_DETAILS_WITH_CURRENCY: 'F19',
  CASE_VEHICLE_ADVERT: 'F20',
  CASE_VEHICLE_PB_ANUNT: 'PB_ANUNT',
  CASE_EVENT_DATE_FACT: 'F21',
  CASE_SOLD_CAR: 'F22',
  CASE_LKQ_APPOINTMENT:'F23',
  CASE_IN_OUT_REPORT: 'F24',
  CARFIX_CASE_CLIENT_DATA: 'F25',
  CARFIX_ANALYSIS_STEP: 'F26',
  CARFIX_BACK_TO_SERVICE_STEP: 'F27',
  CARFIX_APPOINTMENT_VALIDATION_STEP: 'F28',
  CARFIX_REPAIR_IN_PROGRESS_STEP: 'F29',
  CAR_IN_SERVICE: 'F30'
};

export const SectionCode = {
  S1: 'S1',
  S2: 'S2',
  Calculatii: 'S3',
  Comenzi: 'S4',
  Constatare: 'S5',
  Reparatie: 'S6',
  Plati: 'S7',
  Cautare: 'Caut_are',
  Constatare_Efectuata: 'Cons_efct',
  Reconstatare: 'REC',
  Operati_speciale: 'OPE',
  Ofertare: 'OFR',
  Comanda_Piese: 'COM_PES',
  Inchidere_Dosar: 'IN_DOS',
  Oferte: 'S9',
  Anunturi: 'S10',
  Evaluare: 'S25',
};
