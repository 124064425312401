import { AppContext } from '../../context/Contexts';
import { IReferential } from '../../interfaces/IReferential';
import { MavoVehicle, MavoVehicleStatusHistory, Vehicle, VehicleStatusHistory } from '../../interfaces/Vehicle';
import { AppThunkAction } from '.././';
import * as ActionTypes from './ActionTypes';

interface SetMavoVehicleAction {
  type: typeof ActionTypes.MAVO_VEHICLE_SET_VEHICLE;
  mavoVehicle: MavoVehicle | null;
}
interface SetMavoVehicleStatusAction {
  type: typeof ActionTypes.MAVO_VEHICLE_SET_VEHICLE_STATUS;
  status: MavoVehicleStatusHistory;
}

export type MavoVehicleAction = SetMavoVehicleAction | SetMavoVehicleStatusAction;

export const MavoVehicleActionCreators = {
  setMavoVehicle:
    (vehicleId: number): AppThunkAction<MavoVehicleAction> =>
    async (dispatch, getState, context) => {
      const vehicleService = (context as AppContext).vehicleService;

      const vehicle = await vehicleService.GetMavoVehicle(vehicleId);

      if (vehicle.vehicleOptionInfo === null) {
        vehicle.vehicleOptionInfo = {
          vehicleId: vehicleId,
          vehicleTypeId: null,
          engineCapacity: null,
          emissionsClass: null,
          doorCount: null,
          seatCount: null,
          driveType: null,
          registration: null,
          vehicleCondition: null,
          metallicColor: null,
          upholstery: null,
          upholsteryColor: null,
          previousOwnersCount: null,
          countryOfOrigin: null,
          alarm: false,
          antiTheft: false,
          abs: false,
          esp: false,
          driverFrontAirbag: false,
          passengerFrontAirbag: false,
          frontSideAirbags: false,
          frontCurtainAirbag: false,
          xenonHeadlights: false,
          alloyWheels: false,
          glassRoof: false,
          towHook: false,
          protectionFrame: false,
          steeringWheelControls: false,
          onBoardComputer: false,
          cruiseControl: false,
          lockableDifferential: false,
          particleFilter: false,
          electricMirrors: false,
          frontElectricWindows: false,
          rearElectricWindows: false,
          tintedWindows: false,
          heatedSeats: false,
          rainSensors: false,
          parkingSensors: false,
          powerSteering: false,
          sunroof: false,
          rightHandDrive: false,
          fogLights: false,
          airConditioning: false,
          radio: false,
          cdPlayer: false,
          videoScreen: false,
          gps: false,
          vehicleType: null
        };
      }

      dispatch({ type: ActionTypes.MAVO_VEHICLE_SET_VEHICLE, mavoVehicle: vehicle as unknown as MavoVehicle });
    },

  setMavoVehicleObject:
    (vehicle: MavoVehicle | null): AppThunkAction<MavoVehicleAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.MAVO_VEHICLE_SET_VEHICLE, mavoVehicle: vehicle as unknown as MavoVehicle });
    },

  changeMavoVehicleStatus:
    (statusHis: MavoVehicleStatusHistory): AppThunkAction<MavoVehicleAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.MAVO_VEHICLE_SET_VEHICLE_STATUS, status: statusHis });
    }
};
