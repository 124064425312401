import React, { Fragment, useContext } from 'react';

import clsx from 'clsx';
import { ApplicationContext } from '../../context/Contexts';
import { Avatar, Box, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThemeActionCreators } from '../../store';

const SidebarUserbox = (props) => {
  const { sidebarToggle, sidebarHover, propsContainer } = props;

  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;
  return (
    <Fragment>
      <Box
        className={clsx('app-sidebar-userbox', {
          'app-sidebar-userbox--collapsed': sidebarToggle && !sidebarHover
        })}
      >
        <Avatar
          alt="Remy Sharp"
          //src={avatar}
          className="app-sidebar-userbox-avatar"
        />
        <Box className="app-sidebar-userbox-name">
          <Box>
            <div className="pl-3 ">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {props.appUser ? props.appUser.lastName + ' ' + props.appUser.firstName : null}
              </div>
              <div className="text-center pt-2 line-height-1">
                {props.appUser ? props.appUser.email : null}
              </div>
              <div className="text-center text-black-50 pb-2">
                {props.appUser ? props.appUser.jobFunction : null}
              </div>
            </div>
          </Box>
          <Box className="app-sidebar-userbox-btn-profile">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              className="text-white"
              onClick={(e) => {
                props.dispatch(ThemeActionCreators.setSidebarToggleMobile(false));
                props.history.push('/profile');
              }}
            >
              {translatorService.Tranlate('VIEW_PROFILE', 'Vizualizare profil')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.themeOptions.sidebarToggle,
  sidebarHover: state.themeOptions.sidebarHover,
  appUser: state.app.appUser,
  language: state.app.language
});

export default connect(mapStateToProps)(withRouter(SidebarUserbox));
