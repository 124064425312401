import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Box,
  Select,
  MenuItem,
  Card,
  CardContent,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListSubheader,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { WorkflowReminder, WorkflowPartner, EmailTemplate } from '../../../interfaces/Workflow';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { withSnackbar, ProviderContext } from 'notistack';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { ScaleLoader } from 'react-spinners';
import MaterialTable, { Column, EditComponentProps, Icons } from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { forwardRef } from 'react';
import { IReferential } from '../../../interfaces/IReferential';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import SubjectIco from '@material-ui/icons/Subject';
import * as R from 'ramda';
import { mailPlaceHolders } from '../mailPlaceHolders';
import { toBase64 } from '../../../utils/toBase64';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

interface IWorkflowRemindersState {
  workflowReminders: WorkflowReminder[];
  isLoading: boolean;
  workflowId: number;
  workflowPartnerList: WorkflowPartner[];
  caseStatusTypeList: IReferential[];
  emailList: EmailTemplate[];
  emailToList: IReferential[];
  isDialogEmailTemplateOpen: boolean;
  reminderId: number;
  selectedEmailBodyTemplate: EditorState;
  selectedEmailSubjectTemplate: string;
}

type WorkflowRemindersProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowReminders extends React.PureComponent<
  WorkflowRemindersProps,
  IWorkflowRemindersState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowReminders: [],
    isLoading: true,
    workflowId: 0,
    workflowPartnerList: [],
    caseStatusTypeList: [],
    emailList: [],
    emailToList: [],
    isDialogEmailTemplateOpen: false,
    reminderId: 0,
    selectedEmailBodyTemplate: EditorState.createEmpty(),
    selectedEmailSubjectTemplate: ''
  } as IWorkflowRemindersState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData();
      }
    );
  };

  loadData = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);
    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    try {
      const ref = Referentials.referential.find(
        (item) => item.code === ReferentialCode.EmailRecipientType
      );
      const caseStatusRef = Referentials.referential.find(
        (item) => item.code === ReferentialCode.CaseStatus
      );

      const [data, workflowPartnerList, caseStatusTypes, emailList, emailToList] =
        await Promise.all([
          this.workflowService.GetWorkflowReminders(workflowId),
          this.workflowService.GetWorkflowPartners(workflowId),
          this.appReferentialService.Get(caseStatusRef!.baseUrl),
          this.workflowService.GetEmailTemplates(),
          this.appReferentialService.Get(ref!.baseUrl)
        ]);

      const partners = await this.organizationService.GetOrganizationsByIds(
        workflowPartnerList.map((item) => item.partnerId)
      );
      workflowPartnerList.forEach((wpItem) => {
        const partner = partners.find((item) => item.id === wpItem.partnerId);
        wpItem.partner = R.isNil(partner) ? null : partner;
      });
      data.forEach((alertItem) => {
        const wp = workflowPartnerList.find((i) => i.id === alertItem.workflowPartnerId);
        const caseStatus = caseStatusTypes.find((item) => item.id === alertItem.caseStatusId);
        alertItem.workflowPartner = R.isNil(wp) ? null : wp;
        alertItem.caseStatus = R.isNil(caseStatus) ? null : caseStatus;
      });

      this.setState({
        workflowReminders: data,
        isLoading: false,
        workflowId: workflowId,
        workflowPartnerList: workflowPartnerList.filter((item) => item.isActive === true),
        caseStatusTypeList: caseStatusTypes.filter((item) => item.isActive === true),
        emailList: emailList.filter((item) => item.isActive === true),
        emailToList: emailToList.filter((item) => item.isActive === true)
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  createWorkflowPartner = async (item: WorkflowPartner) => {
    [item.partner] = await Promise.all([this.organizationService.GetOrganization(item.partnerId)]);
    return item;
  };

  onEmailTemplateClick = (
    e: any,
    id: number,
    bodyEmailTemplate: string,
    subjectEmailTemplate: string
  ) => {
    const blocksFromHTML = htmlToDraft(bodyEmailTemplate);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    this.setState({
      isDialogEmailTemplateOpen: true,
      reminderId: id,
      selectedEmailBodyTemplate: EditorState.createWithContent(contentState),
      selectedEmailSubjectTemplate: subjectEmailTemplate
    });
  };

  getColumns = () => {
    const lookupOrganization = this.state.workflowPartnerList.reduce(function (
      acc: any,
      cur: any,
      i: number
    ) {
      acc[cur.id] = cur.partner!.name;

      return acc;
    },
    {});

    const lookupCaseStatus = this.state.caseStatusTypeList.reduce(function (
      acc: any,
      cur: any,
      i: number
    ) {
      acc[cur.id] = cur.displayName;

      return acc;
    },
    {});

    const lookupEmail = this.state.emailList.reduce(function (acc: any, cur: any, i: number) {
      acc[cur.id] = cur.displayName;

      return acc;
    }, {});

    const lookupEmailTo = this.state.emailToList.reduce(function (acc: any, cur: any, i: number) {
      acc[cur.id] = cur.displayName;

      return acc;
    }, {});

    return [
      {
        field: 'workflowPartnerId',
        title: this.translatorService.Tranlate(
          'WORKFLOW_REMINDERS_TAB_ORGANIZATION_HEADER',
          'Organizatie'
        ),
        searchable: true,
        lookup: lookupOrganization,
        filtering: true,
        render: (rowData: WorkflowReminder) => {
          return rowData.workflowPartner!.partner!.displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              fullWidth={true}
              value={props.value || ''}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.workflowPartnerList
                .sort(function (a: WorkflowPartner, b: WorkflowPartner) {
                  return a.partner!.displayName.localeCompare(b.partner!.displayName);
                })
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.partner!.displayName}
                  </MenuItem>
                ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'caseStatusId',
        title: this.translatorService.Tranlate(
          'WORKFLOW_REMINDERS_TAB_CASE_STATUS_HEADER',
          'Stare Dosar'
        ),
        searchable: true,
        filtering: false,
        lookup: lookupCaseStatus,
        render: (rowData: WorkflowReminder) => {
          return rowData.caseStatus === null ? '' : rowData.caseStatus!.displayName;
        },

        editComponent: (props: EditComponentProps<any>) => {
          let caseStatusIdList = [] as any[];
          if (!Array.isArray(props.value) && R.isNil(props.value)) {
            caseStatusIdList = caseStatusIdList;
          } else if (!Array.isArray(props.value) && !R.isNil(props.value)) {
            caseStatusIdList.push(props.value);
          } else {
            caseStatusIdList = R.isNil(props.value) ? [] : props.value;
          }
          return (
            <Select
              fullWidth={true}
              multiple
              value={caseStatusIdList || []}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.caseStatusTypeList
                .sort(function (a: any, b: any) {
                  return a.displayName.localeCompare(b.displayName);
                })
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.displayName}
                  </MenuItem>
                ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'emailTemplateId',
        title: this.translatorService.Tranlate('WORKFLOW_REMINDERS_TAB_EMAIL_HEADER', 'Email'),
        searchable: true,
        filtering: false,
        lookup: lookupEmail,
        render: (rowData: WorkflowReminder) => {
          return (
            <Button
              color="primary"
              className=" text-primary"
              onClick={(e) =>
                this.onEmailTemplateClick(e, rowData.id, rowData.body, rowData.subject)
              }
            >
              <span>{rowData.emailTemplate!.displayName}</span>
            </Button>
          );
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              fullWidth={true}
              value={props.value || ''}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.emailList
                .sort(function (a: any, b: any) {
                  return a.displayName.localeCompare(b.displayName);
                })
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.displayName}
                  </MenuItem>
                ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'numberOfHours',
        title: this.translatorService.Tranlate(
          'WORKFLOW_REMINDERS_TAB_NR_HOURS_HEADER',
          'Numar Ore'
        ),
        searchable: true,
        filtering: false,
        type: 'numeric'
      } as Column<any>,
      {
        field: 'emailRecipientTypeId',
        title: this.translatorService.Tranlate('WORKFLOW_REMINDERS_TAB_EMAIL_TO_HEADER', 'Catre'),
        searchable: true,
        filtering: false,
        lookup: lookupEmailTo,
        render: (rowData: WorkflowReminder) => {
          return rowData.emailRecipientType!.displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              fullWidth={true}
              value={props.value || ''}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.emailToList
                .sort(function (a: any, b: any) {
                  return a.displayName.localeCompare(b.displayName);
                })
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.displayName}
                  </MenuItem>
                ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'dateModification',
        title: this.translatorService.Tranlate(
          'WORKFLOW_REMINDERS_TAB_MODIFIED_DATE_HEADER',
          'Data modificare'
        ),
        searchable: true,
        filtering: false,
        render: (rowData: any) => {
          return moment
            .utc(rowData.dateModification)
            .local()
            .format(this.props.appState.longDateFormat);
        },
        editComponent: (props: EditComponentProps<any>) => {
          return <Label>{moment(new Date()).format(this.props.appState.longDateFormat)}</Label>;
        }
      } as Column<any>
    ];
  };

  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        deleteTooltip: this.translatorService.Tranlate('DELETE', 'Sterge'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza'),
          deleteText: this.translatorService.Tranlate(
            'DELETE_TEXT',
            'Sigur doriti sa stergeti aceasta inregistrare?'
          )
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };

  onRowUpdate = (newData: WorkflowReminder, oldData?: WorkflowReminder): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          if (
            R.isNil(newData.workflowPartnerId) ||
            R.isNil(newData.emailTemplateId) ||
            R.isNil(newData.emailRecipientTypeId) ||
            R.isNil(newData.numberOfHours)
          ) {
            this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
              variant: 'error'
            });
            reject();
            return;
          }
          let caseStatusIdList = [] as any[];
          if (!Array.isArray(newData.caseStatusId) && R.isNil(newData.caseStatusId)) {
            caseStatusIdList = caseStatusIdList;
          } else if (!Array.isArray(newData.caseStatusId) && !R.isNil(newData.caseStatusId)) {
            caseStatusIdList.push(newData.caseStatusId);
          } else {
            caseStatusIdList = [...newData.caseStatusId];
          }

          if (
            this.state.workflowReminders.findIndex(
              (item) =>
                item.workflowPartnerId === newData.workflowPartnerId &&
                item.emailTemplateId === newData.emailTemplateId &&
                item.numberOfHours.toString() === newData.numberOfHours.toString() &&
                item.emailRecipientTypeId === newData.emailRecipientTypeId &&
                (item.caseStatusId === null ||
                  caseStatusIdList.length === 0 ||
                  caseStatusIdList.findIndex((itemm) => itemm === item.caseStatusId) !== -1) &&
                item.id !== newData.id
            ) !== -1
          ) {
            this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'ERROR_MSG_WORKFLOW_PERMISSION_EXISTS',
                'Permisiunea exista deja!'
              ),
              { variant: 'error' }
            );
            reject();
            return;
          }

          newData.workflowPartner = null;
          newData.emailRecipientType = null;

          if (newData.emailTemplateId !== oldData.emailTemplateId) {
            newData.subject = newData.emailTemplate!.subject;
            newData.body = newData.emailTemplate!.body;
          }
          newData.emailTemplate = null;
          newData.caseStatus = null;

          if (caseStatusIdList.length === 0) {
            const workflowAlert = newData;
            workflowAlert.caseStatusId = null;
            await this.workflowService.UpdateWorkflowReminder(workflowAlert);
          } else {
            let contor = 1;
            for (const statusId of caseStatusIdList) {
              const workflowAlert = newData;
              workflowAlert.caseStatusId = statusId;
              contor === 1
                ? await this.workflowService.UpdateWorkflowReminder(workflowAlert)
                : await this.workflowService.AddWorkflowReminder({ ...workflowAlert, id: 0 });
              contor = contor + 1;
            }
          }

          await this.loadData();
          resolve();
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowDelete = (oldData: WorkflowReminder): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.workflowService.RemoveWorkflowReminder(oldData.id);
          await this.loadData();
          resolve();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowAdd = (newData: WorkflowReminder): Promise<void> =>
    new Promise(async (resolve, reject) => {
      try {
        if (
          R.isNil(newData.workflowPartnerId) ||
          R.isNil(newData.emailTemplateId) ||
          R.isNil(newData.emailRecipientTypeId) ||
          R.isNil(newData.numberOfHours)
        ) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
          return;
        }
        let caseStatusIdList = [] as any[];
        if (!Array.isArray(newData.caseStatusId) && R.isNil(newData.caseStatusId)) {
          caseStatusIdList = caseStatusIdList;
        } else if (!Array.isArray(newData.caseStatusId) && !R.isNil(newData.caseStatusId)) {
          caseStatusIdList.push(newData.caseStatusId);
        } else {
          caseStatusIdList = newData.caseStatusId;
        }

        if (
          this.state.workflowReminders.findIndex(
            (item) =>
              item.workflowPartnerId === newData.workflowPartnerId &&
              item.emailTemplateId === newData.emailTemplateId &&
              item.numberOfHours.toString() === newData.numberOfHours.toString() &&
              item.emailRecipientTypeId === newData.emailRecipientTypeId &&
              (item.caseStatusId === null ||
                caseStatusIdList.length === 0 ||
                caseStatusIdList.findIndex((itemm) => itemm === item.caseStatusId) !== -1)
          ) !== -1
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ERROR_MSG_WORKFLOW_PERMISSION_EXISTS',
              'Permisiunea exista deja!'
            ),
            { variant: 'error' }
          );
          reject();
          return;
        }

        newData.workflowPartner = null;
        newData.emailRecipientType = null;

        const emailTemplate = this.state.emailList.find(
          (item) => item.id === newData.emailTemplateId
        );

        newData.subject = emailTemplate!.subject;
        newData.body = emailTemplate!.body;
        newData.emailTemplate = null;
        newData.caseStatus = null;

        if (caseStatusIdList.length === 0) {
          const workflowAlert = newData;
          workflowAlert.caseStatusId = null;
          await this.workflowService.AddWorkflowReminder(workflowAlert);
        } else {
          for (const statusId of caseStatusIdList) {
            const workflowAlert = newData;
            workflowAlert.caseStatusId = statusId;
            await this.workflowService.AddWorkflowReminder(workflowAlert);
          }
        }

        await this.loadData();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });

  public renderTable = () => {
    return (
      <MaterialTable
        icons={tableIcons}
        columns={this.getColumns()}
        data={this.state.workflowReminders}
        title=" "
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: any) => this.onRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData),
          onRowDelete: (oldData: any) => this.onRowDelete(oldData)
        }}
      />
    );
  };
  closeDialog = () => {
    this.setState({ isDialogEmailTemplateOpen: false });
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ selectedEmailBodyTemplate: editorState });
  };

  renderEditEmailTemplate = () => {
    return (
      <Editor
        editorState={this.state.selectedEmailBodyTemplate}
        editorStyle={{
          border: '1px solid #d1d2db',
          borderRadius: '0.29rem',
          height: '250px'
        }}
        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState)}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'history',
            'image'
          ],
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: async (file: File) => {
              const imageBase64String = await toBase64(file);
              return Promise.resolve({ data: { link: imageBase64String } });
            },
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: '140',
              width: 'auto'
            }
          }
        }}
      />
    );
  };

  saveEmailTemplateChanges = async () => {
    const id = this.state.reminderId;
    const workflowReminders = [...this.state.workflowReminders];
    const index = workflowReminders.findIndex((item) => item.id === id);
    workflowReminders[index] = {
      ...workflowReminders[index],
      body: draftToHtml(convertToRaw(this.state.selectedEmailBodyTemplate.getCurrentContent())),
      subject: this.state.selectedEmailSubjectTemplate
    };
    await this.workflowService.UpdateWorkflowReminder(workflowReminders[index]);

    this.setState(
      {
        isLoading: true,
        isDialogEmailTemplateOpen: false
      },
      async () => await this.loadData()
    );
  };

  placeHolderClick = (text: string) => {
    const editorState = this.state.selectedEmailBodyTemplate;
    const newBlockMap = convertFromHTML('{{' + text + '}}');
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const key = selectionState.getAnchorKey();
    const blocksAfter = contentState
      .getBlockMap()
      .skipUntil(function (_, k) {
        return k === key;
      })
      .skip(1)
      .toArray();
    const blocksBefore = contentState
      .getBlockMap()
      .takeUntil(function (_, k) {
        return k === key;
      })
      .toArray();

    newBlockMap.contentBlocks = blocksBefore
      .concat([contentState.getBlockForKey(key)])
      .concat(newBlockMap.contentBlocks)
      .concat(blocksAfter);

    const newContentState = ContentState.createFromBlockArray(
      newBlockMap.contentBlocks,
      newBlockMap.entityMap
    );
    const newEditorState = EditorState.createWithContent(newContentState);
    this.setState({ selectedEmailBodyTemplate: newEditorState });
  };
  addPlaceholderToSubject = (text: string) => {
    this.setState({
      selectedEmailSubjectTemplate: this.state.selectedEmailSubjectTemplate + '{{' + text + '}}'
    });
  };

  renderMailPlaceHolders = () => {
    return (
      <List component="nav" subheader={<ListSubheader>Placeholders</ListSubheader>}>
        {mailPlaceHolders.map((val, index) => (
          <ListItem button key={index} dense>
            <ListItemText primary={val} onClick={(e) => this.placeHolderClick(val)} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={(e) => this.addPlaceholderToSubject(val)}>
                <SubjectIco />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('WORKFLOWS_TAB_REMINDERS', 'Alerte')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderTable() : null}
        </Box>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogEmailTemplateOpen}
          // fullWidth={true} maxWidth="md" scroll="body" >
          fullScreen
        >
          <DialogTitle style={{ color: '#ffffff', backgroundColor: '#1F2857' }}>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_EDIT_EMAIL_TEMPLATE_MODAL_TITLE',
                    'Editeaza template-ul email-ului'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1} className="text-right">
                <IconButton
                  aria-label="close"
                  onClick={() => this.closeDialog()}
                  size={'small'}
                  style={{ color: '#ffffff' }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item sm={8} className="text-right">
                <TextField
                  fullWidth
                  className="mb-2"
                  style={{ borderRadius: '0.29rem' }}
                  label={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_EDIT_EMAIL_TEMPLATE_SUBJECT',
                    'Subiect'
                  )}
                  id="outlined-size-small"
                  value={this.state.selectedEmailSubjectTemplate}
                  onChange={(e) =>
                    this.setState({
                      selectedEmailSubjectTemplate: e.target.value
                    })
                  }
                  variant="outlined"
                  size="small"
                />
                {this.renderEditEmailTemplate()}
              </Grid>
              <Grid item sm={4} className="text-right">
                <Card elevation={1}>
                  <Box>{this.renderMailPlaceHolders()}</Box>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              {this.translatorService.Tranlate('CANCEL', 'Anuleaza')}
            </Button>
            <Button onClick={this.saveEmailTemplateChanges} color="primary">
              {this.translatorService.Tranlate('SAVE', 'Salveaza')}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: WorkflowRemindersProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(WorkflowReminders as any));
