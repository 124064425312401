import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsActionCreators } from '../../../store';
import { withSnackbar } from 'notistack';
import { ApplicationContext } from '../../../context/Contexts';
import { ExternalCaseDetailsValuationWizardContainerProps } from './ExternalCaseDetailsValuationWizardContainerProps';
import { IReferential } from '../../../interfaces/IReferential';
import { useContext } from 'react';
import * as R from 'ramda';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { VehicleInfo } from './VehicleInfo';
import { RefConstants } from '../../../helpers/Constants';
import ValuationFiltersWizardComponent from './ValuationFiltersWizardComponent';
import { ValuationVehicle } from '../../../interfaces/Case';
import { isNullOrUndefined } from 'util';
import { on } from 'process';

const initialVehicleInfo: VehicleInfo = {
  brand: null,
  model: null,
  body: null,
  generation: null,
  fuelType: null,
  powerType: null,
  transmissionType: null,
  tractionType: null
};

const ValuationFiltersWizardContainer: React.FC<ExternalCaseDetailsValuationWizardContainerProps> =
  ({
    brandTypes,
    modelTypes,
    bodyTypes,
    fuelTypes,
    tractionTypes,
    transmissionTypes,
    firstRegistrationDate,
    onWizardCallback,
    onFilterChanged,
    onShowCarList,
    onChangeFirstRegistrationDate,
    isFromValuationModule
  }) => {
    const [activeStep, setActiveStep] = useState(0);
    const context = useContext(ApplicationContext);
    const translatorService = context.translatorService;
    const referentialService = context.referentialService;
    const caseService = context.caseService;
    const [state, setVehicleInfo] = useState<VehicleInfo>(initialVehicleInfo);
    const [filteredBodyTypes, setFilteredBodyTypes] = useState<IReferential[]>([]);
    const [generationTypes, setGenerationTypes] = useState<IReferential[]>([]);
    const [filteredModels, setFilteredModels] = useState<IReferential[]>([]);
    const [filteredFuelTypes, setFilteredFuelTypes] = useState<IReferential[]>([]);
    const [filteredPowerTypes, setFilteredPowerTypes] = useState<IReferential[]>([]);
    const [filteredVehicles, setFilteredVehicles] = useState<ValuationVehicle[]>([]);
    const [filteredTractionTypes, setFilteredTractionTypes] = useState<IReferential[]>([]);
    const [filteredTransmissionTypes, setFilteredTransmissionTypes] = useState<IReferential[]>([]);
    const [localMakeList, setLocalMakeList] = useState<IReferential[] | null | undefined>([]);
    const [localBodyTypes, setLocalBodyTypes] = useState<IReferential[] | null | undefined>([]);
    const [localModels, setLocalModels] = useState<IReferential[] | null | undefined>([]);
    const [localfuelTypes, setLocalfuelTypes] = useState<IReferential[] | null | undefined>([]);
    const [localTractionTypes, setLocalTractionTypes] = useState<IReferential[] | null | undefined>(
      []
    );
    const [localTransmissionTypes, setLocalTransmissionTypes] = useState<
      IReferential[] | null | undefined
    >([]);
    const isFirstRender = useRef(true);
    const {
      vehicleMakeRef,
      bodyTypeRef,
      modelRef,
      fuelTypeRef,
      tractionTypeRef,
      transmissionTypeRef
    } = RefConstants;

    const loadMakeList = async () => await referentialService.Get(vehicleMakeRef?.baseUrl);
    const loadBodytypeList = async () => await referentialService.Get(bodyTypeRef?.baseUrl);
    const loadModelList = async () => await referentialService.Get(modelRef?.baseUrl);
    const loadFuelTypesList = async () => await referentialService.Get(fuelTypeRef?.baseUrl);
    const localTractionTypesList = async () =>
      await referentialService.Get(tractionTypeRef?.baseUrl);
    const localTransmissionTypesList = async () =>
      await referentialService.Get(transmissionTypeRef?.baseUrl);

    useEffect(() => {
      if (onShowCarList) {
        if (activeStep >= 4) {
          onShowCarList(true);
        } else {
          onShowCarList(false);
        }
      }
    }, [activeStep, onShowCarList]);

    useEffect(() => {
      const loadData = async () => {
        const [a, b, c, d, e, f] = await Promise.all([
          R.isNil(brandTypes) ? await loadMakeList() : brandTypes,
          R.isNil(bodyTypes) ? await loadBodytypeList() : bodyTypes,
          R.isNil(modelTypes) ? await loadModelList() : modelTypes,
          R.isNil(fuelTypes) ? await loadFuelTypesList() : fuelTypes,
          R.isNil(tractionTypes) ? await localTractionTypesList() : tractionTypes,
          R.isNil(transmissionTypes) ? await localTransmissionTypesList() : transmissionTypes
        ]);

        setLocalMakeList(a);
        setLocalBodyTypes(b);
        setLocalModels(c);
        setLocalfuelTypes(d);
        setLocalTractionTypes(e);
        setLocalTransmissionTypes(f);
      };

      loadData();
    }, []);

    const getSteps = () => [
      translatorService.Tranlate('WIZARD_BRAND', 'Producator'),
      translatorService.Tranlate('WIZARD_MODEL', 'Model'),
      translatorService.Tranlate('WIZARD_BODY_TYPE', 'Caroserie'),
      translatorService.Tranlate('GENERATION_TYPE', 'Generatie'),
      translatorService.Tranlate('WIZARD_FUEL', 'Combustibil'),
      translatorService.Tranlate('WIZARD_POWER', 'Putere'),
      translatorService.Tranlate('WIZARD_FUEL_TRANSMISSION', 'Transmisie'),
      translatorService.Tranlate('WIZARD_TRACTION_TYPE', 'Tractiune')
    ];

    const filterVehicles = (): ValuationVehicle[] => {
      let vehiclesResult: ValuationVehicle[] = filteredVehicles;

      if (!isNullOrUndefined(state.fuelType)) {
        const enFuelTranslation = state.fuelType.translations?.find((t) => t.language === 'en');
        if (enFuelTranslation) {
          vehiclesResult = vehiclesResult.filter((v) => v.fuelType === enFuelTranslation.name);
        }
      }

      if (!isNullOrUndefined(state.powerType)) {
        vehiclesResult = vehiclesResult.filter((v) => v.kw.toString() === state.powerType?.code);
      }

      if (!isNullOrUndefined(state.transmissionType)) {
        vehiclesResult = vehiclesResult.filter(
          (v) => v.transmission === state.transmissionType?.code
        );
      }

      if (!isNullOrUndefined(state.tractionType)) {
        vehiclesResult = vehiclesResult.filter((v) => v.drivenWheels === state.tractionType?.code);
      }

      onFilterChanged(vehiclesResult);

      return vehiclesResult;
    };

    const steps = getSteps();

    const onChange =
      (toNullProperties?: string[]) =>
      (fieldName: string) =>
      (e: any, newValue?: IReferential | null) => {
        let newState = {} as VehicleInfo;
        newState = { ...state, [fieldName]: newValue };

        toNullProperties?.forEach((element) => {
          newState = { ...newState, [element]: null };
        });

        if (isNullOrUndefined(newValue)) {
          // stay on current step if the value is cleared
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }

        // if the selected value is the same as the previous one, force the state update to trigger the useEffect
        if (state[fieldName]?.code === newValue?.code) {
          setVehicleInfo({ ...state, [fieldName]: null });
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          setTimeout(() => {
            setVehicleInfo(newState);
          }, 0);
        } else {
          setVehicleInfo(newState);
        }
      };

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false; // toggle flag after first render/mounting
        return;
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, [state]);

    const checkIfIsValidDate = (date: Date | null) => {
      const registrationTimestamp = date!.getTime();

      if (isNaN(registrationTimestamp)) {
        return null;
      }

      return date;
    };

    useEffect(() => {
      if (!R.isNil(state.brand)) {
        const fetchValuationModels = async (
          caseService: ICaseService,
          state: VehicleInfo,
          firstRegistrationDate: Date | null
        ) => {
          setFilteredModels([]);

          const valuationModels = await caseService.GetValuationModels(
            state.brand?.code,
            checkIfIsValidDate(firstRegistrationDate)
          );

          const newModels = R.innerJoin(
            (stateModel, valuationModel) =>
              stateModel.code === valuationModel.model &&
              stateModel.dependencyId === state.brand?.id,
            localModels!,
            valuationModels
          );

          newModels.forEach((model) => {
            const valuationModel = valuationModels.find(
              (valModel) => valModel.model === model.code
            );
            if (valuationModel) {
              model.extraData = valuationModel;
            }
          });

          newModels.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

          setFilteredModels(newModels);
          if (newModels.length === 1) {
            setVehicleInfo({ ...state, model: newModels[0] });
          }
        };
        fetchValuationModels(caseService, state, firstRegistrationDate);
      }
    }, [state.brand]);

    useEffect(() => {
      if (!R.isNil(state.model) && !R.isNil(state.brand)) {
        const fetchValuationBodies = async (
          caseService: ICaseService,
          state: VehicleInfo,
          firstRegistrationDate: Date | null
        ) => {
          setFilteredBodyTypes([]);
          const valuationBodies = await caseService.GetValuationBodies(
            state.brand?.code,
            state.model?.code,
            checkIfIsValidDate(firstRegistrationDate)
          );

          const newBodyTypes = R.innerJoin(
            (stateBody, valuationBody) => {
              const enBodyTranslation = stateBody.translations?.find((t) => t.language === 'en');
              if (!enBodyTranslation) return false;

              return enBodyTranslation.name === valuationBody.body;
            },
            localBodyTypes!,
            valuationBodies
          );

          newBodyTypes.forEach((bodyType) => {
            const enBodyTranslation = bodyType.translations?.find((t) => t.language === 'en');
            const valuationBody = valuationBodies.find(
              (valBody) => valBody.body === enBodyTranslation?.name
            );
            if (valuationBody) {
              bodyType.extraData = valuationBody;
            }
          });

          setFilteredBodyTypes(newBodyTypes);

          if (newBodyTypes.length === 1) {
            setVehicleInfo({ ...state, body: newBodyTypes[0] });
          }
        };
        fetchValuationBodies(caseService, state, firstRegistrationDate);
      }
    }, [state.model]);

    useEffect(() => {
      if (!R.isNil(state.model) && !R.isNil(state.brand)) {
        const fetchValuationGeneration = async (
          caseService: ICaseService,
          state: VehicleInfo,
          firstRegistrationDate: Date | null
        ) => {
          setGenerationTypes([]);
          const enBodyTranslation = state.body?.translations?.find((t) => t.language === 'en');

          if (!enBodyTranslation?.name || !state.body?.extraData?.doorsNo) return;

          const generationValuation = await caseService.GetValuationGenerations(
            state.brand?.code,
            state.model?.code,
            enBodyTranslation?.name,
            state.body?.extraData?.doorsNo,
            checkIfIsValidDate(firstRegistrationDate)
          );

          const newGenerationType = R.innerJoin(
            (stateBody, generationBody) => {
              const enTranslation = stateBody.translations?.find((t) => t.language === 'en');
              if (!enTranslation) return false;
              return enTranslation.name === generationBody.body;
            },
            localBodyTypes!,
            generationValuation
          );

          newGenerationType.forEach((generationType) => {
            const enTranslation = generationType.translations?.find((t) => t.language === 'en');
            const valuationBody = generationValuation.find(
              (valGen) => valGen.body === enTranslation?.name
            );
            if (valuationBody) {
              generationType.extraData = valuationBody;
            }
          });

          const updatedGenerationType = generationValuation.map((generation, index) => {
            const data = { ...newGenerationType[0] };

            data.extraData = { ...generation };
            data.displayName = `${generation.generation} (${generation.modelYear})`;

            return data;
          });

          setGenerationTypes(updatedGenerationType);

          if (updatedGenerationType.length === 1) {
            setVehicleInfo({ ...state, generation: updatedGenerationType[0] });
          }
        };
        fetchValuationGeneration(caseService, state, firstRegistrationDate);
      }
    }, [state.body]);

    useEffect(() => {
      if (
        !R.isNil(state.generation) &&
        !R.isNil(state.model) &&
        !R.isNil(state.brand) &&
        !R.isNil(state.body)
      ) {
        const onWizardCallbackWrapper = async () => {
          const enBodyTranslation = state.body?.translations?.find((t) => t.language === 'en');
          const resultVehicles = await onWizardCallback(
            state?.brand?.code || '',
            state?.model?.code || '',
            enBodyTranslation?.name || '',
            checkIfIsValidDate(firstRegistrationDate),
            state?.generation?.extraData?.generation
          );
          const newFuelTypes = R.innerJoin(
            (stateFuelType, vehicle) => {
              const enFuelTranslation = stateFuelType.translations?.find(
                (t) => t.language === 'en'
              );
              if (!enFuelTranslation) return false;

              return enFuelTranslation.name === vehicle.fuelType;
            },
            localfuelTypes!,
            resultVehicles
          );

          newFuelTypes.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

          setFilteredFuelTypes(newFuelTypes);
          setFilteredVehicles(resultVehicles);
        };
        onWizardCallbackWrapper();
      }
    }, [state.generation]);

    useEffect(() => {
      if (!R.isNil(state.fuelType)) {
        const data = filterVehicles();

        const newPowerTypes = data
          .map((x) => x.kw)
          .filter((x, i, a) => a.indexOf(x) === i)
          .map((x) => {
            return {
              id: x,
              code: x.toString(),
              isActive: true,
              displayName: x.toString()
            } as IReferential;
          })
          .sort((a, b) => parseInt(a.displayName) - parseInt(b.displayName));

        const newTransmissionTypes = data
          .map((x) => x.transmission)
          .filter((x, i, a) => a.indexOf(x) === i)
          .map((x) => {
            return { id: 0, code: x, isActive: true, displayName: x } as IReferential;
          })
          .sort((a, b) => a.displayName.localeCompare(b.displayName));

        const newTractionTypes = data
          .map((x) => x.drivenWheels)
          .filter((x, i, a) => a.indexOf(x) === i)
          .map((x) => {
            return { id: 0, code: x, isActive: true, displayName: x } as IReferential;
          })
          .sort((a, b) => a.displayName.localeCompare(b.displayName));

        setFilteredPowerTypes(newPowerTypes);
        setFilteredTransmissionTypes(newTransmissionTypes);
        setFilteredTractionTypes(newTractionTypes);
      }
    }, [state.fuelType, state.powerType, state.transmissionType, state.tractionType]);

    const handleStep = useCallback(
      (step: number) => {
        setActiveStep(step);
      },
      [setActiveStep]
    );

    return (
      <ValuationFiltersWizardComponent
        state={state}
        steps={steps}
        activeStep={activeStep}
        bodyTypesList={R.isEmpty(filteredBodyTypes) ? [] : filteredBodyTypes}
        makeList={localMakeList}
        generationTypesList={R.isEmpty(generationTypes) ? [] : generationTypes}
        models={R.isEmpty(filteredModels) ? [] : filteredModels}
        handleStep={handleStep}
        onChange={onChange}
        fuelTypes={R.isEmpty(filteredFuelTypes) ? [] : filteredFuelTypes}
        tractionTypes={R.isEmpty(filteredTractionTypes) ? [] : filteredTractionTypes}
        powerTypes={R.isEmpty(filteredPowerTypes) ? [] : filteredPowerTypes}
        transmissionTypes={R.isEmpty(filteredTransmissionTypes) ? [] : filteredTransmissionTypes}
        firstRegistrationDate={firstRegistrationDate}
        onChangeFirstRegistrationDate={onChangeFirstRegistrationDate}
        isFromValuationModule={isFromValuationModule}
      />
    );
  };

export default connect(
  (state: ApplicationState, ownProps: ExternalCaseDetailsValuationWizardContainerProps) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    ...ownProps
  }),
  CaseSettingsActionCreators
)(withSnackbar(ValuationFiltersWizardContainer as any));
