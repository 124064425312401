import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, IconButton, Grid } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { WorkflowPartner, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { CaseAttachment, CaseServiceAppointmentHistory } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { ReferentialCode, AttachmentTypeCode } from '../../helpers/Constants';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import FileSaver from 'file-saver';
import { AppUser } from '../../interfaces/AppUser';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import * as R from 'ramda';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

interface ICaseDetailsCaseServiceAppointpentsFormState {
  comment: string;
  serviceInDate: Date;
  serviceOutDate: Date;
  organizations: WorkflowPartner[];
  selectedOrganization: WorkflowPartner | null;
  users: AppUser[];
  selectedUser: AppUser | null;
  attachments: CaseAttachment[];
  isLoading: boolean;
  isLoading2: boolean;
  attachType: IReferential;
  isOrganizationsDisabled: boolean;
  isUsersDisabled: boolean;
  hasRights: boolean;
  isUserHidden: boolean;
  executing: boolean;
  userRoleId: number | null;
}

export interface ExternalCaseDetailsCaseServiceAppointpentsFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseServiceAppointpentsFormProps =
  ExternalCaseDetailsCaseServiceAppointpentsFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseServiceAppointpentsForm extends React.PureComponent<
  ICaseDetailsCaseServiceAppointpentsFormProps,
  ICaseDetailsCaseServiceAppointpentsFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private workflowService!: IWorkflowService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;
  state = {
    serviceInDate: new Date(),
    serviceOutDate: new Date(),
    comment: '',
    organizations: [],
    selectedOrganization: null,
    users: [],
    selectedUser: null,
    attachments: [],
    isLoading: false,
    isLoading2: false,
    attachType: {} as IReferential,
    isOrganizationsDisabled: false,
    isUsersDisabled: false,
    hasRights: false,
    isUserHidden: false,
    executing: false,
    userRoleId: null
  } as ICaseDetailsCaseServiceAppointpentsFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseServiceAppointmentForm();
      }
    );
  }

  loadCaseServiceAppointmentForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );

    const casee = this.props.caseSettingsState.case;
    const [workflowPartners, attachmentTypes, currentCaseServiceAppointment] = await Promise.all([
      this.workflowService.GetUserWorkflowPartners(
        this.props.caseSettingsState.case!.caseWorkflow.workflowId
      ),
      this.appReferentialService.Get(ref!.baseUrl),
      this.caseService.GetCurrentCaseServiceAppointment(casee!.id, this.props.workflowForm.id)
    ]);
    const organizations = await this.organizationService.GetUserOrganizationsByIds(
      workflowPartners.map((item) => item.partnerId)
    );

    workflowPartners.forEach((wp) => {
      const partner = organizations.find((item) => item.id === wp.partnerId);
      wp.partner = R.isNil(partner) ? null : partner;
    });

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    let currentCaseServiceAppointment1 = { ...currentCaseServiceAppointment };
    if (currentCaseServiceAppointment1 === null) {
      currentCaseServiceAppointment1 = await this.caseService.GetCurrentCaseServiceAppointment(
        casee!.id,
        null
      );
    }
    if (currentCaseServiceAppointment1 !== null && currentCaseServiceAppointment1.attachments) {
      currentCaseServiceAppointment1.attachments.forEach(
        (item) =>
          (item.attachmentType = attachmentTypes.find(
            (item) => item.code === AttachmentTypeCode.OTHER
          )!)
      );
    }
    this.setState(
      {
        organizations: workflowPartners,
        attachType: attachmentTypes.find((item) => item.code === AttachmentTypeCode.OTHER)!,
        isLoading: false,
        hasRights: hasRights,
        isUserHidden: !hasRights,
        comment:
          currentCaseServiceAppointment === null ? '' : currentCaseServiceAppointment.comment,
        serviceInDate:
          currentCaseServiceAppointment === null
            ? new Date()
            : currentCaseServiceAppointment.serviceInDate,
        serviceOutDate:
          currentCaseServiceAppointment === null
            ? new Date()
            : currentCaseServiceAppointment.serviceOutDate,
        attachments:
          currentCaseServiceAppointment === null ? [] : currentCaseServiceAppointment.attachments
      },
      this.setCaseAllocation
    );
  };

  setCaseAllocation = async () => {
    if (!this.state.hasRights) {
      return;
    }

    const allocationResult = await FormHelpers.ParseAllocations(
      this.props.workflowForm.workflowFormActions,
      this.state.organizations,
      this.appUserService
    );

    if (R.isNil(allocationResult)) {
      const partner = this.state.organizations.find(
        (item) => item.partnerId === this.props.caseSettingsState.case!.caseStatus.assignedTo!.hoId
      );

      this.setState(
        {
          selectedOrganization: R.isNil(partner) ? null : partner,
          selectedUser: this.props.caseSettingsState.case!.caseStatus.assignedTo,
          organizations: this.state.organizations,
          isUsersDisabled: false,
          isOrganizationsDisabled: false
        },
        async () => await this.loadUsers(R.isNil(partner) ? null : partner.partnerId, null)
      );
      return;
    }
    if (allocationResult.isLastUser || allocationResult.caseStatusId != null) {
      this.setState({ isUserHidden: true });
      return;
    }
    this.setState(
      {
        selectedOrganization: allocationResult.partner,
        selectedUser: allocationResult.user,
        organizations: allocationResult.partners,
        isUsersDisabled: !R.isNil(allocationResult.user),
        isOrganizationsDisabled: !R.isNil(allocationResult.partner),
        userRoleId: allocationResult.roleId
      },
      async () => {
        if (allocationResult.partner === null) {
          return;
        }
        await this.loadUsers(allocationResult.partner.partnerId, allocationResult.roleId);
      }
    );
  };

  loadUsers = async (organizationId: number | null, roleId: number | null) => {
    if (organizationId === null) {
      this.setState({
        isLoading2: false
      });
    } else {
      const users = await this.appUserService.GetUserAppUsers();

      this.setState({
        users: users.users.filter(
          (user) =>
            (user.organization!.hoId === organizationId ||
              user.organizationId === organizationId) &&
            ((roleId !== null && user.roleId === roleId) || roleId === null)
        ),
        isLoading2: false
      });
    }
  };

  handleUploadAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const attachments = [...this.state.attachments];
      attachments.push({
        id: this.state.attachments.length,
        caseId: this.props.caseSettingsState.case!.id,
        attachmentTypeId: this.state.attachType.id,
        attachmentType: this.state.attachType,
        file: e.target.files![0],
        fileName: e.target.files![0].name,
        uploadDate: new Date(),
        carPhotos: false,
        originalFileName: e.target.files![0].name,
        caseRequestId: null,
        paymentId: null,
        serviceAppointmentId: null,
        caseClientId: null,
        isSelection: false
      });

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      const attachments = [...this.state.attachments];
      const index = attachments.findIndex((item) => item.id === id);
      attachments.splice(index, 1);

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  renderAttachmentBox = () => {
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={9}>
          {this.state.attachments.length !== 0
            ? this.state.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left">
                      <Button
                        color="primary"
                        className="text-primary text-left"
                        disabled={!this.state.hasRights}
                        onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                    <div className="w-25">
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        className="text-danger"
                        size="small"
                        disabled={!this.state.hasRights}
                        onClick={(e) => this.handleDeleteAttachment(attach.id, attach.fileName)}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
        <Grid item xs={3} container justify="flex-end" direction="column">
          <input
            className="d-none"
            id={'contained-button-file'}
            onChange={(e) => {
              return this.handleUploadAttachment(e);
            }}
            type="file"
            disabled={!this.state.hasRights}
          />
          <label htmlFor={'contained-button-file'}>
            <Button
              variant="contained"
              color="primary"
              className="d-block text-center shadow-none"
              disabled={!this.state.hasRights}
              component="div"
            >
              {this.translatorService.Tranlate('CASE_PAYMENT_DETAILS_FORM_ATTACH', 'Ataseaza')}
            </Button>
          </label>
        </Grid>
      </Grid>
    );
  };

  createAppointment = async () => {
    try {
      this.setState({ executing: true });

      let serviceInDate;
      let serviceOutDate;
      if (moment.isDate(this.state.serviceInDate)) {
        serviceInDate = moment.utc(this.state.serviceInDate.toDateString()).local().toDate();
      } else {
        serviceInDate = moment.utc(this.state.serviceInDate).local().toDate();
      }
      if (moment.isDate(this.state.serviceOutDate)) {
        serviceOutDate = moment.utc(this.state.serviceOutDate.toDateString()).local().toDate();
      } else {
        serviceOutDate = moment.utc(this.state.serviceOutDate).local().toDate();
      }

      const appointmentFormData: CaseServiceAppointmentHistory = {
        id: 0,
        caseId: Number.parseInt(this.props.match.params.id),
        comment: this.state.comment,
        attachments: this.state.attachments,
        serviceInDate: serviceInDate,
        serviceOutDate: serviceOutDate,
        caseSectionStepFormId: this.props.workflowForm.id
      } as CaseServiceAppointmentHistory;

      await this.caseService.AddCaseServiceAppointment(appointmentFormData);

      // const selectedOrganization = this.state.selectedOrganization
      // const hoId =
      //     selectedOrganization === null
      //         ? null
      //         : selectedOrganization.partner!.hoId === null
      //         ? selectedOrganization.partnerId
      //         : selectedOrganization.partner!.hoId

      await this.submitForm(
        appointmentFormData.caseId,
        appointmentFormData.caseSectionStepFormId,
        this.state.selectedUser
      );

      this.setState(
        {
          selectedOrganization: null,
          selectedUser: null,
          comment: '',
          attachments: [],
          serviceInDate: new Date(),
          serviceOutDate: new Date()
        },
        this.setCaseAllocation
      );
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      console.log(ex);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  handleOrganizationChange = (newValue: WorkflowPartner | null) => {
    this.setState(
      {
        selectedOrganization: newValue,
        selectedUser: null,
        users: [],
        isLoading2: true
      },
      async () => {
        await this.loadUsers(newValue === null ? null : newValue.partnerId, this.state.userRoleId);
      }
    );
  };

  handleUserChange = (newValue: AppUser | null) => {
    this.setState({ selectedUser: newValue });
  };
  handleDateInChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState({
      serviceInDate: date!.toDate(),
      // dashboardFilters: dashboardFilters,
      isLoading: false
    });
  };

  handleDateOutChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState({
      serviceOutDate: date!.toDate(),
      // dashboardFilters: dashboardFilters,
      isLoading: false
    });
  };

  rendePaymentDetailsForm = () => {
    return R.isNil(this.state.comment) ? null : (
      <ValidatorForm
        onSubmit={(e) => {
          this.createAppointment();
        }}
      >
        <div className="m-3 text-center">
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={LocalizedUtils}
            locale={this.props.appState.language}
          >
            <DatePicker
              fullWidth
              className="m-2"
              variant="inline"
              format={this.props.appState.dateFormat.toUpperCase()}
              margin="normal"
              id="date-picker-inline-from"
              label={this.translatorService.Tranlate(
                'SERVICE_APPOINTMENT_FORM_SERVICE_DATE_IN_LABEL',
                'Data intrare in reparatie'
              )}
              value={this.state.serviceInDate}
              onChange={this.handleDateInChange}
              autoOk={true}
              disabled={!this.state.hasRights}
              // minDate={activeButton === 1 ? new Date() : this.state.minDate}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={LocalizedUtils}
            locale={this.props.appState.language}
          >
            <DatePicker
              fullWidth
              className="m-2"
              variant="inline"
              format={this.props.appState.dateFormat.toUpperCase()}
              margin="normal"
              id="date-picker-inline-from"
              label={this.translatorService.Tranlate(
                'SERVICE_APPOINTMENT_FORM_SERVICE_DATE_OUT_LABEL',
                'Data finalizare reparatie'
              )}
              value={this.state.serviceOutDate}
              onChange={this.handleDateOutChange}
              autoOk={true}
              disabled={!this.state.hasRights}
              // minDate={activeButton === 1 ? new Date() : this.state.minDate}
            />
          </MuiPickersUtilsProvider>

          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="textComment"
            name="textComment"
            className="mb-3"
            multiline
            rows={8}
            placeholder={this.translatorService.Tranlate(
              'CASE_PAYMENT_DETAILS_FORM_WRITE_MESSAGE_PLACEHOLDER',
              'Write your message...'
            )}
            value={this.state.comment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ comment: e.target.value });
            }}
            variant="outlined"
            validators={['required']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
          />

          {this.renderAttachmentBox()}
          {this.renderAssignementForm()}
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('CASE_COMMENT_SEND', 'Trimite')}
        </Button>
      </ValidatorForm>
    );
  };

  renderAssignementForm = () => {
    if (this.state.isUserHidden) {
      return null;
    }

    return (
      <React.Fragment>
        <Autocomplete
          disabled={this.state.isOrganizationsDisabled || !this.state.hasRights}
          id="workflowPartner"
          className="mb-3"
          options={this.state.organizations.sort(function (a, b) {
            return a.partner!.displayName.localeCompare(b.partner!.displayName);
          })}
          value={this.state.selectedOrganization}
          onChange={(e: any, newValue: WorkflowPartner | null) =>
            this.handleOrganizationChange(newValue)
          }
          getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName || ''}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            stringify: (option: WorkflowPartner) => option.partner!.displayName
          })}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="workflowPartner"
              value={this.state.selectedOrganization}
              label={this.translatorService.Tranlate(
                'CASE_PAYMENT_DETAILS_FORM_ORGANIZATION_LABEL',
                'Organizatie'
              )}
              fullWidth
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          )}
        />

        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading2} />
        </div>
        {!this.state.isLoading2 ? (
          <Autocomplete
            disabled={this.state.isUsersDisabled || !this.state.hasRights}
            id="user"
            className="mb-3"
            options={this.state.users.sort(function (a, b) {
              return a.userName.localeCompare(b.userName);
            })}
            value={this.state.selectedUser}
            onChange={(e: any, newValue: AppUser | null) => this.handleUserChange(newValue)}
            getOptionLabel={(option: AppUser) => option.userName || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="user"
                value={this.state.selectedUser}
                label={this.translatorService.Tranlate(
                  'CASE_PAYMENT_DETAILS_FORM_USER_LABEL',
                  'Utilizator'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
        ) : null}
      </React.Fragment>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendePaymentDetailsForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseServiceAppointpentsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseServiceAppointpentsForm as any));
