import { AppUser } from '../../interfaces/AppUser';
import {
  WorkflowFormAction,
  WorkflowPartner,
  WorkflowPermission,
  WorkflowCalculation,
  WorkflowFormReport
} from '../../interfaces/Workflow';
import { WorkflowFormActionTypeCode } from '../../helpers/Constants';
import { isNullOrUndefined } from 'util';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';

export class FormHelpers {
  public static async ParseAllocations(
    workflowFormActions: WorkflowFormAction[],
    organizations: WorkflowPartner[],
    appUserService: IAppUserService,
    appUser: AppUser | null = null
  ): Promise<any> {
    const caseAllocation = workflowFormActions.find(
      (item) => item.actionType!.code === WorkflowFormActionTypeCode.CASE_ALLOCATION
    );

    if (isNullOrUndefined(caseAllocation)) {
      return null;
    }
    if (
      (caseAllocation.isLastUser === null || !caseAllocation.isLastUser) &&
      caseAllocation.userId === null &&
      caseAllocation.workflowPartnerId === null &&
      caseAllocation.caseStatusId === null &&
      caseAllocation.workflowPartnerTypeId === null &&
      !(caseAllocation.sendEmailSameOrganization && appUser)
    ) {
      return null;
    }
    if (caseAllocation.isLastUser) {
      return {
        user: null,
        roleId: null,
        partners: [],
        partner: null,
        isLastUser: true,
        caseStatusId: null
      };
    }
    if (caseAllocation.caseStatusId !== null) {
      return {
        user: null,
        roleId: null,
        partners: [],
        partner: null,
        isLastUser: null,
        caseStatusId: caseAllocation.caseStatusId
      };
    }

    let workflowPartner: WorkflowPartner | null | undefined = null;
    let user: AppUser | null = null;
    if (!isNullOrUndefined(caseAllocation.workflowPartnerId)) {
      workflowPartner = organizations.find(
        (item) => item.partnerId === caseAllocation!.workflowPartner!.partnerId
      );
    }
    if (!isNullOrUndefined(caseAllocation.userId)) {
      user = await appUserService.GetUserInfo(caseAllocation.userId);
    }
    let partners = organizations;
    if (!isNullOrUndefined(caseAllocation.workflowPartnerTypeId)) {
      partners = organizations.filter(
        (item) =>
          item.partner &&
          item.partner.organizationTypeId ===
            caseAllocation!.workflowPartnerType!.organizationTypeId
      );
    }
    if (!isNullOrUndefined(user)) {
      workflowPartner = organizations.find(
        (item) => item.partnerId === user!.organizationId || item.partnerId === user!.hoId
      );
      partners = partners.filter((item) => item.id === workflowPartner!.id);
    }
    if (caseAllocation.sendEmailSameOrganization && appUser) {
      partners = partners.filter(
        (x) =>
          x.partnerId === appUser?.organizationId || x.partner?.parentId === appUser?.organizationId
      );

      workflowPartner = organizations.find(
        (item) => item.partnerId === appUser!.organizationId || item.partnerId === appUser!.hoId
      );
    }

    return {
      user: isNullOrUndefined(user) ? null : user,
      partners: partners,
      roleId: caseAllocation.userRoleId,
      partner: isNullOrUndefined(workflowPartner) ? null : workflowPartner,
      isLastUser: false,
      caseStatusId: null
    };
  }

  public static HasRights(
    workflowFormPermissions: WorkflowPermission[],
    appUser: AppUser,
    caseStatusId: number
  ): boolean {
    if (workflowFormPermissions.length === 0) {
      return false;
    }

    const permission = workflowFormPermissions.find(
      (item) =>
        item.workflowPartner!.partnerId === appUser.hoId &&
        (item.userRoleId === appUser.roleId || isNullOrUndefined(item.userRoleId)) &&
        item.caseStatusId === caseStatusId
    );

    return !isNullOrUndefined(permission);
  }

  public static VehicleDetailsHasRights(statusCode: string | null | undefined): boolean {
    if (!statusCode?.length) {
      return false;
    }

    return !['SOLD', 'AcquisitionRefused'].includes(statusCode);
  }

  public static GetCalculation(
    workflowCalculations: WorkflowCalculation[],
    appUser: AppUser,
    caseStatusId: number
  ): WorkflowCalculation | undefined {
    return workflowCalculations.find(
      (item) =>
        item.workflowPartner!.partnerId === appUser.hoId &&
        (item.userRoleId === appUser.roleId || isNullOrUndefined(item.userRoleId)) &&
        item.caseStatusId === caseStatusId
    );
  }

  public static GetReports(
    workflowReports: WorkflowFormReport[],
    appUser: AppUser,
    caseStatusId: number
  ): WorkflowFormReport[] {
    return workflowReports.filter(
      (item) =>
        item.workflowPartner!.partnerId === appUser!.hoId &&
        (item.userRoleId === appUser!.roleId || isNullOrUndefined(item.userRoleId)) &&
        item.caseStatusId === caseStatusId
    );
  }
}
