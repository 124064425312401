import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Tooltip,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NumberFormat from 'react-number-format';
import { IVehicleService } from '../../../services/Interfaces/IVehicleService';
import { SellerData } from '../../../interfaces/Vehicle';
import { isNullOrUndefined } from 'util';
import { DropzoneArea } from 'material-ui-dropzone';

interface ISellerDataListState {
  sellerData: SellerData;
  sellerDataList: SellerData[];
  isLoading: boolean;
  isDialogLoading: boolean;
  isDialogOpen: boolean;
}

type ISellerDataListProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class SellerDataList extends React.PureComponent<
  ISellerDataListProps,
  ISellerDataListState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  static contextType = ApplicationContext;
  private sellerData = {
    id: 0,
    name: '',
    address: '',
    address2: '',
    address3: '',
    companyNumber: '',
    registryNumber: '',
    bank: '',
    bankAccount: ''
  } as SellerData;

  state = {
    sellerData: {
      id: 0,
      name: '',
      address: '',
      address2: '',
      address3: '',
      companyNumber: '',
      registryNumber: '',
      bank: '',
      bankAccount: ''
    } as SellerData,
    sellerDataList: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    isDialogLoading: false
  } as ISellerDataListState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadSellerDataList();
      }
    );
  }

  loadSellerDataList = async () => {
    const sellerDataList = await this.vehicleService.GetSellerDataList();
    this.setState({
      sellerDataList: sellerDataList,
      isLoading: false
    });
  };


  public saveSellerData = async () => {
    let sellerId = this.state.sellerData.id;
    try {
      if (this.state.sellerData.id === 0) {
        const sellerData = await this.vehicleService.AddSellerData(this.state.sellerData);
        sellerId = sellerData.id;
      } else {
        await this.vehicleService.UpdateSellerData(this.state.sellerData);
      }

      await this.saveImage(sellerId, this.state.sellerData.image);

      await this.loadSellerDataList();

      this.closeDialog();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  
  
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text') {
      value = event.currentTarget.value;
    }
    this.setState({
      sellerData: {
        ...this.state.sellerData,
        [event.target.id]: value
      }
    });
  };

  handleUploadAttachment = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(files[0]);
    img.onload = async () => {
      if (img.width === 130 && img.height === 130) {
        this.setState({
          sellerData: {
            ...this.state.sellerData,
            image: files![0]
          }
        });  
        return;
      }
      
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'ERROR_INCORECT_FILE_SIZE',
          'Dimensiune imaginii este invalida'
        ),
        { variant: 'error' }
      );
      return true;
    };
  };


  
  saveImage = async (sellerId: number, image: File) => {
    if (sellerId === 0) {
      return;
    }

    if (image === null) {
      return;
    }

    try {
      await this.vehicleService.AddLogo(sellerId, image);

    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  loadData = async () => {
    if (this.state.sellerData.id === 0) {
      this.setState({
        isDialogLoading: false
      });
      return;
    }

    try {
      const image = await this.vehicleService.GetLogo(this.state.sellerData.id);

      if (image === null) {
        this.setState({
          isDialogLoading: false
        });
        return;
      }

      this.setState({
        isDialogLoading: false,
        sellerData: { 
          ...this.state.sellerData,
          image: new File([image], 'logo')
        }
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveSellerData} instantValidate={true}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="name"
              className="m-2"
              id="name"
              value={this.state.sellerData.name || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate('EDIT_ORGANIZATION_NAME_LABEL', 'Nume')}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="address"
              className="m-2"
              id="address"
              value={this.state.sellerData.address || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_ADDRESS_1_LABEL',
                'Adresa 1'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
              />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="address2"
              className="m-2"
              id="address2"
              value={this.state.sellerData.address2 || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_ADDRESS_2_LABEL',
                'Adresa 2'
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="address3"
              className="m-2"
              id="address3"
              value={this.state.sellerData.address3 || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_ADDRESS_3_LABEL',
                'Adresa 3'
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="companyNumber"
              className="m-2"
              id="companyNumber"
              value={this.state.sellerData.companyNumber || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_COMPANY_NUMBER_LABEL',
                'CUI/CIF/Cod TVA'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              className="m-2"
              name="ownCapital"
              placeholder={this.translatorService.Tranlate(
                'SELLER_DATA_OWN_CAPITAL',
                'Capital social'
              ) + ' (lei)'}
              value={this.state.sellerData.ownCapital}
              onChange={(e: any) => {
                this.setState({
                  sellerData: {
                    ...this.state.sellerData,
                    ownCapital: parseFloat(e.target.value)
                  } 
                });
              }}
              validators={['required', 'minNumber:1']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              label={this.translatorService.Tranlate(
                'SELLER_DATA_OWN_CAPITAL',
                'Capital social'
              ) + ' (lei)'}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="registryNumber"
              className="m-2"
              id="registryNumber"
              value={this.state.sellerData.registryNumber || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_REGISTRY_NUMBER_LABEL',
                'Nr. Reg. Comertului'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="bank"
              className="m-2"
              id="bank"
              value={this.state.sellerData.bank || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate('ORGANIZATION_BANK_ACCOUNTS_BANK_HEADER', 'Banca')}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextValidator
              fullWidth
              name="bankAccount"
              className="m-2"
              id="bankAccount"
              value={this.state.sellerData.bankAccount || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'ORGANIZATION_BANK_ACCOUNTS_BANK_ACCOUNT_HEADER',
                'Bank Account'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                )
              ]}
            />
          </Grid>
        </Grid>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center p-3">
          {isNullOrUndefined(this.state.sellerData.image) ? (
            ''
          ) : (
            <img src={URL.createObjectURL(this.state.sellerData.image)} alt="" className="img-fluid" />
          )}
        </div>
        <DropzoneArea
          dropzoneClass="custom-dropzone mb-3"
          dropzoneText={
            this.translatorService.Tranlate(
              'CASE_CAR_PHOTOS_FORM_UPLOAD_IMAGE',
              'Drag and drop an image here or click!'
            ) + ' (130px x 130px)'
          }
          showPreviews={false}
          showPreviewsInDropzone={false}
          filesLimit={1}
          onChange={(files: File[]) => this.handleUploadAttachment(files)}
          previewText=""
          showAlerts={false}
          acceptedFiles={['image/*']}
        />
      <div className="text-right">
        <Button
          className="m-2"
          variant="outlined"
          color="primary"
          onClick={this.closeDialog}
        >
          {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
        </Button>
        <Button className="m-2" variant="contained" color="primary" type="submit">
          {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
        </Button>
      </div>
    </ValidatorForm>

    );
  }

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate('EDIT_ORGANIZATION_NAME_LABEL', 'Nume'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            const sellerData = this.state.sellerDataList.find((item) => item.id === tableMeta.rowData[0]);

            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newSellerDataPopup(tableMeta.rowData[0])}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteSellerData(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];

    return columns;
  };

  deleteSellerData = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.vehicleService.RemoveSellerData(id);

      await this.loadSellerDataList();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  newSellerDataPopup = async (sellerDataId: number | null) => {
    let newSellerData: SellerData;
    if (sellerDataId == null) {
      newSellerData = { ...this.state.sellerData } as SellerData;
    } else {
      newSellerData = this.state.sellerDataList.find((item) => item.id === sellerDataId)!;
    }
    this.setState({
      isDialogOpen: true,
      sellerData: newSellerData,
      isDialogLoading: true
    }, async () => {
        await this.loadData();
      }
    );
  };

  closeDialog = async () => {
    this.setState(
      { 
        isDialogOpen: false, 
        sellerData: this.sellerData
      }
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newSellerDataPopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.sellerDataList}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={false}
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography className="MuiTypography-h4">
                  {!this.state.sellerData.id ? 
                  this.translatorService.Tranlate(
                      'SELLER_DATA_NEW_MODAL_TITLE',
                      'Adauga date vanzator'
                    ) : this.translatorService.Tranlate(
                      'SELLER_DATA_UPDATE_MODAL_TITLE',
                      'Actualizeaza date vanzator'
                    )}       
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
            <div className="d-flex flex-row text-center flex-wrap justify-content-center">
              <ScaleLoader color={'var(--primary)'} loading={this.state.isDialogLoading} />
            </div>
            {!this.state.isDialogLoading ? this.renderAddForm() : null}
          </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(SellerDataList as any));
