import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Organization } from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, Card, CardContent, Tabs, Tab, Switch, IconButton } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { WorkflowCostCenter } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined } from 'util';
import _ from 'lodash';
import { ReferentialCode } from '../../../helpers/Constants';
import { IReferential } from '../../../interfaces/IReferential';
import Referentials from '../../../helpers/Referentials.json';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';

interface IWorkflowCostCentersState {
  workflowCostCenters: WorkflowCostCenter[];
  isLoading: boolean;
  costCenters: IReferential[];
  selectedCostCenter: IReferential[];
  workflowId: number;
}

type IWorkflowCostCentersProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowCostCenters extends React.PureComponent<IWorkflowCostCentersProps, IWorkflowCostCentersState> {
  private translatorService!: ITranslatorService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowCostCenters: [],
    isLoading: false,
    costCenters: [],
    selectedCostCenter: [],
    workflowId: 0
  } as IWorkflowCostCentersState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowCostCenters();
      }
    );
  }
  
  public hasRole = (roleCode: string): boolean => {
    return this.props.appState.appUser?.userRole?.code === roleCode;
  };

  loadWorkflowCostCenters = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CostCenter
    );

    const [workflowCostCenterList, costCenters] = await Promise.all([
      this.workflowService.GetWorkflowCostCenters(workflowId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    workflowCostCenterList.forEach((ojt) => {
      const costCenter = costCenters.find((item) => item.id === ojt.costCenterId);
      ojt.costCenter = isNullOrUndefined(costCenter) ? null : costCenter;
    });

    console.log('workflowCostCenterList', workflowCostCenterList);
    console.log('costCenters', costCenters);

    this.setState({
      workflowCostCenters: workflowCostCenterList,
      costCenters: costCenters.filter((item) => item.isActive === true),
      isLoading: false,
      workflowId: workflowId
    });
  };

  
  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'costCenter',
        label: this.translatorService.Tranlate(
          'WORKFLOW_TAB_COST_CENTER',
          'Centru de Cost'
        ),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: Organization) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PARTNERS_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteWorkflowCostCenter(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  deleteWorkflowCostCenter = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.workflowService.RemoveWorkflowCostCenter(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.loadWorkflowCostCenters();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  addWorkflowCostCenter = async () => {
    try {
      if (
        this.state.workflowCostCenters.findIndex(
          (item) =>
            this.state.selectedCostCenter.findIndex((itemm) => itemm.id === item.costCenterId) !==
            -1
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_WORKFLOW_COST_CENTER_EXISTS', 'Centrul de cost exista deja!'),
          { variant: 'error' }
        );
        return;
      }

      const workflowCostCenters = [];
      for (const costCenter of this.state.selectedCostCenter) {
        workflowCostCenters.push({
          id: 0,
          workflowId: this.state.workflowId,
          costCenterId: costCenter!.id,
          costCenter: costCenter
        } as WorkflowCostCenter);
      }

      await this.workflowService.AddWorkflowCostCenter(workflowCostCenters);

      this.setState(
        {
          isLoading: true,
          selectedCostCenter: []
        },
        () => {
          this.loadWorkflowCostCenters();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  handleWorkflowCostCenterChange = (newValue: any[]) => {
    this.setState({ selectedCostCenter: newValue });
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addWorkflowCostCenter}>
              <Autocomplete
                id="costCenters"
                className="m-2"
                multiple
                options={this.state.costCenters.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedCostCenter}
                onChange={(e: any, newValue: any[]) => this.handleWorkflowCostCenterChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="costCenter"
                    value={this.state.selectedCostCenter}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_TAB_COST_CENTER',
                      'Centru de Cost'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('WORKFLOW_TAB_COST_CENTERS', 'Centre de Cost')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowCostCenters}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(WorkflowCostCenters as any));
