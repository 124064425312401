import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DashboardItem } from '../../../interfaces/Dashboard/DashboardItem';

interface Props {
  title: string;
  items: DashboardItem[];
  handleDonutClick(dataIndex?: number): void;
}

const VehicleDonutChart: React.FC<Props> = ({ title, items, handleDonutClick }) => {
  const series = useMemo(() => {
    return items.map((i) => i.value);
  }, [items]);

  const options = useMemo(() => {
    const labels = items.map((i) => i.displayName);
    return {
      chart: {
        type: 'donut',
        events: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            if (!isNil(config.dataPointIndex)) {
              handleDonutClick(config.dataPointIndex);
            }
          }
        }
      },
      labels,
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };
  }, [handleDonutClick, items]);

  return (
    <div className="pb-2">
      <h6 className="mt-3 ml-3 mb-0">{title}</h6>
      <ReactApexChart options={options} series={series} type="donut" />
    </div>
  );
};

export default VehicleDonutChart;
