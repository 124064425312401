import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Grid, TextField, FormControlLabel, Switch, FormLabel } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';

import {
  DashboardFiltersState,
  DashboardMainFilterStateKeys
} from '../../interfaces/Dashboard/DashboardFiltersState';
import { FiltersSelectItem } from '../../interfaces/Dashboard/FiltersSelectItem';
import { ApplicationContext } from '../../context/Contexts';
import Loader from '../Loader';

interface Props {
  filters: DashboardFiltersState;
  setFiltersValueByName(name: string, value: any): void;
  vatFilterVisible?: boolean;
}

const DashboardFilters: React.FC<Props> = ({
  filters,
  setFiltersValueByName,
  vatFilterVisible = false
}) => {
  const {
    app: { dateFormat, language, appUser }
  } = useSelector((state: any) => state);

  const context = useContext(ApplicationContext);

  const [organizations, setOrganizations] = useState<FiltersSelectItem[]>([]);

  const handleFiltersChange = useCallback(
    (value: any, name: string) => {
      setFiltersValueByName(name, value);
    },
    [setFiltersValueByName]
  );

  useEffect(() => {
    const loadOrganizations = async () => {
      const organizations = await context.organizationService.GetOrganizationsByHO(
        appUser.hoId,
        null
      );

      if (organizations.length) {
        const selectItems = organizations.map<FiltersSelectItem>(({ id, name, parent }) => {
          return { id, name, parentId: parent ? parent.id : undefined };
        });

        if (selectItems.length) {
          setOrganizations(selectItems);
        }
      }
    };

    loadOrganizations();
  }, [appUser.hoId, context.organizationService]);

  const getAllChildren = useCallback(
    (data: FiltersSelectItem[], parentId: number) => {
      const children = organizations.filter((i) => i.parentId === parentId);

      for (const child of children) {
        data.push(child);
        getAllChildren(data, child.id);
      }

      return data;
    },
    [organizations]
  );

  const availableOrganizations = useMemo(() => {
    const userOrganization = organizations.find((item) => item.id === appUser.organizationId);

    if (userOrganization) {
      const children = getAllChildren([], userOrganization.id);
      return [userOrganization, ...children];
    }

    return [];
  }, [appUser.organizationId, getAllChildren, organizations]);

  useEffect(() => {
    const selectedItem = organizations.find((item) => item.id === appUser.organizationId);
    if (selectedItem) {
      const childItems = getAllChildren([], selectedItem.id);
      setFiltersValueByName(DashboardMainFilterStateKeys.SELECTED_ORGANIZATIONS, [
        selectedItem,
        ...childItems
      ]);
    }
  }, [appUser.organizationId, getAllChildren, organizations, setFiltersValueByName]);

  if (!organizations.length) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
          <DatePicker
            variant="inline"
            id={DashboardMainFilterStateKeys.START_DATE}
            fullWidth
            className="mt-2 mb-2"
            size="small"
            label={context.translatorService.Tranlate('DASHBOARD_DATE_LABEL_FROM', 'De la')}
            format={dateFormat.toUpperCase()}
            value={filters.startDate}
            onChange={(value) => {
              handleFiltersChange(value, DashboardMainFilterStateKeys.START_DATE);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
          <DatePicker
            variant="inline"
            id={DashboardMainFilterStateKeys.END_DATE}
            fullWidth
            className="mt-2 mb-2"
            size="small"
            label={context.translatorService.Tranlate('DASHBOARD_DATE_LABEL_TO', 'Pana la')}
            format={dateFormat.toUpperCase()}
            value={filters.endDate}
            onChange={(value) => {
              handleFiltersChange(value, DashboardMainFilterStateKeys.END_DATE);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          id={DashboardMainFilterStateKeys.SELECTED_ORGANIZATIONS}
          size="small"
          className="mt-2 mb-2"
          multiple
          options={availableOrganizations!}
          value={filters.selectedOrganisations}
          onChange={(e: any, newValue: any) =>
            handleFiltersChange(newValue, DashboardMainFilterStateKeys.SELECTED_ORGANIZATIONS)
          }
          getOptionLabel={(option: FiltersSelectItem) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              name="selectedOrganisations"
              value={filters.selectedOrganisations}
              label={context.translatorService.Tranlate('DASHBOARD_ORGANIZATION', 'Organizatie')}
              fullWidth
            />
          )}
        />
      </Grid>
      {vatFilterVisible && (
        <Grid item xs={4}>
          <FormControlLabel
            labelPlacement="top"
            control={
              <Switch
                checked={filters.includeVat}
                value={filters.includeVat}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e: any) => {
                  handleFiltersChange(e.target.checked, DashboardMainFilterStateKeys.INCLUDE_VAT);
                }}
              />
            }
            label={
              <FormLabel>
                <span style={{ fontSize: '11px' }}>
                  {context.translatorService.Tranlate(
                    'DASHBOARD_PRICES_WITH_TAX',
                    'Preturi cu TVA'
                  )}
                </span>
              </FormLabel>
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardFilters;
