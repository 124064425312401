import { API } from '../helpers/API';
import { ICRUDService } from './Interfaces/ICRUDService';

export class CRUDService<T> implements ICRUDService<T> {
  private baseController = '';
  private itemController = '';

  constructor(controller: string, itemController: string) {
    this.baseController = controller;
    this.itemController = itemController;
  }

  public async Get(id: number): Promise<T[]> {
    return (await API.GetAsync<T[]>(`${this.baseController}/${this.itemController}/list/${id}`))
      .data;
  }

  public async GetUser(id: number | null): Promise<T[]> {
    return (
      await API.GetAsync<T[]>(
        `${this.baseController}/${this.itemController}/listUser/${id === null ? '' : id}`
      )
    ).data;
  }

  public async Add(item: T): Promise<T> {
    return (await API.PostAsync<T>(`${this.baseController}/${this.itemController}`, item)).data;
  }

  public async SaveList(items: T[]): Promise<void> {
    await API.PostAsync<T[]>(`${this.baseController}/${this.itemController}/list`, items);
  }

  public async Update(item: T): Promise<void> {
    await API.PutAsync<T>(`${this.baseController}/${this.itemController}`, item);
  }

  public async Remove(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/${this.itemController}/${id}`))
      .data;
  }
}
