import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ApplicationContext } from '../../../context/Contexts';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { AttachmentTypeCode, ReportTypeCode } from '../../../helpers/Constants';
import FileSaver from 'file-saver';
import isNil from 'lodash/isNil';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSelector } from 'react-redux';
import {
  ControlType,
  initialStateValue,
  ReportParameterExtended,
  State
} from './initialStateValue';
import { ReportParameter } from '../../../interfaces/Reports';
import { CaseAttachment, CaseClient } from '../../../interfaces/Case';
import { AttachmentTypeIdEnum } from '../../../enums/AttachmentTypeIdEnum';
import { VehicleOffer } from '../../../interfaces/Vehicle';
import Loader from '../../Loader';
import cloneDeep from 'lodash/cloneDeep';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

interface Props {
  isOpen: boolean;
  offer?: VehicleOffer;
  price?: number;
  isSaleOfferType: boolean;
  handleClose(): void;
}

const personalDataType = ['B.I.', 'C.I', 'C.I.P', 'Pasaport'];

const booleanType = ['Da', 'Nu'];

const originalCopyType = ['Original', 'Copie'];

const ContractModal: React.FC<Props> = ({ isOpen, handleClose, offer, isSaleOfferType, price }) => {
  const context = useContext(ApplicationContext);

  const reduxState = useSelector((state: any) => state);

  const [state, setState] = useState<State | undefined>();
  const [requiredFields, setRequiredFields] = useState<string[]>([]);

  const {
    app: { dateFormat, language }
  } = reduxState;

  useEffect(() => {
    const getVehicle = async () => {
      const vehicleId = reduxState.caseSettings.case.vehicleId;
      if (!isNil(vehicleId)) {
        const vehicle = await context.vehicleService.GetVehicle(vehicleId);
        // TODO read from backend based on caseClientHistoryId
        const caseClient = reduxState.caseSettings.case.caseClient as CaseClient;
        const organizationId = reduxState.app.appUser.organizationId;
        const organization = await context.organizationService.GetOrganization(organizationId);

        const personIsCompany = caseClient.personType?.name === 'PJ';

        const data = cloneDeep(initialStateValue) as State;

        const otherParameters = [...data.others.parameters];
        const priceParamaters = [...data.price.parameters];
        const vehicleParameters = [...data.vehicle.parameters];
        let buyerParameters = personIsCompany
          ? [...data.buyer.parameters.filter((p) => p.name !== 'Buyer_ID')]
          : [...data.buyer.parameters];
        let sellerParameters = personIsCompany
          ? [...data.seller.parameters.filter((p) => p.name !== 'Seller_ID')]
          : [...data.seller.parameters];

        if (vehicle) {
          setParameterValue(vehicleParameters, 'Vehicle_Brand', vehicle.make?.name || '');
          setParameterValue(vehicleParameters, 'Vehicle_Model', vehicle.model?.name || '');
          setParameterValue(vehicleParameters, 'Vehicle_VIN', vehicle.vin || '');
          setParameterValue(vehicleParameters, 'Engine_Size', vehicle.engineSize?.toString() || '');
          setParameterValue(vehicleParameters, 'Plate_Number', vehicle.plateNumber || '');
          setParameterValue(
            vehicleParameters,
            'Make_Year',
            vehicle.makeDate ? moment(vehicle.makeDate).format(dateFormat.toUpperCase()) : ''
          );
          setParameterValue(
            vehicleParameters,
            'Pollution_Norm',
            vehicle.pollutionNorm?.displayName || ''
          );
        }

        if (isSaleOfferType) {
          if (caseClient) {
            setParameterValue(buyerParameters, 'Buyer_Type', caseClient.personType?.name || '');
            setParameterValue(
              buyerParameters,
              'Buyer_Name_Company',
              caseClient.companyName || `${caseClient.firstName} ${caseClient.lastName}`
            );
            setParameterValue(
              buyerParameters,
              'Buyer_Address',
              `${caseClient.address || ''}${caseClient.city ? ', ' + caseClient.city : ''}` || ''
            );
            setParameterValue(
              buyerParameters,
              'Buyer_PIN_CIF',
              personIsCompany ? caseClient.companyNumber || '' : caseClient.pin || ''
            );
            setParameterValue(buyerParameters, 'Buyer_Tel_Fax', caseClient.phone || '');
            setParameterValue(buyerParameters, 'Buyer_Email', caseClient.email || '');
            if (personIsCompany) {
              buyerParameters = buyerParameters.filter((p) => p.name !== 'Buyer_ID');
            }
          }

          if (organization) {
            setParameterValue(sellerParameters, 'Seller_Type', 'PJ' || '');
            setParameterValue(sellerParameters, 'Seller_Name_Company', organization.fullName || '');
            setParameterValue(
              sellerParameters,
              'Seller_Address',
              `${organization.address || ''}${organization.city ? ', ' + organization.city : ''}` ||
                ''
            );
            setParameterValue(sellerParameters, 'Seller_PIN_CIF', organization.companyNumber || '');
            setParameterValue(sellerParameters, 'Seller_Tel_Fax', organization.phone || '');
            setParameterValue(sellerParameters, 'Seller_Email', organization.email || '');
            sellerParameters = sellerParameters.filter((p) => p.name !== 'Seller_ID');
          }
        } else {
          if (caseClient) {
            setParameterValue(sellerParameters, 'Seller_Type', caseClient.personType?.name || '');
            setParameterValue(
              sellerParameters,
              'Seller_Name_Company',
              caseClient.companyName || `${caseClient.firstName} ${caseClient.lastName}`
            );
            setParameterValue(
              sellerParameters,
              'Seller_Address',
              `${caseClient.address || ''}${caseClient.city ? ', ' + caseClient.city : ''}` || ''
            );
            setParameterValue(
              sellerParameters,
              'Seller_PIN_CIF',
              personIsCompany ? caseClient.companyNumber || '' : caseClient.pin || ''
            );
            setParameterValue(sellerParameters, 'Seller_Tel_Fax', caseClient.phone || '');
            setParameterValue(sellerParameters, 'Seller_Email', caseClient.email || '');
            if (personIsCompany) {
              sellerParameters = sellerParameters.filter((p) => p.name !== 'Seller_ID');
            }
          }

          if (organization) {
            setParameterValue(buyerParameters, 'Buyer_Type', 'PJ' || '');
            setParameterValue(buyerParameters, 'Buyer_Name_Company', organization.fullName || '');
            setParameterValue(
              buyerParameters,
              'Buyer_Address',
              `${organization.address || ''}${organization.city ? ', ' + organization.city : ''}` ||
                ''
            );
            setParameterValue(buyerParameters, 'Buyer_PIN_CIF', organization.companyNumber || '');
            setParameterValue(buyerParameters, 'Buyer_Tel_Fax', organization.phone || '');
            setParameterValue(buyerParameters, 'Buyer_Email', organization.email || '');
            buyerParameters = buyerParameters.filter((p) => p.name !== 'Buyer_ID');
          }
        }

        setParameterValue(
          otherParameters,
          'Signing_Date',
          moment().format(dateFormat.toUpperCase())
        );

        setParameterValue(priceParamaters, 'Price_Ron', price?.toFixed(2) || '');
        // setParameterValue(priceParamaters, 'Price_Ron_Words', price?.toFixed(2) || '');

        setState({
          ...data,
          buyer: {
            ...data.buyer,
            parameters: buyerParameters
          },
          seller: {
            ...data.seller,
            parameters: sellerParameters
          }
        });
      }
    };

    getVehicle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (groupName: string, name: string, value: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const parameters = [...state[groupName].parameters];
    const param = parameters.find((p) => {
      return p.name === name;
    });
    param.value = value.toString();
    if (state) {
      setState({
        ...state,
        [groupName]: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          ...state[groupName],
          parameters
        }
      });
    }
  };

  const setParameterValue = (parameters: ReportParameter[], name: string, value: string) => {
    const param = parameters.find((p) => p.name === name);
    if (param) {
      param.value = value;
    }
  };

  const handleSubmit = async () => {
    if (!state) {
      return;
    }

    const reportParametersExtended: ReportParameterExtended[] = [
      ...state.header.parameters,
      ...state.seller.parameters,
      ...state.buyer.parameters,
      ...state.price.parameters,
      ...state.vehicle.parameters,
      ...state.others.parameters,
      ...state.footer.parameters
    ];

    const requiredParameters = reportParametersExtended
      .filter((p) => !p.notMandatory && !p.onlyForDisplay && !p.value)
      .map((p) => p.name);

    if (requiredParameters.length) {
      setRequiredFields(requiredParameters);
      return;
    }

    const reportParameters: ReportParameter[] = reportParametersExtended
      .filter((p) => p.value && !p.onlyForDisplay && p.type !== ControlType.PERSONAL_DATA)
      .map(({ name, value }) => {
        return { name, value: value.trim() };
      });

    const personalDataParams = reportParametersExtended.filter(
      (p) => p.type === ControlType.PERSONAL_DATA
    );

    for (const p of personalDataParams) {
      const values = p.value.split(',');

      reportParameters.push({
        name: `${p.name}_Type`,
        value: values[0]
      });
      reportParameters.push({
        name: `${p.name}_Series`,
        value: values[1]
      });
      reportParameters.push({
        name: `${p.name}_Number`,
        value: values[2]
      });
    }

    reportParameters.push({
      name: 'IsSaleContract',
      value: isSaleOfferType ? 'true' : 'false'
    });

    reportParameters.push({
      name: 'OrganizationID',
      value: reduxState.app.appUser.organizationId.toString()
    });

    if (!isNil(offer?.caseClientHistoryId)) {
      reportParameters.push({
        name: 'CaseClientHistoryID',
        value: offer?.caseClientHistoryId.toString() || ''
      });
    }

    if (!isNil(offer?.id)) {
      reportParameters.push({
        name: 'VehicleOfferID',
        value: offer?.id.toString() || ''
      });
    }

    // TODO save offer contract date here

    const fileBlob = await context.reportsService.GetReport(
      ReportTypeCode.RENAULT_CONTRACT,
      reportParameters
    );

    const fileName = `Contract-${offer?.id}.pdf`;

    const file = new File([fileBlob], fileName);

    const caseAttachment = {
      id: 0,
      caseId: reduxState.caseSettings.case.id,
      attachmentTypeId: AttachmentTypeIdEnum.CONTRACT,
      attachmentType: {
        code: AttachmentTypeCode.CONTRACT
      },
      file,
      fileName,
      isSelection: true
    } as CaseAttachment;

    context.caseService.AddCaseAttachment([caseAttachment]);

    FileSaver.saveAs(fileBlob, fileName);
  };

  const getControl = (groupName: string, param: ReportParameterExtended) => {
    switch (param.type) {
      case ControlType.ORIGINAL_COPY:
        return (
          <div className="mt-2">
            <FormControl fullWidth>
              <InputLabel error={requiredFields.includes(param.name)}>{param.label}</InputLabel>
              <Select
                fullWidth
                error={requiredFields.includes(param.name)}
                value={param.value}
                onChange={(e: any) => handleChange(groupName, param.name, e.target.value)}
                name={param.name}
              >
                {originalCopyType.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={requiredFields.includes(param.name)}>
                {requiredFields.includes(param.name)
                  ? context.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  : undefined}
              </FormHelperText>
            </FormControl>
          </div>
        );
      case ControlType.BOOLEAN:
        return (
          <div className="mt-2">
            <FormControl fullWidth>
              <InputLabel error={requiredFields.includes(param.name)}>{param.label}</InputLabel>
              <Select
                fullWidth
                error={requiredFields.includes(param.name)}
                value={param.value}
                onChange={(e: any) => handleChange(groupName, param.name, e.target.value)}
                name={param.name}
              >
                {booleanType.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={requiredFields.includes(param.name)}>
                {requiredFields.includes(param.name)
                  ? context.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  : undefined}
              </FormHelperText>
            </FormControl>
          </div>
        );
      case ControlType.PERSONAL_DATA:
        const valuesArray = param.value.split(',') || [];

        const id = valuesArray.length ? valuesArray[0] : '';
        const series = valuesArray.length > 0 ? valuesArray[1] : '';
        const number = valuesArray.length > 1 ? valuesArray[2] : '';

        return (
          <div className="d-flex">
            <div className="w-100 mt-2">
              <FormControl fullWidth>
                <InputLabel error={requiredFields.includes(param.name)}>Tip document</InputLabel>
                <Select
                  fullWidth
                  value={id}
                  error={requiredFields.includes(param.name)}
                  onChange={(e: any) => {
                    valuesArray[0] = e.target.value;
                    handleChange(groupName, param.name, valuesArray.join(','));
                  }}
                  name={param.name}
                >
                  {personalDataType.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={requiredFields.includes(param.name)}>
                  {requiredFields.includes(param.name)
                    ? context.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    : undefined}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="w-100 ml-2 mt-2">
              <TextValidator
                fullWidth
                onChange={(e: any) => {
                  valuesArray[1] = e.target.value;
                  handleChange(groupName, param.name, valuesArray.join(','));
                }}
                error={requiredFields.includes(param.name)}
                helperText={
                  requiredFields.includes(param.name)
                    ? context.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    : undefined
                }
                name={param.name}
                value={series}
                label="Serie"
              />
            </div>
            <div className="w-100 ml-2 mt-2">
              <TextValidator
                fullWidth
                onChange={(e: any) => {
                  valuesArray[2] = e.target.value;
                  handleChange(groupName, param.name, valuesArray.join(','));
                }}
                error={requiredFields.includes(param.name)}
                helperText={
                  requiredFields.includes(param.name)
                    ? context.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    : undefined
                }
                type="number"
                name={param.name}
                value={number}
                label="Numar"
              />
            </div>
          </div>
        );
      case ControlType.DATE:
        return (
          <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={language}>
            <DatePicker
              fullWidth
              className="m-2"
              variant="inline"
              views={['year', 'month', 'date']}
              format={dateFormat.toUpperCase()}
              margin="normal"
              label={param.label}
              value={param.value ? moment(param.value, dateFormat.toUpperCase()) : null}
              error={requiredFields.includes(param.name)}
              helperText={
                requiredFields.includes(param.name)
                  ? context.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  : undefined
              }
              onChange={(date: MaterialUiPickersDate | null) =>
                handleChange(
                  groupName,
                  param.name,
                  date ? date.format(dateFormat.toUpperCase()) : ''
                )
              }
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        );
      default:
        return (
          <TextValidator
            fullWidth
            id={param.name}
            disabled={param.onlyForDisplay && !param.onlyForDisplayEditable}
            className="m-2"
            onChange={(e: any) => handleChange(groupName, param.name, e.target.value)}
            name={param.name}
            value={param.value}
            label={param.label}
            error={requiredFields.includes(param.name)}
            helperText={
              requiredFields.includes(param.name)
                ? context.translatorService.Tranlate(
                    'VALIDATORS_REQUIRED',
                    'Campul este obligatoriu'
                  )
                : undefined
            }
          />
        );
    }
  };

  return (
    <div>
      <Dialog open={isOpen} fullScreen fullWidth>
        <DialogTitle
          id="customized-dialog-title"
          style={{ color: '#ffffff', backgroundColor: '#1F2857' }}
        >
          <div>
            <Typography variant="h4" align="center">
              Generare Contract Vanzare-Cumparare
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            className="position-absolute"
            style={{ right: 12, top: 12, color: '#ffffff' }}
            onClick={handleClose}
            size={'small'}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {!state ? (
          <Loader />
        ) : (
          <div className="p-4">
            <ValidatorForm onSubmit={handleSubmit}>
              {Object.entries(state).map(([key, group]) => {
                return (
                  <Paper className="p-4 mt-4" key={key}>
                    <h5>{group.label}</h5>
                    <Grid container spacing={2}>
                      {group.parameters.map((param: ReportParameterExtended) => {
                        return (
                          <Grid item xs={3} key={param.name}>
                            {getControl(group.name, param)}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Paper>
                );
              })}
              <div className="text-center mt-2 mb-2">
                <Button variant="contained" color="primary" type="submit">
                  {context.translatorService.Tranlate('CASE_COMMENT_SEND', 'Trimite')}
                </Button>
              </div>
            </ValidatorForm>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ContractModal;
