import { VehicleFiltersState } from '../../../interfaces/Dashboard/VehicleFiltersState';

export const SET_MAKEID = '[FILTERS] SET_MAKEID';
export const SET_MODELID = '[FILTERS] SET_MODELID';
export const SET_VALUE_BY_NAME = '[FILTERS] SET_VALUE_BY_NAME';
export const SET_TECHNICAL_CONDITION_ID = '[FILTERS] SET_TECHNICAL_CONDITION_ID';
export const SET_STATUS_ID = '[FILTERS] SET_STATUS_ID';
export const SET_INVENTORY_AGE_FILTER = '[FILTERS] SET_INVENTORY_AGE_FILTER';
export const SET_FUEL_ID = '[FILTERS] SET_FUEL_ID';
export const SET_TRANSMISSION_TYPE_ID = '[FILTERS] SET_TRANSMISSION_TYPE_ID';
export const SET_TRACTION_TYPE_ID = '[FILTERS] SET_TRACTION_TYPE_ID';
export const SET_LOCATION_ID = '[FILTERS] SET_LOCATION_ID';
export const RESET_INVENTORY_FILTERS = '[FILTERS] RESET_INVENTORY_FILTERS';

export const vehicleDashboardReducer = (state: VehicleFiltersState, action: any) => {
  switch (action.type) {
    case SET_MAKEID:
      return { ...state, makeId: action.makeId !== state.makeId ? action.makeId : undefined };
    case SET_MODELID:
      return { ...state, modelId: action.modelId !== state.modelId ? action.modelId : undefined };
    case SET_TECHNICAL_CONDITION_ID:
      return {
        ...state,
        technicalConditionId:
          action.technicalConditionId !== state.technicalConditionId
            ? action.technicalConditionId
            : undefined
      };
    case SET_STATUS_ID:
      return {
        ...state,
        statusId: action.statusId !== state.statusId ? action.statusId : undefined
      };
    case SET_LOCATION_ID:
      return {
        ...state,
        locationId: action.locationId !== state.locationId ? action.locationId : undefined
      };
    case SET_FUEL_ID:
      return {
        ...state,
        fuelId: action.fuelId !== state.fuelId ? action.fuelId : undefined
      };
    case SET_TRANSMISSION_TYPE_ID:
      return {
        ...state,
        transmissionTypeId:
          action.transmissionTypeId !== state.transmissionTypeId
            ? action.transmissionTypeId
            : undefined
      };
    case SET_INVENTORY_AGE_FILTER:
      return {
        ...state,
        inventoryAgeFilter:
          action.inventoryAgeFilter !== state.inventoryAgeFilter
            ? action.inventoryAgeFilter
            : undefined
      };
    case SET_TRACTION_TYPE_ID:
      return {
        ...state,
        tractionTypeId:
          action.tractionTypeId !== state.tractionTypeId ? action.tractionTypeId : undefined
      };
    case SET_VALUE_BY_NAME:
      return { ...state, [action.name]: action.value };
    case RESET_INVENTORY_FILTERS:
      return {
        ...state,
        makeId: undefined,
        modelId: undefined,
        fuelId: undefined,
        locationId: undefined,
        transmissionTypeId: undefined,
        tractionTypeId: undefined,
        inventoryAgeFilter: undefined,
        statusId: undefined,
        technicalConditionId: undefined
      };
    default:
      return state;
  }
};
