import React from 'react';
import { Button, IconButton, Modal, TextField, Typography } from '@material-ui/core';
import 'react-calendar/dist/Calendar.css';
import { TranslatorService } from '../../services/TranslatorService';
import CloseIcon from '@material-ui/icons/Close';
import { AppointmentCalendar } from './AppointmentCalendar';


interface ConfirmAppointmentProps {
  visible: boolean;
  setIsVisible: (val:boolean) => void,
  title:string;
  comment?: string;
  setComment?: (val:any) => void
  date: Date | null;
  setDate: any;
  hour: string | null
  setHour: any,
  onClickButton:any,
  calendarText?:string,
  buttonText:string;
}

export const ConfirmAppointment = ({ visible, setIsVisible, title, comment, setComment, date, setDate, hour, setHour, onClickButton, calendarText, buttonText }: ConfirmAppointmentProps) => {

  const translatorService = new TranslatorService();

  return (
    <div>
      <Modal open={visible} onClose={() => { setIsVisible(false) }}>

        <div className="search-vehicle-modal" style={{ minWidth: 300, minHeight: 380, display: 'flex', alignContent: 'center', flexDirection: 'column', }}>
          <div className="text-right">
            <IconButton onClick={() => { setIsVisible(false) }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="h4" style={{ marginBottom: 40, textAlign: 'center', marginTop: 22 }}>
            {title}
          </Typography>

          <div style={{ paddingRight: 30, paddingLeft: 30 }}>
            <Typography>
              {translatorService.Tranlate('APPOINTMENT_RESCHEDULE_MESSAGE', 'Select a new date for scheduling')}
            </Typography>
            <AppointmentCalendar
              showDefaultCalendar
              day={date}
              setDay={setDate}
              hour={hour}
              setHour={setHour}
              minDate={new Date()}
            />
            {calendarText  ?<Typography style={{ marginBottom: 16, marginTop: 26 }}>
              {calendarText}
            </Typography> :null}
            { setComment ? <TextField
              label={translatorService.Tranlate('APPOINTMENT_COMMENT', "Comment")}
              style={{ marginTop: 0, width: '100%' }}
              variant="outlined"
              margin="normal"
              multiline={true}
              value={comment}
              onChange={(e: any) => setComment(e.target.value)}
            />:null}
          </div>
          <Button
            variant="contained"
            style={{ backgroundColor: '#5383ff', color: 'white', marginRight: 8, marginTop:50, alignSelf: 'center' }}
            onClick={onClickButton}
          >
            {buttonText}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
