import  { useContext, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from '@material-ui/core';
import { Car } from '../agenda/agenda.types';
import { isNullOrUndefined } from 'util';
import React from 'react';
import { ApplicationContext } from '../../context/Contexts';
import { Model, ModelData } from '../../interfaces/Case';
import Form from '@rjsf/material-ui';
import { IChangeEvent, ISubmitEvent } from '@rjsf/core';
import CloseIcon from '@material-ui/icons/Close';
import { GTErrorCode, ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import * as R from 'ramda';
import Loader from '../Loader';
import { useSnackbar } from 'notistack';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Autocomplete } from '@material-ui/lab';
import { IReferential } from '../../interfaces/IReferential';

const FORM_JSON = {
  type: "object",
  required:['plateNumber', 'vin', 'brandId', 'modelId'],
  properties: {
    plateNumber: {
      type: "string",
      title: "Plate Number",
      tag: "FRM_CAR_DATA_PLATE_NUMBER"
    },
    vin: {
      type: "string",
      title: "Vin",
      tag: "FRM_CAR_DATA_VIN",
      minLength: 17,
      maxLength: 17

    },
    brandId: {
      type: "number",
      title: "Brand",
      tag: "FRM_CAR_DATA_BRAND",
      anyOf: [
        {
          type: "number",
          title: "CAR_BRAND_ENUM",
          enum: [
            1
          ]
        }
      ]
    },
    modelId: {
      type: "number",
      title: "Model",
      tag: "FRM_CAR_DATA_MODEL",
      anyOf: [
        {
          type: "number",
          title: "CAR_MODEL_ENUM",
          enum: [
            1
          ]
        }
      ]
    }
  }
}

interface AddNewCarModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (vehicle:Car) => void;
}

interface FormType {
  plateNumber:string;
  vin:string;
  brandId:number;
  modelId?:number;
  modelList:Model[],
  brandList:IReferential[]
}

const AddNewCarModal: React.FC<AddNewCarModalProps> = ({ open, onClose, onSave }) => {
  const [formData, setFormData] = useState<FormType>({} as FormType);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [modelData, setModelData] = useState<ModelData[]>([]);
  const [selectedModelData, setSelectedModelData] = useState<any>();
  const context = useContext(ApplicationContext);

  const loadData = async () => {
    setIsLoading(true);
    const refCarBrand = Referentials.referential.find(
      (item) => item?.code === ReferentialCode.CarBrands
    );

    const futureBrandList = await context.referentialService.Get(refCarBrand!.baseUrl)
    let futureModelList: Model[] = [];
    futureModelList = await context.caseService.GetCarModelsByBrand(futureBrandList[0].id);
    setFormData((prevState) => ({
      ...prevState,
      modelList: futureModelList,
      brandList:futureBrandList
    }));
    setIsLoading(false)
  };
  React.useEffect(()=>{
    loadData()
  },[])

  const closeModal = () => {
    onClose();
    setFormData({} as FormType)
  }


  const handleArraySchema = (schema: any) => {
    if (isNullOrUndefined(schema.properties)) {
      return schema;
    }

    for (const key of Object.keys(schema.properties)) {
      if (schema.properties[key].anyOf === undefined) {
        continue;
      }
      if (schema.properties[key].anyOf.length === 0) {
        continue;
      }
      if (
        !!formData?.brandList?.length &&
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf.length != 0 &&
        schema.properties[key].anyOf[0].title === 'CAR_BRAND_ENUM'
      ) {
        schema.properties[key].anyOf = formData?.brandList?.map((item) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
      }
      if (
        !!formData?.modelList?.length &&
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf.length != 0 &&
        schema.properties[key].tag === 'FRM_CAR_DATA_MODEL'
      ) {
        const models = formData?.modelList?.map((item) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
        schema.properties[key].anyOf = models;
      }
    }
    return schema;
  };

  const handleAutocompleteModelDataChange = async (newValue: ModelData | null) => {
    setSelectedModelData(newValue);
  };

  const renderAddModelForm = () => {
    return (
      <ValidatorForm onSubmit={submitSelectedModelData}>
        <div className="text-center m-0">
          <Autocomplete
            id="supplier"
            className="mb-3"
            options={modelData}
            value={selectedModelData}
            onChange={(e: any, newValue: ModelData | null) => handleAutocompleteModelDataChange(newValue)}
            getOptionLabel={(option: ModelData) => option.modelDescription || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="supplier"
                value={selectedModelData}
                label={context.translatorService.Tranlate('FRM_CAR_DATA_MODEL', 'Model Autovehicul')}
                fullWidth
                validators={['required']}
                errorMessages={[
                  context.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
        </div>
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={()=>{setIsDialogOpen(false)}}
          >
            {context.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {context.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  };

  const submitSelectedModelData = async () => {
    try {
      const model = formData.modelList.find(
        (item:Model) => item.code === selectedModelData!.umc
      );
      if (isNullOrUndefined(model)) {
        return;
      }
      setFormData({
        ...formData,
        modelId: model.id
      })
      setIsDialogOpen(false);

    } catch (ex) {
      enqueueSnackbar(context.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  const onBlur = async (id: string, value: any) => {
    if (id === 'root_vin') {
      if (R.test(/^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/, value)) {
        const result = await context.caseService.DecodeVin(value);
        if (result.error !== null) {
          if (result.error.errorInfo.code == GTErrorCode.THE_VIN_NUMBER_SENT_DOES_NOT_DECODE_ANY_MODEL) {
            enqueueSnackbar(context.translatorService.Tranlate('THE_VIN_NUMBER_SENT_DOES_NOT_DECODE_ANY_MODEL', 'Din păcate autovehiculul selectat nu poate fi identificat în aplicație !'), {
              variant: 'warning',
              autoHideDuration: 5000
            });
          }
          return;
        }
        if (result.responseInfo.vehicleInfoDec.modelList.modelData.length === 0) {
          return;
        }
        const makeCode = result.responseInfo.vehicleInfoDec.modelList.modelData[0].makeCode;
        const brand = formData?.brandList?.find((item:any) => makeCode.startsWith(item.code!, 0));
        if (isNullOrUndefined(brand)) {
          return;
        }
        const futureModelList = await context.caseService.GetCarModelsByBrand(brand.id);
        if (result.responseInfo.vehicleInfoDec.modelList.modelData.length > 1) {
          enqueueSnackbar(context.translatorService.Tranlate('SELECT_MODEL_DIALOG', 'Va rugam selectati modelul auto!'), {
            variant: 'warning',
            autoHideDuration: 5000
          });
          setIsDialogOpen(true);
          setFormData((prevState) => {
            const newState = {...prevState};
            delete newState.modelId;
            newState.modelList=[...futureModelList];
            newState.brandId= brand.id;
            newState.vin= value;
            return newState;
          });
          setModelData(result.responseInfo.vehicleInfoDec.modelList.modelData)
        } else {
          const model = futureModelList.find(
            (item) => item.code === result.responseInfo.vehicleInfoDec.modelList.modelData[0].umc
          );
          if (isNullOrUndefined(model)) {
            setFormData((prevState) => ({
              ...prevState,
              modelList: [...futureModelList],
              brandId: brand.id,
              vin: value,
            }));
            return;
          }
          setFormData((prevState) => ({
            ...prevState,
            modelList: [...futureModelList],
            brandId: brand.id,
            modelId: model.id, 
            vin: value,
          }));
        }
      } else {
        enqueueSnackbar(context.translatorService.Tranlate('INVALID_CAR_VIN_IDENTIFICATOR', "Te rugăm să te asiguri că seria de șasiu introdusă  are 17 caractere alfanumerice și nu conține spații sau literele 'O' sau 'I'!"), {
          variant: 'warning',
          autoHideDuration: 6000
        });
      }
    }
  };

  const onFormChange = async (e: IChangeEvent<FormType>) => {
    if (
      e.formData.brandId !== formData.brandId &&
      !isNullOrUndefined(e.formData.brandId)
    ) {
      const futureModelList = await context.caseService.GetCarModelsByBrand(e.formData.brandId);
      const futureFormData = e.formData;
      if (futureModelList.length != 0) {
        futureFormData.modelId = futureModelList[0].id;
      }
      setFormData({
        ...futureFormData,
        modelList: [...futureModelList],
      });
      return;
    }

    setFormData(e.formData)
  };

  const onSubmit = (e:any) =>{
    const indexModel = formData.modelList.findIndex((model)=>model.id === e.formData.modelId);
    const indexBrand = formData.brandList.findIndex((brand)=>brand.id === e.formData.brandId);
    onSave({
      vin: e?.formData.vin || null,
      plateNumber: e?.formData.plateNumber || null,
      model:{
        brand: indexBrand !== -1 ? formData.brandList[indexBrand].displayName : null,
        description: indexModel !== -1 ? formData.modelList[indexModel].description : null,
        name: indexModel !== -1 ? formData.modelList[indexModel]?.description?.split('(')[0] :'',
        carBody:null
      },
      firstPlateDate:null,
      numberOfKilometers:null,
      addManual:true
    })
    onClose()
  }

  const renderFormData = (form: any) => {
    const jsonSchema = form;
    let schema = context.translatorService.TranslateSchema(jsonSchema);
    schema = handleArraySchema(schema);
    return (
      <div className="mx-5 px-5 pb-3 car-details-form">
        <Form
          schema={schema}
          liveValidate
          showErrorList={false}
          formData={formData}
          onBlur={onBlur}
          onChange={async (e) => await onFormChange(e)}
          onSubmit={async (e: ISubmitEvent<any>) => await onSubmit(e)}
          transformErrors={context.translatorService.TranslateErrors}
        >
          <div style={{ textAlign:'end', marginTop:16}}>
            <Button onClick={closeModal} color="primary">
              Anulează
            </Button>
            <Button type='submit' color="primary">
              Salvează
            </Button>
          </div>
        </Form>
        {isDialogOpen ? (
          <Dialog
            onClose={()=>{setIsDialogOpen(false)}}
            aria-labelledby="customized-dialog-title"
            open={isDialogOpen}
            fullScreen={false}
            disableBackdropClick
            fullWidth={true}
          >
            <DialogTitle
              id="customized-dialog-title"
              style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
            >
              <span>{context.translatorService.Tranlate('FRM_CAR_DATA_MODEL', 'Model Autovehicul')}</span>
              <IconButton
                aria-label="close"
                className="position-absolute"
                style={{ right: 12, top: 12, color: '#ffffff' }}
                onClick={()=>{setIsDialogOpen(false)}}
                size={'small'}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="p-3" dividers style={{ backgroundColor: '#fafafa' }}>
              {renderAddModelForm()}
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle style={{textAlign:'center', marginTop:32}}>Add new car</DialogTitle>
      <DialogContent style={{width:'50%', marginLeft:'25%'}}>
        {isLoading && !formData.modelList?.length && !formData.brandList?.length  ? <Loader/>: renderFormData(FORM_JSON)}
      </DialogContent>
    </Dialog>
  );
};

export default AddNewCarModal;