import React from 'react';
import { ApplicationState, AppState } from '../../store';
import { connect } from 'react-redux';
import {
  Grid,
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WrapperSimple } from '../../layout-components';
import { withSnackbar, ProviderContext } from 'notistack';
import { IAuthService } from '../../services/Interfaces/IAuthService';
import { isNullOrUndefined } from 'util';

type UserProfileProps = AppState & ProviderContext;

interface IUserProfileState {
  openModal: boolean;
}

class UserProfile extends React.PureComponent<UserProfileProps, IUserProfileState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private authService!: IAuthService;

  static contextType = ApplicationContext;

  state = {
    openModal: false
  };

  public handleClickOpenModal = () => {
    this.setState({ openModal: true });
  };
  public handleClickCloseModal = () => {
    this.setState({ openModal: false });
  };

  // apasa reseteaza parola
  // se trimite mail
  //apare mesaj Operatiune efectuata cu sucess
  // se deschide modalul
  // se apasa ok si se face delogarea
  public resetPassword = async () => {
    try {
      if (this.props.appUser!.id === null) {
        return;
      }
      await this.appUserService.ResetPassword('');

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });

      this.handleClickOpenModal();
    } catch {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public logout = () => {
    this.setState({ openModal: false });
    return this.authService.logoutAsync();
  };

  public renderForm() {
    return (
      <Box m={1} p={1}>
        <WrapperSimple
          sectionHeading={this.translatorService.Tranlate(
            'USER_PROFILE_HEADER',
            'Profil utilizator'
          )}
        >
          <div className="d-flex justify-content-between w-100">
            <Grid container spacing={4}>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={4} alignContent="center" alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <div className="text-center">
                      <div className="avatar-icon-wrapper  m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper  m-0 d-140">
                          <FontAwesomeIcon
                            icon={['far', 'user']}
                            className="d-flex align-self-center display-1 "
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <div className="p-3">
                      <TextField
                        fullWidth
                        name="email"
                        className="m-2"
                        id="email"
                        value={this.props.appUser!.email}
                        label={this.translatorService.Tranlate(
                          'EDIT_USER_USER_EMAIL_FIELD',
                          'Email'
                        )}
                      />
                      <TextField
                        fullWidth
                        className="m-2"
                        id="lastName"
                        name="lastName"
                        value={this.props.appUser!.lastName}
                        label={this.translatorService.Tranlate('EDIT_USER_LAST_NAME_FIELD', 'Nume')}
                      />
                      <TextField
                        fullWidth
                        className="m-2"
                        id="firstName"
                        name="firstName"
                        value={this.props.appUser!.firstName}
                        label={this.translatorService.Tranlate(
                          'EDIT_USER_FIRST_NAME_FIELD',
                          'Prenume'
                        )}
                      />
                      <TextField
                        fullWidth
                        className="m-2"
                        id="phoneNumber"
                        value={this.props.appUser!.phoneNumber || ''}
                        label={this.translatorService.Tranlate('EDIT_USER_PHONE_FIELD', 'Telefon')}
                      />
                      <TextField
                        fullWidth
                        className="m-2"
                        id="jobFunction"
                        value={this.props.appUser!.jobFunction || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_USER_JOBFUNCTION_FIELD',
                          'Functie'
                        )}
                      />

                      <TextField
                        fullWidth
                        className="m-2"
                        id="organization"
                        value={this.props.appUser!.organization!.name || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_USER_ORGANIZATION_FIELD',
                          'Organizatie'
                        )}
                      />

                      <TextField
                        fullWidth
                        className="m-2"
                        id="manager"
                        value={this.props.appUser!.managerFullName || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_USER_MANAGER_FIELD',
                          'Manager'
                        )}
                      />
                      <TextField
                        fullWidth
                        className="m-2"
                        id="role"
                        value={
                          isNullOrUndefined(this.props.appUser!.userRole)
                            ? ''
                            : this.props.appUser!.userRole!.displayName || ''
                        }
                        label={this.translatorService.Tranlate('EDIT_USER_ROLE_FIELD', 'Rol')}
                      />
                      <div className="text-right">
                        <Button
                          className="m-2"
                          variant="contained"
                          color="primary"
                          onClick={this.resetPassword}
                        >
                          {this.translatorService.Tranlate(
                            'ADMIN_USERS_TAB_USER_PASSWORD',
                            'Reseteaza parola'
                          )}
                        </Button>
                      </div>
                      <Dialog
                        open={this.state.openModal}
                        onClose={this.handleClickCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {this.translatorService.Tranlate(
                            'ADMIN_USERS_TAB_USER_PASSWORD',
                            'Reseteaza parola'
                          )}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {this.translatorService.Tranlate(
                              'PROFILE_RESET_PASSWORD_MESSAGE',
                              'A fost trimis un mail de resetare a parolei. Dupa resetare va rugam sa va reautentificati!'
                            )}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.logout} color="primary" autoFocus>
                            {this.translatorService.Tranlate('PROFILE_OK', 'OK')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </WrapperSimple>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.authService = (this.context as AppContext).authenticationService;
    return this.renderForm();
  }
}

export default connect(
  (state: ApplicationState) => state.app // Selects which state properties are merged into the component's props
)(withSnackbar(UserProfile as any));
