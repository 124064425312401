import React, { useContext } from 'react';
import { Box, Card, Grid, IconButton } from '@material-ui/core';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { SalesChannels } from '../../../interfaces/Dashboard/SalesChannels';
import { SalesTopSeller } from '../../../interfaces/Dashboard/SalesTopSeller';
import olxLogo from '../../../assets/images/OLX.png';
import autovitLogo from '../../../assets/images/Autovit.jpg';
import renaultLogo from '../../../assets/images/Renault.png';

interface SalesChannelProps {
  channelId: number;
  totalCount: number;
  activeCount: number;
  context: AppContext;
}

const SalesChannel: React.FC<SalesChannelProps> = ({
  channelId,
  totalCount,
  activeCount,
  context
}) => (
  <Grid item xs={3}>
    <Card className="h-100 p-4 text-center">
      <div className="text-center d-flex justify-content-center align-items-center flex-column">
        <Box className="header-logo-wrapper" title="Claim Box">
          <IconButton color="primary" size="medium" className="header-logo">
            {channelId == 1 && (
              <a href="https://www.autovit.ro/" target="_blank" rel="noopener noreferrer">
                <img
                  className="app-header-logo-img"
                  alt="Autovit Logo"
                  src={autovitLogo}
                  style={{ height: '50px', boxSizing: 'border-box' }}
                />
              </a>
            )}
            {channelId == 2 && (
              <a href="https://www.olx.ro/" target="_blank" rel="noopener noreferrer">
                <img
                  className="app-header-logo-img"
                  alt="Olx Logo"
                  src={olxLogo}
                  style={{ width: '90px', height: '50px', boxSizing: 'border-box' }}
                />
              </a>
            )}
            {channelId == 3 && (
              <a href="https://www.renault.ro/" target="_blank" rel="noopener noreferrer">
                <img
                  className="app-header-logo-img"
                  alt="Renault Logo"
                  src={renaultLogo}
                  style={{ width: '90px', height: '50px', boxSizing: 'border-box' }}
                />
              </a>
            )}
          </IconButton>
        </Box>
      </div>
      <div className="text-center d-flex justify-content-center align-items-center flex-column">
        <div className="mt-4">
          <h5>{context.translatorService.Tranlate('DASHBOARD_TOTAL_ADS', 'Total anunturi')}</h5>
          <h2>{totalCount}</h2>
        </div>
        <div className="mt-4">
          <h5>{context.translatorService.Tranlate('DASHBOARD_ACTIVE_ADS', 'Active')}</h5>
          <h2>{activeCount}</h2>
        </div>
      </div>
    </Card>
  </Grid>
);
interface SalesHeaderProps {
  channels: SalesChannels[];
  topSellers: SalesTopSeller[];
  onClickSeller(id: string): void;
}
const SalesHeader: React.FC<SalesHeaderProps> = ({ channels, topSellers, onClickSeller }) => {
  const context = useContext(ApplicationContext);
  return (
    <Grid container spacing={2}>
      {topSellers.length > 0 && (
        <Grid item xs={3}>
          <Card className="h-100 p-4 text-center">
            <h4 className="mb-4">
              {context.translatorService.Tranlate('DASHBOARD_TOP_SALE_PERSONS', 'Top vanzatori')}
            </h4>
            {topSellers?.map((seller, index) => {
              return (
                <Grid
                  onClick={() => {
                    onClickSeller(seller.id);
                  }}
                  key={seller.id}
                  container
                  spacing={2}
                  className="border-top border-bottom"
                >
                  <Grid className="border-right" item xs={3}>
                    <h1 className="mt-2">{index + 1}</h1>
                  </Grid>
                  <Grid item xs={9}>
                    <h5 className="rdw-left-aligned-block">{seller.buyerName}</h5>
                    <div className="rdw-left-aligned-block">{seller.organizationName}</div>
                  </Grid>
                </Grid>
              );
            })}
          </Card>
        </Grid>
      )}
      {channels?.map((channel) => {
        return (
          <SalesChannel
            key={channel.advertId}
            channelId={channel.advertId}
            totalCount={channel.totalCount}
            activeCount={channel.activeCount}
            context={context}
          />
        );
      })}
    </Grid>
  );
};

export default SalesHeader;
