import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';

interface Props {
  translatorService: ITranslatorService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enqueueSnackbar(param1: any, param2: any): void;
  vehicleService: IVehicleService;
}

const ImportVehiclesRenaultButton: React.FC<Props> = ({
  translatorService,
  enqueueSnackbar,
  vehicleService
}) => {
  const [fileInputKey, setFileInputKey] = useState<number>(0);

  const handleImportOriginalParts = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const files = event.target.files;

    if (!files || files.length === 0) {
      return;
    }

    const file = event.target.files![0];

    try {
      const result = await vehicleService.SaveImportedXlsxVehicles(file);

      enqueueSnackbar(
        <div>
          <p>
            {translatorService.Tranlate('VEHICLES_VEHICLE_IMPORTED', 'Vehicule importate: ')}{' '}
            {result.importedVehicleVins.length
              ? result.importedVehicleVins.join(', ')
              : translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
          </p>
          <p>
            {translatorService.Tranlate('VEHICLES_VEHICLE_DUPLICATED', 'Vehicule duplicate: ')}{' '}
            {result.duplicatedVehicleVins.length
              ? result.duplicatedVehicleVins.join(', ')
              : translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
          </p>
        </div>,
        {
          variant: 'info',
          autoHideDuration: 6000
        }
      );
      setFileInputKey((prevKey) => prevKey + 1);
    } catch {
      enqueueSnackbar('There was an unexpected error while importing the vehicles', {
        variant: 'error',
        autoHideDuration: 6000
      });
      setFileInputKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <>
      <input
        key={fileInputKey}
        id="importXlsxFile"
        type="file"
        style={{ display: 'none' }}
        onChange={handleImportOriginalParts}
      />
      <Button
        component="label"
        variant="contained"
        color="primary"
        onClick={() => document.getElementById('importXlsxFile')?.click()}
      >
        {translatorService.Tranlate(
          'VEHICLES_VEHICLE_IMPORT_VEHICLES_XLSX_FILE',
          'Importa Vehicule Renault'
        )}
      </Button>
    </>
  );
};

export default ImportVehiclesRenaultButton;
