import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { Grid, Paper } from '@material-ui/core';
import moment from 'moment';
import { MarketData } from '../../interfaces/Radar/MarketData';
import { v4 as uuidv4 } from 'uuid';
import RadarFilters from './RadarFilters';
import { ApplicationContext } from '../../context/Contexts';
import RadarMap from './RadarMap';
import RecentAdds from './RecentAdds';
import RadarDonutChart from './RadarDonutChart';
import RadarScatterChart from './RadarScatterChart';
import RadarLineChart from './RadarLineChart';
import noop from 'lodash/noop';
import Loader from '../Loader';
import {
  radarReducer,
  SET_FILTERS,
  SET_MARKET_DATA_LOADING,
  SET_MARKET_DATA,
  SET_SELECTED_MARKET_DATA,
  SET_SELECTED_SELLER,
  SET_SELECTED_SELLER_TYPE,
  SET_SELECTED_VEHICLE
} from './radarReducer';
import { RadarFilters as Filters } from '../../interfaces/Radar/RadarFilters';
import ApexCharts from 'apexcharts';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import { Vehicle } from '../../interfaces/Vehicle';
interface Props {
  vehicleId?: number;
}

const Radar: React.FC<Props> = ({ vehicleId }) => {
  const [state, dispatch] = useReducer(radarReducer, {
    filters: {
      makes: [],
      selectedMakes: [],
      models: [],
      selectedModels: [],
      firstRegistrationDate: moment().add(-5, 'year')
    },
    marketData: [],
    marketDataLoading: false
  });

  const { filters } = state;

  const selectedDataIndex = useRef<number | undefined>();

  const context = useContext(ApplicationContext);

  const filteredMarketData = useMemo(() => {
    let result: MarketData[] = state.marketData;

    if (state.selectedSeller) {
      result = result.filter((item) => item.source === state.selectedSeller);
    }

    if (state.selectedSellerType) {
      result = result.filter((item) => item.offeredBy === state.selectedSellerType);
    }

    return result;
  }, [state.marketData, state.selectedSeller, state.selectedSellerType]);

  const setFilters = useCallback((newFilters: Filters) => {
    dispatch({ type: SET_FILTERS, filters: newFilters });
  }, []);

  const setMarketDataLoading = useCallback((marketDataLoading: boolean) => {
    dispatch({ type: SET_MARKET_DATA_LOADING, marketDataLoading });
  }, []);

  const setMarketData = useCallback((marketData: MarketData[]) => {
    dispatch({ type: SET_MARKET_DATA, marketData });
  }, []);

  const setSelectedSeller = useCallback((selectedSeller?: string) => {
    dispatch({ type: SET_SELECTED_SELLER, selectedSeller });
  }, []);

  const setSelectedVehicle = useCallback((selectedVehicle?: Vehicle) => {
    dispatch({ type: SET_SELECTED_VEHICLE, selectedVehicle });
  }, []);

  const setSelectedSellerType = useCallback((selectedSellerType?: string) => {
    dispatch({ type: SET_SELECTED_SELLER_TYPE, selectedSellerType });
  }, []);

  const handleSelectedSellerDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const data = uniqBy(filteredMarketData, 'source');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const source = data[dataIndex].source;
        if (!isNil(source)) {
          setSelectedSeller(source);
        }
      }
    },
    [filteredMarketData, setSelectedSeller]
  );

  const handleSelectedSellerTypeDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const data = uniqBy(filteredMarketData, 'offeredBy');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const offeredBy = data[dataIndex].offeredBy;
        if (!isNil(offeredBy)) {
          setSelectedSellerType(offeredBy);
        }
      }
    },
    [filteredMarketData, setSelectedSellerType]
  );

  const setSelectedMarketData = useCallback((selectedMarketData: MarketData) => {
    dispatch({ type: SET_SELECTED_MARKET_DATA, selectedMarketData });
    // if (selectedMarketData) {
    //   const index = filteredMarketData.findIndex(
    //     (item: MarketData) => item.id === selectedMarketData?.id
    //   );
    //   ApexCharts.exec('radarScatterChart', 'toggleDataPointSelection', 0, index);
    //   selectedDataIndex.current = index;
    // } else {
    //   ApexCharts.exec(
    //     'radarScatterChart',
    //     'toggleDataPointSelection',
    //     0,
    //     selectedDataIndex.current
    //   );
    //   selectedDataIndex.current = undefined;
    // }
  }, []);

  const handleScatterClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const selectedMarketData = filteredMarketData[dataIndex];
        dispatch({ type: SET_SELECTED_MARKET_DATA, selectedMarketData });
      }
    },
    [filteredMarketData]
  );

  useEffect(() => {
    const getVehicleFilters = async () => {
      const vehicleHistory = true;
      const vehicleFilters = await context.vehicleService.GetVehiclesFilters(vehicleHistory);

      let vehicle;

      if (vehicleId) {
        vehicle = await context.vehicleService.GetVehicle(vehicleId);
      }

      if (
        vehicleFilters &&
        vehicle &&
        vehicle.modelId &&
        vehicle.makeId &&
        vehicle.make &&
        vehicle.model
      ) {
        setSelectedVehicle(vehicle);
        setFilters({
          period: 3,
          firstRegistrationDate: moment.utc(vehicle.firstRegistrationDate),
          makes: vehicleFilters.makeList.map(({ id, displayName }) => {
            return {
              id,
              name: displayName
            };
          }),
          models: vehicleFilters.modelList.map(({ id, displayName, dependencyId }) => {
            return {
              id,
              name: displayName,
              makeId: dependencyId || -1
            };
          }),
          selectedMakes: [{ id: vehicle.makeId || -1, name: vehicle.make?.displayName || '' }],
          selectedModels: [{ id: +vehicle.modelId || -1, name: vehicle.model?.displayName || '' }]
        });
      }
    };

    getVehicleFilters();
  }, [context.vehicleService, setFilters, setSelectedVehicle, vehicleId]);

  useEffect(() => {
    const getMarketData = async () => {
      setMarketDataLoading(true);
      const marketData = await context.vehicleService.GetVehicleMarketData({
        make: filters.selectedMakes[0].name,
        model: filters.selectedModels[0].name,
        registrationDate: filters.firstRegistrationDate.toDate(),
        months: filters.period
      });

      for (const item of marketData) {
        item.id = uuidv4();
      }

      if (marketData) {
        setMarketData(marketData);
        setMarketDataLoading(false);
      }
    };
    if (
      filters.makes.length &&
      filters.makes.length &&
      filters.selectedMakes.length &&
      filters.selectedModels.length
    ) {
      getMarketData();
    }
  }, [
    context.vehicleService,
    filters.selectedMakes,
    filters.selectedModels,
    filters.firstRegistrationDate,
    filters.period,
    filters.makes.length,
    setMarketDataLoading,
    setMarketData
  ]);

  if (!filters.makes.length && !filters.models.length) {
    return <Loader />;
  }

  return (
    <div>
      <RadarFilters filters={filters} setFilters={setFilters} />
      {state.marketDataLoading ? (
        <Loader />
      ) : !state.marketData.length ? (
        <Paper className="p-4 d-flex justify-content-center mt-2">
          <h5>
            {context?.translatorService.Tranlate(
              'NO_RECORDS_TO_DISPLAY',
              'Nu exista date de afisat'
            )}
          </h5>
        </Paper>
      ) : null}
      {!state.marketDataLoading && state.marketData.length ? (
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={6}>
            <Paper className="h-100">
              <RadarMap
                filteredMarketData={filteredMarketData}
                selectedMarketData={state.selectedMarketData}
                setSelectedMarketData={setSelectedMarketData}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className="p-1">
              <RadarScatterChart
                title={context?.translatorService.Tranlate(
                  'RADAR_PRICE_KM_TABLE_TITLE',
                  'Pret in functie de numarul de kilometri'
                )}
                filteredMarketData={filteredMarketData}
                currentVehicleMileage={state.selectedVehicle?.kilometersNr}
                handleScatterClick={handleScatterClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className="p-1">
              <RadarDonutChart
                title={context?.translatorService.Tranlate('RADAR_SALE_TYPE', 'Tip vanzator')}
                filteredMarketData={
                  state.selectedSellerType ? state.marketData : filteredMarketData
                }
                property="offeredBy"
                handleDonutClick={handleSelectedSellerTypeDonutClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className="p-1">
              <RadarDonutChart
                title={context?.translatorService.Tranlate(
                  'RADAR_PLATFORM_DISTRIBUTION',
                  'Anunt per site'
                )}
                filteredMarketData={state.selectedSeller ? state.marketData : filteredMarketData}
                property="source"
                handleDonutClick={handleSelectedSellerDonutClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className="p-1 d-flex justify-content-center">
              <div style={{ width: '80%' }}>
                <RadarLineChart
                  title={context.translatorService.Tranlate(
                    'RADAR_PRICE_EVOLUTION',
                    'Evolutie pret'
                  )}
                  period={filters.period}
                  filteredMarketData={filteredMarketData}
                  handleLineClick={noop}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <RecentAdds filteredMarketData={filteredMarketData} />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default Radar;
