import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ApplicationState, CaseSettingsActionCreators, VehicleActionCreators } from '../../store';
import { withSnackbar } from 'notistack';
import {
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Tooltip,
  InputAdornment,
  Modal,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { ScaleLoader } from 'react-spinners';
import {
  ValuationVehicle,
  ValuationExtraOption,
  ValuationModel,
  ValuationBody,
  PlateNumber
} from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { RefConstants } from '../../helpers/Constants';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IReferential, IReferentialModel } from '../../interfaces/IReferential';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import CloseIcon from '@material-ui/icons/Close';
import DetailsValuation from './DetailsValuation';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Column } from 'material-table';
import { Cost, Vehicle, VehicleOption } from '../../interfaces/Vehicle';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { Utils } from '../../helpers/Utils';
import * as R from 'ramda';
import { ICaseDetailsCaseTabCarDetailsExtendedContainerState } from '../cases/vehicleDetails/ICaseDetailsCaseTabCarDetailsExtendedContainerState';
import { ExternalCaseDetailsCaseTabCarDetailsExtendedContainerProps } from '../cases/vehicleDetails/ExternalCaseDetailsCaseTabCarDetailsExtendedContainerProps';
import { ICaseDetailsCaseTabCarDetailsExtendedContainerProps } from '../cases/vehicleDetails/ICaseDetailsCaseTabCarDetailsExtendedContainerProps';
import _ from 'lodash';
import manualVinIdentification from '../../assets/images/vin/hand-pen-01.svg';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

export const standardOptionsSchemaIds = [
  301, 2501, 3501, 3601, 23501, 23601, 23701, 23801, 23901, 24001, 31001, 32601, 34801, 38001,
  38701, 38801, 42101, 42901, 44501, 48401, 49001, 50001, 55601, 57901, 58201, 58301, 58401, 58501,
  58601, 59201, 59301, 59801, 63601, 63901
];

class DetailsValuationContainer extends React.PureComponent<
  ICaseDetailsCaseTabCarDetailsExtendedContainerProps,
  ICaseDetailsCaseTabCarDetailsExtendedContainerState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  static displayName = 'CaseDetailsCaseTabCarDetailsExtendedForm';

  state = {
    isLoading: false,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    isValuationOpen: false,
    selectedVehicle: null,
    valuationVehicles: [] as ValuationVehicle[],
    valuationBodies: [] as ValuationBody[],
    valuationModels: [] as ValuationModel[],
    showWizard: false,
    attachType: {} as IReferential,
    personTypes: [] as IReferential[],
    brandTypes: [] as IReferential[],
    modelTypes: [] as IReferentialModel[],
    bodyTypes: [] as IReferential[],
    versionTypes: [] as IReferential[],
    fuelTypes: [] as IReferential[],
    vehicleTypes: [] as IReferential[],
    refurbishedList: [] as IReferential[],
    mvoDvBattypList: [] as IReferential[],
    polutionNormTypes: [] as IReferential[],
    transmissionTypes: [] as IReferential[],
    tractionTypes: [] as IReferential[],
    paintTypes: [] as IReferential[],
    tiresTypes: [] as IReferential[],
    rimsTypes: [] as IReferential[],
    colorTypes: [] as IReferential[],
    carInteriorStateTypes: [] as IReferential[],
    carExteriorStateTypes: [] as IReferential[],
    carMechanicalStateTypes: [] as IReferential[],
    carStandardOptions: [] as VehicleOption[],
    carOptionalOptions: [] as VehicleOption[],
    workflowCalculation: null,
    datePickerFirstRegistrationError: '',
    showRequiredVinError: false,
    optionCodes: [] as string[],
    costValues: [] as Cost[],
    carDetailsExtendedData: {
      id: 0,
      caseId: this.props.caseSettingsState.case ? this.props.caseSettingsState.case!.id : 0,
      plateNumber: '',
      vin: '',
      makeId: null,
      model: null,
      modelId: null,
      version: '',
      makeDate: '',
      firstRegistrationDate: '',
      kilometersNr: null,
      power: null,
      horsePower: null,
      engineSize: null,
      co2: null,
      inConditionComments: null,
      outConditionComments: null,
      technicalConditionComments: null,
      fuelTypeId: null,
      vehicleTypeId: null,
      pollutionNormId: null,
      transmissionTypeId: null,
      tractionTypeId: null,
      color: null,
      colorId: null,
      equipmentVersion: '',
      wheelsTypeId: null,
      tiresTypeId: null,
      paintTypeId: null,
      bodyTypeId: null,
      inConditionId: null,
      outConditionId: null,
      technicalConditionId: null,
      make: null,
      fuelType: null,
      vehicleType: null,
      pollutionNorm: null,
      transmissionType: null,
      tractionType: null,
      wheelsType: null,
      tiresType: null,
      paintType: null,
      bodyType: null,
      inCondition: null,
      outCondition: null,
      technicalCondition: null,
      vehicleOptions: [] as VehicleOption[],
      location: '',
      nrOfDoors: null,
      radioCode: '',
      engineManufactureCode: '',
      purchasePrice: '',
      refurbished: null,
      refurbishedId: null
    } as Vehicle,
    carDetailsFetchedData: {} as Partial<Vehicle>,
    isOpen: false,
    dialogVisible: false,
    ignoreTimeStampSwitch: false,
    ignoreExistingMappingSwitch: false,
    showSearchingLoader: false
  } as ICaseDetailsCaseTabCarDetailsExtendedContainerState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        this.props.resetVehicleState();
        await this.loadData();
      }
    );
  }

  submitForm = async () => {
    console.log('');
  };

  submitVehicleData = async (e: any) => {
    let newVehicle = { ...this.state.carDetailsExtendedData } as Vehicle;
    newVehicle.vehicleOptions = [];

    this.state.carStandardOptions.forEach((standardOption) => {
      standardOption.isSelected = standardOption.checked;
    });
    this.state.carOptionalOptions.forEach((optionalOption) => {
      optionalOption.isSelected = optionalOption.checked;
    });

    newVehicle.vehicleOptions.push(...this.state.carStandardOptions);
    newVehicle.vehicleOptions.push(...this.state.carOptionalOptions);

    newVehicle = {
      ...newVehicle
    };

    try {
      if (newVehicle.valuationVehicleId && newVehicle.vin) {
        await this.caseService.ValuationSaveMapping(newVehicle.vin, newVehicle.valuationVehicleId);
      }
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  onChange =
    (fieldName: string, fieldNameId?: string) => (e: any, newValue?: IReferential | null) => {
      if (fieldName === 'plateNumber') {
        const newValue = e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ''); // Remove special characters from plate number input
        const updatedState = {
          ...this.state.carDetailsExtendedData,
          [fieldName]: newValue
        };

        if (fieldNameId) {
          updatedState[fieldNameId] = Utils.GetIdOrNull(newValue);
        }

        this.setState({
          carDetailsExtendedData: updatedState
        });
      } else {
        !R.isNil(fieldNameId)
          ? this.setState({
              carDetailsExtendedData: {
                ...this.state.carDetailsExtendedData,
                [fieldName]: newValue || e.target.value,
                [fieldNameId]: Utils.GetIdOrNull(newValue)
              }
            })
          : this.setState({
              carDetailsExtendedData: {
                ...this.state.carDetailsExtendedData,
                [fieldName]: newValue || e.target.value
              }
            });
      }
    };

  validateFirstRegistrationDate = (selectedDate?: string): string => {
    let error = '';

    if (!selectedDate) {
      error = this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
    }

    this.setState({
      datePickerFirstRegistrationError: error,
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        firstRegistrationDate: selectedDate || ''
      }
    });

    return error;
  };

  handleFirstRegistrationDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    const selectedDate = date ? date.format('YYYY-MM') : undefined;
    this.validateFirstRegistrationDate(selectedDate);
  };

  setSelectedOptions = (extraoptions: ValuationExtraOption[], optionCodes: string[]) => {
    const indexArray: number[] = [];
    optionCodes.forEach((item) => {
      const index = extraoptions.findIndex((o) => o.optionCode === item);
      if (index !== undefined && index !== -1) {
        indexArray.push(index);

        (extraoptions[index] as any).tableData = { checked: true };
        extraoptions[index].checked = true;
      }
    });

    return indexArray;
  };

  getValuationInfoConfirmation = async () => {
    if (R.isEmpty(this.state.carDetailsExtendedData.vin)) {
      this.setState({
        showRequiredVinError: true
      });
      return;
    }

    if (
      !R.test(
        /^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/,
        this.state.carDetailsExtendedData.vin
      )
    ) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'INVALID_CAR_VIN_IDENTIFICATOR',
          "Te rugăm să te asiguri că seria de șasiu introdusă  are 17 caractere alfanumerice și nu conține spații sau literele 'O' sau 'I'!"
        ),
        {
          variant: 'warning'
        }
      );
      return;
    }

    this.setState({ selectedVehicle: null, isOpen: true, dialogVisible: true }); //reset the vehicle brought from valuation
  };

  getValuationInfo = async () => {
    this.setState({ selectedVehicle: null, isOpen: false, dialogVisible: false });
    try {
      this.setState({ showSearchingLoader: true });

      const body = {
        registration_date:
          this.state.carDetailsExtendedData.firstRegistrationDate !== ''
            ? this.state.carDetailsExtendedData.firstRegistrationDate
            : null,
        ignore_date: this.state.ignoreTimeStampSwitch,
        override_existing_match: this.state.ignoreExistingMappingSwitch
      };

      const result = await this.caseService.GetValuationVehiclesInfo(
        this.state.carDetailsExtendedData.vin,
        null,
        body
      );

      if (result.vehicles.length === 1) {
        result.vehicles[0].vinQuery = true;
        this.selectedVehicleHandler(result.vehicles[0], result.extraOptionCodes);

        this.setState({
          ...this.state,
          valuationVehicles: result.vehicles,
          optionCodes: result.extraOptionCodes,
          isValuationOpen: true,
          showSearchingLoader: false
        });
      } else {
        if (result.vehicles.length == 0) {
          this.setState({
            // reset vin if no vehicle found
            carDetailsExtendedData: {
              ...this.state.carDetailsExtendedData,
              vin: ''
            }
          });

          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'VIN_IDENTIFICATION_NOT_FOUND',
              'Identificarea VIN nu este posibilă , vă rugăm să continuaţi cu selectia manuală a autovehiculului!'
            ),
            {
              variant: 'warning',
              autoHideDuration: 5000
            }
          );
        }

        for (let i = 0; i < result.vehicles.length; i++) {
          result.vehicles[i].vinQuery = true;
        }

        this.setState({
          ...this.state,
          valuationVehicles: result.vehicles,
          optionCodes: result.extraOptionCodes,
          isValuationOpen: true,
          showSearchingLoader: false
        });
      }
    } catch (err) {
      this.setState({ showSearchingLoader: false });

      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'VEHICLE_NOT_IDENTIFIED',
          'Masina nu se poate identifica dupa VIN si data primei inmatriculari. Va rugam incercati identificarea manuala'
        ),
        { variant: 'error' }
      );
    }
  };

  handleCloseConfirmationDialog = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  getManualValuationInfo = async () => {
    if (
      this.state.carDetailsExtendedData.vin &&
      !R.test(
        /^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/,
        this.state.carDetailsExtendedData.vin
      )
    ) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'INVALID_CAR_VIN_IDENTIFICATOR',
          "Te rugăm să te asiguri că seria de șasiu introdusă  are 17 caractere alfanumerice și nu conține spații sau literele 'O' sau 'I'!"
        ),
        {
          variant: 'warning'
        }
      );
      return;
    }
    this.setState({ selectedVehicle: null }); //reset the vehicle brought from valuation

    try {
      this.setState({
        ...this.state,
        valuationVehicles: [],
        optionCodes: [],
        isValuationOpen: true,
        showRequiredVinError: false,
        // if manual identification clicke then reset VIN
        carDetailsExtendedData: {
          ...this.state.carDetailsExtendedData,
          vin: ''
        }
      });
    } catch (err) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'VEHICLE_NOT_IDENTIFIED',
          'Masina nu se poate identifica dupa VIN si data primei inmatriculari. Va rugam incercati identificarea manuala'
        ),
        { variant: 'error' }
      );
    }
  };

  goToValuationHistory = () => {
    this.props.history.push('valuation/history');
  };

  private resetVehicleState() {
    this.setState({
      ...this.state,
      isValuationOpen: false,
      selectedVehicle: null,
      carStandardOptions: [],
      carOptionalOptions: [],
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        fuelType: null,
        fuelTypeId: null,
        vehicleType: null,
        vehicleTypeId: null,
        mvoDvBattypId: null,
        mvoDvBattyp: null,
        model: null,
        modelId: null,
        transmissionType: null,
        transmissionTypeId: null,
        bodyType: null,
        bodyTypeId: null,
        make: null,
        makeId: null,
        version: '',
        equipmentVersion: '',
        kilometersNr: null,
        power: null,
        horsePower: null,
        engineSize: null,
        co2: null,
        inConditionComments: null,
        outConditionComments: null,
        technicalConditionComments: null,
        makeDate: '',
        engineManufactureCode: '',
        nrOfDoors: null,
        tractionType: null,
        tractionTypeId: null,
        color: null,
        colorId: null,
        paintType: null,
        paintTypeId: null,
        pollutionNorm: null,
        pollutionNormId: null,
        firstRegistrationDate: '',
        valuationVehicleId: null,
        propulsionSystem: ''
      }
    });
  }

  selectedVehicleHandler = async (selectedVehicle: any, optionsCodes: string[]) => {
    if (R.isNil(selectedVehicle)) return;

    const model = this.state.modelTypes.find((m) => m.code === selectedVehicle.model);
    const bodyType = this.state.bodyTypes.find((b) => b.code === selectedVehicle.body);
    const options = await this.caseService.GetValuationVehicleOptions(selectedVehicle.vehicleId);

    const fuelType = this.state.fuelTypes.find(
      (fuelType) => fuelType.name === selectedVehicle.fuelType
    );

    const vehicleType = this.state.vehicleTypes.find(
      (vehicleType) => vehicleType.name === selectedVehicle.vehicleType
    );

    const mvoDvBattyp = this.state.mvoDvBattypList.find(
      (mvoDvBattyp) => mvoDvBattyp.name === selectedVehicle.mvoDvBattyp
    );

    const refurbished = this.state.refurbishedList.find(
      (refurbishedItem) => refurbishedItem.name === selectedVehicle.refurbished
    );

    const transmissionType = this.state.transmissionTypes.find(
      (transmissionType) => transmissionType.code === selectedVehicle.transmission[0]
    );

    const make = this.state.brandTypes.find((make) => make.name! === selectedVehicle.manufacturer);

    const traction = this.state.tractionTypes.find(
      (tractionType) => tractionType.code === selectedVehicle.drivenWheels[0]
    );

    const pollutionNorm = this.state.polutionNormTypes.find(
      (x) => x.code === selectedVehicle.pollution
    );

    this.setSelectedOptions(options.extraOptions, optionsCodes);

    const valLanguageId = Utils.GetValuationLanguageId(this.props.appState.language);

    const carDetailsExtendedData = {
      ...this.state.carDetailsExtendedData,
      tractionType: traction || null,
      tractionTypeId: Utils.GetIdOrNull(traction),
      fuelType: fuelType || null,
      fuelTypeId: Utils.GetIdOrNull(fuelType),
      vehicleType: vehicleType || null,
      vehicleTypeId: Utils.GetIdOrNull(vehicleType),
      refurbishedId: Utils.GetIdOrNull(refurbished),
      refurbished: refurbished || null,
      mvoDvBattypId: Utils.GetIdOrNull(mvoDvBattyp),
      mvoDvBattyp: mvoDvBattyp || null,
      model: model || null,
      modelId: Utils.GetIdOrNull(model),
      transmissionType: transmissionType || null,
      transmissionTypeId: Utils.GetIdOrNull(transmissionType),
      make: make || null,
      makeId: Utils.GetIdOrNull(make),
      version: selectedVehicle.version || null,
      equipmentVersion: selectedVehicle.trimLevel !== undefined ? selectedVehicle.trimLevel : null,
      horsePower: selectedVehicle.powerHP || null,
      power: selectedVehicle.kw || null,
      engineSize: selectedVehicle.cc ?? 0,
      co2: selectedVehicle.co2 || 0,
      makeDate: selectedVehicle.fabricationDate,
      engineManufactureCode: selectedVehicle.engineManufactureCode ?? '',
      nrOfDoors: selectedVehicle.noOfDoors,
      bodyType: bodyType || null,
      bodyTypeId: Utils.GetIdOrNull(bodyType),
      pollutionNorm: pollutionNorm || null,
      pollutionNormId: Utils.GetIdOrNull(pollutionNorm),
      valuationVehicleId: selectedVehicle.vehicleId || null,
      vinQuery: selectedVehicle.vinQuery || false,
      propulsionSystem: selectedVehicle.propulsionSystem || null,
      imgUrl: selectedVehicle.imgUrl || null
    };
    // Since we need to make the fields that come from Vehicle Info API readonly after they are set
    // use a second state in order to allow the user edit the missing fields
    this.setState({
      carDetailsExtendedData,
      carDetailsFetchedData: {
        ...carDetailsExtendedData
      },
      isValuationOpen: true,
      selectedVehicle: selectedVehicle,
      carStandardOptions: options.standardOptions
        .filter(
          (item) =>
            item.languageId === valLanguageId && !standardOptionsSchemaIds.includes(item.schemaId)
        )
        .map((op) => {
          return {
            id: 0,
            code: '',
            name: op.content,
            isStandard: true,
            vehicleId: this.state.carDetailsExtendedData.id,
            checked: true
          } as VehicleOption;
        }),
      carOptionalOptions: options.extraOptions.map((op) => {
        return {
          id: 0,
          code: op.optionCode,
          name: op.description,
          isStandard: false,
          vehicleId: this.state.carDetailsExtendedData.id,
          checked: op.checked
        } as VehicleOption;
      })
    });

    // added this to have the selected vehicle in the vehicle state
    this.props.vehicleState.vehicle = this.state.carDetailsExtendedData;
  };

  getCarStandardOptionsColumns = () => {
    return [
      {
        field: 'name',
        title: this.translatorService.Tranlate('CAR_DETAILS_CAR_STANDARD_OPTION', 'Nume'),
        searchable: true
      } as Column<any>
    ];
  };

  getCarOptionalOptionsColumns = () => {
    return [
      {
        field: 'name',
        title: this.translatorService.Tranlate('CAR_DETAILS_CAR_OPTIONAL_OPTION', 'Nume'),
        searchable: true
      } as Column<any>,
      {
        field: 'code',
        title: this.translatorService.Tranlate('CAR_DETAILS_CAR_OPTIONAL_CODE', 'Cod'),
        searchable: true
      } as Column<any>
    ];
  };

  loadData = async () => {
    const {
      vehicleMakeRef,
      bodyTypeRef,
      tractionTypeRef,
      transmissionTypeRef,
      fuelTypeRef,
      paintTypeRef,
      modelRef,
      colorRef,
      vehicleTypeRef
    } = RefConstants;

    const [
      vehicleMakeList,
      bodyTypeList,
      tractionTypeList,
      transmissionTypeList,
      fuelTypeList,
      paintTypeList,
      modelTypesList,
      colorTypeList,
      vehicleTypeList
    ] = await Promise.all([
      this.appReferentialService.Get(vehicleMakeRef?.baseUrl),
      this.appReferentialService.Get(bodyTypeRef!.baseUrl),
      this.appReferentialService.Get(tractionTypeRef!.baseUrl),
      this.appReferentialService.Get(transmissionTypeRef!.baseUrl),
      this.appReferentialService.Get(fuelTypeRef!.baseUrl),
      this.appReferentialService.Get(paintTypeRef!.baseUrl),
      this.appReferentialService.Get(modelRef!.baseUrl),
      this.appReferentialService.Get(colorRef!.baseUrl),
      this.appReferentialService.Get(vehicleTypeRef!.baseUrl)
    ]);

    this.setState({
      isLoading: false,
      brandTypes: vehicleMakeList,
      bodyTypes: bodyTypeList,
      tractionTypes: tractionTypeList,
      transmissionTypes: transmissionTypeList,
      fuelTypes: fuelTypeList,
      paintTypes: paintTypeList,
      modelTypes: modelTypesList as IReferentialModel[],
      colorTypes: colorTypeList,
      vehicleTypes: vehicleTypeList
    });
  };

  onChangeFirstRegistrationDate = (date: Date | null) => {
    if (date === null) {
      return;
    }
    const selectedDate = date ? moment(date).format('YYYY-MM-DD') : '';

    this.setState({
      carDetailsExtendedData: {
        ...this.state.carDetailsExtendedData,
        firstRegistrationDate: selectedDate
      }
    });
  };

  handleChassisNumberChange = (e: any) => {
    const vin = e.target.value;

    this.setState(
      (prevState) => ({
        carDetailsExtendedData: {
          ...prevState.carDetailsExtendedData,
          vin: vin
        }
      }),
      () => {
        if (this.state.carDetailsExtendedData.vin !== '') {
          this.setState({ showRequiredVinError: false });
        }

        const isValidVin =
          /^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/.test(
            this.state.carDetailsExtendedData.vin
          );
      }
    );
  };

  handlePlateNumberBlur = async (plateNumber: PlateNumber) => {
    if (!Utils.validatePlateNumber(plateNumber.PlateNumber)) return;

    try {
      const vinRequest = await this.caseService.GetVinByPlateNumberRequest(plateNumber);

      if (vinRequest.success) {
        this.setState({
          carDetailsExtendedData: {
            ...this.state.carDetailsExtendedData,
            vin: vinRequest.vin
          }
        });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } else {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_VIN', 'Eroare la obtinerea VIN'),
          {
            variant: 'error'
          }
        );
      }
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderClientDataForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitVehicleData(e);
        }}
      >
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                id="chasis-code"
                className="m-2 pr-2 mr-2"
                name="chasisCode"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_CHASIS_CODE',
                  'Serie sasiu'
                )}
                value={this.state.carDetailsExtendedData.vin}
                onChange={this.handleChassisNumberChange}
                validators={[
                  'matchRegexp:^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$'
                ]}
                errorMessages={[
                  this.translatorService.Tranlate('VIN_INVALID', 'Numarul VIN nu este valid')
                ]}
                error={this.state.showRequiredVinError ? true : false}
                helperText={
                  this.state.showRequiredVinError
                    ? this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    : ''
                }
                label={this.translatorService.Tranlate('CAR_DETAILS_CHASIS_CODE', 'Serie sasiu')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={this.translatorService.Tranlate(
                          'VIN_QUERY_IDENTIFICATION',
                          'Identificare cu VIN Query'
                        )}
                      >
                        <IconButton
                          component="div"
                          disabled={
                            this.props.vehicleInfoDisabled ||
                            R.isEmpty(this.state.carDetailsExtendedData.vin) ||
                            this.state.showSearchingLoader
                          }
                          onClick={this.getValuationInfoConfirmation}
                        >
                          {!this.state.showSearchingLoader ? (
                            <img
                              className="app-header-logo-img"
                              alt="ClaimBox Logo"
                              src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA8klEQVRIie2W0Q2CMBRFL37eP1dwBVZgBRyBFWQFR8AVWIERcAQdQf7eb03NC6mlUCRiQuL5IS23ve9dSiABYDAkCczNYbDXbuFGs3E7WFr1GOYnHaxusDqJMaFD9D1+8wxIFiQNyZN7k+SDZOuMrebmjVtvzZvmZSAiFwAdgNwRFgD2AOpIkSnJarIDpVZxquPMmY9hE8hDGt8AKj5oN42I3CObXwFYTaXrwgYi0qg4c6KaU72NsdTrICr/FNkNbRU2/06fTRQRsevOTqyTBlCTOdX3iEipCYwbaN6NZ/YJRz2NPdt/k1fn/8GJsvG/CgBPf0RH7J2R1ysAAAAASUVORK5CYII="
                              style={{ height: '24px', width: '24px', boxSizing: 'border-box' }}
                            />
                          ) : (
                            <ScaleLoader color={'var(--primary)'} loading={true} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item md={6} xs={12}>
              <TextValidator
                fullWidth
                id="plate-number"
                className="m-2 pr-3"
                name="plateNumber"
                placeholder={this.translatorService.Tranlate(
                  'CAR_DETAILS_PLATE_NUMBER',
                  'Numar inmatriculare'
                )}
                value={this.state.carDetailsExtendedData.plateNumber}
                onChange={(e: any) => {
                  this.onChange('plateNumber')(e);
                }}
                onBlur={async (e: any) => {
                  const plateNumberObject: PlateNumber = {
                    PlateNumber: e.target.value,
                    externalClient: false
                  };
                  await this.handlePlateNumberBlur(plateNumberObject);
                }}
                error={
                  this.state.carDetailsExtendedData.plateNumber !== '' &&
                  !Utils.validatePlateNumber(this.state.carDetailsExtendedData.plateNumber)
                }
                helperText={
                  this.state.carDetailsExtendedData.plateNumber !== ''
                    ? Utils.validatePlateNumber(this.state.carDetailsExtendedData.plateNumber)
                      ? ''
                      : this.translatorService.Tranlate(
                          'CAR_DETAILS_PLATE_NUMBER_ERROR',
                          'Numarul de inmatriculare nu este valid'
                        )
                    : ''
                }
                validators={['matchRegexp:^[A-Z]{1,2}[0-9]{2,3}[A-Z]{3}$']}
                errorMessages={[
                  this.translatorService.Tranlate(
                    'CAR_DETAILS_PLATE_NUMBER_ERROR',
                    'Numarul de inmatriculare nu este valid'
                  )
                ]}
                label={this.translatorService.Tranlate(
                  'CAR_DETAILS_PLATE_NUMBER',
                  'Numar inmatriculare'
                )}
              />
              <Typography
                variant="caption"
                style={{
                  marginLeft: '7px'
                }}
              >
                {this.translatorService.Tranlate(
                  'CAR_DETAILS_TRY_WITH_PLATE_NUMBER',
                  '*Nu ai VIN-ul la indemana? Incearca cu nr de inmatriculare'
                )}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-2"
                  openTo="date"
                  views={['year', 'month']}
                  variant="inline"
                  format="MM/YYYY"
                  margin="normal"
                  id="firstRegistrationDate"
                  label={this.translatorService.Tranlate(
                    'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION',
                    'Data primei inmatriculari'
                  )}
                  value={this.state.carDetailsExtendedData.firstRegistrationDate || null}
                  onChange={this.handleFirstRegistrationDateChange}
                  autoOk={true}
                  disableFuture={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              marginTop: '15px',
              marginLeft: '5px'
            }}
          >
            <Grid item md={6} xs={12}>
              <Tooltip
                title={this.translatorService.Tranlate(
                  'VALUTION_IGNORE_TIMESTAMP_TOOLTIP_HOVER_TEXT',
                  'Ignora orice reper temporal, spre exemplu data primei inmatriculari sau data fabricatiei'
                )}
              >
                <FormControlLabel
                  style={{
                    marginRight: '30px'
                  }}
                  control={
                    <Switch
                      checked={this.state.ignoreTimeStampSwitch}
                      onChange={() => {
                        this.setState({
                          ignoreTimeStampSwitch: !this.state.ignoreTimeStampSwitch
                        });
                        if (!this.state.ignoreTimeStampSwitch) {
                          this.setState({
                            datePickerFirstRegistrationError: ''
                          });
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'IGNORE_TIME_STAMP_SWITCH',
                    'Ignora orice reper temporal'
                  )}
                />
              </Tooltip>
            </Grid>
            <Grid item md={6} xs={12}>
              <Tooltip
                title={this.translatorService.Tranlate(
                  'VALUATION_IGNORE_EXISTING_MAPPING_TOOLTIP_HOVER_TEXT',
                  'Se ignora maparea existenta a vehiculului din baza de date'
                )}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.ignoreExistingMappingSwitch}
                      onChange={() => {
                        this.setState({
                          ignoreExistingMappingSwitch: !this.state.ignoreExistingMappingSwitch
                        });
                      }}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'IGNORE_EXISTING_MAPPING',
                    'Ignora maparea existenta'
                  )}
                />
              </Tooltip>
            </Grid>
          </Grid>

          <Dialog
            aria-labelledby="customized-dialog-title"
            open={this.state.isValuationOpen}
            maxWidth={false}
            fullWidth={false}
            PaperProps={{
              style: {
                minHeight: '100vh',
                minWidth: '100vw'
              }
            }}
          >
            <DialogTitle
              id="customized-dialog-title"
              style={{
                color: '#ffffff',
                backgroundColor: '#1F2857'
              }}
            >
              <Typography component="div" variant="h4" align="center">
                {this.translatorService.Tranlate('VALUATION_DIALOG_HEADER', 'Selectare vehicul')}
              </Typography>
              <IconButton
                aria-label="close"
                className="position-absolute"
                id="valuation-dialog-close-button"
                style={{ right: 12, top: 12, color: '#ffffff' }}
                onClick={(e) => {
                  this.setState({ isValuationOpen: false, isOpen: false, dialogVisible: false });

                  !R.isNil(this.state.selectedVehicle)
                    ? (this.props.vehicleState.vehicle = null)
                    : null;

                  this.resetVehicleState();
                  this.props.resetVehicleState();
                }}
                size={'small'}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
              <DialogContent
                dividers
                style={{
                  backgroundColor: '#fafafa',
                  borderBottom: 'none'
                }}
              >
                <DetailsValuation
                  {...this.props}
                  vin={this.state.carDetailsExtendedData.vin}
                  firstRegistrationDate={
                    new Date(this.state.carDetailsExtendedData.firstRegistrationDate) || null
                  }
                  onSelectedVehicle={this.selectedVehicleHandler}
                  valuationVehicles={this.state.valuationVehicles}
                  brandTypes={this.state.brandTypes}
                  modelTypes={this.state.modelTypes}
                  bodyTypes={this.state.bodyTypes}
                  optionCodes={this.state.optionCodes}
                  fuelTypes={this.state.fuelTypes}
                  colorTypes={this.state.colorTypes}
                  onChangeFirstRegistrationDate={this.onChangeFirstRegistrationDate}
                />
              </DialogContent>
            </DialogContent>
          </Dialog>
        </div>

        <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
          <Dialog open={this.state.dialogVisible} onClose={this.handleCloseConfirmationDialog}>
            <DialogContent>
              <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {`${this.translatorService.Tranlate(
                  'VIN_IDENTIFICATION_CONFIRMATION_MESSAGE',
                  'Identificarea conform VIN presupune un cost suplimentar - Doriţi să continuaţi ?'
                )} `}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => this.getValuationInfo()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
              </Button>
              <Button onClick={() => this.handleCloseConfirmationDialog()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
              </Button>
            </DialogActions>
          </Dialog>
        </Modal>

        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="button"
              className="mr-3"
              style={{
                marginTop: '25px'
              }}
              endIcon={
                <img
                  src={manualVinIdentification}
                  alt="Manual Vin Identification"
                  style={{ height: '24px', width: '24px', marginLeft: '5px' }}
                />
              }
              onClick={this.getManualValuationInfo}
            >
              {this.translatorService.Tranlate(
                'EVALUATION_MANUAL_IDENTIFICATION_BUTTON_LABEL',
                'Identificare manuala'
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              style={{
                marginTop: '25px'
              }}
              disabled={this.state.showSearchingLoader}
              endIcon={
                <img
                  className="app-header-logo-img"
                  alt="ClaimBox Logo"
                  src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA8klEQVRIie2W0Q2CMBRFL37eP1dwBVZgBRyBFWQFR8AVWIERcAQdQf7eb03NC6mlUCRiQuL5IS23ve9dSiABYDAkCczNYbDXbuFGs3E7WFr1GOYnHaxusDqJMaFD9D1+8wxIFiQNyZN7k+SDZOuMrebmjVtvzZvmZSAiFwAdgNwRFgD2AOpIkSnJarIDpVZxquPMmY9hE8hDGt8AKj5oN42I3CObXwFYTaXrwgYi0qg4c6KaU72NsdTrICr/FNkNbRU2/06fTRQRsevOTqyTBlCTOdX3iEipCYwbaN6NZ/YJRz2NPdt/k1fn/8GJsvG/CgBPf0RH7J2R1ysAAAAASUVORK5CYII="
                  style={{ height: '24px', width: '24px', boxSizing: 'border-box' }}
                />
              }
              onClick={this.getValuationInfoConfirmation}
            >
              {this.translatorService.Tranlate(
                'VIN_QUERY_IDENTIFICATION',
                'Identificare cu VIN Query'
              )}
            </Button>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: '25px'
            }}
            onClick={this.goToValuationHistory}
          >
            {this.translatorService.Tranlate(
              'CAR_DETAILS_HISTORY_BUTTON_LABEL',
              'Istoric evaluari'
            )}
          </Button>
        </Grid>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? <div className="">{this.renderClientDataForm()}</div> : null}
      </Fragment>
    );
  }
}

const ConnectedComponent = connect(
  (
    state: ApplicationState,
    ownProps: ExternalCaseDetailsCaseTabCarDetailsExtendedContainerProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators }
)(DetailsValuationContainer);

export default withRouter(withSnackbar(ConnectedComponent));
