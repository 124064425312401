import React from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { ReferentialCode, VehicleStatusCodeEnum } from '../../helpers/Constants';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { Vehicle, ChangeVehicleStatusRequest } from '../../interfaces/Vehicle';
import TabPanel from '../_shared/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import CaseDetailsCaseTabCarDetailsExtendedContainer from '../cases/vehicleDetails/CaseDetailsCaseTabCarDetailsExtendedContainer';
import VehicleCase from './VehicleCase';
import VehicleHistoryTimeline from './VehicleHistoryTimeline';
import * as R from 'ramda';
import { allowedVehicleStatuses } from '../../helpers/Constants';
import VehicleAttachments from './VehicleAttachments';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { CaseService } from '../../services/CaseService';
import { OrganizationService } from '../../services/OrganizationService';

interface IDetailsOfCarFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isUserHidden: boolean;
  vehicle: Vehicle | null;
  selectedTab: number;
  statuses: IReferential[];
  statusOptions: IReferential[];
  selectedStatus: IReferential | null;
  selectedDisplayedStatus: string;
  isChangeVehicleStatusDialogOpen: boolean;
}

export interface ExternalDetailsOfCarFormProps {
  workflowForm: WorkflowSectionStepForm;
  vehicleDetails: Vehicle;
}

type IDetailsOfCarFormProps = ExternalDetailsOfCarFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
} & typeof CaseSettingsActionCreators &
  typeof VehicleActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string, type?:string }>;

class DetailsOfCarForm extends React.PureComponent<IDetailsOfCarFormProps, IDetailsOfCarFormState> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;
  private caseService!: ICaseService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {
    isLoading: true,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    selectedTab: 0,
    statuses: [] as IReferential[],
    statusOptions: [],
    selectedStatus: null,
    isChangeVehicleStatusDialogOpen: false,
    vehicle: null,
    selectedDisplayedStatus: ''
  } as IDetailsOfCarFormState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadDetailsOfCarForm();
      }
    );
  }

  loadDetailsOfCarForm = async () => {
    const vehicleId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(vehicleId)) {
      return;
    }

    await this.props.setVehicle(this.props.match.params.id as any);
    const vehicle = this.props.vehicleState.vehicle;
    const statusesTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.VehicleStatus
    );

    const statuses = await this.appReferentialService.Get(statusesTypeRef!.baseUrl);

    const currentStatus = !R.isNil(vehicle?.currentStatus)
      ? vehicle?.currentStatus.status || null
      : null;

    this.setState({
      isLoading: false,
      vehicle: vehicle,
      statuses: statuses,
      statusOptions: this.getStatusOptions(currentStatus, statuses),
      selectedStatus: currentStatus,
      selectedDisplayedStatus: !R.isNil(vehicle?.currentStatus?.status)
        ? vehicle?.currentStatus?.status?.displayName || ''
        : ''
    });
  };

  getStatusOptions = (status: IReferential | null, statuses: IReferential[]) => {
    let statusOptions = statuses;
    const currentStatusEnum = (status?.id as unknown as VehicleStatusCodeEnum) || null;

    if (currentStatusEnum && allowedVehicleStatuses.has(currentStatusEnum)) {
      const allowedStatuses = allowedVehicleStatuses.get(currentStatusEnum) || [];
      statusOptions = statuses.filter((status) => {
        const s = status.id as unknown as VehicleStatusCodeEnum;
        return allowedStatuses.includes(s);
      });
    }

    return statusOptions;
  };

  changeVehicleStatusSubmit = async () => {
    try {
      const newStatus = await this.vehicleService.ChangeVehicleStatus({
        vehicleId: this.props.vehicleState.vehicle?.id,
        statusId: this.state.selectedStatus?.id,
        comment: ''
      } as ChangeVehicleStatusRequest);

      newStatus.status = this.state.selectedStatus;
      this.props.changeVehicleStatus(newStatus);

      this.setState({
        selectedDisplayedStatus: this.state.selectedStatus?.displayName || '',
        statusOptions: this.getStatusOptions(this.state.selectedStatus, this.state.statuses)
      });

      this.closeDialog();
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteVehicleStatusChange = (value: any | null) => {
    this.setState({
      selectedStatus: value
    });
  };

  renderChangeVehicleStatusForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.changeVehicleStatusSubmit();
        }}
      >
        <div className="text-center m-0">
          <Autocomplete
            disableClearable
            id="status"
            className="mb-3"
            options={this.state.statusOptions}
            disabled={!this.state.statusOptions.length}
            value={this.state.selectedStatus}
            onChange={(e: any, newValue: any | null) =>
              this.handleAutocompleteVehicleStatusChange(newValue)
            }
            getOptionLabel={(option: any) => option.displayName}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="status"
                value={this.state.selectedStatus}
                label={this.translatorService.Tranlate('DETAILS_OF_CAR_STATUS_LABEL', 'Stare')}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
        </div>
        <Button
          className="mt-3 float-right"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.statusOptions.length}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  changeVehicleStatusClick = () => {
    this.setState({ isChangeVehicleStatusDialogOpen: true });
  };

  closeDialog = async () => {
    this.setState({ isChangeVehicleStatusDialogOpen: false });
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState({
      selectedTab: newValue
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.caseService = (this.context as AppContext).caseService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <div>
        <React.Fragment>
          <Card className="mb-3 p-3">
            <div className="d-flex align-items-center">
              <div>
                {this.state.selectedDisplayedStatus ? (
                  <Button
                    onClick={(e) => this.changeVehicleStatusClick()}
                    color="inherit"
                    className="text-white bg-success mr-2"
                  >
                    {this.state.selectedDisplayedStatus}
                  </Button>
                ) : undefined}
              </div>
              <div style={{ flexBasis: '40%' }}>
                {this.state.vehicle ? this.state.vehicle?.make?.displayName || '' : ''}
                &nbsp;
                {this.state.vehicle ? this.state.vehicle?.model?.displayName || '' : ''}
                &nbsp;
                {this.state.vehicle ? this.state.vehicle?.version || '' : ''}
                &nbsp;
                {this.state.vehicle?.purchaseVatRate === 0 &&
                  `(${this.translatorService.Tranlate(
                    'DETAILS_OF_CAR_NO_VAT',
                    'Vehicul achizitionat cu TVA 0'
                  )})`}
              </div>
              <div style={{ flexBasis: '50%' }}>
                <Button
                  className="ml-2 float-right bg-theme-primary"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.props.history.push('/vehicles');
                  }}
                >
                  {this.translatorService.Tranlate(
                    'DETAILS_OF_CAR_CARS_STOCK_BUTTON',
                    'Stoc masini'
                  )}
                </Button>
              </div>
            </div>
          </Card>
        </React.Fragment>
        <React.Fragment>
          <Card>
            <AppBar position="static" elevation={0} color="secondary">
              <Tabs
                TabIndicatorProps={{
                  style: {
                    height: '4px'
                  }
                }}
                value={this.state.selectedTab}
                onChange={this.onTabChange}
                variant="fullWidth"
                centered={true}
                indicatorColor="primary"
                aria-label="simple tabs example"
              >
                <Tab
                  label={this.translatorService.Tranlate(
                    'DETAILS_OF_CAR_CAR_DETAILS_TAB',
                    'Date masina'
                  )}
                />
                <Tab
                  label={this.translatorService.Tranlate('DETAILS_OF_CAR_CAR_CASE_TAB', 'Dosare')}
                />
                <Tab
                  label={this.translatorService.Tranlate('DETAILS_OF_CAR_CAR_HISTORY', 'Istoric')}
                />
              </Tabs>
            </AppBar>

            <div>
              <TabPanel value={this.state.selectedTab} index={0}>
                <div className="m-3">
                  {!this.state.isLoading ? (
                    // ""
                    <React.Fragment>
                      <CaseDetailsCaseTabCarDetailsExtendedContainer
                        {...this.props}
                        workflowForm={null}
                        type={this.props.match.params?.type}
                        showCostTypes={true}
                        vehicleInfoDisabled={true}
                      />
                      {this.state.vehicle?.id ? (
                        <VehicleAttachments
                          vehicleService={this.vehicleService}
                          caseService={this.caseService}
                          vehicleId={this.state.vehicle.id}
                          translatorService={this.translatorService}
                          organizationService={this.organizationService}
                        />
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </div>
              </TabPanel>
              <TabPanel value={this.state.selectedTab} index={1}>
                <div className="m-3">
                  {!this.state.isLoading ? (
                    <VehicleCase {...this.props} workflowForm={this.props.workflowForm} />
                  ) : null}
                </div>
              </TabPanel>
              <TabPanel value={this.state.selectedTab} index={2}>
                <div className="m-3">
                  {!this.state.isLoading ? (
                    <VehicleHistoryTimeline
                      {...this.props}
                      workflowForm={this.props.workflowForm}
                    />
                  ) : null}
                </div>
              </TabPanel>
            </div>
          </Card>
        </React.Fragment>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isChangeVehicleStatusDialogOpen}
          fullScreen={false}
          disableBackdropClick
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
          >
            <span>
              {this.translatorService.Tranlate(
                'ORDER_DETAILS_DIALOG_TITLE',
                'Modificare stare comanda'
              )}
            </span>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="p-3" dividers style={{ backgroundColor: '#fafafa' }}>
            {this.renderChangeVehicleStatusForm()}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: ExternalDetailsOfCarFormProps) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators }
)(withSnackbar(DetailsOfCarForm as any));
