import { API } from '../../helpers/API';
import { AppointmentRequestPayload, AppointmentsFiltersType, ChangeAppointmentStatusPayload } from '../../types/Agenda';

export const getClientListRequest = async () => {
  try {
    const response = await API.GetAsync('/case/appointmentClients');
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error getting clients list',
      error: true
    };
  }
};

export const getCarListRequest = async () => {
  try {
    const response = await API.GetAsync('/case/appointmentVehicles');
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error getting cars list',
      error: true
    };
  }
};


export const getAppointmentStatus = async () => {
  try {
    const response = await API.GetAsync('/case/Referential/AppointmentStatus');
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error getting status list',
      error: true
    };
  }
};

export const getJobListRequest = async () => {
  try {
    const response = await API.GetAsync('/case/Referential/JobType');
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error getting jobs list',
      error: true
    };
  }
};

export const getAppointmentsRequest = async (payload: AppointmentsFiltersType) => {
  try {
    const response = await API.Post2Async('/case/appointment', payload);
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error getting appointments list',
      error: true
    };
  }
};

export const getAppointment = async (id: number) => {
  try {
    const response = await API.GetAsync(`case/appointment/${id}`);
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error getting appointment',
      error: true
    };
  }
};

export const addAppointment = async (payload: AppointmentRequestPayload) => {
  try {
    const response = await API.Post2Async('/case/new-appointment', payload);
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    console.log(e.response)
    return {
      message: 'Error save appointment',
      error: true
    };
  }
};

export const getAppointmentCategoryType = async () => {
  try {
    const response = await API.GetAsync('/case/Referential/AppointmentCategoryType');
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error get appointment category type',
      error: true
    };
  }
};


export const changeAppointmentStatus = async (payload: ChangeAppointmentStatusPayload) => {
  try {
    const response = await API.Post2Async('/case/appointment/status', payload);
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    console.log(e.response)
    return {
      message: 'Error change appointment status',
      error: true
    };
  }
};

export const newAppointmentCase = async (payload: { appointmentId: number, workflowId: number }) => {
  try {
    const response = await API.Post2Async('/case/new-appointment-case', payload);
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    console.log(e.response)
    return {
      message: 'Error new appointment case',
      error: true
    };
  }
};

export const getAppointmentByCaseId = async (id: number) => {
  try {
    const response = await API.GetAsync(`case/appointmentByCaseId?caseId=${id}`);
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error getting appointment',
      error: true
    };
  }
};

export const getAppointmentFiltersRequest = async () => {
  try {
    const response = await API.Post2Async(`/case/appointment/filters`, {
      dateFilterStartDate: null,
      datefilterEndDate: null
    });
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error getting appointment filters',
      error: true
    };
  }
};

export const setLastOfferRejected = async (caseId: string, lastOfferRejected: boolean) => {
  try {
    const response = await API.PutAsync(`/case/lastOfferRejected/${caseId}?lastOfferRejected=${lastOfferRejected}`, {});
    return {
      message: 'Success',
      error: false,
      data: response.data
    };
  } catch (e) {
    return {
      message: 'Error last offer rejected',
      error: true
    };
  }
};

