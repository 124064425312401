import { ReportParameter } from '../interfaces/Reports';
import { IReportsService } from './Interfaces/IReportsService';

import { API } from '../helpers/API';

export class ReportsService implements IReportsService {
  private baseController = '/case';

  public async GetReport(code: string, parameters: ReportParameter[]): Promise<Blob> {
    return (
      await API.PostFileAsync<ReportParameter[], Blob>(
        `${this.baseController}/report/${code}`,
        parameters
      )
    ).data;
  }

  public async GetInOutReport(
    code: string,
    caseId: number, 
    appointmentId: number,
    parameters: ReportParameter[]): Promise<Blob> {
    return (
      await API.PostFileAsync<ReportParameter[], Blob>(
        `${this.baseController}/${caseId}/${appointmentId}/report/${code}`,
        parameters
      )
    ).data;
  }

  public async GetInvoiceReport(code: string, caseId: number, parameters: ReportParameter[]): Promise<Blob> {
    return (
      await API.PostFileAsync<ReportParameter[], Blob>(
        `${this.baseController}/${caseId}/invoice/${code}`,
        parameters
      )
    ).data;
  }

  public async GetRepairReport(code: string, caseId: number, parameters: ReportParameter[]): Promise<Blob> {
    return (
      await API.PostFileAsync<ReportParameter[], Blob>(
        `${this.baseController}/${caseId}/repair/${code}`,
        parameters
      )
    ).data;
  }
}
