import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Typography, Box } from '@material-ui/core';
import { getCarListRequest } from '../agenda/agenda.requests';
import { Car } from '../agenda/agenda.types';
import { TranslatorService } from '../../services/TranslatorService';

interface CarAutocompleteProps {
  value: Car |null
  onChange: (event: React.ChangeEvent<unknown>, newValue: Car | null) => void;
  error?: boolean
}

const CarAutocomplete: React.FC<CarAutocompleteProps> = ({value,  onChange, error }) => {
  const [cars, setCars] = useState<Car[]>([]);
  const translatorService = new TranslatorService();

  const getCarList = async () => {
    const response = await getCarListRequest();
    const responseData = response.data as Car[];
    const mappedCars: Car[] = responseData?.map((carData) => {
      return {
        vin: carData?.vin,
        plateNumber: carData?.plateNumber,
        numberOfKilometers: carData?.numberOfKilometers,
        firstPlateDate: carData?.firstPlateDate,
        model: {
          name: carData?.model?.name,
          brand: carData?.model?.brand,
          carBody: carData?.model?.carBody,
          description: carData?.model?.description
          // TODO: add other properties
        }
      };
    });

    if (value && value.addManual) {
      setCars([...mappedCars, value])
    } else {
      setCars(mappedCars);
    }
  };

  useEffect(() => {
    if(cars?.length ===0 || value?.addManual){
      getCarList();
    }   
  }, [value]);
  
  return (
    <Box>
      <Typography variant="h5" style={{ marginBottom: '10px', color: error ? '#f83245' : 'black' }}>
        {translatorService.Tranlate('APPOINTMENT_CAR_TITLE', 'Car')}
      </Typography>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <Autocomplete
          id="selected-car"
          options={cars}
          getOptionLabel={(car) => car.plateNumber ?? ''}
          onChange={onChange}
          value={value}
          renderInput={(params) => (
            <TextField {...params} 
            label="Select car" 
            variant="outlined" 
            helperText={error ? translatorService.Tranlate(
                'VALIDATORS_REQUIRED',
                'Campul este obligatoriu'
              ) : ''}
            error={error}
            style={{ width: 300 }} />
          )}
        />
      </Box>
    </Box>
  );
};

export default CarAutocomplete;
