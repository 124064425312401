import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import {
  WorkflowSectionStepForm
} from '../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import 'react-dropdown-tree-select/dist/styles.css';
import { Button } from '@material-ui/core';
import { AppUser } from '../../interfaces/AppUser';
import { ChangeCaseStatusRequest } from '../../interfaces/Case';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { IReferential } from '../../interfaces/IReferential';
import { CaseStatusCodeEnum, ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';

interface ICaseDetailsCaseRepairInProgressFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  caseStatuses: IReferential[];
}

export interface ExternalCaseDetailsCaseRepairInProgressFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseRepairInProgressFormProps =
  ExternalCaseDetailsCaseRepairInProgressFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseRepairInProgressForm extends React.PureComponent<
  ICaseDetailsCaseRepairInProgressFormProps,
  ICaseDetailsCaseRepairInProgressFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    isLoading: false,
    hasRights: false,
    isUserHidden: false,
    executing: false,
    caseStatuses: []
  } as ICaseDetailsCaseRepairInProgressFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const caseId = Number.parseInt(this.props.match.params.id);
    if (Number.isNaN(caseId)) {
      return;
    }

    const refCaseStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseStatus
    );
    
    const caseStatuses = await this.appReferentialService.Get(refCaseStatus!.baseUrl);

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    this.setState(
      {
        isLoading: false,
        caseStatuses: caseStatuses,
        hasRights: hasRights
      }
    );
  };

  repairHasBeenFinished = async (e: any) => {
    try {
      this.setState({ executing: true });

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });

      this.setState({ executing: false });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  updateOffer = async (e: any) => {
    try {
      this.setState({ executing: true });

      const newStatus = this.state.caseStatuses.find(x => x.code === CaseStatusCodeEnum.ACTUALIZEAZA_CALCULATIE.toString());
      const caseStatusRequest = {
        caseId: this.props.caseSettingsState.case!.id,
        statusId: newStatus!.id
      } as ChangeCaseStatusRequest;
      const currentStatus = await this.caseService.ChangeCaseStatus(caseStatusRequest);

      this.props.SetCaseStatus(currentStatus);

      this.setState({ executing: false });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">
            {!this.props.workflowForm.hideFirstButton && (
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                onClick={this.repairHasBeenFinished}
                disabled={!this.state.hasRights || this.state.executing}
              >
                {this.translatorService.Tranlate(this.props.workflowForm.firstButtonLabel, '')}
              </Button>
            )}
            {!this.props.workflowForm.hideSecondButton && (
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                onClick={this.updateOffer}
                disabled={!this.state.hasRights || this.state.executing}
              >
                {this.translatorService.Tranlate(this.props.workflowForm.secondButtonLabel, '')}
              </Button>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseRepairInProgressFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseRepairInProgressForm as any));
