import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
  Divider,
  Button,
  TextField,
  Grid
} from '@material-ui/core';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { AppUser } from '../../../../interfaces/AppUser';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import { withSnackbar, ProviderContext } from 'notistack';
import Referentials from '../../../../helpers/Referentials.json';
import { ReferentialCode, WorkflowFormActionTypeCode } from '../../../../helpers/Constants';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import { ScaleLoader } from 'react-spinners';
import { IAppUserService } from '../../../../services/Interfaces/IAppUserService';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Workflow,
  WorkflowFormAction,
  WorkflowPartner,
  WorkflowPartnerType
} from '../../../../interfaces/Workflow';
import { IWorkflowService } from '../../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined } from 'util';
import { IReferential } from '../../../../interfaces/IReferential';

interface IWorkflowFormActionCaseAllocationState {
  actionAllocationCase: WorkflowFormAction;
  isLoading: boolean;
  partnerList: WorkflowPartner[];
  //selectedPartner: WorkflowPartner | null
  userList: AppUser[];
  //selectedUser: AppUser | null
  //isLastUser: boolean
  disabledControls: boolean[];
  tranStatusActionType: IReferential | null;
  caseStatusList: IReferential[];
  workFlowPartnerTypes: WorkflowPartnerType[];
  roles: IReferential[];
  proprietaryWorkflow: Workflow | null;
}
export interface ExternalWorkflowFormActionCaseAllocationProps {
  workflowFormId: number;
}

type OrganizationFormActionCaseAllocationProps = ExternalWorkflowFormActionCaseAllocationProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class EditOrganizationFormActionCaseAllocation extends React.PureComponent<
  OrganizationFormActionCaseAllocationProps,
  IWorkflowFormActionCaseAllocationState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appUserService!: IAppUserService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    actionAllocationCase: {
      id: 0,
      isLastUser: false,
      workflowPartner: null,
      workflowPartnerType: null,
      caseStatus: null,
      user: null,
      userRole: null,
      userRoleId: null
    } as WorkflowFormAction,
    isLoading: false,
    partnerList: [],
    selectedPartner: null,
    userList: [],
    workFlowPartnerTypes: [],
    caseStatusList: [],
    disabledControls: [false, false, false, false, false],
    tranStatusActionType: null,
    roles: [],
    proprietaryWorkflow: null
  } as IWorkflowFormActionCaseAllocationState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  };

  loadData = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    try {
      const refCaseStatus = Referentials.referential.find(
        (item) => item.code === ReferentialCode.CaseStatus
      );
      const refActionTypes = Referentials.referential.find(
        (item) => item.code === ReferentialCode.ActionType
      );
      const refOrgType = Referentials.referential.find(
        (item) => item.code === ReferentialCode.OrganizationType
      );
      const refRoles = Referentials.referential.find(
        (item) => item.code === ReferentialCode.UserRole
      );

      const [
        data,
        actionTypes,
        workflowPartnerList,
        userList,
        orgTypes,
        workflowPartnerTypes,
        caseStatusList,
        roles,
        currentWorkFlow
      ] = await Promise.all([
        this.workflowService.GetWorkflowSectionStepFormActions(this.props.workflowFormId),
        this.appReferentialService.Get(refActionTypes!.baseUrl),
        this.workflowService.GetWorkflowPartners(workflowId),
        this.appUserService.GetAppUsers(),
        this.appReferentialService.Get(refOrgType!.baseUrl),
        this.workflowService.GetWorkflowOrganizationTypes(workflowId),
        this.appReferentialService.Get(refCaseStatus!.baseUrl),
        this.appReferentialService.Get(refRoles!.baseUrl),
        this.workflowService.GetWorkflow(workflowId)
      ]);
      const tranStatusActionType = actionTypes.find(
        (item) => item.code === WorkflowFormActionTypeCode.CASE_ALLOCATION
      );

      let allocationCase = data.find((item) => item.actionTypeId === tranStatusActionType!.id);

      const partners = await this.organizationService.GetOrganizationsByIds(
        workflowPartnerList.map((item) => item.partnerId)
      );

      workflowPartnerList.forEach((wpItem) => {
        const partner = partners.find((item) => item.id === wpItem.partnerId);
        wpItem.partner = isNullOrUndefined(partner) ? null : partner;

        const currentUserWorkflowPartner = workflowPartnerList.find(
          (x) => x.partnerId === this.props.appState.appUser?.organizationId
        );

        if (!isNullOrUndefined(allocationCase) && allocationCase.workflowPartnerId === wpItem.id) {
          allocationCase!.workflowPartner = currentUserWorkflowPartner || wpItem;
        }
      });

      workflowPartnerTypes.forEach((wpTypeItem) => {
        const orgType = orgTypes.find((item) => item.id === wpTypeItem.organizationTypeId);
        wpTypeItem.organizationType = isNullOrUndefined(orgType) ? null : orgType;

        if (
          !isNullOrUndefined(allocationCase) &&
          allocationCase.workflowPartnerTypeId === wpTypeItem.id
        ) {
          allocationCase!.workflowPartnerType = wpTypeItem;
        }
      });

      if (!isNullOrUndefined(allocationCase) && !isNullOrUndefined(allocationCase.userId)) {
        const user = userList.users.find((item) => item.id === allocationCase!.userId);
        allocationCase!.user = isNullOrUndefined(user) ? null : user;
      }
      if (!isNullOrUndefined(allocationCase) && !isNullOrUndefined(allocationCase.caseStatusId)) {
        const cs = caseStatusList.find((item) => item.id === allocationCase!.caseStatusId);
        allocationCase!.caseStatus = isNullOrUndefined(cs) ? null : cs;
      }
      if (!isNullOrUndefined(allocationCase) && !isNullOrUndefined(allocationCase.userRoleId)) {
        const ur = roles.find((item) => item.id === allocationCase!.userRoleId);
        allocationCase!.userRole = isNullOrUndefined(ur) ? null : ur;
      }

      if (isNullOrUndefined(allocationCase)) {
        allocationCase = this.state.actionAllocationCase;
      }

      if (isNullOrUndefined(allocationCase!.user)) {
        allocationCase!.user = null;
      }

      this.setState({
        actionAllocationCase: allocationCase,
        isLoading: false,
        caseStatusList: caseStatusList,
        partnerList: workflowPartnerList,
        userList: userList.users,
        roles: roles.filter((item) => item.isActive),
        tranStatusActionType: isNullOrUndefined(tranStatusActionType) ? null : tranStatusActionType,
        workFlowPartnerTypes: workflowPartnerTypes,
        disabledControls:
          allocationCase === undefined ||
          (!allocationCase.isLastUser &&
            isNullOrUndefined(allocationCase.workflowPartnerId) &&
            isNullOrUndefined(allocationCase.workflowPartnerTypeId) &&
            isNullOrUndefined(allocationCase.user))
            ? [false, false, false, false, false]
            : [
                false,
                !isNullOrUndefined(allocationCase.workflowPartnerId) &&
                !(
                  allocationCase.sendEmailSameOrganization ||
                  allocationCase.sendEmailProprietaryOrganization
                )
                  ? false
                  : true,
                !isNullOrUndefined(allocationCase.user) ? false : true,
                !isNullOrUndefined(allocationCase.workflowPartnerType) ? false : true,
                !(
                  allocationCase.sendEmailSameOrganization ||
                  allocationCase.sendEmailProprietaryOrganization
                )
                  ? false
                  : true
              ],
        proprietaryWorkflow: currentWorkFlow
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  addOrgFormActionAllocationCase = async () => {
    try {
      const actionAllocationCase = { ...this.state.actionAllocationCase };

      if (actionAllocationCase.id !== 0) {
        //update
        actionAllocationCase.workflowPartner = null;
        actionAllocationCase.workflowPartnerType = null;
        actionAllocationCase.user = null;
        actionAllocationCase.caseStatus = null;
        actionAllocationCase.userRole = null;

        await this.workflowService.UpdateWorkflowSectionStepAction(actionAllocationCase);
      } else {
        //add
        actionAllocationCase.id = 0;
        actionAllocationCase.workflowSectionStepFormId = this.props.workflowFormId;
        actionAllocationCase.actionTypeId = this.state.tranStatusActionType!.id;
        actionAllocationCase.user = null;
        actionAllocationCase.userRole = null;
        actionAllocationCase.caseStatus = null;
        actionAllocationCase.workflowPartner = null;
        actionAllocationCase.workflowPartnerType = null;

        await this.workflowService.AddWorkflowSectionStepFormAction(actionAllocationCase);
      }

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  handleCheckBoxIsLastUserChange = async (checked: boolean) => {
    this.setState({
      actionAllocationCase: {
        ...this.state.actionAllocationCase,
        isLastUser: checked,
        sendEmailSameOrganization: false,
        sendEmailProprietaryOrganization: false,
        workflowPartner: checked === true ? null : this.state.actionAllocationCase.workflowPartner,
        workflowPartnerId:
          checked === true ? null : this.state.actionAllocationCase.workflowPartnerId
      },
      disabledControls:
        checked === true ? [false, true, true, true, true] : [false, false, false, false, false]
    });
  };

  handleCheckBoxSameOrganization = async (checked: boolean) => {
    let currenctUserWorkflowPartner = null;
    if (checked) {
      currenctUserWorkflowPartner = this.state.partnerList.find(
        (x) => x.partnerId === this.props.appState.appUser?.organizationId
      );
    }

    this.setState({
      actionAllocationCase: {
        ...this.state.actionAllocationCase,
        sendEmailSameOrganization: checked,
        sendEmailProprietaryOrganization: false,
        isLastUser: false,
        workflowPartner: currenctUserWorkflowPartner || null,
        workflowPartnerId: currenctUserWorkflowPartner?.id || null
      },
      disabledControls:
        checked === true ? [false, true, true, true, true] : [false, false, false, false, false]
    });
  };

  handleCheckBoxProprietaryOrganization = async (checked: boolean) => {
    let currenctUserWorkflowPartner = null;
    if (checked) {
      currenctUserWorkflowPartner = this.state.partnerList.find(
        (x) => x.partnerId === this.state.proprietaryWorkflow?.organizationOwnerId
      );
    }

    this.setState({
      actionAllocationCase: {
        ...this.state.actionAllocationCase,
        sendEmailProprietaryOrganization: checked,
        sendEmailSameOrganization: false,
        isLastUser: false,
        workflowPartner: currenctUserWorkflowPartner || null,
        workflowPartnerId: currenctUserWorkflowPartner?.id || null
      },
      disabledControls:
        checked === true ? [false, true, true, true, true] : [false, false, false, false, false]
    });
  };

  handleAutocompleteOrgChange = async (newValue: any | null) => {
    this.setState({
      actionAllocationCase: {
        ...this.state.actionAllocationCase,
        workflowPartner: newValue,
        workflowPartnerId: newValue === null ? null : newValue.id,
        userRole: newValue === null ? null : this.state.actionAllocationCase.userRole,
        userRoleId: newValue === null ? null : this.state.actionAllocationCase.userRoleId
      },
      disabledControls:
        newValue !== null ? [true, false, true, true, true] : [false, false, false, false, false]
    });
  };

  handleAutocompleteRoleChange = async (newValue: any | null) => {
    this.setState({
      actionAllocationCase: {
        ...this.state.actionAllocationCase,
        userRole: newValue,
        userRoleId: newValue === null ? null : newValue.id
      } //,
      //disabledControls: newValue !== null ? [true, false, true, true, true] : [false, false, false, false, false]
    });
  };

  handleAutocompleteOrgTypeChange = async (newValue: any | null) => {
    this.setState({
      actionAllocationCase: {
        ...this.state.actionAllocationCase,
        workflowPartnerType: newValue,
        workflowPartnerTypeId: newValue === null ? null : newValue.id
      },
      disabledControls:
        newValue !== null ? [true, true, true, false, true] : [false, false, false, false]
    });
  };

  handleAutocompleteCaseStatusChange = async (newValue: any | null) => {
    this.setState({
      actionAllocationCase: {
        ...this.state.actionAllocationCase,
        caseStatus: newValue,
        caseStatusId: newValue === null ? null : newValue.id
      },
      disabledControls:
        newValue !== null ? [true, true, true, true, false] : [false, false, false, false, false]
    });
  };

  handleAutocompleteUserChange = async (newValue: AppUser | null) => {
    this.setState({
      actionAllocationCase: {
        ...this.state.actionAllocationCase,
        user: newValue,
        userId: newValue === null ? null : newValue.id,
        userHoId: newValue === null ? null : newValue.hoId,
        userOrganizationId: newValue === null ? null : newValue.organizationId
      },
      disabledControls:
        newValue !== null ? [true, true, false, true, true] : [false, false, false, false, false]
    });
  };
  renderForm = () => {
    return (
      <ValidatorForm onSubmit={this.addOrgFormActionAllocationCase}>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.actionAllocationCase.isLastUser}
              onChange={(e) => this.handleCheckBoxIsLastUserChange(e.target.checked)}
              disabled={this.state.disabledControls[0]}
            ></Checkbox>
          }
          label={this.translatorService.Tranlate(
            'EDIT_ORGANIZATION_FORM_ACTION_ALLOCATION_CASE_IS_LAST_USER_LABEL',
            'Utilizatorul precedent'
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.actionAllocationCase.sendEmailSameOrganization}
              onChange={(e) => this.handleCheckBoxSameOrganization(e.target.checked)}
            ></Checkbox>
          }
          label={this.translatorService.Tranlate(
            'EDIT_ORGANIZATION_FORM_ACTION_ALLOCATION_CASE_IS_LAST_USER_LABEL2',
            'Aceeasi organizatie'
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.actionAllocationCase.sendEmailProprietaryOrganization}
              onChange={(e) => this.handleCheckBoxProprietaryOrganization(e.target.checked)}
            ></Checkbox>
          }
          label={this.translatorService.Tranlate(
            'EDIT_ORGANIZATION_FORM_ACTION_ALLOCATION_CASE_IS_LAST_USER_LABEL3',
            'Organizatia proprietara flux'
          )}
        />
        <Divider />
        <Grid container>
          <Grid item xs={6}>
            <Autocomplete
              id="organization"
              className="m-2"
              disabled={this.state.disabledControls[1]}
              options={this.state.partnerList}
              value={this.state.actionAllocationCase.workflowPartner}
              onChange={(e: any, newValue: WorkflowPartner | null) =>
                this.handleAutocompleteOrgChange(newValue)
              }
              getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName}
              filterOptions={createFilterOptions({
                matchFrom: 'any',
                stringify: (option: WorkflowPartner) => option.partner!.displayName
              })}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="organization"
                  value={this.state.actionAllocationCase.workflowPartner}
                  label={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_ACTION_ALLOCATION_CASE_ORGANIZATION_LABEL',
                    'Organizatie'
                  )}
                  fullWidth
                  //   validators={['required']}
                  //   errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu")]}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="role"
              className="m-2"
              options={this.state.roles}
              disabled={
                this.state.disabledControls[1] &&
                !(
                  this.state.actionAllocationCase.sendEmailSameOrganization ||
                  this.state.actionAllocationCase.sendEmailProprietaryOrganization
                )
              }
              value={
                this.state.actionAllocationCase.userRole === undefined
                  ? null
                  : this.state.actionAllocationCase.userRole
              }
              onChange={(e: any, newValue: IReferential | null) =>
                this.handleAutocompleteRoleChange(newValue)
              }
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="role"
                  value={
                    this.state.actionAllocationCase.userRole === undefined
                      ? null
                      : this.state.actionAllocationCase.userRole
                  }
                  label={this.translatorService.Tranlate('EDIT_USER_ROLE_FIELD', 'Rol')}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Divider />

        <Autocomplete
          id="user"
          className="m-2"
          options={this.state.userList}
          disabled={this.state.disabledControls[2]}
          value={this.state.actionAllocationCase.user}
          onChange={(e: any, newValue: AppUser | null) =>
            this.handleAutocompleteUserChange(newValue)
          }
          getOptionLabel={(option: AppUser) => option.userName}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="user"
              value={this.state.actionAllocationCase.user}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_FORM_ACTION_ALLOCATION_CASE_USER_LABEL',
                'Utilizator'
              )}
              fullWidth
              //   validators={['required']}
              //   errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu")]}
            />
          )}
        />
        <Divider></Divider>
        <Autocomplete
          id="organizationType"
          className="m-2"
          disabled={this.state.disabledControls[3]}
          options={this.state.workFlowPartnerTypes}
          value={this.state.actionAllocationCase.workflowPartnerType}
          onChange={(e: any, newValue: WorkflowPartnerType | null) =>
            this.handleAutocompleteOrgTypeChange(newValue)
          }
          getOptionLabel={(option: WorkflowPartnerType) => option.organizationType!.displayName}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="organizationType"
              value={this.state.actionAllocationCase.workflowPartnerType}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_FORM_ACTION_ALLOCATION_CASE_ORGANIZATION_TYPE_LABEL',
                'Tip Organizatie'
              )}
              fullWidth
              //   validators={['required']}
              //   errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu")]}
            />
          )}
        />

        <Divider></Divider>
        <Autocomplete
          id="caseStatus"
          className="m-2"
          disabled={this.state.disabledControls[4]}
          options={this.state.caseStatusList}
          value={this.state.actionAllocationCase.caseStatus}
          onChange={(e: any, newValue: IReferential | null) =>
            this.handleAutocompleteCaseStatusChange(newValue)
          }
          getOptionLabel={(option: IReferential) => option.displayName}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="caseStatus"
              value={this.state.actionAllocationCase.caseStatus}
              label={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_FORM_ACTION_ALLOCATION_CASE_STATUS_LABEL',
                'Utilizatorul care a modificat starea'
              )}
              fullWidth
              //   validators={['required']}
              //   errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu")]}
            />
          )}
        />

        <div className="text-right">
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
          </Button>
        </div>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_ACTION_CASE_ALLOCATION_TAB',
                    'Alocare Dosar'
                  )}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <Box mt={1} pt={1}>
          <Card>
            <CardContent>
              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
              </div>
              {!this.state.isLoading ? this.renderForm() : null}
            </CardContent>
          </Card>
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalWorkflowFormActionCaseAllocationProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(EditOrganizationFormActionCaseAllocation as any));
