import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { withSnackbar, ProviderContext } from 'notistack';
import { Order, OrderFilters, OrderStatusHistory } from '../../interfaces/Order';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Organization } from '../../interfaces/Organization';
import CloseIcon from '@material-ui/icons/Close';
import OrderDetails from './OrderDetails';
import { AppUser } from '../../interfaces/AppUser';
import { IReferential } from '../../interfaces/IReferential';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ro';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Referentials from '../../helpers/Referentials.json';
import { OrderStatusCode, OwnStockCodeEnum, ReferentialCode } from '../../helpers/Constants';
import * as R from 'ramda';
import _ from 'lodash';
import { Utils } from '../../helpers/Utils';
import { TariffPercentResponse } from '../../interfaces/Case';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

type OrdersProps = { 
  appState: AppState;   
  caseSettingsState: CaseSettingsState;
} & ProviderContext & RouteComponentProps;

interface IOrdersState {
  orderList: Order[];
  suppliers: Organization[];
  selectedSuppliers: Organization[];
  clients: Organization[];
  selectedClients: Organization[];
  madeByUsersList: AppUser[];
  selectedMadeByUsersList: AppUser[];
  locations: Organization[];
  selectedLocations: Organization[];
  orderStatusList: IReferential[];
  selectedOrderStatus: IReferential[];
  isLoading: boolean;
  isSupplier: boolean;
  isDialogOpen: boolean;
  selectedDateFrom: Date;
  selectedDateTo: Date;
  orderIds: number[];
  selectedOrder: Order | null;
  count: number;
  page: number;
  pageSize: number;
  plateNumberSearchText: string;
  descriptionSearchText: string;
  supplierOrderNumberSearchText: string;
}

class Orders extends React.PureComponent<OrdersProps, IOrdersState> {
  private translatorService!: ITranslatorService;
  private orderService!: IOrderService;
  private appReferentialService!: IReferentialService;
  private appUserService!: IAppUserService;
  static contextType = ApplicationContext;
  private organizationService!: IOrganizationService;

  state = {
    orderList: [],
    clients: [],
    suppliers: [],
    selectedClients: [],
    selectedSuppliers: [],
    madeByUsersList: [],
    selectedMadeByUsersList: [],
    locations: [],
    selectedLocations: [],
    orderStatusList: [],
    selectedOrderStatus: [],
    isLoading: true,
    isDialogOpen: false,
    selectedDateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    selectedDateTo: new Date(),
    orderIds: [],
    selectedOrder: null,
    count: 1,
    page: 0,
    pageSize: 10,
    isSupplier: false,
    plateNumberSearchText: '',
    descriptionSearchText: '',
    supplierOrderNumberSearchText: ''
  } as IOrdersState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrders();
      }
    );
  }

  loadOrders = async () => {
    const organizationId = this.props.appState.appUser?.organizationId;
    const filters = {
      clientIds: this.state.selectedClients.map((item) => item.id),
      supplierIds: this.state.selectedSuppliers.map((item) => item.id),
      locationIds: this.state.selectedLocations.map((item) => item.id),
      statusIds: this.state.selectedOrderStatus.map((item) => item.id),
      madeByList: this.state.selectedMadeByUsersList.map((item) => item.id),
      dateFrom: new Date(this.state.selectedDateFrom.setHours(0, 0, 0, 0)).toISOString(),
      dateTo: new Date(this.state.selectedDateTo.setHours(23, 59, 59, 999)).toISOString(),
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      plateNumberSearchText: this.state.plateNumberSearchText.replace(/\s+/g, ''),
      descriptionSearchText: this.state.descriptionSearchText,
      supplierOrderNumberSearchText: this.state.supplierOrderNumberSearchText
    } as OrderFilters;

    const orderList = await this.orderService.GetOrders(filters);

    const orderDetailsList = orderList.orders;

    const refOrderStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrderStatus
    );

    const [users, clients, suppliers, orderStatus] = await Promise.all([
      this.appUserService.GetUsersInfo(
        Array.from(new Set(orderDetailsList.map((item) => item.createdBy)))
      ),
      this.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(orderDetailsList.map((item) => item.clientId)))
      ),
      this.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(orderDetailsList.map((item) => item.supplierId)))
      ),
      this.appReferentialService.Get(refOrderStatus!.baseUrl)
    ]);

    orderDetailsList.forEach((od) => {
      const user = users.find((item) => item.id === od.createdBy);
      const client = clients.find((item) => item.id === od.clientId);
      const supplier = suppliers.find((item) => item.id == od.supplierId);

      od.createdByUser = R.isNil(user) ? null : user;
      od.client = R.isNil(client) ? null : client;
      od.supplier = R.isNil(supplier) ? null : supplier;
    });
    const checkIfIsSupplierAcoount = _.find(suppliers, function (object) {
      return object.id == organizationId;
    });
    this.setState(
      {
        orderList: orderDetailsList,
        orderStatusList: orderStatus,
        isLoading: false,
        page: orderList.page - 1,
        count: orderList.total,
        pageSize: this.state.pageSize,
        isSupplier: checkIfIsSupplierAcoount ? true : false
      },
      async () => {
        await this.reloadFilters();
      }
    );
  };

  reloadFilters = async () => {
    const filters = await this.orderService.GetOrderFilters({
      clientIds: [],
      supplierIds: [],
      locationIds: [],
      statusIds: [],
      madeByList: [],
      dateFrom: new Date(this.state.selectedDateFrom.setHours(0, 0, 0, 0)).toISOString(),
      dateTo: new Date(this.state.selectedDateTo.setHours(23, 59, 59, 999)).toISOString(),
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      plateNumberSearchText: this.state.plateNumberSearchText,
      descriptionSearchText: this.state.descriptionSearchText,
      supplierOrderNumberSearchText: this.state.supplierOrderNumberSearchText
    } as any as OrderFilters);

    const refOrderStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrderStatus
    );

    const [users, orgEntities, suppliers, orderStatuses] = await Promise.all([
      this.appUserService.GetUsersInfo(filters.createdByList),
      filters.clientIds.length == 0
        ? []
        : this.organizationService.GetUserOrganizationsByIds(filters.clientIds),
      filters.supplierIds.length == 0
        ? []
        : this.organizationService.GetUserOrganizationsByIds(filters.supplierIds),
      this.appReferentialService.Get(refOrderStatus!.baseUrl)
    ]);

    const orderListStatusIds = filters.orderStatuses.map(({ id }) => id);
    const orderStatus = orderStatuses.filter((item) => orderListStatusIds.includes(item.id));

    this.setState({
      clients: orgEntities,
      madeByUsersList: users,
      orderStatusList: orderStatus,
      suppliers: suppliers
    });
  };

  reloadOrders = async (page: number, rowsPerPage: number) => {
    this.setState({ isLoading: true, selectedOrder: null });

    const filters = {
      clientIds: this.state.selectedClients.map((item) => item.id),
      supplierIds: this.state.selectedSuppliers.map((item) => item.id),
      locationIds: this.state.selectedLocations.map((item) => item.id),
      statusIds: this.state.selectedOrderStatus.map((item) => item.id),
      madeByList: this.state.selectedMadeByUsersList.map((item) => item.id),
      dateFrom: new Date(this.state.selectedDateFrom.setHours(0, 0, 0, 0)).toISOString(),
      dateTo: new Date(this.state.selectedDateTo.setHours(23, 59, 59, 999)).toISOString(),
      page: page + 1,
      pageSize: rowsPerPage,
      plateNumberSearchText: this.state.plateNumberSearchText.replace(/\s+/g, ''),
      descriptionSearchText: this.state.descriptionSearchText,
      supplierOrderNumberSearchText: this.state.supplierOrderNumberSearchText
    } as OrderFilters;

    const orderList = await this.orderService.GetOrders(filters);

    const orderDetailsList = orderList.orders;

    orderDetailsList.forEach((od) => {
      const client = this.state.clients.find((item) => item.id === od.clientId);
      const user = this.state.madeByUsersList.find((item) => item.id === od.createdBy);
      const supplier = this.state.suppliers.find((item) => item.id == od.supplierId);

      od.createdByUser = R.isNil(user) ? null : user;
      od.client = R.isNil(client) ? null : client;
      od.supplier = R.isNil(supplier) ? null : supplier;
    });

    this.setState({
      orderList: orderDetailsList,
      isLoading: false,
      page: orderList.page - 1,
      pageSize: rowsPerPage,
      count: orderList.total
    });
  };

  onRowClick = async (e: any, id: number) => {
    const orderParts = await this.orderService.GetOrderParts(id);
    const order = this.state.orderList.find((item) => item.id === id);
    order!.orderParts = orderParts;
    this.props.caseSettingsState.case = null;

    this.setState({
      isDialogOpen: true,
      selectedOrder: order!
    });
  };

  getColumns = () => {
    let columns = [];
    if (this.state.isSupplier) {
      columns = [
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              return (
                <Button
                  color="primary"
                  className=" text-primary"
                  onClick={(e) => this.onRowClick(e, tableMeta.rowData[0])}
                >
                  <span>{value}</span>
                </Button>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'orderStatusHistories[0].date',
          label: this.translatorService.Tranlate('ORDER_DATE', 'Data Plasare Comanda'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              const orderStatusHistory = order!.orderStatusHistories[0];
              return (
                <div>
                  {moment
                    .utc(orderStatusHistory!.date)
                    .local()
                    .format(this.props.appState.longDateFormat)}
                </div>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'caseVehicle.plateNumber',
          label: this.translatorService.Tranlate(
            'ORDER_VEHICLES_VEHICLE_PLATE_NUMBER',
            'Nr. de inmatriculare'
          ),
          options: {
            sort: false,
            filter: false
          } as MUIDataTableColumnOptions
        },
        {
          name: 'caseVehicle.vin',
          label: 'Vin',
          options: {
            sort: false,
            filter: false
          } as MUIDataTableColumnOptions
        },
        {
          name: 'client.displayName',
          label: this.translatorService.Tranlate('ORDERS_CLIENT_LABEL', 'Client'),
          options: {
            sort: false,
            filter: false
          } as MUIDataTableColumnOptions
        },
        {
          name: 'amount',
          label: this.translatorService.Tranlate('ORDERS_VALUE_LABEL', 'Valoare'),
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }),
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return Utils.countryNumberFormat(value.toString(), this.props.appState.appUser?.organization?.country ?? null);
            }
          }
        },
        {
          name: 'currencyCode',
          label: this.translatorService.Tranlate('ORDERS_CURRENCY_LABEL', 'Moneda'),
          options: {
            sort: false,
            filter: false
          }
        },
        // {
        //     name: "location",
        //     label: this.translatorService.Tranlate("ORDERS_LOCATION_LABEL", "Locatie"),
        //     options: {
        //         filter: false
        //     }
        // },
        {
          name: 'createdByUser.userName',
          label: this.translatorService.Tranlate('ORDERS_MADE_BY_LABEL', 'Efectuat De'),
          options: {
            sort: false,
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              return (
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <div className="font-size-sm">{value}</div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="font-size-sm">
                      {moment
                        .utc(order!.date)
                        .local()
                        .format(this.props.appState.longDateFormat)}
                    </div>
                  </Grid>
                </Grid>
              );
            }
          }
        },
        {
          name: 'orderStatus.displayName',
          label: this.translatorService.Tranlate('ORDERS_STATUS_LABEL', 'Stare'),
          options: {
            sort: false,
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              const orderStatusHistory = order!.orderStatusHistories;
              return orderStatusHistory.map((orderStatusHistory: OrderStatusHistory, index) => {
                return <div key={index}>{orderStatusHistory.orderStatus?.displayName}</div>;
              });
            }
          }
        },
        {
          name: 'supplierOrderNumber',
          label: this.translatorService.Tranlate('ORDERS_ID_FURNIZOR_LABEL', 'Nr. Comanda'),
          options: {
            sort: false,
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              const orderStatusHistory = order!.orderStatusHistories;
              return orderStatusHistory.map((orderStatusHistory: OrderStatusHistory, index) => {
                return (
                  <div className="text-center" key={index}>
                    {orderStatusHistory.orderStatus?.code == OrderStatusCode.FZR_COMANDA_CONFIRMATA
                      ? order?.supplierOrderNumber
                      : '-'}
                  </div>
                );
              });
            }
          }
        }
      ];
    } else {
      columns = [
        {
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              return (
                <Button
                  color="primary"
                  className=" text-primary"
                  onClick={(e) => this.onRowClick(e, tableMeta.rowData[0])}
                >
                  <span>{value}</span>
                </Button>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'orderStatusHistories[0].date',
          label: this.translatorService.Tranlate('ORDER_DATE', 'Data Plasare Comanda'),
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              const orderStatusHistory = order!.orderStatusHistories[0];
              return (
                <div>
                  {moment
                    .utc(orderStatusHistory!.date)
                    .local()
                    .format(this.props.appState.longDateFormat)}
                </div>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'caseVehicle.plateNumber',
          label: this.translatorService.Tranlate(
            'ORDER_VEHICLES_VEHICLE_PLATE_NUMBER',
            'Nr. de inmatriculare'
          ),
          options: {
            sort: false,
            filter: false
          } as MUIDataTableColumnOptions
        },
        {
          name: 'caseVehicle.vin',
          label: 'Vin',
          options: {
            sort: false,
            filter: false
          } as MUIDataTableColumnOptions
        },
        {
          name: 'supplier.name',
          label: this.translatorService.Tranlate('ORDER_PART_DETAILS_SUPPLIER_LABEL', 'Furnizor'),
          options: {
            filter: false,
            customBodyRender: (value: IReferential, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              if (R.isNil(order)) {
                return '';
              }
  
              return !R.isNil(order.supplier) ? (order.supplier!.name.toUpperCase() == OwnStockCodeEnum.OWN_STOCK.toString() ? this.translatorService.Tranlate('CALCULATION_DETAILS_OWN_STOCK_HEADER', 'Stoc Propriu') : order.supplier!.name) : '';
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'amount',
          label: this.translatorService.Tranlate('ORDERS_VALUE_LABEL', 'Valoare'),
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }),
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return Utils.countryNumberFormat(value.toString(), this.props.appState.appUser?.organization?.country ?? null);
            }
          }
        },
        {
          name: 'currencyCode',
          label: this.translatorService.Tranlate('ORDERS_CURRENCY_LABEL', 'Moneda'),
          options: {
            sort: false,
            filter: false
          }
        },
        // {
        //     name: "location",
        //     label: this.translatorService.Tranlate("ORDERS_LOCATION_LABEL", "Locatie"),
        //     options: {
        //         filter: false
        //     }
        // },
        {
          name: 'createdByUser.userName',
          label: this.translatorService.Tranlate('ORDERS_MADE_BY_LABEL', 'Efectuat De'),
          options: {
            sort: false,
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              return (
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <div className="font-size-sm">{value}</div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="font-size-sm">
                      {moment
                        .utc(order!.date)
                        .local()
                        .format(this.props.appState.longDateFormat)}
                    </div>
                  </Grid>
                </Grid>
              );
            }
          }
        },
        {
          name: 'orderStatus.displayName',
          label: this.translatorService.Tranlate('ORDERS_STATUS_LABEL', 'Stare'),
          options: {
            sort: false,
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              const orderStatusHistory = order!.orderStatusHistories;
              return orderStatusHistory.map((orderStatusHistory: OrderStatusHistory, index) => {
                return <div key={index}>{orderStatusHistory.orderStatus?.displayName}</div>;
              });
            }
          }
        },
        {
          name: 'supplierOrderNumber',
          label: this.translatorService.Tranlate('ORDERS_ID_FURNIZOR_LABEL', 'Nr. Comanda'),
          options: {
            sort: false,
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
              const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
              const orderStatusHistory = order!.orderStatusHistories;
              return orderStatusHistory.map((orderStatusHistory: OrderStatusHistory, index) => {
                return (
                  <div className="text-center" key={index}>
                    {orderStatusHistory.orderStatus?.code == OrderStatusCode.FZR_COMANDA_CONFIRMATA
                      ? order?.supplierOrderNumber
                      : '-'}
                  </div>
                );
              });
            }
          }
        }
      ];
    }

    return columns;
  };

  handleDateChangeFrom = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        selectedDateFrom: date!.toDate(),
        isLoading: false
      },
      async () => {
        await this.loadOrders();
      }
    );
  };

  handleDateChangeTo = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState(
      {
        selectedDateTo: date!.toDate(),
        isLoading: false
      },
      async () => {
        await this.loadOrders();
      }
    );
  };

  handleClientsChange = (newValue: any[]) => {
    this.setState(
      {
        selectedClients: newValue,
        isLoading: true
      },
      async () => await this.reloadOrders(this.state.page, this.state.pageSize)
    );
  };

  handleSuppliersChange = (newValue: any[]) => {
    this.setState(
      {
        selectedSuppliers: newValue,
        isLoading: true
      },
      async () => await this.reloadOrders(this.state.page, this.state.pageSize)
    );
  };

  handleMadeByUsersChange = (newValue: any[]) => {
    this.setState(
      {
        selectedMadeByUsersList: newValue,
        isLoading: true
      },
      async () => await this.reloadOrders(this.state.page, this.state.pageSize)
    );
  };

  handleLocationsChange = (newValue: any[]) => {
    this.setState(
      {
        selectedLocations: newValue,
        isLoading: true
      },
      async () => await this.reloadOrders(this.state.page, this.state.pageSize)
    );
  };

  handleStatusesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedOrderStatus: newValue,
        isLoading: true
      },
      async () => await this.reloadOrders(this.state.page, this.state.pageSize)
    );
  };

  handlePlateNumberChange = (newValue: string) => {
    this.setState({
      plateNumberSearchText: newValue
    });
  };

  handleDescriptionChange = (newValue: string) => {
    this.setState({
      descriptionSearchText: newValue
    });
  };

  handleSupplierOrderNumberChange = (newValue: string) => {
    this.setState({
      supplierOrderNumberSearchText: newValue
    });
  };

  renderFilters = () => {
    return (
      <div className="mt-3">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                style={{ width: '50%' }}
                className="m-0 pt-1 pl-2 pr-2 pb-0"
                disableToolbar
                variant="inline"
                format={this.props.appState.dateFormat.toUpperCase()}
                margin="normal"
                id="date-picker-inline-from"
                label={this.translatorService.Tranlate('ORDERS_DATE_LABEL_FROM', 'De la')}
                InputLabelProps={{
                  style: { paddingLeft: '12px' }
                }}
                value={this.state.selectedDateFrom}
                onChange={this.handleDateChangeFrom}
                autoOk={true}
                // minDate={activeButton === 1 ? new Date() : this.state.minDate}
              />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                style={{ width: '50%' }}
                className="m-0 pt-1 pl-2 pr-2 pb-0"
                disableToolbar
                variant="inline"
                format={this.props.appState.dateFormat.toUpperCase()}
                margin="normal"
                id="date-picker-inline-to"
                label={this.translatorService.Tranlate('ORDERS_DATE_LABEL_TO', 'Pana la')}
                InputLabelProps={{
                  style: { paddingLeft: '12px' }
                }}
                value={this.state.selectedDateTo}
                onChange={this.handleDateChangeTo}
                autoOk={true}
                // minDate={activeButton === 1 ? new Date() : this.state.minDate}
              />
            </MuiPickersUtilsProvider>
            <Autocomplete
              id="madeByUsers"
              size="small"
              className="m-2"
              multiple
              options={this.state.madeByUsersList.sort(function (a, b) {
                return a.userName.localeCompare(b.userName);
              })}
              value={this.state.selectedMadeByUsersList}
              onChange={(e: any, newValue: any | null) => this.handleMadeByUsersChange(newValue)}
              getOptionLabel={(option: AppUser) => option.userName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="madeByUsers"
                  value={this.state.selectedMadeByUsersList}
                  label={this.translatorService.Tranlate('ORDERS_MADE_BY_LABEL', 'Efectuat de')}
                  fullWidth
                />
              )}
            />
            <div className="m-2">
              <TextField
                fullWidth
                id="plateNumber"
                label={this.translatorService.Tranlate(
                  'ORDER_VEHICLES_VEHICLE_PLATE_NUMBER',
                  'Nr. inmatriculare'
                )}
                value={this.state.plateNumberSearchText}
                onChange={(e: any) => this.handlePlateNumberChange(e.target.value)}
                onBlur={(e: any) => this.reloadOrders(this.state.page, this.state.pageSize)}
              />
            </div>
            <div className="m-2">
              <TextField
                fullWidth
                id="nr-comanda"
                label={this.translatorService.Tranlate('ORDERS_ID_FURNIZOR_LABEL', 'Nr. Comanda')}
                value={this.state.supplierOrderNumberSearchText}
                onChange={(e: any) => this.handleSupplierOrderNumberChange(e.target.value)}
                onBlur={(e: any) => this.reloadOrders(this.state.page, this.state.pageSize)}
              />
            </div>
            {/* <Autocomplete
                            id="locations"
                            size="small"
                            className="m-2"
                            multiple
                            options={this.state.locations.sort(function (a, b) { return a.displayName.localeCompare(b.displayName) })}
                            value={this.state.selectedLocations}
                            onChange={(e: any, newValue: any | null) => this.handleLocationsChange(newValue)}
                            getOptionLabel={(option: Organization) => option.displayName || ""}
                            renderInput={params => (
                                <TextField {...params}
                                    name="clients"
                                    value={this.state.selectedLocations}
                                    label={this.translatorService.Tranlate("ORDERS_LOCATION_LABEL", "Locatie")}
                                    fullWidth
                                />
                            )}
                        /> */}
          </Grid>
          <Grid item xs={6}>
            {this.state.isSupplier ? (
              <Autocomplete
                id="clients"
                size="small"
                className="m-2"
                multiple
                options={this.state.clients.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedClients}
                onChange={(e: any, newValue: any | null) => this.handleClientsChange(newValue)}
                getOptionLabel={(option: Organization) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="clients"
                    value={this.state.selectedClients}
                    label={this.translatorService.Tranlate('ORDERS_CLIENT_LABEL', 'Client')}
                    fullWidth
                  />
                )}
              />
            ) : (
              <Autocomplete
                id="suppliers"
                size="small"
                className="m-2"
                multiple
                options={this.state.suppliers.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedSuppliers}
                onChange={(e: any, newValue: any | null) => this.handleSuppliersChange(newValue)}
                getOptionLabel={(option: Organization) => option.name.toUpperCase() == OwnStockCodeEnum.OWN_STOCK.toString() ? this.translatorService.Tranlate('CALCULATION_DETAILS_OWN_STOCK_HEADER', 'Stoc Propriu') : option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="suppliers"
                    value={this.state.selectedSuppliers}
                    label={this.translatorService.Tranlate(
                      'ORDER_PART_DETAILS_SUPPLIER_LABEL',
                      'Furnizor'
                    )}
                    fullWidth
                  />
                )}
              />
            )}
            <Autocomplete
              id="statuses"
              size="small"
              className="m-2"
              multiple
              options={this.state.orderStatusList}
              value={this.state.selectedOrderStatus}
              onChange={(e: any, newValue: any | null) => this.handleStatusesChange(newValue)}
              getOptionLabel={(option: any) => option.displayName || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="statuses"
                  value={this.state.selectedOrderStatus}
                  label={this.translatorService.Tranlate('ORDERS_STATUS_LABEL', 'Stare')}
                  fullWidth
                />
              )}
            />
            <div className="m-2">
              <TextField
                fullWidth
                id="name"
                label={this.translatorService.Tranlate('ORDER_PART_DETAILS_NAME_LABEL', 'Denumire')}
                value={this.state.descriptionSearchText}
                onChange={(e: any) => this.handleDescriptionChange(e.target.value)}
                onBlur={(e: any) => this.reloadOrders(this.state.page, this.state.pageSize)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  closeDialog = async () => {
    this.setState(
      {
        isDialogOpen: false,
        isLoading: true
      },
      async () => {
        await this.loadOrders();
      }
    );
  };

  onOrderChange = (order: Order) => {
    this.setState({
      selectedOrder: 
        {
          ...this.state.selectedOrder!,
          amount: order.amount,
          amountTVA: order.amountTVA,
          amountWithoutTVA: order.amountWithoutTVA,
          orderParts: [...order.orderParts!],
          orderStatus: order.orderStatus,
          orderStatusId: order.orderStatusId
        }
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.orderService = (this.context as AppContext).orderService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.reloadOrders(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.reloadOrders(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            // this.sort(tableState.page, tableState.sortOrder);
            break;
          default:
          //console.log('action not handled.');
        }
      }
    };

    return (
      <React.Fragment>
        <Card>
          <div
            style={{
              backgroundColor: '#3d4977',
              fontWeight: 500,
              borderBottom: '4px solid #5383ff'
            }}
            className="card-header text-white text-center"
          >
            <span className="mb-0 py-2">
              {this.translatorService.Tranlate('ORDERS_TAB_TITLE', 'Comenzi')}
            </span>
          </div>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? <div className="ml-3 mr-3">{this.renderFilters()}</div> : null}

          <Box m={1} p={1}>
            <div className="d-flex flex-row text-center flex-wrap justify-content-center">
              <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
            </div>
            {!this.state.isLoading ? (
              <MUIDataTable
                title={''}
                data={this.state.orderList}
                columns={this.getColumns()}
                options={options}
              />
            ) : null}
          </Box>
        </Card>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{
              color: '#ffffff',
              backgroundColor: '#1F2857',
              textAlign: 'center'
            }}
          >
            <span style={{ fontSize: '20px', lineHeight: '24px' }}>
              {this.translatorService.Tranlate('ORDERS_DIALOG_TITLE', 'Detalii comanda')}
            </span>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            {this.state.selectedOrder === null ? null : (
              <OrderDetails
                {...this.props}
                onOrderChange={this.onOrderChange}
                orderDetails={this.state.selectedOrder}
                hasRights={true}
                additionTariff={{
                  id: 0,
                  value: 0
                } as TariffPercentResponse}
                discountTariff={{
                  id: 0,
                  value: 0
                } as TariffPercentResponse}
                automatePlaceOrder={false}
                workflowAutomatePlaceOrder={false}
                automatePlaceOrderChange={async () => {console.log('automatePlaceOrderChange')}}
                />
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    caseSettingsState: state.caseSettings
  }),
  null
)(withSnackbar(Orders as any));
