import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { WorkflowService } from '../../services/WorkflowService';
import { Organization } from '../../interfaces/Organization';
import { TranslatorService } from '../../services/TranslatorService';
import { isNullOrUndefined } from 'util';
import { OrganizationService } from '../../services/OrganizationService';
import { Workflow } from '../../interfaces/Workflow';
import { Close } from '@material-ui/icons';
import { ScaleLoader } from 'react-spinners';
import { newAppointmentCase } from './agenda.requests';
import { useHistory } from 'react-router';
import { Case } from '../../interfaces/Case';
import { ReferentialService } from '../../services/ReferentialService';
import { CaseTypeCodeEnum, ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';

export const NewCaseAppointment = ({appAppointmentId, close}: any) => {
  const translatorService = new TranslatorService();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<Workflow | null>(null);
  const [workflows, setWorkflows] = useState<Array<Workflow>>([]);
  const [organizations, setOrganizations] = useState<Array<Organization>>([]);

  const loadData = async () =>{
    const workflowServiceInstance = new WorkflowService();
    const organizationServiceInstance = new OrganizationService();
    const appReferentialServiceInstance = new ReferentialService([]);
    const refCaseType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseType
    );
    
    const [workflows, orgCaseTypes] = await Promise.all([
      workflowServiceInstance.GetUserWorkflows(),
      appReferentialServiceInstance.Get(refCaseType!.baseUrl)
    ]);
    const owners = await organizationServiceInstance.GetUserOrganizationsByIds(
      Array.from(new Set(workflows.map((item) => item.organizationOwnerId)))
    );
    workflows.forEach((w) => {
      const owner = owners.find((item) => item.id === w.organizationOwnerId);
      w.organizationOwner = isNullOrUndefined(owner) ? null : owner;

      const orgCaseType = orgCaseTypes.find((item) => item.id == w.caseTypeId);
      w.caseType = isNullOrUndefined(orgCaseType) ? null : orgCaseType;
    });

    const futureOrganizations: Organization[] = [];
    workflows.forEach((w) => {
      if (futureOrganizations.find((item) => item.id === w.organizationOwnerId) === undefined) {
        futureOrganizations.push(w.organizationOwner!);
      }
    });

    let counter = 0;
    let item = null;
    futureOrganizations?.forEach((org) => {
      workflows?.forEach((w) => {
        if ( w?.organizationOwnerId === org?.id && w.caseType?.code === CaseTypeCodeEnum.REPARATIE.toString()){
          counter ++;
          item = w;
        }
      })
    })

    if (counter > 1 || !item){
      setOrganizations(futureOrganizations)
      setWorkflows(workflows);
    }else if(counter === 1 && item){
      createCase(item)
    }
  }

  const createCase = async (item?:Workflow) => {
    if (isNullOrUndefined(selectedItem) && isNullOrUndefined(item)) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await newAppointmentCase({ appointmentId: appAppointmentId, workflowId: selectedItem ? selectedItem.id : item?.id })
      const newCase = res.data as Case
      history.push('/cases/' + newCase!.id, 'Case xx');
      setIsLoading(false);
    } catch (ex:any) {
      setIsLoading(false);
      enqueueSnackbar(
        translatorService.Tranlate(ex.response.data, 'Eroare creare dosar!'),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
      console.log(ex.response.data);
    }
  };

  useEffect(()=>{
    loadData();  
  },[])

  return (<>
    {isLoading || !organizations?.length || !workflows?.length ? <></>:
    <Dialog
      onClose={() => {
        return;
      }}
      aria-labelledby="customized-dialog-title"
      open={Boolean(appAppointmentId)}
      fullWidth
      maxWidth={'md'}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: 50, backgroundColor: '#3d4977',
          marginBottom: 16
        }}
      >
        <IconButton
          aria-label="close"
          onClick={close}
        >
          <Close style={{ color: 'white' }} />
        </IconButton>
      </Box>
      {organizations?.length > 0 && workflows?.length > 0 ? organizations?.map((org, index) => (
        <Box key={index}>
          <Typography className="text-left m-2 " component="h5" variant="h5" color="secondary">
            {org?.displayName}
          </Typography>
          <Grid container spacing={0}>
            {workflows
              .filter((w) => w.organizationOwnerId === org?.id)
              .map((item, index) => (
                <Grid item sm={3} xl={3} key={index} className="m-2">
                  <Box
                    height={80}
                    key={index}
                    border={1}
                    borderColor="text.disabled"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    width="95%"
                    borderRadius="0%"
                    bgcolor={selectedItem?.id === item.id ? 'secondary.main' : ''}
                  >
                    <Button
                      key={index}
                      className={selectedItem?.id === item.id ? 'text-white h-100 ' : 'text-dark h-100'}
                      onClick={() => {
                        setSelectedItem(item);
                      }}
                    >
                      <b>{item.displayName}</b>
                    </Button>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>)) :
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={true} />
        </div>}
      <Box style={{ justifyContent: 'center', display: 'flex', marginTop: 32, marginBottom: 16 }}>
          <Button disabled={isLoading || !selectedItem} className="m-2" variant="contained" color="primary" onClick={() => createCase()}>
          {translatorService.Tranlate('NEW_CASE_CREATE_CASE', 'Creeaza dosar')}
        </Button>
      </Box>

    </Dialog>
}
  </>

  );
};
