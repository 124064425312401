import { Action, Reducer } from 'redux';
import * as ActionTypes from '../actions/ActionTypes';
import { VehicleAuctionAction } from '../actions/VehicleAuction';
import { MavoVehicleAuction } from '../../interfaces/Vehicle';

export interface VehicleAuctionState {
  mavoVehicleAuction: MavoVehicleAuction | null;
}

const initialState: VehicleAuctionState = {
  mavoVehicleAuction: null
};

export const VehicleAuctionReducer: Reducer<VehicleAuctionState | undefined> = (
  state: VehicleAuctionState | undefined,
  incomingAction: Action
): VehicleAuctionState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as VehicleAuctionAction;

  switch (action.type) {
    case ActionTypes.VEHICLE_SET_VEHICLE_AUCTION:
      if (action.mavoVehicleAuction === null)
        return {
          ...state,
          mavoVehicleAuction: null
        };

      return {
        ...state,
        mavoVehicleAuction: {
          ...action.mavoVehicleAuction
        }
      };
  }

  return state;
};
