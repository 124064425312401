import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {
  Button,
  Grid
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';
import { IMavoGeneralInformationContainerProps } from '../cases/vehicleDetails/IMavoGeneralInformationContainerProps';
import { IMavoGeneralInformationContainerState } from '../cases/vehicleDetails/IMavoGeneralInformationContainerState';
import { ApplicationState, CaseSettingsActionCreators, VehicleActionCreators } from '../../store';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IReferential } from '../../interfaces/IReferential';
import { Autocomplete } from '@material-ui/lab';
import {  MavoVehicle, MavoVehicleAttachment, Provenance} from '../../interfaces/Vehicle';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import * as R from 'ramda';
import MomentUtils from '@date-io/moment';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { CarDetailsSettings, MavoVehicleProvenanceType, MavoVehicleStatusCodeEnum, RefConstants, disabledMavoVehicleStatuses, disabledNewMavoVehicleStatuses, mavoVehicleStatuses, mavoVehiclesForNirEnabled } from '../../helpers/Constants';
import { Utils } from '../../helpers/Utils';
import NumberFormat from 'react-number-format';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MavoVehicleDetailsProps } from '../cases/vehicleDetails/MavoVehicleDetailsProps';


class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}
class MavoGeneralInformationContainer extends React.PureComponent<
  IMavoGeneralInformationContainerProps,
  IMavoGeneralInformationContainerState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    executing: false,
    isValuationOpen: false,
    selectedVehicle: null,
    brandTypes: [] as IReferential[],
    modelTypes: [] as IReferential[],
    bodyTypes: [] as IReferential[],
    versionTypes: [] as IReferential[],
    fuelTypes: [] as IReferential[],
    transmissionTypes: [] as IReferential[],
    colorTypes: [] as IReferential[],
    provenanceTypes: [] as Provenance[],
    vehicleAttachmentTypes: [] as IReferential[],
    datePickerFirstRegistrationError: '',
    isOpen: false,
    dialogVisible: false
  } as IMavoGeneralInformationContainerState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );

    ValidatorForm.addValidationRule('isValidNumberOfKm', (value) => {
      if (value >= CarDetailsSettings.numberOfKm) {
        return false;
      }
      return true;
    });

  }

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };

  addMavoVehicleAttachments = async (mavoVehicleAttachments: MavoVehicleAttachment[], mavoVehicleId: number) => {
    if (mavoVehicleAttachments.length === 0) {
      return;
    }

    try {
      if (this.state.vehicleAttachmentTypes.length !== 0) {
        for (const attachemntType of this.state.vehicleAttachmentTypes) {
          const mavoVehicleAttachmentsGrouped = mavoVehicleAttachments.filter((x) => x.attachmentTypeId === attachemntType.id)
            .map((x) => { return { ...x, mavoVehicleId: mavoVehicleId } });
          if (mavoVehicleAttachmentsGrouped.length === 0) {
            continue;
          }
          const newFiles = await this.vehicleService.AddMavoVehicleAttachments(mavoVehicleAttachmentsGrouped);
        }
      }
    } catch (ex) {
      console.log(ex);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } 

  }

  submitMavoVehicleData = async () => {
    try {
      this.setState({ executing: true, isLoading: true });
      if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
        const vehicle = await this.vehicleService.GetMavoVehicleByVin(this.props.mavoVehicleState.mavoVehicle.vin);
        if (!R.isNil(vehicle)) {
          this.setState({ executing: false, isLoading: false });
          this.props.enqueueSnackbar(this.translatorService.Tranlate('MAVO_VEHICLE_EXISTS', "Vehiculul cu acest vin este deja inregistrat!"),  
          { 
            variant: 'warning' 
          });
          return;
        } 
      }

      let newVehicle = { ...this.props.mavoVehicleState.mavoVehicle } as MavoVehicle;
      const mavoVehicleAttachments = _.cloneDeep(this.props.mavoVehicleState.mavoVehicle.mavoVehicleAttachments);
      if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
        newVehicle = await this.vehicleService.AddMavoVehicle(
          newVehicle
        );
        await this.addMavoVehicleAttachments(mavoVehicleAttachments, newVehicle.id);

        this.props.setMavoVehicleObject(newVehicle);
        this.props.history.push('/mavoVehicle/' + newVehicle.id);
      } else {
        newVehicle = await this.vehicleService.AddMavoVehicle({
          ...newVehicle,
          vehicleOptions: []
        });
        this.props.setMavoVehicleObject(newVehicle);
      }

      this.setState({ executing: false, isLoading: false });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } 
  };

  handleBrandTypeChange = async (e: any, newValue: IReferential | null) => {
    this.props.setMavoVehicleObject({ 
      ...this.props.mavoVehicleState.mavoVehicle, 
      make: newValue,
        makeId: Utils.GetIdOrNull(newValue),
        model: null,
        modelId: null,
        bodyTypeRCR: null,
        bodyTypeRCRId: null
    });
  };

  handleModelTypeChange = async (e: any, newValue: IReferential | null) => {
    this.props.setMavoVehicleObject({ 
      ...this.props.mavoVehicleState.mavoVehicle, 
      model: newValue,
      modelId: Utils.GetIdOrNull(newValue),
      bodyTypeRCR: null,
      bodyTypeRCRId: null
    });
  };

  onChange =
    (fieldName: string, fieldNameId?: string) => (e: any, newValue?: IReferential | null) => {
      !R.isNil(fieldNameId)
        ? 
          this.props.setMavoVehicleObject({ 
            ...this.props.mavoVehicleState.mavoVehicle, 
            [fieldName]: newValue || e.target.value,
            [fieldNameId]: Utils.GetIdOrNull(newValue)
          })
        : 
          this.props.setMavoVehicleObject({ 
            ...this.props.mavoVehicleState.mavoVehicle, 
            [fieldName]: newValue || e.target.value
          })
  };

  onChangeProvenance =
  (fieldName: string, fieldNameId?: string) => (e: any, newValue?: Provenance | null) => {
    !R.isNil(fieldNameId)
      ?   this.props.setMavoVehicleObject({ 
        ...this.props.mavoVehicleState.mavoVehicle, 
        [fieldName]: newValue || e.target.value,
        [fieldNameId]: Utils.GetProvenanceIdOrNull(newValue)
      })
      :
      this.props.setMavoVehicleObject({ 
        ...this.props.mavoVehicleState.mavoVehicle, 
        [fieldName]: newValue || e.target.value
      })
  };

  validateFirstRegistrationDate = (selectedDate?: string): string => {
    let error = '';

    if (!selectedDate) {
      error = this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
    } else {
      const currentDate = moment().format('YYYY-MM-DD');
      if (currentDate && selectedDate > currentDate) {
        error = this.translatorService.Tranlate(
          'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION_ERROR',
          'Data primei inmatriculari trebuie sa fie mai mica decat data curenta'
        );
      }
    }

    this.setState({
      datePickerFirstRegistrationError: error
    });

    this.props.setMavoVehicleObject({
      ...this.props.mavoVehicleState.mavoVehicle,
      firstRegistrationDate: selectedDate || null
    });

    return error;
  };

  handleFirstRegistrationDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    const selectedDate = date ? date.format('YYYY-MM-DD') : undefined;
    this.validateFirstRegistrationDate(selectedDate);
  };

 
  handleCloseConfirmationDialog = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };
 
  loadData = async () => {
    const {
      vehicleMakeRef,
      bodyTypeRCRRef,
      transmissionTypeRef,
      modelRef,
      colorRCRRef,
      versionRef,
      vehicleAttachmentTypeRef
    } = RefConstants;

    const [
      vehicleMakeList,
      bodyTypeList,
      transmissionTypeList,
      fuelTypeList,
      modelTypesList,
      colorTypeList,
      versionTypeList,
      provenanceTypeList,
      vehicleAttachmentTypeList
    ] = await Promise.all([
      this.appReferentialService.Get(vehicleMakeRef?.baseUrl),
      this.appReferentialService.Get(bodyTypeRCRRef!.baseUrl),
      this.appReferentialService.Get(transmissionTypeRef!.baseUrl),
      this.vehicleService.GetRCRFuelTypes(),
      this.appReferentialService.Get(modelRef!.baseUrl),
      this.appReferentialService.Get(colorRCRRef!.baseUrl),
      this.appReferentialService.Get(versionRef!.baseUrl),
      this.vehicleService.GetProvenances(),
      this.appReferentialService.Get(vehicleAttachmentTypeRef!.baseUrl)
    ]);

    if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
      if (this.props.mavoVehicleState.mavoVehicle.provenanceId === null) {
        const dvsProvenance = provenanceTypeList.find(x => x.type === MavoVehicleProvenanceType.DVS);
        if (dvsProvenance) {
          this.props.setMavoVehicleObject({
            ...this.props.mavoVehicleState.mavoVehicle,
            provenance: dvsProvenance,
            provenanceId: dvsProvenance.id
          });
        }
      }
    }
    
    this.setState({
      isLoading: false,
      brandTypes: vehicleMakeList,
      bodyTypes: bodyTypeList,
      transmissionTypes: transmissionTypeList,
      fuelTypes: fuelTypeList,
      modelTypes: modelTypesList as IReferential[],
      colorTypes: colorTypeList,
      versionTypes: versionTypeList,
      provenanceTypes: provenanceTypeList as Provenance[],
      vehicleAttachmentTypes: vehicleAttachmentTypeList
    });
  };

  convertKwToHp = (kw: number) => (R.isEmpty(kw) ? '' : Math.round(kw * 1.36));
  convertHpToKw = (hp: number) => (R.isEmpty(hp) ? '' : Math.round(hp / 1.36));

  handlePowerChange = (e: any) => {
    this.props.setMavoVehicleObject({
      ...this.props.mavoVehicleState.mavoVehicle,
      power: e.target.value,
      horsePower: this.convertKwToHp(e.target.value)
    });
  };

  handleHPChange = (e: any) => {
    this.props.setMavoVehicleObject({
      ...this.props.mavoVehicleState.mavoVehicle,
      horsePower: e.target.value,
      power: this.convertHpToKw(e.target.value)
    });
  };


  handleFabricationYearChange = (e: any) => {
    this.props.setMavoVehicleObject({
      ...this.props.mavoVehicleState.mavoVehicle,
      fabricationYear: e.target.value
    });
  };

  handleChassisNumberChange = (e: any) => {
    const vin = e.target.value;
    this.props.setMavoVehicleObject({ ...this.props.mavoVehicleState.mavoVehicle, vin: e.target.value});
    if(R.test(/^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/, vin)) {
      this.props.setMavoVehicleObject({ 
        ...this.props.mavoVehicleState.mavoVehicle, 
        vin: e.target.value,
        make: null,
        makeId: null,
        model: null,
        modelId: null,
        versionItem: null,
        versionId: null,
        colorRCR: null,
        colorRCRId: null,
        fuelType: null,
        fuelTypeId: null,
        bodyTypeRCRId: null,
        bodyTypeRCR: null,
        transmissionTypeId: null,
        transmissionType: null,
        firstRegistrationDate: null,
        fabricationYear: null,
        kilometersNr: null,
        power: null,
        engineManufactureCode: null,
        accessories: null
      });
    }
  };

  chassisNumberValidation = async ()  => {
    if (!R.test(/^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$/, this.props.mavoVehicleState.mavoVehicle.vin)) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('INVALID_CAR_VIN_IDENTIFICATOR', "Te rugăm să te asiguri că seria de șasiu introdusă  are 17 caractere alfanumerice și nu conține spații sau literele 'O' sau 'I'!"),  
      { 
        variant: 'warning' 
      });
      return;
    }
    const vehicle = await this.vehicleService.GetMavoVehicleByVin(this.props.mavoVehicleState.mavoVehicle.vin);
    if (!R.isNil(vehicle)) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('MAVO_VEHICLE_EXISTS', "Vehiculul cu acest vin este deja inregistrat!"),  
      { 
        variant: 'warning' 
      });
      return;
    } 
  }

  private getModelsOptions(): IReferential[] {
    const filteredModelTypes = this.state.modelTypes.filter(
      (model) => model.dependencyId === this.props.mavoVehicleState.mavoVehicle.makeId
    );
    if (!R.isEmpty(filteredModelTypes)) return filteredModelTypes;

     return [];
  }


  private getVersionsOptions(): IReferential[] {
    const filteredVersionTypes = this.state.versionTypes.filter(
      (version) => version.modelId === this.props.mavoVehicleState.mavoVehicle.modelId
    );
    if (!R.isEmpty(filteredVersionTypes)) return filteredVersionTypes;

    return [];
  }

  isDisabled = (): boolean => {
    //1.2	Actualizare/adaugare
    if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
      //Nu pot fi modificate informatii vehicul din tab-urile «Informatii generale», «Stare vehicul», 
      //«Despre vehicul (Dotari)» si «Date STOC» daca vehiculul este in una din starile : «Licitatie», «Comanda», «Aviz», «Factura»
      return  disabledNewMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    } else {
      //daca vehiculul este in stare “Licitatie”, se pot actualiza doar informatiile «Stare vehicul», «Despre vehicul (Dotari)» si «Poze» 
      if (this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code === MavoVehicleStatusCodeEnum.LICITATIE.toString()) {
        return true;
      }
      //daca vehiculul este in orice alta stare («Comanda», «Aviz», «Factura»), nu se pot actualiza nici un fel de informatii referitoare la acest vehicul
      return  disabledMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    }
  };


  isDisabledVin = (): boolean => {
    //1.3	Actualizare/modificare
    //daca vehiculul nu este in stare «Licitatie», «Comanda», «Aviz», «Factura», se poate actualiza orice informatie, 
    //exceptie Cod VIN + Marca + Model
    return  disabledNewMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
  }
  
  private getBodiesOptions(): IReferential[]  {
    return this.state.bodyTypes ?? [];
  }

  renderGeneralInformationForm = () => {
    return (
      <ValidatorForm
        onSubmit={() => {
          this.submitMavoVehicleData();
        }}
     >
      <div className="m-3 text-center">
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabledVin() || this.props.disabled}
              id="chasis-code"
              className="ml-2 mr-2 pr-3 mt-0"
              name="chasisCode"
              placeholder={this.translatorService.Tranlate(
                'CAR_DETAILS_CHASIS_CODE',
                'Serie sasiu'
              )}
              value={this.props.mavoVehicleState.mavoVehicle.vin}
              onChange={this.handleChassisNumberChange}
              onBlur={this.chassisNumberValidation}
              validators={['required', 'matchRegexp:^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                ),
                this.translatorService.Tranlate('VIN_INVALID', 'Numarul VIN nu este valid')
              ]}
              label={this.translatorService.Tranlate('CAR_DETAILS_CHASIS_CODE', 'Serie sasiu')}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disabled={this.isDisabledVin() || this.props.disabled}
              id="car-brand"
              className="ml-2 mr-2"
              options={this.state.brandTypes}
              value={this.props.mavoVehicleState.mavoVehicle.make}
              onChange={this.handleBrandTypeChange}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="brand"
                  value={this.props.mavoVehicleState.mavoVehicle.make}
                  label={this.translatorService.Tranlate('CAR_DETAILS_BRAND', 'Marca')}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Autocomplete
              disabled={this.isDisabledVin() || this.props.disabled}
              id="car-model"
              className="ml-2 mr-2 mt-1"
              options={this.getModelsOptions()}
              value={this.props.mavoVehicleState.mavoVehicle.model}
              onChange={this.handleModelTypeChange}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="model"
                  value={this.props.mavoVehicleState.mavoVehicle.model}
                  label={this.translatorService.Tranlate('CAR_DETAILS_MODEL', 'Model')}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disabled={this.isDisabled() || this.props.disabled}
              id="car-version"
              className="ml-2 mr-2 mt-1"
              options={this.getVersionsOptions()}
              value={this.props.mavoVehicleState.mavoVehicle.versionItem}
              onChange={this.onChange('versionItem', 'versionId')}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="version"
                  value={this.props.mavoVehicleState.mavoVehicle.versionItem}
                  label={this.translatorService.Tranlate('CAR_DETAILS_VERSION', 'Versiune')}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Autocomplete
              disabled={this.isDisabled() || this.props.disabled}
              id="car-fuel"
              className="ml-2 mr-2 mt-1"
              options={this.state.fuelTypes}
              value={this.props.mavoVehicleState.mavoVehicle.fuelType}
              onChange={this.onChange('fuelType', 'fuelTypeId')}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="fuel"
                  value={this.props.mavoVehicleState.mavoVehicle.fuelType}
                  label={this.translatorService.Tranlate('CAR_DETAILS_FUEL_TYPE', 'Combustibil')}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled() || this.props.disabled}
              id="engine manufacture code"
              className="ml-2 mr-2 mt-1 pr-3"
              name="engineManufactureCode"
              placeholder={this.translatorService.Tranlate(
                'MAVO_VEHICLE_MANUFACTURE',
                'Motor'
              )}
              value={this.props.mavoVehicleState.mavoVehicle.engineManufactureCode || ''}
              onChange={(e: any) => this.onChange('engineManufactureCode')(e)}
              label={this.translatorService.Tranlate(
                'MAVO_VEHICLE_MANUFACTURE_CODE',
                'Motor'
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Autocomplete
              disabled={this.isDisabled() || this.props.disabled}
              id="car-transmission"
              className="ml-2 mr-2 mt-1"
              options={this.state.transmissionTypes}
              value={this.props.mavoVehicleState.mavoVehicle.transmissionType}
              onChange={this.onChange('transmissionType', 'transmissionTypeId')}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="transmission"
                  value={this.props.mavoVehicleState.mavoVehicle.transmissionType}
                  label={this.translatorService.Tranlate(
                    'MAVO_VEHICLE_TRANSMISION_TYPE',
                    'Cutie de viteza'
                  )}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disabled={this.isDisabled() || this.props.disabled}
              id="car-body-type"
              className="ml-2 mr-2 mt-1"
              options={this.getBodiesOptions()}
              value={this.props.mavoVehicleState.mavoVehicle.bodyTypeRCR}
              onChange={this.onChange('bodyTypeRCR', 'bodyTypeRCRId')}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="bodyTypeRCR"
                  value={this.props.mavoVehicleState.mavoVehicle.bodyTypeRCR}
                  label={this.translatorService.Tranlate(
                    'MAVO_VEHICLE__DETAILS_BODY',
                    'Caroserie'
                  )}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled() || this.props.disabled}
              id="car-power"
              className="ml-2 mr-2 mt-1 pr-3"
              name="power"
              placeholder={this.translatorService.Tranlate('CAR_DETAILS_POWER', 'Putere (kW)')}
              value={this.props.mavoVehicleState.mavoVehicle.power || ''}
              onChange={this.handlePowerChange}
              label={this.translatorService.Tranlate('CAR_DETAILS_POWER', 'Putere (kW)')}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled() || this.props.disabled}
              id="car-horse-power"
              className="ml-2 mr-2 mt-1 pr-3"
              name="horsePower"
              placeholder={this.translatorService.Tranlate(
                'CAR_DETAILS_HORSE_POWER',
                'Putere (CP)'
              )}
              value={this.props.mavoVehicleState.mavoVehicle.horsePower || ''}
              onChange={(e: any) => {
                this.handleHPChange(e);
              }}
              label={this.translatorService.Tranlate('CAR_DETAILS_HORSE_POWER', 'Putere (CP)')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled() || this.props.disabled}
              id="fabricationYear"
              className="ml-2 mr-2 mt-1 pr-3"
              name="fabricationYear"
              placeholder={this.translatorService.Tranlate(
                'MAVO_VEHICLE_FABRICATION_YEAR',
                'An de fabricatie'
              )}
              value={this.props.mavoVehicleState.mavoVehicle.fabricationYear || ''}
              onChange={this.handleFabricationYearChange}
              label={this.translatorService.Tranlate(
                'MAVO_VEHICLE_FABRICATION_YEAR',
                'An de fabricatie'
              )}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
              error={
                this.props.mavoVehicleState.mavoVehicle.fabricationYear &&
                this.props.mavoVehicleState.mavoVehicle.firstRegistrationDate &&
                this.props.mavoVehicleState.mavoVehicle.fabricationYear >
                new Date(this.props.mavoVehicleState.mavoVehicle.firstRegistrationDate).getFullYear()
                  ? true
                  : false
              }
              helperText={
                this.props.mavoVehicleState.mavoVehicle.fabricationYear &&
                this.props.mavoVehicleState.mavoVehicle.firstRegistrationDate &&
                this.props.mavoVehicleState.mavoVehicle.fabricationYear >
                new Date(this.props.mavoVehicleState.mavoVehicle.firstRegistrationDate).getFullYear()
                  ? this.translatorService.Tranlate(
                      'MAVO_FABRICATION_DATE_ERROR',
                      'Anul fabricatiei trebuie sa fie mai mic decat anul primei inmatriculari'
                    )
                  : ''
              }
            />  
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                fullWidth
                className="mt-1 ml-2 pr-2"
                openTo="date"
                views={['year', 'month', 'date']}
                variant="inline"
                disabled={this.isDisabled() || this.props.disabled}
                format={this.props.appState.dateFormat.toUpperCase()}
                margin="normal"
                id="firstRegistrationDate"
                label={this.translatorService.Tranlate(
                  'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION',
                  'Data primei inmatriculari'
                )}
                value={this.props.mavoVehicleState.mavoVehicle.firstRegistrationDate || null}
                onChange={this.handleFirstRegistrationDateChange}
                autoOk={true}
                error={this.state.datePickerFirstRegistrationError ? true : false}
                helperText={this.state.datePickerFirstRegistrationError}
                // minDate={activeButton === 1 ? new Date() : this.state.minDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled() || this.props.disabled}
              id="km-number"
              className="ml-2 mr-2 mt-1 pr-3"
              name="numberOfKilometers"
              placeholder={this.translatorService.Tranlate(
                'CAR_DETAILS_NUMBER_OF_KILOMETERS',
                'Numar kilometri'
              )}
              value={this.props.mavoVehicleState.mavoVehicle.kilometersNr || ''}
              onChange={(e: any) => this.onChange('kilometersNr')(e)}
              validators={['isValidNumberOfKm']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'CAR_DETAILS_NUMBER_OF_KILOMETERS_ERROR',
                  'Numarul de kilometri nu trebuie sa fie mai mare de 1000000'
                )
              ]}
              label={this.translatorService.Tranlate(
                'CAR_DETAILS_NUMBER_OF_KILOMETERS',
                'Numar kilometri'
              )}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disabled={this.isDisabled() || this.props.disabled}
              id="car-color"
              className="ml-2 mr-2 mt-1"
              options={this.state.colorTypes}
              value={this.props.mavoVehicleState.mavoVehicle.colorRCR}
              onChange={this.onChange('colorRCR', 'colorRCRId')}
              getOptionLabel={(option: IReferential) => option.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="colorRCR"
                  value={this.props.mavoVehicleState.mavoVehicle.colorRCR}
                  label={this.translatorService.Tranlate('MAVO_VEHICLE_BODY_COLOR', 'Culoare caroserie')}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled() || this.props.disabled}
              id="accessories"
              className="ml-2 mr-2 mt-1 pr-3 mt-0"
              name="accessories"
              placeholder={this.translatorService.Tranlate('MAVO_VEHICLE_ACCESORIES', 'Accesorii')}
              value={this.props.mavoVehicleState.mavoVehicle.accessories || ''}
              onChange={(e: any) => this.onChange('accessories')(e)}
              label={this.translatorService.Tranlate('MAVO_VEHICLE_ACCESORIES', 'Accesorii')}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
                disabled={this.isDisabled() || this.props.disabled}
                id="car-provenance"
                className="ml-2 mr-2 mt-1"
                options={this.state.provenanceTypes}
                value={this.props.mavoVehicleState.mavoVehicle.provenance}
                onChange={this.onChangeProvenance('provenance', 'provenanceId')}
                getOptionLabel={(option: Provenance) => option.type || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="provenance"
                    value={this.props.mavoVehicleState.mavoVehicle.provenance}
                    label={this.translatorService.Tranlate(
                      'MENU_NOMENCLATURES_TAB_PROVENANCE',
                      'Provenienta'
                    )}
                    fullWidth
                  />
                )}
              />
          </Grid>
        </Grid>
        {
          this.props.mavoVehicleAuction && this.props.mavoVehicleAuction.id !== 0 ?
          (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  disabled={this.props.disabled}
                  id="observations"
                  className="m-2 pr-3"
                  name="observations"
                  placeholder={this.translatorService.Tranlate(
                    'MAVO_VEHICLES_OBSERVATIONS',
                    'Observatii'
                  )}
                  value={this.props.mavoVehicleAuction.details || ''}
                  onChange={(e: any) => this.onChange('observations')(e)}
                  label={this.translatorService.Tranlate(
                    'MAVO_VEHICLES_OBSERVATIONS',
                    'Observatii'
                  )}
                  multiline
                  variant="outlined"
                  rows={6}
                />
              </Grid>
            </Grid>
          ) : null
        }
       
      </div>
      <div className="m-3 text-center">
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={this.props.disabled}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </div> 
    </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.renderGeneralInformationForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: MavoVehicleDetailsProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicle: state.mavoVehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators }
)(withSnackbar(MavoGeneralInformationContainer as any));
