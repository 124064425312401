import React, { memo, useEffect, useState } from 'react';
import { ApplicationContext } from '../../../context/Contexts';
import { ExternalCaseDetailsValuationWizardComponentProps } from './ExternalCaseDetailsValuationWizardComponentProps';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import { IReferential } from '../../../interfaces/IReferential';
import { useContext } from 'react';
import { StepLabel, TextField } from '@material-ui/core';
import CarCard from '../../evaluation/CarCard';
import { first, random } from 'lodash';
import Loader from '../../Loader';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useAppSelector } from '../../../store/configureStore';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  instructions: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(7)
  }
}));

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}
const renderAutocomplete = (
  options: any,
  value: any,
  label: string,
  fieldName: string,
  step: number,
  onChange: (fieldName: string) => (e: any, newValue?: IReferential | null | undefined) => void,
  showNoDataMessage?: boolean,
  translatorService?: any,
  language?: string,
  firstRegistrationDate?: Date | null,
  onDateChange?: (date: Date | null) => void,
  isFromValuationModule?: boolean
) => {
  const handleDateChange = (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    if (onDateChange) {
      const selectedDate = date ? new Date(date.format('YYYY-MM-DD')) : null;

      onDateChange(selectedDate);
    }
  };

  return (
    <>
      <>
        <Box>
          {step === 0 && (
            <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={language}>
              <DatePicker
                fullWidth
                className="mt-2 ml-2 pr-2"
                openTo="date"
                views={isFromValuationModule ? ['year', 'month'] : ['year', 'month', 'date']}
                variant="inline"
                format={isFromValuationModule ? 'MM/YYYY' : 'DD/MM/YYYY'}
                margin="normal"
                id="firstRegistrationDate"
                label={translatorService.Tranlate(
                  'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION',
                  'Data primei inmatriculari'
                )}
                value={moment(firstRegistrationDate).isValid() ? firstRegistrationDate : null}
                onChange={handleDateChange}
                autoOk={true}
                disableFuture={true}
              />
            </MuiPickersUtilsProvider>
          )}

          <Autocomplete
            className="m-2"
            disabled={
              step === 0 && (!firstRegistrationDate || !moment(firstRegistrationDate).isValid())
            }
            options={options}
            value={step !== 0 && value}
            onChange={onChange(fieldName)}
            getOptionLabel={(option: IReferential) => option.displayName || ''}
            renderInput={(params) => (
              <TextField {...params} name={label} value={value} label={label} fullWidth />
            )}
          />
        </Box>
        {options.length !== 0 ? (
          (step === 1 || step === 2 || step === 3) && (
            <Box
              className="d-flex flex-wrap mt-5"
              style={{
                gap: '30px'
              }}
            >
              {true &&
                options.map((option: IReferential) => (
                  <CarCard
                    key={random(0, 1000000)}
                    option={option}
                    fieldName={fieldName}
                    onChange={onChange}
                  />
                ))}
            </Box>
          )
        ) : showNoDataMessage ? (
          <Box
            style={{
              backgroundColor: '#f8d7da',
              padding: '20px',
              borderRadius: '5px'
            }}
            className="mt-5"
          >
            <Typography variant="h6" component="h2" style={{ color: '#721c24' }}>
              {translatorService.Tranlate(
                'CAR_MANUAL_IDENTIFICATION_NO_DATA_AVAILABLE',
                'Nu există date disponibile! Vă rugăm să verificați dacă pașii anteriori au fost completați cu succes!'
              )}
            </Typography>
          </Box>
        ) : (
          step <= 3 && (
            <div className="mt-5">
              <Loader />
            </div>
          )
        )}
      </>
    </>
  );
};

const ValuationFiltersWizardComponent = memo<ExternalCaseDetailsValuationWizardComponentProps>(
  ({
    steps,
    activeStep,
    handleStep,
    onChange,
    state,
    makeList,
    models,
    bodyTypesList,
    generationTypesList,
    fuelTypes,
    powerTypes,
    transmissionTypes,
    tractionTypes,
    firstRegistrationDate,
    onChangeFirstRegistrationDate,
    isFromValuationModule
  }) => {
    const classes = useStyles();
    const context = useContext(ApplicationContext);
    const translatorService = context.translatorService;
    const [showNoDataMessage, setShowNoDataMessage] = useState(false);
    const language = useAppSelector((state) => state.app?.language);
    const [firstRegistrationDateLocal, setFirstRegistrationDate] = useState<Date | null>(
      firstRegistrationDate
    );

    useEffect(() => {
      setShowNoDataMessage(false);
      const timer = setTimeout(() => {
        setShowNoDataMessage(true);
      }, 3000);

      return () => clearTimeout(timer);
    }, [activeStep]);

    const onDateChange = (date: Date | null) => {
      setFirstRegistrationDate(date);
    };

    useEffect(() => {
      if (firstRegistrationDateLocal && onChangeFirstRegistrationDate) {
        onChangeFirstRegistrationDate(firstRegistrationDateLocal);
      }
    }, [firstRegistrationDateLocal]);

    const getStepContent = (step: number) => {
      switch (step) {
        case 0:
          return renderAutocomplete(
            makeList,
            state.brand,
            translatorService.Tranlate('CAR_DETAILS_BRAND', 'Marca'),
            'brand',
            step,
            onChange([
              'model',
              'body',
              'generation',
              'fuelType',
              'powerType',
              'transmissionType',
              'tractionType'
            ]),
            showNoDataMessage,
            translatorService,
            language,
            firstRegistrationDateLocal,
            onDateChange,
            isFromValuationModule
          );
        case 1:
          return renderAutocomplete(
            models,
            state.model,
            translatorService.Tranlate('CAR_DETAILS_MODEL', 'Model'),
            'model',
            step,
            onChange([
              'body',
              'generation',
              'fuelType',
              'powerType',
              'transmissionType',
              'tractionType'
            ]),
            showNoDataMessage,
            translatorService
          );
        case 2:
          return renderAutocomplete(
            bodyTypesList,
            state.body,
            translatorService.Tranlate('CAR_DETAILS_BODY_TYPE', 'Caroserie'),
            'body',
            step,
            onChange(['generation', 'fuelType', 'powerType', 'transmissionType', 'tractionType']),
            showNoDataMessage,
            translatorService
          );

        case 3:
          return renderAutocomplete(
            generationTypesList,
            state.generation,
            translatorService.Tranlate('WIZARD_GENERATION', 'Generatie'),
            'generation',
            step,
            onChange(['fuelType', 'powerType', 'transmissionType', 'tractionType']),
            showNoDataMessage,
            translatorService
          );

        case 4:
          return renderAutocomplete(
            fuelTypes,
            state.fuelType,
            translatorService.Tranlate('WIZARD_FUEL', 'Combustibil'),
            'fuelType',
            step,
            onChange(['powerType', 'transmissionType', 'tractionType'])
          );
        case 5:
          return renderAutocomplete(
            powerTypes,
            state.powerType,
            translatorService.Tranlate('WIZARD_POWER', 'Putere'),
            'powerType',
            step,
            onChange(['transmissionType', 'tractionType'])
          );
        case 6:
          return renderAutocomplete(
            transmissionTypes,
            state.transmissionType,
            translatorService.Tranlate('WIZARD_FUEL_TRANSMISSION_TYPE', 'Transmisie'),
            'transmissionType',
            step,
            onChange(['tractionType'])
          );
        case 7:
          return renderAutocomplete(
            tractionTypes,
            state.tractionType,
            translatorService.Tranlate('WIZARD_TRACTION_TYPE', 'Tractiune'),
            'tractionType',
            step,
            onChange()
          );
        default:
          return 'Unknown step';
      }
    };

    //useEffect(() => {

    //    if (!R.isNil(state.brand)) {
    //        console.log('state.brand' + state.brand.displayName);
    //        stepsLabel[0] = state.brand.displayName as string;
    //    }
    //}, [state.brand])

    //useEffect(() => {
    //    if (!R.isNil(state.model)) {
    //        stepsLabel[1] = state.model.displayName as string;
    //    }
    //}, [state.model])

    const getTextContent = (step: number) => {
      switch (step) {
        case 0:
          return state.brand?.displayName;
        case 1:
          return state.model?.displayName;
        case 2:
          return state.body?.displayName;
        case 3:
          return `${state.generation?.extraData?.generation} (${state.generation?.extraData?.modelYear})`;
        case 4:
          return state.fuelType?.displayName;
        case 5:
          return state.powerType?.displayName;
        case 6:
          return state.transmissionType?.displayName;
        case 7:
          return state.tractionType?.displayName;
        default:
          return null;
      }
    };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="horizontal">
          {steps.map((label, index) => {
            const labelProps: { optional?: React.ReactNode } = {};
            if (index < activeStep) {
              labelProps.optional = (
                <Typography variant="caption">{getTextContent(index)}</Typography>
              );
            }
            return (
              <Step key={label}>
                <StepButton onClick={() => handleStep(index)} {...labelProps}>
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              {/* <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button> */}
            </div>
          ) : (
            <div>
              <Typography component="div" className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ValuationFiltersWizardComponent;
