import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { Box, Button, IconButton, Card, CardContent, Switch, Dialog } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../interfaces/IReferential';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { WorkflowSection } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';

interface IWorkflowSection {
  workflowSections: WorkflowSection[];
  isLoading: boolean;
  workflowSectionTypes: IReferential[];
  selectedWorkflowSectionType: IReferential | null;
  workflowId: number;
  isDialogOpen: boolean;
  workflowSectionId: number | null;
}

type IWorkflowSectionProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowCaseStructureSectionTab extends React.PureComponent<
  IWorkflowSectionProps,
  IWorkflowSection
> {
  private translatorService!: ITranslatorService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowSections: [],
    isLoading: false,
    workflowSectionTypes: [],
    selectedWorkflowSectionType: null,
    workflowId: 0,
    isDialogOpen: false,
    workflowSectionId: null
  } as IWorkflowSection;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowSections();
      }
    );
  }

  loadWorkflowSections = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.Section);

    const [workflowSectionList, sections] = await Promise.all([
      this.workflowService.GetWorkflowSections(workflowId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    const workflowSections = workflowSectionList.sort(function (a, b) {
      return a.order - b.order;
    });

    this.setState({
      workflowSections: workflowSections,
      workflowSectionTypes: sections, //.filter(item => item.isActive === true),
      isLoading: false,
      workflowId: workflowId
    });
  };

  moveUp = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.workflowSections];
    const itemToMoveUpIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveUpIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveUpIndex].order === 1) {
      return;
    }

    const itemToMoveDownIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveUpIndex].order - 1
    );

    cloneArray[itemToMoveUpIndex] = {
      ...cloneArray[itemToMoveUpIndex],
      order: cloneArray[itemToMoveUpIndex].order - 1
    };
    cloneArray[itemToMoveDownIndex] = {
      ...cloneArray[itemToMoveDownIndex],
      order: cloneArray[itemToMoveDownIndex].order + 1
    };

    try {
      await this.workflowService.UpdateWorkflowSection(cloneArray[itemToMoveUpIndex]);
      await this.workflowService.UpdateWorkflowSection(cloneArray[itemToMoveDownIndex]);

      this.setState({
        workflowSections: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveDown = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.workflowSections];
    const itemToMoveIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveIndex].order === cloneArray.length) {
      return;
    }

    const itemToMoveToIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveIndex].order + 1
    );

    cloneArray[itemToMoveIndex] = {
      ...cloneArray[itemToMoveIndex],
      order: cloneArray[itemToMoveIndex].order + 1
    };
    cloneArray[itemToMoveToIndex] = {
      ...cloneArray[itemToMoveToIndex],
      order: cloneArray[itemToMoveToIndex].order - 1
    };

    try {
      await this.workflowService.UpdateWorkflowSection(cloneArray[itemToMoveIndex]);
      await this.workflowService.UpdateWorkflowSection(cloneArray[itemToMoveToIndex]);

      this.setState({
        workflowSections: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const workflowSections = [...this.state.workflowSections];
    const updatedWorkflowSectionIndex = workflowSections.findIndex((o) => o.id === id);

    if (updatedWorkflowSectionIndex >= 0) {
      workflowSections[updatedWorkflowSectionIndex] = {
        ...workflowSections[updatedWorkflowSectionIndex],
        isActive: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowSection(
          workflowSections[updatedWorkflowSectionIndex]
        );

        this.setState({ workflowSections: workflowSections });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'section',
        label: this.translatorService.Tranlate(
          'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_SECTION_SECTION_HEADER',
          'Sectiune Dosar'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_SECTION_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'order',
        sortDirection: 'asc',
        label: this.translatorService.Tranlate(
          'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_SECTION_CASE_ORDER_HEADER',
          'Ord'
        ),
        options: { sort: false }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          sortOrder: {
            name: 'order',
            direction: 'asc'
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Fragment>
                <IconButton color="inherit" size="small" onClick={(e) => this.moveUp(e, tableMeta)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => this.moveDown(e, tableMeta)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Fragment>
            );
          }
        }
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value ? value : false}
                onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) =>
                  this.setState({ isDialogOpen: true, workflowSectionId: tableMeta.rowData[0] })
                }
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addOrgCase = async () => {
    try {
      await this.workflowService.AddWorkflowSection({
        order: this.state.workflowSections.length + 1,
        sectionId: this.state.selectedWorkflowSectionType!.id,
        workflowId: this.state.workflowId,
        isActive: true
      } as WorkflowSection);

      this.setState(
        {
          isLoading: true,
          selectedWorkflowSectionType: null
        },
        () => {
          this.loadWorkflowSections();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteChange = (newValue: any | null) => {
    this.setState({ selectedWorkflowSectionType: newValue });
  };

  public renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrgCase}>
              <Autocomplete
                id="caseSection"
                className="m-2"
                options={this.state.workflowSectionTypes}
                value={this.state.selectedWorkflowSectionType}
                onChange={(e: any, newValue: any | null) => this.handleAutocompleteChange(newValue)}
                getOptionLabel={(option: any) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="caseSection"
                    value={this.state.selectedWorkflowSectionType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_SECTION_SECTION_HEADER',
                      'Sectiune Dosar'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  closeDialog = async () => {
    this.setState({ isDialogOpen: false });
  };

  deleteWorkflowSection = async () => {
    try {
      await this.workflowService.RemoveWorkflowSection(this.state.workflowSectionId!);
      await this.loadWorkflowSections();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowSections}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>

        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <div className="text-center p-5">
            <h4 className="font-weight-bold mt-4">
              {this.translatorService.Tranlate(
                'DELETE_TEXT',
                'Sigur doriti sa stergeti aceasta inregistrare?'
              )}
            </h4>
            <div className="pt-4">
              <Button
                onClick={this.closeDialog}
                variant="outlined"
                color="secondary"
                className="mx-1"
              >
                <span className="btn-wrapper--label">
                  {this.translatorService.Tranlate('CANCEL', 'Anuleaza')}
                </span>
              </Button>
              <Button
                onClick={() => {
                  this.setState(
                    { isDialogOpen: false },
                    async () => await this.deleteWorkflowSection()
                  );
                }}
                color="primary"
                variant="contained"
                className="mx-1"
              >
                <span className="btn-wrapper--label">
                  {this.translatorService.Tranlate('DELETE', 'Sterge')}
                </span>
              </Button>
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(WorkflowCaseStructureSectionTab as any));
