import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Hidden, IconButton, AppBar, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { ThemeActionCreators } from '../../store';
import projectLogo from '../../assets/images/carfix_newgtlogo.png';
import HeaderLogo from '../../layout-components/HeaderLogo';
import HeaderDots from '../../layout-components/HeaderDots';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import HeaderSearch from '../../layout-components/HeaderSearch';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const Header = (props) => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
    user
  } = props;

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': props.isCollapsedLayout
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}
      >
        {!props.isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box className="app-logo-wrapper" title="Claim Box">
              <Link to="/" className="app-logo-link">
                <IconButton color="primary" size="medium" className="header-logo">
                  <img
                    style={{ height: '100%', width: '100px' }}
                    alt="Claim Box"
                    src={projectLogo}
                  />
                </IconButton>
              </Link>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle
                  })}
                >
                  <IconButton
                    color="inherit"
                    onClick={toggleSidebar}
                    size="medium"
                    className="btn-inverse"
                  >
                    {sidebarToggle ? (
                      <MenuRoundedIcon />
                    ) : (
                      <MenuOpenRoundedIcon /> //style={{color:"#1F2857"}}
                    )}
                  </IconButton>
                </Box>
              )}
              {user != null ? <HeaderSearch /> : null}
            </Box>
          </Hidden>
          {user != null ? (
            <Box className="d-flex align-items-center header-user-menu">
              <HeaderDots />
              <HeaderUserbox />
              <Box className="toggle-sidebar-btn-mobile">
                <IconButton color="inherit" onClick={toggleSidebarMobile} size="medium">
                  {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
                </IconButton>
              </Box>
            </Box>
          ) : null}
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.themeOptions.headerShadow,
  headerFixed: state.themeOptions.headerFixed,
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile,
  sidebarToggle: state.themeOptions.sidebarToggle,
  user: state.app.user,
  appState: state.app
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(ThemeActionCreators.setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(ThemeActionCreators.setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
