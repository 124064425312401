import React from 'react';
import { ScaleLoader } from 'react-spinners';

const Loader: React.FC = () => {
  return (
    <div className="d-flex flex-row text-center flex-wrap justify-content-center">
      <ScaleLoader color={'var(--primary)'} loading={true} />
    </div>
  );
};

export default Loader;
