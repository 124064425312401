import * as React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Card,
} from '@material-ui/core';
import { LanguageApi, LanguageLocal } from '../../../interfaces/Case';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { withSnackbar, ProviderContext } from 'notistack';

interface Props extends ProviderContext {
  languageApi: LanguageApi;
  languagesLocal: LanguageLocal[];
  fetchLanguages: () => void;
}

class LanguageLocalCard extends React.PureComponent<Props> {
  private translatorService!: ITranslatorService;
  
  static contextType = ApplicationContext;

  constructor(props: Props) {
    super(props);
  }

  checkIfLocalLanguageExists(language: LanguageApi) {
    return this.props.languagesLocal.some((lang) => lang.code === language.code);
  }

  showSnackbar = (result: 'success' | 'error') => {
    if (result === 'success') {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCESS_MSG', 'Succes'), {
        variant: 'success'
      });
    } else if (result === 'error') {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handleImport = async (code: string) => {
    try {
      const result = await this.translatorService.ImportLanguage({code});
      this.props.fetchLanguages();
      this.showSnackbar('success');
    } catch (error) {
      this.showSnackbar('error');
    }
  }

  handleUpdate = async (code: string) => {
    try {
      const result = await this.translatorService.UpdateLanguage({code});
      this.props.fetchLanguages();
      this.showSnackbar('success');
    } catch (error) {
      this.showSnackbar('error');
    }
  }

  handleDelete = async (code: string) => {
    try {
      const result = await this.translatorService.DeleteLanguage({code});
      this.props.fetchLanguages();
      this.showSnackbar('success');
    } catch (error) {
      this.showSnackbar('error');
    }
  }
  
  renderActionsButtons(language: LanguageApi) {
    const buttonStyle = {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      borderRadius: '0.5rem',
      margin: '0 5px',
      color: 'white',
      fontWeight: 'bold',
    } as React.CSSProperties;

    if (this.checkIfLocalLanguageExists(language)) {
      return (
        <>
          <ListItem style={{ ...buttonStyle, backgroundColor: '#D32F2F' }} button onClick={() => this.handleDelete(language.code)}>
            <ListItemText primary={this.translatorService.Tranlate('REMOVE', 'Sterge')} />
          </ListItem>
          <ListItem style={{ ...buttonStyle, backgroundColor: '#3D4977' }} button onClick={() => this.handleUpdate(language.code)}>
            <ListItemText primary={this.translatorService.Tranlate('UPDATE', 'Actualizeaza')} />
          </ListItem>
        </>
      );
    } else {
      return (
        <ListItem style={{ ...buttonStyle, border: 2, borderColor: '#5383FF', borderStyle: 'solid', color: '#5383FF' }} button onClick={() => this.handleImport(language.code)}>
          <ListItemText primary={this.translatorService.Tranlate('IMPORT', 'Importa')} />
        </ListItem>
      );
    }
  }

  render() {
    this.translatorService = (this.context as AppContext).translatorService;

    return (
      <Card style={{ padding: '0 10px' }}>
        <List style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <ListItem style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemText primary={this.props.languageApi.code} />
          </ListItem>
          {this.renderActionsButtons(this.props.languageApi)}
        </List>
      </Card>
    );
  }
}

export default withSnackbar(LanguageLocalCard);
