import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Card, Tabs, Tab, CardContent } from '@material-ui/core';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { Language } from '../../../../interfaces/Organization';
import { withSnackbar, ProviderContext } from 'notistack';
import TabPanel from '../../../_shared/Tabs';
import { ScaleLoader } from 'react-spinners';
import { WorkflowSectionStepForm } from '../../../../interfaces/Workflow';
import WorkflowStepStatuses from '../editWorkflowStep/EditWorkflowStepStatuses';

interface IEditWorkflowFormState {
  workflowForm: WorkflowSectionStepForm;
  selectedTab: number;
  languages: Language[];
  isLoading: boolean;
}

export interface ExternalEditWorkflowStepProps {
  workflowStepChanged?: (close: boolean) => void;
  workflowStepId: number;
}

type EditWorkflowStepProps = { appState: AppState } & ProviderContext &
  ExternalEditWorkflowStepProps &
  RouteComponentProps<{ id: string }>;

class EditWorkflowStep extends React.PureComponent<EditWorkflowStepProps, IEditWorkflowFormState> {
  private translatorService!: ITranslatorService;

  static contextType = ApplicationContext;

  state = {
    workflowForm: {} as WorkflowSectionStepForm,
    selectedTab: 0,
    languages: [],
    isLoading: true
  } as IEditWorkflowFormState;

  public async componentDidMount() {
    this.setState({ isLoading: false });
  }

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;

    if (this.state.isLoading) {
      return (
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
      );
    }

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="scrollable"
              onChange={this.onTabChange}
            >
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_FORM_EDIT_DESIGN_TAB',
                  'Design'
                )}
              />
            </Tabs>
          </CardContent>
        </Card>

        <TabPanel value={this.state.selectedTab} index={0}>
          <WorkflowStepStatuses
            {...this.props}
            workflowSectionStepId={this.props.workflowStepId}
          ></WorkflowStepStatuses>
        </TabPanel>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalEditWorkflowStepProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(EditWorkflowStep as any));
