import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const LinkButton = ({ onClickMethod, text }: {onClickMethod:()=>void, text:string}) => {
  return (
    <button 
      onClick={()=> onClickMethod()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor:'transparent',
        borderColor:'transparent',
        alignItems: 'center',
        marginTop: '30px',
        color:'#0069d9'
      }}
    >
      <span className="btn-wrapper--icon" style={{ fontSize: '20px' }}>
        <FontAwesomeIcon color='#0069d9' icon={['fas', 'plus-circle']} />
      </span>
      <span className="btn-wrapper--label">{text}</span>
    </button>
  );
};
