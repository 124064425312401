import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppUserAdminActionCreators, AppUserAdminState } from '../../../store';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import UsersTab from './UsersTab';
import RolesTab from './RolesTab';
import { RouteComponentProps } from 'react-router';
import TabPanel from '../../_shared/Tabs';
import { Card, Tab, Tabs, Box, CardContent } from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import MavoUsersTab from './MavoUsersTab';

export interface ExternalAppUsersAdminProps {
  selectedTab: number;
}
type AppUserAdminProps = AppUserAdminState &
  typeof AppUserAdminActionCreators &
  ExternalAppUsersAdminProps &
  RouteComponentProps<{ startDateIndex: string }>;

class MavoAppUsersAdmin extends React.PureComponent<AppUserAdminProps> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {
    selectedTab: 0
  };

  public componentDidMount() {
    if (this.props.match.path.toLowerCase() === '/admin/mavo/users') {
      this.setState({ selectedTab: 0 });
    }
    if (this.props.match.path.toLowerCase() === '/admin/roles') {
      this.setState({ selectedTab: 1 });
    }
  }

  handleChangeTab = async (event: React.ChangeEvent<any>, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <Fragment>
        <Box m={1} p={1}>
          <Card>
            <CardContent>
              <Tabs
                value={this.state.selectedTab}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                onChange={this.handleChangeTab}
                aria-label="disabled tabs example"
              >
                <Tab
                  label={this.translatorService.Tranlate('ADMIN_USERS_TAB_USERS', 'Utilizatori')}
                />
                <Tab
                  label={this.translatorService.Tranlate(
                    'ADMIN_USERS_TAB_ROLS',
                    'Grupuri Permisiuni'
                  )}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>

        <TabPanel value={this.state.selectedTab} index={0}>
          <MavoUsersTab {...this.props} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <RolesTab {...this.props} />
        </TabPanel>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.app, // Selects which state properties are merged into the component's props
  AppUserAdminActionCreators // Selects which action creators are merged into the component's props
)(MavoAppUsersAdmin as any);
