import { Action, Reducer } from 'redux';
import { VehicleAction } from '../actions/Vehicle';
import * as ActionTypes from '../actions/ActionTypes';
import { Vehicle } from '../../interfaces/Vehicle';

export interface VehicleState {
  vehicle: Vehicle | null;
}

const initialState: VehicleState = {
  vehicle: null
};

export const VehicleReducer: Reducer<VehicleState | undefined> = (
  state: VehicleState | undefined,
  incomingAction: Action
): VehicleState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as VehicleAction;

  switch (action.type) {
    case ActionTypes.VEHICLE_SET_VEHICLE:
      if (action.vehicle === null)
        return {
          ...state,
          vehicle: null
        };

      return {
        ...state,
        vehicle: {
          ...action.vehicle
        }
      };
    case ActionTypes.VEHICLE_SET_VEHICLE_STATUS:
      return {
        ...state,
        vehicle: {
          ...state.vehicle!,
          currentStatus: { ...action.status }
        }
      };
    case ActionTypes.VEHICLE_RESET_STATE:
      return {
        ...state,
        vehicle: initialState.vehicle
      };
    case ActionTypes.VEHICLE_SET_VEHICLE_LOADING:
      return {
        ...state,
        vehicle: {
          ...state.vehicle!,
          loading: action?.loading
        }
      };
  }

  return state;
};
