import moment from 'moment';
import { Case } from '../interfaces/Case';
import { Vehicle } from '../interfaces/Vehicle';
import * as R from 'ramda';

export class VehicleHelper {
  public static GetPlateNumber(casee: Case | null, vehicle: Vehicle | null): string {
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      return casee.caseVehicle.plateNumber!;
    }
    if (!R.isNil(vehicle)) {
      return vehicle.plateNumber;
    }

    return '';
  }

  public static GetBrand(casee: Case | null, vehicle: Vehicle | null): string {
    let caseVehicleBrand = '';
    let vehicleBrand = '';
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      caseVehicleBrand = !R.isNil(casee.caseVehicle.identifiedMake) ? casee.caseVehicle.identifiedMake : R.isNil(casee.caseVehicle.model) ? '' : casee.caseVehicle.model.brand;
    }
    if (!R.isNil(vehicle)) {
      vehicleBrand = R.isNil(vehicle.make) ? '' : vehicle.make.displayName;
    }

    return vehicleBrand || caseVehicleBrand;
  }

  public static GetModel(casee: Case | null, vehicle: Vehicle | null): string {
    let caseVehicleModel = '';
    let vehicleModel = '';
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      caseVehicleModel =!R.isNil(casee.caseVehicle.identifiedModel) ? casee.caseVehicle.identifiedModel : R.isNil(casee.caseVehicle.model) ?  '' : casee.caseVehicle.model.name;
    }
    if (!R.isNil(vehicle)) {
      vehicleModel = R.isNil(vehicle.model) ? '' : vehicle.model.name!;
    }

    return vehicleModel || caseVehicleModel;
  }

  public static GetUMC(casee: Case | null, vehicle: Vehicle | null): string {
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      return R.isNil(casee.caseVehicle.model) ? '' : casee.caseVehicle.model.code;
    }
    if (!R.isNil(vehicle)) {
      return 'N/A';
    }

    return '';
  }

  public static GetVIN(casee: Case | null, vehicle: Vehicle | null): string {
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      return R.isNil(casee.caseVehicle.vin) ? 'N/A' : casee.caseVehicle.vin;
    }
    if (!R.isNil(vehicle)) {
      return vehicle.vin;
    }

    return '';
  }

  public static GetKmNr(casee: Case | null, vehicle: Vehicle | null): number {
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      return R.isNil(casee.caseVehicle.numberOfKilometers)
        ? 0
        : casee.caseVehicle.numberOfKilometers;
    }
    if (!R.isNil(vehicle)) {
      return R.isNil(vehicle.kilometersNr) ? 0 : vehicle.kilometersNr;
    }

    return 0;
  }

  public static GetFirstRegistrationDate(
    casee: Case | null,
    vehicle: Vehicle | null,
    language: string
  ): string {
    const dateObj = this.GetFirstRegistrationDateObject(casee, vehicle);

    return R.isNil(dateObj) ? 'N/A' : dateObj.toLocaleDateString(language);
  }
  public static GetFirstRegistrationDateFormat(
    casee: Case | null,
    vehicle: Vehicle | null,
    format: string
  ): string {
    const dateObj = this.GetFirstRegistrationDateObject(casee, vehicle);

    return moment(dateObj).format(format);
  }

  public static GetFirstRegistrationDateObject(
    casee: Case | null,
    vehicle: Vehicle | null
  ): Date | null {
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      return R.isNil(casee.caseVehicle.firstPlateDate)
        ? null
        : moment.utc(casee.caseVehicle.firstPlateDate).local().toDate();
    }
    if (!R.isNil(vehicle)) {
      return R.isNil(vehicle.firstRegistrationDate)
        ? null
        : moment.utc(vehicle.firstRegistrationDate).local().toDate();
    }

    return null;
  }

  public static GetMakeDateObject(casee: Case | null, vehicle: Vehicle | null): Date | null {
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      return R.isNil(casee.caseVehicle.registrationDate)
        ? null
        : moment.utc(casee.caseVehicle.registrationDate).local().toDate();
    }
    if (!R.isNil(vehicle)) {
      return R.isNil(vehicle.makeDate) ? null : moment.utc(vehicle.makeDate).local().toDate();
    }

    return new Date();
  }

  public static GetMakeDate(casee: Case | null, vehicle: Vehicle | null, language: string): string {
    const dateObj = this.GetMakeDateObject(casee, vehicle);

    return R.isNil(dateObj) ? 'N/A' : dateObj.toLocaleDateString(language);
  }

  public static GetMakeDateFormat(
    casee: Case | null,
    vehicle: Vehicle | null,
    format: string
  ): string | null {
    const dateObj = this.GetMakeDateObject(casee, vehicle);
    if (dateObj == null) return null;
    return moment(dateObj).format(format);
  }

  public static GetBrandModel(casee: Case | null, vehicle: Vehicle | null): string {
    return this.GetBrand(casee, vehicle) + ' ' + this.GetModel(casee, vehicle);
  }
}
