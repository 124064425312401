import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  Modal,
  Box
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  SelectableRows,
  MUIDataTableMeta
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AutorenewOutlinedIcon from '@material-ui/icons/AutorenewOutlined';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import { Order, PartToBeOrdered } from '../../interfaces/Order';
import OrderDetails from '../orders/OrderDetails';

import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CaseStatusCodeEnum, excludedCodes, nontStockRoles, OrderStatusCode, OwnStockCodeEnum, ParametreCode, ReferentialCode, SupplierCode, TariffTypeGroupCodeEnum } from '../../helpers/Constants';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { Organization } from '../../interfaces/Organization';
import UpdateOrderPart from '../orders/UpdateOrderPart';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { AppUser } from '../../interfaces/AppUser';
import * as R from 'ramda';
import {  DialogActions, DialogContentText } from '@material-ui/core';
import _, { min } from 'lodash';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { AutomateAppointmentRequest, CaseStatusHistory, ChangeCaseStatusRequest, CreatePartsToBeOrderedRequest, TariffPercentRequest, TariffPercentResponse } from '../../interfaces/Case';
import { Utils } from '../../helpers/Utils';

interface ICaseDetailsCaseTabOrderFormState {
  isLoading: boolean;
  orderList: Order[];
  unorderedParts: PartToBeOrdered[];
  orderIds: number[];
  isDialogOpen: boolean;
  isAddSupplierOrderStatusDialogOpen: boolean;
  workflowSuppliers: Organization[];
  selectedWorkflowSupplier: Organization | null;
  isUpdateOrderPartDialogOpen: boolean;
  unorderedPartIds: number[];
  currency: string;
  hasRights: boolean;
  organizationId: number | null;
  dialogVisible: boolean;
  isOpen: boolean;
  organizations: Organization[];
  insurerList: IReferential[];
  additionTariff: TariffPercentResponse;
  executing: boolean;
  discountTariff: TariffPercentResponse;
  automatePlaceOrder: boolean;
  caseStatusHistory: CaseStatusHistory[];
}
export interface ExternalCaseDetailsCaseTabOrderFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabOrderFormProps = ExternalCaseDetailsCaseTabOrderFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string; calcId: string | undefined; formId: string | undefined }>;

class CaseDetailsCaseTabOrderForm extends React.PureComponent<
  ICaseDetailsCaseTabOrderFormProps,
  ICaseDetailsCaseTabOrderFormState
> {
  private translatorService!: ITranslatorService;
  private orderService!: IOrderService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    isLoading: true,
    orderList: [],
    unorderedParts: [],
    orderIds: [],
    isDialogOpen: false,
    isAddSupplierOrderStatusDialogOpen: false,
    workflowSuppliers: [] as Organization[],
    selectedWorkflowSupplier: null,
    isUpdateOrderPartDialogOpen: false,
    unorderedPartIds: [],
    currency: '',
    hasRights: false,
    organizationId: this.props.appState.appUser!.organizationId,
    dialogVisible: false,
    isOpen: false,
    organizations: [],
    insurerList: [],
    additionTariff: {
      id: 0,
      value: 0
    } as TariffPercentResponse,
    executing: false,
    discountTariff: {
      id: 0,
      value: 0
    } as TariffPercentResponse,
    automatePlaceOrder: false,
    caseStatusHistory: []
  } as ICaseDetailsCaseTabOrderFormState;

  public async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const workflowSuppliersList =
      this.props.caseSettingsState.caseSettings!.workflow.workflowSuppliers;
    const currrency = this.props.caseSettingsState.case!.caseParameters.find(
      (item) => item.parameter!.code === ParametreCode.CURRENCY
    )!.value;

    const refInsurer = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Insurer
    );

    const hasValidatorRole =  nontStockRoles.includes(this.props.appState.appUser?.userRole?.code ?? '');
    const request = {
      workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
      automateGenerateOrder: this.props.caseSettingsState.caseSettings!.workflow.automateGenerateOrder,
      partStock: this.props.caseSettingsState.caseSettings!.workflow.hasNonStockValidation ? hasValidatorRole : false,
      nonStockOrder: !hasValidatorRole ? this.props.caseSettingsState.caseSettings!.workflow.hasNonStockValidation : false
    } as CreatePartsToBeOrderedRequest;

    const [unorderedParts, suppliersInit, insurerList, caseStatusHistory] = await Promise.all([
      this.props.caseSettingsState.case!.caseStatus.caseStatus!.code == CaseStatusCodeEnum.COM_PI ? this.orderService.CreatePartsToBeOrdered(this.props.caseSettingsState.case!.id, request) :
      this.props.caseSettingsState.case!.caseStatus.caseStatus!.code == CaseStatusCodeEnum.COMANDA_ATM ? this.orderService.GetPartsToBeOrdered(this.props.caseSettingsState.case!.id) : [],
      workflowSuppliersList.length > 0
        ? await this.organizationService.GetUserOrganizationsByIds(
            Array.from(new Set(workflowSuppliersList.map((item) => item.supplierId)))
          )
        : [],
      this.appReferentialService.Get(refInsurer!.baseUrl),
      this.props.caseSettingsState?.caseSettings!.workflow?.isCarfixIntegration 
        ? this.caseService.GetCaseStatusHistory(this.props.caseSettingsState.case!.id) 
        : []
    ]);
    const orderList = await this.orderService.GetOrdersWithParts(this.props.caseSettingsState.case!.id);

    console.log('insurerList', insurerList);

    const [users, clients, orgList, additionTariff, discountTariff] = await Promise.all([
      this.appUserService.GetUsersInfo(
        Array.from(new Set(orderList.map((item) => item.createdBy)))
      ),
      this.organizationService.GetUserOrganizationsByIds(
        Array.from(new Set(orderList.map((item) => item.clientId)))
      ),
      this.organizationService.GetOrganizationsByHO(
        this.props.caseSettingsState.case!.organizationOwnerId,
        null
      ),
      this.caseService.GetTariffPercent({
        workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
        insurerId: this.getInsurerId(insurerList),
        tariffCode: TariffTypeGroupCodeEnum.ADD_PARTS
      } as TariffPercentRequest),
      this.caseService.GetTariffPercent({
        workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
        insurerId: this.getInsurerId(insurerList),
        tariffCode: TariffTypeGroupCodeEnum.PSE_ALT
      } as TariffPercentRequest) 
    ]);

    const unorderedPartsSupplierIds = unorderedParts.map(({ supplierId }) => supplierId);
    const suppliers = suppliersInit.filter(
      (item) => unorderedPartsSupplierIds.includes(item.id) && item.isActive === true
    );

    unorderedParts.forEach((p) => {
      const supplier = suppliersInit.find((item) => item.id === p.supplierId);
      p.supplier = supplier === undefined ? null : supplier;
    });

    orderList.forEach((p) => {
      const supplier = suppliersInit.find((item) => item.id === p.supplierId);
      const user = users.find((item) => item.id === p.createdBy);
      const client = clients.find((item) => item.id === p.clientId);

      p.createdByUser = user === undefined ? null : user;
      p.supplier = supplier === undefined ? null : supplier;
      p.client = client === undefined ? null : client;
    });

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    this.setState({
      orderList: orderList,
      unorderedParts: unorderedParts,
      workflowSuppliers: suppliers,
      currency: currrency,
      isLoading: false,
      hasRights: hasRights,
      organizations: orgList,
      additionTariff: additionTariff,
      discountTariff: discountTariff,
      caseStatusHistory: caseStatusHistory
    });
  };

  onRowClick = async (e: any, id: number) => {
    this.setState({
      isDialogOpen: true,
      orderIds: Array.from([id]),
      automatePlaceOrder: this.props.caseSettingsState.caseSettings!.workflow.automatePlaceOrder
    });
  };

  onSupplierOrderNumberRowClick = async (e: any, supplierName: string) => {
    if (supplierName == SupplierCode.AUGSBURG) {
      window.open('https://www.aicat.ro/csp/berta/portal/index.csp', '_blank');
    }
  };

  updateRow = async (e: any, id: number) => {
    this.setState({
      isUpdateOrderPartDialogOpen: true,
      unorderedPartIds: Array.from([id])
    });
  };

  closeDialog = async () => {
    const unorderedPartsSupplierIds = this.state.unorderedParts.map(({ supplierId }) => supplierId);
    const suppliers = this.state.workflowSuppliers.filter(
      (item) => unorderedPartsSupplierIds.includes(item.id) && item.isActive === true
    );
    this.setState({
      isDialogOpen: false,
      workflowSuppliers: suppliers,
      isLoading: true
    });

    await this.loadData();
  };

  closeAddOrderSupplierDialog = async () => {
    this.setState({ isAddSupplierOrderStatusDialogOpen: false, selectedWorkflowSupplier: null });

    // await this.loadData();
  };

  closeUpdateOrderPartDialog = async () => {
    this.setState({ isUpdateOrderPartDialogOpen: false });

    await this.loadData();
  };

  getInsurerId = (insurerList: IReferential[]) : number  | null => {
    if (R.isNil(this.props.caseSettingsState.case!.caseEvent)) {
      return null;
    }

    if (R.isNil(this.props.caseSettingsState.case!.caseEvent.insurerName)) {
      return null;
    }

    const insurerName = this.props.caseSettingsState.case!.caseEvent.insurerName;
    const insurer = _.find(insurerList, function (insurer) {
      return insurer.displayName.toLowerCase() === insurerName.toLowerCase();
    });
    console.log('insurer', insurer);
    if (R.isNil(insurer)) {
      return null;
    }
    return insurer.id;
  }

  deleteOrder = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.orderService.DeleteOrder(id);

      const partIndex = this.state.orderList.findIndex((item) => item.id === id);
      const newArray = [...this.state.orderList];
      newArray.splice(partIndex, 1);

      this.setState(
        {
          orderList: newArray,
          isLoading: true
        },
        this.loadData
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  DeletePartToBeOrdered = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const partId = tableMeta.rowData[0];
      console.log(partId);
      // await this.orderService.DeletePartToBeOrdered(partId);

      // const partIndex = this.state.unorderedParts.findIndex((item) => item.id === partId);
      // const newArray = [...this.state.unorderedParts];
      // newArray.splice(partIndex, 1);

      // this.setState(
      //   {
      //     unorderedParts: newArray,
      //     isLoading: true
      //   },
      //   this.loadData
      // );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0])}
              >
                <span>{value}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'supplier.name',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_SUPPLIER_LABEL', 'Furnizor'),
        options: {
          filter: false,
          customBodyRender: (value: IReferential, tableMeta: MUIDataTableMeta) => {
            const order = this.state.orderList.find((item) => item.id === tableMeta.rowData[0]);
            if (R.isNil(order)) {
              return '';
            }

            return !R.isNil(order.supplier) ? (order.supplier!.name.toUpperCase() == OwnStockCodeEnum.OWN_STOCK.toString() ? this.translatorService.Tranlate('CALCULATION_DETAILS_OWN_STOCK_HEADER', 'Stoc Propriu') : order.supplier!.name) : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'amount',
        label: this.translatorService.Tranlate('ORDERS_VALUE_LABEL', 'Valoare'),
        options: {
          filter: false,
          setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }),
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            if (this.props.appState.appUser!.organization!.organizationType!.hideOrderPrices) {
              return '';
            }
            return Utils.countryNumberFormat(value.toFixed(2), this.props.appState.appUser?.organization?.country ?? null);
          }
        }
      },
      {
        name: 'currencyCode',
        label: this.translatorService.Tranlate('ORDERS_CURRENCY_LABEL', 'Moneda'),
        options: {
          filter: false
        }
      },
      {
        name: 'createdByUser.userName',
        label: this.translatorService.Tranlate('ORDERS_MADE_BY_LABEL', 'Efectuat De'),
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'orderStatus.displayName',
        label: this.translatorService.Tranlate('ORDERS_STATUS_LABEL', 'Stare'),
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            const id = tableMeta.rowData[0];
            const order = this.state.orderList.find((item) => item.id === id);
            if (order!.orderStatus!.code !== OrderStatusCode.REP_DRAFT) {
              return;
            }
            if (order!.clientId !== this.props.appState.appUser!.organizationId) {
              return '';
            }
            return (
              <IconButton
                onClick={(e) => this.deleteOrder(e, tableMeta)}
                aria-label="delete"
                hidden={!this.state.hasRights}
                color="inherit"
                className="text-danger"
                size="small"
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      },
      {
        name: 'supplierOrderNumber',
        label: this.translatorService.Tranlate('ORDERS_ID_FURNIZOR_LABEL', 'ID Furnizor'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => {
                  this.onSupplierOrderNumberRowClick(e, tableMeta.rowData[1]);
                }}
              >
                <span>{value}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };

  displayUnorderedPartPrice = (value: number, partId: any) => {
    const unorderedPart = this.state.unorderedParts.find((item) => item.id === partId);
    const price =
      unorderedPart!.alternativePartQualityId === null ? value : unorderedPart!.alternativePrice;
    return (Math.round(price! * 100) / 100).toFixed(2) + ' ' + this.state.currency;
  };

  getUnorderedPartsColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'guideNumber',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_CODE_LABEL', 'Cod'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.unorderedParts.find((item) => item.id === tableMeta.rowData[0]);

            if (!isNullOrUndefined(part!.alternativePartQualityId)) {
              return part!.alternativeGuideNumber;
            }

            return part!.guideNumber;
          }
        }
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_NAME_LABEL', 'Denumire'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            const part = this.state.unorderedParts.find((item) => item.id === tableMeta.rowData[0]);

            if (!isNullOrUndefined(part!.alternativePartQualityId)) {
              return part!.alternativePartName;
            }

            return part!.name;
          }
        }
      },
      {
        name: 'producer',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_PRODUCER_LABEL', 'Producator'),
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: 'originalPrice',
        label: this.translatorService.Tranlate(
          'ORDER_PART_DETAILS_ORIGINAL_PRICE_LABEL',
          'Pret unitar'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return Utils.countryNumberFormat(this.displayUnorderedPartPrice(value, tableMeta.rowData[0]), this.props.appState.appUser?.organization?.country ?? null);
          }
        }
      },
      {
        name: 'pieceNr',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_PIECE_NR_LABEL', 'Unitati'),
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: 'supplier.name',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_SUPPLIER_LABEL', 'Furnizor'),
        options: {
          filter: false,
          customBodyRender: (value: IReferential, tableMeta: MUIDataTableMeta) => {
            const unorderedPart = this.state.unorderedParts.find((item) => item.id === tableMeta.rowData[0]);
            if (R.isNil(unorderedPart)) {
              return '';
            }

            return !R.isNil(unorderedPart.supplier) ? (unorderedPart.supplier!.name.toUpperCase() == OwnStockCodeEnum.OWN_STOCK.toString() ? this.translatorService.Tranlate('CALCULATION_DETAILS_OWN_STOCK_HEADER', 'Stoc Propriu') : unorderedPart.supplier!.name) : '';
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'deliveryTime',
        label: this.translatorService.Tranlate('ORDER_PART_DETAILS_DELIVERY_TIME_LABEL', 'Livrare'),
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            const part = this.state.unorderedParts.find((item) => item.id === tableMeta.rowData[0]);
            if (part!.excludeFromOrder) {
              return;
            }
            return (
              <div>
                <IconButton
                  onClick={(e) => this.updateRow(e, tableMeta.rowData[0])}
                  hidden={!this.state.hasRights}
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                >
                  <EditOutlinedIcon />
                </IconButton>
              </div>
            );
          }
        }
      }
      // {
      //   name: '',
      //   options: {
      //     viewColumns: false,
      //     filter: false,
      //     setCellHeaderProps: () => ({ align: 'center' }),
      //     setCellProps: () => ({ align: 'center' }),
      //     customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
      //       return (
      //         <IconButton
      //           onClick={(e) => this.DeletePartToBeOrdered(e, tableMeta)}
      //           aria-label="delete"
      //           hidden={!this.state.hasRights}
      //           color="inherit"
      //           className="text-danger"
      //           size="small"
      //         >
      //           <DeleteOutlineTwoToneIcon />
      //         </IconButton>
      //       );
      //     }
      //   }
      // }
    ];

    return columns;
  };

  addOrder = async () => {
    this.setState({
      isAddSupplierOrderStatusDialogOpen: true
    });
  };

  addOrderSubmit = async () => {
    try {
      this.setState({ executing: true });
      const newOrder = await this.orderService.CreateOrder(
        this.props.caseSettingsState.case!.id,
        this.state.selectedWorkflowSupplier!.id
      );
      newOrder.supplier = this.state.selectedWorkflowSupplier;
      newOrder.createdByUser = this.props.appState.appUser;
      newOrder.client = this.props.appState.appUser!.organization;
      const supplierId = !isNullOrUndefined(this.state.selectedWorkflowSupplier)
        ? this.state.selectedWorkflowSupplier.id
        : null;
      const unorderedPartsBySupplier = this.state.unorderedParts.find(
        (item) => item.supplierId !== supplierId
      );

      this.setState({
        orderList: [...this.state.orderList, newOrder],
        unorderedParts: !isNullOrUndefined(unorderedPartsBySupplier)
          ? [unorderedPartsBySupplier]
          : [],
        orderIds: Array.from([newOrder.id]),
        isDialogOpen: true,
        isAddSupplierOrderStatusDialogOpen: false,
        selectedWorkflowSupplier: null
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
      this.setState({ executing: false });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      this.setState({ executing: false });
    }
  };

  handleAutocompleteSupplierChange = async (newValue: any | null) => {
    this.setState({
      selectedWorkflowSupplier: newValue
    });
  };

  renderAddOrderForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.addOrderSubmit();
        }}
      >
        <div className="text-center m-0">
          <Autocomplete
            id="supplier"
            className="mb-3"
            options={this.state.workflowSuppliers}
            value={this.state.selectedWorkflowSupplier}
            onChange={(e: any, newValue: Organization | null) =>
              this.handleAutocompleteSupplierChange(newValue)
            }
            getOptionLabel={(option: Organization) => option.name.toUpperCase() == OwnStockCodeEnum.OWN_STOCK.toString() ? this.translatorService.Tranlate('CALCULATION_DETAILS_OWN_STOCK_HEADER', 'Stoc Propriu') : option.name || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="supplier"
                value={this.state.selectedWorkflowSupplier}
                label={this.translatorService.Tranlate(
                  'ORDER_PART_DETAILS_SUPPLIER_LABEL',
                  'Furnizor'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
        </div>
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeAddOrderSupplierDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" 
              variant="contained" 
              color="primary" 
              type="submit"
              disabled={this.state.executing}
              >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  };

  orderChange = async (order: Order) => {
    this.setState({
      orderList: this.state.orderList.map((el: Order) =>
        el.id !== order.id
          ? el
          : {
              ...el,
              amount: order.amount,
              amountTVA: order.amountTVA,
              amountWithoutTVA: order.amountWithoutTVA,
              orderParts: [...order.orderParts!],
              orderStatus: order.orderStatus,
              orderStatusId: order.orderStatusId
            }
      )
    });
  };

  handleStockParts = () => {
    const ownerStockOrders = this.state.orderList.filter(x => x.supplier!.name.toUpperCase() == OwnStockCodeEnum.OWN_STOCK.toString());
    if (ownerStockOrders.length > 0) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'HAS_OWN_STOCK_ORDERS',
          'Aveti comenzi pe stoc propriu. Va rugam sa le comandati!'
        ),
        { 
          variant: 'warning',
          autoHideDuration: 5000,
          style: { whiteSpace: 'pre-line' }
        }
      );
    }
  }
  automatePlaceOrderChange = async () => {
    const automatePlaceOrder = this.props.caseSettingsState.caseSettings!.workflow.automatePlaceOrder && this.state.automatePlaceOrder;
    if (automatePlaceOrder) {
      console.log('automatePlaceOrder buton');
      console.log(this.state.caseStatusHistory);
     
      const orderPartStatuses = this.state.caseStatusHistory.filter(x => x.caseStatus!.code === CaseStatusCodeEnum.COM_PI);
      if (orderPartStatuses.length > 1) {
        // Find the last status that does not have one of the excluded codes
        const lastNonExcludedStatus = this.state.caseStatusHistory.find(status =>
          !excludedCodes.includes(status.caseStatus!.code ?? '')
        );
        console.log('Last non-excluded status:', lastNonExcludedStatus);
        
        const caseStatusRequest = {
          caseId: this.props.caseSettingsState.case!.id,
          statusId: lastNonExcludedStatus!.caseStatusId
        } as ChangeCaseStatusRequest;
        const currentStatus = await this.caseService.ChangeCaseStatus(caseStatusRequest);
        this.props.SetCaseStatus(currentStatus);
        this.handleStockParts();
        return;
      }
      
      if (this.props.caseSettingsState.case!.carInService) {
        const request = {
          caseId: this.props.caseSettingsState.case!.id,
          workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
          carInService: true
        } as AutomateAppointmentRequest;

        let newStatus = await this.caseService.CreateAutomateAppointment(request);
        if (newStatus === null) {
          newStatus = this.props.caseSettingsState.case!.caseStatus;
        }

        this.props.SetCaseStatus(newStatus);
        this.handleStockParts();
        return;
      }
      let newStatus = await this.caseService.SubmitForm(this.props.caseSettingsState.case!.id, this.props.workflowForm.id, null);
      if (newStatus === null) {
        newStatus = this.props.caseSettingsState.case!.caseStatus;
      }

      this.props.SetCaseStatus(newStatus);
      this.handleStockParts();
    }
  }

  checkConfirmChanges = async () => {
    if (this.props.caseSettingsState.caseSettings!.workflow.automatePlaceOrder) {
      const firstOrder = _.find(this.state.orderList, function (order) {
        return order.orderStatus!.code == OrderStatusCode.REP_DRAFT;
      });
      if (firstOrder != null) {
        this.setState({
          isDialogOpen: true,
          automatePlaceOrder: true,
          orderIds: Array.from([firstOrder.id])
        });
       return;
      }
    }
    const checkIfExistOrderInDraftStatus = _.filter(this.state.orderList, function (order) {
      return order.orderStatus!.code == OrderStatusCode.REP_DRAFT;
    });
    if (checkIfExistOrderInDraftStatus.length) {
      this.setState({ isOpen: true, dialogVisible: true });
    } else {
      this.handleConfirmChanges();
    }
  };

  handleConfirmChanges = async () => {
    this.handleCloseModal();
    this.setState({ isLoading: true });
    await this.submitForm(this.props.caseSettingsState.case!.id, this.props.workflowForm.id, null);
    this.setState({ isLoading: false });
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    const hasNotUnorderedParts = this.state.unorderedParts.filter(x => !x.isOwnStock).length === 0;
    if (hasNotUnorderedParts) {
      if (this.props.caseSettingsState.caseSettings!.workflow.automatePlaceOrder) {
        console.log(this.state.caseStatusHistory);
        const orderPartStatuses = this.state.caseStatusHistory.filter(x => x.caseStatus!.code === CaseStatusCodeEnum.COM_PI);
        if (orderPartStatuses.length > 1) {
          // Find the last status that does not have one of the excluded codes
          const lastNonExcludedStatus = this.state.caseStatusHistory.find(status =>
            !excludedCodes.includes(status.caseStatus!.code ?? '')
          );
          console.log('Last non-excluded status:', lastNonExcludedStatus);
          
          const caseStatusRequest = {
            caseId: this.props.caseSettingsState.case!.id,
            statusId: lastNonExcludedStatus!.caseStatusId
          } as ChangeCaseStatusRequest;
          const currentStatus = await this.caseService.ChangeCaseStatus(caseStatusRequest);

          this.props.SetCaseStatus(currentStatus);
          this.handleStockParts();
          return;
        }

        if (this.props.caseSettingsState.case!.carInService) {
          const request = {
            caseId: this.props.caseSettingsState.case!.id,
            workflowId: this.props.caseSettingsState.caseSettings!.workflow.id,
            carInService: true
          } as AutomateAppointmentRequest;

          let newStatus = await this.caseService.CreateAutomateAppointment(request);
          if (newStatus === null) {
            newStatus = this.props.caseSettingsState.case!.caseStatus;
          }
  
          this.props.SetCaseStatus(newStatus);
          this.handleStockParts();

          return;
        }
      }

      let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);
      if (newStatus === null) {
        newStatus = this.props.caseSettingsState.case!.caseStatus;
      }

      this.props.SetCaseStatus(newStatus);

      const hasRights = FormHelpers.HasRights(
        this.props.workflowForm.workflowFormPermissions,
        this.props.appState.appUser!,
        newStatus.caseStatusId
      );

      this.handleStockParts();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
      this.setState({ hasRights: hasRights });
    } else {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'ORDERS_UNORDERED_PARTS_INVALID',
          'Aveti piese necomandate'
        ),
        { variant: 'error' }
      );
    }
  };
  handleCloseConfirmationDialog = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.orderService = (this.context as AppContext).orderService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      sort: false,
      search: false,
      pagination: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      elevation: 0,
      textLabels: MUITranslations.GetTranslations(this.translatorService)
    };

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
          <Dialog open={this.state.dialogVisible} onClose={this.handleCloseConfirmationDialog}>
            <DialogContent>
              <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {`${this.translatorService.Tranlate(
                  'ORDER_CONFIRMATION_MESSAGE',
                  'Aveti piese necomandate, doriti sa treceti in pasul urmator?'
                )} `}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => this.handleConfirmChanges()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
              </Button>
              <Button onClick={() => this.handleCloseConfirmationDialog()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
              </Button>
            </DialogActions>
          </Dialog>
        </Modal>
        {!this.state.isLoading ? (
          <div>
            <div className="d-flex align-items-center justify-content-between mt-2 bg-primary p-1 border-2">
              <div className="text-white font-weight-bold">
                <span>{this.translatorService.Tranlate('ORDERS_LIST_TITLE', 'Lista comenzi')}</span>
              </div>
              {
                this.state.unorderedParts.length > 0 ? (
                  <Button   
                    color="primary" 
                    size="small"
                    style={{
                      'fontSize': '14px', 
                      'border': '1px solid', 
                      'background': '#dd4313'
                    }}
                    className="text-white font-weight-bold" 
                    disabled={!this.state.hasRights}
                    onClick={(e) => this.addOrder()}
                  >
                    {this.translatorService.Tranlate('ORDER_ADD_LABEL', 'Adauga comanda')}
                  </Button> 
                ) : null
              }
            </div>
            <div className="m-1">
              <MUIDataTable
                title={''}
                data={this.state.orderList}
                columns={this.getColumns()}
                options={options}
              />
            </div>
          </div>
        ) : null}
        {
          this.state.unorderedParts.length > 0 ? (
            <div>
              <div className="d-flex align-items-center justify-content-between mt-2 bg-primary p-1 border-2">
                <div className="text-white font-weight-bold">
                  <span>
                    {this.translatorService.Tranlate(
                      'ORDERS_UNORDERED_PARTS_TITLE',
                      'Lista piese de comandat'
                    )}
                  </span>
                </div>
              </div>
              <div className="m-1">
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
                </div>
                {!this.state.isLoading ? (
                  <MUIDataTable
                    title={''}
                    data={this.state.unorderedParts}
                    columns={this.getUnorderedPartsColumns()}
                    options={options}
                  />
                ) : null}
              </div>
            </div>
          ) : null
        }
        <div className="text-center">
          <Button
            className="m-2"
            variant="contained"
            color="primary"
            onClick={this.checkConfirmChanges}
            disabled={!this.state.hasRights || this.state.isLoading || !this.state.orderList.length}
          >
            {this.props.caseSettingsState?.caseSettings!.workflow?.isCarfixIntegration && this.props.caseSettingsState.caseSettings!.workflow.automatePlaceOrder ? 
              this.translatorService.Tranlate('ORDER_PART_PLACE_ORDER_LABEL', 'Lanseaza Comanda') : this.translatorService.Tranlate('CASE_CAR_CALCULATIONS_CONFIRM_BUTTON', 'Confirma')}
          </Button>
        </div>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
          >
            <span style={{ fontSize: '20px', lineHeight: '24px' }}>
              {this.translatorService.Tranlate('ORDERS_DIALOG_TITLE', 'Detalii comanda')}
            </span>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <OrderDetails
              {...this.props}
              orderDetails={
                this.state.orderList.find((item) => item.id === this.state.orderIds[0])!
              }
              onOrderChange={this.orderChange}
              automatePlaceOrderChange={this.automatePlaceOrderChange}
              hasRights={this.state.hasRights}
              additionTariff={this.state.additionTariff}
              discountTariff={this.state.discountTariff}
              automatePlaceOrder={this.state.automatePlaceOrder}
              workflowAutomatePlaceOrder={this.props.caseSettingsState.caseSettings!.workflow.automatePlaceOrder}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={this.closeAddOrderSupplierDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isAddSupplierOrderStatusDialogOpen}
          fullScreen={false}
          disableBackdropClick
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
          >
            <span>{this.translatorService.Tranlate('ORDER_ADD_LABEL', 'Adauga comanda')}</span>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeAddOrderSupplierDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="p-3" dividers style={{ backgroundColor: '#fafafa' }}>
            {this.renderAddOrderForm()}
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={this.closeUpdateOrderPartDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isUpdateOrderPartDialogOpen}
          fullScreen={true}
          disableBackdropClick
          fullWidth={true}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ color: '#ffffff', backgroundColor: '#1F2857', textAlign: 'center' }}
          >
            <span style={{ fontSize: '20px', lineHeight: '24px' }}>
              {this.translatorService.Tranlate(
                'ORDER_CHANGE_PART_DETAILS_TITLE',
                'Modifica date piesa'
              )}
            </span>
            <IconButton
              aria-label="close"
              className="position-absolute"
              style={{ right: 12, top: 12, color: '#ffffff' }}
              onClick={this.closeUpdateOrderPartDialog}
              size={'small'}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <UpdateOrderPart
              {...this.props}
              partDetails={
                this.state.unorderedParts.find(
                  (item) => item.id === this.state.unorderedPartIds[0]
                )!
              }
              currency={this.state.currency}
              workflowId={this.props.caseSettingsState.caseSettings!.workflow.id}
              workflowSuppliersList={
                this.props.caseSettingsState.caseSettings!.workflow.workflowSuppliers
              }
              onSave={this.closeUpdateOrderPartDialog}
              additionTariff={this.state.additionTariff}
              discountTariff={this.state.discountTariff}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabOrderFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabOrderForm as any));
