import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { OrganizationCaseType } from '../../../../interfaces/Organization';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import { Box, Button, IconButton, Card, CardContent } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../../interfaces/IReferential';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import Referentials from '../../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface IOrganizationCaseState {
  organizationCases: OrganizationCaseType[];
  isLoading: boolean;
  organizationCaseTypes: IReferential[];
  selectedOrganizationCaseType: IReferential | null;
  organizationId: number;
}

type IOrganizationCaseProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class OrganizationCaseTypeTab extends React.PureComponent<
  IOrganizationCaseProps,
  IOrganizationCaseState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    organizationCases: [],
    isLoading: false,
    organizationCaseTypes: [],
    selectedOrganizationCaseType: null,
    organizationId: 0
  } as IOrganizationCaseState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationCases();
      }
    );
  }

  loadOrganizationCases = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }
    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseType);

    const [orgCaseList, orgCaseTypes] = await Promise.all([
      this.organizationService.GetOrganizationCaseType(hoId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    let organizationCases = orgCaseList.sort(function (a, b) {
      return a.order - b.order;
    });
    organizationCases = organizationCases.map((item: OrganizationCaseType) => {
      item.caseType = orgCaseTypes.find(
        (caseType: IReferential) => caseType.id === item.caseTypeId
      )!;
      return item;
    });

    this.setState({
      organizationCases: organizationCases,
      organizationCaseTypes: orgCaseTypes.filter((item) => item.isActive === true),
      isLoading: false,
      organizationId: hoId
    });
  };

  deleteOrgCase = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.organizationService.RemoveOrganizationCaseType(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.loadOrganizationCases();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveUp = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.organizationCases];
    const itemToMoveUpIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveUpIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveUpIndex].order === 1) {
      return;
    }

    const itemToMoveDownIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveUpIndex].order - 1
    );

    cloneArray[itemToMoveUpIndex] = {
      ...cloneArray[itemToMoveUpIndex],
      order: cloneArray[itemToMoveUpIndex].order - 1
    };
    cloneArray[itemToMoveDownIndex] = {
      ...cloneArray[itemToMoveDownIndex],
      order: cloneArray[itemToMoveDownIndex].order + 1
    };

    try {
      await this.organizationService.UpdateOrganizationCaseType(cloneArray[itemToMoveUpIndex]);
      await this.organizationService.UpdateOrganizationCaseType(cloneArray[itemToMoveDownIndex]);

      this.setState({
        organizationCases: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  moveDown = async (event: any, tableMeta: MUIDataTableMeta) => {
    const id = tableMeta.rowData[0];
    const cloneArray = [...this.state.organizationCases];
    const itemToMoveIndex = cloneArray.findIndex((item) => item.id === id);

    if (itemToMoveIndex === -1) {
      return;
    }
    if (cloneArray[itemToMoveIndex].order === cloneArray.length) {
      return;
    }

    const itemToMoveToIndex = cloneArray.findIndex(
      (item) => item.order === cloneArray[itemToMoveIndex].order + 1
    );

    cloneArray[itemToMoveIndex] = {
      ...cloneArray[itemToMoveIndex],
      order: cloneArray[itemToMoveIndex].order + 1
    };
    cloneArray[itemToMoveToIndex] = {
      ...cloneArray[itemToMoveToIndex],
      order: cloneArray[itemToMoveToIndex].order - 1
    };

    try {
      await this.organizationService.UpdateOrganizationCaseType(cloneArray[itemToMoveIndex]);
      await this.organizationService.UpdateOrganizationCaseType(cloneArray[itemToMoveToIndex]);

      this.setState({
        organizationCases: cloneArray.sort(function (a, b) {
          return a.order - b.order;
        })
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'caseType',
        label: this.translatorService.Tranlate('ORGANIZATIONS_CASE_TYPE_HEADER', 'Tip dosar'),
        options: {
          sort: false,
          customBodyRender: (value: IReferential) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'order',
        sortDirection: 'asc',
        label: this.translatorService.Tranlate('ORGANIZATION_CASE_ORDER_HEADER', 'Ord'),
        options: { sort: false }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          sortOrder: {
            name: 'order',
            direction: 'asc'
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Fragment>
                <IconButton color="inherit" size="small" onClick={(e) => this.moveUp(e, tableMeta)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => this.moveDown(e, tableMeta)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Fragment>
            );
          }
        }
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteOrgCase(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addOrgCase = async () => {
    try {
      await this.organizationService.AddOrganizationCaseType({
        order: this.state.organizationCases.length + 1,
        caseTypeId: this.state.selectedOrganizationCaseType!.id,
        organizationId: this.state.organizationId
      } as OrganizationCaseType);

      this.setState(
        {
          isLoading: true,
          selectedOrganizationCaseType: null
        },
        () => {
          this.loadOrganizationCases();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteChange = (newValue: IReferential | null) => {
    this.setState({ selectedOrganizationCaseType: newValue });
  };

  public renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrgCase}>
              <Autocomplete
                id="caseTypes"
                className="m-2"
                options={this.state.organizationCaseTypes}
                value={this.state.selectedOrganizationCaseType}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleAutocompleteChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationCaseTypes"
                    value={this.state.selectedOrganizationCaseType}
                    label={this.translatorService.Tranlate(
                      'ORGANIZATION_CASE_TYPE_LABEL',
                      'Tip dosar'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizationCases}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationCaseTypeTab as any));
