import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Tooltip,
  Card,
  CardContent,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  Radio,
  Divider,
  Box,
  CircularProgress
} from '@material-ui/core';
import { isUndefined, rest } from 'lodash';

import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import {
  CarClassRentPrice,
  CaseCalculationAlternativePart,
  CaseCalculationHistory,
  CaseCalculationPart,
  CaseVehicleHistory,
  ChangeCaseStatusRequest,
  ManipulationTariffPercentResponse,
  Offer,
  OfferLine,
  OfferPart,
  OriginalPart,
  PartNotifySuppportRequest,
  TariffPercentResponse,
  VehicleDto
} from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import {
  ReferentialCode,
  ParametreCode,
  CarPartQualityRatings,
  CalculationSectionCodeEnum,
  AlternativeCode,
  AdditionalInfoCode,
  CaseStatusCodeEnum,
  MakeExceptionEnum,
  MakeException,
  OriginalPartShortNumberException,
  SupplierCode,
  OrganizationTypeCode
} from '../../helpers/Constants';
import { IReferential } from '../../interfaces/IReferential';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import { Organization, OrganizationOilMappingRequest } from '../../interfaces/Organization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { WorkflowCalculation, WorkflowPartner, WorkflowSupplier } from '../../interfaces/Workflow';
import * as XLSX from 'xlsx';
import {
  ImportAlternativePartsFileHeader,
  ImportOriginalPartsFileHeader
} from '../../helpers/Constants';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import * as R from 'ramda';
import { FormCode } from '../../helpers/forms/FormCode';
import { CalculationRequest } from '../../interfaces/GtService';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import _ from 'lodash';
import { Order, OrderPart, PartToBeOrdered } from '../../interfaces/Order';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { isNullOrUndefined } from 'util';
import { v4 as uuid } from 'uuid';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import { Utils } from '../../helpers/Utils';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';

export interface ExternalCaseDetailsAlternativeCalculationPiecesProps {
  calculationHistoryId: number;
  workflowCalculation: WorkflowCalculation | undefined;
  handleUpdateAlternativeCalcAmount: (calculationParts: CaseCalculationPart[]) => void;
  defaultMaxDeliveryTime: number;
  optmizationType: number;
  resetOptimizatonType: () => void;
  onSave: () => void;
  orderList: Order[];
  manipulationTariff: ManipulationTariffPercentResponse;
  additionTariff: TariffPercentResponse;
  insurerList: IReferential[];
  serviceId: number | null;
  discountTariff: TariffPercentResponse;
  packageCalculationId: number | null;
  nonStockSuppliers: WorkflowSupplier[];
}

interface ICaseDetailsAlternativeCalculationPiecesState {
  caseCalculationHistoryDetails: CaseCalculationHistory;
  calculationParts: CaseCalculationPart[];
  newCalculationPart: CaseCalculationPart | null;
  newCalculationPartId: number | null;
  partQualityTypes: IReferential[];
  supplierList: Organization[];
  isDialogOpen: boolean;
  isAddOriginalPartDialogOpen: boolean;
  isLoading: boolean;
  currentSection: IReferential | null;
  TVA: string;
  modified: boolean;
  selectedAlternativePartValue: number;
  caseCalculationAlternativePart: CaseCalculationAlternativePart[];
  newCaseCalculationAlternativePart: CaseCalculationAlternativePart | null;
  clearStateAlternativeParts: boolean;
  calculationPartFormTypeAdd: boolean;
  partLoaders: boolean[];
  partSection: IReferential | null;
  isDialogModifiedOpen: boolean;
  isConfirm: boolean;
  selectedOrganizationBranch: OrganizationBranch | null;
  organizationBranches: OrganizationBranch[];
  currentWorkflowContainsOrderFormOrInterogatePartner: boolean;
  isDialogOpenConfirmationOrgPartner: boolean;
  orderPartList: OrderPart[];
  calculationPartsOrdered: CaseCalculationPart[];
  isDialogOpenFunctionToBeCalled: GenericVoidFunc | null;
  caseStatuses: IReferential[];
  isDialogOpenOptimConfirmation: boolean;
  caseCalculationAlternativePartCopy:  CaseCalculationAlternativePart[];
  includeChild: boolean;
  carClassRentPrice: CarClassRentPrice | null;
  interogateParts: boolean[];
  hasBillingForm: boolean;
  rentVehiclePrices: VehicleDto | null;
  ownStockOrganization: Organization | null;
  packageCalculationParts: CaseCalculationPart[];
  executing: boolean;
  isNotifySupportDialogOpen: boolean;
  sendNotificationToSupportLoading: boolean;
}

interface OrganizationBranch {
  id: number;
  organizationId: number;
  organizationName: string;
  organizationAddress: string;
  children: OrganizationBranch[];
  parentId: number | null;
  path: string | null;
  label: string;
  checked: boolean | null;
}
type GenericVoidFunc = (...args: any[]) => void;

type ICaseDetailsAlternativeCalculationPiecesProps =
  ExternalCaseDetailsAlternativeCalculationPiecesProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsAlternativeCalculationPieces extends React.PureComponent<
  ICaseDetailsAlternativeCalculationPiecesProps,
  ICaseDetailsAlternativeCalculationPiecesState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private appuserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private orderService!: IOrderService;

  static contextType = ApplicationContext;
  state = {
    partSection: null,
    caseCalculationHistoryDetails: {} as CaseCalculationHistory,
    caseVehicle: {} as CaseVehicleHistory,
    calculationParts: [],
    newCalculationPart: null,
    newCalculationPartId: null,
    partQualityTypes: [],
    supplierList: [],
    isDialogOpen: false,
    isAddOriginalPartDialogOpen: false,
    isLoading: true,
    currentSection: null,
    TVA: '',
    modified: false,
    selectedAlternativePartValue: 9999999999999,
    caseCalculationAlternativePart: [],
    newCaseCalculationAlternativePart: null,
    clearStateAlternativeParts: false,
    calculationPartFormTypeAdd: true,
    partLoaders: [],
    isDialogModifiedOpen: false,
    isConfirm: false,
    selectedOrganizationBranch: null,
    organizationBranches: [],
    currentWorkflowContainsOrderFormOrInterogatePartner: false,
    isDialogOpenConfirmationOrgPartner: false,
    isDialogOpenFunctionToBeCalled: null,
    orderPartList: [],
    calculationPartsOrdered: [],
    caseStatuses: [],
    isDialogOpenOptimConfirmation: false,
    caseCalculationAlternativePartCopy: [],
    includeChild: false,
    carClassRentPrice: null,
    interogateParts: [],
    hasBillingForm: false,
    rentVehiclePrices: null,
    ownStockOrganization: null,
    packageCalculationParts: [],
    executing: false,
    isNotifySupportDialogOpen: false,
    sendNotificationToSupportLoading: false
  } as ICaseDetailsAlternativeCalculationPiecesState;
  rowRefs: { [key: number]: React.RefObject<HTMLTableRowElement> } = {};

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (caseSettings === null || caseSettings === undefined || Number.isNaN(caseId)) {
      return;
    }
    console.log('nonStockSuppliers', this.props.nonStockSuppliers);

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadPiecesDetails();
      }
    );
  }

  componentDidUpdate(prevProps: ICaseDetailsAlternativeCalculationPiecesProps) {
    if (this.props.optmizationType === 0) {
      return;
    }
    if (this.props.optmizationType !== prevProps.optmizationType) {
      this.filterAlternativePartsByOptimization();
      this.props.resetOptimizatonType();
    }
  }

  getInsurerId = () : number  | null => {
    if (isNullOrUndefined(this.props.workflowCalculation)) {
     return null;
    }
    
    if (R.isNil(this.props.caseSettingsState.case!.caseEvent)) {
      return null;
    }

    if (R.isNil(this.props.caseSettingsState.case!.caseEvent.insurerName)) {
      return null;
    }

    const insurerName = this.props.caseSettingsState.case!.caseEvent.insurerName;
    console.log('insurerName', insurerName);
    const insurer = _.find(this.props.insurerList, function (insurer) {
      return insurer.displayName.toLowerCase() === insurerName.toLowerCase();
    });
    console.log('insurer', insurer);
    if (R.isNil(insurer)) {
      return null;
    }
    return insurer.id;
  }

  loadPiecesDetails = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (caseSettings === null || caseSettings === undefined || Number.isNaN(caseId)) {
      return;
    }
    const casee = this.props.caseSettingsState.case;
    const TVA = this.props.caseSettingsState.case!.caseParameters.find(
      (item) => item.parameter!.code === ParametreCode.TVA
    )!.value;

    if (casee === null) {
      return;
    }

    const refQualityPart = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarPartCategory
    );
    const currencyRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Currency
    );
    const refCaseStatus = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseStatus
    );

    const orderList = this.props.orderList;
    const orderPartList: OrderPart[] = [];
    orderList.forEach((order) => {
      if (order.orderParts?.length) {
        order.orderParts.forEach((orderPart) => {
          if (!orderPart.isDeleted) {
            orderPartList.push(orderPart);
          }
        });
      }
    });
    console.log('orderList', orderList);
    console.log('orderPartList', orderPartList);
    const [
      currencyLIst,
      caseCalculation,
      caseCalculationParts,
      partQualityTypes,
      sections,
      caseStatuses,
      ownStockOrganization,
      packageCalculationParts
    ] = await Promise.all([
      this.appReferentialService.Get(currencyRef!.baseUrl),
      this.props.calculationHistoryId
        ? this.caseService.GetCaseCalculationHistoryDetails(this.props.calculationHistoryId)
        : ({} as CaseCalculationHistory),
      this.props.calculationHistoryId
        ? this.caseService.GetCaseCalculationParts(this.props.calculationHistoryId)
        : ([] as CaseCalculationPart[]),
      this.appReferentialService.Get(refQualityPart!.baseUrl),
      this.caseService.GetCalculationSections(),
      this.appReferentialService.Get(refCaseStatus!.baseUrl),
      this.organizationService.GetSupplierStockOrganization(OrganizationTypeCode.SUPPLIER),
      this.props.packageCalculationId
      ? this.caseService.GetCaseCalculationParts(this.props.packageCalculationId)
      : ([] as CaseCalculationPart[]),
    ]);

    console.log('packageCalculationParts', packageCalculationParts);
    let carClassRentPrices: CarClassRentPrice[] = [];
    let rentVehiclePrices : VehicleDto[] = [];
    if (!R.isNil(casee) && !R.isNil(casee.caseVehicle)) {
      const carClassId = casee.caseVehicle.carClassId;
      if (!R.isNil(carClassId)) {
        carClassRentPrices = await this.caseService.GetCarClassRentPriceByClassId(carClassId);
      }

      //verify if is PRC integration
      if (this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration) {
        rentVehiclePrices = await this.caseService.FleetSearchVehicle(casee.caseVehicle.vin!, casee.caseVehicle.modelId!);
        console.log('rentVehiclePrices', rentVehiclePrices);
        //Check if the vehicle or the model class is found in the fleet
        if (!rentVehiclePrices.length) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('NO_CAR_CLASS_FOUNDED_IN_FLEET', 'Nu s-a gasit masina/clasa in flota!'), {
            variant: 'warning'
          });
        } 
      }
      
      const brand = VehicleHelper.GetBrand(
        this.props.caseSettingsState.case,
        null
      );
      console.log('brand', brand);
      console.log('MakeException', MakeException);
      if (MakeException.includes(brand.toUpperCase())) {
        for (const cp of caseCalculationParts) {
          if (cp.calculationSection!.code === CalculationSectionCodeEnum.PART) {
            const trimmedNumber = cp.guideNumber.replace(/^0+/, '');
            console.log('before', cp.guideNumber);
            console.log('after', trimmedNumber);
            cp.guideNumber = _.cloneDeep(trimmedNumber);
          }
        }
      }
    }

    if (this.props.caseSettingsState.caseSettings!.workflow.useRoundUp) {
      for (const cp of caseCalculationParts) {
        if (cp.calculationSection!.code === CalculationSectionCodeEnum.PART) {
          if (OriginalPartShortNumberException.includes(cp.repairMethod)) {
            const result = Math.ceil(cp.pieceNr);
            console.log('before', cp.pieceNr);
            console.log('after', result);
            cp.pieceNr = _.cloneDeep(result);
          }
        }
      }
    }
    const workflowSuppliersList =
      this.props.caseSettingsState.caseSettings!.workflow.workflowSuppliers;
    caseCalculation.currency = currencyLIst.find(
      (item) => item.code === caseCalculation.currencyCode
    )!;

    const caseCalculationPartsCopy = _.cloneDeep(caseCalculationParts);
    const calculationPartsOrdered: CaseCalculationPart[] = [];
    const packageCalculationId = this.props.packageCalculationId;
    console.log('caseCalculationParts before', caseCalculationParts);
    for (let i = 0; i < caseCalculationPartsCopy.length; i++) {
      if (caseCalculationPartsCopy[i].calculationSection!.code === CalculationSectionCodeEnum.PART) {
        if (this.props.packageCalculationId) {
          caseCalculationPartsCopy[i].isNew = true;
        }
        const orderPart = _.find(orderPartList, function (orderPart) {
          return (
            (orderPart.guideNumber === caseCalculationParts[i].guideNumber && orderPart.name === caseCalculationParts[i].name)
            ||
            (packageCalculationId != null && OriginalPartShortNumberException.includes(caseCalculationParts[i].repairMethod) && orderPart.repairMethod === caseCalculationParts[i].repairMethod)
          );
        });

        if (orderPart) {
          caseCalculationPartsCopy[i].isOrdered = true;
          caseCalculationPartsCopy[i].alternativeGuideNumber = orderPart.alternativeGuideNumber;
          caseCalculationPartsCopy[i].alternativePartCode = orderPart.alternativePartCode;
          caseCalculationPartsCopy[i].alternativePartDiscount = orderPart.alternativePartDiscount;
          caseCalculationPartsCopy[i].alternativePartName = orderPart.alternativePartName;
          caseCalculationPartsCopy[i].alternativePartQualityId = orderPart.alternativePartQualityId;
          caseCalculationPartsCopy[i].alternativePartRemarks = orderPart.alternativePartRemarks;
          caseCalculationPartsCopy[i].alternativePartStockLocation =
            orderPart.alternativePartStockLocation;
          caseCalculationPartsCopy[i].alternativePartStockQuantity =
            orderPart.alternativePartStockQuantity;
          caseCalculationPartsCopy[i].alternativePrice = orderPart.alternativePrice;
          caseCalculationPartsCopy[i].alternativePartDiscount = orderPart.alternativePartDiscount;
          caseCalculationPartsCopy[i].carPartCategoryId = orderPart.carPartCategoryId;
          caseCalculationPartsCopy[i].codeType = orderPart.codeType;
          caseCalculationPartsCopy[i].deliveryTime = orderPart.deliveryTime?.toString() ?? '';
          caseCalculationPartsCopy[i].discount = orderPart.discount ?? 0;
          caseCalculationPartsCopy[i].excludeFromOrder = orderPart.excludeFromOrder;
          caseCalculationPartsCopy[i].guideNumber = orderPart.guideNumber;
          caseCalculationPartsCopy[i].name = orderPart.name;
          caseCalculationPartsCopy[i].originalPrice = orderPart.originalPrice;
          caseCalculationPartsCopy[i].pieceNr = orderPart.pieceNr;
          caseCalculationPartsCopy[i].producer = orderPart.producer;
          caseCalculationPartsCopy[i].remarks = orderPart.remarks;
          caseCalculationPartsCopy[i].supplierId = orderPart.supplierId;
          caseCalculationPartsCopy[i].type = orderPart.type;
          caseCalculationPartsCopy[i].validityTime = orderPart.validityTime
            ? orderPart.validityTime.toString()
            : null;
          caseCalculationPartsCopy[i].orderCode = orderPart.orderCode;
          caseCalculationPartsCopy[i].locationId = orderPart.locationId;
          caseCalculationPartsCopy[i].productId = orderPart.productId;
          caseCalculationPartsCopy[i].codEan = orderPart.codEan;
          calculationPartsOrdered.push(caseCalculationPartsCopy[i]);
        } else {
          if (orderPartList.length > 0) { 
            caseCalculationPartsCopy[i].isNew = true;
          }
        }
      }
    }

    console.log('after', caseCalculationPartsCopy);
    const usr = caseCalculation.userId
      ? await this.appuserService.GetUserInfo(caseCalculation.userId)
      : this.props.appState.appUser;

    caseCalculation.user = usr;

    const suppliers =
      workflowSuppliersList.length > 0
        ? await this.organizationService.GetUserOrganizationsByIds(
            Array.from(new Set(workflowSuppliersList.map((item) => item.supplierId)))
          )
        : [];
    const partLoaders: boolean[] = [];
    const interogateParts: boolean[] = [];
    caseCalculationPartsCopy
      .filter((item) => item.calculationSection!.code === CalculationSectionCodeEnum.PART)
      .forEach((item) => {
        partLoaders.push(false);
        if (!item.isOrdered) {
          interogateParts.push(false);
        }
      });

    let workflowPartners = [] as OrganizationBranch[];
    let currentWorkflowPartner = {} as OrganizationBranch | null;

    const workflowSectionStepForms =
      this.props.caseSettingsState.caseSettings?.workflow.workflowSections
        ?.map((x) => x.workflowSectionSteps)
        .reduce((acc, val) => acc.concat(val), [])
        .map((x) => x.workflowSectionStepForms)
        .reduce((acc, val) => acc.concat(val), []) || [];
    const currentWorkflowContainsOrderFormOrInterogatePartner =
      workflowSectionStepForms.some((x) => x.form.code === FormCode.CASE_ORDER) &&
      (this.props.workflowCalculation?.interogatePartner ?? false);

    const hasBillingForm = workflowSectionStepForms.some(
      (x) => x.form.code === FormCode.CASE_EVENT_DATE_FACT
    );

    if (
      this.props.caseSettingsState.caseSettings?.workflow.workflowPartners &&
      this.props.caseSettingsState.caseSettings?.workflow.workflowPartners.length > 0
    ) {
      const partnerOrganizations =
        this.props.caseSettingsState.caseSettings?.workflow.workflowPartners.map((x) => x.partner);
      for (const x of partnerOrganizations) {
        workflowPartners.push({
          id: x!.id,
          organizationId: x!.id,
          organizationName: x!.name,
          organizationAddress: x!.address,
          children: [],
          parentId: x!.parentId,
          path: null,
          label: x?.name + (x?.address ? ' ' + x?.address : ''),
          checked: false
        });
      }
    }

    const parentIds = workflowPartners.filter((x) => !x.parentId).map((x) => x.id);

    const organizationHierarchy = await this.organizationService.GetOrganizationDescendents(
      parentIds
    );

    const organizationBranches =
      organizationHierarchy.map((x) => {
        return {
          id: x.id,
          organizationId: x.id,
          organizationName: x.name,
          organizationAddress: x.address,
          children: [],
          parentId: x.parentId,
          path: null,
          label: x.name + (x.address ? ' ' + x.address : ''),
          checked: false
        } as OrganizationBranch;
      }) || [];

    organizationBranches.forEach((x) => {
      workflowPartners.push(x);
    });

    let deliveryOrganizationId: number | null | undefined;
    if (!isNullOrUndefined(this.props.caseSettingsState.case!.caseEvent)) {
      deliveryOrganizationId = this.props.caseSettingsState.case!.caseEvent!.deliveryOrganizationId;
    } else {
      deliveryOrganizationId = this.props.appState.appUser?.organizationId;
    }
    
    console.log('deliveryOrganizationId', deliveryOrganizationId);

    if (currentWorkflowContainsOrderFormOrInterogatePartner) {
      const arrMap = workflowPartners.reduce((acc: any, el: any, i) => {
        acc[el.id] = i;
        return acc;
      }, {});

      const roots = [] as OrganizationBranch[];

      // Push each element to parent's children array
      workflowPartners.forEach((el) => {
        if (el.parentId === null || arrMap[el.parentId] == null) {
          roots.push(el);
        } else {
          workflowPartners[arrMap[el.parentId]].children.push(el);
        }
      });

      currentWorkflowPartner =
        roots.find((x) => x.organizationId === deliveryOrganizationId) || null;
      workflowPartners = roots;
      this.assignObjectPaths(workflowPartners, '');
      if (currentWorkflowPartner) {
        const selectedItemFromTree = this.findRecursive(
          workflowPartners,
          currentWorkflowPartner?.organizationId
        );
        if (selectedItemFromTree) {
          selectedItemFromTree.checked = true;
        }
      }
    } else {
      currentWorkflowPartner =
        organizationBranches.find(
          (x) => x.organizationId === deliveryOrganizationId
        ) || null;
    }
    if (currentWorkflowPartner == null) {
      currentWorkflowPartner =
        organizationBranches.find(
          (x) => x.organizationId === deliveryOrganizationId
        ) || null;
      if (currentWorkflowPartner != null) {
        currentWorkflowPartner.checked = true;
      }
    }

    console.log('currentWorkflowPartner', currentWorkflowPartner);
    console.log('workflowPartners', workflowPartners);
    console.log('carClassRentPrices', carClassRentPrices);
    this.setState({
      caseCalculationHistoryDetails: caseCalculation,
      calculationParts: caseCalculationPartsCopy,
      partQualityTypes: partQualityTypes.filter((item) => item.isActive === true),
      TVA: TVA,
      supplierList: suppliers.filter((ws) => ws.isActive === true),
      isLoading: false,
      partLoaders: partLoaders,
      partSection: sections.find((item) => item.code === CalculationSectionCodeEnum.PART)!,
      selectedOrganizationBranch: currentWorkflowPartner || null,
      organizationBranches: workflowPartners || [],
      currentWorkflowContainsOrderFormOrInterogatePartner:
        currentWorkflowContainsOrderFormOrInterogatePartner,
      calculationPartsOrdered: calculationPartsOrdered,
      caseStatuses: caseStatuses,
      carClassRentPrice: carClassRentPrices.length ? carClassRentPrices[0] : null,
      interogateParts: interogateParts,
      hasBillingForm: hasBillingForm,
      rentVehiclePrices: rentVehiclePrices.length ? rentVehiclePrices[0] : null,
      ownStockOrganization: ownStockOrganization,
      packageCalculationParts: packageCalculationParts,
      orderPartList: orderPartList
    }, () => {
      this.checkPackageNotification();
    });
  };

  assignObjectPaths = (obj: any, stack: any) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (node && typeof node === 'object' && !Array.isArray(node)) {
        node.path = stack ? `${stack}.${k}` : k;
        this.assignObjectPaths(node, node.path);
      }
    });
  };

  isDialogOpenOptimizeConfirmationHandler = () => {
    const decimalError = this.validateOrginalParts();
    console.log('Decimal Error: ', decimalError);
    if (decimalError) {
      return;
    }
    
    const hasChildItem =  [...this.state.caseCalculationAlternativePartCopy].find(caseCalculationAlternativePart => caseCalculationAlternativePart.isChild);
    console.log('Has Child Items', hasChildItem);
    if (hasChildItem) {
      this.setState({
        isDialogOpenOptimConfirmation: true
      });
      return;
    } else {
      this.setState({
        isDialogOpenOptimConfirmation: false
      });
      this.optimizeAllParts(true);
      return;
    }
  };

  checkIfOptimIsPossible = () : boolean => {
    return _.findIndex(this.state.interogateParts, function(el) {
      return !el;
    }) != -1 ? false : true;
  }

  getParentParts = (originalPartId: number) => {
    //Toate piesele alternative
    const groupedParts =  _.filter(
      [...this.state.caseCalculationAlternativePartCopy],
      function (object) {
        return object.originalPartId === originalPartId;
      }
    );

    //Toate piesele alternative care nu sunt copiii
    const parentParts = _.filter(_.cloneDeep(groupedParts),  function (object) {
      return !object.isChild && object.formatedDeliveryTime != null;
    });
    const excludedParentParts = _.filter(_.cloneDeep(groupedParts),  function (object) {
      return !object.isChild && object.formatedDeliveryTime == null;
    });

    console.log(groupedParts);
    console.log('Before: ', parentParts);
    console.log('Piese altenative excluse din cauza ca au formatedDeliveryTime = null:', excludedParentParts);

    if (this.state.includeChild) {
      for(let i = 0; i < parentParts.length; i++) {
        //Toti copiii asociati unei piese alternative parinte
        const childParts = _.filter(groupedParts, function (object) {
          return (
            object.isChild &&
            object.bundleNo != null &&
            object.bundleNo == parentParts[i].bundleNo &&
            object.alternativePartCode !=
            parentParts[i].alternativePartCode
          );
        });
        parentParts[i].alternativePrice = parentParts[i].alternativePrice + _.cloneDeep(_.sumBy(childParts, 'alternativePrice'));
      }
    }
    console.log('After: ', parentParts);

    return parentParts;
  }

  optimize = async () => {
    this.setState(
      {
        partLoaders: this.state.partLoaders.map(() => true)
      },
      async () => {
        const offerLines : OfferLine[] = [];

        const parts = this.state.calculationParts.filter(
          (item) => item.calculationSectionId === this.state.partSection!.id
        );
        for (const cp of parts) {  
          if (cp.calculationSectionId === this.state.partSection!.id) {
            const parentParts = this.getParentParts(cp.id);
            if (parentParts.length == 0) {
              console.log('Nu avem piese alternative');
            } else {
              offerLines.push({
                originalPartId: cp.id,
                guideNumber: cp.guideNumber,
                quantity: cp.pieceNr,
                offerParts: parentParts.map((part: CaseCalculationAlternativePart) => {
                  return {
                    alternativePartCode: part.alternativePartCode,
                    alternativePartName: part.name,
                    alternativePrice: part.alternativePrice,
                    deliveryTime: part.formatedDeliveryTime,
                    availableQuantity: part.availableQuantity ?? 99999,
                    rowId: part.id
                  } as OfferPart;
                })
              } as OfferLine);
            }
          }
        }
    
        const getBestOfferRequest = {
          caseId: this.props.caseSettingsState.case!.id,
          price: this.props.caseSettingsState.caseSettings!.workflow.isPrcIntegration ? this.getPriceForInsurer(this.state.rentVehiclePrices) 
            : this.state.carClassRentPrice?.price ?? 0,
          offerLines: offerLines
        } as Offer;

        console.log('Best offer request', getBestOfferRequest);
        const bestOfferResult = await this.caseService.GetBestOffer(
          getBestOfferRequest
        );
        console.log('Best offer result', bestOfferResult);
        for (const cp of parts) {  
          if (cp.calculationSectionId === this.state.partSection!.id) {
            const bestOfferLine =  _.find(bestOfferResult.offerLines, function(offerLine) {
              return offerLine.originalPartId == cp.id;
            });
            console.log('bestOfferLine', bestOfferLine);
            if (bestOfferLine) {
              if (bestOfferLine.offerParts.length) {
                const bestOfferPart = bestOfferLine!.offerParts[0];
                console.log('bestOfferPart', bestOfferPart);
                const alternativePart = this.state.caseCalculationAlternativePartCopy.find(
                  (item) => item.id === bestOfferPart.rowId
                );
                console.log('alternativePart', alternativePart);
                this.handleAlternativePartSelect(
                  bestOfferPart.rowId,
                  bestOfferLine.originalPartId,
                  this.state.includeChild ? alternativePart!
                    : {...alternativePart!, bundleNo: null}
                );
              } else {
                this.props.enqueueSnackbar(this.translatorService.Tranlate('NO_OPTIM', 'Nu s-a gasit niciun optim pentru:') + ' ' + cp.guideNumber, {
                  variant: 'warning'
                });
              }
            }
          }
        }
        
        this.setState({
          partLoaders: this.state.partLoaders.map(() => false)
        }, () => {
          this.scrollToSelectedItems();
        });
      }
    );
  };


  scrollToSelectedItems = () => {
    console.log('rowRefs', this.rowRefs);
    const caseCalculationOriginalPartsCopy = [...this.state.calculationParts].reverse();
    const caseCalculationAlternativePartCopy = [...this.state.caseCalculationAlternativePart];
    caseCalculationOriginalPartsCopy
      .filter((item) => item.calculationSectionId === this.state.partSection!.id)
      .map((calculationOriginalPart, index) => {
        const altParts = caseCalculationAlternativePartCopy.filter(
          (item2) => item2.originalPartId === calculationOriginalPart.id && item2.selectedAlternativePart != 9999999999999
        );
        if (altParts.length > 0) {
          // Verifică dacă piesa selectată este vizibilă și derulează la ea
          const selectedAlternativePartId = altParts[0].selectedAlternativePart;
          if (this.rowRefs[selectedAlternativePartId]?.current) {
            console.log('Scroll to row', altParts[0]);
            this.rowRefs[selectedAlternativePartId].current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        }
      });
  }
  getPriceForInsurer(item: VehicleDto | null): number {
    if (item === null) return 0;

    if (R.isNil(this.props.caseSettingsState.case!.caseEvent)) {
      return 0;
    }
    //verify is insurerName is null or empty
    const insurerName = this.props.caseSettingsState.case!.caseEvent!.insurerName.toLowerCase();
    if (R.isNil(insurerName) || insurerName === '') {
      return 0;
    }
    const insurer = _.find(this.props.insurerList, function (insurer) {
      return insurer.displayName.toLowerCase() === insurerName.toLowerCase();
    });
    console.log('insurer', insurer);
    if (R.isNil(insurer)) {
      return 0;
    }

    let insurerCode = insurer.code?.toLowerCase() ?? '';
    insurerCode += 'Pret';
    // Check if the insurer name is a valid property in the item object
    if (item.hasOwnProperty(insurerCode)) {
      const value = item[insurerCode as keyof VehicleDto];
      if (typeof value === 'undefined' || value === null) {
        return 0;
      }

      // Convert the value to a number
      const numericValue = parseFloat(value.toString());

  
      // Check if the conversion was successful
      if (!isNaN(numericValue)) {
        return numericValue;
      } else {
        return 0; // Return 0 if the value cannot be converted to a number
      }
    } else {
      return 0; // Return 0 if the insurer name is not found
    }
  }

  validateOrginalParts = () : boolean => {
    const calculationParts = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === this.state.partSection!.id && !item.isOrdered
    );
    let decimalError = false;
    calculationParts.map((calcPart) => {
      if (!this.props.caseSettingsState.caseSettings!.workflow.useRoundUp && OriginalPartShortNumberException.includes(calcPart.repairMethod)) {
        return;
      }
      if (!Number.isInteger(calcPart.pieceNr)) {
        decimalError = true;
        this.validateOrigPartUnitsAdd(calcPart.id);
        return;
      }
    });

    if (decimalError) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'DECIMAL_ERROR_MSG',
          'Cantitatea comandata trebuie sa fie un numar intreg fara zecimale'
        ),
        {
          variant: 'error'
        }
      );
      return true;
    }
    return false;
  }

  optimizeAllParts = async (includeChild: boolean) => {
    this.setState({
      isDialogOpenOptimConfirmation: false,
      includeChild: includeChild
    });
    
    try {
      await this.optimize();
    } catch (error) {
      console.log('Eroare', error);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      this.setState({
        partLoaders: this.state.partLoaders.map(() => false)
      });
    }
  
  }


  isDialogOpenConfirmationHandler = (func: GenericVoidFunc) => {
    if (this.state.currentWorkflowContainsOrderFormOrInterogatePartner) {
      this.setState({
        isDialogOpenConfirmationOrgPartner:
          this.state.currentWorkflowContainsOrderFormOrInterogatePartner,
        isDialogOpenFunctionToBeCalled: func
      });
    } else {
      func();
    }
  };

  interogateAll = async () => {
    try {
      const parts = this.state.calculationParts.filter(
        (item) => item.calculationSectionId === this.state.partSection!.id && !item.isOrdered
      );
      this.setState({
        caseCalculationAlternativePart: [],
        caseCalculationAlternativePartCopy: []
      });

      for (const cp of parts) {
        const index = parts.indexOf(cp);
        if (cp.calculationSectionId === this.state.partSection!.id) {
          await Promise.all([
            this.oilMapping(cp),
            this.interogate(cp.id, cp.guideNumber, index)
          ]);
        }
      }

      // this.props.enqueueSnackbar(this.translatorService.Tranlate("SUCCES_MSG", "OK"), { variant: 'success' });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  uuidToNumber = (): number  => {
    const uniqueId = uuid();
    const hexString = uniqueId.replace(/-/g, '');
    const numericValue = parseInt(hexString, 16);
    //number with 9 characters
    return numericValue % 1000000000;
  }

  oilMapping = async (cp: CaseCalculationPart) => {
    const oilMapping = this.props.workflowCalculation!.oilMapping  ? await this.organizationService.GetOrganizationOilMapping(
      {
        organizationId: this.state.selectedOrganizationBranch?.organizationId ?? this.props.appState.appUser!.organizationId,
        partCode:  cp.guideNumber,
        cupiCode:  cp.repairMethod,
        auxiliarInformation: cp.auxiliarInformation
      } as OrganizationOilMappingRequest
    ) : null;

    if (oilMapping != null && oilMapping.price) { 
      console.log('oilMapping', oilMapping);
      cp.originalPrice = oilMapping.price;
    }
  }

  interogate = async (originalPartId: number, partCode: string, index: number) => {
    this.setState(
      {
        partLoaders: this.state.partLoaders.map((item, itemIndex) =>
          itemIndex === index ? true : item
        )
      },
      async () => {
        const alternativeParts = await this.caseService.InterogateCaseCalculationsAlternativeParts(
          originalPartId,
          partCode,
          this.props.additionTariff.value,
          this.props.discountTariff.value,
          this.props.caseSettingsState.caseSettings!.workflow.id,
          this.state.selectedOrganizationBranch?.organizationId
        );

        const differentCaseCalculationAlternativePart: CaseCalculationAlternativePart[] = _.filter(
          [...this.state.caseCalculationAlternativePart],
          function (object) {
            return object.originalPartId != originalPartId;
          }
        );
        const calculationsAlternativeParts = [...differentCaseCalculationAlternativePart];
        [...alternativeParts].forEach((item, index) => {
          item.id = this.uuidToNumber();
          console.log(item.id);
        });

        console.log(
          'differentCaseCalculationAlternativePart',
          differentCaseCalculationAlternativePart
        );
        calculationsAlternativeParts.push(...alternativeParts);
        console.log('NEW calculationsAlternativeParts', calculationsAlternativeParts);
        this.setState({
          caseCalculationAlternativePart: calculationsAlternativeParts,
          caseCalculationAlternativePartCopy: calculationsAlternativeParts,
          partLoaders: this.state.partLoaders.map((item, itemIndex) =>
            itemIndex === index ? false : item
          ),
          interogateParts: this.state.interogateParts.map((item, itemIndex) => 
            itemIndex === index ? true : item
          )
        });
      }
    );
  };

  interogateAlternativeParts = async (originalPartId: number, partCode: string, cp: CaseCalculationPart, index: number) => {
    try {
      await Promise.all([
        this.oilMapping(cp),
        this.interogate(originalPartId, partCode, index)
      ]);

      // this.props.enqueueSnackbar(this.translatorService.Tranlate("SUCCES_MSG", "OK"), { variant: 'success' });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  newCalculationPart = async (calculationPartId: number | null) => {
    this.setState({
      calculationPartFormTypeAdd: true
    });

    let newCalculationPart;
    if (calculationPartId === null) {
      newCalculationPart = { ...this.state.newCalculationPart } as CaseCalculationPart;
      newCalculationPart.caseCalculationHistoryId = this.props.calculationHistoryId;
      newCalculationPart.id = -1 * this.state.calculationParts.length;
      newCalculationPart.discount = 0;
      newCalculationPart.alternativePartQualityId = null;
      newCalculationPart.additionInsurer = null;
    } else {
      newCalculationPart = this.state.calculationParts.find(
        (item) => item.id === calculationPartId
      )!;
      newCalculationPart.discount = R.isNil(newCalculationPart.discount)
        ? 0
        : newCalculationPart.discount;
      newCalculationPart.alternativePartQualityId = R.isNil(newCalculationPart.alternativePartQualityId)
        ? null
        : newCalculationPart.alternativePartQualityId;
      newCalculationPart.additionInsurer = R.isNil(newCalculationPart.additionInsurer) ? null : newCalculationPart.additionInsurer;
    }
    
    this.setState({
      newCalculationPart: newCalculationPart,
      newCalculationPartId: calculationPartId,
      isAddOriginalPartDialogOpen: true
    });
  };

  copyCalculationPart = async (calculationPartId: number | null) => {
    this.setState({
      calculationPartFormTypeAdd: false
    });
    const originalPart = this.state.calculationParts.find(
      (calcPart) => calcPart.id === calculationPartId
    );
    const newCalculationPart = { ...originalPart } as CaseCalculationPart;
    newCalculationPart.id = -1 * this.state.calculationParts.length;
    newCalculationPart.pieceNr = 0;
    newCalculationPart.alternativeGuideNumber = '';
    newCalculationPart.alternativePartCode = '';
    newCalculationPart.alternativePartQuality = null;
    newCalculationPart.alternativePrice = null;
    newCalculationPart.alternativePartDiscount = null;
    newCalculationPart.alternativePartName = null;
    newCalculationPart.alternativePartQualityId = null;
    newCalculationPart.alternativePartStockLocation = null;
    newCalculationPart.alternativePartStockQuantity = null;
    newCalculationPart.alternativePartRemarks = null;
    newCalculationPart.minQty = 0;
    newCalculationPart.packing = null;
    newCalculationPart.bundleItem = null;
    newCalculationPart.bundleNo = null;
    newCalculationPart.bundleQuantity = null;
    newCalculationPart.alternativeNetPrice = null;
    newCalculationPart.alternativePriceWithoutDiscount = null;

    this.setState({
      newCalculationPart: newCalculationPart,
      newCalculationPartId: newCalculationPart.id,
      isAddOriginalPartDialogOpen: true
    });
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false,
      newCalculationPart: null,
      newCalculationPartId: null
    });
  };

  closeAddOriginalPartDialog = async () => {
    this.setState({
      isAddOriginalPartDialogOpen: false,
      newCalculationPart: null,
      newCalculationPartId: null
    });
  };

  notifySupportCalculationPart = async (calculationPartId: number | null) => {
    const originalPart = this.state.calculationParts.find(
      (calcPart) => calcPart.id === calculationPartId
    );
    const newCalculationPart = { ...originalPart } as CaseCalculationPart;
    newCalculationPart.id = -1 * this.state.calculationParts.length;
    newCalculationPart.pieceNr = 0;
    newCalculationPart.alternativeGuideNumber = '';
    newCalculationPart.alternativePartCode = '';
    newCalculationPart.alternativePartQuality = null;
    newCalculationPart.alternativePrice = null;
    newCalculationPart.alternativePartDiscount = null;
    newCalculationPart.alternativePartName = null;
    newCalculationPart.alternativePartQualityId = null;
    newCalculationPart.alternativePartStockLocation = null;
    newCalculationPart.alternativePartStockQuantity = null;
    newCalculationPart.alternativePartRemarks = null;
    newCalculationPart.minQty = 0;
    newCalculationPart.packing = null;
    newCalculationPart.bundleItem = null;
    newCalculationPart.bundleNo = null;
    newCalculationPart.bundleQuantity = null;
    newCalculationPart.alternativeNetPrice = null;
    newCalculationPart.alternativePriceWithoutDiscount = null;
    this.setState({
      newCalculationPart: newCalculationPart,
      newCalculationPartId: newCalculationPart.id,
      isNotifySupportDialogOpen: true
    });
  };

  closeNotifySupportDialog = async () => {
    this.setState({
      isNotifySupportDialogOpen: false,
      newCalculationPart: null,
      newCalculationPartId: null
    });
  };

  sendNotificationToSupport = async () => {
    console.log('sendNotificationToSupport');
    try {
      this.setState({
        sendNotificationToSupportLoading: true
      });
      const request = {
        caseId: this.props.caseSettingsState.case!.id,
        organizationOwnerId: this.props.caseSettingsState.case!.organizationOwnerId,
        template: 'GTM_NOTIFY_SUPPORT',
        originalPart: {
          oeCode: this.state.newCalculationPart!.guideNumber,
          name: this.state.newCalculationPart!.name,
          price: this.state.newCalculationPart!.originalPrice,
          quality: this.state.newCalculationPart!.carPartCategory!.displayName,
          comment: this.state.newCalculationPart!.supportComment
        } as OriginalPart
      } as PartNotifySuppportRequest;
      await this.caseService.SendPartNotifySupport(request);
      this.setState({
        sendNotificationToSupportLoading: false,
        isNotifySupportDialogOpen: false
      });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('NOTIFICATION_SEND_SUCCCESS_MSG', 'Notificarea catre support a fost trimisa cu succces!'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  public renderNotifySupportOriginalPartForm() {
    return R.isNil(this.state.newCalculationPart) ? null : (
      <ValidatorForm
        onSubmit={this.sendNotificationToSupport}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator
              disabled={true}
              fullWidth
              name="name"
              className="m-2"
              id="name"
              value={this.state.newCalculationPart!.name}
              onChange={this.handleTextChangeOriginalPart}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_DESCRIPTION_HEADER',
                'Descriere'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <TextValidator
              disabled={true}
              fullWidth
              name="guideNumber"
              className="m-2"
              id="guideNumber"
              value={this.state.newCalculationPart!.guideNumber}
              onChange={this.handleTextChangeOriginalPart}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_GUIDE_NUMBER_HEADER',
                'Cod'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <TextValidator
              disabled={true}
              fullWidth
              name="originalPrice"
              className="m-2"
              id="originalPrice"
              value={
                this.state.calculationPartFormTypeAdd
                  ? this.state.newCalculationPart!.originalPrice
                  : (
                      Math.round(
                        this.state.newCalculationPart!.originalPrice *
                          (1 - this.state.newCalculationPart!.discount / 100) *
                          100
                      ) / 100
                    ).toFixed(2)
              }
              onChange={this.handlePriceChangeOriginalPart}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_UNIT_PRICE_HEADER',
                'Pret Unitar'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />
            <Autocomplete
              id="originalPartQuality"
              className="m-2"
              options={this.state.partQualityTypes.sort(function (a, b) {
                return a.displayName.localeCompare(b.displayName);
              })}
              value={this.state.newCalculationPart!.carPartCategory!}
              onChange={(e: any, newValue: IReferential | null) =>
                this.handleAutocompleteOriginalPartQualityChange(newValue)
              }
              getOptionLabel={(option: IReferential) => option.displayName}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  disabled={true}
                  name="originalPartQuality"
                  value={this.state.newCalculationPart!.carPartCategory}
                  label={this.translatorService.Tranlate(
                    'CALCULATION_DETAILS_QUALITY_HEADER',
                    'Calitate'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
            <TextValidator
              fullWidth
              className="m-2"
              id="textComment"
              name="textComment"
              multiline
              rows={2}
              placeholder={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_SUPPORT_COMMENT_MESSAGE_PLACEHOLDER',
                'Descriere problema...'
              )}
              value={this.state.newCalculationPart!.supportComment}
              label={this.translatorService.Tranlate('CALCULATION_DETAILS_SUPPORT_COMMENT', 'Descriere problema')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.setState({
                  newCalculationPart: {
                    ...this.state.newCalculationPart!,
                    supportComment: e.target.value
                  }
                });
              }}
              variant="outlined"
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          </Grid>
        </Grid>
        <div className="text-right">
          {this.state.sendNotificationToSupportLoading &&                     
            <CircularProgress
              style={{ marginLeft: 8, marginRight: 8 }}
              size={12}
            /> 
          }
          <Button 
            disabled={this.state.sendNotificationToSupportLoading}
            className="m-2" 
            variant="contained" 
            color="primary" 
            type="submit"
            >
            {this.translatorService.Tranlate('NOTIFY_SUPPORT_SEND_BUTTON', 'Trimite')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }


  addAlternativePart = async (id: number) => {
    const addNewAlternativePart = {
      id: this.state.caseCalculationAlternativePart.length,
      originalPartId: id,
      selectedAlternativePart: 9999999999999
    } as CaseCalculationAlternativePart;
    this.setState({
      isDialogOpen: true,
      newCaseCalculationAlternativePart: addNewAlternativePart
    });
  };

  clearSelectedAlternativeParts = async (originalPartId: number) => {
    let alternativePartsCopy = [...this.state.caseCalculationAlternativePart];
    alternativePartsCopy = alternativePartsCopy.map((item) => {
      item.selectedAlternativePart =
        item.originalPartId === originalPartId ? 9999999999999 : item.selectedAlternativePart;
      item.partFromGroup =
        item.originalPartId === originalPartId ? 9999999999999 : item.partFromGroup;
      return item;
    });

    const caseCalculationOriginalPartsCopy = [...this.state.calculationParts];
    const originalPartIndex = caseCalculationOriginalPartsCopy.findIndex(
      (item) => item.id === originalPartId
    );

    caseCalculationOriginalPartsCopy[originalPartIndex] = {
      ...caseCalculationOriginalPartsCopy[originalPartIndex],
      alternativePrice: null,
      alternativePartDiscount: null,
      alternativeGuideNumber: null,
      alternativePartCode: null,
      alternativePartName: null,
      alternativePartQuality: null,
      alternativePartQualityId: null,
      alternativePartStockLocation: null,
      alternativePartStockQuantity: null,
      alternativePartRemarks: null,
      alternativeNetPrice: null,
      alternativePriceWithoutDiscount: null
    };

    this.validateOrigPartUnitsRemove(originalPartId);
    this.setState(
      {
        caseCalculationAlternativePart: alternativePartsCopy,
        caseCalculationAlternativePartCopy: alternativePartsCopy,
        calculationParts: caseCalculationOriginalPartsCopy,
        clearStateAlternativeParts: true
      },
      () => this.props.handleUpdateAlternativeCalcAmount(caseCalculationOriginalPartsCopy)
    );
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator={false}
        decimalScale={2}
        isNumericString
      />
    );
  };

  handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    this.setState({
      newCaseCalculationAlternativePart: {
        ...this.state.newCaseCalculationAlternativePart!,
        [event.target.id]: value
      }
    });
  };

  handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newCaseCalculationAlternativePart: {
        ...this.state.newCaseCalculationAlternativePart!,
        alternativePrice: event.target.value === null ? 0 : parseFloat(event.target.value)
      }
    });
  };
  handleAvailableQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newCaseCalculationAlternativePart: {
        ...this.state.newCaseCalculationAlternativePart!,
        availableQuantity: event.target.value === null ? 0 : parseFloat(event.target.value)
      }
    });
  };

  handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        discount: event.target.value === null ? 0 : parseFloat(event.target.value)
      }
    });
  };

  deleteCalculationPart = async (id: number) => {
    try {
      const calculationPartIndex = this.state.calculationParts.findIndex((item) => item.id === id);
      const calculationParts = [...this.state.calculationParts];
      calculationParts.splice(calculationPartIndex, 1);

      const calculationAlternativeParts = this.state.caseCalculationAlternativePart.filter(
        (item) => item.originalPartId !== id
      );

      const interogateParts = _.cloneDeep(this.state.interogateParts);
      interogateParts.splice(calculationPartIndex, 1);

      this.setState(
        {
          calculationParts: calculationParts,
          caseCalculationAlternativePart: calculationAlternativeParts,
          caseCalculationAlternativePartCopy: calculationAlternativeParts,
          interogateParts: interogateParts
        },
        () => this.props.handleUpdateAlternativeCalcAmount(calculationParts)
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  addAlternativeCalculationPartForm = async () => {
    try {
      const caseCalculationAlternativePart = [...this.state.caseCalculationAlternativePart];
      caseCalculationAlternativePart.push({
        ...this.state.newCaseCalculationAlternativePart!
      });

      this.setState({
        caseCalculationAlternativePart: caseCalculationAlternativePart,
        caseCalculationAlternativePartCopy: caseCalculationAlternativePart,
        newCaseCalculationAlternativePart: null,
        isDialogOpen: false
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  addCalculationPartForm = async () => {
    try {
      const calculationParts = [...this.state.calculationParts];
      let isNew = false;
      if (this.state.orderPartList.length > 0) { 
        const newPart = {...this.state.newCalculationPart};
        const orderPart = _.find(this.state.orderPartList, function (orderPart) {
          return (
            orderPart.guideNumber === newPart.guideNumber &&
            orderPart.name === newPart.name 
          );
        });
  
        if (!orderPart) {
          isNew = true;
        }
      }  
      if (this.state.newCalculationPartId === null) {
        calculationParts.push({
          ...this.state.newCalculationPart,
          amount: 0,
          calculationSectionId: this.state.partSection!.id,
          calculationSection: this.state.partSection,
          isNew: isNew
        } as CaseCalculationPart);
      } else {
        const index = calculationParts.findIndex(
          (item) => item.id === this.state.newCalculationPartId
        )!;
        calculationParts[index] = {
          ...this.state.newCalculationPart!,
          carPartCategoryId:
            this.state.currentSection!.id === 1
              ? this.state.newCalculationPart!.carPartCategory!.id
              : null,
          carPartCategory:
            this.state.currentSection!.id === 1
              ? this.state.newCalculationPart!.carPartCategory
              : null,
          amount: 0,
          isNew: isNew
        };
      }

      this.setState(
        {
          calculationParts: calculationParts,
          newCalculationPartId: null,
          newCalculationPart: null,
          isAddOriginalPartDialogOpen: false,
          partLoaders: [...this.state.partLoaders, false],
          interogateParts: [...this.state.interogateParts, false]
        },
        () => this.props.handleUpdateAlternativeCalcAmount(calculationParts)
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  copyCalculationPartForm = async () => {
    try {
      const calculationParts = [...this.state.calculationParts];
      calculationParts.push({
        ...this.state.newCalculationPart,
        amount: 0
      } as CaseCalculationPart);

      this.setState(
        {
          calculationParts: calculationParts,
          newCalculationPartId: null,
          newCalculationPart: null,
          isAddOriginalPartDialogOpen: false
        },
        () => this.props.handleUpdateAlternativeCalcAmount(calculationParts)
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompletePartQualityChange = async (newValue: IReferential | null) => {
    this.setState({
      newCaseCalculationAlternativePart: {
        ...this.state.newCaseCalculationAlternativePart!,
        alternativePartQuality: newValue,
        alternativePartQualityId: R.isNil(newValue) ? 0 : newValue.id
      }
    });
  };

  handleAutocompleteSupplierChange = (newValue: any | null) => {
    this.setState({
      newCaseCalculationAlternativePart: {
        ...this.state.newCaseCalculationAlternativePart!,
        supplier: newValue,
        supplierId: R.isNil(newValue) ? null : newValue.id
      }
    });
  };

  handleAlternativePartSelect = (
    id: number,
    originalPartid: number,
    alternativePart: CaseCalculationAlternativePart
  ) => {
    const groupedUniqueId = uuid();
    const caseCalculationOriginalPartsCopy = [...this.state.calculationParts];
    const originalPartIndex = caseCalculationOriginalPartsCopy.findIndex(
      (item) => item.id === originalPartid
    );
    caseCalculationOriginalPartsCopy[originalPartIndex] = {
      ...caseCalculationOriginalPartsCopy[originalPartIndex],
      alternativePrice: alternativePart.alternativePrice,
      alternativePartDiscount: alternativePart.alternativePartDiscount,
      alternativeGuideNumber: alternativePart.alternativeGuideNumber,
      alternativePartCode: alternativePart.alternativePartCode,
      alternativePartQuality: alternativePart.alternativePartQuality,
      alternativePartQualityId: alternativePart.alternativePartQualityId,
      alternativePartName: alternativePart.name,
      alternativePartStockLocation: alternativePart.stockLocation,
      alternativePartStockQuantity: alternativePart.availableQuantity,
      supplier: alternativePart.supplier,
      supplierId: alternativePart.supplierId,
      producer: alternativePart.producer,
      deliveryTime: alternativePart.deliveryTime.toString(),
      validityTime: alternativePart.validityTime?.toString() || null,
      codeType: alternativePart.codeType?.toString() || null,
      type: alternativePart.type?.toString() || null,
      orderCode: alternativePart.orderCode?.toString() || null,
      locationId: alternativePart.locationId || null,
      alternativePartRemarks: alternativePart.remarks,
      minQty: alternativePart.minQty,
      packing: alternativePart.packing,
      bundleItem: alternativePart.bundleItem,
      bundleNo: alternativePart.bundleNo,
      bundleQuantity: alternativePart.bundleQuantity,
      excludeFromOrder: alternativePart.excludeFromOrder,
      isChild: alternativePart.isChild,
      productId: alternativePart.productId || null,
      codEan: alternativePart.codEan?.toString() || null,
      groupedUniqueId: groupedUniqueId || null,
      alternativeNetPrice: alternativePart.alternativeNetPrice,
      alternativePriceWithoutDiscount: alternativePart.alternativePriceWithoutDiscount,
      availableQuantity: alternativePart.availableQuantity
    };

    const currentPacking =
      caseCalculationOriginalPartsCopy[originalPartIndex].packing?.split('|')[0];
    const secondItemOfPacking =
      caseCalculationOriginalPartsCopy[originalPartIndex].packing?.split('|')[1];
    const caseCalculationAlternativePartCopy = [...this.state.caseCalculationAlternativePart];

    console.log('Specific alternative parts');
    const specificAlternativeParts = this.state.caseCalculationAlternativePart
      .filter(
        (alternativePart) =>
          alternativePart.originalPartId === caseCalculationOriginalPartsCopy[originalPartIndex].id
      )
      .sort(function (a: any, b: any) {
        return a.id - b.id;
      });
    console.log(specificAlternativeParts);
    const findAlternativePartGroup = _.filter(specificAlternativeParts, function (object) {
      return (
        object.bundleNo != null &&
        object.bundleNo == caseCalculationOriginalPartsCopy[originalPartIndex].bundleNo &&
        object.alternativePartCode !=
          caseCalculationOriginalPartsCopy[originalPartIndex].alternativePartCode
      );
    });
    console.log('Alternative Part Group');
    console.log(findAlternativePartGroup);

    const idList: any = [];
    for (let i = 0; i < findAlternativePartGroup.length; i++) {
      idList.push(findAlternativePartGroup[i].id);
    }

    caseCalculationAlternativePartCopy.forEach((item) => {
      item.selectedAlternativePart =
        item.originalPartId === originalPartid
          ? item.id === id
            ? id
            : 9999999999999
          : item.selectedAlternativePart;
      item.partFromGroup =
        item.originalPartId === originalPartid
          ? idList.includes(item.id)
            ? item.id
            : 9999999999999
          : item.partFromGroup;
      if (
        item.originalPartId === originalPartid &&
        item.selectedAlternativePart !== 9999999999999
      ) {
        const initialCaseCalculationOriginalParts = {
          ...caseCalculationOriginalPartsCopy[originalPartIndex]
        };
        caseCalculationOriginalPartsCopy[originalPartIndex].package = [];
        if (currentPacking && secondItemOfPacking) {
          const currentPackingNumber = parseFloat(currentPacking);
          if (
            caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr % currentPackingNumber !=
              0 ||
            caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr <
              caseCalculationOriginalPartsCopy[originalPartIndex].minQty
          ) {
            this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'MIN_PACKING_ERROR_MSG',
                'Cantitatea comandata trebuie sa fie '
              ) +
                currentPackingNumber +
              this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!'),
              { variant: 'error',
              autoHideDuration: 5000  }
            );
          }
        } else if (
          caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr <
          caseCalculationOriginalPartsCopy[originalPartIndex].minQty
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'MIN_PACKING_ERROR_MSG',
              'Cantitatea comandata trebuie sa fie '
            ) +
              caseCalculationOriginalPartsCopy[originalPartIndex].minQty +
              this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!'),
            { variant: 'error',
            autoHideDuration: 5000 }
          );
        }
        if (findAlternativePartGroup.length) {
          let displayPopup = true;
          let alternativePartString = '';
          for (let i = 0; i < findAlternativePartGroup.length; i++) {
            if (
              findAlternativePartGroup[i].alternativePartCode == AlternativeCode.TAXAMEDIU &&
              findAlternativePartGroup.length == 1
            ) {
              displayPopup = false;
            }
            alternativePartString +=
              findAlternativePartGroup[i].name +
              '(' +
              findAlternativePartGroup[i].alternativePrice +
              ' RON)' +
              '\n';
            caseCalculationOriginalPartsCopy[originalPartIndex].package.push({
              ...initialCaseCalculationOriginalParts,
              id: 0,
              originalPrice: 0,
              alternativePrice: findAlternativePartGroup[i].alternativePrice,
              alternativePartDiscount: findAlternativePartGroup[i].alternativePartDiscount,
              alternativeGuideNumber: findAlternativePartGroup[i].alternativeGuideNumber,
              alternativePartCode: findAlternativePartGroup[i].alternativePartCode,
              alternativePartQuality: findAlternativePartGroup[i].alternativePartQuality,
              alternativePartQualityId: findAlternativePartGroup[i].alternativePartQualityId,
              alternativePartName: findAlternativePartGroup[i].name,
              alternativePartStockLocation: findAlternativePartGroup[i].stockLocation,
              alternativePartStockQuantity: findAlternativePartGroup[i].availableQuantity,
              supplier: findAlternativePartGroup[i].supplier,
              supplierId: findAlternativePartGroup[i].supplierId,
              producer: findAlternativePartGroup[i].producer,
              deliveryTime: findAlternativePartGroup[i].deliveryTime.toString(),
              validityTime: findAlternativePartGroup[i].validityTime?.toString() || null,
              codeType: findAlternativePartGroup[i].codeType?.toString() || null,
              type: findAlternativePartGroup[i].type?.toString() || null,
              orderCode: findAlternativePartGroup[i].orderCode?.toString() || null,
              locationId: findAlternativePartGroup[i].locationId || null,
              alternativePartRemarks: findAlternativePartGroup[i].remarks,
              minQty: findAlternativePartGroup[i].minQty,
              packing: findAlternativePartGroup[i].packing,
              bundleItem: findAlternativePartGroup[i].bundleItem,
              bundleNo: findAlternativePartGroup[i].bundleNo,
              bundleQuantity: findAlternativePartGroup[i].bundleQuantity,
              excludeFromOrder: true,
              productId: findAlternativePartGroup[i].productId || null,
              codEan: findAlternativePartGroup[i].codEan || null,
              groupedUniqueId: groupedUniqueId || null,
              alternativePartNetPrice: findAlternativePartGroup[i].alternativeNetPrice,
              alternativePriceWithoutDiscount: findAlternativePartGroup[i].alternativePriceWithoutDiscount,
              availableQuantity: findAlternativePartGroup[i].availableQuantity
            });
          }
          if (displayPopup) {
            this.props.enqueueSnackbar(
              ' Componente adaugate la comanda: \n' + alternativePartString,
              {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
                autoHideDuration: 5000 
              }
            );
          }
        }
        if (!Number.isInteger(caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr)) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'DECIMAL_ERROR_MSG',
              'Cantitatea comandata trebuie sa fie un numar intreg fara zecimale'
            ),
            {
              variant: 'error',
              autoHideDuration: 5000 
            }
          );
        }
        if (
          item.availableQuantity !== null &&
          item.availableQuantity < caseCalculationOriginalPartsCopy[originalPartIndex].pieceNr
        ) {
          this.validateOrigPartUnitsAdd(originalPartid);
        } else {
          this.validateOrigPartUnitsRemove(originalPartid);
        }
        if (!R.isNil(this.props.workflowCalculation) && this.props.workflowCalculation.hasAlternativePartDescription) {
          const nonStockSupplier = this.props.nonStockSuppliers.find(x => x.supplierId === item.supplierId);
          if (!R.isNil(nonStockSupplier) && R.isNil(item.availableQuantity) &&
            (R.isNil(caseCalculationOriginalPartsCopy[originalPartIndex].alternativePartDescription) || R.isEmpty(caseCalculationOriginalPartsCopy[originalPartIndex].alternativePartDescription))
          ) {
            this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'ALTERNATIVE_PART_DESCRIPTION_ERROR_MSG',
                'Va rugam sa completati campul Descriere piesa alt'
              ),
              { 
                variant: 'error',
                autoHideDuration: 5000 
              }
            );
            this.validateAlternativePartDescriptionAdd(originalPartid);
          } else {
            this.validateAlternativePartDescriptionRemove(originalPartid);
          }
        }
      }
    });

    console.log('caseCalculationOriginalPartsCopy');
    console.log(caseCalculationOriginalPartsCopy);

    this.setState(
      {
        caseCalculationAlternativePart: caseCalculationAlternativePartCopy,
        caseCalculationAlternativePartCopy: caseCalculationAlternativePartCopy,
        calculationParts: caseCalculationOriginalPartsCopy
      },
      () => this.props.handleUpdateAlternativeCalcAmount(caseCalculationOriginalPartsCopy)
    );
  };

  handleAlternativePartChecked = (selected: number, id: number, partFromGroup: number) => {
    return selected === id || partFromGroup == id;
  };

  validateOriginalPartPriceAdd = (originalPartid: number) => {
    const labelParent = document.getElementsByClassName('id-' + originalPartid + '-price')[0] as HTMLElement;
    const identifylabel = labelParent.firstChild as any;
    const labelValidate = identifylabel.classList.add('Mui-error');

    const inputParent = document
      .getElementsByClassName('id-' + originalPartid + '-price')[0]
      .closest('div') as HTMLElement;
    const identifyInput = inputParent.children[1].firstChild as any;
    const inputValidate = identifyInput.classList.add('text-danger');

    const borderParent = document
    .getElementsByClassName('id-' + originalPartid + '-price')[0]
    .closest('div') as HTMLElement;
    const borderIdentify = borderParent.children[1] as any;
    const borderValidate = borderIdentify.classList.add('Mui-error');

    return labelValidate && inputValidate && borderValidate;
  };

  validateOriginalPartPriceRemove = (originalPartid: number) => {
    const labelParent = document.getElementsByClassName('id-' + originalPartid + '-price')[0] as HTMLElement;
    const identifylabel = labelParent.firstChild as any;
    const labelValidate = identifylabel.classList.remove('Mui-error');

    const inputParent = document
      .getElementsByClassName('id-' + originalPartid + '-price')[0]
      .closest('div') as HTMLElement;
    const identifyInput = inputParent.children[1].firstChild as any;
    const inputValidate = identifyInput.classList.remove('text-danger');

    const borderParent = document
      .getElementsByClassName('id-' + originalPartid + '-price')[0]
      .closest('div') as HTMLElement;
    const borderIdentify = borderParent.children[1] as any;
    const borderValidate = borderIdentify.classList.remove('Mui-error');

    return labelValidate && inputValidate && borderValidate;
  };


  validateOrigPartUnitsAdd = (originalPartid: number) => {
    const labelParent = document.getElementsByClassName('id-' + originalPartid)[0] as HTMLElement;
    const identifylabel = labelParent.firstChild as any;
    const labelValidate = identifylabel.classList.add('Mui-error');

    const inputParent = document
      .getElementsByClassName('id-' + originalPartid)[0]
      .closest('div') as HTMLElement;
    const identifyInput = inputParent.children[1].firstChild as any;
    const inputValidate = identifyInput.classList.add('text-danger');

    const borderParent = document
      .getElementsByClassName('id-' + originalPartid)[0]
      .closest('div') as HTMLElement;
    const borderIdentify = borderParent.children[1] as any;
    const borderValidate = borderIdentify.classList.add('Mui-error');

    return labelValidate && inputValidate && borderValidate;
  };

  validateOrigPartUnitsRemove = (originalPartid: number) => {
    const labelParent = document.getElementsByClassName('id-' + originalPartid)[0] as HTMLElement;
    const identifylabel = labelParent.firstChild as any;
    const labelValidate = identifylabel.classList.remove('Mui-error');

    const inputParent = document
      .getElementsByClassName('id-' + originalPartid)[0]
      .closest('div') as HTMLElement;
    const identifyInput = inputParent.children[1].firstChild as any;
    const inputValidate = identifyInput.classList.remove('text-danger');

    const borderParent = document
      .getElementsByClassName('id-' + originalPartid)[0]
      .closest('div') as HTMLElement;
    const borderIdentify = borderParent.children[1] as any;
    const borderValidate = borderIdentify.classList.remove('Mui-error');

    return labelValidate && inputValidate && borderValidate;
  };

  validateAlternativePartDescriptionAdd = (originalPartid: number) => {
    const labelParent = document.getElementsByClassName('id-' + originalPartid + '-alternative-part-description')[0] as HTMLElement;
    const identifylabel = labelParent.firstChild as any;
    const labelValidate = identifylabel.classList.add('Mui-error');

    const inputParent = document
      .getElementsByClassName('id-' + originalPartid + '-alternative-part-description')[0]
      .closest('div') as HTMLElement;
    const identifyInput = inputParent.children[1].firstChild as any;
    const inputValidate = identifyInput.classList.add('text-danger');

    const borderParent = document
    .getElementsByClassName('id-' + originalPartid + '-alternative-part-description')[0]
    .closest('div') as HTMLElement;
    const borderIdentify = borderParent.children[1] as any;
    const borderValidate = borderIdentify.classList.add('Mui-error');

    return labelValidate && inputValidate && borderValidate;
  };

  validateAlternativePartDescriptionRemove = (originalPartid: number) => {
    const labelParent = document.getElementsByClassName('id-' + originalPartid + '-alternative-part-description')[0] as HTMLElement;
    const identifylabel = labelParent.firstChild as any;
    const labelValidate = identifylabel.classList.remove('Mui-error');

    const inputParent = document
      .getElementsByClassName('id-' + originalPartid + '-alternative-part-description')[0]
      .closest('div') as HTMLElement;
    const identifyInput = inputParent.children[1].firstChild as any;
    const inputValidate = identifyInput.classList.remove('text-danger');

    const borderParent = document
      .getElementsByClassName('id-' + originalPartid + '-alternative-part-description')[0]
      .closest('div') as HTMLElement;
    const borderIdentify = borderParent.children[1] as any;
    const borderValidate = borderIdentify.classList.remove('Mui-error');

    return labelValidate && inputValidate && borderValidate;
  };

  filterAlternativePartsByOptimization = () => {
    if (this.props.optmizationType === 1 || this.props.optmizationType === 2) {
      const caseCalculationOriginalPartsCopy = [...this.state.calculationParts].reverse();

      let caseCalculationAlternativePartCopy = [...this.state.caseCalculationAlternativePart];
      caseCalculationAlternativePartCopy = caseCalculationAlternativePartCopy.map((item) => {
        item.selectedAlternativePart = 9999999999999;
        return item;
      });
      caseCalculationOriginalPartsCopy
        .filter((item) => item.calculationSectionId === this.state.partSection!.id)
        .map((calculationOriginalPart, index) => {
          let altParts = caseCalculationAlternativePartCopy.filter(
            (item2) => item2.originalPartId === calculationOriginalPart.id
          );
          if (altParts.length > 0) {
            altParts = this.filterAlternativePartsByDeliveryTime(altParts);
            if (this.props.optmizationType === 1 && altParts.length > 0) {
              altParts = this.filterAlternativePartsByPrice(altParts);
              if (altParts.length > 1) {
                altParts = this.filterAlternativePartsByQuality(altParts);
                if (altParts.length > 1) {
                  const altPart = altParts.sort(function (a, b) {
                    return a.deliveryTime - b.deliveryTime;
                  })[0];
                  altParts = [altPart];
                }
              }
            } else if (this.props.optmizationType === 2 && altParts.length > 0) {
              altParts = this.filterAlternativePartsByQuality(altParts);
              if (altParts.length > 1) {
                altParts = this.filterAlternativePartsByPrice(altParts);
                if (altParts.length > 1) {
                  const altPart = altParts.sort(function (a, b) {
                    return a.deliveryTime - b.deliveryTime;
                  })[0];
                  altParts = [altPart];
                }
              }
            }
          }
          if (altParts.length > 0) {
            altParts[0].selectedAlternativePart = altParts[0].id;
            const index = caseCalculationAlternativePartCopy.findIndex(
              (item) => item.id === altParts[0].id
            );

            caseCalculationAlternativePartCopy[index] = altParts[0];
            calculationOriginalPart.alternativePrice = altParts[0].alternativePrice;
            calculationOriginalPart.alternativePartDiscount = altParts[0].alternativePartDiscount;
            calculationOriginalPart.alternativeGuideNumber = altParts[0].alternativeGuideNumber;
            calculationOriginalPart.alternativePartCode = altParts[0].alternativePartCode;
            calculationOriginalPart.alternativePartQuality = altParts[0].alternativePartQuality;
            calculationOriginalPart.alternativePartQualityId = altParts[0].alternativePartQualityId;
            calculationOriginalPart.alternativePartName = altParts[0].name;
            calculationOriginalPart.alternativePartStockLocation = altParts[0].stockLocation;
            calculationOriginalPart.alternativePartStockQuantity = altParts[0].availableQuantity;
            calculationOriginalPart.supplier = altParts[0].supplier;
            calculationOriginalPart.supplierId = altParts[0].supplierId;
            calculationOriginalPart.producer = altParts[0].producer;
            calculationOriginalPart.deliveryTime = altParts[0].deliveryTime.toString();
            calculationOriginalPart.alternativePartRemarks = altParts[0].remarks;
            calculationOriginalPart.minQty = altParts[0].minQty;
            calculationOriginalPart.packing = altParts[0].packing;
            calculationOriginalPart.bundleItem = altParts[0].bundleItem;
            calculationOriginalPart.bundleNo = altParts[0].bundleNo;
            calculationOriginalPart.bundleQuantity = altParts[0].bundleQuantity;
            calculationOriginalPart.excludeFromOrder = altParts[0].excludeFromOrder;
            calculationOriginalPart.validityTime = altParts[0].validityTime;
            calculationOriginalPart.codeType = altParts[0].codeType;
            calculationOriginalPart.locationId = altParts[0].locationId;
            calculationOriginalPart.type = altParts[0].type;
            calculationOriginalPart.orderCode = altParts[0].orderCode;
            calculationOriginalPart.productId = altParts[0].productId;
            calculationOriginalPart.codEan = altParts[0].codEan;
            calculationOriginalPart.alternativeNetPrice = altParts[0].alternativeNetPrice;
            calculationOriginalPart.alternativePriceWithoutDiscount = altParts[0].alternativePriceWithoutDiscount;
            calculationOriginalPart.availableQuantity = altParts[0].availableQuantity;
            if (
              calculationOriginalPart.id === altParts[0].originalPartId &&
              altParts[0].selectedAlternativePart !== 9999999999999
            ) {
              if (
                !R.isNil(altParts[0].availableQuantity) &&
                altParts[0].availableQuantity < calculationOriginalPart.pieceNr
              ) {
                this.validateOrigPartUnitsAdd(calculationOriginalPart.id);
              } else {
                this.validateOrigPartUnitsRemove(calculationOriginalPart.id);
              }

              if (!R.isNil(this.props.workflowCalculation) && this.props.workflowCalculation.hasAlternativePartDescription) {
                const nonStockSupplier = this.props.nonStockSuppliers.find(x => x.supplierId === altParts[0].supplierId);
                if (!R.isNil(nonStockSupplier) && R.isNil(altParts[0].availableQuantity) &&
                  (R.isNil(calculationOriginalPart.alternativePartDescription) || R.isEmpty(calculationOriginalPart.alternativePartDescription))
                ) {
                  this.props.enqueueSnackbar(
                    this.translatorService.Tranlate(
                      'ALTERNATIVE_PART_DESCRIPTION_ERROR_MSG',
                      'Va rugam sa completati campul Descriere piesa alt'
                    ),
                    { 
                      variant: 'error',
                      autoHideDuration: 5000 
                    }
                  );
                  this.validateAlternativePartDescriptionAdd(calculationOriginalPart.id);
                } else {
                  this.validateAlternativePartDescriptionRemove(calculationOriginalPart.id);
                }
              }
            }
            
            // Verifică dacă piesa selectată este vizibilă și derulează la ea
            const selectedAlternativePartId = altParts[0].selectedAlternativePart;
            if (selectedAlternativePartId !== 9999999999999 && this.rowRefs[selectedAlternativePartId]?.current) {
              console.log('Scroll to row', altParts[0]);
              this.rowRefs[selectedAlternativePartId].current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }
          }
        });

      this.setState(
        {
          caseCalculationAlternativePart: caseCalculationAlternativePartCopy,
          caseCalculationAlternativePartCopy: caseCalculationAlternativePartCopy,
          calculationParts: caseCalculationOriginalPartsCopy.reverse()
        },
        () => this.props.handleUpdateAlternativeCalcAmount(this.state.calculationParts)
      );
    }
  };

  filterAlternativePartsByDeliveryTime = (alternativeParts: CaseCalculationAlternativePart[]) => {
    return alternativeParts.filter(
      (item) =>
        item.deliveryTime <= this.props.defaultMaxDeliveryTime ||
        item.deliveryTime.toString().match(/^[0-9]+$/) === null
    );
  };

  filterAlternativePartsByPrice = (alternativeParts: CaseCalculationAlternativePart[]) => {
    if (this.props.workflowCalculation!.byAquisitionPrice) {
      const min = alternativeParts.reduce((prev, curr) =>
        prev.alternativeNetPrice! < curr.alternativeNetPrice! ? prev : curr
      ).alternativeNetPrice;
  
      return alternativeParts.filter((item) => item.alternativeNetPrice === min);
    } else {
      const min = alternativeParts.reduce((prev, curr) =>
        prev.alternativePrice < curr.alternativePrice ? prev : curr
      ).alternativePrice;
  
      return alternativeParts.filter((item) => item.alternativePrice === min);
    }
  };

  filterAlternativePartsByQuality = (alternativeParts: CaseCalculationAlternativePart[]) => {
    const qualityRatings = CarPartQualityRatings;
    const qualities = qualityRatings.filter((elem) =>
      alternativeParts.find(
        ({ alternativePartQuality }) => elem.CarPartQualityTypeCode === alternativePartQuality!.code
      )
    );
    const maxRatingQuality = qualities.sort(function (a, b) {
      return b.Rating - a.Rating;
    });
    const bestQuality = maxRatingQuality[0];

    return alternativeParts.filter(
      (item) => item.alternativePartQuality!.code === bestQuality.CarPartQualityTypeCode
    );
  };

  public renderAddForm() {
    return R.isNil(this.state.newCaseCalculationAlternativePart) ? null : (
      <ValidatorForm onSubmit={(e) => this.addAlternativeCalculationPartForm()}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              name="name"
              className="m-2"
              id="name"
              value={this.state.newCaseCalculationAlternativePart!.name}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_DESCRIPTION_HEADER',
                'Descriere'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <TextValidator
              fullWidth
              name="alternativeGuideNumber"
              className="m-2"
              id="alternativeGuideNumber"
              value={this.state.newCaseCalculationAlternativePart!.alternativeGuideNumber}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_GUIDE_NUMBER_HEADER',
                'Cod'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <TextValidator
              fullWidth
              name="originalPrice"
              className="m-2"
              id="originalPrice"
              value={this.state.newCaseCalculationAlternativePart!.alternativePrice}
              onChange={this.handlePriceChange}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_UNIT_PRICE_HEADER',
                'Pret Unitar'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />

            <Autocomplete
              id="alternativePartQuality"
              className="m-2"
              options={this.state.partQualityTypes.sort(function (a, b) {
                return a.displayName.localeCompare(b.displayName);
              })}
              value={this.state.newCaseCalculationAlternativePart!.alternativePartQuality}
              onChange={(e: any, newValue: IReferential | null) =>
                this.handleAutocompletePartQualityChange(newValue)
              }
              getOptionLabel={(option: IReferential) => option.displayName}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="alternativePartQuality"
                  value={this.state.newCaseCalculationAlternativePart!.alternativePartQuality}
                  label={this.translatorService.Tranlate(
                    'CALCULATION_DETAILS_QUALITY_HEADER',
                    'Calitate'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
            <Autocomplete
              id="supplierList"
              className="m-2"
              options={this.state.supplierList.sort(function (a, b) {
                return a.name.localeCompare(b.name);
              })}
              value={this.state.newCaseCalculationAlternativePart!.supplier}
              onChange={(e: any, newValue: Organization | null) =>
                this.handleAutocompleteSupplierChange(newValue)
              }
              getOptionLabel={(option: Organization) => option.name}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="supplierList"
                  value={this.state.newCaseCalculationAlternativePart!.supplier}
                  label={this.translatorService.Tranlate(
                    'CALCULATION_DETAILS_SUPPLIER_HEADER',
                    'Furnizor'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              name="deliveryTime"
              type="number"
              className="m-2"
              id="deliveryTime"
              value={this.state.newCaseCalculationAlternativePart!.deliveryTime}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_DELIVERY_LABEL',
                'Livrare'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <TextValidator
              fullWidth
              name="producer"
              className="m-2"
              id="producer"
              value={this.state.newCaseCalculationAlternativePart!.producer}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_OWNER_LABEL',
                'Producator'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <TextField
              fullWidth
              name="stockLocation"
              className="m-2"
              id="stockLocation"
              value={this.state.newCaseCalculationAlternativePart!.stockLocation}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_STOCK_LOCATION_LABEL',
                'Locatie'
              )}
            />
            <TextField
              fullWidth
              name="availableQuantity"
              className="m-2"
              id="availableQuantity"
              value={this.state.newCaseCalculationAlternativePart!.availableQuantity}
              onChange={this.handleAvailableQuantityChange}
              label={this.translatorService.Tranlate(
                'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_QUANTITY_LABEL',
                'Cantitate'
              )}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />
            <TextField
              fullWidth
              className="m-2"
              name="remarks"
              id="remarks"
              label={this.translatorService.Tranlate(
                'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_REMARKS_LABEL',
                'Observatii'
              )}
              multiline
              rows="4"
              variant="outlined"
              value={this.state.newCaseCalculationAlternativePart!.remarks}
              onChange={this.handleTextChange}
            />
          </Grid>
        </Grid>
        <div className="text-right">
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('SAVE', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }

  handleTextChangeOriginalPart = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    
    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        [event.target.id]: event.target.id == 'guideNumber' ?  value.trimLeft() : value
      }
    });
  };

  handlePriceChangeOriginalPart = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        originalPrice: event.target.value === null ? 0 : parseFloat(event.target.value)
      }
    });
  };

  handleAutocompleteOriginalPartQualityChange = async (newValue: IReferential | null) => {
    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        carPartCategory: newValue,
        carPartCategoryId: R.isNil(newValue) ? 0 : newValue.id
      }
    });
  };

  handleUnitsOriginalPart = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        pieceNr: event.target.value === null ? 0 : parseFloat(event.target.value)
      }
    });
  };

  handleSelectedWorkflowPartner = (newValue: OrganizationBranch | null) => {
    this.setState({
      ...this.state,
      selectedOrganizationBranch: newValue
    });
  };

  public renderAddOriginalPartForm() {
    return R.isNil(this.state.newCalculationPart) ? null : (
      <ValidatorForm
        onSubmit={(e) =>
          this.state.calculationPartFormTypeAdd
            ? this.addCalculationPartForm()
            : this.copyCalculationPartForm()
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator
              disabled={this.state.calculationPartFormTypeAdd ? false : true}
              fullWidth
              name="name"
              className="m-2"
              id="name"
              value={this.state.newCalculationPart!.name}
              onChange={this.handleTextChangeOriginalPart}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_DESCRIPTION_HEADER',
                'Descriere'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />

            <TextValidator
              disabled={this.state.calculationPartFormTypeAdd ? false : true}
              fullWidth
              name="guideNumber"
              className="m-2"
              id="guideNumber"
              value={this.state.newCalculationPart!.guideNumber}
              onChange={this.handleTextChangeOriginalPart}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_GUIDE_NUMBER_HEADER',
                'Cod'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />

            <TextValidator
              disabled={this.state.calculationPartFormTypeAdd ? false : true}
              fullWidth
              name="originalPrice"
              className="m-2"
              id="originalPrice"
              value={
                this.state.calculationPartFormTypeAdd
                  ? this.state.newCalculationPart!.originalPrice
                  : (
                      Math.round(
                        this.state.newCalculationPart!.originalPrice *
                          (1 - this.state.newCalculationPart!.discount / 100) *
                          100
                      ) / 100
                    ).toFixed(2)
              }
              onChange={this.handlePriceChangeOriginalPart}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_UNIT_PRICE_HEADER',
                'Pret Unitar'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />

            <Autocomplete
              id="originalPartQuality"
              className="m-2"
              options={this.state.partQualityTypes.sort(function (a, b) {
                return a.displayName.localeCompare(b.displayName);
              })}
              value={this.state.newCalculationPart!.carPartCategory!}
              onChange={(e: any, newValue: IReferential | null) =>
                this.handleAutocompleteOriginalPartQualityChange(newValue)
              }
              getOptionLabel={(option: IReferential) => option.displayName}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  disabled={this.state.calculationPartFormTypeAdd ? false : true}
                  name="originalPartQuality"
                  value={this.state.newCalculationPart!.carPartCategory}
                  label={this.translatorService.Tranlate(
                    'CALCULATION_DETAILS_QUALITY_HEADER',
                    'Calitate'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
            <TextValidator
              fullWidth
              name="pieceNr"
              className="m-2"
              id="pieceNr"
              value={this.state.newCalculationPart!.pieceNr}
              onChange={this.handleUnitsOriginalPart}
              label={this.translatorService.Tranlate('CALCULATION_DETAILS_UNITS_HEADER', 'Unitati')}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />
          </Grid>
        </Grid>
        <div className="text-right">
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('SAVE', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }

  displayCalculationPartSupplier = (supplierId: any) => {
    const supplier = this.state.supplierList.find((item) => item.id === supplierId);
    const supplierName = !R.isNil(supplier) ? supplier.name : '';
    return supplierName;
  };

  displayCalculationPartPrice = (value: number, partId: any) => {
    const calculationPartsFiltered = this.state.calculationPartsOrdered.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === partId);
    const price =
      calculationPart!.alternativePartQualityId === null
        ? value
        : calculationPart!.alternativePrice;
    return (Math.round(price! * 100) / 100).toFixed(2);
  };

  displayCalculationPartDiscount = (value: number, partId: any) => {
    const calculationPartsFiltered = this.state.calculationPartsOrdered.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === partId);
    const discount =
      calculationPart!.alternativePartQualityId === null
        ? R.isNil(value)
          ? 0
          : value
        : R.isNil(calculationPart!.alternativePartDiscount)
        ? 0
        : calculationPart!.alternativePartDiscount;
    return discount;
  };

  displayCalculationPartTotalPrice = (row: any) => {
    const calculationPartsFiltered = this.state.calculationPartsOrdered.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === row[0]);

    if (calculationPart!.alternativePartQualityId === null) {
      if (R.isNil(calculationPart!.discount)) {
        return (
          (
            Math.round(calculationPart!.originalPrice * calculationPart!.pieceNr * 100) / 100
          ).toFixed(2) +
          ' ' +
          this.state.caseCalculationHistoryDetails.currency!.displayName
        );
      }
      const value = calculationPart!.originalPrice * calculationPart!.pieceNr;
      return (
        (Math.round(value * (1 - calculationPart!.discount / 100) * 100) / 100).toFixed(2) +
        ' ' +
        this.state.caseCalculationHistoryDetails.currency!.displayName
      );
    } else {
      if (R.isNil(calculationPart!.alternativePartDiscount)) {
        return (
          (
            Math.round(calculationPart!.alternativePrice! * calculationPart!.pieceNr * 100) / 100
          ).toFixed(2) +
          ' ' +
          this.state.caseCalculationHistoryDetails.currency!.displayName
        );
      }
      const value = calculationPart!.alternativePrice! * calculationPart!.pieceNr;
      return (
        (
          Math.round(value * (1 - calculationPart!.alternativePartDiscount / 100) * 100) / 100
        ).toFixed(2) +
        ' ' +
        this.state.caseCalculationHistoryDetails.currency!.displayName
      );
    }
  };

  displayCalculationPartQuality = (value: IReferential, row: any) => {
    const calculationPartsFiltered = this.state.calculationPartsOrdered.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === row[0]);
    if (calculationPart!.alternativePartQualityId === null) {
      return (
        <Tooltip
          title={
            <div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_PRODUCER_HEADER',
                  'Producator'
                ) +
                  ': ' +
                  (R.isNil(row[6]) ? '' : row[6])}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_DELIVERY_TIME_HEADER',
                  'Timp livrare'
                ) +
                  ': ' +
                  (R.isNil(row[7]) ? '' : row[7])}
              </div>
            </div>
          }
        >
          <div>
            <span>{value.displayName + ' '}</span>
            <FontAwesomeIcon icon={['fas', 'info-circle']} />
          </div>
        </Tooltip>
      );
    } else {
      const partQualityType = this.state.partQualityTypes.find(
        (quality) => quality.id === calculationPart!.alternativePartQualityId
      );
      return (
        <Tooltip
          title={
            <div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_ALT_PART_GN_LABEL',
                  'Cod alternativa'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativeGuideNumber)
                    ? ''
                    : calculationPart!.alternativeGuideNumber)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_ALT_PART_NAME_LABEL',
                  'Descriere alternativa'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativePartName)
                    ? ''
                    : calculationPart!.alternativePartName)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_PRODUCER_HEADER',
                  'Producator'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.producer) ? '' : calculationPart!.producer)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_DELIVERY_TIME_HEADER',
                  'Timp livrare'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.deliveryTime) ? '' : calculationPart!.deliveryTime)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_ALT_PART_QUANTITY_LABEL',
                  'Cantitate disponibila'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativePartStockQuantity)
                    ? ''
                    : calculationPart!.alternativePartStockQuantity)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_STOCK_LOCATION_LABEL',
                  'Locatie'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativePartStockLocation)
                    ? ''
                    : calculationPart!.alternativePartStockLocation)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_REMARKS_LABEL',
                  'Observatii'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativePartRemarks)
                    ? ''
                    : calculationPart!.alternativePartRemarks)}
              </div>
            </div>
          }
        >
          <div>
            <span>{partQualityType!.displayName + ' '}</span>
            <FontAwesomeIcon icon={['fas', 'info-circle']} />
          </div>
        </Tooltip>
      );
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'supplierId',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'guideNumber',
        label: this.translatorService.Tranlate('CALCULATION_DETAILS_GUIDE_NUMBER_HEADER', 'Cod'),
        options: {
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            const item = this.state.calculationPartsOrdered.find(
              (item) => item.id === tableMeta.rowData[0]
            );
            return <span>{value}</span>;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'alternativeGuideNumber',
        label: this.translatorService.Tranlate(
          'CALCULATION_DETAILS_ALT_GUIDE_NUMBER_HEADER',
          'Cod Aftermarket'
        ),
        options: { filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate(
          'CALCULATION_DETAILS_DESCRIPTION_HEADER',
          'Descriere'
        ),
        options: {
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            const item = this.state.calculationPartsOrdered.find(
              (item) => item.id === tableMeta.rowData[0]
            );

            if (R.isNil(item)) {
              return '';
            }
            return R.isNil(item!.alternativePartQualityId) ? item!.name : item!.alternativePartName;
          }
        }
      },
      {
        name: '',
        label: this.translatorService.Tranlate('CALCULATION_DETAILS_SUPPLIER_HEADER', 'Furnizor'),
        options: {
          filter: false,
          customBodyRender: (value: IReferential, tableMeta: MUIDataTableMeta) => {
            return this.displayCalculationPartSupplier(tableMeta.rowData[1]);
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'producer',
        label: this.translatorService.Tranlate('CALCULATION_DETAILS_PRODUCER_HEADER', 'Producator'),
        options: { filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'deliveryTime',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'alternativePartQuality',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'carPartCategory',
        label: this.translatorService.Tranlate('CALCULATION_DETAILS_QUALITY_HEADER', 'Calitate'),
        options: {
          customBodyRender: (value: IReferential, tableMeta: MUIDataTableMeta) => {
            return this.displayCalculationPartQuality(value, tableMeta.rowData);
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'pieceNr',
        label: this.translatorService.Tranlate('CALCULATION_DETAILS_UNITS_HEADER', 'Unitati')
      },
      {
        name: 'alternativePrice',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'originalPrice',
        label: this.translatorService.Tranlate(
          'CALCULATION_DETAILS_UNIT_PRICE_HEADER',
          'Pret Unitar'
        ),
        options: {
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return Utils.countryNumberFormat(this.displayCalculationPartPrice(value, tableMeta.rowData[0]), this.props.appState.appUser?.organization?.country ?? null);
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'discount',
        label: this.translatorService.Tranlate(
          'CALCULATION_DETAILS_DISCOUNT_HEADER',
          'Discount (%)'
        ),
        options: {
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return this.displayCalculationPartDiscount(value, tableMeta.rowData[0]);
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        label: this.translatorService.Tranlate(
          'CALCULATION_DETAILS_TOTAL_PRICE_HEADER',
          'Pret Total'
        ),
        options: {
          customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
            return Utils.countryNumberFormat(this.displayCalculationPartTotalPrice(tableMeta.rowData), this.props.appState.appUser?.organization?.country ?? null);
          }
        } as MUIDataTableColumnOptions
      }
    ];

    return columns;
  };
  public renderOrderedPartsTable = () => {
    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      rowHover: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      elevation: 1
    };
    return (
      <div className="mt-3">
        <MUIDataTable
          columns={this.getColumns()}
          data={this.state.calculationPartsOrdered}
          title={this.translatorService.Tranlate('ORDERS_ORDERED_PARTS_TITLE', 'Piese comandate')}
          options={options}
        />
      </div>
    );
  };

  save = async () => {
    if (this.state.hasBillingForm) {
      if (this.state.selectedOrganizationBranch?.organizationId == null) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ALTERNATIVE_CALCULATION_NO_DELIVERY_ADDRESS', 'Va rugam selectati adresa de livrare!'),
          { 
            variant: 'warning',
            autoHideDuration: 6000
          }
        );
        return;
      }
    }
    
    try {
      const calculationParts = this.state.calculationParts.filter(
        (item) => item.calculationSectionId === this.state.partSection!.id && !item.isOrdered
      );

      if (this.props.workflowCalculation!.allPartsHasBeenInterogated) {
        const allPartsHasBeenInterogated = this.checkIfOptimIsPossible();
        if (!allPartsHasBeenInterogated) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('ALTERNATIVE_CALCULATION_WITHOUT_ALL_PARTS_INTEROGATED', 'Va rugam interogati toate piesele!'),
            { 
              variant: 'warning',
              autoHideDuration: 6000
            }
          );
          return;
        }
      } else {
        if (calculationParts.filter((item) => !R.isNil(item.alternativePartQualityId)).length == 0 && !this.state.isConfirm) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('ALTERNATIVE_CALCULATION_WITHOUT_INTEROGATE_PARTS', 'O calculatie alternativa nu poate fi salvata daca nu are niciun reper interogat!'),
            { 
              variant: 'warning',
              autoHideDuration: 6000
            }
          );
          return;
        }  
      }

      const alternativeParts = [...this.state.caseCalculationAlternativePart];
      let validateError = 0;
      let errorString = '';
      let decimalError = false;
      let zeroPriceError = false;
      let alternativePartDescriptionError = false;
      calculationParts.map((calcPart) => {
        const interogateList : CaseCalculationAlternativePart[] = [];
        [...alternativeParts].map((item) => {
          if (item.originalPartId === calcPart.id) {
            interogateList.push(item);
            if (item.selectedAlternativePart !== 9999999999999) {
              const filtercalculationParts = _.filter(
                [...calculationParts],
                function (calcPartObject) {
                  return (
                    calcPartObject.alternativeGuideNumber == calcPart.alternativeGuideNumber &&
                    calcPartObject.alternativePartCode == calcPart.alternativePartCode &&
                    calcPartObject.supplierId == calcPart.supplierId
                  );
                }
              );
              if (!R.isNil(this.props.workflowCalculation) && this.props.workflowCalculation.hasAlternativePartDescription) {
                const nonStockSupplier = this.props.nonStockSuppliers.find(x => x.supplierId === item.supplierId);
                if (!R.isNil(nonStockSupplier) && R.isNil(item.availableQuantity) &&
                  (R.isNil(calcPart.alternativePartDescription) || R.isEmpty(calcPart.alternativePartDescription))
                ) {
                  alternativePartDescriptionError = true;
                  this.validateAlternativePartDescriptionAdd(calcPart.id);
                } else {
                  this.validateAlternativePartDescriptionRemove(calcPart.id);
                }
              }
              const currentPacking = item.packing?.split('|')[0];
              const secondItemOfPacking = item.packing?.split('|')[1];
              if (currentPacking && secondItemOfPacking) {
                const currentPackingNumber = parseFloat(currentPacking);
                if (filtercalculationParts && filtercalculationParts.length) {
                  const pieceNr = _.sumBy(filtercalculationParts, 'pieceNr');
                  console.log('filtercalculationParts');
                  console.log(filtercalculationParts);
                  console.log(pieceNr);
                  if (pieceNr % currentPackingNumber == 0) {
                    return;
                  }
                }
                if (
                  calcPart.pieceNr % currentPackingNumber != 0 ||
                  calcPart.pieceNr < calcPart.minQty
                ) {
                  errorString +=
                  this.translatorService.Tranlate(
                    'MIN_PACKING_ERROR_MSG',
                    'Cantitatea comandata trebuie sa fie '
                  ) +
                    currentPackingNumber +
                    this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!') + ' \n';
                  this.validateOrigPartUnitsAdd(calcPart.id);
                  return;
                }
              } else {
                if (calcPart.pieceNr < calcPart.minQty) {
                  errorString +=
                  this.translatorService.Tranlate(
                    'MIN_PACKING_ERROR_MSG',
                    'Cantitatea comandata trebuie sa fie '
                  ) +
                    calcPart.minQty +
                    this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!') + ' \n';
                  this.validateOrigPartUnitsAdd(calcPart.id);
                  return;
                }
              }
              if (!Number.isInteger(calcPart.pieceNr)) {
                decimalError = true;
                this.validateOrigPartUnitsAdd(calcPart.id);
                return;
              }
              if (!R.isNil(item.availableQuantity) && item.availableQuantity < calcPart.pieceNr) {
                validateError++;
                this.validateOrigPartUnitsAdd(calcPart.id);
              } else {
                this.validateOrigPartUnitsRemove(calcPart.id);
              } 
              
            }
          }
        });
        calcPart.interogationList = JSON.stringify(interogateList);
      });

      const calculationPartsList = _.cloneDeep(this.state.calculationParts);
      if (this.props.workflowCalculation!.serviceOwnStock && this.state.ownStockOrganization) {
        calculationPartsList.forEach((item) => {
          const alternativePartsList = [...alternativeParts].filter(x => x.originalPartId === item.id);
          if (alternativePartsList.length > 0) {  
            return;
          }
          if (item.calculationSection!.code === CalculationSectionCodeEnum.PART &&
            !item.isOrdered && !item.supplierId && item.originalPrice === 0) {
            zeroPriceError = true;
            item.editOriginalPrice = true;
          }
        });
      }

      if (errorString) {
        this.props.enqueueSnackbar(errorString, {
          variant: 'error',
          style: { whiteSpace: 'pre-line' }
        });
        return;
      }

      if (decimalError) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'DECIMAL_ERROR_MSG',
            'Cantitatea comandata trebuie sa fie un numar intreg fara zecimale'
          ),
          {
            variant: 'error'
          }
        );
        return;
      }

      if (alternativePartDescriptionError) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ALTERNATIVE_PART_DESCRIPTION_ERROR_MSG',
            'Va rugam completati descrierea piesei alternative!'
          ),
          {
            variant: 'error'
          }
        )
        return;
      }

      if (zeroPriceError) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ZERO_PRICE_ERROR_MSG',
            'Pretul trebuie sa fie diferit de 0!'
          ),
          {
            variant: 'error'
          }
        );
        this.setState({
          calculationParts: calculationPartsList
        }, () => {
          const calculationPartsList = _.cloneDeep(this.state.calculationParts);
          const alternativeParts = _.cloneDeep(this.state.caseCalculationAlternativePart);
          if (this.props.workflowCalculation!.serviceOwnStock && this.state.ownStockOrganization) {
            calculationPartsList.forEach((item) => {
              const alternativePartsList = [...alternativeParts].filter(x => x.originalPartId === item.id);
              if (alternativePartsList.length > 0) {  
                return;
              }
              if (item.calculationSection!.code === CalculationSectionCodeEnum.PART &&
                  !item.isOrdered && !item.supplierId && item.originalPrice === 0) {
                this.validateOriginalPartPriceAdd(item.id);
              }
            });
          }
        });
        return;
      }

      if (validateError > 0) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        return;
      }

      if (
        calculationParts.filter((item) => R.isNil(item.alternativePartQualityId)).length !== 0 &&
        !this.state.isConfirm
      ) {
        this.setState({ isDialogModifiedOpen: true });
        return;
      }

      this.setState({ executing: true });
      let caseCalculationHistoryId = this.props.calculationHistoryId;
      if (!caseCalculationHistoryId) {
        caseCalculationHistoryId = (
          await this.caseService.CreateCalculationHistory({
            caseId: this.props.caseSettingsState.case!.id,
            formId: this.props.workflowCalculation?.workflowSectionStepFormId,
            calculationId: null,
            calculationTypeId: 2, // Alternative
            workflowCalculationId: this.props.workflowCalculation!.id,
            partsLocationOrganizationId: this.state.selectedOrganizationBranch?.organizationId,
            partsOrganizationId: this.props.appState.appUser?.organizationId,
            organizationId: this.props.appState.appUser!.organizationId,
            organizationName: this.props.appState.appUser!.organization!.name,
            manipulationPercent: this.props.manipulationTariff!.value
          } as CalculationRequest)
        ).id;
      }

      let calculationPartsToSave =  _.cloneDeep(this.state.calculationParts.concat(
        this.getPackageFromCalculationParts([...this.state.calculationParts])
      ));
      console.log('calculationPartsToSave before', calculationPartsToSave);
      console.log('ownStockOrganization: ', this.state.ownStockOrganization, 'serviceOwnStock: ', this.props.workflowCalculation!.serviceOwnStock);
      if (this.props.workflowCalculation!.serviceOwnStock && this.state.ownStockOrganization) {
        calculationPartsToSave.forEach((item) => {
          if (item.calculationSection!.code === CalculationSectionCodeEnum.PART && !item.isOrdered && !item.supplierId) {
            item.supplierId = this.state.ownStockOrganization!.id;
            item.isOwnStock = true;
          }
        });
      }
      calculationPartsToSave = calculationPartsToSave.concat([...this.state.packageCalculationParts]);
      console.log('calculationPartsToSave after', calculationPartsToSave);
      await this.caseService.UpdateCaseCalculationParts(
        calculationPartsToSave,
        this.state.caseCalculationHistoryDetails.comment,
        this.state.caseCalculationHistoryDetails.offerNumber,
        caseCalculationHistoryId,
        this.state.selectedOrganizationBranch?.organizationId || null,
        this.props.appState.appUser!.organizationId,
        this.props.appState.appUser!.organizationId,
        this.props.appState.appUser!.organization!.name,
        false,
        this.props.manipulationTariff!.value,
        null,
        this.props.workflowCalculation!.workflowSectionStepFormId
      );

      if (this.props.appState.appUser!.organization!.changeCaseStatusBasedCalculation) {
        const status = _.find(this.state.caseStatuses, function (caseStatus) {
          return caseStatus.code === CaseStatusCodeEnum.AM;
        });
        const caseStatusRequest = {
          caseId: this.props.caseSettingsState.case!.id,
          statusId: status!.id
        } as ChangeCaseStatusRequest;

        const currentStatus = await this.caseService.ChangeCaseStatus(caseStatusRequest);
        this.props.SetCaseStatus(currentStatus);
      }
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });

      this.props.onSave();

      this.setState({ isConfirm: false });
      this.setState({ executing: false });
    } catch (err) {
      console.log(err);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      this.setState({ executing: false });
    }
  };

  getPackageFromCalculationParts = (calculationParts: CaseCalculationPart[]) => {
    const newcalculationParts = [];
    for (let i = 0; i < calculationParts.length; i++) {
      if (calculationParts[i].package && calculationParts[i].package.length) {
        for (let j = 0; j < calculationParts[i].package.length; j++) {
          newcalculationParts.push(calculationParts[i].package[j]);
        }
      }
    }
    return newcalculationParts;
  };

  handleImportOriginalParts = (event: any) => {
    event.preventDefault();

    const files = event.target.files,
      f = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target!.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      const fullParsedContent = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (fullParsedContent.length === 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      const header = fullParsedContent[0] as any;
      if (
        fullParsedContent.length > 0 &&
        (header[0] !== ImportOriginalPartsFileHeader.H1 ||
          header[1] !== ImportOriginalPartsFileHeader.H2 ||
          header[2] !== ImportOriginalPartsFileHeader.H3 ||
          header[3] !== ImportOriginalPartsFileHeader.H4 ||
          header[4] !== ImportOriginalPartsFileHeader.H5)
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }

      /* Convert array to json*/
      const dataParse = XLSX.utils
        .sheet_to_json(ws, {
          header: [
            'guideNumberImported',
            'nameImported',
            'originalPriceImported',
            'qualityImported',
            'unitsImported'
          ],
          defval: ''
        })
        .slice(1);
      if (dataParse.length <= 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      const partQualityTypes = this.state.partQualityTypes.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName);
      });
      const dataParseArr = dataParse as any[];
      let emptyRows = false as boolean;
      dataParseArr.map((item) => {
        if (
          item.guideNumberImported.toString() === '' ||
          !item.guideNumberImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (item.nameImported.toString() === '' || !item.nameImported.toString().trim().length) {
          emptyRows = true;
        }
        if (
          item.originalPriceImported.toString() === '' ||
          !item.originalPriceImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (
          item.qualityImported.toString() === '' ||
          !item.qualityImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (item.unitsImported.toString() === '' || !item.unitsImported.toString().trim().length) {
          emptyRows = true;
        }
        const partQualityTypesFilter = partQualityTypes.filter(
          (qualityType) => qualityType.code === item.qualityImported.toString()
        );
        if (partQualityTypesFilter.length <= 0) {
          emptyRows = true;
        }
      });
      if (emptyRows === true) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      this.importOriginalParts(dataParse);
    };
    reader.readAsBinaryString(f);
    event.target.value = '';
  };

  importOriginalParts = (importedOriginalParts: any) => {
    try {
      const partQualityTypes = this.state.partQualityTypes.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName);
      });
      const calculationParts = [...this.state.calculationParts];
      const loaders = [...this.state.partLoaders];
      const interogateParts = [...this.state.interogateParts];
      [...importedOriginalParts].map((item, index) => {
        const partQualityTypesFilter = partQualityTypes.filter(
          (qualityType) => qualityType.code === item.qualityImported
        );
        calculationParts.push({
          carPartCategory: partQualityTypesFilter[0],
          carPartCategoryId: partQualityTypesFilter[0].id,
          amount: 0,
          caseCalculationHistoryId: this.props.calculationHistoryId,
          id: -1 * this.state.calculationParts.length - index,
          discount: 0,
          calculationSectionId: this.state.partSection!.id,
          calculationSection: this.state.partSection,
          guideNumber: item.guideNumberImported,
          name: item.nameImported,
          originalPrice: item.originalPriceImported,
          pieceNr: item.unitsImported
        } as CaseCalculationPart);

        loaders.push(false);
        interogateParts.push(false);
      });

      this.setState(
        {
          calculationParts: calculationParts,
          partLoaders: loaders,
          interogateParts: interogateParts
        },
        () => this.props.handleUpdateAlternativeCalcAmount(calculationParts)
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleImportAlternativeParts = (event: any) => {
    event.preventDefault();

    const originalParts = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === this.state.partSection!.id
    );
    if (originalParts.length === 0) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'NO_ORIGINAL_PARTS_FOUND',
          'Nu exista nicio piesa originala!'
        ),
        { variant: 'error' }
      );
      event.target.value = '';
      return;
    }

    const files = event.target.files,
      f = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target!.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const fullParsedContent = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (fullParsedContent.length === 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      const header = fullParsedContent[0] as any;
      if (
        fullParsedContent.length > 0 &&
        (header[0] !== ImportAlternativePartsFileHeader.H1 ||
          header[1] !== ImportAlternativePartsFileHeader.H2 ||
          header[2] !== ImportAlternativePartsFileHeader.H3 ||
          header[3] !== ImportAlternativePartsFileHeader.H4 ||
          header[4] !== ImportAlternativePartsFileHeader.H5 ||
          header[5] !== ImportAlternativePartsFileHeader.H6 ||
          header[6] !== ImportAlternativePartsFileHeader.H7 ||
          header[7] !== ImportAlternativePartsFileHeader.H8 ||
          (header[8] !== undefined && header[8] !== ImportAlternativePartsFileHeader.H9) ||
          (header[9] !== undefined && header[9] !== ImportAlternativePartsFileHeader.H10) ||
          (header[10] !== undefined && header[10] !== ImportAlternativePartsFileHeader.H11))
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }

      /* Convert array to json*/
      const dataParse = XLSX.utils
        .sheet_to_json(ws, {
          header: [
            'originalPartGuideNumberImported',
            'alternativePartGuideNumberImported',
            'nameImported',
            'originalPriceImported',
            'qualityImported',
            'producerImported',
            'supplierImported',
            'deliveryTimeImported',
            'availableQuantityImported',
            'stockLocationImported',
            'remarksImported'
          ],
          defval: ''
        })
        .slice(1);

      if (dataParse.length <= 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      const partQualityTypes = this.state.partQualityTypes.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName);
      });
      const supplierList = this.state.supplierList;
      const dataParseArr = dataParse as any[];
      let emptyRows = false as boolean;

      const originalPartsGuideNrs = [] as any[];
      originalParts.map((item) => {
        originalPartsGuideNrs.push({ guideNumber: item.guideNumber });
      });

      dataParseArr.map((item) => {
        if (
          item.originalPartGuideNumberImported.toString() === '' ||
          !item.originalPartGuideNumberImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (
          item.alternativePartGuideNumberImported.toString() === '' ||
          !item.alternativePartGuideNumberImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (item.nameImported.toString() === '' || !item.nameImported.toString().trim().length) {
          emptyRows = true;
        }
        if (
          item.originalPriceImported.toString() === '' ||
          !item.originalPriceImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (
          item.qualityImported.toString() === '' ||
          !item.qualityImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (
          item.producerImported.toString() === '' ||
          !item.producerImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (
          item.supplierImported.toString() === '' ||
          !item.supplierImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (
          item.deliveryTimeImported.toString() === '' ||
          !item.deliveryTimeImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        const partQualityTypesFilter = partQualityTypes.filter(
          (qualityType) => qualityType.code === item.qualityImported.toString()
        );
        if (partQualityTypesFilter.length <= 0) {
          emptyRows = true;
        }
        const filterByOriginalGuideNumber = originalPartsGuideNrs.filter(
          (cls) => item.originalPartGuideNumberImported.toString() === cls.guideNumber.toString()
        );
        if (filterByOriginalGuideNumber.length === 0) {
          emptyRows = true;
        }

        const supplierListFilter = supplierList.filter(
          (supplier) => supplier.companyNumber === item.supplierImported.toString()
        );
        if (supplierListFilter.length <= 0) {
          emptyRows = true;
        }
      });
      if (emptyRows === true) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      this.importAlternativeParts(dataParse);
    };
    reader.readAsBinaryString(f);
    event.target.value = '';
  };

  importAlternativeParts = (importedAlternativeParts: any) => {
    try {
      const partQualityTypes = this.state.partQualityTypes.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName);
      });
      const supplierList = this.state.supplierList;
      const calculationParts = this.state.calculationParts.filter(
        (item) => item.calculationSectionId === this.state.partSection!.id
      );
      const alternativeParts = [...this.state.caseCalculationAlternativePart];

      calculationParts.map((calcPart) => {
        [...importedAlternativeParts].map((item, index) => {
          if (item.originalPartGuideNumberImported.toString() === calcPart.guideNumber.toString()) {
            const partQualityTypesFilter = partQualityTypes.filter(
              (qualityType) => qualityType.code === item.qualityImported
            );
            const supplierListFilter = supplierList.filter(
              (supplier) =>
                supplier.companyNumber !== '' &&
                supplier.companyNumber === item.supplierImported.toString()
            );
            alternativeParts.push({
              id: this.state.caseCalculationAlternativePart.length + index,
              alternativeGuideNumber: item.alternativePartGuideNumberImported,
              alternativePartQuality: partQualityTypesFilter[0],
              alternativePartQualityId: partQualityTypesFilter[0].id,
              alternativePrice: item.originalPriceImported,
              availableQuantity: isNaN(Number.parseFloat(item.availableQuantityImported))
                ? null
                : Number.parseFloat(item.availableQuantityImported),
              deliveryTime: item.deliveryTimeImported,
              name: item.nameImported,
              originalPartId: calcPart.id,
              producer: item.producerImported,
              remarks: item.remarksImported,
              selectedAlternativePart: 9999999999999,
              stockLocation: item.stockLocationImported,
              supplierId: supplierListFilter.length > 0 ? supplierListFilter[0].id : null,
              supplier: supplierListFilter.length > 0 ? supplierListFilter[0] : null
            } as CaseCalculationAlternativePart);
          }
        });
      });

      this.setState(
        {
          // calculationParts: calculationParts,
          caseCalculationAlternativePart: alternativeParts,
          caseCalculationAlternativePartCopy: alternativeParts
        },
        () => this.props.handleUpdateAlternativeCalcAmount(calculationParts)
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderPartsButtons = () => {
    return (
      <CardContent style={{ minHeight: '450px' }}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {!R.isNil(this.props.workflowCalculation) &&
            !R.isNil(this.props.workflowCalculation.addOriginalPart) &&
            this.props.workflowCalculation.addOriginalPart ? (
              <Button
                onClick={(e) => this.newCalculationPart(null)}
                className="mr-2"
                variant="contained"
                color="primary"
              >
                {this.translatorService.Tranlate(
                  'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_ADD_ORIGINAL_PART_LABEL',
                  'Adauga piesa originala'
                )}
              </Button>
            ) : null}
            {!R.isNil(this.props.workflowCalculation) &&
            !R.isNil(this.props.workflowCalculation.importOriginalParts) &&
            this.props.workflowCalculation.importOriginalParts ? (
              <Button
                onChange={(e: any) => {
                  this.handleImportOriginalParts(e);
                }}
                component="label"
                variant="contained"
                color="secondary"
              >
                {this.translatorService.Tranlate(
                  'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_IMPORT_NEW_PARTS_LABEL',
                  'Importa piese noi'
                )}
                <input type="file" style={{ display: 'none' }} />
              </Button>
            ) : null}
          </div>
          <div>
            {!R.isNil(this.props.workflowCalculation) &&
            !R.isNil(this.props.workflowCalculation.importAlternativeParts) &&
            this.props.workflowCalculation.importAlternativeParts ? (
              <Button
                onChange={(e: any) => {
                  this.handleImportAlternativeParts(e);
                }}
                component="label"
                variant="contained"
                color="secondary"
              >
                {this.translatorService.Tranlate(
                  'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_IMPORT_ALTERNATIVE_PARTS_LABEL',
                  'Importa piese alternative'
                )}
                <input type="file" style={{ display: 'none' }} />
              </Button>
            ) : null}
          </div>
        </div>
        <div className="mt-3 text-right">
          <Button 
            onClick={this.save} 
            className="text-right" 
            variant="contained" 
            color="primary"
            disabled={this.state.executing}
            >
            {this.translatorService.Tranlate('SAVE', 'Salveaza')}
          </Button>
        </div>
      </CardContent>
    );
  };

  handleOriginalPartPriceChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const calculationParts = [...this.state.calculationParts];
    const index = calculationParts.findIndex((item) => item.id === id);
    calculationParts[index] = { ...calculationParts[index], originalPrice:  e.target.value === null ? 0 : parseFloat(e.target.value)};
    
    if (this.props.workflowCalculation!.serviceOwnStock && this.state.ownStockOrganization) {
      if (!calculationParts[index].supplierId && calculationParts[index].originalPrice === 0) {
        this.validateOriginalPartPriceAdd(calculationParts[index].id);
      } else {
        this.validateOriginalPartPriceRemove(calculationParts[index].id);
      }
    }

    this.setState({ calculationParts: calculationParts });
  };

  handleOriginalPartGuideNumberChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const calculationParts = [...this.state.calculationParts];
    const index = calculationParts.findIndex((item) => item.id === id);
    calculationParts[index] = { ...calculationParts[index], guideNumber: (e.target.value).trimLeft()};
    this.setState({ calculationParts: calculationParts });
  };

  handleAlternativePartDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const calculationParts = [...this.state.calculationParts];
    const index = calculationParts.findIndex((item) => item.id === id);
    calculationParts[index] = {
      ...calculationParts[index],
      alternativePartDescription: e.target.value
    };
    const caseCalculationAlternativePartCopy = [...this.state.caseCalculationAlternativePart];
    const alternativePartChecked = caseCalculationAlternativePartCopy.find(
      (item) =>
        item.originalPartId === calculationParts[index].id &&
        item.selectedAlternativePart !== 9999999999999
    );

    if (!R.isNil(alternativePartChecked)) {
      if (!R.isNil(this.props.workflowCalculation) && this.props.workflowCalculation.hasAlternativePartDescription) {
        if (R.isNil(calculationParts[index].alternativePartDescription) ||
          R.isEmpty(calculationParts[index].alternativePartDescription)
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ALTERNATIVE_PART_DESCRIPTION_ERROR_MSG',
              'Va rugam sa completati campul Descriere piesa alt'
            ),
            { 
              variant: 'error',
              autoHideDuration: 5000 
            }
          );
          this.validateAlternativePartDescriptionAdd(calculationParts[index].id);
        } else {
          this.validateAlternativePartDescriptionRemove(calculationParts[index].id);
        }
      }
    }

    this.setState({ calculationParts: calculationParts });
  };

  handleOriginalPartQuantityChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const calculationParts = [...this.state.calculationParts];
    const index = calculationParts.findIndex((item) => item.id === id);
    calculationParts[index] = {
      ...calculationParts[index],
      pieceNr: isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)
    };
    if (calculationParts[index].package && calculationParts[index].package.length) {
      for (let i = 0; i < calculationParts[index].package.length; i++) {
        calculationParts[index].package[i].pieceNr = isNaN(parseFloat(e.target.value))
          ? 0
          : parseFloat(e.target.value);
      }
    }

    const caseCalculationAlternativePartCopy = [...this.state.caseCalculationAlternativePart];
    const alternativePartChecked = caseCalculationAlternativePartCopy.find(
      (item) =>
        item.originalPartId === calculationParts[index].id &&
        item.selectedAlternativePart !== 9999999999999
    );

    if (!R.isNil(alternativePartChecked)) {
      const currentPacking = alternativePartChecked.packing?.split('|')[0];
      const secondItemOfPacking = alternativePartChecked.packing?.split('|')[1];
      if (currentPacking && secondItemOfPacking) {
        const currentPackingNumber = parseFloat(currentPacking);
        if (
          parseFloat(e.target.value) % currentPackingNumber != 0 ||
          parseFloat(e.target.value) < alternativePartChecked.minQty
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'MIN_PACKING_ERROR_MSG',
              'Cantitatea comandata trebuie sa fie '
            ) +
              currentPackingNumber +
              this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!'),
            { variant: 'error',
              autoHideDuration: 5000 }
          );
        }
      } else if (parseFloat(e.target.value) < alternativePartChecked.minQty) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'MIN_PACKING_ERROR_MSG',
            'Cantitatea comandata trebuie sa fie '
          ) +
            alternativePartChecked.minQty +
            this.translatorService.Tranlate('MIN_PACKING_MULTIPLE_ERROR_MSG',  ' sau multiplul acesteia!'),
          { variant: 'error',
          autoHideDuration: 5000 }
        );
      }
      if (
        !R.isNil(alternativePartChecked!.availableQuantity) &&
        alternativePartChecked!.availableQuantity < parseFloat(e.target.value)
      ) {
        this.validateOrigPartUnitsAdd(calculationParts[index].id);
      } else {
        this.validateOrigPartUnitsRemove(calculationParts[index].id);
      }
    }

    this.setState({ calculationParts: calculationParts }, () =>
      this.props.handleUpdateAlternativeCalcAmount(calculationParts)
    );
  };

  checkPackageNotification = () => {
    const { packageCalculationParts, calculationParts } = this.state;
  
    // Găsește piesele care se potrivesc pe baza guideNumber și name
    const matchingParts = calculationParts.filter(part => 
      packageCalculationParts.some(pkgPart => 
        (pkgPart.guideNumber === part.guideNumber && pkgPart.name === part.name)
        ||
        (pkgPart.repairMethod === part.repairMethod && OriginalPartShortNumberException.includes(pkgPart.repairMethod))
      )
    );
  
    if (matchingParts.length > 0) {
      const combinedMessage = matchingParts.map(({ guideNumber, name }) => 
        `\n ${guideNumber} - ${name} `
      ).join(", ");


      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'PARTS_ALREADY_EXIST_IN_PACKAGE',
          'Piesele de mai jos se regasesc deja in pachetul selectat si nu mai pot fi adaugate: '
        ) + combinedMessage,
        {
          variant: 'warning',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 5000 
        }
      );
    } 
  };
  

  renderCalculationPieces = () => {
    return (
      <div>
        {this.state.calculationParts.filter(
          (item) => item.calculationSectionId === this.state.partSection!.id && !item.isOrdered
        ).length > 0 ? (
          this.state.calculationParts
            .filter(
              (item) => item.calculationSectionId === this.state.partSection!.id && !item.isOrdered
            )
            .map((item, index) => {
              return (
                <div key={item.id}>
                  <Box m={1} p={1}>
                    <Card>
                      <CardContent className="p-3">
                        <Grid container spacing={4}>
                          <Grid item xs={2}>
                            <div className="font-weight-bold mb-3">
                              {R.isNil(item!.name) ? '' : item!.name}
                              {!R.isNil(this.props.workflowCalculation) && (
                                <div className="d-flex flex-column float-right align-items-center">
                                  {!R.isNil(this.props.workflowCalculation.addOriginalPart) && this.props.workflowCalculation.addOriginalPart ? (
                                    <Tooltip
                                      title={this.translatorService.Tranlate(
                                        'COPY_ORIGINALS_PARTS_TITLE',
                                        'Copiaza'
                                      )}
                                    >
                                      <IconButton
                                        onClick={(e) => this.copyCalculationPart(item.id)}
                                        aria-label="copy"
                                        color="primary"
                                        className="pt-0"
                                      >
                                        <FileCopyOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                ) : null}
                                  {!R.isNil(this.props.workflowCalculation.addNotifySupportOriginalPart) && this.props.workflowCalculation.addNotifySupportOriginalPart ? (
                                    <Tooltip 
                                      title={this.translatorService.Tranlate(
                                        'NOTIFICATION_ORIGINALS_PARTS_TITLE',
                                        'Notifica suport'
                                      )}
                                    >
                                      <IconButton
                                        aria-label="notification"
                                        color="primary"  
                                        className="pt-1"
                                        onClick={(e) => this.notifySupportCalculationPart(item.id)}
                                      >
                                        <NotificationImportantOutlinedIcon style={{ fontSize: '28px' }} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            className="d-flex align-items-center justify-content-between"
                          >
                            <TextField
                              fullWidth
                              id="guideNumber"
                              disabled={
                                !R.isNil(this.props.workflowCalculation)
                                  ? !R.isNil(this.props.workflowCalculation.editGuideNumber)
                                    ? this.props.workflowCalculation.editGuideNumber
                                      ? false
                                      : true
                                    : false
                                  : false
                              }
                              name="guideNumber"
                              className="mr-3"
                              label={this.translatorService.Tranlate(
                                'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CODE_LABEL',
                                'Cod'
                              )}
                              value={R.isNil(item!.guideNumber) ? '' : item!.guideNumber}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.handleOriginalPartGuideNumberChange(e, item.id);
                              }}
                            />
                            {!R.isNil(this.props.workflowCalculation) && this.props.workflowCalculation.hasAlternativePartDescription ? (
                              <TextField
                                fullWidth
                                id="alternativePartDescription"
                                name="alternativePartDescription"
                                className={`mr-3 id-${item.id}-alternative-part-description`}
                                label={this.translatorService.Tranlate(
                                  'CASE_CALCULATION_ALTERNATIVE_ALTERNATIVE_DESCRIPTION_LABEL',
                                  'Descriere piesa alt'
                                )}
                                value={R.isNil(item!.alternativePartDescription) ? '' : item!.alternativePartDescription}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  this.handleAlternativePartDescriptionChange(e, item.id);
                                }}
                              />
                              ) : null
                            }
                            {
                              item!.editOriginalPrice ? (
                                <TextField
                                  fullWidth
                                  id="price"
                                  disabled={false}
                                  name="price"   
                                  className={`mr-3 id-${item.id}-price`}
                                  label={this.translatorService.Tranlate(
                                    'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_PRICE_LABEL',
                                    'Pret'
                                  )}
                                  value={R.isNil(item!.originalPrice) ? '' : item!.originalPrice.toFixed(2)}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleOriginalPartPriceChange(e, item.id);
                                  }}
                                  autoFocus={false}
                                  InputProps={{
                                    inputComponent: this.NumberFormatCustom
                                  }}
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  id="price"
                                  disabled={true}
                                  name="price"
                                  className={`mr-3 id-${item.id}-price`}
                                  label={this.translatorService.Tranlate(
                                    'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_PRICE_LABEL',
                                    'Pret'
                                  )}
                                  value={
                                    R.isNil(item!.originalPrice)
                                      ? ''
                                      : Utils.countryNumberFormat((
                                          Math.round(
                                            item!.originalPrice * (1 - item!.discount / 100) * 100
                                          ) / 100
                                        ).toFixed(2), this.props.appState.appUser?.organization?.country ?? null)
                                  }
                                />
                              )
                            }
                            <TextField
                              fullWidth
                              id="quality"
                              disabled={true}
                              name="quality"
                              className="mr-3"
                              label={this.translatorService.Tranlate(
                                'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_QUALITY_LABEL',
                                'Calitate'
                              )}
                              value={
                                R.isNil(item!.carPartCategory!.displayName)
                                  ? ''
                                  : item!.carPartCategory!.displayName
                              }
                            />
                            <TextField
                              fullWidth
                              id="quantity"
                              disabled={
                                !R.isNil(this.props.workflowCalculation)
                                  ? !R.isNil(this.props.workflowCalculation.editQuantity)
                                    ? this.props.workflowCalculation.editQuantity
                                      ? false
                                      : true
                                    : false
                                  : false
                              }
                              name="quantity"
                              className={`mr-3 id-${item.id}`}
                              label={this.translatorService.Tranlate(
                                'CALCULATION_DETAILS_UNITS_HEADER',
                                'Unitati'
                              )}
                              value={R.isNil(item!.pieceNr) ? '' : item!.pieceNr.toFixed(2)}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.handleOriginalPartQuantityChange(e, item.id);
                              }}
                              autoFocus={false}
                              InputProps={{
                                inputComponent: this.NumberFormatCustom
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} style={{ alignItems: 'end' }} className="d-flex">
                            {!R.isNil(this.props.workflowCalculation) &&
                            !R.isNil(this.props.workflowCalculation.deleteOriginalPart) &&
                            this.props.workflowCalculation.deleteOriginalPart ? (
                              <Button
                                onClick={(e) => this.deleteCalculationPart(item.id)}
                                size="small"
                                variant="contained"
                                color="secondary"
                                className="mr-3"
                              >
                                <span className="btn-wrapper--label">
                                  {this.translatorService.Tranlate(
                                    'DELETE_ORIGINAL_PART_LABEL',
                                    'Sterge piesa'
                                  )}
                                </span>
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['fas', 'trash-alt']} />
                                </span>
                              </Button>
                            ) : null}
                            <Button
                              onClick={async (e) => {
                                this.isDialogOpenConfirmationHandler(async () => {
                                  await this.interogateAlternativeParts(
                                    item.id,
                                    item.guideNumber,
                                    item,
                                    index
                                  );
                                });
                              }}
                              className="float-right"
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              {this.translatorService.Tranlate('INTEROGATE', 'Interogheaza')}
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item xs={12} style={{ paddingTop: '0px' }}>
                            <div>
                              {!R.isNil(this.props.workflowCalculation) &&
                              !R.isNil(this.props.workflowCalculation.addAlternativePart) &&
                              this.props.workflowCalculation.addAlternativePart ? (
                                <Tooltip
                                  title={this.translatorService.Tranlate(
                                    'ADD_ALTERNATIVE_PARTS_TITLE',
                                    'Adauga piesa alternativa'
                                  )}
                                >
                                  <IconButton
                                    onClick={(e) => this.addAlternativePart(item.id)}
                                    aria-label="add"
                                    color="primary"
                                    className="pt-0 float-right"
                                  >
                                    <AddCircleTwoToneIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                            </div>
                            <div className="table-responsive">
                              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                                <ScaleLoader
                                  color={'var(--primary)'}
                                  loading={this.state.partLoaders[index]}
                                />
                              </div>
                              {this.state.partLoaders[index] ? null : (
                                <div className="tableFixHead">
                                  <table className="table table-striped table-hover table-bordered mb-4">
                                    <thead
                                      style={{
                                        display: 'block',
                                        width: '100%',
                                        position: 'sticky',
                                        top: '0',
                                        backgroundColor: '#f8f9fa', // Make sure the header has a background color
                                        zIndex: 1 // Ensure header stays on top
                                      }}
                                      className="thead-light"
                                    >
                                      <tr
                                        style={{
                                          display: 'table',
                                          width: '100%',
                                          tableLayout: 'fixed'
                                        }}
                                      >
                                        <th
                                          className="text-center"
                                          scope="col"
                                          style={{
                                            width: '50px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          <Button
                                            disabled={
                                              this.state.caseCalculationAlternativePart.filter(
                                                (alternativePart) =>
                                                  alternativePart.originalPartId === item.id
                                              ).length > 0 &&
                                              this.state.caseCalculationAlternativePart
                                                .filter(
                                                  (alternativePart) =>
                                                    alternativePart.originalPartId === item.id
                                                )
                                                .some(
                                                  (el) => el.selectedAlternativePart !== 9999999999999
                                                )
                                                ? false
                                                : true
                                            }
                                            title={this.translatorService.Tranlate(
                                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CLEAR_LABEL',
                                              'Goleste'
                                            )}
                                            onClick={(e) =>
                                              this.clearSelectedAlternativeParts(item.id)
                                            }
                                            className="min min-w-auto"
                                            size="small"
                                            variant="contained"
                                            color="secondary"
                                          >
                                            <span className="btn-wrapper--icon">
                                              <FontAwesomeIcon icon={['fas', 'ban']} />
                                            </span>
                                          </Button>
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            width: '150px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CODE_LABEL',
                                            'Cod'
                                          )}
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            width: '200px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'ORDER_PART_DETAILS_NAME_LABEL',
                                            'Denumire'
                                          )}
                                        </th>
                                        {!R.isNil(this.props.workflowCalculation) && this.props.workflowCalculation!.alternativeNetPrice ? (
                                          <th
                                            scope="col"
                                            style={{
                                              width: '85px',
                                              paddingLeft: '4px',
                                              paddingRight: '4px'
                                            }}
                                          >
                                            {this.translatorService.Tranlate(
                                              'CALCULATION_DETAILS_NET_PRICE_LKQ_HEADER',
                                              'Pret Net'
                                            )}
                                          </th>
                                        ): null}
                                        <th
                                          scope="col"
                                          style={{
                                            width: '85px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_PRICE_LABEL',
                                            'Pret'
                                          )}
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            width: '70px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_QUALITY_LABEL',
                                            'Calitate'
                                          )}
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            width: '130px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_DELIVERY_LABEL',
                                            'Livrare'
                                          )}
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            width: '100px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_OWNER_LABEL_ACRONYM',
                                            'Producator'
                                          )}
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            width: '100px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_SUPLIER_LABEL',
                                            'Furnizor'
                                          )}
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            width: '50px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_QUANTITY_INFO_LABEL',
                                            'Cant.'
                                          )}
                                        </th>
                                        <th
                                          scope="col"
                                          style={{
                                            width: '300px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px'
                                          }}
                                        >
                                          {this.translatorService.Tranlate(
                                            'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_STOCK_LOCATION_LABEL',
                                            'Locatie'
                                          )}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      style={{
                                        wordBreak: 'break-word',
                                        display: 'block',
                                        // maxHeight: '250px',
                                        width: '100%',
                                        tableLayout: 'fixed'
                                      }}
                                    >
                                      {this.state.caseCalculationAlternativePart.filter(
                                        (alternativePart) =>
                                          alternativePart.originalPartId === item.id
                                      ).length > 0 ? (
                                        this.state.caseCalculationAlternativePart
                                          .filter(
                                            (alternativePart) =>
                                              alternativePart.originalPartId === item.id
                                          )
                                          .sort(function (a: any, b: any) {
                                            console.log(a.supplierName);
                                            return a.supplierName - b.supplierName;
                                          })
                                          .map((alternativePart, alternativePartindex) => {
                                            // Creează o referință pentru fiecare rând
                                            this.rowRefs[alternativePart.id] = React.createRef();
                                            return (
                                              <tr
                                                key={alternativePart.id}
                                                ref={this.rowRefs[alternativePart.id]}
                                                style={{
                                                  display: 'table',
                                                  width: '100%',
                                                  tableLayout: 'fixed'
                                                }}
                                              >
                                                <td
                                                  className="text-center"
                                                  style={{
                                                    width: '50px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  <Radio
                                                    style={{ padding: '0px' }}
                                                    disabled={
                                                      (!R.isNil(this.props.workflowCalculation)
                                                        ? !R.isNil(
                                                            this.props.workflowCalculation
                                                              .selectAlternativePart
                                                          )
                                                          ? this.props.workflowCalculation
                                                              .selectAlternativePart
                                                            ? false
                                                            : true
                                                          : false
                                                        : false) || alternativePart.isChild
                                                    }
                                                    checked={this.handleAlternativePartChecked(
                                                      alternativePart.selectedAlternativePart,
                                                      alternativePart.id,
                                                      alternativePart.partFromGroup
                                                    )}
                                                    onChange={(e) =>
                                                      this.handleAlternativePartSelect(
                                                        alternativePart.id,
                                                        alternativePart.originalPartId,
                                                        alternativePart
                                                      )
                                                    }
                                                    value={alternativePart.id}
                                                    id={alternativePart.id.toString()}
                                                    name={item.id.toString()}
                                                  />
                                                </td>
                                                <td
                                                  style={{
                                                    width: '150px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  {alternativePart.alternativeGuideNumber}
                                                </td>
                                                <td
                                                  style={{
                                                    width: '200px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  {!R.isNil(alternativePart.remarks) &&
                                                  alternativePart.remarks !== '' ? (
                                                    <Tooltip
                                                      placement="top"
                                                      title={
                                                        <div>
                                                          <div
                                                            style={{ fontSize: '16px' }}
                                                            className="text-center font-weight-bold mb-2 text-danger"
                                                          >
                                                            {this.translatorService.Tranlate(
                                                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_REMARKS_LABEL',
                                                              'Observatii'
                                                            )}
                                                          </div>
                                                          <div>{alternativePart.remarks}</div>
                                                        </div>
                                                      }
                                                    >
                                                      <div>{alternativePart.name}</div>
                                                    </Tooltip>
                                                  ) : (
                                                    <Tooltip
                                                      placement="top"
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                      }}
                                                      title={
                                                        <div>
                                                          <div>{alternativePart.name}</div>
                                                        </div>
                                                      }
                                                    >
                                                      <div>{alternativePart.name}</div>
                                                    </Tooltip>
                                                  )}
                                                </td>
                                                {!R.isNil(this.props.workflowCalculation) && this.props.workflowCalculation!.alternativeNetPrice ? (
                                                  <td
                                                    style={{
                                                      width: '85px',
                                                      paddingLeft: '4px',
                                                      paddingRight: '4px'
                                                    }}
                                                  >
                                                    {alternativePart.alternativeNetPrice ? Utils.countryNumberFormat((
                                                      Math.round(
                                                        alternativePart.alternativeNetPrice * 100
                                                      ) / 100
                                                    ).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) 
                                                    : null}
                                                  </td> 
                                                ) : null}
                                                <td
                                                  style={{
                                                    width: '85px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                {R.isNil(alternativePart!.alternativePartDiscount)
                                                    ? Utils.countryNumberFormat(
                                                        (Math.round(alternativePart.alternativePrice * 100) / 100).toFixed(2),
                                                        this.props.appState.appUser?.organization?.country ?? null
                                                      )
                                                    : Utils.countryNumberFormat(
                                                        (
                                                          Math.round(
                                                            alternativePart.alternativePrice * (1 - alternativePart!.alternativePartDiscount / 100) * 100
                                                          ) / 100
                                                        ).toFixed(2),
                                                        this.props.appState.appUser?.organization?.country ?? null
                                                      )
                                                  }
                                                </td>
                                                <td
                                                  style={{
                                                    width: '70px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  {R.isNil(alternativePart.alternativePartQuality)
                                                    ? ''
                                                    : alternativePart.alternativePartQuality.code}
                                                </td>
                                                <td
                                                  style={{
                                                    width: '130px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  <Tooltip
                                                    placement="top"
                                                    style={{
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis'
                                                    }}
                                                    title={
                                                      <div>{alternativePart.deliveryTime.toString().includes("in cazul in care piesa se afla in stocul extern") ? 
                                                      <><span>{alternativePart.deliveryTime.toString().split("in cazul in care piesa se afla in stocul extern")[0]}</span><b style={{ color: 'red' }}>in cazul in care piesa se afla in stocul extern</b></>: alternativePart.deliveryTime}</div>
                                                    }
                                                  >
                                                    <div>{alternativePart.deliveryTime.toString().includes("in cazul in care piesa se afla in stocul extern") ? 
                                                      <><span>{alternativePart.deliveryTime.toString().split("in cazul in care piesa se afla in stocul extern")[0]}</span><b style={{ color: 'red' }}>in cazul in care piesa se afla in stocul extern</b></>: alternativePart.deliveryTime}</div>
                                                  </Tooltip>
                                                </td>
                                                <td
                                                  style={{
                                                    width: '100px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  {alternativePart.producer}
                                                </td>
                                                <td
                                                  style={{
                                                    width: '100px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  {!R.isNil(alternativePart.supplier)
                                                    ? alternativePart.supplier!.name
                                                    : ''}
                                                </td>
                                                <td
                                                  style={{
                                                    width: '50px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  {alternativePart.quantityAdditionalInfo &&
                                                  alternativePart.availableQuantity ? (
                                                    <Tooltip
                                                      {...rest}
                                                      title={
                                                        <div>
                                                          <div>
                                                            {alternativePart.quantityAdditionalInfo}
                                                          </div>
                                                        </div>
                                                      }
                                                    >
                                                      <div>
                                                        <span>
                                                          {alternativePart.availableQuantity}
                                                        </span>
                                                        <FontAwesomeIcon
                                                          icon={['fas', 'info-circle']}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                  ) : (
                                                  (this.displayCalculationPartSupplier(alternativePart.supplierId).toUpperCase() == SupplierCode.LKQ.toString().toUpperCase()
                                                    ? (
                                                      <span>
                                                        {isNaN(alternativePart.availableQuantity)
                                                          ? null
                                                          : alternativePart.availableQuantity > 5 ? '5+' : alternativePart.availableQuantity}
                                                      </span>
                                                    ) 
                                                    : (
                                                      <span>
                                                        {isNaN(alternativePart.availableQuantity)
                                                          ? null
                                                          : alternativePart.availableQuantity}
                                                      </span>
                                                    ) 
                                                  )        
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    width: '300px',
                                                    paddingLeft: '4px',
                                                    paddingRight: '4px'
                                                  }}
                                                >
                                                  {alternativePart.stockLocation ? (
                                                    <Tooltip
                                                      {...rest}
                                                      title={
                                                        <div>
                                                          <div>
                                                            {this.translatorService.Tranlate(
                                                              'ORDER_PART_DETAILS_LOCATION_LABEL',
                                                              'Locatie'
                                                            ) +
                                                              ': ' +
                                                              alternativePart.stockLocation}
                                                          </div>
                                                        </div>
                                                      }
                                                    >
                                                      <div>
                                                        <span>
                                                          {
                                                            alternativePart.stockLocation.split(
                                                              '\n'
                                                            )[0]
                                                          }
                                                        </span>
                                                        <FontAwesomeIcon
                                                          icon={['fas', 'info-circle']}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                  ) : (
                                                    alternativePart.stockLocation
                                                  )}

                                                  {!R.isNil(alternativePart.additionalInfo) &&
                                                  alternativePart.additionalInfo !== '' &&
                                                  AdditionalInfoCode.PRODUS_NU_POATE_FI_RETURNAT ==
                                                    alternativePart.additionalInfo ? (
                                                    <p
                                                      style={{
                                                        color: 'red'
                                                      }}
                                                    >
                                                      {alternativePart.additionalInfo}
                                                    </p>
                                                  ) : (
                                                    ''
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })
                                      ) : (
                                        <tr
                                          style={{
                                            display: 'table',
                                            width: '100%',
                                            tableLayout: 'fixed'
                                          }}
                                        >
                                          <td className="text-center">
                                            <span>
                                              {this.translatorService.Tranlate(
                                                'NO_RECORDS_TO_DISPLAY',
                                                'Nu exista inregistrari de afisat'
                                              )}
                                            </span>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>

                  <Divider />
                </div>
              );
            })
        ) : (
          <div>
            <h6 className="p-4 text-center mb-0">
              {this.translatorService.Tranlate(
                'NO_RECORDS_TO_DISPLAY',
                'Nu exista inregistrari de afisat'
              )}
            </h6>
            <Divider />
          </div>
        )}
        {this.state.calculationParts.filter(
          (item) => item.calculationSectionId === this.state.partSection!.id && item.isOrdered
        ).length > 0 ? (
          <div>
            <Box m={1} p={1}>
              {this.renderOrderedPartsTable()}
            </Box>
          </div>
        ) : (
          ''
        )}
        {this.renderPartsButtons()}
      </div>
    );
  };

  closeModifiedDialog = async () => {
    this.setState({
      isDialogModifiedOpen: false,
      isConfirm: false
    });
  };

  onDropDownChange = (currentNode: any, selectedNodes: any) => {
    // react-dropdown-tree-select doesn't have an intrinsic mechanism for keeping track of selected items, only provides what is selected
    // So we have to manually check/uncheck the leaves from this tree used by this component
    let newSelectionOrganizationBranch: OrganizationBranch | null = null;
    if (this.state.selectedOrganizationBranch) {
      const oldSelectionOrganizationBranch = this.findRecursive(
        this.state.organizationBranches,
        this.state.selectedOrganizationBranch.organizationId
      );
      if (oldSelectionOrganizationBranch) {
        oldSelectionOrganizationBranch.checked = false;
      }
    }

    if (selectedNodes.length == 1) {
      const newPartialSelectionOrganizationBranch =
        this.findRecursive(
          this.state.organizationBranches,
          (selectedNodes[0] as OrganizationBranch)?.organizationId
        ) || null;

      if (newPartialSelectionOrganizationBranch) {
        newPartialSelectionOrganizationBranch.checked = true;
        newSelectionOrganizationBranch = newPartialSelectionOrganizationBranch;
      }
    }

    if (this.state.caseCalculationAlternativePart.length > 0) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('REINTEROGATE_ALTERANTIVE_PARTS', 'Adresa de livrare a fost modificată , vă rugăm să reinterogați piesele!'),
        { 
          variant: 'warning',
          autoHideDuration: 6000
        }
      );
    }

    const caseCalculationOriginalPartsCopy = this.state.calculationParts.map((item: CaseCalculationPart) => {
      if (item.calculationSectionId === this.state.partSection!.id && !item.isOrdered) {
        return {
          ...item,
          alternativePrice: null,
          alternativePartDiscount: null,
          alternativeGuideNumber: null,
          alternativePartCode: null,
          alternativePartName: null,
          alternativePartQuality: null,
          alternativePartQualityId: null,
          alternativePartStockLocation: null,
          alternativePartStockQuantity: null,
          alternativePartRemarks: null,
          minQty: 0,
          packing: null,
          bundleItem: null,
          bundleNo: null,
          bundleQuantity: null,
          supplierId: null,
          supplier: null,
          producer: '',
          deliveryTime: '',
          alternativeNetPrice: null,
          alternativePriceWithoutDiscount: null
        };
      } else return item;
    });
    
    this.setState({
      ...this.state,
      selectedOrganizationBranch: newSelectionOrganizationBranch,
      caseCalculationAlternativePart: [],
      caseCalculationAlternativePartCopy: [],
      calculationParts: caseCalculationOriginalPartsCopy
    },
    () => this.props.handleUpdateAlternativeCalcAmount(caseCalculationOriginalPartsCopy));
  };

  findRecursive = (
    arr: OrganizationBranch[],
    organizationId: number
  ): OrganizationBranch | null => {
    let result: OrganizationBranch | null = null;
    for (const entry in arr) {
      if (arr[entry].organizationId == organizationId) {
        result = arr[entry];
        break;
      }
      if (arr[entry].children?.length) {
        const partialResult = this.findRecursive(arr[entry].children, organizationId);
        if (partialResult != null) {
          result = partialResult;
          break;
        }
      }
    }
    return result;
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appuserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.orderService = (this.context as AppContext).orderService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div>
            <div className="m-3 text-center">
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={this.state.isDialogOpenConfirmationOrgPartner}
                fullWidth={true}
                maxWidth={'sm'}
              >
                <div className="text-center p-5">
                  <h4 className="font-weight-bold mt-4">
                    {this.translatorService.Tranlate(
                      'INTEROGATE_ALTERNATIVE_PARTS_DIALOG_TITLE',
                      'Disponibilitatea pieselor va fi afisata conform adresei'
                    ) +
                      ' ' +
                      this.state.selectedOrganizationBranch?.organizationName +
                      ' ' +
                      (this.state.selectedOrganizationBranch?.organizationAddress ?? '')}
                  </h4>
                  <div className="pt-4">
                    <Button
                      onClick={() => {
                        this.setState({
                          isDialogOpenConfirmationOrgPartner: false
                        });
                        if (this.state.isDialogOpenFunctionToBeCalled) {
                          this.state.isDialogOpenFunctionToBeCalled();
                        }
                      }}
                      color="primary"
                      variant="contained"
                      className="mx-1"
                    >
                      <span className="btn-wrapper--label">
                        {this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_YES', 'Da')}
                      </span>
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({
                          isDialogOpenConfirmationOrgPartner: false
                        });
                      }}
                      variant="outlined"
                      color="secondary"
                      className="mx-1"
                    >
                      <span className="btn-wrapper--label">
                        {this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_NO', 'Nu')}
                      </span>
                    </Button>
                  </div>
                </div>
              </Dialog>
            </div>
            <div className="m-3 text-center">
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={this.state.isDialogOpenOptimConfirmation}
                fullWidth={true}
                maxWidth={'sm'}
              >
                <div className="text-center p-5">
                  <h4 className="font-weight-bold mt-4">
                    {this.translatorService.Tranlate(
                      'OPTIM_PARTS_DIALOG_TITLE',
                      'Atentie! Exista o componenta suplimentara. Doriti adaugarea acesteia ?'
                    )}
                  </h4>
                  <div className="pt-4">
                    <Button
                      onClick={() => {
                        this.optimizeAllParts(true);
                      }}
                      color="primary"
                      variant="contained"
                      className="mx-1"
                    >
                      <span className="btn-wrapper--label">
                        {this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_YES', 'Da')}
                      </span>
                    </Button>
                    <Button
                      onClick={() => {
                        this.optimizeAllParts(false);
                      }}
                      variant="outlined"
                      color="secondary"
                      className="mx-1"
                    >
                      <span className="btn-wrapper--label">
                        {this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_NO', 'Nu')}
                      </span>
                    </Button>
                  </div>
                </div>
              </Dialog>
            </div>
            <CardContent className="p-3">
              <div className="d-flex flex-row flex-wrap align-items-center">
                <div style={{marginRight: '5rem'}}>
                  <Button
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.isDialogOpenConfirmationHandler(this.interogateAll);
                    }}
                  >
                    {this.translatorService.Tranlate('INTEROGATE', 'Interogheaza')}
                  </Button>
                  {!R.isNil(this.props.workflowCalculation) && this.props.workflowCalculation!.viewOptim ? (                  
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.isDialogOpenOptimizeConfirmationHandler();
                      }}
                      disabled={!this.checkIfOptimIsPossible()}
                    >
                      {this.translatorService.Tranlate('OPTIM', 'Optimizeaza')}
                    </Button>
                  ) : null}
                </div>
                <div style={{paddingBottom: '10px'}}>
                  {this.state.currentWorkflowContainsOrderFormOrInterogatePartner ? (
                    <>
                      <div className="font-weight-bold">
                        {this.translatorService.Tranlate(
                          'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_LOCATION_TEXT',
                          'Adresa de livrare:'
                        )}
                      </div>
                      <div>
                          <DropdownTreeSelect
                            data={this.state.organizationBranches}
                            onChange={this.onDropDownChange}
                            mode="radioSelect"
                            texts={{
                              placeholder: this.translatorService.Tranlate(
                                'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CHOOSE_TEXT',
                                'Selecteaza'
                              )
                            }}
                          ></DropdownTreeSelect>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

            </CardContent>
            {this.renderCalculationPieces()}
          </div>
        ) : null}

        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography className="MuiTypography-h4">
                  {this.translatorService.Tranlate(
                    'ADD_ALTERNATIVE_PARTS_TITLE',
                    'Adauga piese alternativa'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{this.renderAddForm()}</DialogContent>
        </Dialog>

        <Dialog
          onClose={this.closeAddOriginalPartDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isAddOriginalPartDialogOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography className="MuiTypography-h4">
                  {this.state.calculationPartFormTypeAdd
                    ? this.translatorService.Tranlate(
                        'ADD_ORIGINALS_PARTS_TITLE',
                        'Adauga piesa originala'
                      )
                    : this.translatorService.Tranlate(
                        'COPY_ORIGINALS_PARTS_TITLE',
                        'Copiaza piesa originala'
                      )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="close"
                  onClick={this.closeAddOriginalPartDialog}
                  size={'small'}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{this.renderAddOriginalPartForm()}</DialogContent>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogModifiedOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <div className="text-center p-5">
            <h4 className="font-weight-bold mt-4">
              {this.translatorService.Tranlate(
                'ERROR_MSG_NO_PARTS_SELECTED',
                'Aveti repere neoptimizate. Doriti sa salvati?'
              )}
            </h4>
            <div className="pt-4">
              <Button
                onClick={() => {
                  this.setState(
                    {
                      isDialogModifiedOpen: false,
                      isConfirm: true
                    },
                    this.save
                  );
                }}
                color="primary"
                variant="contained"
                className="mx-1"
              >
                <span className="btn-wrapper--label">
                  {this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_YES', 'Da')}
                </span>
              </Button>
              <Button
                onClick={this.closeModifiedDialog}
                variant="outlined"
                color="secondary"
                className="mx-1"
              >
                <span className="btn-wrapper--label">
                  {this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_NO', 'Nu')}
                </span>
              </Button>
            </div>
          </div>
        </Dialog>

        {this.state.isNotifySupportDialogOpen ? (
          <Dialog
            onClose={this.closeNotifySupportDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isNotifySupportDialogOpen}
            fullWidth={true}
            maxWidth={'sm'}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography className="MuiTypography-h4">
                    {this.translatorService.Tranlate('NOTIFY_SUPPORT_TITLE', 'Notifica support')}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    onClick={this.closeNotifySupportDialog}
                    size={'small'}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>{this.renderNotifySupportOriginalPartForm()}</DialogContent>
          </Dialog>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsAlternativeCalculationPiecesProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null,
  mergeProps
)(withSnackbar(CaseDetailsAlternativeCalculationPieces as any));
