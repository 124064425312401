import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Organization } from '../../../../interfaces/Organization';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import {
  Box,
  Button,
  IconButton,
  Card,
  CardContent,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../../interfaces/IReferential';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import Referentials from '../../../../helpers/Referentials.json';
import { ReferentialCode, WorkflowFormActionTypeCode } from '../../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { IWorkflowService } from '../../../../services/Interfaces/IWorkflowService';
import { WorkflowFormAction, EmailTemplate } from '../../../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import SubjectIco from '@material-ui/icons/Subject';
import { mailPlaceHolders } from '../../mailPlaceHolders';
import { toBase64 } from '../../../../utils/toBase64';

interface IWorkflowFormActionSendEmailState {
  sendEmailList: WorkflowFormAction[];
  isLoading: boolean;
  emailTemplate: EmailTemplate | null;
  emailTemplates: EmailTemplate[];
  stateList: IReferential[];
  sendEmailActionList: IReferential[];
  sendEmailToList: IReferential[];
  selectedState: IReferential[];
  selectedSendEmailAction: IReferential[];
  selectedSendEmailTo: IReferential[];
  selectedTemplate: boolean;
  actionType: IReferential | null;
  isDialogEmailTemplateOpen: boolean;
  actionId: number;
  selectedEmailBodyTemplate: EditorState;
  selectedEmailSubjectTemplate: string;
}
export interface ExternalWorkflowFormActionSendEmailProps {
  workflowFormId: number;
}

type WorkflowFormActionSendEmailProps = ExternalWorkflowFormActionSendEmailProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class EditWorkflowFormActionSendEmail extends React.PureComponent<
  WorkflowFormActionSendEmailProps,
  IWorkflowFormActionSendEmailState
> {
  private translatorService!: ITranslatorService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    sendEmailList: [],
    emailTemplate: null,
    emailTemplates: [] as EmailTemplate[],
    isLoading: false,
    stateList: [],
    sendEmailActionList: [],
    sendEmailToList: [],
    selectedState: [],
    selectedSendEmailAction: [],
    selectedSendEmailTo: [],
    selectedTemplate: false,
    actionType: null,
    isDialogEmailTemplateOpen: false,
    actionId: 0,
    selectedEmailBodyTemplate: EditorState.createEmpty(),
    selectedEmailSubjectTemplate: ''
  } as IWorkflowFormActionSendEmailState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizationFormActionsSendEmail();
      }
    );
  }

  loadOrganizationFormActionsSendEmail = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    try {
      const caseStateRef = Referentials.referential.find(
        (item) => item.code === ReferentialCode.CaseStatus
      );
      const recipientTypeRef = Referentials.referential.find(
        (item) => item.code === ReferentialCode.EmailRecipientType
      );
      const actionTypeRef = Referentials.referential.find(
        (item) => item.code === ReferentialCode.ActionType
      );

      const [data, caseStatusList, recipientTypeList, emailTemplateList, actionTypeList] =
        await Promise.all([
          this.workflowService.GetWorkflowSectionStepFormActions(this.props.workflowFormId),
          this.appReferentialService.Get(caseStateRef!.baseUrl),
          this.appReferentialService.Get(recipientTypeRef!.baseUrl),
          this.workflowService.GetEmailTemplates(),
          this.appReferentialService.Get(actionTypeRef!.baseUrl)
        ]);

      const tranStatusActionType = actionTypeList.find(
        (item) => item.code === WorkflowFormActionTypeCode.SEND_MAIL
      );
      const data1 = data.filter((item) => item.actionTypeId === tranStatusActionType!.id);

      data1.forEach((element) => {
        const cs = caseStatusList.find((item) => item.id === element.sendMailStatusId);

        element.sendMailStatus = isNullOrUndefined(cs) ? null : cs;
      });

      this.setState({
        sendEmailList: data1,
        isLoading: false,
        stateList: caseStatusList.filter((item) => item.isActive),
        sendEmailActionList: actionTypeList.filter((item) => item.isActive),
        sendEmailToList: recipientTypeList.filter((item) => item.isActive),
        actionType: isNullOrUndefined(tranStatusActionType) ? null : tranStatusActionType,
        emailTemplates: emailTemplateList.filter((item) => item.isActive),
        selectedSendEmailAction: isNullOrUndefined(tranStatusActionType)
          ? []
          : [tranStatusActionType]
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  deleteOrgFormReport = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];
      await this.workflowService.RemoveWorkflowSectionStepFormAction(id);
      const sendEmailList = [...this.state.sendEmailList];
      const index = sendEmailList.findIndex((item) => item.id === id);
      sendEmailList.splice(index, 1);
      this.setState({
        sendEmailList: sendEmailList
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  onEmailTemplateClick = (
    e: any,
    id: number,
    bodyEmailTemplate: string,
    subjectEmailTemplate: string
  ) => {
    const blocksFromHTML = htmlToDraft(bodyEmailTemplate);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    this.setState({
      isDialogEmailTemplateOpen: true,
      actionId: id,
      selectedEmailBodyTemplate: EditorState.createWithContent(contentState),
      selectedEmailSubjectTemplate: subjectEmailTemplate
    });
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'sendMailStatus',
        label: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_STATE_HEADER',
          'Stare'
        ),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'sendMailActionType',

        label: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_ACTION_HEADER',
          'Actiune'
        ),
        options: {
          display: 'excluded',
          filter: true,
          sort: false,
          customBodyRender: (value: Organization, tableMeta: MUIDataTableMeta) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'emailTemplate',
        label: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_ACTION_EMAIL_STATE_HEADER',
          'Email'
        ),
        options: {
          filter: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const id = tableMeta.rowData[0];
            const body = tableMeta.rowData[7];
            const subject = tableMeta.rowData[8];

            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onEmailTemplateClick(e, id, body, subject)}
              >
                <span>{value.displayName}</span>
              </Button>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'emailRecipientType',
        label: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_TO_HEADER',
          'Catre'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'reuseEmailTemplate',
        label: 'Template',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: boolean) => (value ? 'Da' : 'Nu')
        } as MUIDataTableColumnOptions
      },
      {
        name: 'body',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: 'subject',
        options: {
          display: 'excluded',
          filter: false
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteOrgFormReport(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addOrgFormActionSendEmail = async () => {
    try {
      if (
        this.state.sendEmailList.findIndex(
          (item) =>
            item.emailTemplateId === this.state.emailTemplate!.id &&
            this.state.selectedSendEmailTo.findIndex(
              (itemm) => itemm.id === item.emailRecipientTypeId
            ) !== -1 &&
            this.state.selectedSendEmailAction.findIndex(
              (itemm) => itemm.id === item.sendMailActionTypeId
            ) !== -1 &&
            this.state.selectedState.findIndex((itemm) => itemm.id === item.sendMailStatusId) !== -1
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_MSG_WORKFLOW_FORM_ACTION_SEND_EMAIL_EXISTS',
            'Posibilitatea trimiterii acestui e-mail exista deja!'
          ),
          { variant: 'error' }
        );
        return;
      }

      const orgFormActionSendEmail = [] as WorkflowFormAction[];
      let contor = 0;
      for (const stateList of this.state.selectedState) {
        for (const actionList of this.state.selectedSendEmailAction) {
          for (const to of this.state.selectedSendEmailTo) {
            orgFormActionSendEmail.push({
              id: 0,
              workflowSectionStepFormId: this.props.workflowFormId,
              actionTypeId: this.state.actionType!.id,
              fromStatusId: null,
              fromStatus: null,
              toStatusId: null,
              toStatus: null,
              toWorkflowId: null,
              toWorkflow: null,
              isLastUser: false,
              workflowPartnerId: null,
              workflowPartnerTypeId: null,
              workflowPartner: null,
              workflowPartnerType: null,
              userRoleId: null,
              userRole: null,
              userId: null,
              user: null,
              userHoId: null,
              userOrganizationId: null,
              sendMailStatusId: stateList.id,
              sendMailStatus: null,
              sendMailActionTypeId: actionList.id,
              sendMailActionType: null,
              emailRecipientTypeId: to.id,
              emailRecipientType: null,
              emailTemplate: null,
              emailTemplateId: this.state.emailTemplate!.id,
              subject: this.state.emailTemplate!.subject,
              body: this.state.emailTemplate!.body,
              actionType: null,
              caseStatus: null,
              caseStatusId: null,
              sendEmailSameOrganization: false,
              sendEmailProprietaryOrganization: false,
              reuseEmailTemplate: this.state.selectedTemplate
            });
            contor = contor + 1;
          }
        }
      }

      await this.workflowService.AddWorkflowSectionStepFormActions(orgFormActionSendEmail);

      this.setState(
        {
          isLoading: true,
          selectedState: [],
          selectedSendEmailAction: [],
          emailTemplate: null,
          selectedSendEmailTo: []
        },
        async () => await this.loadOrganizationFormActionsSendEmail()
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleStateChange = (newValue: IReferential[]) => {
    this.setState({ selectedState: newValue });
  };

  handleSendEmailActionChange = (newValue: IReferential[]) => {
    this.setState({ selectedSendEmailAction: newValue });
  };

  handleSendEmailToChange = (newValue: IReferential[]) => {
    this.setState({ selectedSendEmailTo: newValue });
  };

  handleEmailChange = (newValue: any) => {
    this.setState({ emailTemplate: newValue });
  };

  public renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addOrgFormActionSendEmail}>
              <Autocomplete
                id="state"
                multiple
                options={this.state.stateList}
                value={this.state.selectedState}
                onChange={(e: any, newValue: IReferential[]) => this.handleStateChange(newValue)}
                getOptionLabel={(option: { id: number; displayName: string }) =>
                  option.displayName || ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="state"
                    value={this.state.selectedState[0] || ''}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_STATE_LABEL',
                      'Stare'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                hidden={true}
                id="sendEmailAction"
                multiple
                options={this.state.sendEmailActionList}
                value={this.state.selectedSendEmailAction}
                onChange={(e: any, newValue: IReferential[]) =>
                  this.handleSendEmailActionChange(newValue)
                }
                getOptionLabel={(option: { id: number; displayName: string }) =>
                  option.displayName || ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="sendEmailAction"
                    value={this.state.selectedSendEmailAction[0] || ''}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_ACTION_LABEL',
                      'Actiune'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="email"
                options={this.state.emailTemplates}
                value={this.state.emailTemplate}
                onChange={(e: any, newValue: EmailTemplate | null) =>
                  this.handleEmailChange(newValue)
                }
                getOptionLabel={(option: EmailTemplate) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="state"
                    value={this.state.emailTemplate || ''}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_EMAIL_STATE_HEADER',
                      'Email'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="sendEmailTo"
                multiple
                options={this.state.sendEmailToList}
                value={this.state.selectedSendEmailTo}
                onChange={(e: any, newValue: IReferential[]) =>
                  this.handleSendEmailToChange(newValue)
                }
                getOptionLabel={(option: { id: number; displayName: string }) =>
                  option.displayName || ''
                }
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="sendEmailTo"
                    value={this.state.selectedSendEmailTo[0] || ''}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_TO_LABEL',
                      'Catre'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <FormControlLabel
                className="mt-3"
                control={
                  <Switch
                    checked={this.state.selectedTemplate}
                    onChange={(e: any) => {
                      this.setState({ selectedTemplate: e.target.checked });
                    }}
                  />
                }
                label="Template"
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  closeDialog = () => {
    this.setState({ isDialogEmailTemplateOpen: false });
  };

  onEditorStateChange = (editorState: EditorState) => {
    this.setState({ selectedEmailBodyTemplate: editorState });
  };

  renderEditEmailTemplate = () => {
    return (
      <Editor
        editorState={this.state.selectedEmailBodyTemplate}
        editorStyle={{
          border: '1px solid #d1d2db',
          borderRadius: '0.29rem',
          height: '250px'
        }}
        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState)}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'history',
            'image'
          ],
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: async (file: File) => {
              const imageBase64String = await toBase64(file);
              return Promise.resolve({ data: { link: imageBase64String } });
            },
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: '140',
              width: 'auto'
            }
          }
        }}
      />
    );
  };
  saveEmailTemplateChanges = async () => {
    const id = this.state.actionId;
    const sendEmailList = [...this.state.sendEmailList];
    const index = sendEmailList.findIndex((item) => item.id === id);
    if (index > -1) {
      sendEmailList[index] = {
        ...sendEmailList[index],
        body: draftToHtml(convertToRaw(this.state.selectedEmailBodyTemplate.getCurrentContent())),
        subject: this.state.selectedEmailSubjectTemplate
      };

      await this.workflowService.UpdateWorkflowSectionStepAction(sendEmailList[index]);

      this.setState(
        {
          isLoading: true,
          isDialogEmailTemplateOpen: false
        },
        async () => await this.loadOrganizationFormActionsSendEmail()
      );
    }
  };

  addPlaceholderToSubject = (text: string) => {
    this.setState({
      selectedEmailSubjectTemplate: this.state.selectedEmailSubjectTemplate + '{{' + text + '}}'
    });
  };

  placeHolderClick = (text: string) => {
    const editorState = this.state.selectedEmailBodyTemplate;
    const newBlockMap = convertFromHTML('{{' + text + '}}');
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const key = selectionState.getAnchorKey();
    const blocksAfter = contentState
      .getBlockMap()
      .skipUntil(function (_, k) {
        return k === key;
      })
      .skip(1)
      .toArray();
    const blocksBefore = contentState
      .getBlockMap()
      .takeUntil(function (_, k) {
        return k === key;
      })
      .toArray();

    newBlockMap.contentBlocks = blocksBefore
      .concat([contentState.getBlockForKey(key)])
      .concat(newBlockMap.contentBlocks)
      .concat(blocksAfter);

    const newContentState = ContentState.createFromBlockArray(
      newBlockMap.contentBlocks,
      newBlockMap.entityMap
    );
    const newEditorState = EditorState.createWithContent(newContentState);
    this.setState({ selectedEmailBodyTemplate: newEditorState });
  };

  renderMailPlaceHolders = () => {
    return (
      <List component="nav" subheader={<ListSubheader>Placeholders</ListSubheader>}>
        {mailPlaceHolders.map((val, index) => (
          <ListItem button key={index} dense>
            <ListItemText primary={val} onClick={(e) => this.placeHolderClick(val)} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={(e) => this.addPlaceholderToSubject(val)}>
                <SubjectIco />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      search: true,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_TAB',
                    'Trimite e-mail'
                  )}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.sendEmailList}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogEmailTemplateOpen}
          // fullWidth={true} maxWidth="md" scroll="body" >
          fullScreen
        >
          <DialogTitle style={{ color: '#ffffff', backgroundColor: '#1F2857' }}>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_EDIT_EMAIL_TEMPLATE_MODAL_TITLE',
                    'Editeaza template-ul email-ului'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1} className="text-right">
                <IconButton
                  aria-label="close"
                  onClick={() => this.closeDialog()}
                  size={'small'}
                  style={{ color: '#ffffff' }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item sm={8} className="text-right">
                <TextField
                  fullWidth
                  className="mb-2"
                  style={{ borderRadius: '0.29rem' }}
                  label={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_EDIT_EMAIL_TEMPLATE_SUBJECT',
                    'Subiect'
                  )}
                  id="outlined-size-small"
                  value={this.state.selectedEmailSubjectTemplate}
                  onChange={(e) =>
                    this.setState({
                      selectedEmailSubjectTemplate: e.target.value
                    })
                  }
                  variant="outlined"
                  size="small"
                />
                {this.renderEditEmailTemplate()}
              </Grid>
              <Grid item sm={4} className="text-right">
                <Card elevation={1}>
                  <Box>{this.renderMailPlaceHolders()}</Box>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              {this.translatorService.Tranlate('CANCEL', 'Anuleaza')}
            </Button>
            <Button onClick={this.saveEmailTemplateChanges} color="primary">
              {this.translatorService.Tranlate('SAVE', 'Salveaza')}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalWorkflowFormActionSendEmailProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null,
  mergeProps
)(withSnackbar(EditWorkflowFormActionSendEmail as any));
