import React from 'react';
import moment from 'moment';

import { MuiPickersUtilsProvider, DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import { DateType } from '@date-io/type';

import MomentUtils from '@date-io/moment';

type Props = {
  value: string | number | Date | null | undefined;
  onChange: (date: DateType | null) => void;
  locale: string;
  disabled?: boolean;
  required?: boolean;
  label?: React.ReactNode;
  format?: string;
  helperText?: React.ReactNode;
};

const DatePicker: React.FC<Props> = ({
  value,
  onChange,
  locale,
  disabled,
  required,
  label,
  format,
  helperText
}: Props) => (
  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
    <MaterialDatePicker
      variant="inline"
      fullWidth
      autoOk
      format={format}
      value={value}
      onChange={onChange}
      disabled={disabled}
      required={required}
      label={label}
      helperText={helperText}
    />
  </MuiPickersUtilsProvider>
);

export default DatePicker;
