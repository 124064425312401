import React, { Fragment, useContext } from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper, SwipeableDrawer  } from '@material-ui/core';

import { connect } from 'react-redux';

import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarUserbox from '../../layout-components/SidebarUserbox';
import SidebarMenu from '../../layout-components/SidebarMenu';
// import SidebarFooter from '../../layout-components/SidebarFooter';

import navItems from './navItems';
import { ApplicationContext } from '../../context/Contexts';
import { ThemeActionCreators } from '../../store';
// import {
//   setSidebarToggleMobile,
//   setSidebarHover,
//   setSidebarToggle,
//   setSidebarFooter,
//   setSidebarUserbox
// } from '../../reducers/ThemeOptions';

const Sidebar = (props) => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,

    sidebarHover,
    // setSidebarHover,
    sidebarToggle,
    // sidebarUserbox,
    sidebarShadow,
    // sidebarFooter,
    appState
  } = props;

  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;

  // const toggleHoverOn = () => setSidebarHover(true);
  // const toggleHoverOff = () => setSidebarHover(false);

  if (appState.user === null) {
    return '';
  }

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const sidebarMenuContent = (
    <div
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover
      })}
    >
      {navItems.map((list) => {
        return (
          <SidebarMenu
            component="div"
            key={`${list.label} ${list.tag}`}
            pages={list.content}
            title={translatorService.Tranlate(list.tag, list.label)}
            user={appState.user}
            props={props}
          />
        );
      })}
    </div>
  );
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <Fragment>
      <Hidden lgUp>
        <SwipeableDrawer 
          sx={{
            '& .MuiBackdrop-root': {
                display: 'none',
            },
          }}
          anchor="left"
          open={sidebarToggleMobile}
          onClose={() => {
            props.dispatch(ThemeActionCreators.setSidebarToggleMobile(false));
          }}
          onOpen={() => {
            props.dispatch(ThemeActionCreators.setSidebarToggleMobile(true));
          }}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          BackdropProps={{ invisible: true }}
          disableSwipeToOpen={false}
        >
          <SidebarHeader />
          <PerfectScrollbar>
            {<SidebarUserbox propsContainer={props}/>}
            {sidebarMenuContent}
            {/* {sidebarFooter && <SidebarFooter />} */}
          </PerfectScrollbar>
        </SwipeableDrawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          //  onMouseEnter={toggleHoverOn}
          //  onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 3}
        >
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {appState.user !== null ? <SidebarUserbox propsContainer={props}/> : null}
              {sidebarMenuContent}
              {/* {sidebarFooter && <SidebarFooter />} */}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarFixed: state.themeOptions.sidebarFixed,
  headerFixed: state.themeOptions.headerFixed,
  sidebarToggle: state.themeOptions.sidebarToggle,
  sidebarHover: state.themeOptions.sidebarHover,
  sidebarShadow: state.themeOptions.sidebarShadow,
  // sidebarFooter: state.themeOptions.sidebarFooter,
  // sidebarUserbox: state.themeOptions.sidebarUserbox,
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile,
  appState: state.app
});

// const mapDispatchToProps = dispatch => ({
//   setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
//   setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
//   setSidebarHover: enable => dispatch(setSidebarHover(enable)),
//   setSidebarFooter: enable => dispatch(setSidebarFooter(enable)),
//   setSidebarUserbox: enable => dispatch(setSidebarUserbox(enable))
// });

export default connect(mapStateToProps, null)(Sidebar);
