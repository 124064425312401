import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  AppState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  CardContent,
  Button,
  IconButton,
  DialogTitle,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogContent
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { Card, CardFooter } from 'reactstrap';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import { AttachmentTypeCode, ReferentialCode } from '../../helpers/Constants';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import FileSaver from 'file-saver';
import CloseIcon from '@material-ui/icons/Close';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileViewer from 'react-file-viewer';
import { IReferential } from '../../interfaces/IReferential';
import GetAppIcon from '@material-ui/icons/GetApp';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import 'react-html5-camera-photo/build/css/index.css';
import _, { at } from 'lodash';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { CaseAttachment, CaseSelectedAttachment } from '../../interfaces/Case';

interface IAppointmentTabDocumentsFormState {
  appointmentAttachments: CaseAttachment[];
  attachmentTypeList: IReferential[];
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isDialogOpen: boolean;
  previewAttachFile: any;
  previewAttachFileExtension: string;
  isOpen: boolean;
  dialogVisible: boolean;
  selectedAttachment: CaseSelectedAttachment;
}

export interface ExternalAppointmentTabDocumentsFormProps {
  appointmentId: number;
}

type IAppointmentTabDocumentsFormProps = ExternalAppointmentTabDocumentsFormProps & {
  appState: AppState;
} & ProviderContext;
export const imageExtensions = ['png', 'jpeg', 'jpg', 'bmp'];

class AppointmentTabDocumentsForm extends React.PureComponent<
  IAppointmentTabDocumentsFormProps,
  IAppointmentTabDocumentsFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;
  static contextType = ApplicationContext;
  state = {
    appointmentAttachments: [],
    attachmentTypeList: [],
    isLoading: false,
    hasRights: false,
    executing: false,
    isDialogOpen: false,
    previewAttachFile: '',
    previewAttachFileExtension: '',
    showUploadMessage: false,
    isOpen: false,
    dialogVisible: false,
    selectedAttachment: {
      id: -1,
      fileName: ''
    }
  } as IAppointmentTabDocumentsFormState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadAppointmentDocumentsForm();
      }
    );
  }

  loadAppointmentDocumentsForm = async () => {
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );
    const [attachmentTypes] = await Promise.all([
      (await (this.appReferentialService.Get(ref!.baseUrl))).filter(x => x.code == AttachmentTypeCode.OTHER)
    ]);

    const appointmentAttachments = await this.caseService.GetAppointmentAttachments(
      this.props.appointmentId,
      attachmentTypes.map((item) => item!.code!)
    )
   
    this.setState({
      appointmentAttachments: appointmentAttachments.sort(function (a: any, b: any) {
        return b.uploadDate - a.uploadDate;
      }),
      attachmentTypeList: attachmentTypes.map((item) => ({...item, isLoading: false})),
      isLoading: false,
      hasRights: false
    });
  };

  setAttachmentTypeList = (attachmentType: IReferential, value: boolean) => {
    const attachmentTypeList = _.cloneDeep(this.state.attachmentTypeList);
    for (let i = 0; i < attachmentTypeList.length; i++) {
      if (attachmentTypeList[i].id === attachmentType.id) {
        attachmentTypeList[i].isLoading = value;
      }
    }

    return attachmentTypeList;
  };

  handleCloseConfirmationDialog = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handlePreviewAttachment = async (originalFileName: string, filename: string) => {
    try {
      const fileBlob = await this.caseService.GetCaseAttachment(filename);
      const url = URL.createObjectURL(fileBlob);
      const extension = originalFileName.split('.').pop();

      if (isNullOrUndefined(extension)) {
        return;
      }

      this.setState({
        previewAttachFile: url,
        previewAttachFileExtension: extension!.toLowerCase(),
        isDialogOpen: true
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderPreviewAttachment = () => {
    return (
      <FileViewer
        fileType={this.state.previewAttachFileExtension}
        filePath={this.state.previewAttachFile}
      />
    );
  };

  renderPreviewImage = (file: any, fileName: string) => {
    const url = URL.createObjectURL(file);

    return <img src={url} alt={fileName} className="img-fluid" />;
  };

  renderAttachments = (attachmentType: IReferential) => {
    const attachments = this.state.appointmentAttachments.filter(
      (item) => item.attachmentTypeId === attachmentType.id
    );
    const attachmentsToRender = [] as any[];

    attachments.forEach((attach, index) => {
      attachmentsToRender.push(
        <React.Fragment key={index}>
          <Grid container spacing={0}>
            <Grid item xs={1}>
              <div className="d-flex align-items-center">
                <IconButton
                  aria-label="preview"
                  color="inherit"
                  className="text-primary"
                  size="small"
                  onClick={(e) =>
                    this.handlePreviewAttachment(attach.originalFileName, attach.fileName)
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={9}>
              <div className="align-box-row text-wrap " style={{ wordBreak: 'break-all' }}>
                <small>
                  <Button
                    color="primary"
                    className="m-2 text-primary"
                    onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                  >
                    <span>{attach.originalFileName}</span>
                  </Button>{' '}
                </small>
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className="d-flex align-items-center">
                <IconButton
                  aria-label="download"
                  color="inherit"
                  size="small"
                  onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                >
                  <GetAppIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    });
    return attachmentsToRender;
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  renderAttachmentTypes = () => {
    const attachmentTypes = [] as any[];
    const attachmentTypeList = this.state.attachmentTypeList;
    if (attachmentTypeList.length !== 0) {
      attachmentTypeList.forEach((attach, index) => {
        attach.fileInputKey = Date.now();
        attachmentTypes.push(
          <div key={index} className={`p-1 d-flex justify-content-center w-50`}>
            <Card className="card-box w-100 shadow-none">
              <div className="card-header">
                <div className="card-header--title">
                  <b>
                    {attach!.displayName}
                    <IconButton
                      aria-label="download"
                      color="inherit"
                      size="small"
                      disabled={
                        this.state.appointmentAttachments.filter(
                          (item) => item.attachmentTypeId === attach.id
                        ).length === 0
                      }
                      onClick={(e) => {
                        this.downloadBasedOnAttachmentType(attach);
                      }}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </b>
                </div>
              </div>

              <CardContent className="p-3 flex-grow-1">
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={attach.isLoading} />
                </div>
                {!attach.isLoading ? (
                  <div className="file-manager-wrapper h-100">
                    {this.state.appointmentAttachments.filter(
                      (item) => item.attachmentTypeId === attach.id
                    ).length !== 0 ? (
                      <div className="table-responsive">
                        <table className="table table-striped table-hover text-nowrap mb-0">
                          <tbody>
                            <tr>
                              <td>{this.renderAttachments(attach)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="h-100 align-self-center text-center">
                        <DescriptionIcon
                          className="h-100"
                          color="secondary"
                          fontSize="large"
                        ></DescriptionIcon>
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </CardContent>
              <CardFooter className="p-3 border-0">
              </CardFooter>
            </Card>
          </div>
        );
      });
    }
    return attachmentTypes;
  };

  downloadBasedOnAttachmentType = async (item: IReferential) => {
    this.setState({
      attachmentTypeList: this.setAttachmentTypeList(item, true)
    });

    const fileBlob = await this.caseService.GetAppointmentAttachmentsArchive(
      this.props.appointmentId,
      [item.code!]
    );

    this.setState({
      attachmentTypeList: this.setAttachmentTypeList(item, false)
    });
    
    FileSaver.saveAs(
      fileBlob,
      this.props.appointmentId + '_' + item!.displayName + '.zip'
    );
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <Box className="pt-3">
          <Card className="card-box  overflow-visible">
            <CardContent className="p-3">
              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
              </div>
              {!this.state.isLoading ? (
                <div className="text-center mb-2">
                  <div className="d-flex flex-wrap m-1">{this.renderAttachmentTypes()}</div>
                </div>
              ) : null}
            </CardContent>
          </Card>
        </Box>
        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          maxWidth="md"
          fullWidth
          fullScreen={false}
          classes={{ paper: 'dialogPaper' }}
          scroll="body"
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate('CASE_PREVIEW_DOC', 'Previzualizare document')}
                </Typography>
              </Grid>
              <Grid className="text-right" item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container alignContent="center">
              <Grid item xs={12}>
                <Box height="65vh" className="text-center">
                  {this.renderPreviewAttachment()}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalAppointmentTabDocumentsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null,
  mergeProps
)(withSnackbar(AppointmentTabDocumentsForm as any));
