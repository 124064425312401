import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, Card, CardContent, IconButton } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import { WorkflowPartner, WorkflowPermission } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { IReferential } from '../../../interfaces/IReferential';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { isNullOrUndefined } from 'util';

interface IWorkflowPermissionState {
  workflowPermissions: WorkflowPermission[];
  isLoading: boolean;
  workflowPartnerTypeList: WorkflowPartner[];
  selecteWorkflowPartnerType: WorkflowPartner | null;
  caseStatusTypeList: IReferential[];
  selectedCaseStatusType: IReferential | null;
  selectedUserRole: IReferential | null;
  userRoles: IReferential[];
  //  workflowSectionStepList: WorkflowSectionStep[]
  // selecteWorkflowSectionStepType: WorkflowSectionStep | null
  //  workflowId: number
}
export interface ExternalPermissionFormActionProps {
  workflowFormId: number;
}

type IWorkflowPermissionProps = ExternalPermissionFormActionProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowPermissions extends React.PureComponent<
  IWorkflowPermissionProps,
  IWorkflowPermissionState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowPermissions: [],
    isLoading: false,
    workflowPartnerTypeList: [],
    selecteWorkflowPartnerType: null,
    caseStatusTypeList: [],
    selectedCaseStatusType: null,
    workflowSectionStepList: [],
    selecteWorkflowSectionStepType: null,
    selectedUserRole: null,
    userRoles: []
    // workflowId: 0
  } as IWorkflowPermissionState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowPermissions();
      }
    );
  }

  loadWorkflowPermissions = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseStatus);
    const refRole = Referentials.referential.find((item) => item.code === ReferentialCode.UserRole);

    const [workflowPermissions, workflowPartnerList, caseStatusTypes, userRoles] =
      await Promise.all([
        this.workflowService.GetWorkflowPermissions(this.props.workflowFormId),
        this.workflowService.GetWorkflowPartners(workflowId),
        this.appReferentialService.Get(ref!.baseUrl),
        this.appReferentialService.Get(refRole!.baseUrl)
        // this.workflowService.GetWorkflowSteps(workflowId)
      ]);
    const partners = await this.organizationService.GetOrganizationsByIds(
      workflowPartnerList.map((item) => item.partnerId)
    );
    workflowPartnerList.forEach((wpItem) => {
      const partner = partners.find((item) => item.id === wpItem.partnerId);
      wpItem.partner = isNullOrUndefined(partner) ? null : partner;
    });

    workflowPermissions.forEach((wpItem) => {
      const partner = workflowPartnerList.find((item) => item.id === wpItem.workflowPartnerId);
      const caseStatus = caseStatusTypes.find((item) => item.id === wpItem.caseStatusId);

      wpItem.workflowPartner = isNullOrUndefined(partner) ? null : partner;
      wpItem.caseStatus = isNullOrUndefined(caseStatus) ? null : caseStatus;
    });
    this.setState({
      workflowPermissions: workflowPermissions,
      caseStatusTypeList: caseStatusTypes.filter((item) => item.isActive === true),
      workflowPartnerTypeList: workflowPartnerList.filter((item) => item.isActive === true),
      // workflowSectionStepList: workflowSectionStepList.filter(item => item.isActive === true),
      isLoading: false,
      userRoles: userRoles.filter((item) => item.isActive === true)
      //  workflowId: workflowId
    });
  };

  createWorkflowPartner = async (item: WorkflowPartner) => {
    [item.partner] = await Promise.all([this.organizationService.GetOrganization(item.partnerId)]);

    return item;
  };

  handleIsActiveChange = async (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const workflowPermissions = [...this.state.workflowPermissions];
    const updatedWorkflowPermissionIndex = workflowPermissions.findIndex((o) => o.id === id);

    if (updatedWorkflowPermissionIndex >= 0) {
      workflowPermissions[updatedWorkflowPermissionIndex] = {
        ...workflowPermissions[updatedWorkflowPermissionIndex],
        [event.target.id]: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowPermission(
          workflowPermissions[updatedWorkflowPermissionIndex]
        );

        this.setState({ workflowPermissions: workflowPermissions });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  deletePermission = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const index = this.state.workflowPermissions.findIndex((i) => i.id === tableMeta.rowData[0]);
      const deletedPermission = this.state.workflowPermissions[index];
      const newWorkflowPermissionsArray = [...this.state.workflowPermissions];
      newWorkflowPermissionsArray.splice(index, 1);

      await this.workflowService.RemoveWorkflowPermission(deletedPermission.id);

      this.setState({
        workflowPermissions: newWorkflowPermissionsArray
        //   workflowSectionStepList: await this.filterSectionSteps(newWorkflowPermissionsArray, this.state.selecteWorkflowPartnerType, this.state.selectedCaseStatusType)
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'workflowPartner',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PERMISSION_TAB_ORGANIZATION_HEADER',
          'Organizatie'
        ),
        options: {
          sort: false,
          customBodyRender: (value: WorkflowPartner, tableMeta: MUIDataTableMeta) => {
            return value.partner!.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseStatus',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PERMISSION_TAB_CASE_STATUS_HEADER',
          'Status Dosar'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'userRole',
        label: this.translatorService.Tranlate('WORKFLOW_USER_ROLE_LABEL', 'Rol'),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value ? value.displayName : '';
          }
        } as MUIDataTableColumnOptions
      },

      // {
      //     name: 'read',
      //     label: this.translatorService.Tranlate("WORKFLOW_PERMISSION_TAB_READ_HEADER", "Citeste"),

      //     options: {
      //         filter: false,
      //         sort: false,

      //         customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
      //             return (
      //                 <Switch
      //                     id="read"
      //                     checked={value ? value : false}
      //                     onChange={(e) => this.handleIsActiveChange(e, tableMeta.rowData[0])}
      //                     color="primary"
      //                     inputProps={{ 'aria-label': 'primary checkbox' }}
      //                 />)
      //         }

      //     }
      // },
      // {
      //     name: 'write',
      //     label: this.translatorService.Tranlate("WORKFLOW_PERMISSION_TAB_WRITE_HEADER", "Scrie"),
      //     options: {
      //         filter: false,
      //         sort: false,
      //         customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
      //             return (
      //                 <Switch
      //                     id="write"
      //                     checked={value ? value : false}
      //                     onChange={(e) => this.handleIsActiveChange(e, tableMeta.rowData[0])}
      //                     color="primary"
      //                     inputProps={{ 'aria-label': 'primary checkbox' }}
      //                 />)
      //         }

      //     }
      // },
      // {
      //     name: 'execute',
      //     label: this.translatorService.Tranlate("WORKFLOW_PERMISSION_TAB_EXECUTE_HEADER", "Executa"),
      //     options: {
      //         filter: false,
      //         sort: false,
      //         customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
      //             return (
      //                 <Switch
      //                     id="execute"
      //                     checked={value ? value : false}
      //                     onChange={(e) => this.handleIsActiveChange(e, tableMeta.rowData[0])}
      //                     color="primary"
      //                     inputProps={{ 'aria-label': 'primary checkbox' }}
      //                 />)
      //         }

      //     }
      // },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PERMISSION_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deletePermission(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addWorkflowPermissionType = async () => {
    try {
      if (
        this.state.workflowPermissions.findIndex(
          (item) =>
            item.workflowPartnerId === this.state.selecteWorkflowPartnerType!.id &&
            ((this.state.selectedUserRole !== null &&
              item.userRoleId === this.state.selectedUserRole!.id) ||
              (this.state.selectedUserRole === null && item.userRoleId !== null) ||
              (this.state.selectedUserRole !== null && item.userRoleId === null) ||
              (this.state.selectedUserRole === null && item.userRoleId === null)) &&
            item.caseStatusId === this.state.selectedCaseStatusType!.id
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_MSG_WORKFLOW_PERMISSION_EXISTS',
            'Permisiunea exista deja!'
          ),
          { variant: 'error' }
        );
        return;
      }
      await this.workflowService.AddWorkflowPermission({
        workflowPartnerId: this.state.selecteWorkflowPartnerType!.id,
        caseStatusId: this.state.selectedCaseStatusType!.id,
        workflowSectionStepFormId: this.props.workflowFormId,
        userRoleId: this.state.selectedUserRole ? this.state.selectedUserRole.id : null,
        read: true,
        execute: true,
        write: true
      } as WorkflowPermission);

      this.setState(
        {
          isLoading: true
          //selecteWorkflowSectionStepType : null,
        },
        () => {
          this.loadWorkflowPermissions();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  handleUserRoleChange = (newValue: any | null) => {
    this.setState({ selectedUserRole: newValue });
  };
  handleCaseStatusChange = async (newValue: any | null) => {
    this.setState({
      selectedCaseStatusType: newValue
      // workflowSectionStepList: await this.filterSectionSteps(this.state.workflowPermissions, this.state.selecteWorkflowPartnerType, newValue)
    });
  };
  // handleSectionStepChange = (newValue: WorkflowSectionStep | null) => {
  //     this.setState({ selecteWorkflowSectionStepType: newValue });
  // }
  handleWorkflowPartnerChange = (newValue: WorkflowPartner | null) => {
    this.setState(
      {
        selecteWorkflowPartnerType: newValue
      },
      async () => {
        // await this.reloadWorkflowPermission(newValue ? newValue.id : null);
      }
    );
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addWorkflowPermissionType}>
              <Autocomplete
                id="workflowPartner"
                className="m-2"
                options={this.state.workflowPartnerTypeList.sort(function (a, b) {
                  return a.partner!.displayName.localeCompare(b.partner!.displayName);
                })}
                value={this.state.selecteWorkflowPartnerType}
                onChange={(e: any, newValue: WorkflowPartner | null) =>
                  this.handleWorkflowPartnerChange(newValue)
                }
                getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName || ''}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option: WorkflowPartner) => option.partner!.displayName
                })}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="workflowPartner"
                    value={this.state.selecteWorkflowPartnerType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_PERMISSION_TAB_ORGANIZATION_LABEL',
                      'Organizatie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              <Autocomplete
                id="caseStatus"
                className="m-2"
                options={this.state.caseStatusTypeList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedCaseStatusType}
                onChange={(e: any, newValue: any | null) => this.handleCaseStatusChange(newValue)}
                getOptionLabel={(option: any) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="caseStatus"
                    value={this.state.selectedCaseStatusType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_PERMISSION_TAB_CASE_STATUS_LABEL',
                      'Status'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              <Autocomplete
                id="userRole"
                className="m-2"
                options={this.state.userRoles.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedUserRole}
                onChange={(e: any, newValue: any | null) => this.handleUserRoleChange(newValue)}
                getOptionLabel={(option: any) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="userRole"
                    value={this.state.selectedUserRole}
                    label={this.translatorService.Tranlate('WORKFLOW_USER_ROLE_LABEL', 'Rol')}
                    fullWidth
                  />
                )}
              />
              {/* <Autocomplete
                                id="workflowSectionSteps"
                                className="m-2"
                                options={this.state.workflowSectionStepList.sort(function (a, b) { return a.workflowSection.section!.displayName.localeCompare(b.workflowSection.section!.displayName) })}
                                value={this.state.selecteWorkflowSectionStepType}
                                onChange={(e: any, newValue: any | null) => this.handleSectionStepChange(newValue)}
                                getOptionLabel={(option: WorkflowSectionStep) => option.workflowSection.section!.displayName+ "-"+ option.workflowStep!.displayName  || ""}
                                renderInput={params => (
                                    <TextValidator {...params}
                                        name="workflowSectionSteps "
                                        value={this.state.selecteWorkflowSectionStepType}
                                        label={this.translatorService.Tranlate("WORKFLOW_PERMISSION_TAB_SECTION_STEP_LABEL", "Pas")}
                                        fullWidth
                                        validators={['required']}
                                        errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu")]}
                                    />
                                )}
                            /> */}
              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      search: true,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {/* <Box>
                    <Card>
                        <CardContent>
                            <Tabs
                                value={0}
                                indicatorColor="primary"
                                textColor="secondary"
                                variant="standard"
                                color="primary"
                                aria-label="disabled tabs example">
                                <Tab className="text-capitalize" label={this.translatorService.Tranlate("WORKFLOWS_TAB_PERMISSIONS", "Permisiuni")} />
                            </Tabs>
                        </CardContent>
                    </Card>
                </Box> */}
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowPermissions}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalPermissionFormActionProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null,
  mergeProps
)(withSnackbar(WorkflowPermissions as any));
