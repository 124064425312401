import { Card, Grid, Paper, Button } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import isNil from 'lodash/isNil';

import AcquisitionDonutChart from './AcquisitionDonutChart';
import AcquisitionMainCards from './AcquisitionMainCards';
import AcquisitionDashboardAppliedFilters from './AcquisitionDashboardAppliedFilters';
import RecentAcquisitions from './RecentAcquisitions';
import TopBuyers from './TopBuyers';
import DashboardFilters from '../DashboardFilters';
import {
  acquisitionDashboardReducer,
  RESET_FILTERS,
  SET_CLIENT_TYPE_ID,
  SET_MAKE_ID,
  SET_OFFER_TYPE_ID,
  SET_VALUE_BY_NAME
} from './acquisitionDashboardReducer';
import { AcquisitionFilter } from '../../../interfaces/Dashboard/AcquisitionFilter';
import { ApplicationContext } from '../../../context/Contexts';
import { AcquisitionResult } from '../../../interfaces/Dashboard/AcquisitionResult';
import Loader from '../../Loader';
import { PptService } from '../../../services/PptService';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { numberFormat } from '../../../utils/numberFormat';
import { AttachmentTypeCode } from '../../../helpers/Constants';

const AcquistionDashboard: React.FC = () => {
  const [filters, dispatch] = useReducer(acquisitionDashboardReducer, {
    startDate: moment().add(-3, 'month'),
    endDate: moment(),
    selectedOrganisations: [],
    includeVat: true,
    locationId: undefined,
    clientTypeId: undefined,
    offerTypeId: undefined,
    makeId: undefined
  });

  const [data, setData] = useState<AcquisitionResult>();

  const [updatedItems, setUpdatedItems] = useState<any[]>([]);

  const context = useContext(ApplicationContext);

  const constantsData = MUITranslations.GetDashboardTranslations(context.translatorService);

  const setFiltersValueByName = useCallback((name: string, value: any) => {
    dispatch({ type: SET_VALUE_BY_NAME, name, value });
  }, []);

  const setClientTypeId = useCallback((clientTypeId?: number) => {
    dispatch({ type: SET_CLIENT_TYPE_ID, clientTypeId });
  }, []);

  const setOfferTypeId = useCallback((offerTypeId?: number) => {
    dispatch({ type: SET_OFFER_TYPE_ID, offerTypeId });
  }, []);

  const setMakeId = useCallback((makeId?: number) => {
    dispatch({ type: SET_MAKE_ID, makeId });
  }, []);

  const resetFilters = useCallback(() => {
    dispatch({ type: RESET_FILTERS });
  }, []);

  const handleMakeDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const makeId = data?.purchasedVehiclesByMaker[dataIndex].id;
        if (!isNil(makeId)) {
          setMakeId(makeId);
        }
      }
    },
    [data?.purchasedVehiclesByMaker, setMakeId]
  );

  const handleClientTypeDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const clientTypeId = data?.purchasedVehiclesByClientType[dataIndex].id;
        if (!isNil(clientTypeId)) {
          setClientTypeId(clientTypeId);
        }
      }
    },
    [data?.purchasedVehiclesByClientType, setClientTypeId]
  );

  const handleOfferTypeDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const offerTypeId = data?.purchasedVehiclesByOfferType[dataIndex].id;
        if (!isNil(offerTypeId)) {
          setOfferTypeId(offerTypeId);
        }
      }
    },
    [data?.purchasedVehiclesByOfferType, setOfferTypeId]
  );

  const getTopBuyersFormatTable = (
    isTentative: boolean,
    firstPart: [{ title: string; field?: string; render?(rowData: any): string | 0 }]
  ) => {
    const formatTable = [...firstPart];
    if (isTentative) {
      formatTable.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_ESTIMATED_VALUE',
          'Pret mediu estimat'
        ),
        render: (rowData: any) => {
          if (!filters.includeVat) {
            return rowData.inProgressPurchasesEstimatedAveragePrice
              ? numberFormat(rowData.inProgressPurchasesEstimatedAveragePrice, 0, undefined, ' EUR')
              : 0;
          } else {
            return rowData.inProgressPurchasesEstimatedAveragePriceInclVat
              ? numberFormat(
                  rowData.inProgressPurchasesEstimatedAveragePriceInclVat,
                  0,
                  undefined,
                  ' EUR'
                )
              : 0;
          }
        }
      });
    } else {
      formatTable.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_FINALIZED_TABLE_HEADER',
          'Achizitii Finalizate'
        ),
        render: (rowData: any) => rowData.completedPurchases.count
      });
      formatTable.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_TOTAL_PAID',
          'Total Achitat'
        ),
        render: (rowData: any) =>
          filters.includeVat
            ? numberFormat(rowData.completedPurchases.amountInclVat, 0, undefined, ' EUR')
            : numberFormat(rowData.completedPurchases.amount, 0, undefined, ' EUR')
      });
      formatTable.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_DAMAGE_AND_OTHER',
          'Daune + Alte costuri'
        ),
        render: (rowData: any) =>
          filters.includeVat
            ? numberFormat(rowData.damageAndOtherCostsInclVat, 0, undefined, ' EUR')
            : numberFormat(rowData.damageAndOtherCosts, 0, undefined, ' EUR')
      });
      formatTable.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_AVERAGE_PRICE',
          'Pret Mediu Achizitie'
        ),
        render: (rowData: any) => {
          const price = filters.includeVat
            ? rowData.completedPurchases.amountInclVat
            : rowData.completedPurchases.amount;

          const averagePrice = price / rowData.completedPurchases.count;

          return numberFormat(averagePrice, 0, undefined, ' EUR');
        }
      });
    }
    return formatTable;
  };

  const handleCallback = (childData: any) => {
    setUpdatedItems(childData);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getData = async () => {
      try {
        const filter: AcquisitionFilter = {
          startDate: moment(new Date(filters.startDate.utc().format()).setHours(0, 0, 0))
            .utc()
            .toDate(),
          endDate: moment(new Date(filters.endDate.utc().format()).setHours(23, 59, 59))
            .utc()
            .toDate(),
          organizationIds: !isNil(filters.locationId)
            ? [filters.locationId]
            : filters.selectedOrganisations.map(({ id }) => id),
          makeIds: !isNil(filters.makeId) ? [filters.makeId] : [],
          personTypeIds: !isNil(filters.clientTypeId) ? [filters.clientTypeId] : [],
          offerTypeIds: !isNil(filters.offerTypeId) ? [filters.offerTypeId] : []
        };

        if (filter.startDate && filter.endDate && filter.organizationIds.length) {
          const result = await context.vehicleService.GetDashboardAquisitionData(
            filter,
            source.token
          );

          if (result) {
            const finalResult: AcquisitionResult = {
              ...result,
              purchasedVehiclesByClientType: !isNil(filters.clientTypeId)
                ? data?.purchasedVehiclesByClientType || []
                : result.purchasedVehiclesByClientType,
              purchasedVehiclesByOfferType: !isNil(filters.offerTypeId)
                ? data?.purchasedVehiclesByOfferType || []
                : result.purchasedVehiclesByOfferType,
              purchasedVehiclesByMaker: !isNil(filters.makeId)
                ? data?.purchasedVehiclesByMaker || []
                : result.purchasedVehiclesByMaker
            };
            setData(finalResult);
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    };

    getData();

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.endDate,
    filters.startDate,
    filters.selectedOrganisations,
    context.vehicleService,
    filters.makeId,
    filters.locationId,
    filters.clientTypeId,
    filters.offerTypeId
  ]);

  const checkIfUpdatedItemsIsLoaded = () => {
    if (!data?.recentAcquisitions.length) {
      return false;
    }
    if (!updatedItems.length) {
      return true;
    }

    return false;
  };

  return (
    <div style={{ paddingBottom: '40px' }}>
      <Paper className="p-4 d-flex justify-content-center">
        <DashboardFilters
          filters={filters}
          setFiltersValueByName={setFiltersValueByName}
          vatFilterVisible
        />
        <Button
          className="m-2"
          variant="outlined"
          color="primary"
          disabled={checkIfUpdatedItemsIsLoaded()}
          onClick={async () => {
            context.pptService = new PptService(context.translatorService, data);
            context.pptService.addFirstPage(filters, 'acquisitions');
            context.pptService.addCircleChart(
              data?.purchasedVehiclesByClientType,
              context.translatorService.Tranlate('DASHBOARD_CLIENT_TYPE', 'TIP CLIENT')
            );
            context.pptService.addCircleChart(
              data?.purchasedVehiclesByOfferType,
              context.translatorService.Tranlate('DASHBOARD_OFFER_TYPE', 'TIP Oferta')
            );
            context.pptService.addCircleChart(
              data?.purchasedVehiclesByMaker,
              context.translatorService.Tranlate('CAR_DETAILS_BRAND', 'Marca')
            );
            context.pptService.recentAcquisitions(updatedItems);
            context.pptService.addTable(
              getTopBuyersFormatTable(false, constantsData.topBuyers),
              data?.topBuyers,
              context.translatorService.Tranlate(
                'DASHBOARD_ACQUISITION_TOP_BUYERS',
                'Cumparatori (Top 10)'
              )
            );
            context.pptService.addTable(
              getTopBuyersFormatTable(true, constantsData.topTentaiveBuyers),
              data?.topTentativeBuyers,
              context.translatorService.Tranlate(
                'DASHBOARD_ACQUISITION_TENTATIVE_BUYERS',
                'Tentative Buyers (Top 10)'
              )
            );
            context.pptService.exportPpt();
          }}
        >
          Export
        </Button>
      </Paper>
      {data ? (
        <>
          <div className="mt-4">
            <Grid container spacing={2}>
              <AcquisitionDashboardAppliedFilters
                filters={filters}
                data={data}
                resetFilters={resetFilters}
                setFiltersValueByName={setFiltersValueByName}
              />
              <AcquisitionMainCards data={data} includeVat={filters.includeVat} />
            </Grid>
          </div>
          <div className="mt-2">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card className="h-100">
                  <AcquisitionDonutChart
                    title={context.translatorService.Tranlate(
                      'DASHBOARD_CLIENT_TYPE',
                      'TIP CLIENT'
                    )}
                    items={data.purchasedVehiclesByClientType}
                    handleDonutClick={handleClientTypeDonutClick}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className="h-100">
                  <AcquisitionDonutChart
                    title={context.translatorService.Tranlate('DASHBOARD_OFFER_TYPE', 'TIP Oferta')}
                    items={data.purchasedVehiclesByOfferType}
                    handleDonutClick={handleOfferTypeDonutClick}
                  />
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <AcquisitionDonutChart
                    title={context.translatorService.Tranlate('CAR_DETAILS_BRAND', 'Marca')}
                    items={data.purchasedVehiclesByMaker}
                    handleDonutClick={handleMakeDonutClick}
                  />
                </Card>
              </Grid>
            </Grid>
          </div>
          <div className="mt-4">
            <RecentAcquisitions
              items={data.recentAcquisitions}
              includeVat={filters.includeVat}
              parentCallback={handleCallback}
            />
          </div>
          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <TopBuyers
                  title={context.translatorService.Tranlate(
                    'DASHBOARD_ACQUISITION_TOP_BUYERS',
                    'Cumparatori (Top 10)'
                  )}
                  topBuyers={data.topBuyers}
                  includeVat={filters.includeVat}
                />
              </Grid>
              <Grid item xs={5}>
                <TopBuyers
                  title={context.translatorService.Tranlate(
                    'DASHBOARD_ACQUISITION_TENTATIVE_BUYERS',
                    'Tentative Buyers (Top 10)'
                  )}
                  topBuyers={data.topTentativeBuyers}
                  includeVat={filters.includeVat}
                  isTentative
                />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default AcquistionDashboard;
