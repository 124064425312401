import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Box, Grid, Button, Typography, TextField } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { Workflow, WorkflowPartner, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import { Organization } from '../../interfaces/Organization';
import { AppUser } from '../../interfaces/AppUser';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { IReferential } from '../../interfaces/IReferential';
import { ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { TextValidator } from 'react-material-ui-form-validator';

interface ICaseDetailsCaseTabModifyWorkflowFormState {
  workflows: Workflow[];
  selectedWorkflow: Workflow | null;
  organizations: WorkflowPartner[];
  selectedOrganization: WorkflowPartner | null;
  users: AppUser[];
  selectedUser: AppUser | null;
  isLoading: boolean;
  isLoading2: boolean;
  isLoading3: boolean;
  isOrganizationsDisabled: boolean;
  isUsersDisabled: boolean;
  hasRights: boolean;
  isUserHidden: boolean;
  executing: boolean;
  caseStatusList: IReferential[];
  selectedCaseStatus: IReferential;
  userRoleId: number | null;
}

export interface ExternalCaseDetailsCaseTabModifyWorkflowFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabModifyWorkflowFormProps =
  ExternalCaseDetailsCaseTabModifyWorkflowFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabModifyWorkflowForm extends React.PureComponent<
  ICaseDetailsCaseTabModifyWorkflowFormProps,
  ICaseDetailsCaseTabModifyWorkflowFormState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private caseService!: ICaseService;
  private workflowService!: IWorkflowService;
  private appUserService!: IAppUserService;
  private referencialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    workflows: [],
    selectedWorkflow: null,
    organizations: [],
    selectedOrganization: null,
    users: [],
    selectedUser: null,
    isLoading: true,
    isLoading2: false,
    isLoading3: false,
    isOrganizationsDisabled: false,
    isUsersDisabled: false,
    hasRights: false,
    isUserHidden: false,
    executing: false,
    caseStatusList: [],
    selectedCaseStatus: this.props.caseSettingsState.case!.caseStatus.caseStatus,
    userRoleId: null
  } as ICaseDetailsCaseTabModifyWorkflowFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseWorkflows();
      }
    );
  }

  loadCaseWorkflows = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseStatus);

    const [workflows, ststusList] = await Promise.all([
      this.workflowService.GetUserWorkflows(),
      this.referencialService.Get(ref!.baseUrl)
    ]);

    const owners = await this.organizationService.GetUserOrganizationsByIds(
      Array.from(new Set(workflows.map((item) => item.organizationOwnerId)))
    );
    workflows.forEach((w) => {
      const owner = owners.find((item) => item.id === w.organizationOwnerId);
      w.organizationOwner = isNullOrUndefined(owner) ? null : owner;
    });

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );
    this.setState({
      workflows: workflows,
      isLoading: false,
      hasRights: hasRights,
      caseStatusList: ststusList
    });
  };

  loadOrganizations = async (workflowId: number) => {
    const [workflowPartners] = await Promise.all([
      this.workflowService.GetUserWorkflowPartners(workflowId)
    ]);
    const organizations = await this.organizationService.GetUserOrganizationsByIds(
      workflowPartners.map((item) => item.partnerId)
    );

    workflowPartners.forEach((wp) => {
      const partner = organizations.find((item) => item.id === wp.partnerId);
      wp.partner = isNullOrUndefined(partner) ? null : partner;
    });

    this.setState({
      organizations: workflowPartners,
      isLoading2: false
    });
  };

  loadUsers = async (organizationId: number | null, roleId: number | null) => {
    if (organizationId === null) {
      this.setState({
        isLoading3: false
      });
    } else {
      const users = await this.appUserService.GetUserAppUsers();

      this.setState({
        users: users.users.filter(
          (user) =>
            (user.organization!.hoId === organizationId ||
              user.organizationId === organizationId) &&
            ((roleId !== null && user.roleId === roleId) || roleId === null)
        ),
        isLoading3: false
      });
    }
  };
  handleAutocompleteStatusChange = (newValue: any | null) => {
    this.setState({
      selectedCaseStatus: newValue
    });
  };

  renderWorkflows = () => {
    return (
      <React.Fragment>
        {this.renderWorkflowList()}
        <div>
          <Autocomplete
            id="statusList"
            className="ml-4 mr-4 mb-2"
            disabled={!this.state.hasRights}
            disableClearable
            options={this.state.caseStatusList.sort(function (a, b) {
              return a.displayName.localeCompare(b.displayName);
            })}
            value={this.state.selectedCaseStatus}
            onChange={(e: any, newValue: any | null) =>
              this.handleAutocompleteStatusChange(newValue)
            }
            getOptionLabel={(option: any) => option.displayName}
            renderInput={(params) => (
              <TextField
                {...params}
                name="statusList"
                value={this.state.selectedCaseStatus}
                label={this.translatorService.Tranlate(
                  'EDIT_ORGANIZATION_FORM_REPORT_STATUS_LABEL',
                  'Status'
                )}
                fullWidth
              />
            )}
          />
        </div>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading2} />
        </div>
        {this.renderAssignementForm()}
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          onClick={this.changeWorkflow}
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </React.Fragment>
    );
  };

  renderAssignementForm = () => {
    if (this.state.isUserHidden) {
      return null;
    }

    return (
      <React.Fragment>
        {!this.state.isLoading2 ? (
          <div className="m-3">
            <Autocomplete
              disabled={this.state.isOrganizationsDisabled || !this.state.hasRights}
              id="workflowPartner"
              className="m-2"
              options={this.state.organizations.sort(function (a, b) {
                return a.partner!.displayName.localeCompare(b.partner!.displayName);
              })}
              value={this.state.selectedOrganization}
              onChange={(e: any, newValue: WorkflowPartner | null) =>
                this.handleOrganizationChange(newValue)
              }
              getOptionLabel={(option: WorkflowPartner) => option.partner!.displayName || ''}
              filterOptions={createFilterOptions({
                matchFrom: 'any',
                stringify: (option: WorkflowPartner) => option.partner!.displayName
              })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="workflowPartner"
                  value={this.state.selectedOrganization}
                  label={this.translatorService.Tranlate(
                    'CASE_MODIFY_WORKFLOW_FORM_ORGANIZATION_LABEL',
                    'Organizatie'
                  )}
                  fullWidth
                />
              )}
            />

            <div className="d-flex flex-row text-center flex-wrap justify-content-center">
              <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading3} />
            </div>
            {!this.state.isLoading3 ? (
              <Autocomplete
                disabled={this.state.isUsersDisabled || !this.state.hasRights}
                id="user"
                className="m-2"
                options={this.state.users.sort(function (a, b) {
                  return a.userName.localeCompare(b.userName);
                })}
                value={this.state.selectedUser}
                onChange={(e: any, newValue: AppUser | null) => this.handleUserChange(newValue)}
                getOptionLabel={(option: AppUser) => option.userName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="user"
                    value={this.state.selectedUser}
                    label={this.translatorService.Tranlate(
                      'CASE_MODIFY_WORKFLOW_FORM_USER_LABEL',
                      'Utilizator'
                    )}
                    fullWidth
                  />
                )}
              />
            ) : null}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  changeWorkflow = async () => {
    if (isNullOrUndefined(this.state.selectedWorkflow)) {
      return;
    }
    try {
      this.setState({ executing: true });

      await this.caseService.ChangeCaseWorkflow(
        this.state.selectedWorkflow.id,
        this.props.caseSettingsState.case!.id,
        isNullOrUndefined(this.state.selectedUser) ? '' : this.state.selectedUser.id!,
        this.state.selectedCaseStatus.id
      );

      if (this.props.workflowForm.form.hasActions) {
        const selectedOrganization = this.state.selectedOrganization;
        const hoId =
          selectedOrganization === null
            ? null
            : selectedOrganization.partner!.hoId === null
            ? selectedOrganization.partnerId
            : selectedOrganization.partner!.hoId;

        await this.submitForm(
          this.props.caseSettingsState.case!.id,
          this.props.workflowForm.id,
          this.state.selectedUser
        );
      }

      this.props.SetCaseSettings(this.props.caseSettingsState.case!.id);
    } catch (ex) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(ex.response.data, 'Eroare modificare flux!'),
        { variant: 'error' }
      );
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }
    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  renderWorkflowList = () => {
    let selectedItem = -1;
    if (!isNullOrUndefined(this.state.selectedWorkflow)) {
      selectedItem = this.state.selectedWorkflow.id;
    }

    const organizations: Organization[] = [];

    const scopeWorkflows = this.state.workflows.filter(
      (item) => item.caseTypeId === this.props.caseSettingsState.case!.caseTypeId
    );
    scopeWorkflows.forEach((w) => {
      if (organizations.find((item) => item.id === w.organizationOwnerId) === undefined) {
        organizations.push(w.organizationOwner!);
      }
    });

    return organizations.map((org, index) => (
      <React.Fragment key={index}>
        <Typography className="text-left m-2 " component="h5" variant="h5" color="secondary">
          {org.displayName}
        </Typography>
        <Grid container spacing={0} className="mx-3">
          {scopeWorkflows
            .filter((w) => w.organizationOwnerId === org.id)
            .map((item, index) => (
              <Grid item sm={3} xl={3} key={index} className="m-2">
                <Box
                  height={80}
                  key={index}
                  border={1}
                  borderColor="text.disabled"
                  width="100%"
                  borderRadius="0%"
                  bgcolor={selectedItem === item.id ? 'secondary.main' : ''}
                >
                  <Button
                    key={index}
                    className={selectedItem === item.id ? 'text-white h-100 ' : 'text-dark h-100'}
                    disabled={!this.state.hasRights}
                    onClick={() => this.handleSelectWorkflow(item)}
                  >
                    <b>{item.displayName}</b>
                  </Button>
                </Box>
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    ));
  };

  handleSelectWorkflow = (item: Workflow) => {
    this.setState(
      {
        selectedWorkflow: item,
        isLoading2: true,
        selectedUser: null,
        selectedOrganization: null,
        isUsersDisabled: false,
        isOrganizationsDisabled: false
      },
      async () => {
        await this.loadOrganizations(item.id);
        await this.setCaseAllocation();
      }
    );
  };

  setCaseAllocation = async () => {
    const allocationResult = await FormHelpers.ParseAllocations(
      this.props.workflowForm.workflowFormActions,
      this.state.organizations,
      this.appUserService
    );

    if (isNullOrUndefined(allocationResult)) {
      const partner = this.state.organizations.find(
        (item) => item.partnerId === this.props.caseSettingsState.case!.caseStatus.assignedTo!.hoId
      );

      this.setState(
        {
          selectedOrganization: isNullOrUndefined(partner) ? null : partner,
          selectedUser: this.props.caseSettingsState.case!.caseStatus.assignedTo,
          organizations: this.state.organizations,
          isUsersDisabled: false,
          isOrganizationsDisabled: false
        },
        async () =>
          await this.loadUsers(isNullOrUndefined(partner) ? null : partner.partnerId, null)
      );
      return;
    }
    if (allocationResult.isLastUser || allocationResult.caseStatusId != null) {
      this.setState({ isUserHidden: true });
      return;
    }
    this.setState(
      {
        selectedOrganization: allocationResult.partner,
        selectedUser: allocationResult.user,
        organizations: allocationResult.partners,
        isUsersDisabled: !isNullOrUndefined(allocationResult.user),
        isOrganizationsDisabled: !isNullOrUndefined(allocationResult.partner),
        userRoleId: allocationResult.roleId
      },
      () => this.handleOrganizationChange(allocationResult.partner)
    );
  };

  handleOrganizationChange = (newValue: WorkflowPartner | null) => {
    this.setState(
      {
        selectedOrganization: newValue,
        users: [],
        isLoading3: true
      },
      async () => {
        await this.loadUsers(newValue === null ? null : newValue.partnerId, this.state.userRoleId);
      }
    );
  };

  handleUserChange = (newValue: AppUser | null) => {
    this.setState({ selectedUser: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.caseService = (this.context as AppContext).caseService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.referencialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="app-inner-content-layout ">
            <div className="bg-white p-0 text-center w-100">{this.renderWorkflows()}</div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabModifyWorkflowFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabModifyWorkflowForm as any));
