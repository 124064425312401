import React from 'react';

interface Props {
  title: string;
  value: string;
  adsValue: string;
}

const SalesSummaryCard: React.FC<Props> = ({ title, value, adsValue }) => (
  <div style={{ flex: 1 }}>
    <h6 className="text-uppercase">{title}</h6>
    <h2 className="text-center">{value}</h2>
    <h4 className="text-center">{adsValue}</h4>
  </div>
);

export default SalesSummaryCard;
