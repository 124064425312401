import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  FormControlLabel,
  CardMedia,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { ApplicationContext } from '../../context/Contexts';
import { useHistory } from 'react-router';
import { ValuationVehicleOptions } from '../../interfaces/Case';
import { Label } from 'reactstrap';
import * as R from 'ramda';
import { Utils } from '../../helpers/Utils';
import Loader from '../Loader';
import { useSnackbar } from 'notistack';
import { useAppSelector } from '../../store/configureStore';
import { ValuationCarFile } from './evaluation.types';
import { getCarValuationDetails, getValuationVehicleOptions } from './evaluation.requests';
import ImageNotAvailable from '../../assets/images/car-image-not-available.jpg';
import { standardOptionsSchemaIds } from './DetailsValuation';
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import EvaluationReportFile from './EvaluationReportFile';
import moment from 'moment';

const EvaluationCarFile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(ApplicationContext);
  const history = useHistory();
  const translatorService = context.translatorService;
  const language = useAppSelector((state) => (state.app?.language ? state.app.language : 'ro'));
  const valLanguageId = Utils.GetValuationLanguageId(language);
  const valuationId = parseInt(useParams<{ id: string }>().id);
  const [isLoading, setIsLoading] = useState(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [carDetails, setCarDetails] = useState<ValuationCarFile>({} as ValuationCarFile);
  const reportRef = useRef<HTMLDivElement>(null);

  const fetchCarDetails = async (id: number) => {
    setIsLoading(true);
    try {
      const carDetailsResponse = await getCarValuationDetails(id);

      if (carDetailsResponse.error || !carDetailsResponse.data) {
        resetCarDetails();
        enqueueSnackbar(
          translatorService.Tranlate(
            'EVALUATION_CAR_FILE_ERROR',
            'A aparut o eroare la preluarea detaliilor masinii'
          ),
          { variant: 'error' }
        );

        setIsLoading(false);
        return;
      }

      let decodedOptions = {} as ValuationVehicleOptions;
      if (
        !R.isNil(carDetailsResponse.data.options) &&
        !R.isEmpty(carDetailsResponse.data.options)
      ) {
        decodedOptions = {
          extraOptions: JSON.parse(carDetailsResponse.data.options),
          standardOptions: []
        };
      }

      setCarDetails(
        (prevState) =>
          ({
            ...prevState,
            ...carDetailsResponse.data,
            options: decodedOptions
          } as ValuationCarFile)
      );
    } catch (error) {
      resetCarDetails();
      enqueueSnackbar(
        translatorService.Tranlate(
          'EVALUATION_CAR_FILE_ERROR',
          'A aparut o eroare la preluarea detaliilor masinii'
        ),
        { variant: 'error' }
      );
    }
    setIsLoading(false);
  };

  const getCarOptions = async (vehicleId: number) => {
    setIsOptionsLoading(true);
    try {
      const carOptionsResponse = await getValuationVehicleOptions(vehicleId);
      if (carOptionsResponse.error || !carOptionsResponse.data) {
        enqueueSnackbar(
          translatorService.Tranlate(
            'EVALUATION_CAR_FILE_FETCHING_STANDARD_OPTIONS_ERROR',
            'A aparut o eroare la preluarea optiunilor standard ale vehiculului'
          ),
          { variant: 'error' }
        );
        setIsOptionsLoading(false);
        return;
      }

      const standardOptions = carOptionsResponse.data[0].standardOptions.filter(
        (option) =>
          option.languageId === valLanguageId && !standardOptionsSchemaIds.includes(option.schemaId)
      );

      setCarDetails(
        (prevState) =>
          ({
            ...prevState,
            options: {
              ...prevState.options,
              standardOptions: standardOptions
            }
          } as ValuationCarFile)
      );
    } catch (error) {
      enqueueSnackbar(
        translatorService.Tranlate(
          'EVALUATION_CAR_FILE_FETCHING_STANDARD_OPTIONS_ERROR',
          'A aparut o eroare la preluarea optiunilor standard ale vehiculului'
        ),
        { variant: 'error' }
      );
    }
    setIsOptionsLoading(false);
  };

  const resetCarDetails = () => {
    setCarDetails({
      date: '',
      valuationDate: '',
      numberOfKilometers: 0,
      extraOptionsPrice: 0,
      extraOptionsDepreciationPrice: 0,
      valuationPrice: 0,
      priceAsNew: 0,
      bodyDamageValue: 0,
      attritionValue: 0,
      fabricationDate: '',
      gpl: false,
      vatRate: 0,
      options: null,
      registrationDate: '',
      color: '',
      imageUrl: '',
      tradeInPrice: 0,
      offerPrice: 0,
      retailPrice: 0,
      make: '',
      model: '',
      version: '',
      equipmentVersion: '',
      dateCreation: '',
      dateModification: '',
      createdBy: '',
      modifiedBy: '',
      id: 0,
      caseId: 0,
      userId: '',
      valuationSourceId: 0,
      vehicleSourceId: 0,
      case: null,
      vin: ''
    });
  };

  const goToNewEvaluation = () => {
    history.push('/valuation');
    resetCarDetails();
  };

  const goToHistory = () => {
    history.push('/valuation/history');
    resetCarDetails();
  };

  const formattedThisDateTime = () => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-GB').replace(/\//g, '.');
    const formattedTime = now.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit'
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  const downloadCarPdfRaport = async () => {
    const element = reportRef.current;
    if (element) {
      const options = {
        margin: [20, 10, 25, 10],
        filename: `Raport_evaluare_${carDetails.make}_${carDetails.model}_${new Date(
          carDetails.date
        ).toLocaleDateString('en-GB')}.pdf`,
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 4, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      };

      const worker = html2pdf().from(element).set(options).toPdf();

      worker.get('pdf').then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();
        const pageHeight = pdf.internal.pageSize.height;
        const pageWidth = pdf.internal.pageSize.width;
        const reportName = translatorService.Tranlate('VALUATION_REPORT_TITLE', 'Raport evaluare');
        const dateAndTime = formattedThisDateTime();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);

          // Add 'Raport evaluare' to the top left corner
          pdf.text(reportName, 10, 10);

          // Add date and time to the top right corner
          pdf.text(dateAndTime, pageWidth - 30, 10);

          // Add page number to the bottom right corner
          pdf.text(`${i}/${totalPages}`, pageWidth - 15, pageHeight - 10);
        }
      });

      worker.save();
    }
  };

  useEffect(() => {
    fetchCarDetails(valuationId);
  }, []);

  useEffect(() => {
    if (carDetails.vehicleSourceId) {
      getCarOptions(carDetails.vehicleSourceId);
      // getCarOptions(833282720240220);
    }
  }, [carDetails.vehicleSourceId]);

  const checkIfImageExists = (url: string | undefined) => {
    if (R.isNil(url) || url.includes('undefined') || url.includes('null') || url === '') {
      return ImageNotAvailable;
    }
    return url;
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Grid
            container
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography variant="h2" className="m-1 mb-4">
              {`${!R.isNil(carDetails?.make) ? carDetails.make : ''}  
              ${!R.isNil(carDetails?.model) ? carDetails.model : ''}
              ${!R.isNil(carDetails?.version) ? carDetails.version : ''}
            `}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className="m-2 mb-4"
              onClick={() => {
                goToHistory();
              }}
            >
              {translatorService.Tranlate('CAR_DETAILS_HISTORY_BUTTON_LABEL', 'Istoric evaluari')}
            </Button>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Card elevation={1} className="p-4 mb-2">
                <CardContent>
                  <TextField
                    fullWidth
                    className="m-2"
                    value={
                      carDetails.registrationDate
                        ? moment(carDetails.registrationDate).format('MM/YYYY')
                        : '-'
                    }
                    label={translatorService.Tranlate(
                      'VALUATION_REGISTRATION_DATE_LABEL',
                      'Data primei inmatriculari'
                    )}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    value={
                      carDetails.fabricationDate
                        ? new Date(carDetails.fabricationDate).toLocaleDateString('en-GB')
                        : '-'
                    }
                    label={translatorService.Tranlate(
                      'CAR_DETAILS_DATE_OF_FABRICATION',
                      'Data fabricatie'
                    )}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    value={carDetails.color ? carDetails.color : '-'}
                    label={translatorService.Tranlate('CAR_DETAILS_COLOR', 'Culoare')}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    value={carDetails.numberOfKilometers ? carDetails.numberOfKilometers : 0}
                    label={translatorService.Tranlate('VALUATION_KILOMETERS_LABEL', 'Kilometri')}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    value={carDetails.attritionValue ? carDetails.attritionValue : 0}
                    label={translatorService.Tranlate(
                      'VALUATION_TIRE_ATTRITION_LABEL',
                      'Uzura anvelope'
                    )}
                  />
                  <TextField
                    fullWidth
                    className="m-2"
                    value={carDetails.bodyDamageValue ? carDetails.bodyDamageValue : 0}
                    label={translatorService.Tranlate(
                      'VALUATION_CHASSIS_DAMAGE_ATTRITION_LABEL',
                      'Daune caroserie'
                    )}
                  />
                  <FormControlLabel
                    className="pl-2"
                    control={<Checkbox checked={carDetails.gpl} color="primary" />}
                    label={translatorService.Tranlate(
                      'VALUATION_CAR_HAS_GPL_LABEL',
                      'Masina prezinta instalatie GPL'
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card elevation={1} className="p-4">
                <CardContent>
                  <div className="d-flex flex-row text-center flex-wrap justify-content-center"></div>
                  <CardMedia
                    style={{
                      height: 'auto',
                      width: '100%',
                      maxWidth: '380px',
                      margin: '0 auto',
                      minHeight: '230px'
                    }}
                    className="mb-2"
                    image={checkIfImageExists(carDetails.imageUrl)}
                  />
                </CardContent>
              </Card>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className="m-2"
                    onClick={() => {
                      downloadCarPdfRaport();
                    }}
                    disabled={isLoading || isOptionsLoading}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_CAR_FILE_DOWNLOAD_RAPORT_PDF',
                      'Descarca raport PDF'
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className="m-2"
                    onClick={() => {
                      goToNewEvaluation();
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_CAR_FILE_NEW_EVALUATION',
                      'Evaluare noua'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="stretch" className="mb-2">
            <Grid item xs={6}>
              <Card elevation={1} className="p-4 mb-2">
                <CardContent>
                  <Grid container>
                    <Grid container>
                      <Grid item className="mr-2">
                        <div style={{ marginBottom: '4px' }}>
                          <Label>
                            {`${translatorService.Tranlate(
                              'VALUATION_BASE_PRICE_LABEL_NEW',
                              'Pret masina noua (incl. TVA)'
                            )}:`}
                          </Label>
                        </div>
                        <div style={{ marginBottom: '4px' }}>
                          <Label>
                            {`${translatorService.Tranlate(
                              'VALUATION_OPTIONS_PRICE_LABEL_NEW',
                              'Pret echipamente extra (incl. TVA)'
                            )}:`}
                          </Label>
                        </div>
                        <div style={{ marginBottom: '4px' }}>
                          <Label>
                            {`${translatorService.Tranlate(
                              'VALUATION_TOTAL_LABEL_NEW',
                              'Pret de nou total (incl. TVA)'
                            )}:`}
                          </Label>
                        </div>
                      </Grid>
                      <Grid item>
                        <h5>
                          {carDetails.priceAsNew ? carDetails.priceAsNew.toFixed(2) : '0.00'} €
                        </h5>
                        <h5>
                          {carDetails.extraOptionsPrice
                            ? carDetails.extraOptionsPrice.toFixed(2)
                            : '0.00'}{' '}
                          €
                        </h5>
                        <h5>
                          {carDetails.priceAsNew >= 0 && carDetails.extraOptionsPrice >= 0
                            ? (carDetails.priceAsNew + carDetails.extraOptionsPrice).toFixed(2)
                            : '0.00'}{' '}
                          €
                        </h5>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card elevation={1} className="p-4 mb-2">
                <CardContent>
                  <Grid container>
                    <Grid container>
                      <Grid item className="mr-2">
                        <div style={{ marginBottom: '4px' }}>
                          <Label>
                            {`${translatorService.Tranlate(
                              'VALUATION_TRADE_IN_PRICE_LABEL',
                              'Trade-in price (incl. TVA)'
                            )}:`}
                          </Label>
                        </div>
                        <div style={{ marginBottom: '4px' }} className="text-success">
                          <Label>
                            {`${translatorService.Tranlate(
                              'VALUATION_OFFER_PRICE_LABEL',
                              'Offer price (incl. TVA)'
                            )}:`}
                          </Label>
                        </div>
                        <div style={{ marginBottom: '4spx' }} className="text-success">
                          <Label>
                            {`${translatorService.Tranlate(
                              'VALUATION_RETAIL_PRICE_LABEL',
                              'Retail price (incl. TVA)'
                            )}:`}
                          </Label>
                        </div>
                      </Grid>
                      <Grid item>
                        <h5>
                          {carDetails.tradeInPrice ? carDetails.tradeInPrice.toFixed(2) : 0} €
                        </h5>
                        <h5 className="text-success">
                          {carDetails.offerPrice ? carDetails.offerPrice.toFixed(2) : 0} €
                        </h5>
                        <h5 className="text-success">
                          {carDetails.retailPrice ? carDetails.retailPrice.toFixed(2) : 0} €
                        </h5>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      {`${translatorService.Tranlate(
                        'VALUATION_CAR_FILE_STANDARD_OPTIONS',
                        'Optionale standard selectate'
                      )}:`}
                    </Typography>
                    {!isOptionsLoading ? (
                      R.isNil(carDetails.options) ||
                      R.isNil(carDetails.options.standardOptions) ||
                      carDetails.options.standardOptions.length === 0 ? (
                        <ListItem>
                          <ListItemText
                            secondary={translatorService.Tranlate(
                              'VALUATION_CAR_FILE_EMPTY_LIST',
                              'Nu exista optiuni selectate'
                            )}
                          />
                        </ListItem>
                      ) : (
                        <List
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                          }}
                        >
                          {carDetails.options?.standardOptions.map((item, index) => (
                            <ListItem
                              key={index}
                              style={{
                                width: '50%',
                                alignItems: 'baseline',
                                padding: '0px 0px 0px 16px'
                              }}
                            >
                              <ListItemIcon style={{ padding: '0px', minWidth: '20px' }}>
                                <FiberManualRecord
                                  style={{
                                    fontSize: '7px',
                                    color: 'black'
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={`${item?.content}`} />
                            </ListItem>
                          ))}
                        </List>
                      )
                    ) : (
                      <Loader />
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      {`${translatorService.Tranlate(
                        'VALUATION_CAR_FILE_EXTRA_OPTIONS',
                        'Optionale extra selectate'
                      )}:`}
                    </Typography>
                    {R.isNil(carDetails.options) ||
                    R.isNil(carDetails.options.extraOptions) ||
                    carDetails.options.extraOptions.length === 0 ? (
                      <ListItem>
                        <ListItemText
                          secondary={translatorService.Tranlate(
                            'VALUATION_CAR_FILE_EMPTY_LIST',
                            'Nu exista optiuni selectate'
                          )}
                        />
                      </ListItem>
                    ) : (
                      <List
                        component="ul"
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap'
                        }}
                      >
                        {carDetails.options?.extraOptions.map((item, index) => (
                          <ListItem
                            key={index}
                            style={{
                              width: '50%',
                              alignItems: 'baseline',
                              padding: '0px 0px 0px 16px'
                            }}
                          >
                            <ListItemIcon style={{ padding: '0px', minWidth: '20px' }}>
                              <FiberManualRecord
                                style={{
                                  fontSize: '7px',
                                  color: 'black'
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={`[${item?.optionCode}] ${item?.description} - ${item?.price} €`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          {!isLoading && !isOptionsLoading && (
            <div style={{ display: 'none' }}>
              <div ref={reportRef}>
                <EvaluationReportFile carDetails={carDetails} />
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default EvaluationCarFile;
