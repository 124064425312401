import { CaseCalculationPart } from '../interfaces/Case';
import { IReferential } from './IReferential';
import { CalculationSectionCodeEnum } from '../helpers/Constants';

export class CalculationParts {
  private _parts: CaseCalculationPart[];
  private _sections: IReferential[];
  private _vat: number;
  private _ignoreAlternativePart: boolean;
  private _smallPartPercentage: number;

  public constructor(
    sections: IReferential[],
    parts: CaseCalculationPart[],
    vat: number,
    smallPartsPercent: number,
    ignoreAlternativePart = true
  ) {
    this._sections = sections;
    this._parts = parts;
    this._vat = vat / 100;
    this._smallPartPercentage = smallPartsPercent;
    this._ignoreAlternativePart = ignoreAlternativePart;
  }

  public get SmallPartPercentage(): number {
    return this._smallPartPercentage;
  }

  public GetSmallPartsValue(): number {
    return this.GetAmountSalePartWithDiscount(CalculationSectionCodeEnum.PART) * (this._smallPartPercentage / 100);
  }

  private GetPrice(part: CaseCalculationPart): number {
    return part.alternativePartQualityId === null || this._ignoreAlternativePart
      ? part.originalPrice
      : part.alternativePrice ?? 0;
  }

  private GetDiscount(part: CaseCalculationPart): number {
    const discount =
      part.alternativePartQualityId === null || this._ignoreAlternativePart
        ? part.discount
        : part.alternativePartDiscount;
    const addition = part.additionInsurer !== null ? part.additionInsurer : 0;

    return discount !== null ? (discount - addition) / 100 : (0 - addition) / 100;
  }

  private GetInsurerAddition(part: CaseCalculationPart): number {
    return part.additionInsurer !== null ?  part.additionInsurer / 100 : 0;
  }

  public GetAmountPart(section: string): number {
    const partsSectionId: number = this._sections.find(
      (item) => item.isActive && item.code === section
    )!.id;
    return this._parts
      .filter((item) => item.calculationSectionId === partsSectionId)
      .reduce((sum, item) => sum + item.pieceNr * this.GetPrice(item), 0);
  }

  public GetAmountPieceTotal(): number {
    return this.GetAmountPart(CalculationSectionCodeEnum.PART);
  }

  public GetAmountPartWithDiscount(section: string): number {
    const partsSectionId: number = this._sections.find(
      (item) => item.isActive && item.code === section
    )!.id;
    const smallPartValue: number =
      section === CalculationSectionCodeEnum.PART ? this.GetSmallPartsValue() : 0;

    return (
      smallPartValue +
      this._parts
        .filter((item) => item.calculationSectionId === partsSectionId)
        .reduce(
          (sum, item) => sum + item.pieceNr * this.GetPrice(item) * (1 - this.GetDiscount(item)),
          0
        )
    );
  }

  public GetAmountSalePartWithDiscount(section: string): number {
    const partsSectionId: number = this._sections.find(
      (item) => item.isActive && item.code === section
    )!.id;
    return (
      this._parts
        .filter((item) => item.calculationSectionId === partsSectionId)
        .reduce(
          (sum, item) => sum + item.pieceNr * this.GetPrice(item) * (1 - this.GetDiscount(item)),
          0
        )
    );
  }

  public TotalDiscount(): number {
    return this._parts.reduce(
      (sum, item) => sum + item.pieceNr * this.GetPrice(item) * this.GetDiscount(item),
      0
    );
  }

  public GetTotalInsurerAddition(section: string): number {
    const partsSectionId: number = this._sections.find(
      (item) => item.isActive && item.code === section
    )!.id;

    return (
      this._parts
        .filter((item) => item.calculationSectionId === partsSectionId)
        .reduce(
          (sum, item) => sum + item.pieceNr * this.GetPrice(item) *  this.GetInsurerAddition(item),
          0
        )
    );
  }

  public TotalWithoutVAT(): number {
    const totalParts: number = this._parts.reduce(
      (sum, item) => sum + item.pieceNr * this.GetPrice(item),
      0
    );

    const smallPartsValue: number = this.GetSmallPartsValue();
    const discount: number = this.TotalDiscount();

    return totalParts - discount  + smallPartsValue;
  }

  public TotalVAT(): number {
    return this.TotalWithoutVAT() * this._vat;
  }

  public Total(): number {
    const totalWithoutVAT: number = this.TotalWithoutVAT();
    return totalWithoutVAT + totalWithoutVAT * this._vat;
  }

  private GetDiscountPercent(part: CaseCalculationPart): number {
    const discount =
      part.alternativePartQualityId === null || this._ignoreAlternativePart
        ? part.discount
        : part.alternativePartDiscount;

    return discount !== null ? discount / 100 : 0;
  }

  public GetTotalPartsDiscount(section: string): number {
    const partsSectionId: number = this._sections.find(
      (item) => item.isActive && item.code === section
    )!.id;

    return (
      this._parts
        .filter((item) => item.calculationSectionId === partsSectionId)
        .reduce(
          (sum, item) => sum + item.pieceNr * this.GetPrice(item) *  this.GetDiscountPercent(item),
          0
        )
    );
  }
  
}
