import React, { useContext, useEffect, useMemo, useState } from 'react';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions, MUIDataTableMeta, SelectableRows } from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { Box, Button, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, Tooltip } from '@material-ui/core';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import moment from 'moment';
import { ApplicationContext } from '../../context/Contexts';
import { useAppSelector } from '../../store/configureStore';
import { getDay, getHour } from '../../utils/appointments';
import { useHistory } from 'react-router';
import {  CreateNewFolder, Visibility } from '@material-ui/icons';
import { NewCaseAppointment } from './NewCaseAppointment';
import Folder from '@material-ui/icons/Folder';
import { AppointmentFilterStateKeys, AppointmentFiltersPayloadType, AppointmentType, AppointmentsFiltersType } from '../../types/Agenda';
import * as R from 'ramda';
import { getAppointmentFiltersRequest } from './agenda.requests';
import { DropDownSelect } from './DropDownSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core';

const initialFilters: AppointmentFiltersPayloadType = {
  appointmentIds: [],
  formatedDateList: [],
  formatedHourList: [],
  makeList: [],
  modelList: [],
  plateNumberList: [],
  appointmentStatuses: [],
  formattedDateCreationList: [],
  formattedHourCreationList: [],
  appAppointmentIds: [],
  appointmentCategoryTypes: []
}

type AppointmentTableProps = {
  appointments: AppointmentType[],
  getAppointment: (filter: AppointmentsFiltersType) => void,
  tableProps: any,
  setOpenModal:any
}

export const AppointmentsTable = ({ appointments, getAppointment, tableProps, setOpenModal }: AppointmentTableProps) => {
  const context = useContext(ApplicationContext);
  const history = useHistory();
  const translatorService = context.translatorService;
  const language = useAppSelector((state) => state.app?.language)
  const statusList = useAppSelector((state) => state.agenda?.statusList)
  const [filters, setFilters] = useState<AppointmentFiltersPayloadType>();
  const [appointmentId, setAppointmentId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedfilters, setSelectedFilters] = useState<AppointmentFiltersPayloadType>(initialFilters)
  const allValue = translatorService.Tranlate('APPOINTMENT_TABLE_ALL_VALUE', 'Toate');
  const [formatedHours, setFormatedHours] = useState<string[]>();
  const longDateFormat = useAppSelector((state) => state.app?.longDateFormat);
  const dateFormat = useAppSelector((state) => state.app?.dateFormat);

  const getAppointmentFilters = async () => {
    setIsLoading(false)
    const res = await getAppointmentFiltersRequest();
    const filters = res.data as AppointmentFiltersPayloadType
    if (res.error || !filters) {
      setIsLoading(false)
      return;
    }
    const formattedHourCreationListToLocal = filters?.formattedHourCreationList.map((item) => {
      return getHour(moment
        ?.utc(item)
        ?.local()
        ?.format(longDateFormat || 'DD/MM/YYYY HH:mm'))
    })
    setFormatedHours(formattedHourCreationListToLocal);
    setFilters(filters)
    setIsLoading(false)
  }

  const getStatusIds = (filterList: any) => {
    const statusList: any = [];
    const statusNameMap: { [key: string]: string } = {};
    filterList.appointmentStatuses?.forEach((selectedStatus: string) => {
      statusNameMap[selectedStatus] = selectedStatus;
    });
    filters?.appointmentStatuses?.forEach((status: any) => {
      const index = status.translations.findIndex((translation: any) => statusNameMap[translation.name]);
      if (index !== -1) {
        statusList.push(status.id);
      }
    });
    return statusList;
  };

  const getAppointmentCategoryTypeIds = (filterList: any) => {
    const appointmentCategoryTypeList: any = [];
    const appointmentCategoryTypeNameMap: { [key: string]: string } = {};
    filterList.appointmentCategoryTypes?.forEach((selectedAppointmentCategoryType: string) => {
      appointmentCategoryTypeNameMap[selectedAppointmentCategoryType] = selectedAppointmentCategoryType;
    });

    console.log(filters?.appointmentCategoryTypes);
    filters?.appointmentCategoryTypes?.forEach((status: any) => {
      const index = status.translations.findIndex((translation: any) => appointmentCategoryTypeNameMap[translation.name]);
      if (index !== -1) {
        appointmentCategoryTypeList.push(status.id);
      }
    });

    return appointmentCategoryTypeList;
  };

  const getFormatedHour = (selectedValue: string[]) => {
    const futureArray = selectedValue.map((hour) => {
      const index = formatedHours?.findIndex((val) => val === hour);
      return getHour(filters?.formattedHourCreationList[index])
    })
    return futureArray;
  }

  const applyFilters = (currentFilterList:any)=>{
    const futureFilterList: any = { ...selectedfilters };
    Object.keys(selectedfilters).map((key: string) => {
      const selectedValue = currentFilterList[AppointmentFilterStateKeys[key]];
      if (key === 'formattedHourCreationList') {
        futureFilterList[key] = selectedValue[0] === allValue ? [...new Set(formatedHours)] : getFormatedHour(selectedValue)
      } else {
        futureFilterList[key] = selectedValue[0] === allValue ? filters[key] : selectedValue
      }
    });

    const statusIds = futureFilterList.appointmentStatuses?.length > 0 ? getStatusIds(futureFilterList) :[];
    delete futureFilterList.appointmentStatuses

    const appointmentCategoryTypeIds = futureFilterList.appointmentCategoryTypes?.length > 0 ? getAppointmentCategoryTypeIds(futureFilterList) : [];
    delete futureFilterList.appointmentCategoryTypes;

    getAppointment({
      ...futureFilterList,
      statusIds: statusIds,
      appointmentCategoryTypeIds: appointmentCategoryTypeIds,
      includedAdditionalData:true
    })
  }

  useEffect(()=>{
    if(language){
      getAppointmentFilters();
    }
  },[])

  const options = {
    filter: true,
    serverSide: true,
    selectableRows: 'none' as SelectableRows,
    selectableRowsOnClick: false,
    print: false,
    responsive: 'scroll',
    download: false,
    textLabels: MUITranslations.GetTranslations(translatorService),
    enableNestedDataAccess: '.',
    sort: false,
    count: tableProps?.total || 0,
    page: tableProps?.page || 0,
    rowsPerPage: tableProps?.rowsPerPage || 19,
    data: appointments,
    displayData: appointments,
    searchText: tableProps?.search || '',
    // responsive: "stacked",
    onTableChange: (action: string, tableState: any) => {
      if (action === 'changeRowsPerPage' || action === "changePage") {
        getAppointment({
          page: tableState?.page + 1,
          pageSize: tableState?.rowsPerPage,
          text: tableState?.searchText,
          includedAdditionalData:true,
          ...selectedfilters
        })
      } else if (action === 'search') {
        getAppointment({
          page: 1,
          pageSize: tableState?.rowsPerPage,
          text: tableState?.searchText,
          includedAdditionalData:true,
          ...selectedfilters
        })
      }

    },
    customToolbar: () => {
      return (<>
        <Tooltip title={translatorService.Tranlate('Add', 'Adauga')}>
          <IconButton
            aria-label="add"
            color="primary"
            onClick={(e) => {
              history.push('/appointment')
            }}
          >
            <AddCircleTwoToneIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={translatorService.Tranlate('Calendar', 'Calendar')}>
          <IconButton
            aria-label="add"
            color="primary"
            onClick={() => setOpenModal(true)}
          >
            <FontAwesomeIcon  icon={['fas', 'calendar']} style={{ width: 20, height: 20, color: '#5383ff' }} />
          </IconButton>
        </Tooltip>
        
      </>

      );
    },
    onFilterChange: (column, filterList, type) => {
      if(type === 'reset'){
        setSelectedFilters(initialFilters)
      }
    },

    customFilterDialogFooter: (currentFilterList:any, applyNewFilters:any) => {
      return (
        <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
          <Button
            className="m-2"
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => { applyFilters(currentFilterList) }}
          >

            {translatorService.Tranlate('APPOINTMENT_TABLE_APPLY_FILTERS', 'Apply Filters')}
          </Button>
        </div>
      );
    },
  };

  const COLUMNS: MUIDataTableColumn[] = useMemo(
    () => [
      {
        name: 'id',
        label: translatorService.Tranlate('APPOINTMENT_TABLE_NO','No.'),
        options: {
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = filters?.appointmentIds ? [allValue, ...filters?.appointmentIds] : [allValue];
              return <DropDownSelect 
                filterList={filterList}
                onChange={onChange}
                index={index} 
                column={column} 
                optionValues={optionValues}
                name={translatorService.Tranlate('APPOINTMENT_TABLE_NO', 'No.')}
              />
           },
          }
        }
      },
      {
        name: 'dateCreation',
        label: translatorService.Tranlate('APPOINTMENT_TABLE_DATE_CREATION','Creation Date'),
        options: {
          customBodyRender: (value: string) => getDay(new Date(value), dateFormat || 'DD/MM/YYYY'),
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              
              const optionValues = filters?.formattedDateCreationList ? [allValue, ...filters?.formattedDateCreationList] : [allValue];
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('APPOINTMENT_TABLE_DATE_CREATION', 'Creation Date')}
              />
            },
          }
        }
      },
      {
        name: 'dateCreation',
        label: translatorService.Tranlate('APPOINTMENT_TABLE_HOUR_CREATION','Creation Hour'),
        options: {
          customBodyRender: (value: string) => getHour(moment
            .utc(value)
            .local()
            .format(longDateFormat || 'DD/MM/YYYY HH:mm')),
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const uniqueArray = [...new Set(formatedHours)];
              const optionValues = uniqueArray ? [allValue, ...uniqueArray] : [allValue];
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('APPOINTMENT_TABLE_HOUR_CREATION', 'Creation Hour')}
              />
            },
          }
        }
      },
      {
        name: "vehicle.model.brand",
        label: translatorService.Tranlate('VEHICLES_VEHICLE_MAKE','MARCA'),
        options: {
          sort: false,
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = filters?.makeList ? [allValue, ...filters?.makeList] : [allValue];
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'MARCA')}
              />
            },
          },
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const appointment = appointments.find((item) => item.id === tableMeta.rowData[0]);
            if (R.isNil(appointment)) {
              return;
            }

            return (
              <div>
                {appointment.vehicle?.identifiedMake || appointment.vehicle?.model?.brand || ''}
              </div>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicle.model.name',
        label: translatorService.Tranlate('FRM_CAR_DATA_MODEL','Model'),
        options: {
          sort: false,
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = filters?.modelList ? [allValue, ...filters?.modelList] : [allValue];
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('FRM_CAR_DATA_MODEL', 'Model')}
              />
            },
          },
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const appointment = appointments.find((item) => item.id === tableMeta.rowData[0]);
            if (R.isNil(appointment)) {
              return;
            }

            return (
              <div>
                {appointment.vehicle?.identifiedModel || appointment.vehicle?.model?.name || ''}
              </div>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'vehicle.plateNumber',
        label: translatorService.Tranlate('FRM_NOTICE_PLATE_NUMBER','Numar Inmatriculare'),
        options:{
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = filters?.plateNumberList ? [allValue, ...filters?.plateNumberList] : [allValue];
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                name={translatorService.Tranlate('FRM_NOTICE_PLATE_NUMBER', 'Numar Inmatriculare')}
                optionValues={optionValues}
              />
            },
          }
        }
      },
      {
        name: 'date',
        label: translatorService.Tranlate('APPOINTMENT_TABLE_DATE','Data'),
        options: {
          customBodyRender: (value: string) => value ? getDay(new Date(value), dateFormat || 'DD/MM/YYYY') : '',
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = filters?.formatedDateList ? [allValue, ...filters?.formatedDateList] : [allValue];
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('APPOINTMENT_TABLE_DATE', 'Data')}
              />
            },
          }
        }
      },
      {
        name: 'date',
        label: translatorService.Tranlate('APPOINTMENT_TABLE_TIME','Ora'),
        options: {
          customBodyRender: (value: string) => value ? getHour(new Date(value)) : '',
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = filters?.formatedHourList ? [allValue, ...filters?.formatedHourList] : [allValue];
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('APPOINTMENT_TABLE_TIME', 'Ora')}
              />
            },
          }
        }
      },
      {
        name: 'appointmentStatus',
        label: translatorService.Tranlate('APPOINTMENT_TABLE_STATUS','Status'),
        options: {
          customBodyRender: (value: any) => {
            const indexLang = value.translations.findIndex((trans: any) => trans.language === language);
            return indexLang !== -1 ? value.translations[indexLang].name : value.name;
          },
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              let optionValues:any = [allValue];
              if(filters?.appointmentStatuses){
                optionValues = filters?.appointmentStatuses.map(obj => {
                  const status = obj.translations.find(translation => translation.language === language)
                  return status ? status.name : ''
                });
              }
              
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('APPOINTMENT_TABLE_STATUS', 'Status')}
              />
            },
          }
        }
      },
      {
        name: 'appAppointmentId',
        label: translatorService.Tranlate('APPOINTMENT_TABLE_ID','ID'),
        options:{
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const optionValues = filters?.appAppointmentIds ? [allValue, ...filters?.appAppointmentIds] : [allValue];
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('APPOINTMENT_TABLE_ID', 'ID')}
              />
            },
          }
        }
      },
      {
        name: 'appointmentCategoryType',
        label: translatorService.Tranlate('APPOINTMENT_TABLE_CATEGORY_TYPE','Tip'),
        options: {
          customBodyRender: (value: any) => {
            const indexLang = value.translations.findIndex((trans: any) => trans.language === language);
            return indexLang !== -1 ? value.translations[indexLang].name : value.name;
          },
          filter: true,
          display: 'true',
          filterType: 'custom',
          filterOptions: {
            display: (filterList, onChange, index, column) => {
              let optionValues:any = [allValue];
              if(filters?.appointmentCategoryTypes){
                optionValues = filters?.appointmentCategoryTypes.map(obj => {
                  const appointmentCategoryType = obj.translations.find(translation => translation.language === language)
                  return appointmentCategoryType ? appointmentCategoryType.name : ''
                });
              }
              
              return <DropDownSelect
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
                optionValues={optionValues}
                name={translatorService.Tranlate('APPOINTMENT_TABLE_CATEGORY_TYPE', 'Tip')}
              />
            },
          }
        }
      },
      {
        name: 'actions',
        label: translatorService.Tranlate(
          'APPOINTMENT_TABLE_ACTIONS',
          'Acțiuni'
        ),
        options: {
          filter:false,
          customBodyRender: (value, tableMeta, updateValue) => {
            // If there is no value, return empty div

            return (
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <IconButton
                    aria-label="add"
                    style={{ padding: '2px' }}
                    onClick={async () => {
                      history.push(`/appointment/${appointments![tableMeta.rowIndex].id}`)
                    }}
                  >
                    <Visibility />
                  </IconButton>
                </Box>
                {appointments![tableMeta.rowIndex].appointmentStatus?.code === statusList.PAYMENT_CONFIRMATION?.code || appointments![tableMeta.rowIndex].caseId ?
                  <Box><IconButton
                    aria-label="add"
                    style={{ marginTop: -12, padding: '2px' }}
                    onClick={async () => {
                      if (appointments![tableMeta.rowIndex].caseId) {
                        history.push('/cases/' + appointments![tableMeta.rowIndex].caseId, 'Case xx');
                      }
                      else {
                        setAppointmentId(appointments![tableMeta.rowIndex].id);
                      }
                    }}
                  >
                    {appointments![tableMeta.rowIndex].caseId ? <Folder /> : <CreateNewFolder style={{ color: '#f67346' }} />}
                  </IconButton></Box> : null}
              </Box>
            );
          }
        }
      }
    ],
    [appointments, filters]
  );

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "5px",
          paddingBottom: "5px"
        },
      },
      MUIDataTableBodyCell: {
        root: {
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "50px !important"
        }
      }
    } as any
  });
  

  return (
    <Box
      key={JSON.stringify(appointments)}
      className="agenda-table"
      style={{
        flex: 1
      }}
    > 
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          key={language}
          title={translatorService.Tranlate(
            'APPOINTMENT_TABLE_TITLE',
            'Programări'
          )}
          data={appointments || []}
          options={options}
          columns={COLUMNS}
        />
      </MuiThemeProvider>
      {appointmentId ? <NewCaseAppointment appAppointmentId={appointmentId} close={() => setAppointmentId(null)} /> : null}
    </Box>
  );
};
