import { Action, Reducer } from 'redux';
import * as ActionTypes from '../actions/ActionTypes';
import { MavoVehicle, MavoVehicleAttachment, MavoVehicleOption, VehicleOptionInfo } from '../../interfaces/Vehicle';
import { MavoVehicleAction } from '../actions/MavoVehicle';

export interface MavoVehicleState {
  mavoVehicle: MavoVehicle;
}

const initialState: MavoVehicleState = {
  mavoVehicle: {
    id: 0,
    vin: '',
    make: null,
    makeId: null,
    model: null,
    modelId: null,
    versionItem: null,
    versionId: null,
    color: null,
    colorId: null,
    fuelType: null,
    fuelTypeId: null,
    bodyTypeId: null,
    bodyType: null,
    transmissionTypeId: null,
    transmissionType: null,
    provenance: null,
    provenanceId: null,
    firstRegistrationDate: null,
    fabricationYear: null,
    kilometersNr: null,
    power: null,
    horsePower: null,
    engineManufactureCode: null,
    accessories: null,
    isReserved: false,
    reservations: null,
    cylinderCapacity: null,
    supplier: null,
    location: null,
    htPurchaseRonPrice: null,
    ttcEvaluationEurPrice: null,
    nirNumber: null,
    nirDate: null,
    currentStatus: null,
    vehicleOptions: [] as MavoVehicleOption[],
    mavoVehicleAttachments: [] as MavoVehicleAttachment[],
    isMavoVehicle: true,
    vehicleType: null,
    vehicleTypeId: null,
    vehicleOptionInfo: {
      vehicleId: 0,
      engineCapacity: null,
      emissionsClass: null,
      doorCount: null,
      seatCount: null,
      driveType: null,
      registration: null,
      vehicleCondition: null,
      metallicColor: null,
      upholstery: null,
      upholsteryColor: null,
      previousOwnersCount: null,
      countryOfOrigin: null,
      alarm: false,
      antiTheft: false,
      abs: false,
      esp: false,
      driverFrontAirbag: false,
      passengerFrontAirbag: false,
      frontSideAirbags: false,
      frontCurtainAirbag: false,
      xenonHeadlights: false,
      alloyWheels: false,
      glassRoof: false,
      towHook: false,
      protectionFrame: false,
      steeringWheelControls: false,
      onBoardComputer: false,
      cruiseControl: false,
      lockableDifferential: false,
      particleFilter: false,
      electricMirrors: false,
      frontElectricWindows: false,
      rearElectricWindows: false,
      tintedWindows: false,
      heatedSeats: false,
      rainSensors: false,
      parkingSensors: false,
      powerSteering: false,
      sunroof: false,
      rightHandDrive: false,
      fogLights: false,
      airConditioning: false,
      radio: false,
      cdPlayer: false,
      videoScreen: false,
      gps: false
  } as VehicleOptionInfo
  } as unknown as MavoVehicle
};

export const MavoVehicleReducer: Reducer<MavoVehicleState | undefined> = (
  state: MavoVehicleState | undefined,
  incomingAction: Action
): MavoVehicleState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as MavoVehicleAction;

  switch (action.type) {
    case ActionTypes.MAVO_VEHICLE_SET_VEHICLE:
      if (action.mavoVehicle === null)
        return {
          ...state,
          mavoVehicle: initialState.mavoVehicle
        };

      return {
        ...state,
        mavoVehicle: {
          ...action.mavoVehicle
        }
      };
    case ActionTypes.MAVO_VEHICLE_SET_VEHICLE_STATUS:
      return {
        ...state,
        mavoVehicle: {
          ...state.mavoVehicle!,
          currentStatus: { ...action.status }
        }
      };
  }

  return state;
};
