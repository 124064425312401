import * as React from 'react';
import { Redirect } from 'react-router';
import { AppState, AppActionCreators, ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { IAuthService } from '../../services/Interfaces/IAuthService';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { History } from 'history';
import { ProviderContext, withSnackbar } from 'notistack';

type LogInCallBackProps = AppState & typeof AppActionCreators & History & ProviderContext;

interface LogInCallBackState {
  result: string;
}

class LogInCallBack extends React.PureComponent<LogInCallBackProps, LogInCallBackState> {
  private authService!: IAuthService;
  static contextType = ApplicationContext;
  state = {
    result: ''
  } as LogInCallBackState;

  public async componentDidMount() {
    if (this.props.user === null && this.props.location!.search !== '') {
      this.authService = (this.context as AppContext).authenticationService;
      //await new Oidc.UserManager({ response_mode: "query" }).signinRedirectCallback();
      const result = await this.authService.userManager.signinRedirectCallback();
      this.setState({ result: result.state });
    }
    this.props.SetUser(this.props.enqueueSnackbar);
  }

  public render() {
    if (this.props.user === null) {
      return null;
    }
    if (this.state.result !== '') {
      return <Redirect to={this.state.result} />;
    }

    return <Redirect to="/" />;
  }
}

export default connect(
  (state: ApplicationState) => state.app,
  AppActionCreators
)(withSnackbar(LogInCallBack as any));
