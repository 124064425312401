import React, { useContext } from 'react';
import { matchPath } from 'react-router-dom';

import PropTypes from 'prop-types';

import { List, Typography } from '@material-ui/core';

import useRouter from '../../utils/useRouter'; //'utils/useRouter';
import SidebarMenuListItem from './SidebarMenuListItem';
import { ApplicationContext } from '../../context/Contexts';
import { ThemeActionCreators } from '../../store';
import { RoleClaimsEnum } from './../../helpers/Constants';

const SidebarMenuList = (props) => {
  const { pages, user, propsContainer, ...rest } = props;
  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;

  return (
    <List className="p-0">
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, user, translatorService, propsContainer, ...rest }),
        []
      )}
    </List>
  );
};

SidebarMenuList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};

const createHref = (page, user) =>{
  switch(true){
    case RoleClaimsEnum.AdminService.toString() && page.to == "/admin/hoorganizations/":
      return page.to + user.profile.organization_id
    case page.to == '/v3/sample-page':
      return page.to + `?access_token=${user.access_token}`
    default:
      return page.to
  }
}

const reduceChildRoutes = (props) => {
  const { router, items, page, depth, user, translatorService, propsContainer } = props;

  if (page.rights) {
    if (!page.rights.some(right => user.profile.role.includes(right))) {
      return items;
    }
  } else {
    if (!user.profile.role.includes(page.right)) {
      return items;
    }
  }

  if (page.content) {
    const open = matchPath(router.location.pathname, {
      path: page.to,
      exact: false
    });

    items.push(
      <SidebarMenuListItem
        depth={depth}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        open={Boolean(open)}
        redirectTo={page.redirectTo}
        needToken={page.needToken}
        user={props.user}
        title={translatorService.Tranlate(page.tag, page.label)}
      >
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList depth={depth + 1} pages={page.content} router={router} user={user} 
            propsContainer={propsContainer}/>
        </div>
      </SidebarMenuListItem>
    );
  } else {
    items.push(
      <SidebarMenuListItem
        depth={depth}
        href={RoleClaimsEnum.AdminService.toString() && page.to == "/admin/hoorganizations/" ? page.to + user.profile.organization_id : page.to}
        icon={page.icon}
        redirectTo={page.redirectTo}
        needToken={page.needToken}
        key={page.label}
        label={page.badge}
        user={props.user}
        title={translatorService.Tranlate(page.tag, page.label)}
        onClick={() => {
          propsContainer.props.dispatch(ThemeActionCreators.setSidebarToggleMobile(false));
        }}  
      />
    );
  }

  return items;
};

const SidebarMenu = (props) => {
  const { title, pages, className, user, component: Component, ...rest } = props;

  const router = useRouter();

  return (
    <Component {...rest} className={className}>
      {title && <Typography className="app-sidebar-heading">{title}</Typography>}
      <SidebarMenuList depth={0} pages={pages} user={user} router={router} 
              propsContainer={props}/>
    </Component>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};

SidebarMenu.defaultProps = {
  component: 'nav'
};

export default SidebarMenu;
