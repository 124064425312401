import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box,  } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Card } from 'reactstrap';
import {
  AGENDA_SET_SELECTED_RANGE
} from '../../store/actions/ActionTypes';
import { formatAppointmentForCalendar, getDay, getStatusData } from '../../utils/appointments';
import { useHistory } from 'react-router';
import { AppointmentType } from '../../types/Agenda';
import { ApplicationContext } from '../../context/Contexts';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { AppointmentStatusCodeEnum } from '../../helpers/Constants';

export const BigCalendar = ({ getAppointments, defaultView, setDefaultView, defaultDay, handleSlotSelect }: any) => {
  const history = useHistory()
  const localizer = momentLocalizer(moment);
  const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);
  const selectedRange = useAppSelector((state) => state.agenda?.selectedRange);
  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;
//  const [view, setView] = React.useState<'month' | 'week' | 'day' | 'agenda'>(defaultView || 'month')
  const appointments = useAppSelector((state) => state.agenda?.appointments.filter((appointment) => appointment?.appointmentStatus?.code !== AppointmentStatusCodeEnum.CANCELED.toString()));
  const dispatch = useAppDispatch();
  const dateFormat = useAppSelector((state) => state.app?.dateFormat);

  useEffect(() => {
    if (defaultView === 'agenda') {
      return;
    }

    // if selectedRange is null, set it to current month
    if (!selectedRange) {
      setDefaultView('month');
      return;
    }

    // if selectedRange start_date and end_date are the same, set view to day (can bedifferent hours)
    if (getDay(selectedRange.start_date, dateFormat || 'DD/MM/YYYY') === getDay(selectedRange.end_date, dateFormat || 'DD/MM/YYYY')) {
      setDefaultView('day');
      return;
    }

    // if selectedRange start_date and end_date are not the same but the difference is less than 7 days, set view to week
    if (selectedRange.start_date !== selectedRange.end_date) {
      const diff = moment(selectedRange.end_date).diff(moment(selectedRange.start_date), 'days');

      if (diff < 7) {
        setDefaultView('week');
        return;
      }
    }

    setDefaultView('month');
  }, [selectedRange]);

  const formatedAppointments = useMemo(() => {
    if (!appointments) {
      return [];
    }
    return appointments.map((e) => formatAppointmentForCalendar(e, dateFormat || 'DD/MM/YYYY'));
  }, [appointments]);

  useEffect(() => {
    const handleZoomChange = () => {
      setZoomLevel(window.devicePixelRatio);
    };

    const matchMedia = window.matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
    matchMedia.addEventListener('change', handleZoomChange);

    window.addEventListener('resize', handleZoomChange);
    return () => {
      matchMedia.removeEventListener('change', handleZoomChange);
      window.removeEventListener('resize', handleZoomChange);
    };
  }, []);

  const getEventStyle = () => {
    let fontSize = '14px'; 
    const padding =  '4px';
    if (zoomLevel > 1) {
      fontSize = `${14 - zoomLevel*1}px`; // text mai mic la zoom in
    } else if (zoomLevel < 1) {
      fontSize = '16px'; // text mai mare la zoom out
    }
    return {
      style: {
        fontSize,
        padding
      }
    };
  };

  interface DateRange {
    start: Date;
    end: Date;
  }
  
  interface MonthRange {
    monthName: string;
    startDate: string; // Data de început a lunii
    endDate: string;   // Data de sfârșit a lunii
  }
  
  const getFullMonthRangesInRange = (range: DateRange): MonthRange | null => {
    const startDate = range.start; 
    const endDate = range.end;
    
    // Initialize current to the first day of the month of the start date
    const current = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

    // Iterate through each month in the date range
    while (current <= endDate) {
      // Calculate the start and end of the current month
      const monthStart = new Date(current.getFullYear(), current.getMonth(), 1);
      const monthEnd = new Date(current.getFullYear(), current.getMonth() + 1, 0);
      monthEnd.setHours(23, 59, 59, 999);

      // Check if the whole month is within the date range
      if (monthStart >= startDate && monthEnd <= endDate) {
        return {
          monthName: current.toLocaleString('default', { month: 'long' }), // Month name (e.g., "June")
          startDate: moment(monthStart).format('YYYY-MM-DDTHH:mm:ss'), // Start date of the month
          endDate: moment(monthEnd).format('YYYY-MM-DDTHH:mm:ss') // End date of the month, inclusive
        };
      }
      // Move to the next month
      current.setMonth(current.getMonth() + 1);
    }

    return null; // Return null if no full month is found
  };
  
  return (
    <Card
      className='bigCalendar'
      style={{
        flex: 1,
        height: '80vh'
      }}
    >
      <Calendar
        localizer={localizer}
        events={formatedAppointments}
        defaultView="month"
        dayLayoutAlgorithm={'no-overlap'}
        min={new Date(0, 0, 0, 7, 0, 0)}
        views={{ day: true, week: true, month:true,  }}
        selectable={!!handleSlotSelect}
        view={defaultView}
        onView={(view:any) => {
          setDefaultView(view);
        }}
        components={{
          event: (props) => <CustomEvent {...props} customStyle={{/* adăugați stilurile personalizate aici */ }} />
        }}
        eventPropGetter={getEventStyle}
        messages={{
          date: translatorService.Tranlate('TABLE_DATE', 'Dată'),
          time: translatorService.Tranlate('TABLE_TIME','Timp'),
          event: translatorService.Tranlate('TABLE_REZERVATION', 'Rezervare'),
          allDay: translatorService.Tranlate('TABLE_ALL_DAY', 'Toată ziua'),
          week: translatorService.Tranlate('TABLE_WEEK', 'Săptămână'),
          work_week: translatorService.Tranlate('TABLE_WORK_WEEK', 'Săptămână de lucru'),
          day: translatorService.Tranlate('TABLE_DAY', 'Ziuă'),
          month: translatorService.Tranlate('TABLE_MONTH', 'Lună'),
          previous: <ArrowLeftIcon style={{ margin:-12}}/>,
          next: <ArrowRightIcon style={{margin:-12}}/>,
          yesterday: translatorService.Tranlate('TABLE_YESTERDAY', 'Ieri'),
          tomorrow: translatorService.Tranlate('TABLE_TOMORROW', 'Mâine'),
          today: translatorService.Tranlate('TABLE_TODAY', 'Azi'),
          agenda: translatorService.Tranlate('TABLE_AGENDA', 'Agendă'),

          noEventsInRange: translatorService.Tranlate('TABLE_NO_EVENTS_IN_RANGE', 'Nu există rezervări în această perioadă'),

          showMore: (total: any) => `${translatorService.Tranlate('TABLE_TOTAL','Încă')} ${total}`
        }}
        key={JSON.stringify(selectedRange + defaultDay + zoomLevel)}
        defaultDate={defaultDay || selectedRange?.start_date ||  new Date()}
        value={selectedRange}
        onRangeChange={(range:any) => {
          if (range.start && range.end) {
            console.log('range', range);
            dispatch({
              type: AGENDA_SET_SELECTED_RANGE,
              payload: {
                start_date: range.start,
                end_date: range.end
              }
            });

            const rang: DateRange = {
              start: range.start,
              end: range.end
            };
            const fullMonthRanges = getFullMonthRangesInRange(rang);
            console.log('fullMonthRanges', fullMonthRanges);
            // Call getAppointments with the correctly formatted date range
            getAppointments({
              dateFilterStartDate: fullMonthRanges?.startDate || range.start,
              datefilterEndDate: fullMonthRanges?.endDate || range.end
            });
            return;
          }

          // if array with more than 1 element, it's weekly view
          if (range.length > 1) {
            dispatch({
              type: AGENDA_SET_SELECTED_RANGE,
              payload: {
                start_date: range[0],
                end_date: range[range.length - 1]
              }
            });
            getAppointments({
              dateFilterStartDate: range[0],
              datefilterEndDate: range[range.length - 1]
            })
            return;
          }

          // if array with 1 element, it's daily view
          if (range.length === 1) {
            dispatch({
              type: AGENDA_SET_SELECTED_RANGE,
              payload: {
                start_date: range[0],
                end_date: range[0]
              }
            });
            const date = new Date(range[0]);
            const endDate = date.setDate(date.getDate() + 1)
            getAppointments({
              dateFilterStartDate: range[0],
              datefilterEndDate: moment(endDate).format()
            })
            return;
          }
        }}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={(event: any) => {
          history.push(`/appointment/${event.resource.id}`)
        }}
        onSelectSlot={handleSlotSelect}
        popup
        style={{ width: '100%', heigth: '80vh' }}
      />
    </Card>
  );
};

export const CustomEvent = ({ event, customStyle }: any) => {
  const resource = event.resource as AppointmentType;
  const statusList = useAppSelector((state) => state.agenda?.statusList)
  const language = useAppSelector((state)=>state.app?.language)

  if (!resource) {
    return null;
  }

  const { text, color } = getStatusData(resource?.appointmentStatus?.code || '', statusList, language);

  return (
    <Box
      className='bigCalendarEvent'
      style={{
        backgroundColor:color,
        borderRadius: '4px',
        paddingLeft: '8px',
      }}
    >
        {text}
      <strong> {`${resource.id} ${resource?.vehicle?.plateNumber ? `(${resource?.vehicle?.plateNumber})`:''}`} </strong>
    </Box>
  );
};
