import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import { Box, Button, Card, CardContent, IconButton } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import Referentials from '../../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../../helpers/Constants';
import { WorkflowSectionStepCaseStatus } from '../../../../interfaces/Workflow';
import { IWorkflowService } from '../../../../services/Interfaces/IWorkflowService';
import { IReferential } from '../../../../interfaces/IReferential';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { isNullOrUndefined } from 'util';

interface IWorkflowStepStatusesState {
  workflowSectionStepCaseStatusList: WorkflowSectionStepCaseStatus[];
  isLoading: boolean;

  caseStatusTypeList: IReferential[];
  selectedCaseStatusType: IReferential | null;
}
export interface ExternalWorkflowStepStatusesProps {
  workflowSectionStepId: number;
}

type IWorkflowStepStatusesProps = ExternalWorkflowStepStatusesProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowStepStatuses extends React.PureComponent<
  IWorkflowStepStatusesProps,
  IWorkflowStepStatusesState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowSectionStepCaseStatusList: [],
    isLoading: false,

    caseStatusTypeList: [],
    selectedCaseStatusType: null,
    workflowSectionStepList: [],
    selectedUserRole: null,
    userRoles: []
    // workflowId: 0
  } as IWorkflowStepStatusesState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowSectionStepStatuses();
      }
    );
  }

  loadWorkflowSectionStepStatuses = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseStatus);

    const [workflowSectionStepStatusList, caseStatusTypes] = await Promise.all([
      this.workflowService.GetWorkflowSectionStepCaseStatuses(this.props.workflowSectionStepId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    workflowSectionStepStatusList.forEach((wpItem) => {
      const caseStatus = caseStatusTypes.find((item) => item.id === wpItem.caseStatusId);
      wpItem.caseStatus = isNullOrUndefined(caseStatus) ? null : caseStatus;
    });
    this.setState({
      workflowSectionStepCaseStatusList: workflowSectionStepStatusList,
      caseStatusTypeList: caseStatusTypes.filter((item) => item.isActive === true),
      isLoading: false
    });
  };

  deleteWorkflowSectionStepStatus = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const index = this.state.workflowSectionStepCaseStatusList.findIndex(
        (i) => i.id === tableMeta.rowData[0]
      );
      const deletedStatus = this.state.workflowSectionStepCaseStatusList[index];
      const newStatusesArray = [...this.state.workflowSectionStepCaseStatusList];
      newStatusesArray.splice(index, 1);

      await this.workflowService.RemoveWorkflowSectionStepCaseStatus(deletedStatus.id);

      this.setState({
        workflowSectionStepCaseStatusList: newStatusesArray
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },

      {
        name: 'caseStatus',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PERMISSION_TAB_CASE_STATUS_HEADER',
          'Status Dosar'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },

      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PERMISSION_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteWorkflowSectionStepStatus(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addStatus = async () => {
    try {
      if (
        this.state.workflowSectionStepCaseStatusList.findIndex(
          (item) => item.caseStatusId === this.state.selectedCaseStatusType!.id
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_MSG_WORKFLOW_PERMISSION_EXISTS',
            'Permisiunea exista deja!'
          ),
          { variant: 'error' }
        );
        return;
      }
      await this.workflowService.AddWorkflowSectionStepCaseStatus({
        caseStatusId: this.state.selectedCaseStatusType!.id,
        workflowSectionStepId: this.props.workflowSectionStepId
      } as WorkflowSectionStepCaseStatus);

      this.setState(
        {
          isLoading: true
          //selecteWorkflowSectionStepType : null,
        },
        () => {
          this.loadWorkflowSectionStepStatuses();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleCaseStatusChange = async (newValue: any | null) => {
    this.setState({
      selectedCaseStatusType: newValue
      // workflowSectionStepList: await this.filterSectionSteps(this.state.workflowPermissions, this.state.selecteWorkflowPartnerType, newValue)
    });
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addStatus}>
              <Autocomplete
                id="caseStatus"
                className="m-2"
                options={this.state.caseStatusTypeList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedCaseStatusType}
                onChange={(e: any, newValue: any | null) => this.handleCaseStatusChange(newValue)}
                getOptionLabel={(option: any) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="caseStatus"
                    value={this.state.selectedCaseStatusType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_PERMISSION_TAB_CASE_STATUS_LABEL',
                      'Status'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      search: true,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowSectionStepCaseStatusList}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalWorkflowStepStatusesProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null,
  mergeProps
)(withSnackbar(WorkflowStepStatuses as any));
