import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useContext } from 'react';
import { ApplicationContext } from "../../context/Contexts";

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
};

interface DropDownSelectProps { 
  filterList: any, 
  onChange: any, 
  index: number, 
  column: any, 
  optionValues: any,
  name:string
}



export const DropDownSelect = ({ filterList, onChange, index, column, optionValues, name }: DropDownSelectProps) => {
  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;
  const allValue = translatorService.Tranlate('APPOINTMENT_TABLE_ALL_VALUE', 'Toate');

  return <FormControl variant='standard'>
    <InputLabel id="demo-multiple-name-label">{name}</InputLabel>
    <Select
      labelId="demo-multiple-name-label"
      id="demo-multiple-name"
      multiple
      value={filterList[index]?.length > 0 ? filterList[index] : []}
      onChange={(e) => {
        let futureFilterList: any = e.target.value;
        if (futureFilterList?.length > 1 && futureFilterList[0] === allValue) {
          futureFilterList?.shift()
          filterList[index] = futureFilterList;
        }

        if (futureFilterList?.length > 1 && futureFilterList[futureFilterList?.length - 1] === allValue) {
          futureFilterList = [allValue];
        }
        onChange(futureFilterList, index, column);
      }}
      MenuProps={MenuProps}
    >
      {optionValues?.map((name: string) => (
        <MenuItem
          key={name}
          value={name}
        >
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
}
