import { AcquisitionFiltersState } from '../../../interfaces/Dashboard/AcquisitionFiltersState';

export const SET_VALUE_BY_NAME = '[FILTERS] SET_VALUE_BY_NAME';
export const SET_CLIENT_TYPE_ID = '[FILTERS] SET_CLIENT_TYPE_ID';
export const SET_OFFER_TYPE_ID = '[FILTERS] SET_OFFER_TYPE_ID';
export const SET_MAKE_ID = '[FILTERS] SET_MAKE_ID';
export const RESET_FILTERS = '[FILTERS] RESET_FILTERS';

export const acquisitionDashboardReducer = (state: AcquisitionFiltersState, action: any) => {
  switch (action.type) {
    case SET_CLIENT_TYPE_ID:
      return {
        ...state,
        clientTypeId: action.clientTypeId !== state.clientTypeId ? action.clientTypeId : undefined
      };
    case SET_OFFER_TYPE_ID:
      return {
        ...state,
        offerTypeId: action.offerTypeId !== state.offerTypeId ? action.offerTypeId : undefined
      };
    case SET_MAKE_ID:
      return { ...state, makeId: action.makeId !== state.makeId ? action.makeId : undefined };
    case SET_VALUE_BY_NAME:
      return { ...state, [action.name]: action.value };
    case RESET_FILTERS:
      return {
        ...state,
        clientTypeId: undefined,
        offerTypeId: undefined,
        makeId: undefined
      };
    default:
      return state;
  }
};
