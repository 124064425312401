import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import { connect } from 'react-redux';

import projectLogo from '../../assets/images/carfix_newgtlogo.png';

import { ThemeActionCreators } from '../../store';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

const SidebarHeader = (props) => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
    sidebarHover
  } = props;

  return (
    <Fragment>
      <div
        className={clsx('app-sidebar-header', {
          'app-sidebar-header-close': sidebarToggle && !sidebarHover
        })}
      >
        <Box className="header-logo-wrapper" title="ClaimBox">
          <Link to="/DashboardDefault" className="header-logo-wrapper-link">
            <IconButton color="primary" size="medium" className="header-logo">
              <img className="app-header-logo-img" alt="ClaimBox" src={projectLogo} />
            </IconButton>
          </Link>
        </Box>
        <Box
          className={clsx('app-sidebar-header-btn', {
            'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover
          })}
        >
          <IconButton color="inherit" onClick={toggleSidebar} size="medium">
            {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
          </IconButton>
        </Box>
        <Box className="app-sidebar-header-btn-mobile">
          <IconButton color="inherit" onClick={toggleSidebarMobile} size="medium">
            {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
          </IconButton>
        </Box>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  sidebarToggle: state.themeOptions.sidebarToggle,
  sidebarHover: state.themeOptions.sidebarHover,
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(ThemeActionCreators.setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
