import { Icons } from 'material-table';
import React, { forwardRef } from 'react';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';

import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';

export const MaterialTableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

export const getLocalization = (translatorService: ITranslatorService) => {
  return {
    header: { actions: '' },
    toolbar: {
      searchPlaceholder: translatorService.Tranlate('SEARCH', 'Cauta'),
      searchTooltip: translatorService.Tranlate('SEARCH', 'Cauta'),
      nRowsSelected: `{0} ${translatorService.Tranlate('ROWS_SELECTED', 'randuri selectate')}`
    },
    body: {
      editTooltip: translatorService.Tranlate('EDIT', 'Editeaza'),
      deleteTooltip: translatorService.Tranlate('DELETE', 'Sterge'),
      addTooltip: translatorService.Tranlate('Add', 'Adauga'),
      emptyDataSourceMessage: translatorService.Tranlate(
        'NO_RECORDS_TO_DISPLAY',
        'Nu exista date de afisat'
      ),
      editRow: {
        cancelTooltip: translatorService.Tranlate('CANCEL', 'Anuleaza'),
        saveTooltip: translatorService.Tranlate('SAVE', 'Salveaza'),
        deleteText: translatorService.Tranlate(
          'DELETE_TEXT',
          'Sigur doriti sa stergeti aceasta inregistrare?'
        )
      }
    },
    pagination: {
      labelRowsSelect: translatorService.Tranlate('ROWS', 'inregistrari'),
      firstTooltip: translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
      previousTooltip: translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
      nextTooltip: translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
      lastTooltip: translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
      labelDisplayedRows: '{from}-{to} ' + translatorService.Tranlate('OF', 'din') + ' {count}'
    }
  };
};

export const getAdvertLocalization = (translatorService: ITranslatorService) => {
  return {
    header: { actions: '' },
    toolbar: {
      searchPlaceholder: translatorService.Tranlate('SEARCH', 'Cauta'),
      searchTooltip: translatorService.Tranlate('SEARCH', 'Cauta'),
      nRowsSelected: `{0} ${translatorService.Tranlate('ADVERT_ROWS_SELECTED', 'dotari selectate')}`
    },
    body: {
      editTooltip: translatorService.Tranlate('EDIT', 'Editeaza'),
      deleteTooltip: translatorService.Tranlate('DELETE', 'Sterge'),
      addTooltip: translatorService.Tranlate('Add', 'Adauga'),
      emptyDataSourceMessage: translatorService.Tranlate(
        'NO_RECORDS_TO_DISPLAY',
        'Nu exista date de afisat'
      ),
      editRow: {
        cancelTooltip: translatorService.Tranlate('CANCEL', 'Anuleaza'),
        saveTooltip: translatorService.Tranlate('SAVE', 'Salveaza'),
        deleteText: translatorService.Tranlate(
          'DELETE_TEXT',
          'Sigur doriti sa stergeti aceasta inregistrare?'
        )
      }
    },
    pagination: {
      labelRowsSelect: translatorService.Tranlate('ROWS', 'inregistrari'),
      firstTooltip: translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
      previousTooltip: translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
      nextTooltip: translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
      lastTooltip: translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
      labelDisplayedRows: '{from}-{to} ' + translatorService.Tranlate('OF', 'din') + ' {count}'
    }
  };
};
