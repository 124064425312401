import { IOrganizationService } from '../services/Interfaces/IOrganizationService';
import { OrganizationType } from '../interfaces/Organization';
import { ICaseSettingsService } from './Interfaces/ICaseSettingsService';
import { CaseSettings } from '../interfaces/CaseSettings';
import { IWorkflowService } from './Interfaces/IWorkflowService';
import { Workflow } from '../interfaces/Workflow';
import { OrganizationService } from './OrganizationService';
import { isNullOrUndefined } from 'util';
import Referentials from '../helpers/Referentials.json';
import { ReferentialCode } from '../helpers/Constants';
import { IReferentialService } from '../services/Interfaces/IReferentialService';
import { ReferentialService } from './ReferentialService';

export class CaseSettingsService implements ICaseSettingsService {
  private workflowService: IWorkflowService;
  private organizationService: IOrganizationService;
  private appReferentialService: IReferentialService;
  private baseController = '/case';

  constructor(
    workflowService: IWorkflowService,
    organizationService: OrganizationService,
    appReferentialService: ReferentialService
  ) {
    this.workflowService = workflowService;
    this.organizationService = organizationService;
    this.appReferentialService = appReferentialService;
  }

  public async GetCaseSettings(workflowId: number): Promise<CaseSettings | null> {
    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CompanyParameter
    );
    const orgTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrganizationType
    );
    const [workflow, parameterList, orgTypes] = await Promise.all([
      this.workflowService.GetWorkflowSettings(workflowId),
      this.appReferentialService.Get(ref!.baseUrl),
      this.appReferentialService.Get(orgTypeRef!.baseUrl)
    ]);

    if (isNullOrUndefined(workflow as Workflow)) {
      return null;
    }
    const orgIds = workflow.workflowPartners.map((item) => item.partnerId);
    orgIds.push(workflow.organizationOwnerId);

    const [partners] = await Promise.all([
      this.organizationService.GetUserOrganizationsByIds(Array.from(new Set(orgIds)))
    ]);

    workflow.workflowPartners.forEach((wpItem) => {
      const partner = partners.find((item) => item.id === wpItem.partnerId);
      wpItem.partner = isNullOrUndefined(partner) ? null : partner;
      const orgType = orgTypes.find((item) => item.id === wpItem.partner!.organizationTypeId);
      wpItem.partner!.organizationType = (isNullOrUndefined(orgType)
        ? null
        : orgType) as any as OrganizationType;
    });

    workflow.workflowParameters.forEach((item) => {
      const param = parameterList.find((par) => par.id === item.parameterId);
      item.parameter = isNullOrUndefined(param) ? null : param;
    });

    const orgOwner = partners.find((item) => item.id === workflow.organizationOwnerId);
    workflow.organizationOwner = isNullOrUndefined(orgOwner) ? null : orgOwner;

    const caseSettings = {
      workflow: { ...workflow }
    } as CaseSettings;

    return caseSettings;
  }
}
