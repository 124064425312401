import { API } from '../helpers/API';
import { IGtService } from './Interfaces/IGtService';
import { VinDecodeResponse } from '../interfaces/Case';

export class GtService implements IGtService {
  private baseController = '/case';

  public async DecodeVin(vin: string): Promise<VinDecodeResponse> {
    return (await API.GetAsync<VinDecodeResponse>(`${this.baseController}/decodevin/${vin}`)).data;
  }
}
