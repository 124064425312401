import { Moment } from 'moment';
import { FiltersSelectItem } from './FiltersSelectItem';

export interface SalesFiltersState {
  startDate: Moment;
  endDate: Moment;
  selectedOrganisations: FiltersSelectItem[];
  clientTypeId?: number;
  locationId?: number;
  salePersonId?: string;
}

export enum SalesMainFilterStateKeys {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  SELECTED_ORGANIZATIONS = 'selectedOrganisations'
}

export enum SalesFilterStateKeys {
  LOCATION_ID = 'locationId',
  CLIENT_TYPE_ID = 'clientTypeId',
  SALE_PERSON_ID = 'salePersonId'
}
