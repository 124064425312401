import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Grid, Button, Box } from '@material-ui/core';
import OrganizationDetailsTab from '../organizations/OrganizationDetailsTab';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import OrganizationUsers from '../organizations/OrganizationUsers';
import OrganizationInterfaces from '../organizations/OrganizationInterfaces';
import OrganizationParameters from '../organizations/OrganizationParameters';
import OrganizationNewCaseType from './organizationCase/OrganizationNewCaseType';
import OrganizationTariffs from '../tariffs/Tariffs';
import Workflows from '../workflows/Workflows';
import { RouteComponentProps } from 'react-router';
import { PageTitle } from '../../../layout-components';
import OrganizationFilters from '../../admin/organizations/OrganizationFilters';
import { Organization } from '../../../interfaces/Organization';
import MavoOrganizationDetailsTab from './MavoOrganizationDetailsTab';

interface IOrganizationAdminStateState {
  selectedTab: number;
  organization: Organization | null;
}

type IOrganizationAdminProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class MavoOrganizationAdmin extends React.PureComponent<
  IOrganizationAdminProps,
  IOrganizationAdminStateState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;
  state = {
    selectedTab: 0,
    organization: null
  } as IOrganizationAdminStateState;

  componentDidMount = async () => {
    const orgId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(orgId)) {
      return;
    }
    const organizations = await this.organizationService.GetUserOrganizationsByIds([orgId]);

    this.setState({
      organization: organizations.length === 1 ? organizations[0] : null
    });
  };

  onButtonClick = (newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <PageTitle
          titleHeading={this.state.organization === null ? '' : this.state.organization!.name}
          backButtonName={this.translatorService.Tranlate(
            'EDIT_ORGANIZATION_BACK_TO_HO_BTN',
            'Organizatii'
          )}
          backButtonHref="/admin/mavo/hoorganizations"
          {...this.props}
        ></PageTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2}>
            <Box height={500}>
              <Box
                height={1 / 7}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 0 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={
                    this.state.selectedTab === 0 ? 'text-white h-100' : 'text-dark h-100 h-100'
                  }
                  onClick={() => this.onButtonClick(0)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'ORGANIZATIONS_TAB_GENERAL_DATA',
                      'Date Generale'
                    )}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 7}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 1 ? 'secondary.main' : ''}
              >
                 <Button
                  fullWidth
                  className={this.state.selectedTab === 1 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(1)}
                >
                  <b>{this.translatorService.Tranlate('ORGANIZATIONS_TAB_USERS', 'Utilizatori')}</b>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={10}>
            {this.state.selectedTab === 0 ? (
              <MavoOrganizationDetailsTab {...this.props}></MavoOrganizationDetailsTab>
            ) : null}
            {this.state.selectedTab === 1 ? (
              <OrganizationUsers {...this.props}></OrganizationUsers>
            ) : null}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(MavoOrganizationAdmin as any));
