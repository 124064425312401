import { IDashboardService } from './Interfaces/IDashboardService';
import {
  IDashboardFilters,
  IDashboardChart,
  IDashboardCostsStructure
} from '../interfaces/Dashboard';
import { Case } from '../interfaces/Case';

export class DashboardService implements IDashboardService {
  private baseController = '/dashboard';

  async GetDashboardOriginalCalc(Filters: IDashboardFilters): Promise<number> {
    return 3000;
  }

  async GetDashboardAlternativeCalc(Filters: IDashboardFilters): Promise<number> {
    return 4000;
  }

  async GetDashboardVinIdentifiersNumber(Filters: IDashboardFilters): Promise<number> {
    return 5000;
  }

  async GetDashboardGTEstimateNumber(Filters: IDashboardFilters): Promise<number> {
    return 6000;
  }

  async GetDashboardPiecesOrdersNumber(Filters: IDashboardFilters): Promise<number> {
    return 7000;
  }

  async GetDashboardTotalCases(Filters: IDashboardFilters): Promise<number> {
    return 8000;
  }

  async GetDashboardCostsStructure(
    Filters: IDashboardFilters
  ): Promise<IDashboardCostsStructure[]> {
    const dashboardsCostsStructure = [
      { total: 200, closedCases: 30, openedCases: 40 }
    ] as IDashboardCostsStructure[];
    return dashboardsCostsStructure;
  }

  async GetDashboardCostOptimization(
    Filters: IDashboardFilters
  ): Promise<IDashboardCostsStructure[]> {
    const dashboardsCostOptimization = [
      { total: 400, closedCases: 50, openedCases: 60 }
    ] as IDashboardCostsStructure[];
    return dashboardsCostOptimization;
  }

  async GetDashboardCasesAnalsysChartCaseOwner(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test1', value: 20 },
      { displayName: 'Test2', value: 30 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardCasesAnalsysChartCaseType(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test3', value: 40 },
      { displayName: 'Test4', value: 50 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardCasesAnalsysChartCaseStatus(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test5', value: 60 },
      { displayName: 'Test6', value: 70 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardCostStructureChartCaseOwner(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test7', value: 44 },
      { displayName: 'Test8', value: 88 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardCostStructureChartCostType(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test9', value: 75 },
      { displayName: 'Test10', value: 77 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardCostStructureChartVehicleBrand(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test11', value: 33 },
      { displayName: 'Test12', value: 22 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardCostOptimizationChartCaseOwner(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test13', value: 454 },
      { displayName: 'Test14', value: 848 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardCostOptimizationChartCostType(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test15', value: 64 },
      { displayName: 'Test16', value: 34 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardCostOptimizationChartVehicleBrand(
    Filters: IDashboardFilters
  ): Promise<IDashboardChart[]> {
    const dashboardsCharts = [
      { displayName: 'Test17', value: 77 },
      { displayName: 'Test18', value: 90 }
    ] as IDashboardChart[];
    return dashboardsCharts;
  }

  async GetDashboardNewCases(Filters: IDashboardFilters): Promise<Case[]> {
    return [];
  }

  async GetDashboardUpdatedCases(Filters: IDashboardFilters): Promise<Case[]> {
    return [];
  }

  async GetDashboardWaitingCases(Filters: IDashboardFilters): Promise<Case[]> {
    return [];
  }

  async GetDashboardFilters(startDate: Date, endDate: Date): Promise<IDashboardFilters> {
    const dashboardFilters = {
      id: 1,
      dateFrom: startDate,
      dateTo: endDate,
      organizationIds: [34, 35, 36, 37, 38],
      casePartnerIds: [1, 34],
      caseStatusIds: [3],
      caseTypeIds: [1, 2]
    } as IDashboardFilters;
    return dashboardFilters;
  }
}
