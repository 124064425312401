import { MavoVehicleAuction } from '../../interfaces/Vehicle';
import { AppThunkAction } from '.././';
import * as ActionTypes from './ActionTypes';

interface SetVehicleAuction {
  type: typeof ActionTypes.VEHICLE_SET_VEHICLE_AUCTION;
  mavoVehicleAuction: MavoVehicleAuction | null;
}

export type VehicleAuctionAction = SetVehicleAuction;

export const VehicleAuctionActionCreators = {
  setMavoVehicleAuctionObject:
    (mavoVehicleAuction: MavoVehicleAuction | null): AppThunkAction<VehicleAuctionAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.VEHICLE_SET_VEHICLE_AUCTION, mavoVehicleAuction: mavoVehicleAuction });
    },
};
