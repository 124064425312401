import { SalesFiltersState } from '../../../interfaces/Dashboard/SalesFiltersState';

export const SET_VAL_BY_NAME = '[FILTERS] SET_VAL_BY_NAME';
export const SET_PERSON_TYPE_ID = '[FILTERS] SET_PERSON_TYPE_ID';
export const SET_SALE_PERSON_ID = '[FILTERS] SET_SALE_PERSON_ID';
export const RESET_SALES_FILTERS = '[FILTERS] RESET_SALES_FILTERS';

export const salesDashboardReducer = (state: SalesFiltersState, action: any) => {
  switch (action.type) {
    case SET_PERSON_TYPE_ID:
      return {
        ...state,
        clientTypeId: action.clientTypeId !== state.clientTypeId ? action.clientTypeId : undefined
      };
    case SET_SALE_PERSON_ID:
      return {
        ...state,
        salePersonId: action.salePersonId !== state.salePersonId ? action.salePersonId : undefined
      };
    case SET_VAL_BY_NAME:
      return { ...state, [action.name]: action.value };
    case RESET_SALES_FILTERS:
      return {
        ...state,
        clientTypeId: undefined,
        locationId: undefined,
        salePersonId: undefined,
        advertiserId: undefined
      };
    default:
      return state;
  }
};
