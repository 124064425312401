import React, { Fragment, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Avatar, Box, Badge, Menu, Button, List, ListItem, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ApplicationContext } from '../../context/Contexts';
import { connect } from 'react-redux';
import { AppActionCreators } from '../../store';
import { withRouter } from 'react-router';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

const HeaderUserbox = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const context = useContext(ApplicationContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentLanguage = () => {
    return props.appUser.organization.gtOrganization.languages.find(
      (item) => item.code === translatorService.GetLanguage()
    );
  };

  const activeLanguages = () => {
    let activeLanguages = props.appUser.organization.gtOrganization.languages.filter(
      (language) => language.isActive === true
    );

    activeLanguages.forEach((language) => {
      language.displayName =
        language.displayName.charAt(0).toUpperCase() + language.displayName.slice(1);
    });
    return activeLanguages;
  };

  let buttonLanguages = [];

  const languages = activeLanguages();
  for (let i = 0; i < languages.length; i++) {
    buttonLanguages.push(
      <Grid key={i} item sm={6} className="p-2">
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            translatorService.SetLanguage(languages[i].code);
            props.setLanguage();
            handleCloseMenu();
            window.location.reload();
          }}
          className="w-100 d-block text-center"
        >
          { languages[i].displayName}
        </Button>
      </Grid>
    );
  }

  const logout = async () => {
    const authService = context.authenticationService;
    handleClose();
    await authService.logoutAsync();
  };

  const translatorService = context.translatorService;
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center"
      >
        <Box>
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            variant="dot"
          >
            <Avatar sizes="44" alt="Dustin Watson" />
            {/* src={avatar4}  */}
          </StyledBadge>
        </Box>

        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2"
      >
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                variant="dot"
              >
                <Avatar sizes="44" alt="Dustin Watson" />
                {/* src={avatar4}  */}
              </StyledBadge>
            </Box>
            <div className="pl-3 ">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {props.appUser ? props.appUser.lastName + ' ' + props.appUser.firstName : null}
              </div>
              <div className="text-center pt-2 line-height-2">
                {props.appUser ? props.appUser.email : null}
              </div>
              <div className="text-center text-black-50">
                {props.appUser ? props.appUser.jobFunction : null}
              </div>
            </div>
            <Divider className="w-100  mt-2" />
            <div className="pl-3 ">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {props.appUser ? props.appUser.organization.hoName : null}
              </div>
              <div className="text-center pt-2 line-height-1">
                {props.appUser ? props.appUser.organization.name : null}
              </div>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem className="d-block rounded-bottom px-6 pt-2 pb-0 text-center">
              <Button
                color="secondary"
                className=""
                size="small"
                variant="contained"
                onClick={handleClickMenu}
              >
                {currentLanguage()?.displayName}

              </Button>
              <Menu
                style={{ maxWidth: '700px' }}
                anchorEl={anchorElMenu}
                keepMounted
                open={Boolean(anchorElMenu)}
                onClose={handleCloseMenu}
                classes={{ list: 'p-0' }}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <div className="overflow-hidden dropdown-menu-lg p-0">
                  <div className=" grid-menu-2col">
                    <Grid container spacing={0}>
                      {buttonLanguages}
                    </Grid>
                  </div>
                </div>
              </Menu>
              <Button
                color="secondary"
                onClick={(e) => {
                  props.history.push('/profile');
                  handleClose();
                }}
              >
                {translatorService.Tranlate('PROFILE', 'Profil')}
              </Button>
            </ListItem>

            <Divider className="w-100 mt-2" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Button color="primary" onClick={logout} variant="contained">
                {translatorService.Tranlate('LOG_OUT', 'Deconecteaza-te')}
              </Button>
            </ListItem>
          </List>
        </div>
      </Menu>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  // sidebarToggle: state.themeOptions.sidebarToggle,
  // sidebarHover: state.themeOptions.sidebarHover,
  appUser: state.app.appUser,
  appState: state.app
});
const mapDispatchToProps = (dispatch) => ({
  setLanguage: () => dispatch(AppActionCreators.SetLanguage())
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderUserbox));
