import { Moment } from 'moment';
import { FiltersSelectItem } from './FiltersSelectItem';

export interface AcquisitionFiltersState {
  startDate: Moment;
  endDate: Moment;
  selectedOrganisations: FiltersSelectItem[];
  includeVat: boolean;
  locationId?: number;
  offerTypeId?: number;
  clientTypeId?: number;
  makeId?: number;
}

export enum AcquisitionMainFilterStateKeys {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  SELECTED_ORGANIZATIONS = 'selectedOrganisations',
  INCLUDE_VAT = 'includeVat'
}

export enum AcquisitionFilterStateKeys {
  LOCATION_ID = 'locationId',
  CLIENT_TYPE_ID = 'clientTypeId',
  OFFER_TYPE_ID = 'offerTypeId',
  MAKE_ID = 'makeId'
}
