import olxLogo from  '../assets/images/OLX.png';
import autovitLogo from '../assets/images/Autovit.jpg';
import renaultLogo from '../assets/images/Renault.png';


export const defaultChartColors = ["26a0fc", "26e7a6", "febc3b", "ff6178", "8b75d7"];
export const defaultChartPosition = { x: 0.3, y: 0.3, w: '90%', h: '90%' }
export const titleOptions = { fontSize: 16, color: "000000", bold: true, align: "center" };
export const titlePosition = { x: 0.5, y: 0.4, w: "90%" };

export const tabNameOptions = { fontSize: 24, color: "000000", bold: true,  align: "center" };
export const tabNamePosition = { x: 0.5, y: "45%", w: "90%" };


export const rectangletextOpts = {
  y: 1.5,
  w: 2,
  h: 1,
  align: "center",
  fill: { color: 'e7e6e6' },
  wrap: true,
}

export const cellOpt = { color: '000000', fontSize: 14, bold: true }

export const sallerTableOptions = { x: 2.5, y: 1, w: 5.0, valign: "middle", border: { pt: "1" }, autoPage: true }

export const tableOptions = { x: 0.5, y: 1.0, w: 9.0, valign: "middle", border: { pt: "1" }, fill: { color: "F1F1F1" }, autoPage: true }


export const logos = [
  { image: autovitLogo, dimension: { w: 2, h: 0.5, x: 1 } },
  { image: olxLogo, dimension: { w: 0.5, h: 0.5, x: 4.7 } },
  { image: renaultLogo, dimension: { w: 1, h: 0.5, x: 7.5 }}
]

export const recentAcquisitionLogos = [
  { dimension: { w: 2, h: 1.5, x: 0.5 } },
  { dimension: { w: 2, h: 1.5, x: 4.2 } },
  { dimension: { w: 2, h: 1.5, x: 7.9 }}
]


export const recentAcquisitionOptions = {
  w: 2.5,
  h: 0.5,
  align: "center",
  wrap: true,
  fontSize: 10
}


export const salesChannelOptions = {
  w: 2,
  h: 1,
  align: "center",
  wrap: true,
}

export const rectangleOptions ={
  align: "center",
  fill: { color: 'e7e6e6' },
  wrap: true
}

export const salesOptions ={
  align: "center",
  wrap: true,
  fontSize: 10
}


export const horizontalChartOptions = {
  ...defaultChartPosition,
  w: "100%",
  x: 0,
  valAxisMinVal: 0,
  catAxisLabelFontSize: 6,
  valAxisHidden: false,
  catAxisLineShow: false,
  showValue: true,
  dataLabelColor: '000000' ,
  dataLabelFontSize: 6,
  barGapWidthPct: 15,
  chartColors: defaultChartColors,
}

export const verticalCharOptions = {
  ...defaultChartPosition,
  barDir: "col",
  barGapWidthPct: 25,
  chartColors: defaultChartColors,
  chartColorsOpacity: 50,
  barGrouping: "stacked",
  showValue: true,
  title: name,
  showTitle: true,
  dataLabelColor: "FFFFFF",
}

export const verticalChar2Options = {
  ...defaultChartPosition,
  chartArea: { fill: { color: "F1F1F1" } },
  plotArea: { fill: { color: "404040" } },
  barDir: "col",
  barGapWidthPct: 25,
  chartColors: ["26a0fc", "26e7a6"],
  catAxisLabelFontFace: "Calibri",
  catAxisLabelFontSize: 11,
  dataLabelColor: "FFFFFF",
  dataLabelFontFace: "Arial",
  dataLabelFontSize: 10,
  dataLabelPosition: "inEnd",
  showValue: true,
  showLegend: false,
  legendPos: "b",
  showTitle: true,
}

export const circleChartOptions = {
  ...defaultChartPosition,
  chartArea: { fill: { color: "F1F1F1" } },
  dataBorder: { pt: "1", color: "F1F1F1" },
  showLegend: true,
  chartColors: defaultChartColors,
  legendPos: "b",
  showTitle: true,
  title: name,
  firstSliceAng: 90,
  dataLabelSize: 8,
  dataLabelColor: "FFFFFF",
  dataLabelFormatCode: '##.#%'
}

export const scatterChartOptions = {
  ...defaultChartPosition,
  plotArea: { fill: { color: "F2F9FC" } },
  chartColors: ['26a0fc', "26e7a6"],
  chartArea: { fill: { color: "F1F1F1" } },
  lineSize: 3,
  lineSmooth: true,
  showLegend: true,
  catGridLine: 'solid',
  legendPos: "b",
  catAxisLabelPos: "low",
}