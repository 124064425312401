import React from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  Card,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { MavoVehicleStatusCodeEnum, ReferentialCode, mavoVehicleStatuses } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import {  MavoVehicle } from '../../interfaces/Vehicle';
import TabPanel from '../_shared/Tabs';
import * as R from 'ramda';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import { ScaleLoader } from 'react-spinners';
import MavoStockDataEditContainer from './MavoStockDataEditContainer';
import NavigationIcon from '@material-ui/icons/Navigation';

interface IMavoVehicleStockDetailsFormState {
  isLoading: boolean;
  executing: boolean;
  isUserHidden: boolean;
  vehicle: MavoVehicle | null;
  selectedTab: number;
  statuses: IReferential[];
  selectedDisplayedStatus: string;
  hasRights: boolean;
}

type IMavoVehicleStockDetailsFormProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
  mavoVehicleState: MavoVehicleState;
} & typeof CaseSettingsActionCreators &
  typeof VehicleActionCreators &
  typeof MavoVehicleActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class MavoVehicleStockDetailsForm extends React.PureComponent<IMavoVehicleStockDetailsFormProps, IMavoVehicleStockDetailsFormState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    isLoading: true,
    executing: false,
    isUserHidden: false,
    selectedTab: 0,
    statuses: [] as IReferential[],
    selectedDisplayedStatus: '',
    hasRights: false
  } as IMavoVehicleStockDetailsFormState;

  public componentDidMount() {    
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadMavoVehicle();
      }
    );
  }

  loadMavoVehicle = async () => {
    const statusesTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.VehicleStatus
    );
    const statuses = (await this.appReferentialService.Get(statusesTypeRef!.baseUrl)).filter(x => mavoVehicleStatuses.includes(x.id));

    await this.props.setMavoVehicle(this.props.match.params.id as any);

    const vehicle = this.props.mavoVehicleState.mavoVehicle;

    this.setState({
      isLoading: false,
      vehicle: vehicle,
      statuses: statuses,
      selectedDisplayedStatus: !R.isNil(vehicle?.currentStatus?.status)
        ? vehicle?.currentStatus?.status?.displayName || '' : '',
      hasRights: this.props.mavoVehicleState.mavoVehicle!.currentStatus!.status!.code === MavoVehicleStatusCodeEnum.INDISPONIBIL ? true : false
    });
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState({
      selectedTab: newValue
    });
  };
  
  updateHasRightsValue = (value: boolean) => {
    this.setState({
      hasRights: value
    });
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <div>
        <React.Fragment>
          <Card className="mb-3 p-3">
            <div className="d-flex align-items-center">
              <div style={{ flexBasis: '50%' }}>
              </div>
              <div style={{ flexBasis: '50%' }}>
                <Button
                  className="ml-2 float-right bg-theme-primary"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.props.setMavoVehicleObject(null);
                    this.props.history.push('/mavo-vehicles');
                  }}
                >
                  <NavigationIcon />
                  {this.translatorService.Tranlate(
                    'MENU_MAVO_VEHICLES_RECORD',
                    'Evidenta vehicule'
                  )}
                </Button>
              </div>
            </div>
          </Card>
        </React.Fragment>
        <React.Fragment>
          <Card>
            <AppBar position="static" elevation={0} color="secondary">
              <Tabs
                TabIndicatorProps={{
                  style: {
                    height: '4px'
                  }
                }}
                value={this.state.selectedTab}
                onChange={this.onTabChange}
                variant="fullWidth"
                centered={true}
                indicatorColor="primary"
                aria-label="simple tabs example"
                >
                <Tab
                  label={
                    this.state.hasRights
                    ? this.translatorService.Tranlate('MAVO_VEHICLE_STOCK_DATA_EDIT_TAB', 'Editare date stoc')
                    : this.translatorService.Tranlate('MAVO_VEHICLE_STOCK_DATA_VIEW_TAB', 'Vizualizare date stoc')
                  }
                />
              </Tabs>
            </AppBar>
            <div>
              <TabPanel value={this.state.selectedTab} index={0}>
                  <div className="m-3">
                    <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                      <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
                    </div>
                    {!this.state.isLoading ? (
                      <MavoStockDataEditContainer
                        {...this.props}
                        statuses={this.state.statuses} 
                        hasRights={this.state.hasRights}
                        updateHasRightsValue={this.updateHasRightsValue}

                      />
                    ) : null}
                  </div>
              </TabPanel>
            </div>
          </Card>
        </React.Fragment>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicleState: state.mavoVehicle
  }),
{ ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators }
)(withSnackbar(MavoVehicleStockDetailsForm as any));
