import { ITranslatorService } from '../services/Interfaces/ITranslatorService';

export class MUITranslations {
  public static GetTranslations(translatorService: ITranslatorService): any {
    return {
      body: {
        noMatch: translatorService.Tranlate('NO_RECORDS_TO_DISPLAY', 'Nu exista date de afisat'),
        toolTip: translatorService.Tranlate('SORT', 'Sorteaza'),
        columnHeaderTooltip: (column: any) =>
          `${translatorService.Tranlate('SORT_BY', 'Sorteaza dupa')} ${column.label}`
      },
      pagination: {
        next: translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        previous: translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        rowsPerPage: translatorService.Tranlate('ROWS_PER_PAGE', 'Randuri pe pagina'),
        displayRows: translatorService.Tranlate('OF', 'din')
      },
      toolbar: {
        search: translatorService.Tranlate('SEARCH', 'Cauta'),
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: translatorService.Tranlate('VIEW_COLUMNS', 'Vizualizare coloane'),
        filterTable: translatorService.Tranlate('FILTER_TABLE', 'Filtreaza')
      },
      filter: {
        all: translatorService.Tranlate('FILTER_ALL', 'Toate'),
        title: translatorService.Tranlate('FILTER_FILTERS', 'Filtre'),
        reset: translatorService.Tranlate('FILTER_RESET', 'Reseteaza')
      },
      viewColumns: {
        title: translatorService.Tranlate('SHOW_COLUMNS', 'Vizualizare coloane'),
        titleAria: translatorService.Tranlate('SHOW_COLUMNS_TITLE', 'Vizualizeaza/ascunde coloane')
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows'
      }
    };
  }

  public static GetDashboardTranslations(translatorService:ITranslatorService):any{
    return {
      technicalCondition:[
        { title: '', field: 'displayName' },
        {
          title: translatorService.Tranlate('DASHBOARD_INTERIOR', 'Interior'),
          field: 'byInConditionValue' 
        },
        {
          title: translatorService.Tranlate('DASHBOARD_EXTERIOR', 'Exterior'),
          field: 'byOutCondition'
        },
        {
          title: translatorService.Tranlate('DASHBOARD_MECHANICAL', 'Mecanica'),
          field: 'byTehnicalConditionValue'
        }
      ],
      stockTable:[
        {
          title: translatorService.Tranlate('DASHBOARD_VEHICLE_COUNT','Numar vehicule'),
          field: 'value'
        },
        {
          title: translatorService.Tranlate('DASHBOARD_STATE', 'Status'),
          field: 'displayName'
        }
      ],
      topBuyers:[
        {
          title: translatorService.Tranlate('DASHBOARD_ORGANIZATION', 'Organizatie'),
          field: 'organizationName'
        },
        {
          title: translatorService.Tranlate('DASHBOARD_BUYER_NAME', 'Cumparator'),
          field: 'buyerName'
        }
      ],
      topTentaiveBuyers:[
        {
          title: translatorService.Tranlate('DASHBOARD_ORGANIZATION', 'Organizatie'),
          field: 'organizationName'
        },
        {
          title: translatorService.Tranlate('DASHBOARD_BUYER_NAME', 'Cumparator'),
          field: 'buyerName'
        },
        {
          title: translatorService.Tranlate(
            'DASHBOARD_ACQUISITION_IN_PROGRESS_TABLE_HEADER',
            'Achizitii in desfasurare'
          ),
          field: 'inProgressPurchasesCount'
        }
      ],
      groupedOfferAndSentEmail:[
        {
          label: 'date',
          value: 'offerGeneratedCount'
        },
        {
          label: 'date',
          value: 'emailSentCount'
        }
      ],
      advertListAndSoldVehicleListGrouped: [
        {
          label: 'date',
          value: 'advertCount'
        },
        {
          label: 'date',
          value: 'soldVehicleCount'
        }
      ],
      salesTable:[
        { title: translatorService.Tranlate('DASHBOARD_CAR_TYPE', 'Marca'), field: 'makeName' },
        { title: translatorService.Tranlate('DASHBOARD_MODEL', 'Model'), field: 'modelName' },
        { title: translatorService.Tranlate('DASHBOARD_QUANTITY', 'Nr. Unitati vandute'), field: 'numberOfUnitSold'}
      ],
      stockMap:[
        {
          title: translatorService.Tranlate('DASHBOARD_ORGANIZATION', 'Organizatie'),
          field: 'name'
        },
        {
          title: translatorService.Tranlate('CLIENT_DATA_FORM_ADDRESS', 'Adresa'),
          field: 'address'
        },
        {
          title: translatorService.Tranlate('DASHBOARD_VEHICLE_COUNT','Numar vehicule'),
          field: 'count'
        }
      ],

    }
  }
}
