import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { TextField, Tabs, Tab, AppBar, Button, Chip } from '@material-ui/core';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { OrganizationInterface } from '../../interfaces/Organization';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { withSnackbar, ProviderContext } from 'notistack';
import MaterialTable, { Column, Icons } from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { forwardRef } from 'react';
import { isTemplateElement } from '@babel/types';
import moment from 'moment';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

interface IOrganizationInterfacesState {
  interfaces: OrganizationInterface[];
  isLoading: boolean;
  organizationId: number;
}

type OrganizationInterfacesProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class OrganizationInterfaces extends React.PureComponent<
  OrganizationInterfacesProps,
  IOrganizationInterfacesState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {
    interfaces: [],
    isLoading: true,
    organizationId: 0
  } as IOrganizationInterfacesState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData();
      }
    );
  };

  loadData = async () => {
    const hoId = this.props.appState.appUser?.organizationId;

    if (!hoId) {
      return;
    }

    try {
      const data = await this.organizationService.GetInterfacesWithClientAccess(hoId);

      this.setState({
        interfaces: data,
        isLoading: false,
        organizationId: hoId
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleConnect = async (interfaceId: number, orgId: number) => {
    const url = await this.organizationService.GetInterfaceAuthorizeUrl(orgId, interfaceId);

    if (url) {
      window.open(url, '_blank');
    }
  };

  getColumns = () => {
    return [
      {
        field: 'code',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_CODE_HEADER', 'Code'),
        searchable: true,
        editable: 'never'
      } as Column<OrganizationInterface>,
      {
        field: 'userName',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_UER_HEADER', 'Utilizator'),
        searchable: true,
        render: (props: OrganizationInterface) => {
          if (props.supportsOAuth2) {
            return '-';
          }

          return props.userName;
        },
        editComponent: (props: any) => {
          if (props.rowData.supportsOAuth2) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="text"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      },
      {
        field: 'password',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_PASSWORD_HEADER', 'Parola'),
        render: (props: OrganizationInterface) => {
          if (props.supportsOAuth2) {
            return '-';
          }
          return '***';
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editComponent: (props: any) => {
          if (props.rowData.supportsOAuth2) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="password"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      },
      {
        field: 'customerId',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_INTERFACE_CLIENTID_HEADER',
          'Id Client'
        ),
        render: (props: OrganizationInterface) => {
          if (!props.supportsOAuth2) {
            return '-';
          }
          return '***';
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editComponent: (props: any) => {
          if (!props.rowData.supportsOAuth2) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="text"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      },
      {
        field: 'clientSecret',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_INTERFACE_CLIENTSECRET_HEADER',
          'Secret Client'
        ),
        render: (props: OrganizationInterface) => {
          if (!props.supportsOAuth2) {
            return '-';
          }
          return '***';
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editComponent: (props: any) => {
          if (!props.rowData.supportsOAuth2) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="text"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      },
      {
        field: 'refreshTokenValidUntil',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_STATUS_HEADER', 'Status'),
        editable: 'never',
        render: (rowData: OrganizationInterface) => {
          if (!rowData.supportsOAuth2) {
            return (
              <Chip
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_INTERFACE_STATUS_ACTIVE',
                  'Activa'
                )}
                style={{ backgroundColor: '#2e7d32', color: 'white' }}
              />
            );
          }

          if (!rowData.refreshTokenValidUntil) {
            return (
              <Chip
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_INTERFACE_STATUS_INACTIVE',
                  'Inactiva'
                )}
                style={{ backgroundColor: '#d32f2f', color: 'white' }}
              />
            );
          }

          if (
            rowData.refreshTokenValidUntil &&
            moment.utc(rowData.refreshTokenValidUntil).isAfter(moment.utc())
          ) {
            return (
              <Chip
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_INTERFACE_STATUS_ACTIVE',
                  'Activa'
                )}
                style={{ backgroundColor: '#2e7d32', color: 'white' }}
              />
            );
          }

          return (
            <Chip
              label={this.translatorService.Tranlate(
                'ORGANIZATION_INTERFACE_STATUS_INACTIVE',
                'Inactiva'
              )}
              style={{ backgroundColor: '#d32f2f', color: 'white' }}
            />
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editComponent: (props: any) => {
          if (!props.rowData.supportsOAuth2) {
            return <></>;
          }
          return (
            <TextField
              value={props.value}
              fullWidth={true}
              type="text"
              onChange={(e) => props.onChange(e.target.value)}
            />
          );
        }
      } as Column<OrganizationInterface>,
      {
        field: ' ',
        title: ' ',
        render: (rowData: OrganizationInterface) => {
          if (rowData.supportsOAuth2) {
            return (
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                onClick={() => this.handleConnect(rowData.id, rowData.organizationId)}
              >
                {this.translatorService.Tranlate(
                  'ORGANIZATION_INTERFACE_CONNECT_HEADER',
                  'Conecteaza'
                )}
              </Button>
            );
          }
        },
        editComponent: () => {
          return <></>;
        }
      }
    ];
  };

  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        deleteTooltip: this.translatorService.Tranlate('DELETE', 'Sterge'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza'),
          deleteText: this.translatorService.Tranlate(
            'DELETE_TEXT',
            'Sigur doriti sa stergeti aceasta inregistrare?'
          )
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };

  onRowUpdate = (newData: OrganizationInterface, oldData?: OrganizationInterface): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.organizationService.SetInterfaceCredentials({
            orgId: this.state.organizationId,
            interfaceId: newData.id,
            userName: !newData.supportsOAuth2 ? newData.userName : undefined,
            password: !newData.supportsOAuth2 ? newData.password : undefined,
            clientId: newData.supportsOAuth2 ? newData.customerId : undefined,
            clientSecret: newData.supportsOAuth2 ? newData.clientSecret : undefined
          });
          await this.loadData();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  public renderTable = () => {
    return (
      <MaterialTable
        icons={tableIcons}
        title=" "
        columns={this.getColumns()}
        data={this.state.interfaces}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData)
        }}
      />
    );
  };
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <AppBar position="static" elevation={0} color="secondary">
          <Tabs
            TabIndicatorProps={{
              style: {
                height: '4px'
              }
            }}
            value={0}
            onChange={undefined}
            variant="fullWidth"
            centered={true}
            indicatorColor="primary"
            aria-label="simple tabs example"
          >
            <Tab
              label={this.translatorService.Tranlate('ORGANIZATIONS_TAB_INTERFACES', 'Interfete')}
            />
          </Tabs>
        </AppBar>
        {!this.state.isLoading ? this.renderTable() : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: OrganizationInterfacesProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(OrganizationInterfaces as any));
