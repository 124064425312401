import React, { useMemo, useState } from 'react';
import MUIDataTable, { MUIDataTableColumnOptions, MUIDataTableMeta, SelectableRows } from 'mui-datatables';

import { AppointmentCategoryType, AppointmentClientSelection, AppointmentStatusType, ChangeAppointmentStatusPayload, ResponseAppointmentsData } from '../../types/Agenda';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { TranslatorService } from '../../services/TranslatorService';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { formatAppointmentForCalendar, getDay, getHour } from '../../utils/appointments';
import { Box, Button, CircularProgress, IconButton, Modal, Typography } from '@material-ui/core'; 
import { AppointmentCategoryTypeCodeEnum, AppointmentStatusCodeEnum } from '../../helpers/Constants';
import _ from 'lodash';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { changeAppointmentStatus, getAppointmentsRequest } from '../agenda/agenda.requests';
import { BigCalendar, CustomEvent } from '../agenda/BigCalendar';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppointmentActionStatus } from '../../enums/Status';
import { useHistory } from 'react-router';
import { AGENDA_SET_APPOINTMENTS, AGENDA_SET_SELECTED_RANGE } from '../../store/actions/ActionTypes';
import { useSnackbar } from 'notistack';
import { ConfirmAppointment } from './ConfirmAppointment';

interface AppointmentClientSelectionProps {
  clientDateSelections: AppointmentClientSelection[];
  status: AppointmentStatusType | null;
  appointmentId: number | null;
  getSelectedAppointment: () => void;
  setIsLoading: (loading: boolean) => void;
  changeStatus: (status:number, commnet?:string)=>void;
  date: Date | null;
  setDate:any;
  hour: string | null
  setHour:any
  caseId: number | null;
  appointmentCategoryType: AppointmentCategoryType;
  plateNumber: string | null;
}

const AppointmentClientSelectionDetails: React.FC<AppointmentClientSelectionProps> = ({ clientDateSelections, status, appointmentId, getSelectedAppointment, setIsLoading, changeStatus, date, setDate, hour, setHour, caseId , appointmentCategoryType, plateNumber}) => {
  const translatorService = new TranslatorService();
  const localizer = momentLocalizer(moment);
  const statusList = useAppSelector((state)=>state.agenda?.statusList)
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [openViewCalendar, setOpenViewCalendar] = useState<boolean>(false);
  const [defaultView, setDefaultView] = useState<'month' | 'week' | 'day' | 'agenda'>('week');
  const [defaultDay, setDefaultDay] = useState<any>()
  const [openRescheduleModal, setOpenRescheduleModal] = useState<boolean>(false)
  const [openRescheduleCalendarModal, setOpenRescheduleCalendarModal] = useState<boolean>(false)
  const language = useAppSelector((state) => state.app?.language)
  const [comment, setComment] = useState<string>('');
  const [localAppointments, setLocalAppoitments] = useState<Array<any>>([]);
  const [selectedHour, setSelectedHour] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const dateFormat = useAppSelector((state) => state.app?.dateFormat);

  const fetchAppointments = async (filters: any, onlyOneDay?: boolean) => {
    !onlyOneDay && setLocalLoading(true)
    const response = await getAppointmentsRequest(filters);

    const responseData = response.data as ResponseAppointmentsData;

    if (!responseData?.appointments) {
      !onlyOneDay && setLocalLoading(false)
      return
    }

    if(onlyOneDay){
    setLocalAppoitments(responseData?.appointments)
    }else{
      dispatch({
        type: AGENDA_SET_APPOINTMENTS,
        payload: responseData.appointments,
      });  
    }

    !onlyOneDay && setLocalLoading(false)
  };


  const CustomToolbar = (toolbar:any) => {
    return (
      <div className="rbc-toolbar">
        <span className="rbc-toolbar-label">{toolbar.label}</span>
      </div>
    );
  };

  const formatedAppointments = useMemo(() => {
    if (!localAppointments) {
      return [];
    }

    return localAppointments.filter((appointment) => appointment?.appointmentStatus?.code !== AppointmentStatusCodeEnum.CANCELED.toString()).map((e) => formatAppointmentForCalendar(e, dateFormat || 'DD/MM/YYYY'));
  }, [localAppointments]);

  const renderModal = () => {
    return (
      <div>
        <Modal open={openViewCalendar} onClose={() => {setOpenViewCalendar(false); setSelectedHour('')}}>

          <div className="search-vehicle-modal" style={{ width: '50%' }}>
            <div className="text-right">
              <IconButton onClick={() => { setOpenViewCalendar(false); setSelectedHour('') }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Calendar
              key={JSON.stringify(localAppointments + selectedHour)}
              localizer={localizer}
              events={formatedAppointments}
              defaultView="day"
              min={new Date(0, 0, 0, 7, 0, 0)}
              views={{ day: true}}
              view={'day'}
              components={{
                event: (props:any) => <CustomEvent {...props} />,
                toolbar: CustomToolbar
              }}
              messages={{
                noEventsInRange: translatorService.Tranlate('TABLE_NO_EVENTS_IN_RANGE', 'Nu există rezervări în această perioadă'),
                showMore: (total: any) => `${translatorService.Tranlate('TABLE_TOTAL', 'Încă')} ${total}`
              }}
              value={selectedHour ? new Date(selectedHour) : new Date()}
              defaultDate={selectedHour ? new Date(selectedHour) : new Date()}
              popup
              startAccessor="start"
              endAccessor="end"
              style={{ width: '100%', heigth: '80vh' }}
            />
          </div>
        </Modal>
      </div>
    );
  };

  const handleApprove = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];
      const payload: ChangeAppointmentStatusPayload = {
        appointmentId: appointmentId!,
        statusId: statusList.CONFIRMED.id,
        appointmentClientSelectionId: id
      }

      setIsLoading(true);
      const res = await changeAppointmentStatus(payload);

      if (res.error) {
        setIsLoading(false);
        return;
      }

      if (caseId && (appointmentCategoryType?.code == AppointmentCategoryTypeCodeEnum.MAINTENANCE || appointmentCategoryType?.code == AppointmentCategoryTypeCodeEnum.REPAIR)) {
        history.push('/cases/' + caseId, 'Case xx');
        return;
      }

      getSelectedAppointment();
      
    } catch (error) {
    
    }
  };

  const handleSlotSelect = (slotInfo: any) => {
    const selectedDate = new Date(slotInfo.start);
    if (defaultView === 'month') {
      setDefaultDay(selectedDate);
      setDefaultView('day')
    } else {
      const currentDate = new Date();
      if (selectedDate < currentDate) {
        enqueueSnackbar(translatorService.Tranlate('PAST_DATE', 'You cannot select a date from the past for scheduling'), {
          variant: 'error'
        });
        return;
      }
      const selectedHour = selectedDate.getHours();
      const selectedMinute = selectedDate.getMinutes();
      setDate(selectedDate);
      setHour(`${selectedHour < 10 ? `0${selectedHour}` : selectedHour}:${selectedMinute < 10 ? `0${selectedMinute}` : selectedMinute}`);
      setOpenRescheduleModal(true)
    }
  }

  const renderRescheduleCalendarModal = () =>{
    return <div>
      <Modal open={openRescheduleCalendarModal} onClose={() => { setOpenRescheduleCalendarModal(false) }}>
        <div className="reschedule-calendar-modal">
          <div className="text-right">
            <IconButton onClick={() => { setOpenRescheduleCalendarModal(false) }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="h3" style={{ textAlign: 'center', marginTop:-16 }}>
            {translatorService.Tranlate('APPOINTMENT_RESCHUDELE_CALENDAR_TITLE', 'Select the date and time for rescheduling')}
          </Typography >
          <Typography variant='h3' style={{ textAlign: 'center', marginBottom: '16px', }}>
            {`${appointmentId}${plateNumber ? ` - ${plateNumber}` : ''}`}
          </Typography>
          <BigCalendar 
            getAppointments={fetchAppointments} 
            defaultView={defaultView} 
            setDefaultView={setDefaultView} 
            defaultDay={defaultDay}
            handleSlotSelect={handleSlotSelect} 
          />
        </div>
      </Modal>
    </div>
  }
  
  const getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'date',
        label: translatorService.Tranlate(
          'APPOINTMENT_TABLE_DATE',
          'Data'
        ),
        options: {
          customBodyRender: (value: string | null) => value != null ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'ceter', alignContent: 'center' }}>
            <FontAwesomeIcon icon={['fas', 'calendar-alt']} style={{ color: '#5383ff', fontSize: '1rem',marginRight: 8 }} />
            <div >{getDay(new Date(value), dateFormat || 'DD/MM/YYYY')}</div>
          </div>  : null
        }
      },
      {
        name: 'date',
        label: translatorService.Tranlate(
          'APPOINTMENT_TABLE_TIME',
          'Ora'
        ),
        options: {
          customBodyRender: (value: string | null) => value != null ? <div style={{ display: 'flex', flexDirection: 'row', alignItems:'ceter', alignContent:'center' }}>
            <FontAwesomeIcon icon={['fas', 'clock']} style={{ fontSize: '1rem', marginRight: 8, color: '#5383ff' }} />
            <div >{getHour(new Date(value))}</div>
          </div> : null
        }
      },
      {
        name: 'appointmentDetailsType',
        label: translatorService.Tranlate(
          'APPOINTMENT_TABLE_CLIENT_DETAILS',
          'Detalii'
        ),
        options: {
          customBodyRender: (value: any) => {
            const indexLang = value?.translations?.findIndex((trans: any) => trans?.language === language);
            return indexLang !== -1 ? value?.translations[indexLang]?.name : value?.displayName;
          }
        }
      },
      {
        name: 'appointmentActionType',
        label: translatorService.Tranlate(
          'APPOINTMENT_TABLE_ACTIONS',
          'Actions'
        ),
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            if (!value  && (status?.code === AppointmentStatusCodeEnum.INITIATA || status?.code === AppointmentStatusCodeEnum.RESCHEDULED)){
              return (<Box className='tableActions'>
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'rgb(56 203 72)', color: 'white', margin: 8 }}
                  onClick={(e) => handleApprove(e, tableMeta)}
                >
                  {translatorService.Tranlate('APPOINTMENT_CLIENT_SELECTION_CONFIRM', 'Confirm')}
                </Button>
                <Button
                  style={{ backgroundColor: 'transparent', color: '#f4772e', margin: 8, border: '1px solid #f4772e', }}
                  onClick={async (e) => {
                    const currentDate = new Date(tableMeta.rowData[1]);
                    setDefaultDay(currentDate);
                    
                    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    lastDayOfMonth.setHours(23, 59, 59, 999);
                    
                    dispatch({
                      type: AGENDA_SET_SELECTED_RANGE,
                      payload: {
                        start_date: firstDayOfMonth,
                        end_date: lastDayOfMonth
                      }
                    });

                    await fetchAppointments({
                      dateFilterStartDate: moment(firstDayOfMonth).format('YYYY-MM-DDTHH:mm:ss'),
                      datefilterEndDate: moment(lastDayOfMonth).format('YYYY-MM-DDTHH:mm:ss')
                    });
                    setOpenRescheduleCalendarModal(true)
                    setDefaultView('week')
                  }}
                >
                  <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    {localLoading && <CircularProgress style={{color:'#f4772e', marginRight:4, marginTop:-2}} size={12}/> }
                    {translatorService.Tranlate('APPOINTMENT_DETAILS_RESCHEDULE_BUTTON', 'Reschedule')}
                  </div>
                </Button>
                <Button
                  variant="text"
                  style={{ backgroundColor: 'transparent', color: '#5383ff', margin: 8, fontSize: 13, textDecoration: 'underline', padding:0 }}
                  onClick={async (e) => {
                    setSelectedHour(tableMeta.rowData[1])
                    const startDate = new Date(tableMeta.rowData[1])
                    startDate.setUTCHours(0, 0, 0, 0);
                    const endDate = new Date(tableMeta.rowData[1])
                    endDate.setUTCHours(23, 59, 59, 999);
                    await fetchAppointments({
                      dateFilterStartDate: moment(startDate).format(),
                      datefilterEndDate: moment(endDate).format()
                    }, true)
                    setOpenViewCalendar(true)
                  }}
                >
                  {translatorService.Tranlate('APPOINTMENT_CLIENT_SELECTION_OPEN_CALENDAR', 'Open calendar')}
                </Button>

              </Box>

              );
            }
            const indexLang = value?.translations?.findIndex((trans: any) => trans?.language === language);
            const text =  indexLang !== -1 ? value?.translations[indexLang]?.name : value?.displayName;
            return <div style={{ display:'flex', flexDirection:'row', }}>
              <Button style={{alignSelf:'flex-start', pointerEvents: 'none', color: value?.code === AppointmentActionStatus.CONFIRMEDSERVICE ? 'rgb(56 203 72)' : '#f4772e'}}                >
                {text}
              </Button>
            </div>
          }
        }
      }
    ];
    return columns;
  };

  const options = {
    filter: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none' as SelectableRows,
    selectableRowsOnClick: false,
    print: false,
    download: false,
    textLabels: MUITranslations.GetTranslations(translatorService),
    elevation: 0,
    pagination: false,
  };

  return (
    <Box className='appointmentTable' px={1} style={{ paddingLeft: 0, paddingRight: 0}}>
      <MUIDataTable
        title={''}
        key={JSON.stringify(clientDateSelections)}
        data={clientDateSelections}
        columns={getColumns()}
        options={options}
      />
      {renderModal()}
      {openRescheduleModal ? <ConfirmAppointment 
        setIsVisible={setOpenRescheduleModal}
        visible={openRescheduleModal}
        title={translatorService.Tranlate('APPOINTMENT_DETAILS_RESCHEDULE_BUTTON', 'Reschedule')}
        comment={comment}
        setComment={setComment}
        date={date}
        setDate={setDate}
        hour={hour}
        setHour={setHour}
        onClickButton={async (e) => {
          changeStatus(statusList.RESCHEDULED.id, comment)
          setOpenRescheduleCalendarModal(false);
          setOpenRescheduleCalendarModal(false);
        }}
        calendarText= {translatorService.Tranlate('APPOINTMENT_RESCHEDULE_MESSAGE', 'Select a new date for scheduling')}
        buttonText={translatorService.Tranlate('APPOINTMENT_DETAILS_RESCHEDULE_BUTTON', 'Reschedule')}
      />:null}
      {renderRescheduleCalendarModal()}
    </Box>
  );
};




export default AppointmentClientSelectionDetails;
