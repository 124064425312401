import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { ApplicationContext } from "../../context/Contexts";
import { MavoVehicleAuctionStatusHistory } from "../../interfaces/Vehicle";
import { MavoAuctionStatusEnum } from "../../helpers/Constants";

type RealTimeDateProps = {
  endDate: string;
  showLastOffer: boolean;
  auctionStatus: MavoVehicleAuctionStatusHistory;
};

const RealTimeDate = ({ endDate, showLastOffer, auctionStatus }: RealTimeDateProps) => {
  const [remainingTime, setRemainingTime] = useState("");
  const [isLastOffer, setIsLastOffer] = useState(false);
  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;

  const calculateRemainingTime = (endDate: string) => {
    const now = moment();
    const end = moment(endDate);
    const duration = moment.duration(end.diff(now));

    if (auctionStatus?.status?.code === MavoAuctionStatusEnum.TERMINATA) {
      return translatorService.Tranlate("CLOSED_AUCTION", "Licitatie inchisa!");
    }

    if (duration.asMilliseconds() <= 0) {
      return translatorService.Tranlate("AUCTION_IN_ANALYSIS", "In Analiza!");
    }

    // Dacă mai sunt mai puțin de 5 minute până la ora 17:00
    if (duration.asMinutes() <= 5 && end.hour() === 17) {
      setIsLastOffer(true);
    } else {
      setIsLastOffer(false);
    }

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let timeString = '';
    if (days > 0) timeString += `${days} zile, `;
    if (hours > 0) timeString += `${hours} ore, `;
    if (minutes > 0) timeString += `${minutes} minute, `;
    timeString += `${seconds} secunde`;

    return timeString;
  };

  useEffect(() => {
    const updateRemainingTime = () => {
      setRemainingTime(calculateRemainingTime(endDate));
    };

    const interval = setInterval(updateRemainingTime, 1000);
    updateRemainingTime(); 

    return () => clearInterval(interval);
  }, [endDate]);

  return (
    <span>
      {remainingTime}
      {showLastOffer && isLastOffer &&  (
        <span style={{ color: 'red', marginLeft: '10px' }}>
          {translatorService.Tranlate("AUCTION_LAST_OFFER", "Ultima oferta")}
        </span>
      )}
    </span>
  );
};

export default RealTimeDate;
