import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Card, Grid, Paper } from '@material-ui/core';

import { ApplicationContext } from '../../../context/Contexts';
import MaterialTable from '@material-table/core';
import { getLocalization, MaterialTableIcons } from '../../_shared/TableSettings';
import { SalesVehicle } from '../../../interfaces/Dashboard/SalesVehicle';
import { numberFormat } from '../../../utils/numberFormat';

interface SalesAggregateTableProps {
  vehicles: SalesVehicle[];
}

const SalesAggregateTable: React.FC<SalesAggregateTableProps> = ({ vehicles }) => {
  const context = useContext(ApplicationContext);
  const [selected, setSelected] = useState<SalesVehicle | null>(null);

  const carType = context.translatorService.Tranlate('DASHBOARD_CAR_TYPE', 'Marca');
  const carModel = context.translatorService.Tranlate('DASHBOARD_MODEL', 'Model');
  const quantity = context.translatorService.Tranlate('DASHBOARD_QUANTITY', 'Nr. Unitati vandute');
  const averageAge = context.translatorService.Tranlate(
    'DASHBOARD_AVERAGE_VEHICLE_AGE',
    'Varsta medie'
  );
  const total = context.translatorService.Tranlate('DASHBOARD_TOTAL', 'Total achizitie');
  const fixesAndOtherCosts = context.translatorService.Tranlate(
    'DASHBOARD_FIXES_AND_OTHER',
    'Reparatii + alte costuri'
  );
  const salePrice = context.translatorService.Tranlate(
    'DASHBOARD_SALE_PRICE',
    'Pret Total vanzare'
  );
  const profit = context.translatorService.Tranlate('DASHBOARD_PROFIT', 'Profit %');
  const totalProfit = context.translatorService.Tranlate(
    'DASHBOARD_TOTAL_PROFIT',
    'Profit (valoare absoluta)'
  );

  const columns = useMemo(() => {
    return [
      { title: carType, field: 'makeName' },
      { title: carModel, field: 'modelName' },
      { title: quantity, field: 'numberOfUnitSold' },
      {
        title: averageAge,
        field: 'avgOfYear',
        render: (rowData: SalesVehicle) => numberFormat(rowData.avgOfYear, 1)
      },
      {
        title: total,
        field: 'purchaseValue',
        render: (rowData: SalesVehicle) => numberFormat(rowData.purchaseValue, 0, undefined, ' EUR')
      },
      {
        title: fixesAndOtherCosts,
        field: 'damageValue',
        render: (rowData: SalesVehicle) => numberFormat(rowData.damageValue, 0, undefined, ' EUR')
      },
      {
        title: salePrice,
        field: 'soldValue',
        render: (rowData: SalesVehicle) => numberFormat(rowData.soldValue, 0, undefined, ' EUR')
      },
      {
        title: profit,
        field: 'profitPercent',
        render: (rowData: SalesVehicle) => numberFormat(rowData.profitPercent)
      },
      {
        title: totalProfit,
        field: 'profitValue',
        render: (rowData: SalesVehicle) => numberFormat(rowData.profitValue, 0, undefined, ' EUR')
      }
    ];
  }, [
    carType,
    carModel,
    quantity,
    averageAge,
    total,
    fixesAndOtherCosts,
    salePrice,
    profit,
    totalProfit
  ]);

  const carInfo = (data: SalesVehicle) => {
    return (
      <Grid item xs={3}>
        <Card className="h-100 p-4 text-center d-flex justify-content-center align-items-center flex-column">
          <div className="row">
            <div className="col-md-8">
              {data?.makeName} {data?.modelName}
              <br />
              {`${context.translatorService.Tranlate(
                'DASHBOARD_SALES_TABLE_QUANTITY',
                'Numar de unitati'
              )}: ${data?.numberOfUnitSold}`}
              <br />
              {`${averageAge}: ${numberFormat(data?.avgOfYear, 1)}`}
              <br />
              <div>
                {data?.groupedByFuelType.map((row, index) => {
                  return (
                    <div key={index}>
                      {row.name}: {Math.round(row.percentValue)}% ({row.count})
                    </div>
                  );
                })}
              </div>
              <div>
                {data?.groupedByTransmissionType.map((row, index) => {
                  return (
                    <div key={index}>
                      {row.name}: {Math.round(row.percentValue)}% ({row.count})
                    </div>
                  );
                })}
              </div>
              {`${context.translatorService.Tranlate(
                'DASHBOARD_AVG_SALE_VALUE',
                'Pret mediu de vanzare'
              )}: ${numberFormat(data?.avgSoldValue)} Euro`}
              <br />
              {`${context.translatorService.Tranlate(
                'DASHBOARD_AVG_DAMAGE_VALUE',
                'Costuri medii'
              )}: ${numberFormat(data?.avgDamageValue)} Euro`}
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <h3 style={{ marginTop: 10 }}>
                {context.translatorService.Tranlate('DASHBOARD_PROFIT_VALUE', 'Profit')}{' '}
                {Math.round(data?.profitPercent)}%
              </h3>
            </div>
          </div>
        </Card>
      </Grid>
    );
  };

  useEffect(() => {
    setSelected(null);
  }, [vehicles]);

  return (
    <>
      <Grid item xs={9}>
        <div style={{ maxWidth: '100%', height: '100%' }}>
          <MaterialTable
            title={context.translatorService.Tranlate('DASHBOARD_SALES_TABLE_TITLE', 'Vanzari')}
            icons={MaterialTableIcons}
            localization={getLocalization(context.translatorService)}
            columns={columns}
            data={vehicles}
            onRowClick={(evt, selectedRow) => {
              setSelected(selectedRow as SalesVehicle);
            }}
            options={{
              search: false,
              maxBodyHeight: '49vh',
              headerStyle: { position: 'sticky', top: 0 },
              pageSize: vehicles.length,
              rowStyle: (rowData) => ({
                backgroundColor:
                  selected?.vehicleId === rowData.vehicleId ||
                  (!selected && rowData.vehicleId === vehicles[0].vehicleId)
                    ? '#267DC3'
                    : '#FFF',
                color:
                  selected?.vehicleId === rowData.vehicleId ||
                  (!selected && rowData.vehicleId === vehicles[0].vehicleId)
                    ? '#FFF'
                    : '#000'
              }),
              actionsCellStyle: { backgroundColor: 'red' },
              tableLayout: 'fixed'
            }}
            components={{
              Container: (props) => <Paper {...props} className="h-100" />,
              Pagination: () => null
            }}
          />
        </div>
      </Grid>
      {!!selected ? carInfo(selected) : carInfo(vehicles[0])}
    </>
  );
};

export default SalesAggregateTable;
