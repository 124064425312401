import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, IconButton, Grid, FormControlLabel, Switch } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { CaseAttachment } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import {
  ReferentialCode,
  AttachmentTypeCode,
  InsuredTypeCode,
  RefConstants
} from '../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { AppUser } from '../../interfaces/AppUser';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import NumberFormat from 'react-number-format';
import { CaseClient } from '../../interfaces/Case';
import { CNP } from 'romanian-personal-identity-code-validator';
import * as R from 'ramda';
import { isNullOrUndefined } from 'util';
import { SectionCode } from '../../helpers/forms/FormCode';
import * as IbanTools from 'ibantools';

interface ICaseDetailsCaseTabClientDataFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isUserHidden: boolean;
  clientData: CaseClient;
  personTypes: Array<IReferential>;
  attachType: IReferential;
  bankName: string;
  bankList: IReferential[];

  region: IReferential | null;
  city: IReferential | null;
  district: IReferential | null;
  regionsTypes: IReferential[];
  citiesTypes: IReferential[];
  districtsTypes: IReferential[];
}

export interface ExternalCaseDetailsCaseTabClientDataFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabClientDataFormProps = ExternalCaseDetailsCaseTabClientDataFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabClientDataForm extends React.PureComponent<
  ICaseDetailsCaseTabClientDataFormProps,
  ICaseDetailsCaseTabClientDataFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    isLoading: false,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    attachType: {} as IReferential,
    personTypes: [] as IReferential[],
    regionsTypes: [] as IReferential[],
    citiesTypes: [] as IReferential[],
    districtsTypes: [] as IReferential[],
    region: {} as IReferential,
    district: {} as IReferential,
    city: {} as IReferential,
    bankName: '',
    bankList: [] as IReferential[],

    clientData: {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      caseSectionStepFormId: this.props.workflowForm.id,
      clientId: 0,
      pin: '',
      companyNumber: '',
      companyName: '',
      attachments: [] as CaseAttachment[],
      gdprAgreement: true,
      address: '',
      city: '',
      region: '',
      district: '',
      bankAccount: '',
      email: '',
      phone: ''
    } as CaseClient
  } as ICaseDetailsCaseTabClientDataFormState;

  inputTextValidator = React.createRef<TextValidator>();

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseClientDataForm();
      }
    );

    ValidatorForm.addValidationRule('isValidCNP', (value) => {
      if (!value) {
        return true;
      }

      const cnp = new CNP(value);

      if (!cnp.isValid()) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule('isIbanValid', (value) => {
      if (!value) {
        return true;
      }

      const ibanValidationResult = IbanTools.validateIBAN(value.replace(/\s/g, ''));
      return ibanValidationResult.valid;
    });
  }

  loadCaseClientDataForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const casee = this.props.caseSettingsState.case;

    let caseClient = this.state.clientData;
    if (!R.isNil(casee!.caseClient)) {
      caseClient = casee!.caseClient;
    }

    const personTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PersonType
    );
    const refAttachemntType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );
    const refBanks = Referentials.referential.find((item) => item.code === ReferentialCode.Bank);

    const [personTypeList, attachmentTypes] = await Promise.all([
      this.appReferentialService.Get(personTypeRef!.baseUrl),
      this.appReferentialService.Get(refAttachemntType!.baseUrl)
    ]);

    caseClient.attachments.forEach(
      (item) =>
        (item.attachmentType = attachmentTypes.find(
          (item) => item.code === AttachmentTypeCode.OTHER
        )!)
    );

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    const { regionRef, cityRef, districtRef } = RefConstants;
    const [regions, cities, districts, banks] = await Promise.all([
      this.appReferentialService.Get(regionRef!.baseUrl),
      this.appReferentialService.Get(cityRef!.baseUrl),
      this.appReferentialService.Get(districtRef!.baseUrl),
      this.appReferentialService.Get(refBanks!.baseUrl)
    ]);

    console.log('personTypeList', personTypeList);
    this.setState({
      ...this.state,
      isLoading: false,
      hasRights: hasRights,
      isUserHidden: !hasRights,
      personTypes: personTypeList,
      clientData: caseClient,
      attachType: attachmentTypes.find((item) => item.code === AttachmentTypeCode.OTHER)!,
      regionsTypes: regions,
      citiesTypes: cities,
      districtsTypes: districts,
      region:
        regions.find((item) => item.displayName === caseClient.region)! || ({} as IReferential),
      district:
        districts.find((item) => item.displayName === caseClient.district)! || ({} as IReferential),
      city: cities.find((item) => item.displayName === caseClient.city)! || ({} as IReferential),
      bankList: banks
    });
  };

  formatBankAccount = (bankAccountValue: string) => {
    return bankAccountValue.length > 5
      ? bankAccountValue
          //.replace(/[^\dA-Z]/g, '')
          .replace(/(.{4})/g, '$1 ')
          .trim()
      : bankAccountValue;
  };

  updateBankOnblur() {
    const newBankName =
      this.state.bankList.find(
        (b) => b.code === this.state.clientData.bankAccount.replace(/\s/g, '').substr(4, 4)
      )?.name || '';
    this.setState({
      bankName: newBankName
    });
  }

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  submitClientData = async (e: any) => {
    try {
      this.setState({ executing: true });

      let newCaseClient = {
        ...this.state.clientData,
        caseSectionStepFormId: this.props.workflowForm.id
      };

      if (this.state.clientData.id === 0) {
        newCaseClient = await this.caseService.AddCaseClient(this.state.clientData);
      } else {
        await this.caseService.UpdateCaseClient(newCaseClient);
      }

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      this.props.SetCaseClientData(newCaseClient);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  handleCNPChange = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        pin: e.target.value
      }
    });
  };

  handlePersonTypeChange = (newValue: IReferential | null) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        personType: newValue,
        personTypeId: newValue === null ? null : newValue.id
      }
    });
  };

  handleTextChange = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        email: e.target.value
      }
    });
  };

  handleAddressChange = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        address: e.target.value
      }
    });
  };

  handleBankAccountChange = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        bankAccount: e.target.value
      }
    });
  };

  handleIsActiveAgreement = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        gdprAgreement: e.currentTarget.checked
      }
    });
  };

  handleUploadAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let attachments = [...this.state.clientData.attachments];
      if (R.isNil(e.target.files)) {
        return;
      }

      attachments = [];

      attachments.push({
        id: 0,
        caseId: this.props.caseSettingsState.case!.id,
        attachmentTypeId: this.state.attachType.id,
        attachmentType: this.state.attachType,
        file: e.target.files![0],
        fileName: e.target.files![0].name,
        uploadDate: new Date(),
        carPhotos: false,
        originalFileName: e.target.files![0].name,
        caseRequestId: null,
        paymentId: null,
        serviceAppointmentId: null,
        caseClientId: null,
        isSelection: false
      });

      this.setState({
        clientData: {
          ...this.state.clientData,
          attachments: attachments
        }
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  isCnpCuiEnabled = () => {
    const workflowSectionStepId = this.props.workflowForm.workflowSectionStepId;
    const resultPart1 = this.props.caseSettingsState.caseSettings!.workflow.workflowSections?.find(
      (x) => x.workflowSectionSteps.some((y) => y.id == workflowSectionStepId)
    );
    let shouldEnableCnpCUi = false;
    if (!isNullOrUndefined(resultPart1)) {
      shouldEnableCnpCUi = resultPart1?.section?.code === SectionCode.Oferte;
    }

    const result =
      shouldEnableCnpCUi || isNullOrUndefined(this.props.caseSettingsState.case?.caseClient);
    return result;
  };

  checkIsItsSaleWorkflow = () => {
    const workflowSectionStepId = this.props.workflowForm.workflowSectionStepId;
    const resultPart1 = this.props.caseSettingsState.caseSettings!.workflow.workflowSections?.find(
      (x) => x.workflowSectionSteps.some((y) => y.id == workflowSectionStepId)
    );

    let showResetClientData = false;
    if (!isNullOrUndefined(resultPart1)) {
      showResetClientData = resultPart1?.section?.code === SectionCode.Oferte;
    }
    return showResetClientData;
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      const attachments = [...this.state.clientData.attachments];
      const index = attachments.findIndex((item) => item.id === id);
      attachments.splice(index, 1);

      this.setState({
        clientData: {
          ...this.state.clientData,
          attachments: attachments
        }
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAdvertRegionTypeChange = (newValue: IReferential | null) => {
    this.setState({
      ...this.state,
      region: newValue,
      city: null,
      district: null,
      clientData: {
        ...this.state.clientData,
        region: newValue?.displayName,
        city: null,
        district: null
      }
    });
  };

  handleAdvertDistrictTypeChange = (newValue: IReferential | null) => {
    this.setState({
      ...this.state,
      district: newValue,
      clientData: {
        ...this.state.clientData,
        district: newValue?.displayName
      }
    });
  };

  handleCnpCuiOnBlur = async (newValue: any) => {
    if (
      isNullOrUndefined(this.state.clientData.personType) ||
      (this.state.clientData.pin.length == 0 && this.state.clientData.companyNumber.length == 0)
    ) {
      return;
    }
    this.setState({ isLoading: true });

    const pin_cui =
      this.state.clientData.personType!.code === InsuredTypeCode.PJ
        ? this.state.clientData.companyNumber
        : this.state.clientData.pin;

    const clients = await this.caseService.SearchClient(
      this.state.clientData.personType!.id,
      pin_cui,
      ''
    );

    if (clients.length > 0) {
      const client = clients[0];
      const region =
        this.state.regionsTypes.find((item) => item.displayName === client.region)! ||
        ({} as IReferential);
      const city =
        this.state.citiesTypes.find(
          (item) => item.displayName === client.city && item.dependencyId == region.id
        )! || ({} as IReferential);
      const district =
        this.state.districtsTypes.find(
          (item) => item.displayName === client.district && item.dependencyId === city.id
        )! || ({} as IReferential);
      const bankName = client.bankAccount
        ? this.state.bankList.find(
            (b) => b.code === client.bankAccount.replace(/\s/g, '')?.substr(4, 4)
          )?.name || ''
        : '';
      this.setState({
        ...this.state,
        clientData: {
          ...this.state.clientData,
          clientId: client.id,
          companyName: client.companyName,
          companyNumber: client.companyNumber,
          contactPerson: client.contactPerson,
          phone: client.phone,
          firstName: client.firstName,
          lastName: client.lastName,
          email: client.email,
          address: client.address,
          city: client.city,
          region: client.region,
          district: client.district,
          bankAccount: client.bankAccount
        },
        bankName: bankName,
        region: region,
        city: city,
        district: district
      });
      this.handleAdvertRegionTypeChange(region);
      this.handleAdvertCityTypeChange(city);
      this.handleAdvertDistrictTypeChange(district);
    }

    this.setState({ isLoading: false });
  };

  handleAdvertCityTypeChange = (newValue: IReferential | null) => {
    this.setState({
      ...this.state,
      city: newValue,
      district: null,
      clientData: {
        ...this.state.clientData,
        city: newValue?.displayName,
        district: null
      }
    });
  };

  renderAttachmentBox = () => {
    return (
      <Grid container spacing={2} className="">
        <Grid item xs={9}>
          {this.state.clientData.attachments.length !== 0
            ? this.state.clientData.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100 mt-3">
                    <div className="w-75 text-left">
                      <Button
                        color="primary"
                        className="text-primary text-left"
                        disabled={!this.state.hasRights}
                        //onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                    <div className="w-25">
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        className="text-danger"
                        size="small"
                        onClick={(e) => this.handleDeleteAttachment(attach.id, attach.fileName)}
                        disabled={!this.state.hasRights}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
        <Grid item xs={3} container justify="flex-end" direction="column">
          <input
            className="d-none"
            id={'contained-button-file'}
            disabled={!this.state.hasRights}
            onChange={(e) => {
              return this.handleUploadAttachment(e);
            }}
            type="file"
          />
          <label htmlFor={'contained-button-file'}>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.hasRights}
              className="d-block text-center shadow-none mt-3"
              component="div"
            >
              {this.translatorService.Tranlate('CLIENT_DATA_FORM_ATTACH', 'Ataseaza')}
            </Button>
          </label>
        </Grid>
      </Grid>
    );
  };

  resetClientData = () => {
    this.setState({
      region: {} as IReferential,
      district: {} as IReferential,
      city: {} as IReferential,
      bankName: '',
      clientData: {
        id: this.state.clientData.id,
        caseId: this.props.caseSettingsState.case!.id,
        caseSectionStepFormId: this.props.workflowForm.id,
        clientId: 0,
        pin: '',
        companyNumber: '',
        companyName: '',
        attachments: [] as CaseAttachment[],
        gdprAgreement: true,
        address: '',
        city: '',
        region: '',
        district: '',
        bankAccount: ''
      } as CaseClient
    });
  };

  rendeClientDataForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitClientData(e);
        }}
      >
        {this.checkIsItsSaleWorkflow() ? (
          <div style={{ float: 'right' }}>
            <Button
              className="m-2"
              variant="contained"
              color="primary"
              disabled={!this.state.hasRights}
              onClick={() => {
                this.resetClientData();
              }}
            >
              {this.translatorService.Tranlate('RESET_CLIENT_DATA', 'Reseteaza date client')}
            </Button>
          </div>
        ) : (
          ''
        )}

        <div className="m-3 text-center">
          <Autocomplete
            disabled={!this.state.hasRights || !this.isCnpCuiEnabled()}
            id="person-type"
            className="m-2"
            options={this.state.personTypes}
            value={this.state.clientData.personType || null}
            onChange={(e: any, newValue: IReferential | null) =>
              this.handlePersonTypeChange(newValue)
            }
            getOptionLabel={(option: IReferential) => option.displayName || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="personType"
                value={this.state.clientData.personType}
                label={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_PERSON_TYPE',
                  'Tip persoana'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />

          {!R.isNil(this.state.clientData.personType!) &&
          this.state.clientData.personType!.code === InsuredTypeCode.PJ ? (
            <div>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights || !this.isCnpCuiEnabled()}
                id="client-cui"
                className="m-2"
                name="companyNumber"
                placeholder={this.translatorService.Tranlate('CLIENT_DATA_FORM_CUI', 'CUI')}
                value={this.state.clientData.companyNumber || ''}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      companyNumber: e.target.value
                    }
                  });
                }}
                onBlur={async (e: any) => {
                  await this.handleCnpCuiOnBlur(e);
                }}
                label={this.translatorService.Tranlate('CLIENT_DATA_FORM_CUI', 'CUI')}
              />

              <TextValidator
                fullWidth
                disabled={!this.state.hasRights || !this.isCnpCuiEnabled()}
                id="client-company-name"
                className="m-2"
                name="companyName"
                placeholder={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_COMPANY_NAME',
                  'Nume companie'
                )}
                value={this.state.clientData.companyName || ''}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      companyName: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_COMPANY_NAME',
                  'Nume companie'
                )}
              />

              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                name="contactPerson"
                id="client-contact-person"
                className="m-2"
                value={this.state.clientData.contactPerson || ''}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      contactPerson: e.target.value
                    }
                  });
                }}
                placeholder={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_CONTACT_PERSON',
                  'Persoana de contact'
                )}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_CONTACT_PERSON',
                  'Persoana de contact'
                )}
              />
            </div>
          ) : (
            <div>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights || !this.isCnpCuiEnabled()}
                id="client-cnp"
                className="m-2"
                name="pin"
                placeholder={this.translatorService.Tranlate('CLIENT_DATA_FORM_CNP', 'CNP')}
                value={this.state.clientData.pin || ''}
                onChange={(e: any) => {
                  this.handleCNPChange(e);
                }}
                onBlur={async (e: any) => {
                  await this.handleCnpCuiOnBlur(e);
                }}
                validators={['isValidCNP']}
                errorMessages={[
                  this.translatorService.Tranlate('CLIENT_DATA_FORM_VALIDATOR_CNP', 'CNP invalid')
                ]}
                label={this.translatorService.Tranlate('CLIENT_DATA_FORM_CNP', 'CNP')}
              />

              <TextValidator
                value={this.state.clientData.lastName || ''}
                id="lastName"
                name="lastName"
                className="m-2"
                disabled={!this.state.hasRights || !this.isCnpCuiEnabled()}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      lastName: e.target.value
                    }
                  });
                }}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CLIENT_DATA_FORM_NAME', 'Nume')}
              />

              <TextValidator
                value={this.state.clientData.firstName || ''}
                fullWidth
                disabled={!this.state.hasRights || !this.isCnpCuiEnabled()}
                id="firstName"
                name="firstName"
                className="m-2"
                placeholder={this.translatorService.Tranlate('CLIENT_DATA_FORM_SURNAME', 'Prenume')}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      firstName: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CLIENT_DATA_FORM_SURNAME', 'Prenume')}
              />
            </div>
          )}

          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="client-email"
            name="email"
            className="m-2"
            placeholder={this.translatorService.Tranlate('CLIENT_DATA_FORM_EMAIL', 'E-mail')}
            value={this.state.clientData.email || ''}
            onChange={(e) => this.handleTextChange(e)}
            validators={['isEmail']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_EMAIL', 'E-mail invalid')
            ]}
            label={this.translatorService.Tranlate('CLIENT_DATA_FORM_EMAIL', 'E-mail')}
          />

          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            onChange={(e: any) => {
              this.setState({
                clientData: {
                  ...this.state.clientData,
                  phone: e.target.value
                }
              });
            }}
            name="phone"
            className="m-2"
            label={this.translatorService.Tranlate('CLIENT_DATA_FORM_PHONE', 'Telefon')}
            value={this.state.clientData.phone || ''}
          />

          <TextValidator
            ref={this.inputTextValidator}
            disabled={!this.state.hasRights}
            className="m-2"
            name="bankAccount"
            label={
              this.state.bankName !== ''
                ? this.state.bankName
                : this.translatorService.Tranlate('CASE_PAYMENT_DETAILS_FORM_IBAN_LABEL', 'IBAN')
            }
            fullWidth
            value={this.state.clientData.bankAccount || ''}
            onBlur={() => this.updateBankOnblur()}
            onChange={(e) => this.handleBankAccountChange(e)}
            validators={['isIbanValid']}
            errorMessages={[
              this.translatorService.Tranlate(
                'CASE_PAYMENT_DETAILS_FORM_IBAN_INVALID_WARNING',
                'IBAN-ul este invalid'
              )
            ]}
          />

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disableClearable
                  disabled={!this.state.hasRights}
                  id="region"
                  className="m-2"
                  options={this.state.regionsTypes}
                  value={this.state.region || null}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleAdvertRegionTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="region"
                      value={this.state.region}
                      label={this.translatorService.Tranlate('CLIENT_DATA_FORM_REGION', 'Judet')}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disableClearable
                  disabled={!this.state.hasRights}
                  id="city"
                  className="m-2"
                  options={this.state.citiesTypes.filter(
                    (city) => city.dependencyId === this.state.region?.id
                  )}
                  value={this.state.city || null}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleAdvertCityTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="city"
                      value={this.state.city}
                      label={this.translatorService.Tranlate('CLIENT_DATA_FORM_CITY', 'Oras')}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="district"
                  className="m-2"
                  options={this.state.districtsTypes.filter(
                    (district) => district.dependencyId === this.state.city?.id
                  )}
                  value={this.state.district || null}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleAdvertDistrictTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="region"
                      value={this.state.district}
                      label={this.translatorService.Tranlate('CLIENT_DATA_FORM_DISTRICT', 'Sector')}
                      fullWidth
                      validators={
                        this.state.districtsTypes.filter(
                          (district) => district.dependencyId === this.state.city?.id
                        ).length > 0
                          ? ['required']
                          : []
                      }
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <TextValidator
                  fullWidth
                  disabled={!this.state.hasRights}
                  id="client-address"
                  name="address"
                  className="m-2"
                  placeholder={this.translatorService.Tranlate(
                    'CLIENT_DATA_FORM_ADDRESS',
                    'Adresa'
                  )}
                  value={this.state.clientData.address || ''}
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      clientData: {
                        ...this.state.clientData,
                        address: e.target.value
                      }
                    });
                  }}
                  label={this.translatorService.Tranlate('CLIENT_DATA_FORM_ADDRESS', 'Adresa')}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={2} className="text-left">
              <Button
                className="mt-3"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={!this.state.hasRights || this.state.executing}
              >
                {this.translatorService.Tranlate('CLIENT_DATA_FORM_GDPR', 'Acord GDPR')}
              </Button>
            </Grid>

            <Grid item xs={2} className="text-left ">
              <FormControlLabel
                control={
                  <Switch
                    disabled={!this.state.hasRights}
                    checked={this.state.clientData.gdprAgreement}
                    onChange={(e: any) => this.handleIsActiveAgreement(e)}
                    color="primary"
                  />
                }
                label={this.translatorService.Tranlate('CLIENT_DATA_FORM_SIGNED', 'Semnat')}
                labelPlacement="end"
                className="mt-3 ml-0 mr-0"
              />
            </Grid>

            <Grid item xs={8} className="text-right">
              {this.renderAttachmentBox()}
            </Grid>
          </Grid>
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendeClientDataForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabClientDataFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabClientDataForm as any));
