import React, { Fragment, useState } from 'react';
import { Box } from '@material-ui/core';
import { useAppSelector } from '../../store/configureStore';

import DetailsValuationContainer from './DetailsValuationContainer';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Loader from '../Loader';

const EvaluationPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const language = useAppSelector((state) => state.app?.language);

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box
            style={{
              width: '1000px'
            }}
          >
            <DetailsValuationContainer
              workflowForm={null}
              showCostTypes={false}
              vehicleInfoDisabled={false}
            />
          </Box>
        </div>
      )}
    </Fragment>
  );
};

export default EvaluationPage;
