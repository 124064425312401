import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { ListItem, Button, Collapse } from '@material-ui/core';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const CustomRouterLink = forwardRef(function CustomLink(props, ref) {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

const SidebarMenuListItem = (props) => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    redirectTo,
    open: openProp,
    label: Label,
    needToken,
    user,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  let paddingLeft = 14;

  if (depth > 0) {
    paddingLeft = 32 + 20 * depth;
  }

  const style = {
    paddingLeft
  };

  if (children) {
    return (
      <ListItem {...rest} className={clsx('app-sidebar-item', className)} disableGutters>
        <Button
          color="primary"
          className={clsx('app-sidebar-button', { active: open }, 'depth-0')}
          onClick={handleToggle}
          style={style}
        >
          {Icon && <Icon className="app-sidebar-icon" />}
          <span>{title}</span>
          {open ? (
            <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
          ) : (
            <ExpandLessIcon
              className="sidebar-expand-icon sidebar-expand-icon-rotate"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem {...rest} className={clsx('app-sidebar-item', className)} disableGutters>
        {redirectTo ?
          <Button
            activeClassName="active-item"
            color="primary"
            disableRipple
            variant="text"
            className={clsx('app-sidebar-button-wrapper', `depth-${depth}`)}
            exact
            style={style}
            onClick={() => window.open(`${redirectTo}${needToken ? `access_token=${user.access_token}`:''}`)}
          >
            {Icon && <Icon className="app-sidebar-icon" />}
            {title}
            {Label && (
              <span className="menu-item-label">
                <Label />
              </span>
            )}
          </Button> :<Button
            activeClassName="active-item"
            color="primary"
            disableRipple
            variant="text"
            className={clsx('app-sidebar-button-wrapper', `depth-${depth}`, {
              'active-item': href == '/agenda' && location.pathname.includes('/appointment/'),
            })}              
            component={CustomRouterLink}
            exact
            style={style}
            to={href}
        >
          {Icon && <Icon className="app-sidebar-icon" />}
          {title}
          {Label && (
            <span className="menu-item-label">
              <Label />
            </span>
          )}
        </Button>
  }
      </ListItem>
    );
  }
};

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  redirectTo:PropTypes.string,
  needToken:PropTypes.bool,
};

SidebarMenuListItem.defaultProps = {
  depth: 0,
  open: false
};

export default SidebarMenuListItem;
