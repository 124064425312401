import { isNil } from 'ramda';
import React, { useContext, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApplicationContext } from '../../../context/Contexts';

import { DashboardItem } from '../../../interfaces/Dashboard/DashboardItem';

interface Props {
  title: string;
  selectedId: number;
  items: DashboardItem[];
  handleBarClick(dataIndex?: number): void;
}

const VehicleBarChart: React.FC<Props> = ({ title, selectedId, items = [], handleBarClick }) => {
  const context = useContext(ApplicationContext);

  const numberOfVehicles = context.translatorService.Tranlate(
    'DASHBOARD_VEHICLE_COUNT',
    'Numar vehicule'
  );

  const maxValue = Math.max(...items.map((item) => item.value));

  const series = useMemo(() => {
    return [
      {
        name: numberOfVehicles,
        data: items.map((i) => i.value)
      }
    ];
  }, [items, numberOfVehicles]);

  const options = useMemo(() => {
    const categories = items.map((i) => i.displayName);
    return {
      chart: {
        type: 'bar',
        events: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            if (!isNil(config.dataPointIndex)) {
              handleBarClick(config.dataPointIndex);
            }
          }
        },
        toolbar: {
          show: false
        },
        selection: {
          enabled: true,
          type: 'x',
          fill: {
            color: '#24292e',
            opacity: 0.1
          }
        }
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          distributed: true
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories,
        tickAmount: maxValue === 1 || (maxValue === 2 && maxValue),
        labels: {
          formatter: function (val: number) {
            return val.toFixed(0);
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return val;
          }
        }
      }
    };
  }, [items, handleBarClick]);

  const chartHeight = useMemo(() => {
    return items.length * 30 + 100;
  }, [items.length]);

  const selectedItemName = useMemo(() => {
    const item = items.find(({ id }) => id === selectedId);

    return item?.displayName;
  }, [items, selectedId]);

  return (
    <div>
      <h6 className="mt-3 ml-3 mb-0">
        {!selectedItemName ? title : `${title}: ${selectedItemName}`}
      </h6>
      <ReactApexChart options={options} series={series} type="bar" height={chartHeight} />
    </div>
  );
};

export default VehicleBarChart;
