import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, Grid, Paper } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { CaseParameterHistory, CaseTariffValueHistory } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { ParametreCode, ReferentialCode } from '../../helpers/Constants';
import { AppUser } from '../../interfaces/AppUser';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import NumberFormat from 'react-number-format';
import MaterialTable, { Column, Icons } from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import { VehicleOption } from '../../interfaces/Vehicle';
import * as R from 'ramda';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

interface ICaseDetailsCaseTabCarRepairCostFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isUserHidden: boolean;
  caseParameters: Array<CaseParameterHistory>;
  caseTariffValues: CaseTariffValueHistory[];
  tariffTypeGroupList: IReferential[];
}

export interface ExternalCaseDetailsCaseTabCarRepairCostFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabCarDetailsExtendedFormProps =
  ExternalCaseDetailsCaseTabCarRepairCostFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabCarRepairCostForm extends React.PureComponent<
  ICaseDetailsCaseTabCarDetailsExtendedFormProps,
  ICaseDetailsCaseTabCarRepairCostFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  defaultBrand = {
    name: 'All', //this.translatorService.Tranlate("SELECT_ALL_OPTION", "Toate"),
    displayName: 'All', //this.translatorService.Tranlate("SELECT_ALL_OPTION", "Toate"),
    id: -1,
    isActive: true
  } as IReferential;

  state = {
    isLoading: false,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    caseParameters: [] as CaseParameterHistory[],
    caseTariffValues: [],
    tariffTypeGroupList: []
  } as ICaseDetailsCaseTabCarRepairCostFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const caseParametersList = this.props.caseSettingsState.case!.caseParameters;
    this.setState(
      {
        isLoading: true,
        caseParameters: caseParametersList
      },
      async () => {
        await this.loadCaseClientDataForm();
      }
    );
  }

  loadCaseClientDataForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    const tariffGroupTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.TariffTypeGroup
    );
    const tariffGroupTypeList = await this.appReferentialService.Get(tariffGroupTypeRef!.baseUrl);

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    this.setState({
      isLoading: false,
      hasRights: hasRights,
      isUserHidden: !hasRights,
      caseTariffValues: this.props.caseSettingsState.case!.caseTariff.caseTariffValuesHistory,
      tariffTypeGroupList: tariffGroupTypeList.filter((item) => item.isActive === true)
    });
  };

  renderTariffGroupTypes = () => {
    const tariffGroupTypes = [] as any[];
    for (const tariffGroupType of this.state.tariffTypeGroupList) {
      tariffGroupTypes.push(
        <div key={tariffGroupType.id} className="pt-4">
          <h4
            style={{ color: 'white' }}
            className="bg-first text-left mt-2 p-1 custom-timeline-list-label mb-2 font-weight-bold"
          >
            {tariffGroupType.displayName}
          </h4>
          <Grid container className="mx-0">
            {this.renderCaseTariffValues(tariffGroupType.id)}
          </Grid>
        </div>
      );
    }
    return tariffGroupTypes;
  };

  renderCaseTariffValues = (tariffGroupTypeId: number) => {
    const caseTariffValuess = [] as any[];
    const caseTariffValues = this.state.caseTariffValues.filter(
      (item) => item.tariffType!.tariffTypeGroupId === tariffGroupTypeId
    );
    this.getDistinctTariffTypes(caseTariffValues).forEach((tt) => {
      const tariffValues = caseTariffValues.filter((item) => item.tariffTypeId === tt!.id);

      caseTariffValuess.push(
        <Grid item xs={6} key={tt!.id} className="p-3" style={{ color: '#7a7b97' }}>
          <h6 className="text-left mt-2"> {tt!.displayName} </h6>
          {this.renderTariffValues(tariffValues)}
        </Grid>
      );
    });
    return caseTariffValuess;
  };

  getDistinctTariffTypes = (tariffValues: CaseTariffValueHistory[]) => {
    return tariffValues
      .map((item) => item.tariffType)
      .filter((tt, index, arr) => arr.findIndex((i) => i!.id === tt!.id) == index);
  };

  onHandleTariffInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const index = this.state.caseTariffValues.findIndex((value) => value.id === id);
    const newCaseTariffValues = [...this.state.caseTariffValues];

    newCaseTariffValues[index].value = parseInt(e.currentTarget.value);

    this.setState({
      caseTariffValues: newCaseTariffValues
    });
  };

  renderTariffValues = (list: CaseTariffValueHistory[]) => {
    list = list.sort((a: CaseTariffValueHistory, b: CaseTariffValueHistory) => {
      return this.compareName(a.brand!, b.brand!);
    });
    return list.map((item, index) => {
      return (
        <div key={item.id}>
          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id={'tariff-' + item.id}
            className="m-0 mt-2 p-0 pr-1 mr-2"
            name="tariff"
            placeholder={''}
            value={item.value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.onHandleTariffInputChange(e, item.id)
            }
            validators={['required']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
            label={
              (item.brand === null ? this.defaultBrand.displayName : item.brand.displayName) +
              ' ' +
              this.renderVehicleAge(item)
            }
            InputProps={{
              inputComponent: this.NumberFormatCustom
            }}
          />
        </div>
      );
    });
  };

  compareName = (a: IReferential, b: IReferential) => {
    const nameA = a === null ? '' : a.displayName!.toUpperCase();
    const nameB = b === null ? '' : b.displayName!.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  renderVehicleAge = (tv: CaseTariffValueHistory) => {
    if (tv.startVehicleYear === null && tv.endVehicleYear === null) {
      return '';
    }
    const start = tv.startVehicleYear === null ? '0' : tv.startVehicleYear;
    const end = tv.endVehicleYear === null ? '' : tv.endVehicleYear;

    return '(' + start + '<' + end + ')';
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  submitData = async (e: any) => {
    // let newVehicle =  { ...this.state.carDetailsExtendedData } as Vehicle;
    try {
      let caseParameterMandatoryField = false;
      this.state.caseParameters.forEach((caseParameter) => {
        if (caseParameter.parameter?.code === ParametreCode.TVA) {
          caseParameter.value = caseParameter.value.trim();
          if (R.isEmpty(caseParameter.value)) {
            caseParameterMandatoryField = true;
            this.props.enqueueSnackbar(this.translatorService.Tranlate('MANDATORY_VAT', 'TVA-ul este obligatoriu!'), {
              variant: 'warning'
            });
          } 
        }
        if (caseParameter.parameter?.code === ParametreCode.CURRENCY) {
          caseParameter.value = caseParameter.value.trim();
          if (R.isEmpty(caseParameter.value)) {
            caseParameterMandatoryField = true;
            this.props.enqueueSnackbar(this.translatorService.Tranlate('MANDATORY_CURRENCY', 'Moneda este obligatorie!'), {
              variant: 'warning'
            });
          } 
        }
      });

      if (caseParameterMandatoryField) {
        return;
      }

      this.setState({ executing: true });

      await this.caseService.SaveParameterHistory(
        this.props.caseSettingsState.case!.id,
        this.state.caseParameters
      );

      await this.caseService.SaveCaseTariffHistory(
        this.props.caseSettingsState.case!.id,
        this.state.caseTariffValues
      );

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      this.state.caseParameters.forEach((element) => {
        this.props.UpdateCaseParameters(element);
      });

      this.state.caseTariffValues.forEach((element) => {
        this.props.UpdateCaseTariffvalue(element);
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({
        executing: false
      });
    }
  };

  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        deleteTooltip: this.translatorService.Tranlate('DELETE', 'Sterge'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza'),
          deleteText: this.translatorService.Tranlate(
            'DELETE_TEXT',
            'Sigur doriti sa stergeti aceasta inregistrare?'
          )
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };

  getCarRepairCostParametersColumns = () => {
    return [
      {
        field: 'parameter.displayName',
        title: this.translatorService.Tranlate('CAR_REPAIR_COST_PARAMETER', 'Parametru'),
        searchable: true,
        editable: 'never'
      } as Column<any>,
      {
        field: 'value',
        title: this.translatorService.Tranlate('CAR_REPAIR_COST_VALUE', 'Valoare'),
        searchable: true
      } as Column<any>
    ];
  };

  parametersOnRowAdd = (newData: VehicleOption): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        // newData.organizationId = this.props.organizationId;

        // await this.organizationService.AddOrganizationBankAccount(newData);
        // await this.loadData();
        // this.setState({carStandardOptions: [
        //     ...this.state.carStandardOptions,
        //     {
        //         id: 0,
        //         vehicleId: this.state.carDetailsExtendedData.id,
        //         code: newData.code,
        //         name: newData.name
        //     }
        // ]
        // })

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });
  };

  parametersOnRowUpdate = (newData: any, oldData?: any): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          if (newData.parameter?.code === ParametreCode.TVA) {
            newData.value = newData.value.trim();
            if (R.isEmpty(newData.value)) {
              this.props.enqueueSnackbar(this.translatorService.Tranlate('MANDATORY_VAT', 'TVA-ul este obligatoriu!'), {
                variant: 'warning'
              });
              reject();
            } 
          }
          if (newData.parameter?.code === ParametreCode.CURRENCY) {
            newData.value = newData.value.trim();
            if (R.isEmpty(newData.value)) {
              this.props.enqueueSnackbar(this.translatorService.Tranlate('MANDATORY_CURRENCY', 'Moneda este obligatorie!'), {
                variant: 'warning'
              });
              reject();
            } 
          }
         
          const items = [...this.state.caseParameters];

          const newItemIndex = items.findIndex((i) => i.id === oldData.id);

          items[newItemIndex].value = newData.value;

          this.setState({ caseParameters: items });

          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  parametersOnRowDelete = (oldData: any): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          // await this.organizationService.RemoveOrganizationBankAccount(oldData.id);
          //   await this.loadData();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  public renderCarRepairCostParametersTable = () => {
    return (
      <MaterialTable
        icons={tableIcons}
        title={''}
        columns={this.getCarRepairCostParametersColumns()}
        data={this.state.caseParameters}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          paging: false,
          search: false
        }}
        localization={this.getLocalization()}
        editable={{
          // isEditable: rowData => rowData.parameterCode !== 'LANG',
          onRowUpdate: (newData: any, oldData?: any) => this.parametersOnRowUpdate(newData, oldData)
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
      />
    );
  };

  renderForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitData(e);
        }}
      >
        <div className="m-3 text-center">
          <div className="parameters">
            <h5 className="bg-first text-left mt-2 p-1" style={{ color: 'white' }}>
              {this.translatorService.Tranlate('CAR_REPAIR_COST_PARAMETERS', 'Parametri')}
            </h5>

            {!this.state.isLoading ? this.renderCarRepairCostParametersTable() : null}
          </div>

          <div className="workmanship">
            {!this.state.isLoading ? this.renderTariffGroupTypes() : null}
          </div>
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? <div className="text-center m-2">{this.renderForm()}</div> : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabCarRepairCostFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabCarRepairCostForm as any));
