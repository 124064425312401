import pptxgen from "pptxgenjs";
import moment from "moment";
import { IPptService } from './Interfaces/IPptService';
import { DashboardFiltersState } from '../interfaces/Dashboard/DashboardFiltersState';
import { numberFormat } from "../utils/numberFormat";
import { ITranslatorService } from "./Interfaces/ITranslatorService";
import{
  titleOptions, titlePosition, rectangletextOpts, cellOpt, tableOptions,
  logos, salesChannelOptions, rectangleOptions, horizontalChartOptions, sallerTableOptions, verticalCharOptions, verticalChar2Options,
  circleChartOptions, scatterChartOptions, defaultChartPosition, defaultChartColors, recentAcquisitionLogos, recentAcquisitionOptions, tabNameOptions, tabNamePosition, salesOptions
} from '../helpers/PptConstants'
import { SalesVehicle } from "../interfaces/Dashboard/SalesVehicle";
import { faTheRedYeti } from "@fortawesome/free-brands-svg-icons";


export class PptService implements IPptService {

  private translatorService: ITranslatorService;
  private pptx: any;
  private pptData: any;

  constructor(
    translatorService: ITranslatorService,
    pptData?:any,
  ) {
    this.translatorService = translatorService;
    this.pptx = new pptxgen();
    this.pptData = pptData;
  }

  addStockStructure = (slide: any) => {
    slide.addText([{
      text: `${this.translatorService.Tranlate('DASHBOARD_INVENTORY_STRUCTURE', 'Structura stoc la data de')} ${moment().format('DD-MM-YYYY')}`, 
      options: titleOptions 
    }], titlePosition);

    slide.addText(`Total stoc\n${this.pptData.count}`, { ...rectangletextOpts, x: 1.0 });
    slide.addText(`Rulaj mediu\n${this.pptData.averageMileage} km`, { ...rectangletextOpts, x: 4.0 });
    slide.addText(`Varsta medie\n${this.pptData.averageVehicleAge} ani`, { ...rectangletextOpts, x: 7.0 });
  }

  acquisitionMainCard = (slide: any, includeVat?:boolean)=>{
    const { purchasesCompleted, purchasesInProgress, purchasesFailed, newVehiclesSoldCount } = this.pptData;
    const purchasesCompletedPrice = includeVat
      ? purchasesCompleted.amountInclVat
      : purchasesCompleted.amount;

    const purchasesInProgressPrice = includeVat
      ? purchasesInProgress.amountInclVat
      : purchasesInProgress.amount;

    const purchasesFailedPrice = includeVat ? purchasesFailed.amountInclVat : purchasesFailed.amount;

    const averagePurchasePrice =
      purchasesCompleted.count == 0 ? 0 : purchasesCompletedPrice / purchasesCompleted.count;

    const textOpts = {
      y: 1.0,
      w: 3,
      h: 1.5,
      ...rectangleOptions
    }

    slide.addText(`${this.translatorService.Tranlate(
      'DASHBOARD_ACQUISITION_FINALISED',
      'Finalizate cu succes')
      }\n${purchasesCompleted.count}\n${`${numberFormat(purchasesCompletedPrice)} EUR`}`, { ...textOpts, x: 1.5 });

    slide.addText(`${this.translatorService.Tranlate(
      'DASHBOARD_ACQUISITION_IN_PROGRESS',
      'In curs de achizitie')
      }\n${purchasesInProgress.count}\n${`${numberFormat(purchasesInProgressPrice)} EUR`}`, { ...textOpts, x: 6.0 });

    slide.addText(`${this.translatorService.Tranlate(
      'DASHBOARD_ACQUISITION_FAILED',
      'Achizitii esuate')
      }\n${purchasesFailed.count}\n${`${numberFormat(purchasesFailedPrice)} EUR`}`, { ...textOpts, y: 3, x: 0.3 });
    slide.addText(`${this.translatorService.Tranlate(
        'DASHBOARD_ACQUISITION_AVERAGE_PRICE',
        'Pret mediu achizitie')
      }\n${`${numberFormat(averagePurchasePrice)} EUR`}`, { ...textOpts, y: 3, x: 3.5 });
    slide.addText(`${this.translatorService.Tranlate(
      'DASHBOARD_ACQUISITION_NEW_VEHICLES_SOLD',
      'Vehicule noi vandute'
    )
      }\n${newVehiclesSoldCount}`, { ...textOpts, y: 3, x: 6.8 });
  }

  salesTopSeller = (slide:any) =>{
    slide.addText([{ text: this.translatorService.Tranlate('DASHBOARD_TOP_SALE_PERSONS', 'Top vanzatori'), options: titleOptions }], titlePosition);
    const rows = [];
    if (this.pptData.topSellersBasedOnClosedFolders?.length > 0) {
      this.pptData.topSellersBasedOnClosedFolders.forEach((item: any, index: number) => {
        rows.push([{ text: index + 1, options: { ...cellOpt} }, { text: `${item.organizationName}\n${item.buyerName}`, options: cellOpt }])
      })
      slide.addTable(rows, { ...sallerTableOptions });
    }
  }

  salesChannel = (slide:any) =>{
    let pos = 1;
    if (this.pptData.salesChannels?.length > 0){
      this.pptData.salesChannels.forEach((channel:any, index:number)=>{
        slide.addImage({ path: logos[index].image, ...logos[index].dimension, y: 0.3, align: "center" });
        slide.addText(this.translatorService.Tranlate('DASHBOARD_TOTAL_ADS', 'Total anunturi'), { ...salesChannelOptions, x: pos, y:1 });
        slide.addText(channel.totalCount, { ...salesChannelOptions, x: pos ,y:2});
        slide.addText(this.translatorService.Tranlate('DASHBOARD_ACTIVE_ADS', 'Active'), { ...salesChannelOptions, x: pos, y:3 });
        slide.addText(channel.activeCount, { ...salesChannelOptions, x: pos, y:4 });
        pos = pos + 2 + 1;
      })

    }
  }

  recentAcquisitions = (data: any) =>{
    const slide = this.pptx.addSlide();
    slide.addText([{ text: this.translatorService.Tranlate('DASHBOARD_ACQUISITION_RECENT', 'Achizitii recente'), options: titleOptions }], titlePosition);
    let pos = 0.5;
    if (data?.length > 0){
      data.forEach((recentAcquisition:any, index:number)=>{
        recentAcquisition.makeName = recentAcquisition.makeName ? recentAcquisition.makeName : '';
        recentAcquisition.modelName = recentAcquisition.modelName ? recentAcquisition.modelName : '';
        recentAcquisition.equipmentVersion = recentAcquisition.equipmentVersion ? recentAcquisition.equipmentVersion : '';
        recentAcquisition.makeYear = recentAcquisition.makeYear ? recentAcquisition.makeYear : '';
        recentAcquisition.fuelType = recentAcquisition.fuelType ? recentAcquisition.fuelType : '';
        recentAcquisition.transmissionType = recentAcquisition.transmissionType ? recentAcquisition.transmissionType: '';

        slide.addImage({ data: recentAcquisition.base64Value, ...recentAcquisitionLogos[index].dimension, y: 0.6, align: "center" });
        slide.addText(recentAcquisition.makeName  + " " + recentAcquisition.modelName, { ...recentAcquisitionOptions, x: pos, y: 2.3 });
        slide.addText(recentAcquisition.equipmentVersion + '/' + recentAcquisition.makeYear + '/' + recentAcquisition.fuelType + '/' + recentAcquisition.transmissionType, { ...recentAcquisitionOptions, x: pos ,y: 2.8});
        slide.addText("Pret achizitie:"   +  Math.round(recentAcquisition.purchasePriceInclVat) + 'Euro', { ...recentAcquisitionOptions, x: pos, y: 3.2 });
        slide.addText(this.translatorService.Tranlate('DASHBOARD_BUYER_NAME', 'Cumparator') + ":" +  recentAcquisition.buyerName, { ...recentAcquisitionOptions, x: pos, y: 3.7 });
        slide.addText(this.translatorService.Tranlate('DASHBOARD_ORGANIZATION', 'Organizatie') + ":" +  recentAcquisition.organizationName, { ...recentAcquisitionOptions, x: pos, y: 4.2 });
        pos = pos + 2 + 1 + 0.5;
      })

    }
  }

  getFilterName = (id:number, items:any, name:string) =>{
    const indexMake = items.findIndex((item: any) => item.id === id);
    return indexMake >= 0 ? `${name}: ${items[indexMake].displayName}` : '';
  } 

  salesProfit = () => {
    const slide = this.pptx.addSlide();
    const textOpts = {
      y: 1.0,
      w: 2.5,
      h: 1.5,
      ...rectangleOptions
    }
    slide.addText(`${this.translatorService.Tranlate('DASHBOARD_SALES_WARRANTIES','Garantii vandute')
      }\n${numberFormat(this.pptData.guaranteeCount)}`, { ...textOpts, x: 0.5 });

    slide.addText(`${this.translatorService.Tranlate('DASHBOARD_SALES_TABLE_TITLE', 'Vanzari')
      }\n${numberFormat(this.pptData.soldVehicleInformation.amountInclVat, 0, undefined, ' EUR')}\n${numberFormat(this.pptData.addsInformation.amountInclVat, 0, undefined, ' EUR')}`, { ...textOpts, x: 3.5 });

    slide.addText(`${this.translatorService.Tranlate('DASHBOARD_PROFIT_VALUE', 'Profit')
      }\n${numberFormat(this.pptData.soldVehicleInformation.profitValue,  0, undefined, ' EUR')}\n${numberFormat(this.pptData.addsInformation.profitValue, 0, undefined, ' EUR')}`, { ...textOpts, x: 6.5 });

    slide.addText(`${this.translatorService.Tranlate('DASHBOARD_PROFIT_RATIO', 'PROFIT %')
      }\n${numberFormat(this.pptData.soldVehicleInformation.profitPercent)}\n${numberFormat(this.pptData.addsInformation.profitPercent)}`, { ...textOpts, y: 3, x: 0.5 });

    slide.addText(`${this.translatorService.Tranlate('DASHBOARD_QUANTITY','Nr. Unitati vandute')
      }\n${numberFormat(this.pptData.soldVehicleInformation.count)}\n${numberFormat(this.pptData.addsInformation.count) }`, { ...textOpts, y: 3, x: 3.5 });

    slide.addText(`${this.translatorService.Tranlate('DASHBOARD_SALES_COST', 'Cost')
      }\n${numberFormat(this.pptData.soldVehicleInformation.damageValue,  0, undefined, ' EUR')}\n${numberFormat(this.pptData.addsInformation.damageValue,  0, undefined, ' EUR') }`, { ...textOpts, y: 3, x: 6.5 });
  }

  getFiltersInfo = (slide:any,filters:any, type:string) =>{
    const textOpts = { color: "363636", fontSize: 15 }
    let firstPosition = 1;
    const textPostion = { x: 0.5, y: firstPosition, w: "90%" }

    if (filters.startDate)
      slide.addText([{
        text: `${this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_FROM', 'De la')} ${moment(filters.startDate).format('DD-MM-YYYY')} ${this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_TO', 'Pana la') } ${moment(filters.endDate).format('DD-MM-YYYY')}`,
        options: textOpts
      }], {...textPostion}); 

    if (filters.makeId){
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.makeId, type === 'vehicle' ? this.pptData.byMaker : this.pptData.purchasedVehiclesByMaker, this.translatorService.Tranlate('DASHBOARD_MAKE', 'Producator')),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
    }

    if (filters.offerTypeId) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.makeId, this.pptData.purchasedVehiclesByOfferType, this.translatorService.Tranlate('DASHBOARD_OFFER_TYPE', 'TIP Oferta')),
        options: textOpts
      }], { ...textPostion, y: firstPosition });
    }

    if (filters.modelId) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.modelId, this.pptData.byModel, this.translatorService.Tranlate('DASHBOARD_MODEL', 'Model')),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
    }

    if (filters.fuelId) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.fuelId, this.pptData.byFuelType, this.translatorService.Tranlate('DASHBOARD_FUEL', 'Combustibil')),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
    }

    if (filters.includeVat){
      firstPosition += 0.3;
      slide.addText([{
        text: this.translatorService.Tranlate('DASHBOARD_PRICES_WITH_VAT_EUR', 'Preturi in EUR cu TVA'),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
    } else if (filters.includeVat == false){
      firstPosition += 0.3;
      slide.addText([{
        text: this.translatorService.Tranlate('DASHBOARD_PRICES_WITHOUT_VAT_EUR', 'Preturi in EUR fara TVA'),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
    }

    if (filters.statusId) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.statusId, this.pptData.byStatusType, this.translatorService.Tranlate(
          'DASHBOARD_TECHNICAL_INVENTORY_STATE',
          'Stare tehnica stoc'
        )),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 

    }

    if (filters.technicalConditionId) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.technicalConditionId, this.pptData.byInOutAndTechnicalCondition, this.translatorService.Tranlate('DASHBOARD_INVENTORY_DETAILS', 'Detalii stoc')),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
    }

    if (filters.tractionTypeId) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.tractionTypeId, this.pptData.byTractionType, this.translatorService.Tranlate('DASHBOARD_TRACTION', 'Tractiune')),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
    }

    if (filters.transmissionTypeId) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.transmissionTypeId, this.pptData.byTransmissionType, this.translatorService.Tranlate('DASHBOARD_TRANSMISSION', 'Transmisie')),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
    }

    if (filters.inventoryAgeFilter) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.inventoryAgeFilter, this.pptData.byInventoryAge, this.translatorService.Tranlate(
          'DASHBOARD_AVERAGE_INVENTORY_AGE',
          'Vehcime medie stoc'
        )),
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
      
    }

    if (filters.clientTypeId) {
      firstPosition += 0.3;
      slide.addText([{
        text: this.getFilterName(filters.clientTypeId, type === 'sales' ? this.pptData.vehiclesGroupedByClientType : this.pptData.purchasedVehiclesByClientType, this.translatorService.Tranlate('DASHBOARD_CLIENT_TYPE', 'Tip client')),
        options: textOpts
      }], { ...textPostion, y: firstPosition });

    }

    if (filters.salePersonId) {
      firstPosition += 0.3;
      const indexMake = this.pptData.topSellersBasedOnClosedFolders.findIndex((item: any) => item.id === filters.salePersonId);
      if (indexMake >=0){
        const seller = this.pptData.topSellersBasedOnClosedFolders[indexMake];
        slide.addText([{
          text: `${this.translatorService.Tranlate('DASHBOARD_TOP_SALE_PERSONS', 'Top vanzatori')}: ${seller.organizationName}, ${seller.buyerName}`,
          options: textOpts
        }], { ...textPostion, y: firstPosition });
      }
    }

    if (filters.selectedOrganisations?.length > 0)
      firstPosition += 0.6;
      slide.addText([{
        text: `${this.translatorService.Tranlate('DASHBOARD_ORGANIZATION', 'Organizatie')}: ${filters.selectedOrganisations.map((org: any) => ` ${org.name}`)}`,
        options: textOpts
      }], { ...textPostion, y: firstPosition }); 
  }

  public addFirstPage = (filters: DashboardFiltersState, type:string) => {
    const firstSlide = this.pptx.addSlide();
    switch(type){
      case 'vehicle': 
        firstSlide.addText([{
          text: this.translatorService.Tranlate('MENU_VEHICLES', 'Stoc vehicule'),
          options: tabNameOptions
        }], tabNamePosition);
        break;
      case 'acquisitions':
        firstSlide.addText([{
          text: this.translatorService.Tranlate('DASHBOARD_ACQUISITION_TAB', 'Achizitii'),
          options: tabNameOptions
        }], tabNamePosition);
        break;
      case 'sales':
        firstSlide.addText([{
          text: this.translatorService.Tranlate('DASHBOARD_SALES_TABLE_TITLE', 'Vanzari'),
          options: tabNameOptions
        }], tabNamePosition);
        break;       
      default:
        break;
    }

    const slide = this.pptx.addSlide();
    slide.addText([{
      text: this.translatorService.Tranlate('DASHBOARD_APPLIED_FILTERS', 'Filtre aplicate1'),
      options: titleOptions
    }], titlePosition);

    this.getFiltersInfo(slide, filters, type);

    const secondSlide = this.pptx.addSlide();

    switch(type){
      case 'vehicle': 
        this.addStockStructure(secondSlide);
        break;
      case 'acquisitions':
        this.acquisitionMainCard(secondSlide, filters.includeVat)
        break;
      case 'sales':
        this.salesTopSeller(secondSlide);
        this.salesChannel(this.pptx.addSlide());
        break;       
      default:
        break;
    }
  }

  getDataForChart = (data:any, formatData?:any) =>{
    const dataChart = { labels: [], values: [] };
    let maxValue = 0;
    data.forEach((item: any) => {
      const val = formatData ? item[formatData.value] : item.value;
      if (val > maxValue) {
        maxValue = item.value;
      }
      dataChart.labels.push(formatData ? item[formatData.label] : item.displayName);
      dataChart.values.push(formatData ? item[formatData.value] : item.value)
    })
    return { maxValue: maxValue, data: dataChart }
  }

  public addHorizontalChart = (charData:any,name:string) =>{
    if(!(charData?.length > 0)){
      return;
    }
    const slide = this.pptx.addSlide();
    slide.addText([{ text: name, options: titleOptions }], titlePosition);
    const infoChart = this.getDataForChart(charData);
  
    slide.addChart(
      this.pptx.charts.BAR,
      [
        {
          name: name,
          labels: [...infoChart.data.labels],
          values: [...infoChart.data.values],
        },
      ],
      {
        ...horizontalChartOptions,
        valAxisMaxVal: infoChart.maxValue + 20,
    
      }
    );
}

  public addTable = (tableFormat: [{ title: string, field?: string, render?(rowData: any): string | 0  }],tableData:any, name:string) =>{
    if (!(tableData?.length > 0)) {
      return;
    }
    const slide = this.pptx.addSlide();
    slide.addText([{ text: name, options: titleOptions }], titlePosition);
    const rows = [];
    rows.push(tableFormat.map((item) => { return { text: item.title, options: { color: '000000', fontSize: 14, bold: true } }}));
    tableData.forEach((val:any)=>{
      rows.push(tableFormat.map((item) => { return { text: item.render ? item.render(val) : val[item.field], options: { color: "000000", fontSize: 12 } }}))
    })
    slide.addTable(rows, { ...tableOptions });

  }

  public addVerticalChart = (charData: any, name: string, customLabels?:any) =>{
    if (!(charData?.length > 0)) {
      return;
    }
    const slide = this.pptx.addSlide({ sectionTitle: "Charts" });
    const infoChart = this.getDataForChart(charData);
    slide.addChart(
      this.pptx.charts.BAR, 
      [
        {
          name: name,
          labels: customLabels ? [...customLabels] :[...infoChart.data.labels],
          values: [...infoChart.data.values],
        },
      ], 
      {
        ...verticalCharOptions,
        title: name,
        valAxisMaxVal: infoChart.maxValue,
      });
  
  }

  public addVerticalChart2 = (data:any, name:string, formatData:any) =>{
    if (!(data?.length > 0)) {
      return;
    }
    const slide = this.pptx.addSlide();
    const firstCategoryChart = this.getDataForChart(data, formatData[0]);
    const secondCategoryChart = this.getDataForChart(data, formatData[1]);
    slide.addChart(
      this.pptx.charts.BAR, 
      [
        {
          name: 'Category 1',
          labels: [...firstCategoryChart.data.labels],
          values: [...firstCategoryChart.data.values],
        },
        {
          name: 'Category 2',
          labels: [...secondCategoryChart.data.labels],
          values: [...secondCategoryChart.data.values],
        }
      ], 
      {
        ...verticalChar2Options,
        title: name,
        valAxisMaxVal: firstCategoryChart.maxValue > secondCategoryChart.maxValue ? firstCategoryChart.maxValue : secondCategoryChart.maxValue,
      });
  }

  public addCircleChart = (charData:any, name:string) =>{
    if (!(charData?.length > 0)) {
      return;
    }
    const slide = this.pptx.addSlide();
    const infoChart = this.getDataForChart(charData);
    slide.addChart(
      this.pptx.charts.PIE,
      [{
      name: name,
      labels: [...infoChart.data.labels],
      values: [...infoChart.data.values],
      }], 
      {
        ...circleChartOptions,
        title: name
      });
  }

  public addCarBox = () =>{
    const slide = this.pptx.addSlide();
  }

  public addScatterChar = (data: any, name: string, formatData: any, firstCategoryName: string, secondCategoryName: string) =>{
    const slide  = this.pptx.addSlide();
    const firstCategoryChart = this.getDataForChart(data, formatData[0]);
    const secondCategoryChart = this.getDataForChart(data, formatData[1]);
    console.log(firstCategoryChart);
    slide.addChart(this.pptx.charts.LINE, [
      {
        name: firstCategoryName,
        labels: [...firstCategoryChart.data.labels],
        values: [...firstCategoryChart.data.values],
      },
      {
        name: secondCategoryName,
        labels: [...secondCategoryChart.data.labels],
        values: [...secondCategoryChart.data.values],
      }
    ], { ...scatterChartOptions});
  }

  public lastSalePage(data: SalesVehicle) {
    const slide = this.pptx.addSlide();
    console.log(data);
    let firstPosition = 1;
    const textPostion = { x: 0.5, y: firstPosition, w: "90%",  fontSize: 14, align: 'center' };

    slide.addText(`${data?.makeName} ${data?.modelName}`, { ...textPostion});

    firstPosition += 0.3;
    slide.addText(`${this.translatorService.Tranlate(
      'DASHBOARD_SALES_TABLE_QUANTITY',
      'Numar de unitati'
    )}: ${data?.numberOfUnitSold}`,  { ...textPostion, y: firstPosition });

    firstPosition += 0.3;
    slide.addText(`${this.translatorService.Tranlate(
      'DASHBOARD_AVERAGE_VEHICLE_AGE',
      'Varsta medie'
    )}: ${numberFormat(data?.avgOfYear, 1)}`,  { ...textPostion, y: firstPosition })


    for (let i = 0; i <  data?.groupedByFuelType.length; i++) {
      firstPosition += 0.3;
      slide.addText(`${data?.groupedByFuelType[i].name} ${Math.round(data?.groupedByFuelType[i].percentValue)} % (${data?.groupedByFuelType[i].count})`,  { ...textPostion, y: firstPosition });
    }

    for (let i = 0; i <  data?.groupedByTransmissionType.length; i++) {
      firstPosition += 0.3;
      slide.addText(`${data?.groupedByTransmissionType[i].name} ${Math.round(data?.groupedByTransmissionType[i].percentValue)} % (${data?.groupedByTransmissionType[i].count})`,  { ...textPostion, y: firstPosition });
    }

    firstPosition += 0.3;
    slide.addText( `${this.translatorService.Tranlate(
      'DASHBOARD_AVG_SALE_VALUE',
      'Pret mediu de vanzare'
    )}: ${numberFormat(data?.avgSoldValue)} Euro`,  { ...textPostion, y: firstPosition });

    firstPosition += 0.3;
    slide.addText(`${this.translatorService.Tranlate(
      'DASHBOARD_AVG_DAMAGE_VALUE',
      'Costuri medii'
    )}: ${numberFormat(data?.avgDamageValue)} Euro`,  { ...textPostion, y: firstPosition });

    firstPosition += 0.3;
    slide.addText(`${this.translatorService.Tranlate('DASHBOARD_PROFIT_VALUE', 'Profit')}: ${Math.round(data?.profitPercent)}%`,  { ...textPostion, y: firstPosition, bold : true, fontSize: 20 });

   
  }

  public exportPpt = () =>{
    this.pptx.writeFile()
  }


}

