import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Box, TextField } from '@material-ui/core';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { OrganizationInterface } from '../../../../interfaces/Organization';
import { withSnackbar, ProviderContext } from 'notistack';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import { ScaleLoader } from 'react-spinners';
import MaterialTable, { Column, EditComponentProps, Icons } from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { forwardRef } from 'react';
import { WorkflowFormInterface } from '../../../../interfaces/Workflow';
import { IWorkflowService } from '../../../../services/Interfaces/IWorkflowService';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

interface IWorkflowFormInterfacesState {
  interfaces: WorkflowFormInterface[];
  isLoading: boolean;
}

export interface ExternalWorkflowFormInterfaceProps {
  workflowFormId: number;
}

type WorkflowFormInterfacesProps = { appState: AppState } & ExternalWorkflowFormInterfaceProps &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class WorkflowFormInterfaces extends React.PureComponent<
  WorkflowFormInterfacesProps,
  IWorkflowFormInterfacesState
> {
  private translatorService!: ITranslatorService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    interfaces: [],
    isLoading: true,
    organizationId: 0
  } as IWorkflowFormInterfacesState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData();
      }
    );
  };

  loadData = async () => {
    try {
      const data = await this.workflowService.GetWorkflowFormInterfaces(this.props.workflowFormId);

      this.setState({
        interfaces: data,
        isLoading: false
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    return [
      {
        field: 'code',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_CODE_HEADER', 'Cod'),
        searchable: true
      } as Column<any>,
      {
        field: 'url',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_URL_HEADER', 'URL'),
        searchable: true
      } as Column<any>,
      {
        field: 'userName',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_UER_HEADER', 'Utilizator'),
        searchable: true
      },
      {
        field: 'password',
        title: this.translatorService.Tranlate('ORGANIZATION_INTERFACE_PASSWORD_HEADER', 'Parola'),
        render: (rowData: any) => {
          return '***';
        },
        editComponent: (props) => (
          <TextField
            value={props.value}
            fullWidth={true}
            type="password"
            onChange={(e) => props.onChange(e.target.value)}
          />
        )
      } as Column<any>,
      {
        field: 'dateModification',
        title: this.translatorService.Tranlate(
          'ORGANIZATION_INTERFACE_MODIFIED_DATE_HEADER',
          'Data modificare'
        ),
        defaultSort: 'desc',
        searchable: true,
        render: (rowData: any) => {
          return moment
            .utc(rowData.dateModification)
            .local()
            .toDate()
            .toLocaleString(this.props.appState.language);
        },
        editComponent: (props: EditComponentProps<any>) => {
          return <Label>{new Date().toLocaleString(this.props.appState.language)}</Label>;
        }
      } as Column<any>
    ];
  };

  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        deleteTooltip: this.translatorService.Tranlate('DELETE', 'Sterge'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza'),
          deleteText: this.translatorService.Tranlate(
            'DELETE_TEXT',
            'Sigur doriti sa stergeti aceasta inregistrare?'
          )
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };

  onRowUpdate = (newData: WorkflowFormInterface, oldData?: OrganizationInterface): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.workflowService.UpdateWorkflowFormInterface(newData);
          await this.loadData();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowDelete = (oldData: OrganizationInterface): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.workflowService.RemoveWorkflowFormInterface(oldData.id);
          await this.loadData();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });

          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowAdd = (newData: WorkflowFormInterface): Promise<void> =>
    new Promise(async (resolve, reject) => {
      try {
        newData.workflowSectionStepFormId = this.props.workflowFormId;

        await this.workflowService.AddWorkflowFormInterface(newData);
        await this.loadData();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });

  public renderTable = () => {
    return (
      <MaterialTable
        icons={tableIcons}
        title=" "
        columns={this.getColumns()}
        data={this.state.interfaces}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: any) => this.onRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData),
          onRowDelete: (oldData: any) => this.onRowDelete(oldData)
        }}
      />
    );
  };
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderTable() : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalWorkflowFormInterfaceProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(WorkflowFormInterfaces as any));
