import React, { useContext, useEffect, useState } from 'react';
import { Card, Grid } from '@material-ui/core';
import { ApplicationContext } from '../../../context/Contexts';
import { DashboardAcquisitionDetails } from '../../../interfaces/Dashboard/DashboardAcquisitionDetails';
import { AttachmentTypeCode } from '../../../helpers/Constants';

interface DashboardAcquisitionExtendedDetails extends DashboardAcquisitionDetails {
  imageURL?: string;
  base64Value?: any;
}
interface Props {
  items: DashboardAcquisitionExtendedDetails[];
  includeVat: boolean;
  parentCallback: (values: any[]) => any;
}

const RecentAcquisitions: React.FC<Props> = ({ items, includeVat, parentCallback }) => {
  const context = useContext(ApplicationContext);
  const caseService = context.caseService;
  const [updatedItems, setUpdatedItems] = useState<DashboardAcquisitionExtendedDetails[]>(items);

  const convertBlobToBase64 = async (blob: any) => {
    // blob data
    return await blobToBase64(blob);
  };

  const blobToBase64 = (blob: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    const getAttachments = async () => {
      const itemsWithImages: DashboardAcquisitionExtendedDetails[] = [];
      for (let i = 0; i < items.length; i++) {
        const attachments = await caseService.GetCaseAttachments(items[i].caseId, [
          AttachmentTypeCode.MAIN_VEHICLE_PHOTO
        ]);
        const blob = await caseService.GetCaseAttachment(attachments[0].fileName);
        items[i].imageURL = URL.createObjectURL(blob);
        items[i].base64Value = await convertBlobToBase64(blob);
        itemsWithImages.push({ ...items[i] });
      }

      return itemsWithImages;
    };

    getAttachments().then((updatedItems) => {
      setUpdatedItems(updatedItems);
      parentCallback(updatedItems);
    });
  }, [items, caseService]);

  return (
    <>
      <h4>
        {context.translatorService.Tranlate('DASHBOARD_ACQUISITION_RECENT', 'Achizitii recente')}
      </h4>
      <div className="mt-2">
        <Grid container spacing={2}>
          {updatedItems.map((item) => (
            <Grid item xs={4} key={item.caseId}>
              <Card className="h-100 p-4 d-flex" style={{ columnGap: '1rem' }}>
                <img
                  src={item.imageURL}
                  alt="image"
                  width={150}
                  height={100}
                  className="img-fluid rounded"
                />
                <div>
                  <span>
                    {item.makeName} {item.modelName}
                  </span>{' '}
                  <span>{item.equipmentVersion}</span> /<span>{item.makeYear}</span>/
                  <span>{item.fuelType}</span>/<span>{item.transmissionType}</span>
                  <div>
                    {context.translatorService.Tranlate(
                      'DASHBOARD_ACQUISITION_PRICE',
                      'Pret achizitie'
                    )}
                    : {Math.round(includeVat ? item.purchasePriceInclVat : item.purchasePrice)} Euro
                  </div>
                  <div>
                    {context.translatorService.Tranlate('DASHBOARD_BUYER_NAME', 'Cumparator')}:{' '}
                    {item.buyerName}
                  </div>
                  <div>
                    {context.translatorService.Tranlate('DASHBOARD_ORGANIZATION', 'Organizatie')}:{' '}
                    {item.organizationName}
                  </div>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};
export default RecentAcquisitions;
