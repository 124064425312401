import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Button, Card, Tabs, Tab, CardContent } from '@material-ui/core';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { Language } from '../../../../interfaces/Organization';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { WrapperSimple } from '../../../../layout-components';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import TabPanel from '../../../_shared/Tabs';
import EditWorkflowFormReport from './EditWorkflowFormReport';
import EditWorkflowFormDesign from './EditWorkflowFormDesign';
import EditWorkflowFormAction from './EditWorkflowFormAction';
import EditWorkflowFormInterfaces from './EditWorkflowFormInterfaces';
import { ScaleLoader } from 'react-spinners';
import { IWorkflowService } from '../../../../services/Interfaces/IWorkflowService';
import { WorkflowSectionStepForm } from '../../../../interfaces/Workflow';
import WorkflowOptimizations from '../WorkflowOptimizations';
import WorkflowDocumentTypes from '../WorkflowDocumentTypes';
import WorkflowCalculations from '../WorkflowCalculations';
import WorkflowPermissions from '../WorkflowPermissions';
import { IReferential, ITranslation } from '../../../../interfaces/IReferential';
import { FormCode } from '../../../../helpers/forms/FormCode';
import Referentials from '../../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../../helpers/Constants';
import { Autocomplete } from '@material-ui/lab';
import { Switch, FormControlLabel, Typography } from '@material-ui/core';

interface IEditWorkflowFormState {
  workflowForm: WorkflowSectionStepForm;
  selectedTab: number;
  languages: Language[];
  isLoading: boolean;
  appointmentCategoryTypes: IReferential[];
}

export interface ExternalEditWorkflowFormsProps {
  workflowFormChanged?: (close: boolean) => void;
  workflowFormId: number;
  workflowFormCode: string | null;
}

type EditWorkflowFormProps = { appState: AppState } & ProviderContext &
  ExternalEditWorkflowFormsProps &
  RouteComponentProps<{ id: string }>;

class EditWorkflowForm extends React.PureComponent<EditWorkflowFormProps, IEditWorkflowFormState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;
  private workflowService!: IWorkflowService;

  static contextType = ApplicationContext;

  state = {
    workflowForm: {} as WorkflowSectionStepForm,
    selectedTab: 0,
    languages: [],
    isLoading: true,
    appointmentCategoryTypes: []
  } as IEditWorkflowFormState;

  public async componentDidMount() {
    // const hoId = Number.parseInt(this.props.match.params.id);

    let workflowForm = {} as WorkflowSectionStepForm;
    const languages = this.activeLanguages();

    if (this.props.workflowFormId !== 0) {
      workflowForm = await this.workflowService.GetWorkflowSectionStepForm(
        this.props.workflowFormId
      );
    }

    const formTranslations = workflowForm.translations;
    languages.forEach((language: Language, index: number) => {
      if (formTranslations.length === 0) {
        formTranslations.push({
          id: 0,
          referentialTypeId: workflowForm.id,
          name: null,
          language: language.code
        });
      } else {
        const indexTrans = formTranslations.findIndex((trans: ITranslation) => {
          return language.code === trans.language;
        });
        if (indexTrans === -1) {
          formTranslations.push({
            id: 0,
            referentialTypeId: workflowForm.id,
            name: null,
            language: language.code
          });
        }
      }
    });
    workflowForm.translations = [...formTranslations];

    let appointmentCategoryTypes = [] as IReferential[];
    if (workflowForm!.form!.code == FormCode.CASE_IN_OUT_REPORT || workflowForm!.form!.code == FormCode.CASE_ATTACHMENTS) {
      const ref = Referentials.referential.find(
        (item) => item.code === ReferentialCode.AppointmentCategoryType
      );
  
      appointmentCategoryTypes = await this.appReferentialService.Get(ref!.baseUrl);

      if (workflowForm!.appointmentCategoryTypeId) {
        const appointmentCategoryType = appointmentCategoryTypes.find(
          (item) => item.id === workflowForm!.appointmentCategoryTypeId
        );
        workflowForm.appointmentCategoryType = appointmentCategoryType || null;
      }
    }
    this.setState({
      workflowForm: workflowForm,
      languages: languages,
      isLoading: false,
      appointmentCategoryTypes: appointmentCategoryTypes
    });
  }
  activeLanguages = () => {
    const activeLanguages =
      this.props.appState.appUser!.organization!.gtOrganization!.languages!.filter(
        (language) => language.isActive === true
      );

    activeLanguages.forEach((language) => {
      language.displayName =
        language.displayName.charAt(0).toUpperCase() + language.displayName.slice(1);
    });
    return activeLanguages;
  };

  handleFormNameChange = (event: any, code: string | null) => {
    let newFormTransList = [] as ITranslation[];
    if (this.state.workflowForm.translations) {
      newFormTransList = this.state.workflowForm.translations.map((loc: any) => {
        if (loc.language === code) {
          loc.name = event.target.value;
        }
        return loc;
      });
    }
    this.setState({
      workflowForm: {
        ...this.state.workflowForm,
        translations: newFormTransList
      }
    });
  };

  compare = (a: ITranslation, b: ITranslation) => {
    const bandA = a.language!.toUpperCase();
    const bandB = b.language!.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = -1;
    } else if (bandA < bandB) {
      comparison = 1;
    }
    return comparison;
  };

  renderNameTextFields = () => {
    if (Object.keys(this.state.workflowForm).length === 0) {
      return null;
    } else {
      const nameTextFieldsList = [] as any[];

      this.state
        .workflowForm!.translations.sort(this.compare)
        .forEach((item: ITranslation, index: number) => {
          nameTextFieldsList.push(
            <TextValidator
              key={index}
              fullWidth
              name={'name_' + index}
              className="m-2"
              value={item.name || ''}
              onChange={(e) => this.handleFormNameChange(e, item.language)}
              label={item.language}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          );
        });
      return nameTextFieldsList;
    }
  };

  public saveWorkflowForm = async () => {
    try {
      await this.workflowService.UpdateWorkflowSectionStepForm(this.state.workflowForm);

      if (this.props.workflowFormChanged) {
        this.props.workflowFormChanged(true);
      }

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  private handleAppointmentCategoryTypeChange = (newValue: IReferential | null) => {
    this.setState({
      workflowForm: {
        ...this.state.workflowForm,
        appointmentCategoryType: newValue,
        appointmentCategoryTypeId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  public renderForm() {
    return (
      <Grid container spacing={4} alignContent="center" alignItems="center">
        <Grid item xs={12} lg={12} className="mt-4">
          <ValidatorForm onSubmit={this.saveWorkflowForm} instantValidate={true}>
            <WrapperSimple
              sectionHeading={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_FORM_GENERAL_DATA_GUI_NAME',
                'Nume GUI'
              )}
            >
              {this.renderNameTextFields()}
            </WrapperSimple>
            {
              this.state.workflowForm!.form!.code == FormCode.CARFIX_ANALYSIS_STEP ||
              this.state.workflowForm!.form!.code == FormCode.CARFIX_APPOINTMENT_VALIDATION_STEP  ?
              (
                <WrapperSimple
                  sectionHeading={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_GENERAL_DATA_COMMENTS_POEDITOR_KEY',
                    'Cheie Comentariu Poeditor'
                  )}
                >
                  <TextValidator
                    fullWidth
                    name="comments"
                    className="m-2"
                    value={this.state.workflowForm!.comments || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({
                        workflowForm: {
                          ...this.state.workflowForm,
                          comments: e.target.value
                        }
                      });
                    }}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_GENERAL_DATA_COMMENTS_POEDITOR_KEY',
                      'Cheie Comentariu Poeditor'
                    )}
                  />
                </WrapperSimple>
              ) : ''
            }
            {
              this.state.workflowForm!.form!.code == FormCode.CARFIX_REPAIR_IN_PROGRESS_STEP ?
              (
                <>
                  <WrapperSimple
                    sectionHeading={this.translatorService.Tranlate(
                      'EDIT_LABEL_FIRST_BUTTON_POEDITOR_KEY',
                      'Primul buton'
                    )}
                  >
                    <TextValidator
                      fullWidth
                      name="firstButtonLabel"
                      className="m-2"
                      value={this.state.workflowForm!.firstButtonLabel || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({
                          workflowForm: {
                            ...this.state.workflowForm,
                            firstButtonLabel: e.target.value
                          }
                        });
                      } }
                      label={this.translatorService.Tranlate(
                        'EDIT_LABEL_FIRST_BUTTON_POEDITOR_KEY',
                        'Primul buton'
                      )} 
                    />
                    <FormControlLabel
                      className="m-2"
                      control={
                        <Switch
                          id="hideFirstButton"
                          size="small"
                          checked={
                            this.state.workflowForm!.hideFirstButton
                              ? this.state.workflowForm!.hideFirstButton
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              workflowForm: {
                                ...this.state.workflowForm!,
                                hideFirstButton: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate('HIDE_FIRST_BUTTON_POEDITOR_KEY', 'Ascunde butonul')}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </WrapperSimple>
                  <WrapperSimple
                    sectionHeading={this.translatorService.Tranlate(
                      'EDIT_LABEL_SECOND_BUTTON_POEDITOR_KEY',
                      'Al doilea buton'
                    )}
                  >
                    <TextValidator
                      fullWidth
                      name="secondButtonLabel"
                      className="m-2"
                      value={this.state.workflowForm!.secondButtonLabel || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({
                          workflowForm: {
                            ...this.state.workflowForm,
                            secondButtonLabel: e.target.value
                          }
                        });
                      } }
                      label={this.translatorService.Tranlate(
                        'EDIT_LABEL_SECOND_BUTTON_POEDITOR_KEY',
                        'Al doilea buton'
                      )} 
                    />
                    <FormControlLabel
                      className="m-2"
                      control={
                        <Switch
                          id="hideSecondButton"
                          size="small"
                          checked={
                            this.state.workflowForm!.hideSecondButton
                              ? this.state.workflowForm!.hideSecondButton
                              : false
                          }
                          onChange={(e) =>
                            this.setState({
                              workflowForm: {
                                ...this.state.workflowForm!,
                                hideSecondButton: e.target.checked
                              }
                            })
                          }
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color="secondary"
                          className="font-weight-bold"
                          align="left"
                        >
                          {this.translatorService.Tranlate('HIDE_SECOND_BUTTON_POEDITOR_KEY', 'Ascunde butonul')}
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </WrapperSimple>
                </>
              
              ) : ''
            }
            {
              this.state.workflowForm!.form!.code == FormCode.CASE_IN_OUT_REPORT || this.state.workflowForm!.form!.code == FormCode.CASE_ATTACHMENTS  ?
              (
                <>
                  <WrapperSimple
                    sectionHeading={this.translatorService.Tranlate(
                      'EDIT_WORKFLOW_FORM_APPOINTMENT_CATEGORY_TYPE',
                      'Tip Programare'
                    )}
                  >
                    <Autocomplete
                      id="appointmentCategoryType"
                      className="m-2"
                      options={this.state.appointmentCategoryTypes || []}
                      value={this.state.workflowForm!.appointmentCategoryType || null}
                      onChange={(e: any, newValue: IReferential | null) =>
                        this.handleAppointmentCategoryTypeChange(newValue)
                      }
                      getOptionLabel={(option: IReferential) => option.displayName || ''}
                      renderInput={(params) => (
                        <TextValidator
                          {...params}
                          name="appointmentCategoryType"
                          value={this.state.workflowForm!.appointmentCategoryType || null}
                          label={this.translatorService.Tranlate(
                            'EDIT_WORKFLOW_FORM_APPOINTMENT_CATEGORY_TYPE',
                            'Tip Programare'
                          )}
                          fullWidth
                          validators={this.state.workflowForm!.form!.code == FormCode.CASE_ATTACHMENTS ? [] : ['required']}
                          errorMessages={[
                            this.translatorService.Tranlate(
                              'VALIDATORS_REQUIRED',
                              'Campul este obligatoriu'
                            )
                          ]}
                        />
                      )}
                    />
                  </WrapperSimple>
                </> 
              ) : ''
            }
            <div className="text-right">
              <Button
                className="m-2"
                variant="outlined"
                color="primary"
                onClick={() =>
                  this.props.workflowFormChanged ? this.props.workflowFormChanged(false) : null
                }
              >
                {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
              </Button>
              <Button className="m-2" variant="contained" color="primary" type="submit">
                {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
              </Button>
            </div>
          </ValidatorForm>
        </Grid>
      </Grid>
    );
  }

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.workflowService = (this.context as AppContext).workflowService;

    if (this.state.isLoading) {
      return (
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
      );
    }

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="scrollable"
              onChange={this.onTabChange}
            >
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_FORM_EDIT_GENERAL_DATA_TAB',
                  'Date generale'
                )}
              />
              <Tab
                hidden={!this.state.workflowForm.form.hasDesign}
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_FORM_EDIT_DESIGN_TAB',
                  'Design'
                )}
              />
              <Tab
                hidden={!this.state.workflowForm.form.hasPermissions}
                className="text-capitalize"
                label={this.translatorService.Tranlate('WORKFLOWS_TAB_PERMISSIONS', 'Permisiuni')}
              />
              <Tab
                hidden={!this.state.workflowForm.form.hasActions}
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_FORM_EDIT_ACTIONS_TAB',
                  'Actiuni'
                )}
              />
              <Tab
                hidden={!this.state.workflowForm.form.hasCalculations}
                className="text-capitalize"
                label={this.translatorService.Tranlate('WORKFLOWS_TAB_CALCULATIONS', 'Calculatii')}
              />
              <Tab
                hidden={!this.state.workflowForm.form.hasReports}
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_FORM_EDIT_REPORTS_TAB',
                  'Rapoarte'
                )}
              />
              <Tab
                hidden={!this.state.workflowForm.form.hasOptimizations}
                className="text-capitalize"
                label={this.translatorService.Tranlate('WORKFLOWS_TAB_OPTIMIZATION', 'Optimizari')}
              />
              <Tab
                hidden={!this.state.workflowForm.form.hasDocumentTypes}
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'WORKFLOWS_TAB_DOCUMENT_TYPES',
                  'Tip documente'
                )}
              />
              <Tab
                hidden={!this.state.workflowForm.form.hasInterfaces}
                className="text-capitalize"
                label={this.translatorService.Tranlate('WORKFLOW_TAB_INTERFACES', 'Interfete')}
              />
            </Tabs>
          </CardContent>
        </Card>
        <TabPanel value={this.state.selectedTab} index={0}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderForm() : null}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <EditWorkflowFormDesign {...this.props} workflowFormId={this.props.workflowFormId} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={2}>
          <WorkflowPermissions {...this.props} workflowFormId={this.props.workflowFormId} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={3}>
          <EditWorkflowFormAction
            {...this.props}
            workflowFormId={this.props.workflowFormId}
            currentWorkflowId={
              this.state.workflowForm.workflowSectionStep?.workflowSection.workflowId || null
            }
          />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={4}>
          <WorkflowCalculations {...this.props} workflowFormId={this.props.workflowFormId} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={5}>
          <EditWorkflowFormReport {...this.props} workflowFormId={this.props.workflowFormId} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={6}>
          <WorkflowOptimizations {...this.props} workflowFormId={this.props.workflowFormId} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={7}>
          <WorkflowDocumentTypes {...this.props} workflowFormId={this.props.workflowFormId} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={8}>
          <EditWorkflowFormInterfaces {...this.props} workflowFormId={this.props.workflowFormId} />
        </TabPanel>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalEditWorkflowFormsProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(EditWorkflowForm as any));
