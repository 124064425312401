import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { TranslatorService } from '../../services/TranslatorService';
import { BigCalendar } from '../agenda/BigCalendar';
import { getAppointmentsRequest } from '../agenda/agenda.requests';
import { ResponseAppointmentsData } from '../../types/Agenda';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { AGENDA_SET_APPOINTMENTS } from '../../store/actions/ActionTypes';

export const AppointmentBigCalendar = () => {
  const translatorService = new TranslatorService();
  const [localAppointments, setLocalAppoitments] = useState<Array<any>>([]);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [defaultView, setDefaultView] = useState<'month' | 'week' | 'day' | 'agenda'>('week');
  const [defaultDay, setDefaultDay] = useState<any>()
  const dispatch = useAppDispatch();

  const fetchAppointments = async (filters: any, onlyOneDay?: boolean) => {
    !onlyOneDay && setLocalLoading(true)
    const response = await getAppointmentsRequest(filters);

    const responseData = response.data as ResponseAppointmentsData;

    if (!responseData?.appointments) {
      !onlyOneDay && setLocalLoading(false)
      return
    }

    if (onlyOneDay) {
      setLocalAppoitments(responseData?.appointments)
    } else {
      dispatch({
        type: AGENDA_SET_APPOINTMENTS,
        payload: responseData.appointments,
      });
    }

    !onlyOneDay && setLocalLoading(false)
  };

  return (
    <BigCalendar
      getAppointments={fetchAppointments}
      defaultView={defaultView}
      setDefaultView={setDefaultView}
      defaultDay={defaultDay}
    />
  );
};
