import { RadarState } from '../../interfaces/Radar/RadarState';

export const SET_FILTERS = '[RADAR] SET_FILTERS';
export const SET_SELECTED_MARKET_DATA = '[RADAR] SET_SELECTED_MARKET_DATA';
export const SET_MARKET_DATA = '[RADAR] SET_MARKET_DATA';
export const SET_SELECTED_SELLER_TYPE = '[RADAR SET_SELECTED_SELLER_TYPE';
export const SET_SELECTED_SELLER = '[RADAR] SET_SELECTED_SELLER';
export const SET_MARKET_DATA_LOADING = '[RADAR] SET_MARKET_DATA_LOADING';
export const SET_SELECTED_VEHICLE = '[RADAR] SET_SELECTED_VEHICLE';

export const radarReducer = (state: RadarState, action: any) => {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, filters: action.filters };
    case SET_SELECTED_MARKET_DATA:
      return {
        ...state,
        selectedMarketData: action.selectedMarketData
          ? action.selectedMarketData.id !== state.selectedMarketData?.id
            ? action.selectedMarketData
            : undefined
          : undefined
      };
    case SET_MARKET_DATA:
      return { ...state, marketData: action.marketData };
    case SET_SELECTED_SELLER_TYPE:
      return {
        ...state,
        selectedSellerType:
          action.selectedSellerType !== state.selectedSellerType
            ? action.selectedSellerType
            : undefined
      };
    case SET_SELECTED_SELLER:
      return {
        ...state,
        selectedSeller:
          action.selectedSeller !== state.selectedSeller ? action.selectedSeller : undefined
      };
    case SET_MARKET_DATA_LOADING:
      return { ...state, marketDataLoading: action.marketDataLoading };
    case SET_SELECTED_VEHICLE:
      return { ...state, selectedVehicle: action.selectedVehicle };
    default:
      return state;
  }
};
