import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { ApplicationState } from '../../../store';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { Organization } from '../../../interfaces/Organization';
import { Typography, Box, CardContent, Card, Tabs, Tab } from '@material-ui/core';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import { AppUser } from '../../../interfaces/AppUser';
import { ScaleLoader } from 'react-spinners';

interface IOrganizationUsersState {
  hoOrganization: Organization | null;
  organizations: Organization[];
  users: AppUser[];
  ids: string[];
  isLoading: boolean;
}
class OrganizationUsers extends React.PureComponent<any, IOrganizationUsersState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;
  state = {
    hoOrganization: null,
    organizations: [] as Organization[],
    users: [] as AppUser[],
    ids: [] as string[],
    isLoading: true
  };

  public async componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const [organizations, users] = await Promise.all([
      this.organizationService.GetOrganizationsByHO(hoId, null),
      this.appUserService.GetAppUsers()
    ]);

    const organization = organizations.find((item) => item.id === hoId);

    this.setState({
      hoOrganization: organization === undefined ? null : organization,
      organizations: organizations,
      ids: organizations.map((item) => item.id.toString()),
      users: users.users.filter(
        (user) =>
          user.organization!.hoId === organization!.id || user.organizationId === organization!.id
      ),
      isLoading: false
    });
  }

  renderTree = (nodes: Organization | null) => {
    if (nodes === null) {
      return;
    }

    const users = this.state.users.filter((u) => u.organization!.id === nodes.id);

    nodes.childOrganizations = this.state.organizations.filter(
      (item) => item.parentId === nodes.id
    );

    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id.toString()}
        label={
          <div color="#3c8039">
            <Typography variant="body2" color="secondary" className="font-weight-bold">
              {nodes.name}
            </Typography>
          </div>
        }
      >
        {users.map((user) => {
          return (
            <Typography
              variant="caption"
              color="secondary"
              key={user.id !== null ? user.id : ''}
              className=""
            >
              {user.lastName + ' ' + user.firstName} <br />
            </Typography>
          );
        })}
        {Array.isArray(nodes.childOrganizations)
          ? nodes.childOrganizations.map((node) => this.renderTree(node))
          : null}
      </TreeItem>
    );
  };

  render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('ORGANIZATIONS_TAB_USERS', 'Utilizatori')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <Box mt={1} pt={1}>
          <Card>
            <CardContent>
              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
              </div>

              {this.state.isLoading ? null : (
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpanded={this.state.ids}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  {this.renderTree(this.state.hoOrganization)}
                </TreeView>
              )}
            </CardContent>
          </Card>
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationUsers as any));
