import React, { useContext } from 'react';
import { Card, Grid } from '@material-ui/core';

import { ApplicationContext } from '../../../context/Contexts';
import { AcquisitionResult } from '../../../interfaces/Dashboard/AcquisitionResult';
import { numberFormat } from '../../../utils/numberFormat';

interface Props {
  data: AcquisitionResult;
  includeVat: boolean;
}

const AcquisitionMainCards: React.FC<Props> = ({ data, includeVat }) => {
  const context = useContext(ApplicationContext);

  const { purchasesCompleted, purchasesInProgress, purchasesFailed, newVehiclesSoldCount } = data;

  const purchasesCompletedPrice = includeVat
    ? purchasesCompleted.amountInclVat
    : purchasesCompleted.amount;

  const purchasesInProgressPrice = includeVat
    ? purchasesInProgress.amountInclVat
    : purchasesInProgress.amount;

  const purchasesFailedPrice = includeVat ? purchasesFailed.amountInclVat : purchasesFailed.amount;

  const averagePurchasePrice =
    purchasesCompleted.count == 0 ? 0 : purchasesCompletedPrice / purchasesCompleted.count;

  return (
    <>
      <Grid item xs={3}>
        <Card className="h-100">
          <div className="p-2 text-center">
            <h5>
              {context.translatorService.Tranlate(
                'DASHBOARD_ACQUISITION_FINALISED',
                'Finalizate cu succes'
              )}
            </h5>
            <h2>{purchasesCompleted.count}</h2>
            <h2>{`${numberFormat(purchasesCompletedPrice)} EUR`}</h2>
          </div>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card className="h-100">
          <div className="p-2 text-center">
            <h5>
              {context.translatorService.Tranlate(
                'DASHBOARD_ACQUISITION_IN_PROGRESS',
                'In curs de achizitie'
              )}
            </h5>
            <h2>{purchasesInProgress.count}</h2>
            <h2>{`${numberFormat(purchasesInProgressPrice)} EUR`}</h2>
          </div>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card className="h-100">
          <div className="p-2 text-center">
            <h5>
              {context.translatorService.Tranlate(
                'DASHBOARD_ACQUISITION_FAILED',
                'Achizitii esuate'
              )}
            </h5>
            <h2>{purchasesFailed.count}</h2>
            <h2>{`${numberFormat(purchasesFailedPrice)} EUR`}</h2>
          </div>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card className="h-100">
          <div className="p-2 text-center">
            <h5>
              {context.translatorService.Tranlate(
                'DASHBOARD_ACQUISITION_AVERAGE_PRICE',
                'Pret mediu achizitie'
              )}
            </h5>
            <h2>{`${numberFormat(averagePurchasePrice)} EUR`}</h2>
          </div>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Card className="h-100">
          <div className="p-2 text-center">
            <h5>
              {context.translatorService.Tranlate(
                'DASHBOARD_ACQUISITION_NEW_VEHICLES_SOLD',
                'Vehicule noi vandute'
              )}
            </h5>
            <h2>{newVehiclesSoldCount}</h2>
          </div>
        </Card>
      </Grid>
    </>
  );
};

export default AcquisitionMainCards;
