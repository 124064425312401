import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Grid, Button, Box } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../../../context/Contexts';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import EditWorkflowFormActionTransStatus from '../editWorkflowForm/EditWorkflowFormActionTransStatus';
import EditOrganizationFormActionCaseAllocation from '../editWorkflowForm/EditWorkflowFormActionCaseAllocation';
import EditOrganizationFormActionSendEmail from '../editWorkflowForm/EditWorkflowFormActionSendEmail';
import { RouteComponentProps } from 'react-router';
interface IOrganizationFormActionState {
  selectedTab: number;
}
export interface ExternalOrganizationFormActionProps {
  workflowFormId: number;
  workflowFormCode: string | null;
  currentWorkflowId: number | null;
}

type IOrganizationFormActionProps = ExternalOrganizationFormActionProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string }>;

class EditOrganizationFormAction extends React.PureComponent<
  IOrganizationFormActionProps,
  IOrganizationFormActionState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;
  state = {
    selectedTab: 0
  };

  onButtonClick = (newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <Grid container spacing={2} className="mt-3">
          <Grid item xs={12} lg={2}>
            <Box height={500}>
              <Box
                height={1 / 7}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 0 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={
                    this.state.selectedTab === 0 ? 'text-white h-100' : 'text-dark h-100 h-100'
                  }
                  onClick={() => this.onButtonClick(0)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_TRANSITION_STATUS_TAB',
                      'Transition status'
                    )}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 7}
                border={1}
                borderColor="text.disabled"
                borderBottom={0}
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 1 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 1 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(1)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_CASE_ALLOCATION_TAB',
                      'Alocare dosar'
                    )}
                  </b>
                </Button>
              </Box>
              <Box
                height={1 / 7}
                border={1}
                borderColor="text.disabled"
                borderRadius="0%"
                bgcolor={this.state.selectedTab === 2 ? 'secondary.main' : ''}
              >
                <Button
                  fullWidth
                  className={this.state.selectedTab === 2 ? 'text-white h-100' : 'text-dark h-100'}
                  onClick={() => this.onButtonClick(2)}
                >
                  <b>
                    {this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FORM_ACTION_SEND_EMAIL_TAB',
                      'Trimite e-mail'
                    )}
                  </b>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={10}>
            {this.state.selectedTab === 0 ? (
              <EditWorkflowFormActionTransStatus
                {...this.props}
              ></EditWorkflowFormActionTransStatus>
            ) : null}
            {this.state.selectedTab === 1 ? (
              <EditOrganizationFormActionCaseAllocation
                {...this.props}
              ></EditOrganizationFormActionCaseAllocation>
            ) : null}
            {this.state.selectedTab === 2 ? (
              <EditOrganizationFormActionSendEmail {...this.props} />
            ) : null}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalOrganizationFormActionProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null,
  mergeProps
)(withSnackbar(EditOrganizationFormAction as any));
