import React, { ChangeEvent, Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Button, Card, Tabs, Tab, CardContent, TextField, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  CountryBuffer,
  Organization,
  OrganizationLevelType,
  OrganizationType
} from '../../../interfaces/Organization';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { WrapperSimple } from '../../../layout-components';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode, OrganizationLevelTypeCode, RoleCodeEnum, RoleClaimsEnum, OrganizationTypeCode } from '../../../helpers/Constants';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import TabPanel from '../../_shared/Tabs';
import OrganizationBankAccounts from './OrganizationBankAccounts';
import OrganizationLogo from './OrganizationLogo';
import OrganizationTokens from './OrganizationTokens';
import { IReferential } from '../../../interfaces/IReferential';
import { ScaleLoader } from 'react-spinners';
import { Switch, FormControlLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import * as R from 'ramda';
import NumberFormat from 'react-number-format';
import { count } from 'console';

interface IEditOrganizationState {
  organization: Organization;
  organizationLevelTypes: OrganizationLevelType[];
  parentOrganizations: Organization[];
  organizationTypes: OrganizationType[];
  selectedTab: number;
  countryList: IReferential[];
  regionList: IReferential[];
  cityList: IReferential[];
  isLoading: boolean;
  countryBuffers: CountryBuffer[];
  partQualityTypes: IReferential[];
}

export interface ExternalEditOrganizationsProps {
  organizationChanged?: () => void;
  organizationId: number;
}

type EditOrganizationProps = { appState: AppState } & ProviderContext &
  ExternalEditOrganizationsProps &
  RouteComponentProps<{ id: string }>;

class EditOrganization extends React.PureComponent<EditOrganizationProps, IEditOrganizationState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {
    organization: {
      id: 0,
      name: '',
      organizationType: null,
      organizationLevelType: null,
      country: null,
      region: null,
      parent: null,
      gtId: this.props.appState.appUser!.gtId,
      isActive: true,
      newFeature: false,
      uploadFeature: false,
      mondayToFridayOpen: null,
      mondayToFridayClose: null,
      mondayToThursdayOpen: null,
      mondayToThursdayClose: null,
      fridayOpen: null,
      fridayClose: null,
      saturdayOpen: null,
      saturdayClose: null,
      cityInfo: null,
      bufferValue: 0,
      categoryPart: null,
      categoryPartId: null
    } as Organization,
    organizationLevelTypes: [] as OrganizationLevelType[],
    parentOrganizations: [] as Organization[],
    organizationTypes: [] as OrganizationType[],
    selectedTab: 0,
    countryList: [] as IReferential[],
    regionList: [] as IReferential[],
    cityList: [] as IReferential[],
    isLoading: true,
    isMondayToFridayOpenValid: true,
    countryBuffers: [] as CountryBuffer[],
    partQualityTypes: [] as IReferential[]
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  public async componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    //let organization = this.state.organization;

    // if (this.props.organizationId !== 0) {
    //     organization = ;
    // }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrganizationType
    );
    const countryRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Country
    );
    const regionRef = Referentials.referential.find((item) => item.code === ReferentialCode.Region);
    const cityRef = Referentials.referential.find((item) => item.code === ReferentialCode.City);
    const refQualityPart = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarPartCategory
    );

    const [organization, orgLevelList, orgTypes, countryList, regionList, cityList, countryBuffers, partQualityTypes] = await Promise.all([
      this.props.organizationId !== 0
        ? this.organizationService.GetOrganization(this.props.organizationId)
        : this.state.organization,
      this.organizationService.GetOrganizationLevels(hoId),
      this.appReferentialService.Get(ref!.baseUrl),
      this.appReferentialService.Get(countryRef!.baseUrl),
      this.appReferentialService.Get(regionRef!.baseUrl),
      this.appReferentialService.Get(cityRef!.baseUrl),
      this.organizationService.GetCountryBuffers(),
      this.appReferentialService.Get(refQualityPart!.baseUrl)
    ]);
    organization.hoId = hoId;
    const organizationLevelTypes = orgLevelList.map((item) => item.organizationLevelType);

    organization.categoryPart = organization.categoryPartId != null ? partQualityTypes.find((item) => item.id === organization.categoryPartId) ?? null : null
    this.setState(
      {
        organization: organization,
        organizationLevelTypes: organizationLevelTypes.filter(
          (item) => item.code !== OrganizationLevelTypeCode.HO
        ),
        organizationTypes: orgTypes as any as OrganizationType[],
        //parentOrganizations: [],
        regionList: regionList.filter((item) => item.isActive === true),
        countryList: countryList.filter((item) => item.isActive === true),
        cityList: cityList.filter((item) => item.isActive === true),
        isLoading: false,
        countryBuffers: countryBuffers,
        partQualityTypes: partQualityTypes
      },
      async () => {
        const organizations =
          this.props.organizationId === 0
            ? ([] as Organization[])
            : await this.organizationService.GetOrganizationsByHO(
                hoId,
                this.state.organization.organizationLevelTypeId
              );

        this.setState({
          parentOrganizations: organizations
        });
      }
    );

    ValidatorForm.addValidationRule('isTimeValid', (value) => {
      if (value) {
        return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
      }
      return true;
    });
  }

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text') {
      value = event.currentTarget.value;
    }
    this.setState({
      organization: {
        ...this.state.organization,
        [event.target.id]: value
      }
    });
  };

  private handleValueChange = (value: string) => {
    this.setState({
      organization: {
        ...this.state.organization,
        bufferValue: parseFloat(value)
      }
    });
  };

  private handleOrganizationLevelTypeChange = async (newValue: OrganizationLevelType | null) => {
    let orgs = [] as Organization[];
    if (newValue !== null) {
      orgs = await this.organizationService.GetOrganizationsByHO(
        this.state.organization.hoId,
        newValue.id
      );
    }
    this.setState({
      organization: {
        ...this.state.organization,
        organizationLevelType: newValue,
        organizationLevelTypeId: newValue !== null ? newValue!.id : 0,
        parent:
          newValue !== null && newValue.code === OrganizationLevelTypeCode.HO
            ? null
            : this.state.organization.parent,
        parentId:
          newValue !== null && newValue.code === OrganizationLevelTypeCode.HO
            ? null
            : this.state.organization.parentId
      },
      parentOrganizations: orgs
    });
  };

  private handleOrganizationTypeChange = (newValue: OrganizationType | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        organizationType: newValue,
        organizationTypeId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  private handleCountryChange =  (newValue: IReferential | null) => {
    const countryId = newValue !== null ? newValue!.id : null;
    const countryBuffer = this.state.countryBuffers.find((item) => item.countryId === countryId);
    this.setState({
      organization: {
        ...this.state.organization,
        country: newValue,
        countryId: countryId,
        region: null,
        regionId: null,
        cityInfo: null,
        cityId: null,
        bufferValue: countryBuffer ? countryBuffer.value : null
      }
    });
  };

  private handleRegionChange = (newValue: IReferential | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        region: newValue,
        regionId: newValue !== null ? newValue!.id : null,
        cityInfo: null,
        cityId: null
      }
    });
  };

  private handleCityInfoChange = (newValue: IReferential | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        cityInfo: newValue,
        cityId: newValue !== null ? newValue!.id : null
      }
    });
  };

  private handleCategoryPartChange = (newValue: IReferential | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        categoryPart: newValue,
        categoryPartId: newValue !== null ? newValue!.id : null
      }
    });
  };

  private handleOrganizationChange = (newValue: Organization | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        parent: newValue,
        parentId: newValue !== null ? newValue!.id : 0,
        organizationType: newValue !== null ? newValue.organizationType : null
      }
    });
  };

  public saveOrganization = async () => {
    try {
      if (this.state.organization.id === 0) {
        await this.organizationService.AddOrganization(this.state.organization);
      } else {
        await this.organizationService.UpdateOrganization(this.state.organization);
      }

      if (this.props.organizationChanged) {
        this.props.organizationChanged();
      }

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleInputChange = (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const hourValue = event.target.value.trim();
    this.setState({
      organization: {
        ...this.state.organization,
        [fieldName]: hourValue === '' ? null : hourValue
      }
    });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  isValidTime = (value : string | null) => {
    if (value) {
      return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
    }
    return true;
  };

  private getRegionsOptions(): IReferential[] {
    const filteredRegions = this.state.regionList.filter(
      (region) => (this.state.organization.countryId && region.dependencyId) && region.dependencyId === this.state.organization.countryId
    );

    return filteredRegions || [];
  }

  private getCitiesOptions(): IReferential[] {
    const filteredCities = this.state.cityList.filter(
      (city) => (this.state.organization.regionId && city.dependencyId) && city.dependencyId === this.state.organization.regionId
    );

    return filteredCities || [];
  }

  public hasRole = (roleCode: string): boolean => {
    return this.props.appState.appUser?.userRole?.code === roleCode;
  };

  public renderForm() {
    return (
      <Grid container spacing={4} alignContent="center" alignItems="center">
        <Grid item xs={12} lg={12} className="mt-4">
          <ValidatorForm onSubmit={this.saveOrganization} instantValidate={true}>
            <WrapperSimple
              sectionHeading={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_GENERAL_DATA_SECTION',
                'Date generale'
              )}
            >
              <TextValidator
                fullWidth
                name="name"
                className="m-2"
                id="name"
                value={this.state.organization.name || ''}
                onChange={this.handleTextChange}
                label={this.translatorService.Tranlate('EDIT_ORGANIZATION_NAME_LABEL', 'Nume')}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />

              <Autocomplete
                id="organizationLevelType"
                className="m-2"
                disabled={
                  this.state.organization.organizationLevelType === null
                    ? false
                    : this.state.organization.organizationLevelType.code ===
                      OrganizationLevelTypeCode.HO
                }
                options={this.state.organizationLevelTypes}
                value={this.state.organization.organizationLevelType}
                onChange={(e: any, newValue: OrganizationLevelType | null) =>
                  this.handleOrganizationLevelTypeChange(newValue)
                }
                getOptionLabel={(option: OrganizationLevelType) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationLevelType"
                    value={this.state.organization.organizationLevelType}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_ORGANIZATION_LEVEL_TYPE_LABEL',
                      'Nivel Ierarhic'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="parent"
                className="m-2"
                disabled={
                  this.state.organization.organizationLevelType === null
                    ? false
                    : this.state.organization.organizationLevelType.code ===
                      OrganizationLevelTypeCode.HO
                }
                options={this.state.parentOrganizations}
                value={this.state.organization.parent}
                onChange={(e: any, newValue: Organization | null) =>
                  this.handleOrganizationChange(newValue)
                }
                getOptionLabel={(option: Organization) => option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="parent"
                    value={this.state.organization.parent}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_PARENT_LABEL',
                      'Entitatea Parinte'
                    )}
                    fullWidth
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="organizationType"
                className="m-2"
                options={this.state.organizationTypes}
                value={this.state.organization.organizationType}
                onChange={(e: any, newValue: OrganizationType | null) =>
                  this.handleOrganizationTypeChange(newValue)
                }
                getOptionLabel={(option: OrganizationType) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationType"
                    value={this.state.organization.organizationType}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_ORGANIZATION_TYPE_LABEL',
                      'Tip Organizatie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="alternativePartnerEditable"
                    size="small"
                    checked={
                      this.state.organization!.newFeature
                        ? this.state.organization!.newFeature
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          newFeature: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('COPY_CASE', 'Copiaza dosar')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="alternativePartnerEditable"
                    size="small"
                    checked={
                      this.state.organization!.uploadFeature
                        ? this.state.organization!.uploadFeature
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          uploadFeature: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('UPLOAD_MESSAGE', 'Caracteristici Upload')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="alternativePartnerEditable"
                    size="small"
                    checked={
                      this.state.organization!.showOrderDetails
                        ? this.state.organization!.showOrderDetails
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          showOrderDetails: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('ORDERS_DIALOG_TITLE', 'Detalii comanda')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />

              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="changeCaseStatusBasedCalculation"
                    size="small"
                    checked={
                      this.state.organization!.changeCaseStatusBasedCalculation
                        ? this.state.organization!.changeCaseStatusBasedCalculation
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          changeCaseStatusBasedCalculation: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('CHANGE_STATUS_BASED_CALCULATION', 'Stare calculatie')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
               <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="bulkNotification"
                    size="small"
                    checked={
                      this.state.organization!.bulkNotification
                        ? this.state.organization!.bulkNotification
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          bulkNotification: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('NOTIFICATION_HISTORY_LABEL', 'Notificari cu istoric')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="isMavoPartener"
                    size="small"
                    checked={
                      this.state.organization!.isMavoPartener
                        ? this.state.organization!.isMavoPartener
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          isMavoPartener: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('MAVO_ORG_PARTNER', 'Partener Mavo')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="isMavoPartener"
                    size="small"
                    checked={
                      this.state.organization!.isCarfixPartener
                        ? this.state.organization!.isCarfixPartener
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          isCarfixPartener: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('CARFIX_ORG_PARTNER', 'Partener Carfix App')}
                  </Typography>
                }
                labelPlacement="start"
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="hasAppointmentWorkflow"
                    size="small"
                    checked={
                      this.state.organization!.hasAppointmentWorkflow
                        ? this.state.organization!.hasAppointmentWorkflow
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          hasAppointmentWorkflow: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('CARFIX_ORG_APPOINTMENT_WORKFLOW', 'Programare simpla')}
                  </Typography>
                }
                labelPlacement="start"
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="hasRepairWorkflow"
                    size="small"
                    checked={
                      this.state.organization!.hasRepairWorkflow
                        ? this.state.organization!.hasRepairWorkflow
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          hasRepairWorkflow: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('CARFIX_ORG_REPAIR_WORKFLOW', 'Flux Reparatie')}
                  </Typography>
                }
                labelPlacement="start"
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="hasMentainanceWorkflow"
                    size="small"
                    checked={
                      this.state.organization!.hasMentainanceWorkflow
                        ? this.state.organization!.hasMentainanceWorkflow
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          hasMentainanceWorkflow: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('CARFIX_ORG_MAINTENANCE_WORKFLOW', 'Flux Mentenanta')}
                  </Typography>
                }
                labelPlacement="start"
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="includeToMvo"
                    size="small"
                    checked={
                      this.state.organization!.includeToMvo
                        ? this.state.organization!.includeToMvo
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          includeToMvo: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('INCLUDE_TO_MVO_LABEL', 'Include in MVO')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="showCaseStatus"
                    size="small"
                    checked={
                      this.state.organization!.showCaseStatus
                        ? this.state.organization!.showCaseStatus
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          showCaseStatus: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('SHOW_CASE_STATUS_COLUMN_LABEL', 'Include Coloana Status')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="hideOrganizationOwnerWorkflows"
                    size="small"
                    checked={
                      this.state.organization!.hideOrganizationOwnerWorkflows
                        ? this.state.organization!.hideOrganizationOwnerWorkflows
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          hideOrganizationOwnerWorkflows: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('HIDE_ORGANIZATION_OWNER_WORKFLOWS_COLUMN_LABEL', 'Ascunde fluxuri pe care nu este partener')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="hidePartnerTab"
                    size="small"
                    checked={
                      this.state.organization!.hidePartnerTab
                        ? this.state.organization!.hidePartnerTab
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          hidePartnerTab: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('HIDE_ORGANIZATION_PARTNER_TAB_COLUMN_LABEL', 'Ascunde tab dosare partneri + intern')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="showMakeModelSearch"
                    size="small"
                    checked={
                      this.state.organization!.showMakeModelSearch
                        ? this.state.organization!.showMakeModelSearch
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          showMakeModelSearch: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('ORGANIZATION_SHOW_MAKE_MODEL_SEARCH_COLUMN_LABEL', 'Cautare dupa marca + model in dosare')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <FormControlLabel
                className="m-2"
                control={
                  <Switch
                    id="hideLkqColumns"
                    size="small"
                    checked={
                      this.state.organization!.hideLkqColumns
                        ? this.state.organization!.hideLkqColumns
                        : false
                    }
                    onChange={(e) =>
                      this.setState({
                        organization: {
                          ...this.state.organization!,
                          hideLkqColumns: e.target.checked
                        }
                      })
                    }
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="secondary"
                    className="font-weight-bold"
                    align="left"
                  >
                    {this.translatorService.Tranlate('ORGANIZATION_HIDE_LKQ_COLUMN_LABEL', 'Ascunde coloane LKQ in dosare')}
                  </Typography>
                }
                labelPlacement="start"
                hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
              />
            </WrapperSimple>

            <WrapperSimple
              sectionHeading={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_CONTACT_SECTION',
                'Date de contact'
              )}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="fullName"
                    className="m-2"
                    id="fullName"
                    value={this.state.organization.fullName || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FULL_NAME_LABEL',
                      'Nume Complet'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="companyNumber"
                    className="m-2"
                    id="companyNumber"
                    value={this.state.organization.companyNumber || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_COMPANY_NUMBER_LABEL',
                      'CUI'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                    disabled={this.hasRight(RoleClaimsEnum.AdminService)}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="registryNumber"
                    className="m-2"
                    id="registryNumber"
                    value={this.state.organization.registryNumber || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_REGISTRY_NUMBER_LABEL',
                      'Nr. Reg. Comertului'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                    disabled={this.hasRight(RoleClaimsEnum.AdminService)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    id="country"
                    className="m-2"
                    options={this.state.countryList}
                    value={this.state.organization.country}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleCountryChange(newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name="country"
                        value={this.state.organization.country || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_ORGANIZATION_COUNTRY_LABEL',
                          'Tara'
                        )}
                        fullWidth
                        validators={[]}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    id="region"
                    className="m-2"
                    options={this.getRegionsOptions()}
                    value={this.state.organization.region}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleRegionChange(newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name="region"
                        value={this.state.organization.region || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_ORGANIZATION_COUNTY_LABEL',
                          'Judet'
                        )}
                        fullWidth
                        validators={[]}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    id="cityInfo"
                    className="m-2"
                    options={this.getCitiesOptions()}
                    value={this.state.organization.cityInfo}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleCityInfoChange(newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name="cityInfo"
                        value={this.state.organization.cityInfo || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_ORGANIZATION_CITY_1_LABEL',
                          'Oras'
                        )}
                        fullWidth
                        validators={[]}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} 
                  lg={6}                     
                  hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
                  >
                  <TextValidator
                    fullWidth
                    name="city"
                    className="m-2"
                    id="city"
                    value={this.state.organization.city || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_CITY_LABEL',
                      'Localitate'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="address"
                    className="m-2"
                    id="address"
                    value={this.state.organization.address || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_ADDRESS_LABEL',
                      'Adresa'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="postalCode"
                    className="m-2"
                    id="postalCode"
                    value={this.state.organization.postalCode || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_POSTALCODE_LABEL',
                      'Cod Postal'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="contactPerson"
                    className="m-2"
                    id="contactPerson"
                    value={this.state.organization.contactPerson || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_CONTACT_PERSON_LABEL',
                      'Persoana Contact'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="phone"
                    className="m-2"
                    id="phone"
                    value={this.state.organization.phone || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_PHONE_LABEL',
                      'Telefon'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="email"
                    className="m-2"
                    id="email"
                    value={this.state.organization.email || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_EMAIL_LABEL',
                      'E-mail'
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="dealerId"
                    className="m-2"
                    id="dealerId"
                    value={this.state.organization.dealerId || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_DEALER_LABEL',
                      'Dealer Id'
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="latitude"
                    className="m-2"
                    id="latitude"
                    value={this.state.organization.latitude || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_LATITUDE_LABEL',
                      'Latitudine'
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="longitude"
                    className="m-2"
                    id="longitude"
                    value={this.state.organization.longitude || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_LONGITUDE_LABEL',
                      'Longitudine'
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="bufferValue"
                    className="m-2"
                    id="bufferValue"
                    value={this.state.organization.bufferValue || ''}
                    onChange={(e: any) => this.handleValueChange(e.target.value)}
                    label={this.translatorService.Tranlate(
                      'COUNTRY_BUFFER_ORGANIZATION_VALUE_LABEL',
                      'Nr. zile'
                    )}
                    validators={['minNumber:0']}
                    InputProps={{
                      inputComponent: this.NumberFormatCustom
                    }}
                  />
                </Grid>
                {this.state.organization.organizationType && this.state.organization.organizationType!.code == OrganizationTypeCode.SUPPLIER && (
                  <Grid item xs={12} lg={6}>
                   <Autocomplete
                     id="partQuality"
                     className="m-2"
                     options={this.state.partQualityTypes.sort(function (a, b) {
                       return a.displayName.localeCompare(b.displayName);
                     })}
                     value={this.state.organization!.categoryPart}
                     onChange={(e: any, newValue: IReferential | null) =>
                       this.handleCategoryPartChange(newValue)
                     }
                     getOptionLabel={(option: IReferential) => option.displayName}
                     renderInput={(params) => (
                       <TextValidator
                         {...params}
                         name="categoryPart"
                         value={this.state.organization!.categoryPart}
                         label={this.translatorService.Tranlate(
                           'ORGANIZATION_QUALITY_HEADER',
                           'Calitate'
                         )}
                         fullWidth
                       />
                     )}
                   />
                 </Grid>
                )}
               
              </Grid>
            </WrapperSimple>

            <WrapperSimple
              sectionHeading={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_WORK_PROGRAM_SECTION',
                'Program de lucru'
              )}
            >
              <Grid container spacing={4}>
                {/* Luni-Vineri */}
                <Grid item xs={12} style={{paddingBottom: 0}}>
                  {this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_WORK_PROGRAM_MONDAY_TO_FRIDAY_SECTION',
                      'Luni-Vineri')}     
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingTop: '5px'}}>
                  <TextValidator
                    name="mondayToFridayOpen"
                    value={this.state.organization.mondayToFridayOpen || ''}
                    label={this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_FROM', 'De la')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, 'mondayToFridayOpen')}
                    error={!this.isValidTime(this.state.organization.mondayToFridayOpen)}
                    helperText={!this.isValidTime(this.state.organization.mondayToFridayOpen) ? this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.') : ""}
                    fullWidth
                    validators={['isTimeValid']}
                    errorMessages={[
                      this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.')
                    ]}
                    disabled={!!this.state.organization.mondayToThursdayOpen || !!this.state.organization.mondayToThursdayClose}
                  />
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingTop: '5px'}}>
                  <TextValidator
                    name="mondayToFridayClose"
                    value={this.state.organization.mondayToFridayClose || ''}
                    label={this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_TO', 'Pana la')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, 'mondayToFridayClose')}
                    error={!this.isValidTime(this.state.organization.mondayToFridayClose)}
                    helperText={!this.isValidTime(this.state.organization.mondayToFridayClose) ? this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.') : ""}
                    fullWidth
                    validators={['isTimeValid']}
                    errorMessages={[
                      this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.')
                    ]}
                    disabled={!!this.state.organization.mondayToThursdayOpen || !!this.state.organization.mondayToThursdayClose}
                  />
                </Grid>

                {/* Luni-Joi */}
                <Grid item xs={12} style={{paddingBottom: 0, paddingTop: 0}}>
                  {this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_WORK_PROGRAM_MONDAY_TO_THURSDAY_SECTION',
                    'Luni-Joi')}  
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingTop: '5px'}}>
                  <TextValidator
                    name="mondayToThursdayOpen"
                    value={this.state.organization.mondayToThursdayOpen || ''}
                    label={this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_FROM', 'De la')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, 'mondayToThursdayOpen')}
                    error={!this.isValidTime(this.state.organization.mondayToThursdayOpen)}
                    helperText={!this.isValidTime(this.state.organization.mondayToThursdayOpen) ? this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.') : ""}
                    fullWidth
                    validators={['isTimeValid']}
                    errorMessages={[
                      this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.')
                    ]}
                    disabled={!!this.state.organization.mondayToFridayOpen || !!this.state.organization.mondayToFridayClose}
                  />
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingTop: '5px'}}>
                  <TextValidator
                    name="mondayToThursdayClose"
                    value={this.state.organization.mondayToThursdayClose || ''}
                    label={this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_TO', 'Pana la')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, 'mondayToThursdayClose')}
                    error={!this.isValidTime(this.state.organization.mondayToThursdayClose)}
                    helperText={!this.isValidTime(this.state.organization.mondayToThursdayClose) ? this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.') : ""}
                    fullWidth
                    validators={['isTimeValid']}
                    errorMessages={[
                      this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.')
                    ]}
                    disabled={!!this.state.organization.mondayToFridayOpen || !!this.state.organization.mondayToFridayClose}
                  />
                </Grid>

                {/* Vineri */}
                <Grid item xs={12} style={{paddingBottom: 0, paddingTop: 0}}>
                  {this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_WORK_PROGRAM_FRIDAY_SECTION',
                    'Vineri')}  
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingTop: '5px'}}>
                  <TextValidator
                    name="fridayOpen"
                    value={this.state.organization.fridayOpen || ''}
                    label={this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_FROM', 'De la')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, 'fridayOpen')}
                    error={!this.isValidTime(this.state.organization.fridayOpen)}
                    helperText={!this.isValidTime(this.state.organization.fridayOpen) ? this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.') : ""}
                    fullWidth
                    validators={['isTimeValid']}
                    errorMessages={[
                      this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.')
                    ]}
                    disabled={!!this.state.organization.mondayToFridayOpen || !!this.state.organization.mondayToFridayClose}
                  />
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingTop: '5px'}}>
                  <TextValidator
                    name="fridayClose"
                    value={this.state.organization.fridayClose || ''}
                    label={this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_TO', 'Pana la')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, 'fridayClose')}
                    error={!this.isValidTime(this.state.organization.fridayClose)}
                    helperText={!this.isValidTime(this.state.organization.fridayClose) ? this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.') : ""}
                    fullWidth
                    validators={['isTimeValid']}
                    errorMessages={[
                      this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.')
                    ]}
                    disabled={!!this.state.organization.mondayToFridayOpen || !!this.state.organization.mondayToFridayClose}
                  />
                </Grid>

                {/* Sambata */}
                <Grid item xs={12} style={{paddingBottom: 0, paddingTop: 0}}>
                  {this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_WORK_PROGRAM_SATURDAY_SECTION',
                    'Sambata')}  
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingTop: '5px'}}>
                  <TextValidator
                    name="saturdayOpen"
                    value={this.state.organization.saturdayOpen || ''}
                    label={this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_FROM', 'De la')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, 'saturdayOpen')}
                    error={!this.isValidTime(this.state.organization.saturdayOpen)}
                    helperText={!this.isValidTime(this.state.organization.saturdayOpen) ? this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.') : ""}
                    fullWidth
                    validators={['isTimeValid']}
                    errorMessages={[
                      this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.')
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6} style={{paddingTop: '5px'}}>
                  <TextValidator
                    name="saturdayClose"
                    value={this.state.organization.saturdayClose || ''}
                    label={this.translatorService.Tranlate('DASHBOARD_DATE_LABEL_TO', 'Pana la')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(e, 'saturdayClose')}
                    error={!this.isValidTime(this.state.organization.saturdayClose)}
                    helperText={!this.isValidTime(this.state.organization.saturdayClose) ? this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.') : ""}
                    fullWidth
                    validators={['isTimeValid']}
                    errorMessages={[
                      this.translatorService.Tranlate('INVALID_TIME_FORMAT', 'Invalid time format. Please enter a time in HH:mm format.')
                    ]}
                  />
                </Grid>
              </Grid>
            </WrapperSimple>

            <div className="text-right">
              <Button
                className="m-2"
                variant="outlined"
                color="primary"
                onClick={this.props.organizationChanged}
              >
                {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
              </Button>
              <Button className="m-2" variant="contained" color="primary" type="submit">
                {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
              </Button>
            </div>
          </ValidatorForm>
        </Grid>
      </Grid>
    );
  }

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="standard"
              onChange={this.onTabChange}
            >
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_EDIT_TAB',
                  'Informatii Entitate'
                )}
              />
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_EDIT_BANK_ACCOUNTS_TAB',
                  'Detalii Bancare'
                )}
              />
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate('ORGANIZATION_EDIT_LOGO_TAB', 'Logo')}
              />
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate('ORGANIZATION_EDIT_TOKEN_TAB', 'Token')}
              />
            </Tabs>
          </CardContent>
        </Card>
        <TabPanel value={this.state.selectedTab} index={0}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderForm() : null}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <OrganizationBankAccounts {...this.props} organizationId={this.props.organizationId} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={2}>
          <OrganizationLogo {...this.props} organizationId={this.props.organizationId} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={3}>
          <OrganizationTokens {...this.props} organizationId={this.props.organizationId} />
        </TabPanel>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalEditOrganizationsProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(EditOrganization as any));
