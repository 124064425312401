import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, Grid} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import {
  ReferentialCode,
  InsuredTypeCode
} from '../../helpers/Constants';
import { AppUser } from '../../interfaces/AppUser';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import NumberFormat from 'react-number-format';
import { CaseClient } from '../../interfaces/Case';
import { CNP } from 'romanian-personal-identity-code-validator';
import * as R from 'ramda';
import { isNullOrUndefined } from 'util';

interface ICaseDetailsCaseTabIntegrationClientDataFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isUserHidden: boolean;
  clientData: CaseClient;
  personTypes: Array<IReferential>;
}

export interface ExternalCaseDetailsCaseTabIntegrationClientDataFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabIntegrationClientDataFormProps = ExternalCaseDetailsCaseTabIntegrationClientDataFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabIntegrationClientDataForm extends React.PureComponent<
ICaseDetailsCaseTabIntegrationClientDataFormProps,
ICaseDetailsCaseTabIntegrationClientDataFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    isLoading: false,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    clientData: {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      caseSectionStepFormId: this.props.workflowForm.id,
      clientId: 0,
      pin: '',
      companyNumber: '',
      companyName: '',
      gdprAgreement: true,
      address: '',
      city: null,
      region: null,
      district: null,
      bankAccount: '',
      email: '',
      phone: ''
    } as CaseClient
  } as ICaseDetailsCaseTabIntegrationClientDataFormState;

  inputTextValidator = React.createRef<TextValidator>();

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseClientDataForm();
      }
    );
  }

  loadCaseClientDataForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const casee = this.props.caseSettingsState.case;

    let caseClient = this.state.clientData;
    if (!R.isNil(casee!.caseClient)) {
      caseClient = casee!.caseClient;
    }

    const personTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PersonType
    );

    const personTypeList = await this.appReferentialService.Get(personTypeRef!.baseUrl);

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    console.log('personTypeList', personTypeList);
    this.setState({
      ...this.state,
      isLoading: false,
      hasRights: hasRights,
      isUserHidden: !hasRights,
      personTypes: personTypeList,
      clientData: caseClient
    });
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  submitClientData = async (e: any) => {
    try {
      this.setState({ executing: true });

      let newCaseClient = {
        ...this.state.clientData,
        caseSectionStepFormId: this.props.workflowForm.id
      };

      if (this.state.clientData.id === 0) {
        newCaseClient = await this.caseService.AddCaseClient(this.state.clientData);
      } else {
        await this.caseService.UpdateCaseClient(newCaseClient);
      }

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      this.props.SetCaseClientData(newCaseClient);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  handleCNPChange = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        pin: e.target.value
      }
    });
  };

  handlePersonTypeChange = (newValue: IReferential | null) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        personType: newValue,
        personTypeId: newValue === null ? null : newValue.id
      }
    });
  };

  handleTextChange = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        email: e.target.value
      }
    });
  };

  handleAddressChange = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        address: e.target.value
      }
    });
  };

  handleBankAccountChange = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        bankAccount: e.target.value
      }
    });
  };

  handleIsActiveAgreement = (e: any) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        gdprAgreement: e.currentTarget.checked
      }
    });
  };


  handleCnpCuiOnBlur = async (newValue: any) => {
    if (
      isNullOrUndefined(this.state.clientData.personType) ||
      (this.state.clientData.pin.length == 0 && this.state.clientData.companyNumber.length == 0)
    ) {
      return;
    }
    this.setState({ isLoading: true });

    const pin_cui =
      this.state.clientData.personType!.code === InsuredTypeCode.PJ
        ? this.state.clientData.companyNumber
        : this.state.clientData.pin;

    const clients = await this.caseService.SearchClient(
      this.state.clientData.personType!.id,
      pin_cui,
      ''
    );

    if (clients.length > 0) {
      const client = clients[0];
      this.setState({
        ...this.state,
        clientData: {
          ...this.state.clientData,
          clientId: client.id,
          companyName: client.companyName,
          companyNumber: client.companyNumber,
          contactPerson: client.contactPerson,
          phone: client.phone,
          firstName: client.firstName,
          lastName: client.lastName,
          email: client.email,
          address: client.address
        }
      });
    }

    this.setState({ isLoading: false });
  };


  rendeClientDataForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitClientData(e);
        }}
      >
        <div className="m-3 text-center">
          <Autocomplete
            disabled={!this.state.hasRights}
            id="person-type"
            className="m-2"
            options={this.state.personTypes}
            value={this.state.clientData.personType || null}
            onChange={(e: any, newValue: IReferential | null) =>
              this.handlePersonTypeChange(newValue)
            }
            getOptionLabel={(option: IReferential) => option.displayName || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="personType"
                value={this.state.clientData.personType}
                label={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_PERSON_TYPE',
                  'Tip persoana'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />

          {!R.isNil(this.state.clientData.personType!) &&
          this.state.clientData.personType!.code === InsuredTypeCode.PJ ? (
            <div>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="client-cui"
                className="m-2"
                name="companyNumber"
                placeholder={this.translatorService.Tranlate('CLIENT_DATA_FORM_CUI', 'CUI')}
                value={this.state.clientData.companyNumber || ''}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      companyNumber: e.target.value
                    }
                  });
                }}
                onBlur={async (e: any) => {
                  await this.handleCnpCuiOnBlur(e);
                }}
                label={this.translatorService.Tranlate('CLIENT_DATA_FORM_CUI', 'CUI')}
              />

              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="client-company-name"
                className="m-2"
                name="companyName"
                placeholder={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_COMPANY_NAME',
                  'Nume companie'
                )}
                value={this.state.clientData.companyName || ''}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      companyName: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_COMPANY_NAME',
                  'Nume companie'
                )}
              />

              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                name="contactPerson"
                id="client-contact-person"
                className="m-2"
                value={this.state.clientData.contactPerson || ''}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      contactPerson: e.target.value
                    }
                  });
                }}
                placeholder={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_CONTACT_PERSON',
                  'Persoana de contact'
                )}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'CLIENT_DATA_FORM_CONTACT_PERSON',
                  'Persoana de contact'
                )}
              />
            </div>
          ) : (
            <div>
              <TextValidator
                value={(this.state.clientData.firstName || '') + ' ' + (this.state.clientData.lastName || '')}
                fullWidth
                disabled={!this.state.hasRights}
                id="fullName"
                name="fullName"
                className="m-2"
                placeholder={this.translatorService.Tranlate('CLIENT_DATA_FORM_FULL_NAME', 'Nume complet')}
                onChange={(e: any) => {
                  this.setState({
                    clientData: {
                      ...this.state.clientData,
                      firstName: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('CLIENT_DATA_FORM_FULL_NAME', 'Nume complet')}
              />
            </div>
          )}

          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="client-email"
            name="email"
            className="m-2"
            placeholder={this.translatorService.Tranlate('CLIENT_DATA_FORM_EMAIL', 'E-mail')}
            value={this.state.clientData.email || ''}
            onChange={(e) => this.handleTextChange(e)}
            validators={['isEmail']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_EMAIL', 'E-mail invalid')
            ]}
            label={this.translatorService.Tranlate('CLIENT_DATA_FORM_EMAIL', 'E-mail')}
          />

          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            onChange={(e: any) => {
              this.setState({
                clientData: {
                  ...this.state.clientData,
                  phone: e.target.value
                }
              });
            }}
            name="phone"
            className="m-2"
            label={this.translatorService.Tranlate('CLIENT_DATA_FORM_PHONE', 'Telefon')}
            value={this.state.clientData.phone || ''}
          />

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="text-left">
                <TextValidator
                  fullWidth
                  disabled={!this.state.hasRights}
                  id="client-address"
                  name="address"
                  className="m-2"
                  placeholder={this.translatorService.Tranlate(
                    'CLIENT_DATA_FORM_ADDRESS',
                    'Adresa'
                  )}
                  value={this.state.clientData.address || ''}
                  onChange={(e: any) => {
                    this.setState({
                      ...this.state,
                      clientData: {
                        ...this.state.clientData,
                        address: e.target.value
                      }
                    });
                  }}
                  label={this.translatorService.Tranlate('CLIENT_DATA_FORM_ADDRESS', 'Adresa')}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendeClientDataForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabIntegrationClientDataFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabIntegrationClientDataForm as any));
