import React, { Fragment, useEffect, useState } from 'react';
import { Box, CardContent} from '@material-ui/core';
import { Card } from 'reactstrap';
import moment from 'moment';
import { AppointmentHistoryType } from '../../types/Agenda';
import { TranslatorService } from '../../services/TranslatorService';
import { useAppSelector } from '../../store/configureStore';

const AppointmentHistory = ({ appointmentHistory, isLoading }: { appointmentHistory?: AppointmentHistoryType[], isLoading: boolean}) => {
  const translatorService = new TranslatorService();
  const language = useAppSelector((state)=>state.app?.language);
  const longDateFormat = useAppSelector((state)=>state.app?.longDateFormat);

  const checkDate = (a, b) => {
    const dataA = new Date(a.date).getTime();
    const dataB = new Date(b.date).getTime();
    if (dataA > dataB) {
      return -1;
    } else if (dataA < dataB) {
      return 1;
    } else {
      return 0;
    }
  }

  const showTimeline = () => {
    if (appointmentHistory?.length === 0 || !appointmentHistory) {
      return (
        <b>
          {translatorService.Tranlate(
            'TARIFF_EDIT_LABOR_TAB_NO_DATA',
            'Nu exista date de afisat!'
          )}
        </b>
      );
    }

    const getTranslations = (status:any) =>{
      console.log(status.translations, language)
      const indexLang = status.translations.findIndex((trans: any) => trans.language === language);
      return indexLang !== -1 ? status.translations[indexLang].name : status.name;
    }

    return <>{appointmentHistory.sort(checkDate).map((appointmenObj: AppointmentHistoryType) => (
      <div key={appointmenObj?.date?.toString()} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon " />
          <p className="timeline-item--label mb-2 font-weight-bold">
            {getTranslations(appointmenObj.appointmentStatus) + ' (' + appointmenObj?.modifiedByUser?.userName + ')'}
          </p>
          <p className="timeline-item--label mb-2 font-weight-bold">{appointmenObj.comment}</p>
          <p className="timeline-item--content font-weight-bold">
            {moment
              .utc(appointmenObj?.date)
              .local()
              .format(longDateFormat)}
          </p>
        </div>
      </div>
    ))}</>
  };

  return (<Fragment>
    {!isLoading ? (
      <Box className="pt-3">
        <Card className="card-box  overflow-visible">
          <CardContent className="p-3">
            <div className="m-4 p-4">
              <div className="timeline-list  timeline-list--primary">
                {' '}
                {showTimeline()}{' '}
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
    ) : null}
  </Fragment>
  );
};

export default AppointmentHistory;
