import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Chip, Grid } from '@material-ui/core';
import {
  AcquisitionFiltersState,
  AcquisitionFilterStateKeys
} from '../../../interfaces/Dashboard/AcquisitionFiltersState';
import isNil from 'lodash/isNil';
import { ApplicationContext } from '../../../context/Contexts';
import { AcquisitionResult } from '../../../interfaces/Dashboard/AcquisitionResult';
import { DashboardFilter } from '../../../interfaces/Dashboard/DashboardFilter';
interface Props {
  filters: AcquisitionFiltersState;
  data?: AcquisitionResult;
  setFiltersValueByName(name: string, value: any): void;
  resetFilters(): void;
}

const AcquisitionDashboardAppliedFilters: React.FC<Props> = ({
  filters,
  data,
  setFiltersValueByName,
  resetFilters
}) => {
  const [orgName, setOrgName] = useState<string | undefined>();
  const context = useContext(ApplicationContext);

  const appliedFilters = useMemo(() => {
    if (!data || !filters) {
      return;
    }
    const result: DashboardFilter[] = [];
    Object.values(AcquisitionFilterStateKeys).forEach((value) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const id = filters[value];
      if (!isNil(id)) {
        result.push({ id: id, propertyName: value });
      }
    });

    for (const item of result) {
      switch (item.propertyName) {
        case AcquisitionFilterStateKeys.MAKE_ID:
          item.displayName = data.purchasedVehiclesByMaker.find(
            ({ id }) => id === item.id
          )?.displayName;
          break;
        case AcquisitionFilterStateKeys.LOCATION_ID:
          item.displayName = orgName;
          break;
        case AcquisitionFilterStateKeys.CLIENT_TYPE_ID:
          item.displayName = data.purchasedVehiclesByClientType.find(
            ({ id }) => id === item.id
          )?.displayName;
          break;
        case AcquisitionFilterStateKeys.OFFER_TYPE_ID:
          item.displayName = data.purchasedVehiclesByOfferType.find(
            ({ id }) => id === item.id
          )?.displayName;
          break;
      }
    }

    return result;
  }, [data, filters, orgName]);

  useEffect(() => {
    const getOrgName = async () => {
      if (!isNil(filters.locationId)) {
        const organization = await context.organizationService.GetOrganization(filters.locationId);

        if (organization) {
          setOrgName(organization.displayName);
        }
      } else {
        setOrgName(undefined);
      }
    };

    getOrgName();
  }, [context.organizationService, filters.locationId]);

  const handleDelete = useCallback(
    (propertyName: string) => {
      setFiltersValueByName(propertyName, undefined);
    },
    [setFiltersValueByName]
  );

  if (!appliedFilters?.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Card className="h-100 p-2">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h5>
                {context.translatorService.Tranlate(
                  'DASHBOARD_APPLIED_FILTERS',
                  'Filtre aplicate1'
                )}
              </h5>
            </div>
            <div>
              <Button className="m-2" variant="outlined" color="secondary" onClick={resetFilters}>
                {context.translatorService.Tranlate('DASHBOARD_RESET_FILTERS', 'Reseteaza filtre1')}
              </Button>
            </div>
          </div>
          {appliedFilters.map((item: DashboardFilter, index) => {
            return (
              <Chip
                key={index}
                label={item.displayName}
                variant="outlined"
                onDelete={() => handleDelete(item.propertyName)}
              />
            );
          })}
        </div>
      </Card>
    </Grid>
  );
};

export default AcquisitionDashboardAppliedFilters;
