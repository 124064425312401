import React from 'react';
import { ITranslatorService } from '../services/Interfaces/ITranslatorService';
import { TranslatorService } from '../services/TranslatorService';

import { IAuthService } from '../services/Interfaces/IAuthService';
import { AuthService } from '../services/AuthService';

import { IWeatherForecastsService } from '../services/Interfaces/IWeatherForecastsService';
import { WeatherForecastsService } from '../services/WeatherForecastsService';

import { IOrganizationService } from '../services/Interfaces/IOrganizationService';
import { OrganizationService } from '../services/OrganizationService';

import { IAppUserService } from '../services/Interfaces/IAppUserService';
import { AppUserService } from '../services/AppUserService';

import { IReferentialService } from '../services/Interfaces/IReferentialService';
import { ReferentialService } from '../services/ReferentialService';

import { ITariffService } from '../services/Interfaces/ITariffService';
import { TariffService } from '../services/TariffService';

import { ICaseService } from '../services/Interfaces/ICaseService';
import { CaseService } from '../services/CaseService';

import { IGtService } from '../services/Interfaces/IGtService';
import { GtService } from '../services/GtService';

import { IWorkflowService } from '../services/Interfaces/IWorkflowService';
import { WorkflowService } from '../services/WorkflowService';

import { ICaseSettingsService } from '../services/Interfaces/ICaseSettingsService';
import { CaseSettingsService } from '../services/CaseSettingsService';

import { IDashboardService } from '../services/Interfaces/IDashboardService';
import { DashboardService } from '../services/DashboardService';

import { IOrderService } from '../services/Interfaces/IOrderService';
import { OrderService } from '../services/OrderService';

import { IReportsService } from '../services/Interfaces/IReportsService';
import { ReportsService } from '../services/ReportsService';

import { IVehicleService } from '../services/Interfaces/IVehicleService';
import { VehicleService } from '../services/VehicleService';

import { ICache } from '../interfaces/Icache';
import { IPptService } from '../services/Interfaces/IPptService';
import { PptService } from '../services/PptService';

export type AppContext = {
  translatorService: ITranslatorService;
  authenticationService: IAuthService;
  weatherForecastsService: IWeatherForecastsService;
  organizationService: IOrganizationService;
  appUserService: IAppUserService;
  referentialService: IReferentialService;
  tariffService: ITariffService;
  workflowService: IWorkflowService;
  caseService: ICaseService;
  gtService: IGtService;
  caseSettingsService: ICaseSettingsService;
  dashboardService: IDashboardService;
  orderService: IOrderService;
  reportsService: IReportsService;
  vehicleService: IVehicleService;
  cache: ICache[];
  pptService:IPptService;
};

const cache = [] as ICache[];

const translatorSrv = new TranslatorService();
const authSrv = new AuthService();
const weatherForecastsService = new WeatherForecastsService();
const organizationService = new OrganizationService();
const appUserAdminService = new AppUserService();
const referentialService = new ReferentialService(cache);
const tariffService = new TariffService();
const workflowService = new WorkflowService();
const vehicleService = new VehicleService();
const pptService = new PptService(translatorSrv);

const caseService = new CaseService(
  tariffService,
  referentialService,
  organizationService,
  appUserAdminService
);
const gtService = new GtService();
const caseSettingsService = new CaseSettingsService(
  workflowService,
  organizationService,
  referentialService
);
const dashboardService = new DashboardService();
const orderService = new OrderService();
const reportsService = new ReportsService();

export const AppContext: AppContext = {
  translatorService: translatorSrv,
  authenticationService: authSrv,
  weatherForecastsService: weatherForecastsService,
  organizationService: organizationService,
  appUserService: appUserAdminService,
  referentialService: referentialService,
  tariffService: tariffService,
  workflowService: workflowService,
  caseService: caseService,
  caseSettingsService: caseSettingsService,
  gtService: gtService,
  dashboardService: dashboardService,
  orderService: orderService,
  reportsService: reportsService,
  vehicleService: vehicleService,
  cache: cache,
  pptService:pptService,
};

export const ApplicationContext = React.createContext<AppContext>(AppContext);
