import * as React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
  Box,
  Grid,
  Container
} from '@material-ui/core';
import LanguageApiCard from './LanguageApiCard';
import LanguageLocalCard from './LanguageLocalCard';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { AppState, ApplicationState } from '../../../store';
import { LanguageApi, LanguageLocal } from '../../../interfaces/Case';
import { ReferentialCode, RoleClaimsEnum } from '../../../helpers/Constants';
import { connect } from 'react-redux';
import { ProviderContext, withSnackbar } from 'notistack';
import { ScaleLoader } from 'react-spinners';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';

interface PoeditorState {
  isLoading: boolean;
  languagesApi: LanguageApi[];
  languagesLocal: LanguageLocal[];
}

type PoeditorProps = { appState: AppState } & ProviderContext;

class PoEditor extends React.Component<PoeditorProps, PoeditorState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  constructor(props: PoeditorProps) {
    super(props);
    this.state = {
      isLoading: false, 
      languagesApi: [],
      languagesLocal: []
    };
  }

  public componentDidMount() {
    console.log(this.props.appState);
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.fetchLanguagesList();
      }
    );
  }
  
  public hasRole = (roleCode: string): boolean => {
    return this.props.appState.appUser?.userRole?.code === roleCode;
  };

  fetchLanguagesList = async () => {
    try {
      const countryRef = Referentials.referential.find((item) => item.code === ReferentialCode.Country);
      const [languagesList, languages, countryList] = await Promise.all([
        await this.translatorService.GetLanguagesList(),
        await this.translatorService.GetLanguages(),
        this.appReferentialService.Get(countryRef!.baseUrl)
      ]);

      if (this.hasRight(RoleClaimsEnum.AdminCountryOrganization) && this.props.appState.appUser?.countryId) {
        const country = countryList.find((item: any) => item.id === this.props.appState.appUser?.countryId);
        this.setState({
          languagesApi: (languagesList.data as LanguageApi[]).filter((item: LanguageApi) => country?.code?.toLowerCase() == item.code.toLowerCase()) || [],
          languagesLocal: languages.data as LanguageLocal[],
          isLoading: false
        });
      } else {
        this.setState({ 
          languagesApi: languagesList.data as LanguageApi[],
          languagesLocal: languages.data as LanguageLocal[],
          isLoading: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  fetchLanguages = async () => {
    try {
      const result = await this.translatorService.GetLanguages();
      this.setState({ languagesLocal: result.data as LanguageLocal[] });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Box m={1} p={1}>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
        <Container>
          <Typography variant="h2" gutterBottom>
            Poditor
          </Typography>
          <Typography variant="h4" gutterBottom style={{ marginTop: '1rem', marginBottom: '2rem' }}>
            {this.translatorService.Tranlate('AVAILABLE_LANGUAGES', 'Limbi disponibile')}
          </Typography>

          <List style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={4} style={{ marginBottom: '-0.5rem' }}>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom>
                  {this.translatorService.Tranlate('LANGUAGES_ON_API', 'Limbi disponibile pe API')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom>
                  {this.translatorService.Tranlate('LANGUAGES_ON_CARFIX', 'Limbi disponibile pe Carfix')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              {this.state.languagesApi?.map((language: LanguageApi) => (
                <React.Fragment key={language.code}>
                  <Grid item xs={6}>
                    <LanguageApiCard language={language} />
                  </Grid>
                  <Grid item xs={6}>
                    <LanguageLocalCard languageApi={language} languagesLocal={this.state.languagesLocal} fetchLanguages={this.fetchLanguages} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </List>
        </Container>
        ) : null}
      </Box>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(PoEditor as any));
