import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Organization } from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import {
  Box,
  Switch,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { IReferential } from '../../../interfaces/IReferential';
import { isNullOrUndefined } from 'util';
import EditOrganization from './EditOrganization';
import CloseIcon from '@material-ui/icons/Close';
import { RoleClaimsEnum } from '../../../helpers/Constants';

interface IOrganizationEntitiesState {
  organizations: Organization[];
  isLoading: boolean;
  isOpenDialog: boolean;
  selectedOrganizationId: number;
}

type IOrganizationEntitiesPros = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class OrganizationEntities extends React.PureComponent<
  IOrganizationEntitiesPros,
  IOrganizationEntitiesState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {
    organizations: [],
    isLoading: false,
    isOpenDialog: false,
    selectedOrganizationId: 0
  } as IOrganizationEntitiesState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizations();
      }
    );
  }

  loadOrganizations = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    const orgList = await this.organizationService.GetOrganizationsByHO(hoId, null);

    this.setState({
      organizations: orgList,
      isLoading: false
    });
  };

  onRowClick = (e: any, id: number) => {
    this.editOrganization(id);
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newOrganizations = [...this.state.organizations];
    const updatedOrgIndex = newOrganizations.findIndex((o) => o.id === id);

    if (updatedOrgIndex >= 0) {
      newOrganizations[updatedOrgIndex] = {
        ...newOrganizations[updatedOrgIndex],
        isActive: event.target.checked
      };

      try {
        await this.organizationService.UpdateOrganization(newOrganizations[updatedOrgIndex]);

        this.setState({ organizations: newOrganizations });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
};


  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_ORGANIZATION_NAME_HEADER',
          'Organizatie'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0])}
              >
                <span>{value}</span>
              </Button>
            );
          }
        }
      },
      {
        name: 'organizationLevelType',
        label: this.translatorService.Tranlate(
          'ORGANIZATION_ENTITIES_HIERARCHY_LEVEL',
          'Nivel ierarhic'
        ),
        options: {
          sort: false,
          customBodyRender: (value: IReferential) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'parent',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_ORGANIZATION_PARENT_HEADER',
          'Entitate Superioara'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Organization) => {
            return isNullOrUndefined(value) ? '' : value.name;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'ORGANIZATIONS_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
               <>
                  <Switch
                    checked={value}
                    onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  { 
                    this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService) ?
                    (
                      value ? this.translatorService.Tranlate('ORGANIZATION_ENTITY_ENABLED', 'Enabled') : 
                      this.translatorService.Tranlate('ORGANIZATION_ENTITY_DISABLED', 'Disabled')
                    ) : 
                    (
                      ''
                    )
                  }
               </>
            );
          }
        }
      }
    ];

    return columns;
  };

  editOrganization = (id: number) => {
    this.setState({ isOpenDialog: true, selectedOrganizationId: id });
  };

  handleClose = (reload: boolean) => {
    if (reload) {
      this.setState({ isOpenDialog: false }, async () => {
        await this.loadOrganizations();
      });
    } else {
      this.setState({ isOpenDialog: false });
    }
  };

  
  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      // responsive: "stacked",
      customToolbar: () => {
        return (
          this.hasRight(RoleClaimsEnum.AdminService) ? null : (
            <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
              <IconButton
                aria-label="add"
                color="primary"
                className="m-2"
                onClick={() => {
                  this.setState({ selectedOrganizationId: 0, isOpenDialog: true });
                }}
              >
                <AddCircleTwoToneIcon />
              </IconButton>
            </Tooltip>
          ) 
        );
      }
    };

    return (
      <Fragment>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.organizations}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isOpenDialog}
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate(
                    'ORGANIZATION_EDIT_MODAL_TITLE',
                    'Editare Organizatie'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1} className="text-right">
                <IconButton
                  aria-label="close"
                  onClick={() => this.handleClose(false)}
                  size={'small'}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <EditOrganization
              {...this.props}
              organizationId={this.state.selectedOrganizationId}
              organizationChanged={() => this.handleClose(true)}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationEntities as any));
