import moment from 'moment';
import { AppointmentStatusType, AppointmentType } from '../types/Agenda';
import { getAppointmentCategoryType, getAppointmentStatus } from '../components/agenda/agenda.requests';

export const sanitizeAppointment = (appointment: AppointmentType) => {
  const { datetime } = appointment;

  if (typeof datetime === 'string') {
    appointment.datetime = JSON.parse(datetime);
  }

  return appointment;
};

// get day as dd/mm/yyyy using moment
export const getDay = (date: Date, dateFormat: string) => {
  return moment(date).format(dateFormat);
};

export const getHourForAppointment = () =>{
  const currentDate = new Date();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  if (minutes >= 0 && minutes <= 29) {
    currentDate.setMinutes(30)
  } else if (minutes >= 31 && minutes <= 59) {
    currentDate.setHours(hours+1)
    currentDate.setMinutes(0)
  }
  return moment(currentDate).format('HH:mm');
}

// get hour as hh:mm using moment
export const getHour = (date: Date | string ) => {
  // if already a string and it's like hh:mm then return it
  if (typeof date === 'string' && date.indexOf(':') > -1) {
    const indexHour = date.indexOf(':');
    return indexHour > 3 ? date.slice(indexHour - 2, indexHour + 3) : date
  }

  return moment(date).format('HH:mm');
};

export type CalendarEvent = {
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  resource?: any;
};

export const formatAppointmentForCalendar = (appointment: AppointmentType, dateFormat: string): CalendarEvent => {
  const appointmentDate = new Date(appointment.date);
  const day = getDay(appointmentDate, dateFormat);
  const hour = getHour(appointmentDate);

  const start = moment(`${day} ${hour}`, 'DD-MM-YYYY HH:mm').toDate();
  const end = moment(start).add(30, 'minutes').toDate();
  return {
    title: `#${appointment.id} la ${hour}`,
    start,
    end,
    resource: appointment
  };
};

const randomNumberBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const appointmentsInDay = (day: Date, appointments: AppointmentType[] | undefined, dateFormat: string) => {
  if(!appointments){
    return [];
  }
  const dayAsString = getDay(day, dateFormat);
  return appointments.filter((appointment) => getDay(new Date(appointment.date), dateFormat).replace(' ', '') === dayAsString.replace(' ', ''));
};

export const getStatusData = (status: string, statusList: any, language: string): { color: string; text: string} => {
  let text = '';
  Object.values(statusList).forEach((statusItem: any) => {
    if(statusItem.code === status){
      const indexLang = statusItem.translations.findIndex((trans: any) => trans.language === language);
      text = indexLang !== -1 ? statusItem.translations[indexLang].name : statusItem.name;
    }
  }
  );
  if (status === statusList?.CANCELED?.code) {
    return { color: '#E57373', text: text };
  }

  if (status === statusList?.INITIATA?.code) {
    return { color: '#FFC107', text: text };
  }

  if (status === statusList?.RESCHEDULED?.code) {
    return { color: '#5383ff', text: text };
  }

  if (status === statusList?.CONFIRMED?.code) {
    return { color: '#81C784', text: text };
  }

  if (status === statusList?.PAYMENT_CONFIRMATION?.code) {
    return { color: '#64B5F6', text: text };
  }

  if (status === statusList?.ESTIMATE_ACCEPTED?.code) {
    return { color: '#408f43', text: text };
  }

  if (status) {
    return { color: '#B0BEC5', text: text };
  }

  return { color: '#B0BEC5', text: 'Necunoscut' };
};


export const generateArrayOfHours = () => {
  const hours = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      hours.push(formattedHour);
    }
  }

  return hours;
};

export const getStatusList = async (setIsLoading: (val: boolean) => void, setValue: any, enqueueSnackbar:any) => {
  setIsLoading(true);

  const response = await getAppointmentStatus();
  const responseData = response.data as Array<AppointmentStatusType>;

  if (!responseData) {
    enqueueSnackbar(
      'Eroare get status list!',
      {
        variant: 'error',
        autoHideDuration: 5000
      }
    );
    return
  }
  const futureStatusList: any = {};
  responseData.forEach((status: AppointmentStatusType) => {
    if(status && status?.code){
      futureStatusList[status?.code] = status
    }
    
  })
  setValue(futureStatusList)
  setIsLoading(false);
}

export const getCategoryTypeList = async (setIsLoading: (val: boolean) => void, setValue: any, enqueueSnackbar: any) => {
  setIsLoading(true);

  const response = await getAppointmentCategoryType();
  if (!response.data) {
    enqueueSnackbar(
      'Eroare get status list!',
      {
        variant: 'error',
        autoHideDuration: 5000
      }
    );
    return
  }
  setValue(response.data)
  setIsLoading(false);
}

const toStartOfWeek = (date:any) => {
  const dayOfWeek = date.getDay();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - dayOfWeek);
}

export const formatDate = (date:string)=>{
  const parts = date.split('-');
  const formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
  const formatedDate = new Date(formattedDateString);
  return formatedDate
}


export const areDatesInSameWeek = (dates:any) => {
  if (dates.length === 0) return false;
  const firstDate = formatDate(dates[0])
  const startOfWeek = toStartOfWeek(firstDate);
  for (let i = 0; i < dates.length; i++) {
    const currentDate = formatDate(dates[i]);
    const currentStartOfWeek = toStartOfWeek(currentDate);

    if (currentStartOfWeek.getTime() !== startOfWeek.getTime()) {
      return false; 
    }
  }

  return true;
}