import { OrderPart } from "./Order";

export class OrderParts {
  private _parts: OrderPart[];
  private _vat: number;

  public constructor(parts: OrderPart[], vat: number) {
    // Filtrare pentru a exclude piesele șterse
    this._parts = parts.filter(item => !item.isDeleted);
    this._vat = vat / 100;
  }

  // Metoda pentru a calcula prețul unei piese
  private getPrice(part: OrderPart): number {
    return part.alternativePartQualityId === null ? part.originalPrice
           : part.alternativePrice ?? 0;
  }

  // Metoda pentru a calcula discount-ul unei piese
  private getDiscount(part: OrderPart): number {
    const discount = part.alternativePartQualityId === null ? part.discount
                    : part.alternativePartDiscount;
    return discount ? discount / 100 : 0;
  }

  // Calcularea sumei totale a pieselor fără discount
  public getAmountPart(): number {
    return this._parts.reduce((sum, part) =>
      sum + part.pieceNr * this.getPrice(part), 0);
  }

  // Calcularea sumei totale a pieselor cu discount
  public getAmountPartWithDiscount(): number {
    return this._parts.reduce((sum, part) =>
      sum + part.pieceNr * this.getPrice(part) * (1 - this.getDiscount(part)), 0);
  }

  // Calcularea totalului discountului
  public totalDiscount(): number {
    return this._parts.reduce((sum, part) =>
      sum + part.pieceNr * this.getPrice(part) * this.getDiscount(part), 0);
  }

  // Calcularea totalului fără TVA
  public totalWithoutVAT(): number {
    const totalParts = this.getAmountPart();
    const discount = this.totalDiscount();
    return totalParts - discount;
  }

  // Calcularea TVA-ului
  public totalVAT(): number {
    return this.totalWithoutVAT() * this._vat;
  }

  // Calcularea totalului final cu TVA
  public total(): number {
    const totalWithoutVAT = this.totalWithoutVAT();
    return totalWithoutVAT + this.totalVAT();
  }
}
