import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Button, Card, Tabs, Tab, CardContent } from '@material-ui/core';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { Language, OrganizationCaseTypeStepForm } from '../../../../interfaces/Organization';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { WrapperSimple } from '../../../../layout-components';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import TabPanel from '../../../_shared/Tabs';
import EditOrganizationFormDesign from './EditOrganizationFormDesign';
import { ScaleLoader } from 'react-spinners';
import { ITranslation } from '../../../../interfaces/IReferential';

interface IEditOrganizationFormState {
  organizationForm: OrganizationCaseTypeStepForm;
  selectedTab: number;
  languages: Language[];
  isLoading: boolean;
}

export interface ExternalEditOrganizationFormsProps {
  organizationFormChanged?: (close: boolean) => void;
  organizationFormId: number;
}

type EditOrganizationFormProps = { appState: AppState } & ProviderContext &
  ExternalEditOrganizationFormsProps &
  RouteComponentProps<{ id: string }>;

class EditOrganizationForm extends React.PureComponent<
  EditOrganizationFormProps,
  IEditOrganizationFormState
> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {
    organizationForm: {} as OrganizationCaseTypeStepForm,
    selectedTab: 0,
    languages: [],
    isLoading: true
  } as IEditOrganizationFormState;

  public async componentDidMount() {
    // const hoId = Number.parseInt(this.props.match.params.id);

    let organizationForm = {} as OrganizationCaseTypeStepForm;
    const languages = this.activeLanguages();

    if (this.props.organizationFormId !== 0) {
      organizationForm = await this.organizationService.GetOrganizationCaseTypeStepForm(
        this.props.organizationFormId
      );
    }

    const formTranslations = organizationForm.translations;
    languages.forEach((language: Language, index: number) => {
      if (formTranslations.length === 0) {
        formTranslations.push({
          id: 0,
          referentialTypeId: organizationForm.id,
          name: null,
          language: language.code
        });
      } else {
        const indexTrans = formTranslations.findIndex((trans: ITranslation) => {
          return language.code === trans.language;
        });
        if (indexTrans === -1) {
          formTranslations.push({
            id: 0,
            referentialTypeId: organizationForm.id,
            name: null,
            language: language.code
          });
        }
      }
    });
    organizationForm.translations = [...formTranslations];

    this.setState({
      organizationForm: organizationForm,
      languages: languages,
      isLoading: false
    });
  }
  activeLanguages = () => {
    const activeLanguages =
      this.props.appState.appUser!.organization!.gtOrganization!.languages!.filter(
        (language) => language.isActive === true
      );

    activeLanguages.forEach((language) => {
      language.displayName =
        language.displayName.charAt(0).toUpperCase() + language.displayName.slice(1);
    });
    return activeLanguages;
  };

  handleFormNameChange = (event: any, code: string | null) => {
    let newFormTransList = [] as ITranslation[];
    if (this.state.organizationForm.translations) {
      newFormTransList = this.state.organizationForm.translations.map((loc: any) => {
        if (loc.language === code) {
          loc.name = event.target.value;
        }
        return loc;
      });
    }
    this.setState({
      organizationForm: {
        ...this.state.organizationForm,
        translations: newFormTransList
      }
    });
  };

  compare = (a: ITranslation, b: ITranslation) => {
    const bandA = a.language!.toUpperCase();
    const bandB = b.language!.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = -1;
    } else if (bandA < bandB) {
      comparison = 1;
    }
    return comparison;
  };

  renderNameTextFields = () => {
    if (Object.keys(this.state.organizationForm).length === 0) {
      return null;
    } else {
      const nameTextFieldsList = [] as any[];

      this.state
        .organizationForm!.translations.sort(this.compare)
        .forEach((item: ITranslation, index: number) => {
          nameTextFieldsList.push(
            <TextValidator
              key={index}
              fullWidth
              name={'name_' + index}
              className="m-2"
              value={item.name || ''}
              onChange={(e) => this.handleFormNameChange(e, item.language)}
              label={item.language}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          );
        });
      return nameTextFieldsList;
    }
  };

  public saveOrganizationForm = async () => {
    try {
      await this.organizationService.UpdateOrganizationCaseTypeStepForm(
        this.state.organizationForm
      );

      if (this.props.organizationFormChanged) {
        this.props.organizationFormChanged(false);
      }

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public renderForm() {
    return (
      <Grid container spacing={4} alignContent="center" alignItems="center">
        <Grid item xs={12} lg={12} className="mt-4">
          <ValidatorForm onSubmit={this.saveOrganizationForm} instantValidate={true}>
            <WrapperSimple
              sectionHeading={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_FORM_GENERAL_DATA_GUI_NAME',
                'Nume GUI'
              )}
            >
              {this.renderNameTextFields()}
            </WrapperSimple>

            <div className="text-right">
              <Button
                className="m-2"
                variant="outlined"
                color="primary"
                onClick={() =>
                  this.props.organizationFormChanged
                    ? this.props.organizationFormChanged(true)
                    : null
                }
              >
                {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
              </Button>
              <Button className="m-2" variant="contained" color="primary" type="submit">
                {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
              </Button>
            </div>
          </ValidatorForm>
        </Grid>
      </Grid>
    );
  }

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="standard"
              onChange={this.onTabChange}
            >
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_FORM_EDIT_GENERAL_DATA_TAB',
                  'Date generale'
                )}
              />
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_FORM_EDIT_DESIGN_TAB',
                  'Design'
                )}
              />
              {/* <Tab className="text-capitalize" label={this.translatorService.Tranlate("ORGANIZATION_FORM_EDIT_REPORTS_TAB", "Rapoarte")} />
                            <Tab className="text-capitalize" label={this.translatorService.Tranlate("ORGANIZATION_FORM_EDIT_ACTIONS_TAB", "Actiuni")} /> */}
            </Tabs>
          </CardContent>
        </Card>
        <TabPanel value={this.state.selectedTab} index={0}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderForm() : null}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <EditOrganizationFormDesign
            {...this.props}
            organizationFormId={this.props.organizationFormId}
          />
        </TabPanel>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalEditOrganizationFormsProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(EditOrganizationForm as any));
