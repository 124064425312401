import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import { Client } from '../agenda/agenda.types';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { TranslatorService } from '../../services/TranslatorService';

interface AddNewClientModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (client:Client) => void;
}

const initialState = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
}

interface FormType extends Client{
  [key: string]: any;
}

const AddNewClientModal: React.FC<AddNewClientModalProps> = ({ open, onClose, onSave }) => {
  const [formData, setFormData] = useState<FormType>({...initialState});
  const [formError,setFormError] = useState<FormType>({...initialState});
  const translatorService  = new TranslatorService()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const closeModal = ( )=>{
    onClose();
    setFormError({...initialState})
    setFormData({ ...initialState })
  }

  const handleSave = () => {
    const payload = { ...formData, personTypeId:1}
    onSave(payload)
    closeModal()
  };

  const validateForm = () =>{
    const errors = {...formError};
    let canContinue = true
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && !formData[key]) {
        canContinue = false;
        errors[key] = translatorService.Tranlate(
          'VALIDATORS_REQUIRED',
          'Campul este obligatoriu'
        )
      }else{
        errors[key] = ''
      }
    }
    if(canContinue){
      handleSave();
    }else{
      setFormError(errors)
    }

  }

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={onClose}>
      <DialogTitle>Add new client</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Last name"
          type="text"
          fullWidth
          name="lastName"
          value={formData.lastName}
          helperText={formError.lastName}
          error={Boolean(formError.lastName && !formData.lastName)}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="First name"
          type="text"
          fullWidth
          name="firstName"
          value={formData.firstName}
          helperText={formError.firstName && !formData.firstName ? formError.firstName : ''}
          error={Boolean(formError.firstName && !formData.firstName)}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Phone"
          type="tel"
          fullWidth
          name="phone"
          value={formData.phone}
          helperText={formError.phone && !formData.phone ? formError.phone : ''}
          error={Boolean(formError.phone && !formData.phone)}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="E-mail"
          type="email"
          fullWidth
          name="email"
          value={formData.email}
          helperText={formError.email && !formData.email ? formError.email : ''}
          error={Boolean(formError.email && !formData.email)}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Anulează
        </Button>
        <Button onClick={validateForm} color="primary">
          Salvează
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewClientModal;