import { AppThunkAction } from '..';
import * as ActionTypes from './ActionTypes';
import { AppContext } from '../../context/Contexts';
import { AppuserList, AppUser } from '../../interfaces/AppUser';

interface AppUserGetListStartAction {
  type: typeof ActionTypes.APPUSER_USER_GET_LIST_START;
}
interface AppUserGetListEndAction {
  type: typeof ActionTypes.APPUSER_USER_GET_LIST_END;
  userList: AppuserList;
}

interface AppUserEditAction {
  type: typeof ActionTypes.APPUSER_USER_EDIT;
  appUser: AppUser;
}

export type AppUserAction = AppUserGetListStartAction | AppUserGetListEndAction | AppUserEditAction;

export const AppUserAdminActionCreators = {
  LoadUsers:
    (page: number, rowsPerPage: number): AppThunkAction<AppUserAction> =>
    async (dispatch, getState, context) => {
      const service = (context as AppContext).appUserService;

      dispatch({ type: ActionTypes.APPUSER_USER_GET_LIST_START });

      const users = await service.GetAppUsers();

      dispatch({ type: ActionTypes.APPUSER_USER_GET_LIST_END, userList: users });
    },

  EditUser:
    (appUser: AppUser): AppThunkAction<AppUserAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.APPUSER_USER_EDIT, appUser: appUser });
    }
};
