import { Grid, Paper, Slider, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useMemo, useContext } from 'react';
import { SelectItem } from '../../interfaces/Radar/SelectItem';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { RadarFilters as Filters } from '../../interfaces/Radar/RadarFilters';
import { useSelector } from 'react-redux';
import Loader from '../Loader';
import { ApplicationContext } from '../../context/Contexts';

const SLIDER_MIN = 1;
const SLIDER_MAX = 12;
const SLIDER_STEP = 1;

interface Props {
  filters: Filters;
  setFilters(filters: Filters): void;
}

const RadarFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const {
    app: { language, dateFormat }
  } = useSelector((state: any) => state);

  const context = useContext(ApplicationContext);

  const sliderMarks = useMemo(() => {
    const result = [];
    for (let i = SLIDER_MIN; i <= SLIDER_MAX; i += SLIDER_STEP) {
      result.push({
        value: i,
        label: i.toString()
      });
    }

    return result;
  }, []);

  const makes = useMemo(() => {
    if (filters.selectedMakes.length >= 3) {
      return [];
    }

    const selectedMakesIds = filters.selectedMakes.map(({ id }) => id);
    return filters.makes.filter((c) => !selectedMakesIds.includes(c.id));
  }, [filters.makes, filters.selectedMakes]);

  const models = useMemo(() => {
    if (filters.selectedModels.length >= 3) {
      return [];
    }

    let filterModels = filters.models;
    if (filters.selectedMakes.length) {
      const selectedMakeIds = filters.selectedMakes.map(({ id }) => id);
      filterModels = filterModels.filter((c) => selectedMakeIds.includes(c.makeId!));
    }

    const selectedModelsIds = filters.selectedModels.map(({ id }) => id);
    return filterModels.filter((c) => !selectedModelsIds.includes(c.id));
  }, [filters.models, filters.selectedModels, filters.selectedMakes]);

  const handleFormChange = (value: any, name: string) => {
    if (name === 'selectedMakes') {
      if (!value.length) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const valueIds = value.map((item: any) => item.id);

      if (!valueIds.includes(filters.selectedMakes[0].id)) {
        return;
      }
    }

    if (name === 'selectedModels') {
      if (!value.length) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const valueIds = value.map((item: any) => item.id);

      if (!valueIds.includes(filters.selectedModels[0].id)) {
        return;
      }
    }

    setFilters({
      ...filters,
      [name]: value
    });
  };

  if (!filters.period) {
    return <Loader />;
  }

  return (
    <Paper className="p-4 d-flex justify-content-center">
      <Grid container spacing={2} style={{ width: '75%' }}>
        <Grid item xs={4}>
          <Autocomplete
            id="makes"
            size="small"
            className="mt-2 mb-2"
            multiple
            options={makes}
            value={filters.selectedMakes}
            onChange={(e: any, newValue: any) => handleFormChange(newValue, 'selectedMakes')}
            getOptionLabel={(option: SelectItem) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                name="selectedMakes"
                value={filters.selectedMakes}
                label={context.translatorService.Tranlate('DASHBOARD_CAR_TYPE', 'Marca')}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="selectedModels"
            size="small"
            className="mt-2 mb-2"
            multiple
            options={models}
            value={filters.selectedModels}
            onChange={(e: any, newValue: any) => handleFormChange(newValue, 'selectedModels')}
            getOptionLabel={(option: SelectItem) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                name="selectedModels"
                value={filters.selectedModels}
                label={context.translatorService.Tranlate('DASHBOARD_MODEL', 'Model')}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
            <DatePicker
              variant="inline"
              id="firstRegistrationDate"
              fullWidth
              className="mt-2 mb-2"
              size="small"
              label={context.translatorService.Tranlate(
                'CALCULATION_DETAILS_VEHICAL_FIRST_REGISTRATION_DATE',
                'Data primei inmatriculari'
              )}
              format={dateFormat.toUpperCase()}
              value={filters.firstRegistrationDate}
              onChange={(value) => {
                handleFormChange(value, 'firstRegistrationDate');
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <Typography id="period" gutterBottom>
            {context.translatorService.Tranlate('RADAR_PERIOD_FILTER', 'Perioada')}
          </Typography>
          <Slider
            className="mt-2 mb-2"
            id="period"
            value={filters.period}
            onChange={(e, value) => handleFormChange(value, 'period')}
            valueLabelDisplay="on"
            max={SLIDER_MAX}
            step={SLIDER_STEP}
            min={SLIDER_MIN}
            marks={sliderMarks}
            track={false}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RadarFilters;
