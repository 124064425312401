import React from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  Card,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import {ReferentialCode, mavoVehicleStatuses } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import {  MavoVehicle, MavoVehicleAttachment, MavoVehicleAuction, VehicleOptionInfo } from '../../interfaces/Vehicle';
import TabPanel from '../_shared/Tabs';
import * as R from 'ramda';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import MavoGeneralInformationContainer from './MavoGeneralInformationContainer';
import MavoVehicleOptionsContainer from './MavoVehicleOptionsContainer';
import MavoAttachmentDataContainer from './MavoAttachmentDataContainer';
import { ScaleLoader } from 'react-spinners';
import MavoStatusDataContainer from './MavoStatusDataContainer';
import { VehicleAuctionActionCreators } from '../../store/actions/VehicleAuction';
import NavigationIcon from '@material-ui/icons/Navigation';
import _ from 'lodash';

interface IMavoVehicleDetailsViewFormState {
  isLoading: boolean;
  executing: boolean;
  isUserHidden: boolean;
  vehicle: MavoVehicle;
  selectedTab: number;
  statuses: IReferential[];
  statusOptions: IReferential[];
  selectedDisplayedStatus: string;
  isChangeVehicleStatusDialogOpen: boolean;
}

export interface ExternalMavoVehicleDetailsViewFormProps {
  mavoVehicleAuction: MavoVehicleAuction;
  closeDialog: () => void;
} 

type IMavoVehicleDetailsViewFormProps = ExternalMavoVehicleDetailsViewFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
  mavoVehicleState: MavoVehicleState;
  mavoVehicleAuctionState: MavoVehicleAuction;
} & typeof CaseSettingsActionCreators &
  typeof VehicleActionCreators &
  typeof MavoVehicleActionCreators &  typeof VehicleAuctionActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class MavoVehicleDetailsViewForm extends React.PureComponent<IMavoVehicleDetailsViewFormProps, IMavoVehicleDetailsViewFormState> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    isLoading: true,
    executing: false,
    isUserHidden: false,
    selectedTab: 0,
    statuses: [] as IReferential[],
    statusOptions: [],
    isChangeVehicleStatusDialogOpen: false,
    vehicle: {
      id: 0,
      vin: '',
      make: null,
      makeId: null,
      model: null,
      modelId: null,
      versionItem: null,
      versionId: null,
      color: null,
      colorId: null,
      fuelType: null,
      fuelTypeId: null,
      bodyTypeId: null,
      bodyType: null,
      transmissionTypeId: null,
      transmissionType: null,
      provenance: null,
      provenanceId: null,
      firstRegistrationDate: '',
      fabricationYear: null,
      kilometersNr: null,
      power: null,
      engineManufactureCode: '',
      accessories: null,
      isReserved: false,
      reservations: '',
      cylinderCapacity: null,
      supplier: null,
      location: null,
      htPurchaseRonPrice: null,
      ttcEvaluationEurPrice: null,
      nirNumber: null,
      nirDate: null,
      currentStatus: null,
      vehicleOptions: [],
      vehicleType: null,
      vehicleTypeId: null,
      mavoVehicleAttachments: [] as MavoVehicleAttachment[],
      vehicleOptionInfo: {
        vehicleId: 0,
        engineCapacity: null,
        emissionsClass: null,
        doorCount: null,
        seatCount: null,
        driveType: null,
        registration: null,
        vehicleCondition: null,
        metallicColor: null,
        upholstery: null,
        upholsteryColor: null,
        previousOwnersCount: null,
        countryOfOrigin: null,
        alarm: false,
        antiTheft: false,
        abs: false,
        esp: false,
        driverFrontAirbag: false,
        passengerFrontAirbag: false,
        frontSideAirbags: false,
        frontCurtainAirbag: false,
        xenonHeadlights: false,
        alloyWheels: false,
        glassRoof: false,
        towHook: false,
        protectionFrame: false,
        steeringWheelControls: false,
        onBoardComputer: false,
        cruiseControl: false,
        lockableDifferential: false,
        particleFilter: false,
        electricMirrors: false,
        frontElectricWindows: false,
        rearElectricWindows: false,
        tintedWindows: false,
        heatedSeats: false,
        rainSensors: false,
        parkingSensors: false,
        powerSteering: false,
        sunroof: false,
        rightHandDrive: false,
        fogLights: false,
        airConditioning: false,
        radio: false,
        cdPlayer: false,
        videoScreen: false,
        gps: false
      } as VehicleOptionInfo,
    } as unknown as MavoVehicle,
    selectedDisplayedStatus: ''
  } as IMavoVehicleDetailsViewFormState;

  public componentDidMount() {   
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadMavoVehicle();
      }
    );
  }

  loadMavoVehicle = async () => {
    const statusesTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.VehicleStatus
    );
    const statuses = (await this.appReferentialService.Get(statusesTypeRef!.baseUrl)).filter(x => mavoVehicleStatuses.includes(x.id));
    
    if (R.isNil(this.props.mavoVehicleAuction.mavoVehicle.vehicleOptionInfo)) {
      this.props.mavoVehicleAuction.mavoVehicle.vehicleOptionInfo = _.cloneDeep(this.state.vehicle.vehicleOptionInfo);
    }

    this.props.setMavoVehicleObject(this.props.mavoVehicleAuction.mavoVehicle!);

    const vehicle = this.props.mavoVehicleState.mavoVehicle;

    this.setState({
      isLoading: false,
      vehicle: vehicle,
      statuses: statuses,
      selectedDisplayedStatus: !R.isNil(vehicle?.currentStatus?.status)
        ? vehicle?.currentStatus?.status?.displayName || ''
        : ''
    });
  };

  closeDialog = async () => {
    this.setState({ isChangeVehicleStatusDialogOpen: false });
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState({
      selectedTab: newValue
    });
  };
 

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <div>
        <React.Fragment>
          <Card className="mb-3 p-3">
            <div className="d-flex align-items-center">
              <div style={{ flexBasis: '50%' }}>
              </div>
              <div style={{ flexBasis: '50%' }}>
                <Button
                  className="ml-2 float-right bg-theme-primary"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    this.props.closeDialog();
                  }}
                >
                  <NavigationIcon />
                  {this.translatorService.Tranlate(
                    'MENU_MAVO_VEHICLES_AUCTIONS_RECORD',
                    'Inapoi la lista'
                  )}
                </Button>
              </div>
            </div>
          </Card>
        </React.Fragment>
        <React.Fragment>
          <Card>
            <AppBar position="static" elevation={0} color="secondary">
              <Tabs
                  TabIndicatorProps={{
                    style: {
                      height: '4px'
                    }
                  }}
                  value={this.state.selectedTab}
                  onChange={this.onTabChange}
                  variant="fullWidth"
                  centered={true}
                  indicatorColor="primary"
                  aria-label="simple tabs example"
                >
                  <Tab
                    label={this.translatorService.Tranlate(
                      'MAVO_VEHICLE_GENERAL_INFORMATION_TAB',
                      'Informatii generale'
                    )}
                  />
                  <Tab
                    label={this.translatorService.Tranlate('MAVO_VEHICLE_STATUS_VEHICLE_TAB', 'Stare vehicul')}
                  /> 
                  <Tab
                    label={this.translatorService.Tranlate('MAVO_VEHICLE_OPTIONS_TAB', 'Despre vehicul (Dotari)')}
                  />
                  <Tab
                    label={this.translatorService.Tranlate('MAVO_VEHICLE_PHOTOS_TAB', 'Poze')}
                  />
              </Tabs>
            </AppBar>
            <div>
              <TabPanel value={this.state.selectedTab} index={0}>
                <div className="m-3">
                  <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                    <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
                  </div>
                  {!this.state.isLoading ? (
                    <MavoGeneralInformationContainer
                      {...this.props}
                      statuses={this.state.statuses}
                      disabled={true}
                      mavoVehicleAuction={this.props.mavoVehicleAuction}
                    />
                  ) : null}
                </div>
              </TabPanel>
              <TabPanel value={this.state.selectedTab} index={1}>
                <div className="m-3">
                  {!this.state.isLoading ? 
                    <MavoStatusDataContainer
                      {...this.props}
                      statuses={this.state.statuses} 
                      disabled={true}
                    />
                    : null}
                </div>
              </TabPanel>
              <TabPanel value={this.state.selectedTab} index={2}>
                <div className="m-3">
                  {!this.state.isLoading ? (
                    <MavoVehicleOptionsContainer
                      {...this.props}
                      statuses={this.state.statuses} 
                      disabled={true}
                      vehicleOptionInfo={this.props.mavoVehicleAuction.mavoVehicle.vehicleOptionInfo}
                      />
                  ) : null}
                </div>
              </TabPanel>
              <TabPanel value={this.state.selectedTab} index={3}>
                <div className="m-3">
                  {!this.state.isLoading ? (
                    <MavoAttachmentDataContainer
                      {...this.props}
                      statuses={this.state.statuses}
                      disabled={true} 
                      />
                  ) : null}
                </div>
              </TabPanel>
            </div>
          </Card>
        </React.Fragment>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: ExternalMavoVehicleDetailsViewFormProps) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicleState: state.mavoVehicle,
    mavoVehicleAuctionState: state.mavoVehicleAuction,
  }),
{ ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators, ...VehicleAuctionActionCreators }
)(withSnackbar(MavoVehicleDetailsViewForm as any));
