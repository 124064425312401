import React from 'react';
import { AppUser } from '../../../interfaces/AppUser';
import { connect } from 'react-redux';
import { withSnackbar, ProviderContext } from 'notistack';
import { RouteComponentProps } from 'react-router';
import { Box, TextField, Button, CardContent, Card } from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { ApplicationState } from '../../../store';

interface IUserDetailsUser {
  appUser: AppUser;
}

type ResetPassProps = IUserDetailsUser & RouteComponentProps<{ id: string }> & ProviderContext;

interface IResetPassState {
  isLoading: boolean;
}

class ResetUserPass extends React.PureComponent<ResetPassProps, IResetPassState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  public resetPass = async () => {
    try {
      if (this.props.appUser.id === null) {
        return;
      }

      await this.appUserService.ResetPassword(this.props.appUser.id);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };
  public renderResetPassForm() {
    return (
      <Box m={1} p={1}>
        <Card>
          <CardContent>
            <TextField
              fullWidth
              className="m-2"
              id="filled-basic"
              label={this.translatorService.Tranlate(
                'ADMIN_EDIT_USER_ROLES_USER_LABEL',
                'Utilizator'
              )}
              variant="filled"
              value={this.props.appUser.userName}
            />
            <div className="text-right">
              <Button
                className="m-2"
                variant="contained"
                color="primary"
                disabled={!this.props.appUser.isActive}
                onClick={this.resetPass}
              >
                {this.translatorService.Tranlate(
                  'ADMIN_EDIT_USER_RESETPASS_BTN',
                  'Trimite mail resetare parola'
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appUserService = (this.context as AppContext).appUserService;

    return this.renderResetPassForm();
  }
}

export default connect(
  (state: ApplicationState) => state.appUserAdmin // Selects which state properties are merged into the component's props
)(withSnackbar(ResetUserPass as any));
