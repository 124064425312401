import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { MavoVehicleAuction } from '../../interfaces/Vehicle';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { AppState } from '../../store';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { withSnackbar, ProviderContext } from 'notistack';
import { ScaleLoader } from 'react-spinners';
import { IReferential } from '../../interfaces/IReferential';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

interface MavoVehicleAuctionDialogProps extends ProviderContext {
  appState: AppState;
  isDialogOpenAuction: boolean;
  closeDialogAuction: () => void;
  auctionForm: MavoVehicleAuction;
  handleChangeAuction: (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => void;
  handleStartDateChange: (date: MaterialUiPickersDate | null,value?: string | null) => void;
  handleEndDateChange: (date: MaterialUiPickersDate | null,value?: string | null) => void;
  vehicleId: number | undefined;
}

interface IMavoVehicleAuctionDialogtate {
  executing: boolean;
  isLoading: boolean;
}

class MavoVehicleAuctionDialog extends React.Component<
  MavoVehicleAuctionDialogProps,
  IMavoVehicleAuctionDialogtate
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;

  static contextType = ApplicationContext;

  constructor(props: MavoVehicleAuctionDialogProps) {
    super(props);
    this.state = {
      executing: false,
      isLoading: false
    };
  }
  

  handleSaveAuction = async () => {
    if (this.props.auctionForm.endDate == null) {
      return;
    }

    if (this.checkIfEndDateIsBeforeStartDate()) {
      return;
    }

    const request = _.cloneDeep({
      ...this.props.auctionForm,
      endDate: this.props.auctionForm.endDate + ' 4 PM'
    });

    request.currentPrice = 0;
    try {
      this.setState({ executing: true, isLoading: true });
      const response = await this.vehicleService.AddMavoVehicleAuction(request);
      if (response.success == true) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('AUCTION_SUCCESS', 'Licitatia a fost adaugata cu succes'), { variant: 'success' });
      } else {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('AUCTION_ERROR', 'A aparut o eroare la adaugarea licitatiei'), { variant: 'error' });
      }
      this.setState({ executing: false, isLoading: false });

      this.props.closeDialogAuction();
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('AUCTION_ERROR', 'A aparut o eroare la adaugarea licitatiei'), { variant: 'error' });
      this.setState({ executing: false, isLoading: false});
    }

  }

  checkIfEndDateIsBeforeStartDate = () => {
    if (this.props.auctionForm?.endDate && this.props.auctionForm?.startDate) {
      return moment(this.props.auctionForm?.endDate).isBefore(moment(this.props.auctionForm?.startDate));
    }
    return false;
  }

  NumberDecimalFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  public renderAuctionForm = () => {
    const maxNumberOfCharactersObservations = 300;
    return (
      <div className="text-center">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              id="startPrice"
              className="m-2 pr-3"
              name="startPrice"
              value={this.props.auctionForm.startPrice || ''}
              onChange={(e: any) => {
                this.props.handleChangeAuction('startPrice', true)(e);
              }}
              placeholder={this.translatorService.Tranlate(
                'MAVO_AUCTION_PRICE',
                'Pret licitatie (EUR)'
              )}
              validators={['required', 'minNumber:1']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                ),
                this.translatorService.Tranlate('PRICE_INVALID', 'Pretul nu este valid')
              ]}
              label={this.translatorService.Tranlate(
                'MAVO_AUCTION_PRICE',
                'Pret licitatie (EUR)'
              )}
              InputProps={{
                inputComponent: this.NumberDecimalFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                className="m-2 pr-3"
                fullWidth
                views={['year', 'month', 'date']}
                disablePast
                minDate={moment()}
                format={this.props.appState.dateFormat.toUpperCase()}
                id="startDate"
                name="startDate"
                label={this.translatorService.Tranlate(
                  'MAVO_AUCTION_START_DATE',
                  'Data start'
                )}
                value={this.props.auctionForm?.startDate}
                onChange={this.props.handleStartDateChange}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                className="m-2 pr-3"
                fullWidth
                views={['year', 'month', 'date']}
                disablePast
                minDate={moment(this.props.auctionForm?.startDate || moment().add(1, 'days')).add(1, 'days')}
                error={this.checkIfEndDateIsBeforeStartDate() || this.props.auctionForm.endDate == null}
                helperText={this.checkIfEndDateIsBeforeStartDate()
                  ? this.translatorService.Tranlate('MAVO_AUCTION_INVALID_END_DATE', 'Data stop trebuie sa fie mai mare decat data start')
                  : this.props.auctionForm.endDate == null ? 
                    this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                  : ''
                }
                format={this.props.appState.dateFormat.toUpperCase()}
                id="endDate"
                name="endDate"
                label={this.translatorService.Tranlate(
                  'MAVO_AUCTION_END_DATE',
                  'Data stop'
                )}
                required={true}
                value={this.props.auctionForm?.endDate || null}
                onChange={this.props.handleEndDateChange}          
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              className="m-2 pr-3"
              fullWidth
              id="minDifference"
              name="minDifference"
              value={this.props.auctionForm.minDifference || ''}
              onChange={(e: any) =>
                this.props.handleChangeAuction('minDifference', true)(e)
              }
              placeholder={this.translatorService.Tranlate(
                'MAVO_AUCTION_PRICE_BID_DIFF',
                'Diferenta minima intre pret si valoare licitata (EUR)'
              )}
              validators={['required', 'minNumber:1']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_REQUIRED',
                  'Campul este obligatoriu'
                ),
                this.translatorService.Tranlate('MAVO_AUCTION_INVALID_PRICE_BID_DIFF', 'Diferenta minima nu este valida')
              ]}
              label={this.translatorService.Tranlate(
                'MAVO_AUCTION_PRICE_BID_DIFF',
                'Diferenta minima intre pret si valoare licitata (EUR)'
              )}
              InputProps={{
                inputComponent: this.NumberDecimalFormatCustom
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              required={false}
              fullWidth
              id="details"
              className="my-3"
              name="details"
              placeholder={this.translatorService.Tranlate(
                'MAVO_VEHICLES_AUCTION_DETAILS',
                'Detalii'
              )}
              value={this.props.auctionForm.details || ''}
              onChange={(e: any) =>
                this.props.handleChangeAuction('details', false)(e)
              }              
              label={this.translatorService.Tranlate(
                'MAVO_VEHICLES_AUCTION_DETAILS',
                'Detalii'
              )}
              validators={[`maxStringLength: ${maxNumberOfCharactersObservations}`]}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_MAX_LENGTH',
                  'Numar maxim de caractere: '
                ) + maxNumberOfCharactersObservations
              ]}
              multiline
              variant="outlined"
              rows={6}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  public renderAuctionContent() {
    return (
      <ValidatorForm onSubmit={this.handleSaveAuction} instantValidate={true}>
        {this.renderAuctionForm()}
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.props.closeDialogAuction}
          >
            {this.translatorService.Tranlate('MAVO_VEHICLE_IMPORT_CLOSE_BTN', 'Renunta')}
          </Button>
          <Button className="m-2" 
            variant="contained" 
            color="primary" 
            type="submit"
            disabled={this.state.executing}
          >
            {this.translatorService.Tranlate('SAVE', 'Salveaza')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }

  render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Dialog
        onClose={this.props.closeDialogAuction}
        aria-labelledby="customized-dialog-title"
        open={this.props.isDialogOpenAuction}
        fullScreen={false}
        fullWidth={true}
        >
        <DialogTitle id="customized-dialog-title">
          <Grid container>
            <Grid item xs={11}>
              <Typography className="MuiTypography-h4">
                {this.translatorService.Tranlate(
                  'MAVO_VEHICLES_VEHICLE_DISPOSITION_IN_THE_AUCTION',
                  'Dispunere in licitatie'
                )}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="close" onClick={this.props.closeDialogAuction} size={'small'}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {this.renderAuctionContent()}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(MavoVehicleAuctionDialog);
