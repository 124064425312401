import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {
  Grid,
  TextField
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';
import { ApplicationState, CaseSettingsActionCreators, VehicleActionCreators } from '../../store';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { IReferential } from '../../interfaces/IReferential';
import MomentUtils from '@date-io/moment';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { MavoVehicleStatusCodeEnum, VehicleStatusCodeEnum, allowedMavoVehicleStatuses, disabledMavoVehicleStatuses, disabledNewMavoVehicleStatuses } from '../../helpers/Constants';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { MavoVehicleStatusDetailsProps } from '../cases/vehicleDetails/MavoVehicleStatusDetailsProps';
import { IMavoStatusDataContainerProps } from '../cases/vehicleDetails/IMavoStatusDataContainerProps';
import { Autocomplete } from '@material-ui/lab';

interface IMavoStatusDataContainerState {
  isLoading: boolean;
  executing: boolean;
}


class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

const maxNumberOfCharactersObservations = 300;

class MavoStatusDataContainer extends React.PureComponent<
  IMavoStatusDataContainerProps,
  IMavoStatusDataContainerState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  
  static contextType = ApplicationContext;
  static displayName = 'MavoStatusDataContainer';

  state = {
    isLoading: false,
    executing: false,
    isValuationOpen: false,
    isOpen: false,
    dialogVisible: false
  } as IMavoStatusDataContainerState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: false
      }
    );
  }
  isDisabled = (): boolean => {
    //1.2	Actualizare/adaugare
    if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
      //Nu pot fi modificate informatii vehicul din tab-urile «Informatii generale», «Stare vehicul», 
      //«Despre vehicul (Dotari)» si «Date STOC» daca vehiculul este in una din starile : «Licitatie», «Comanda», «Aviz», «Factura»
      return  disabledNewMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    } else  {
      //daca vehiculul este in stare “Licitatie”, se pot actualiza doar informatiile «Stare vehicul», «Despre vehicul (Dotari)» si «Poze» 
      if (this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code === MavoVehicleStatusCodeEnum.LICITATIE.toString()) {
        return false;
      }
      //daca vehiculul este in orice alta stare («Comanda», «Aviz», «Factura»), nu se pot actualiza nici un fel de informatii referitoare la acest vehicul
      return  disabledMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    }
  };
 

  getStatusOptions = (status: IReferential | null, statuses: IReferential[]) => {
    let statusOptions = statuses;
    const currentStatusEnum = (status?.id as unknown as VehicleStatusCodeEnum) || null;

    if (currentStatusEnum && allowedMavoVehicleStatuses.has(currentStatusEnum)) {
      const allowedStatuses = allowedMavoVehicleStatuses.get(currentStatusEnum) || [];
      statusOptions = statuses.filter((status) => {
        const s = status.id as unknown as VehicleStatusCodeEnum;
        return allowedStatuses.includes(s);
      });
    }

    return statusOptions;
  };

  
  handleAutocompleteVehicleStatusChange = async (value: IReferential | null) => {
    this.props.setMavoVehicleObject({
      ...this.props.mavoVehicleState.mavoVehicle,
      currentStatus: {
        ...this.props.mavoVehicleState.mavoVehicle.currentStatus,
        id: 0,
        status: _.cloneDeep(value),
        statusId: _.cloneDeep(value!.id)
      }
    });

    if (disabledMavoVehicleStatuses.includes(value!.code ?? '')) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('IN_PROCESING_OF_SALE', 'Inregistrare in curs de vanzare'), {
        variant: 'info'
      });
    }

    if (this.props.mavoVehicleState.mavoVehicle.id) {
      const currentStatus = await this.vehicleService.ChangeMavoVehicleStatus({
        mavoVehicleId: this.props.mavoVehicleState.mavoVehicle.id,
        statusId: value!.id,
        comment: ''
      });

      this.props.setMavoVehicleObject({
        ...this.props.mavoVehicleState.mavoVehicle,
        currentStatus: _.cloneDeep(currentStatus)
      });
    } 
  };
  

  renderChangeVehicleStatusForm = () => {
    return (
      <div className="my-3">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="status"
              className="mb-3"
              options={this.getStatusOptions(this.props.mavoVehicleState.mavoVehicle.currentStatus?.status || null, this.props.statuses)}
              value={this.props.mavoVehicleState.mavoVehicle.currentStatus?.status || null}
              onChange={(e: any, newValue: IReferential | null) => {
                this.handleAutocompleteVehicleStatusChange(newValue);
              }}
              disabled={this.isDisabled() || this.props.disabled}
              getOptionLabel={(option: any) => option.displayName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="status"
                  value={this.props.mavoVehicleState.mavoVehicle.currentStatus?.status || null}
                  label={this.translatorService.Tranlate('DETAILS_OF_CAR_STATUS_LABEL', 'Stare')}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.renderChangeVehicleStatusForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: MavoVehicleStatusDetailsProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicle: state.mavoVehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators }
)(withSnackbar(MavoStatusDataContainer as any));
