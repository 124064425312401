import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { Button, Grid, CardContent, IconButton, Dialog, DialogTitle, Typography, DialogContent, Box, Divider} from '@material-ui/core';
import { withSnackbar, ProviderContext } from 'notistack';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import {  TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { ScaleLoader } from 'react-spinners';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { Organization, OrganizationAttachment } from '../../interfaces/Organization';
import { Autocomplete } from '@material-ui/lab';
import { AttachmentTypeCode, RefConstants, RoleClaimsEnum } from '../../helpers/Constants';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { IReferential } from '../../interfaces/IReferential';

import { ButtonGroup, Card, CardFooter } from 'reactstrap';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileSaver from 'file-saver';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { isNullOrUndefined } from 'util';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileViewer from 'react-file-viewer';

type UserManualProps = {
    appState: AppState;
    caseSettingsState: CaseSettingsState;
  } & ProviderContext &
    RouteComponentProps;

interface IUserManualState {
  isLoading: boolean;
  organizationList: Organization[];
  selectedOrganization: Organization | null;
  attachmentTypeList: IReferential[];
  organizationAttachments: OrganizationAttachment[];
  previewAttachFile: any;
  previewAttachFileExtension: string;
  isDialogOpen: boolean;
  isLoadingAttachments: boolean;
}

class LocalizedUtils extends MomentUtils {
}

class UserManual extends React.PureComponent<UserManualProps, IUserManualState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    organizationList: [],
    selectedOrganization: null,
    attachmentTypeList: [],
    organizationAttachments: [],
    previewAttachFile: '',
    previewAttachFileExtension: '',
    isDialogOpen: false, 
    isLoadingAttachments: false
  }  as IUserManualState;

  public async componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadOrganizations();
      }
    );
  }

  loadOrganizations = async () => {
    const {
      attachementTypeRef
    } = RefConstants;

    const organizationId = this.props.appState.appUser!.hoId === null ? this.props.appState.appUser!.organizationId : this.props.appState.appUser!.hoId;
    console.log(organizationId);
    const [
      organizations,
      attachmentTypeList,
      organizationAttachments
    ] = await Promise.all([
      this.organizationService.GetHOOrganizations(null),
      this.appReferentialService.Get(attachementTypeRef?.baseUrl),
      this.hasRight(RoleClaimsEnum.Admin) ? this.organizationService.GetOrganizationAdminAttachments() 
        : this.organizationService.GetOrganizationAttachments(organizationId!)
    ]);

    attachmentTypeList.forEach((attachmentType) => {
      attachmentType.isLoading = false;
    });

    console.log('organizationAttachments', organizationAttachments);

    this.setState({
      organizationList: organizations,
      isLoading: false,
      attachmentTypeList: attachmentTypeList.filter((item) => item.code == AttachmentTypeCode.MANUAL_UTILIZARE),
      organizationAttachments: organizationAttachments
    });
  }

  loadOrganizationAttachments = async () => {
    this.setState({
      isLoadingAttachments: true
    });

    const organizationId = this.props.appState.appUser!.hoId === null ? this.props.appState.appUser!.organizationId : this.props.appState.appUser!.hoId;
    console.log(organizationId);
    const organizationAttachments = this.hasRight(RoleClaimsEnum.Admin) ? await this.organizationService.GetOrganizationAdminAttachments() 
      : await this.organizationService.GetOrganizationAttachments(organizationId!);
    
    this.setState({
      organizationAttachments: organizationAttachments,
      isLoadingAttachments: false
    });
  }

  saveOrganizationAttachments = async () => {
    try {
      this.setState({ isLoading: true });

      this.setState({ isLoading: false });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err: any) {
      this.setState({ isLoading: false });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleOrganizationChange = (newValue: Organization | null) => {
    this.setState({
      selectedOrganization: newValue
    });
  };

  setAttachmentTypeList = (attachmentType: IReferential, value: boolean) => {
    const attachmentTypeList = _.cloneDeep(this.state.attachmentTypeList);
    for (let i = 0; i < attachmentTypeList.length; i++) {
      if (attachmentTypeList[i].id === attachmentType.id) {
        attachmentTypeList[i].isLoading = value;
      }
    }

    return attachmentTypeList;
  };

  handleUploadAttachment = async (
    e: React.ChangeEvent<HTMLInputElement>,
    attachmentType: IReferential
  ) => {
    try {

      console.log(e.target.files);
      if (e.target.files === null) {
        return;
      }
      e.persist();

      this.setState({
        attachmentTypeList: this.setAttachmentTypeList(attachmentType, true)
      });


      const attachments: OrganizationAttachment[] = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files![i];
        attachments.push({
          id: 0,
          organizationId: this.state.selectedOrganization!.id,
          file: file,
          originalFileName: file.name
        } as OrganizationAttachment);
      }

      const newFiles = await this.organizationService.AddOrganizationAttachments(attachments);
      
      await this.loadOrganizationAttachments();

      this.setState({
        attachmentTypeList: this.setAttachmentTypeList(attachmentType, false)
      });


      e.target.value = '';

    } catch (error) {
      console.log(error);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      e.target.value = '';
    }
  };

  renderWorkflowAttashmentTypes = () => {
    const attachmentTypes = [] as any[];
    const attachmentTypeList = this.state.attachmentTypeList;
    if (attachmentTypeList.length !== 0) {
      attachmentTypeList.forEach((attach, index) => {
        attach.fileInputKey = Date.now();
        attachmentTypes.push(
          <div key={index} className="p-1 w-100 d-flex justify-content-center">
            <Card className="card-box w-100 shadow-none">
              <div className="card-header text-center">
                <div className="card-header--title">
                  <b>
                    {attach!.displayName}
                  </b>
                </div>
              </div>
              <CardContent className="p-3 flex-grow-1">
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={attach.isLoading} />
                </div>
              </CardContent>
              <CardFooter className="p-3 border-0">
                <ButtonGroup className="w-100">
                  <Button
                    variant="contained"
                    color="primary"
                    className="d-block w-100 text-center shadow-none"
                    disabled={!this.state.selectedOrganization}
                  > 
                    <input
                      className="d-none"
                      key={attach.fileInputKey}
                      id={'contained-button-file' + attach.id}
                      onChange={(e) => {
                        return this.handleUploadAttachment(e, attach);
                      }}
                      type="file"
                      multiple={true}
                      disabled={!this.state.selectedOrganization}

                    />
                    <label
                      htmlFor={'contained-button-file' + attach.id}
                      className="w-100 mb-0"
                      aria-disabled="true"
                    >
                      Upload
                    </label>
                  </Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          </div>
        );
      });
    }
    return attachmentTypes;
  };

  renderFilters = () => {
    return (
      <ValidatorForm
        onSubmit={() => {
          this.saveOrganizationAttachments();
        }}
      >
      <div className="m-3 text-center">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              id="org"
              size="small"
              className="m-2"
              options={this.state.organizationList.sort(function (a, b) {
                return a!.displayName.localeCompare(b!.displayName);
              })}
              value={this.state.selectedOrganization}
              onChange={(e: any, newValue: Organization | null) =>
                this.handleOrganizationChange(newValue)
              }              
              getOptionLabel={(option: Organization) => option!.displayName || ''}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="org"
                  value={this.state.selectedOrganization}
                  label={this.translatorService.Tranlate(
                    'VEHICLES_VEHICLE_ORGANIZATION',
                    'Organizatie'
                  )}
                  fullWidth
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="d-flex flex-wrap m-1">{this.renderWorkflowAttashmentTypes()}</div>
          </Grid>
        </Grid>
      </div>
    </ValidatorForm>
    );
  };

  getDistinctOrganization = (organizationAttachments: OrganizationAttachment[]) => {
    return _.map(_.groupBy(organizationAttachments, 'organizationId'), 
      (items, organizationId) => ({
        organization: items[0].organization,
        attachments: items,
      }));
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false
    });
  };

  handlePreviewAttachment = async (originalFileName: string, filename: string) => {
    try {
      const fileBlob = await this.organizationService.GetOrganizationAttachment(filename);
      const url = URL.createObjectURL(fileBlob);
      const extension = originalFileName.split('.').pop();

      if (isNullOrUndefined(extension)) {
        return;
      }

      this.setState({
        previewAttachFile: url,
        previewAttachFileExtension: extension!.toLowerCase(),
        isDialogOpen: true
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderPreviewAttachment = () => {
    return (
      <FileViewer
        fileType={this.state.previewAttachFileExtension}
        filePath={this.state.previewAttachFile}
      />
    );
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.organizationService.GetOrganizationAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      await this.organizationService.RemoveOrganizationAttachment(fileName);

      await this.loadOrganizationAttachments();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderAttachments = (attachments: OrganizationAttachment[]) => {
    const attachmentsToRender = [] as any[];

    attachments.forEach((attach, index) => {
      attachmentsToRender.push(
        <React.Fragment key={index}>
          <Grid container spacing={0}>
            <Grid item xs={1}>
              <div className="d-flex align-items-center">
               {attach.id ? (
                  <IconButton
                    aria-label="preview"
                    color="inherit"
                    className="text-primary"
                    size="small"
                    onClick={(e) => {
                     this.handlePreviewAttachment(attach.originalFileName, attach.fileName);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton> ) 
                : null} 
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className="align-box-row text-wrap " style={{ wordBreak: 'break-all' }}>
                <small>
                  <Button
                    color="primary"
                    className="m-2 text-primary"
                    onClick={() => {
                      if (attach.id !== 0) {
                       this.downloadFile(attach.fileName, attach.originalFileName)
                      }
                    }}
                  >
                    <span>{attach.originalFileName}</span>
                  </Button>{' '}
                </small>
              </div>
            </Grid>
            {attach.id !== 0  && this.hasRight(RoleClaimsEnum.Admin) ? (
              <Grid item xs={1}>
                <div className="d-flex align-items-center">
                  <IconButton
                    aria-label="delete"
                    color="inherit"
                    className="text-danger"
                    size="small"
                    onClick={(e) => {
                      this.handleDeleteAttachment(
                        attach.id,
                        attach.fileName
                      );
                    }}
                  >
                    <DeleteOutlineTwoToneIcon />
                  </IconButton>
                </div>
             </Grid>
            ) : null}
          </Grid>
        </React.Fragment>
      );
    });
    return attachmentsToRender;
  };

  renderOrganizationAttachments = () => {
    const organizationAttachment = [] as any[];
    const organizationAttachmentList = this.getDistinctOrganization(this.state.organizationAttachments);

    if (organizationAttachmentList.length !== 0) {
      organizationAttachmentList.forEach((attach, index) => {
        organizationAttachment.push(
          <div key={index} className={`p-1 ${this.hasRight(RoleClaimsEnum.Admin) ? 'w-50' : 'w-100'} d-flex justify-content-center`}>
            <Card className="card-box w-100 shadow-none">
              <div className="card-header text-center">
                <div className="card-header--title">
                  <b>
                    {attach.organization!.displayName}
                  </b>
                </div>
              </div>
              <CardContent className="p-3 flex-grow-1">
                <div className="file-manager-wrapper h-100">
                    {attach.attachments.length !== 0 ? (
                      <div className="table-responsive">
                        <table className="table table-striped table-hover text-nowrap mb-0">
                          <tbody>
                            <tr>
                              <td>{this.renderAttachments(attach.attachments)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="h-100 align-self-center text-center">
                        <DescriptionIcon
                          className="h-100"
                          color="secondary"
                          fontSize="large"
                        ></DescriptionIcon>
                      </div>
                    )}
                  </div>
              </CardContent>
            </Card>
          </div>
        );
      });
    }

    return organizationAttachment;
  };

  renderOrganizations = () => {
    return (
      <>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingAttachments} />
        </div>
        {!this.state.isLoadingAttachments ? (
          <div className="m-3 text-center">
            <Grid container spacing={2} alignContent="center" alignItems="center">
              <Grid item xs={12}>
                <div className="d-flex flex-wrap m-1">
                  {this.renderOrganizationAttachments()}
                </div>
              </Grid>
            </Grid>
          </div>
          ) : null}
      </>
    );
  };


  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    
    return (
      <React.Fragment>
        <Card>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            this.hasRight(RoleClaimsEnum.Admin) ? (
              <div>
                {this.renderFilters()}
                <Divider />
                {this.renderOrganizations()}
              </div>
            ) : (   
              <div>
                {this.renderOrganizations()}
              </div>         
            )
          ) : null}

        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          maxWidth="md"
          fullWidth
          fullScreen={false}
          classes={{ paper: 'dialogPaper' }}
          scroll="body"
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate('CASE_PREVIEW_DOC', 'Previzualizare document')}
                </Typography>
              </Grid>
              <Grid className="text-right" item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container alignContent="center">
              <Grid item xs={12}>
                <Box height="65vh" className="text-center">
                  {this.renderPreviewAttachment()}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    caseSettingsState: state.caseSettings
  }),
  null
)(withSnackbar(UserManual as any));
