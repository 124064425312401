import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { ApplicationState } from '../../../store';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { Organization, OrganizationCaseType, Language } from '../../../interfaces/Organization';
import { Box, CardContent, Card, Tabs, Tab, Button, Grid } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Workflow } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import { IReferential, ITranslation } from '../../../interfaces/IReferential';
import { isNullOrUndefined } from 'util';

interface IWorkflowGeneralDataState {
  workflows: Workflow[];
  workflow: Workflow;
  caseTypeList: IReferential[];
  isLoading: boolean;
}
class WorkflowGeneralData extends React.PureComponent<any, IWorkflowGeneralDataState> {
  private translatorService!: ITranslatorService;
  private workflowService!: IWorkflowService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    workflows: [],
    workflow: {} as Workflow,
    caseTypeList: [],
    isLoading: true
  } as IWorkflowGeneralDataState;

  public async componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    const languages = this.activeLanguages();

    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseType);
    const [workflows, workflow, orgCaseList, orgCaseTypes, owner] = await Promise.all([
      this.workflowService.GetWorkflows(hoId),
      this.workflowService.GetWorkflow(workflowId),
      this.organizationService.GetOrganizationCaseType(hoId),
      this.appReferentialService.Get(ref!.baseUrl),
      this.organizationService.GetOrganization(hoId)
    ]);

    let organizationCases = orgCaseList.sort(function (a, b) {
      return a.order - b.order;
    });
    organizationCases = organizationCases.map((item: OrganizationCaseType) => {
      item.caseType = orgCaseTypes.find(
        (caseType: IReferential) => caseType.id === item.caseTypeId
      )!;
      return item;
    });

    workflow.organizationOwnerId = hoId;
    const workflowCaseType = orgCaseTypes.find((item) => item.id === workflow.caseTypeId);
    workflow.caseType = isNullOrUndefined(workflowCaseType) ? null : workflowCaseType;

    workflow.organizationOwner = owner !== undefined ? owner : ({} as Organization);

    const workflowTranslations = workflow.translations;
    languages.forEach((language: Language, index: number) => {
      if (workflowTranslations.length === 0) {
        workflowTranslations.push({
          id: 0,
          referentialTypeId: workflow.id,
          name: workflow.organizationOwner!.name + ' - ',
          language: language.code
        });
      } else {
        const indexTrans = workflowTranslations.findIndex((trans: ITranslation) => {
          return language.code === trans.language;
        });
        if (indexTrans === -1) {
          workflowTranslations.push({
            id: 0,
            referentialTypeId: workflow.id,
            name: workflow.organizationOwner!.name + ' - ',
            language: language.code
          });
        }
      }
    });
    workflow.translations = [...workflowTranslations];

    this.setState({
      workflows: workflows,
      workflow: workflow === undefined ? ({} as Workflow) : workflow,
      caseTypeList: organizationCases.map((item) => item.caseType!),
      isLoading: false
    });
  }

  handleWorkflowCaseTypeChange = (newValue: IReferential | null) => {
    this.setState({
      workflow: {
        ...this.state.workflow,
        caseType: newValue,
        caseTypeId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  saveWorkflow = async () => {
    try {
      let workflowExist = -1;
      this.state.workflows.forEach((item: Workflow, index: number) => {
        if (
          item.caseTypeId === this.state.workflow.caseTypeId &&
          item.organizationOwnerId === this.state.workflow.organizationOwnerId &&
          item.id !== this.state.workflow.id
        ) {
          let indexFinded = -1;
          for (const i of item.translations) {
            indexFinded = Math.max(
              indexFinded,
              this.state.workflow.translations.findIndex(
                (item) => item.language === i.language && item.name === i.name
              )
            );
          }
          workflowExist = Math.max(workflowExist, indexFinded);
          return;
        } else {
          workflowExist = Math.max(workflowExist, -1);
        }
      });
      if (workflowExist !== -1) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_WORKFLOW_EXISTS', 'Fluxul exista deja!'),
          { variant: 'error' }
        );
        return;
      }

      if (Number.parseInt(this.props.match.params.workflowId) === 0) {
        await this.workflowService.AddWorkflow(this.state.workflow);
      } else {
        await this.workflowService.UpdateWorkflow(this.state.workflow);
      }

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  activeLanguages = () => {
    const activeLanguages =
      this.props.appState.appUser!.organization!.gtOrganization!.languages!.filter(
        (language: any) => {
          if (language.isActive === true) {
            return language;
          }
        }
      );
    activeLanguages.forEach((language: any) => {
      language.displayName =
        language.displayName.charAt(0).toUpperCase() + language.displayName.slice(1);
    });
    return activeLanguages;
  };

  handleWorkflowNameChange = (event: any, code: string | null) => {
    let newWorkflowTransList = [] as ITranslation[];
    if (this.state.workflow.translations) {
      newWorkflowTransList = this.state.workflow.translations.map((loc: any) => {
        if (loc.language === code) {
          loc.name = event.target.value;
        }
        return loc;
      });
    }
    this.setState({
      workflow: {
        ...this.state.workflow,
        translations: newWorkflowTransList
      }
    });
  };

  renderNameTextFields = () => {
    if (Object.keys(this.state.workflow).length === 0) {
      return null;
    } else {
      const nameTextFieldsList = [] as any[];
      this.state.workflow!.translations.forEach((item: ITranslation, index: number) => {
        nameTextFieldsList.push(
          <Grid key={index} item xs={6}>
            <TextValidator
              fullWidth
              name={'name_' + index}
              className="m-2"
              value={item.name || ''}
              onChange={(e) => this.handleWorkflowNameChange(e, item.language)}
              label={
                this.translatorService.Tranlate('EDIT_WORKFLOW_GUI_NAME_LABEL', 'Nume GUI') +
                ' - ' +
                item.language
              }
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          </Grid>
        );
      });
      return nameTextFieldsList;
    }
  };

  render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate(
                    'WORKFLOW_GENERAL_DATA_TAB',
                    'Date generale'
                  )}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <Box mt={1} pt={1}>
          <Card>
            <CardContent>
              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
              </div>

              {this.state.isLoading ? null : (
                <ValidatorForm onSubmit={this.saveWorkflow} instantValidate={true}>
                  <TextValidator
                    fullWidth
                    name="organziationOwner"
                    className="m-2"
                    id="organziationOwner"
                    disabled={true}
                    value={
                      this.state.workflow.organizationOwner === null
                        ? ''
                        : this.state.workflow.organizationOwner!.name || ''
                    }
                    label={this.translatorService.Tranlate(
                      'EDIT_WORKFLOW_OWNER_LABEL',
                      'Proprietar flux'
                    )}
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />

                  <Autocomplete
                    id="caseType"
                    className="m-2"
                    options={this.state.caseTypeList}
                    value={this.state.workflow.caseType}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleWorkflowCaseTypeChange(newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name="casetype"
                        value={this.state.workflow.caseType}
                        label={this.translatorService.Tranlate(
                          'EDIT_WORKFLOW_CASE_TYPE_LABEL',
                          'Tip Dosar'
                        )}
                        fullWidth
                        validators={['required']}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                      />
                    )}
                  />
                  <Grid container spacing={2} className="mt-2">
                    {this.renderNameTextFields()}
                  </Grid>
                  <div className="text-right">
                    <Button className="m-2" variant="contained" color="primary" type="submit">
                      {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
                    </Button>
                  </div>
                </ValidatorForm>
              )}
            </CardContent>
          </Card>
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  null
)(withSnackbar(WorkflowGeneralData as any));
