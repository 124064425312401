import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsState, AppState } from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { Card } from 'reactstrap';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { Case, CaseParameterHistory, CasePartnerHistory } from '../../interfaces/Case';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import TariffProperties from './TariffProperties';

interface ICaseDetailsCaseTabSummaryFormState {
  case: Case;
  isLoading: boolean;
  isOpenDialog: boolean;
}

type ICaseDetailsCaseTabSummaryFormProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabSummaryForm extends React.PureComponent<
  ICaseDetailsCaseTabSummaryFormProps,
  ICaseDetailsCaseTabSummaryFormState
> {
  private translatorService!: ITranslatorService;

  static contextType = ApplicationContext;
  state = {
    case: {},
    isLoading: true,
    isOpenDialog: false
  } as ICaseDetailsCaseTabSummaryFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);

    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseSettingsSummaryForm();
      }
    );
  }

  loadCaseSettingsSummaryForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);

    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    const casee = this.props.caseSettingsState.case;
    if (casee === null) {
      return;
    }

    this.setState({
      case: casee,
      isLoading: false
    });
  };

  renderCaseDetails = () => {
    const casee = this.props.caseSettingsState.case;
    if (isNullOrUndefined(casee)) {
      return;
    }

    return Object.keys(casee).length === 0 ||
      Object.keys(casee.caseStatus).length === 0 ||
      isNullOrUndefined(this.props.caseSettingsState.caseSettings) ? null : (
      <Card className="card-box rounded-0 border-2 my-2 w-100 shadow-none">
        <div className="grid-menu grid-menu-2col">
          <Grid container spacing={0}>
            <Grid item sm={6}>
              <div className="text-center MuiButton-root d-block border-0 w-100 rounded-0 py-4 px-1">
                <div className="font-weight-bold text-black">{casee.caseType!.displayName}</div>
                <div className="font-weight-bold text-black">{casee.caseNumber}</div>
                <div className="font-size-sm text-black">
                  {casee.caseStatus.caseStatus!.displayName}
                </div>
                <div className="font-size-sm mb-1 text-black-50">
                  {moment
                    .utc(casee.caseStatus.date)
                    .local()
                    .format(this.props.appState.longDateFormat)}
                </div>
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className="text-center MuiButton-root d-block border-0 w-100 rounded-0 py-4 px-1">
                <div className="font-weight-bold text-black">
                  {this.translatorService.Tranlate(
                    'CASE_DETAILS_WORKFLOW_OWNER',
                    'Proprietar flux'
                  )}
                </div>
                <div className="font-weight-bold text-black">
                  {this.props.caseSettingsState.caseSettings!.workflow.organizationOwner!.name}
                </div>
                {/* <div className="font-size-sm text-black">Bucuresti</div>
                                <div className="font-size-sm mb-1 text-black-50">Sector 1, Agentia Piata Victoriei</div> */}
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className="text-center MuiButton-root d-block border-0 w-100 rounded-0 py-4 px-1">
                <div className="font-weight-bold text-black">
                  {this.translatorService.Tranlate('CASE_DETAILS_CREATED_BY', 'Dosar creat de')}
                </div>
                <div className="font-weight-bold text-black">
                  {this.state.case.createdByUser!.organization!.name}
                </div>
                <div className="font-size-sm text-black">
                  {this.state.case.createdByUser!.userName}
                </div>
                <div className="font-size-sm mb-1 text-black-50">
                  {moment
                    .utc(casee.dateCreation)
                    .local()
                    .format(this.props.appState.longDateFormat)}
                </div>
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className="text-center MuiButton-root d-block border-0 w-100 rounded-0 py-4 px-1">
                <div className="font-weight-bold text-black">
                  {this.translatorService.Tranlate('CASE_DETAILS_ASSIGNED', 'Asignat')}
                </div>
                <div className="font-weight-bold text-black">
                  {casee.caseStatus.assignedTo!.organization!.name}
                </div>
                <div className="font-size-sm text-black">
                  {this.state.case.caseStatus.assignedTo!.userName}
                </div>
                <div className="font-size-sm mb-1 text-black-50">
                  {moment
                    .utc(casee.caseStatus.date)
                    .local()
                    .format(this.props.appState.longDateFormat)}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  };

  renderPartners = () => {
    const partners = [] as any[];
    if (this.props.caseSettingsState.case !== null) {
      this.props.caseSettingsState.case.casePartners.forEach(
        (item: CasePartnerHistory, index: number) => {
          partners.push(
            <div key={index} className="w-50 p-3 d-flex justify-content-center">
              <div className="card card-box d-flex justify-content-center px-4 py-3 w-100 shadow-none">
                <div className="text-center w-100 py-3">
                  <div className="font-weight-bold text-black">
                    {item.organization!.organizationType!.displayName}
                  </div>
                  <div className="font-size-sm mb-1 text-black-50">{item.organization!.name}</div>
                </div>
              </div>
            </div>
          );
        }
      );
    }

    return partners;
  };

  renderParameters = () => {
    const parameters = [] as any[];
    if (this.props.caseSettingsState.case !== null) {
      this.props.caseSettingsState.case.caseParameters.forEach(
        (item: CaseParameterHistory, index: number) => {
          parameters.push(
            <div key={index} className="w-50 p-3 d-flex justify-content-center">
              <div className=" card card-box d-flex justify-content-center px-4 py-3 w-100  shadow-none">
                <div className="text-center w-100 py-3">
                  <div className="font-weight-bold text-black">{item.parameter!.displayName}</div>
                  <div className="font-size-sm mb-1 text-black-50">{item.value}</div>
                </div>
              </div>
            </div>
          );
        }
      );
    }
    return parameters;
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;

    if (this.props.caseSettingsState === null || this.props.caseSettingsState.case === null) {
      return '';
    }

    const casee = this.props.caseSettingsState.case;
    if (isNullOrUndefined(casee)) {
      return;
    }
    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <Fragment>
            <div className="m-2 pt-2">
              <div className="d-flex flex-row rounded-sm px-4 py-2  border-1 ">
                <Typography className="m-0" variant="h6">
                  {this.props.caseSettingsState.caseSettings!.workflow.displayName}
                </Typography>
                <Typography className="m-0 pl-1" variant="h6">
                  {'>> '}
                </Typography>
                <Typography
                  className="m-0 pl-1"
                  variant="h6"
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    this.setState({ isOpenDialog: true });
                  }}
                >
                  {!isNullOrUndefined(casee.caseTariff) &&
                  !isNullOrUndefined(casee.caseTariff.tariff)
                    ? casee.caseTariff.tariff!.name
                    : ''}
                </Typography>
              </div>
              <div className="d-flex flex-wrap ">{this.renderCaseDetails()}</div>

              <div className="rounded-sm px-4 py-2  border-1">
                <h6 className="m-0">
                  {this.translatorService.Tranlate('CASE_SUMMARY_FORM_PARTNERS', 'Parteneri')}
                </h6>
              </div>
              <div className="d-flex flex-wrap ">{this.renderPartners()}</div>

              <div className="rounded-sm px-4 py-2  border-1">
                <h6 className="m-0">
                  {this.translatorService.Tranlate('CASE_SUMMARY_FORM_PARAMETERS', 'Parametri')}
                </h6>
              </div>
              <div className="d-flex flex-wrap">{this.renderParameters()}</div>
            </div>
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={this.state.isOpenDialog}
              fullWidth={true}
              maxWidth={'md'}
            >
              <DialogTitle>
                <Grid container>
                  <Grid item xs={11}>
                    <Typography variant="h4">
                      {!isNullOrUndefined(casee.caseTariff) &&
                      !isNullOrUndefined(casee.caseTariff.tariff)
                        ? casee.caseTariff.tariff!.name
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className="text-right">
                    <IconButton
                      aria-label="close"
                      onClick={() => this.setState({ isOpenDialog: false })}
                      size={'small'}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>

              <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
                {isNullOrUndefined(casee.caseTariff) ? (
                  ''
                ) : (
                  <TariffProperties {...this.props} caseTariffHistoryId={casee.caseTariff.id} />
                )}
              </DialogContent>
            </Dialog>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null
)(withSnackbar(CaseDetailsCaseTabSummaryForm as any));
