import { Action, Reducer } from 'redux';
import { WeatherForecastsAction } from '../actions/WeatherForecasts';
import { WeatherForecast } from '../../interfaces/WeatherForecast';

export interface WeatherForecastsState {
  isLoading: boolean;
  startDateIndex?: number;
  forecasts: WeatherForecast[];
}

const initialState: WeatherForecastsState = {
  forecasts: [],
  isLoading: false
};

export const WeatherForecastsReducer: Reducer<WeatherForecastsState> = (
  state: WeatherForecastsState | undefined,
  incomingAction: Action
): WeatherForecastsState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as WeatherForecastsAction;
  switch (action.type) {
    case 'REQUEST_WEATHER_FORECASTS':
      return {
        startDateIndex: action.startDateIndex,
        forecasts: state.forecasts,
        isLoading: true
      };
    case 'RECEIVE_WEATHER_FORECASTS':
      if (action.startDateIndex === state.startDateIndex) {
        return {
          startDateIndex: action.startDateIndex,
          forecasts: action.forecasts,
          isLoading: false
        };
      }
      break;
  }

  return state;
};
