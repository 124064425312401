import React from 'react';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Button, IconButton, Grid, TextField, Typography } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { CurrencyCode, ReferentialCode } from '../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { TextValidator } from 'react-material-ui-form-validator';
import { IReferential } from '../../../interfaces/IReferential';
import { Utils } from '../../../helpers/Utils';
import * as R from 'ramda';
import { Cost, CostAttachment } from '../../../interfaces/Vehicle';
import Referentials from '../../../helpers/Referentials.json';
import { IVehicleService } from '../../../services/Interfaces/IVehicleService';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import NumberFormat from 'react-number-format';

interface ICaseDetailsCostDetailsFormState {
  showCostDetails: boolean;
  costTypes: IReferential[];
  currencyList: IReferential[];
  isLoading: boolean;
}

export interface ExternalCaseDetailsCostDetailsFormProps {
  costValues: Cost[];
  updateCostValues: (values: Cost[]) => any;
  currencyTypeCode: string;
  caseSectionStepFormId?: number;
  readonly?: boolean;
  showTitle: boolean;
  vehicleId: number;
}

type ICaseDetailsCostDetailsFormProps = ExternalCaseDetailsCostDetailsFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCostDetailsForm extends React.PureComponent<
  ICaseDetailsCostDetailsFormProps,
  ICaseDetailsCostDetailsFormState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;
  private caseService!: ICaseService;
  static contextType = ApplicationContext;
  state = {
    showCostDetails: false,
    costTypes: [] as IReferential[],
    currencyList: [] as IReferential[],
    isLoading: false
  } as ICaseDetailsCostDetailsFormState;

  input = React.createRef<HTMLInputElement>();
  inputTextValidator = React.createRef<TextValidator>();

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCasePaymentDetailsForm();
      }
    );
  }

  loadCasePaymentDetailsForm = async () => {
    const refCurrencyType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Currency
    );
    const futureCurrencyList = await this.appReferentialService.Get(refCurrencyType!.baseUrl);
    const futureCostTypes = await this.vehicleService.GetVehicleCostTypes();

    this.setState({
      costTypes: futureCostTypes,
      showCostDetails: this.props.readonly || false,
      currencyList: futureCurrencyList
    });

    if (!this.props?.readonly) {
      const indexCurrency = futureCurrencyList.findIndex((item) => item?.code === CurrencyCode.EUR);
      const currencyType = futureCurrencyList[indexCurrency];
      const futureCostValues = futureCostTypes.map((cost) => {
        return {
          caseId: this.props.match?.params?.id || '',
          vehicleId: this.props.vehicleId,
          costTypeId: cost.id,
          costTypeCode: cost.code || '',
          files: [],
          value: null,
          currencyRate: this.props.currencyTypeCode
            ? Utils.GetCurrencyRate(this.props.appState.currencyRates, this.props.currencyTypeCode)
            : null,
          currency: currencyType?.displayName || '',
          secondValue: null,
          secondCurrency: this.getSecondCurrency(),
          caseSectionStepFormId: this.props.caseSectionStepFormId || null
        };
      });
      this.props.updateCostValues(futureCostValues);
    }
  };

  getSecondCurrency = () => {
    const indexRon = this.state?.currencyList?.findIndex((item) => item?.code === CurrencyCode.RON);
    const indexEur = this.state?.currencyList?.findIndex((item) => item?.code === CurrencyCode.EUR);
    if (this.props.currencyTypeCode === CurrencyCode.EUR) {
      return this.state.currencyList[indexRon]?.displayName;
    }

    return this.props.currencyTypeCode === CurrencyCode.RON
      ? this.state?.currencyList[indexEur]?.displayName
      : '';
  };

  setSecondValueBasedOnCaseCurrency(value: number): number | null {
    let resultValue = null;
    if (
      this.props.currencyTypeCode == CurrencyCode.EUR ||
      this.props.currencyTypeCode == CurrencyCode.RON
    ) {
      resultValue = 0;
      const previousCurrencyCode = this.props.currencyTypeCode;
      const futureCurrencyCode =
        this.props.currencyTypeCode == CurrencyCode.EUR ? CurrencyCode.RON : CurrencyCode.EUR;
      if (!R.isNil(value) && previousCurrencyCode) {
        const previousCurrencyRate = Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          previousCurrencyCode
        );

        const futureCurrencyRate = Utils.GetCurrencyRate(
          this.props.appState.currencyRates,
          futureCurrencyCode
        );
        resultValue = value * (previousCurrencyRate / futureCurrencyRate);
        return Number.parseFloat(resultValue.toFixed(2));
      }
    }

    return resultValue;
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (!this.props.updateCostValues) {
      return;
    }
    const val = e.target.value;
    const futureCostValues = [...this.props.costValues];
    futureCostValues[index].value = Number.isNaN(Number.parseFloat(val))
      ? null
      : Number.parseFloat(val);
    futureCostValues[index].secondValue = Number.isNaN(Number.parseFloat(val))
      ? null
      : this.setSecondValueBasedOnCaseCurrency(Number.parseFloat(val));
    this.props.updateCostValues(futureCostValues);
  };

  getLabel = (item: Cost) => {
    if (!item.value) {
      return '';
    }
    const valueWithoutVat = this.translatorService.Tranlate(
      'CASE_PAYMENT_DETAILS_FORM_PAYMENT_AMOUNT_WITHOUT_VAT_LABEL',
      'Suma fara TVA'
    );
    return `${valueWithoutVat} | ${item?.value?.toFixed(2)} ${item?.currency}${
      item?.secondCurrency && `( ${item?.secondValue} ${item?.secondCurrency})`
    }`;
  };

  handleUploadCostAttachment = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    try {
      const futureCostValues = [...this.props.costValues];
      futureCostValues[index].files.push({
        file: e.target.files![0],
        fileName: e.target.files![0].name,
        originalFileName: e.target.files![0].name
      } as any as CostAttachment);

      this.props.updateCostValues(futureCostValues);
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  downloadVehicleCostFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.vehicleService.GetVehicleCostAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  getValue = (item: Cost) => {
    if (!item.value) {
      return '';
    }
    return item.value;
  };

  handleDeleteCostAttachment = async (index: number, indexAttachment: number) => {
    try {
      const futureCostValues = [...this.props.costValues];
      futureCostValues[index].files.splice(indexAttachment, 1);

      this.props.updateCostValues(futureCostValues);
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  renderCostType = (cost: IReferential, index: number) => {
    const indexLang = cost.translations.findIndex(
      (lang) => lang.language === this.translatorService.GetLanguage()
    );
    return (
      <Grid container>
        <Grid className="mt-2">
          <Typography>{cost.translations[indexLang].name}</Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} className="mt-2">
            <TextField
              fullWidth
              id={`outlined-${index}`}
              size="small"
              value={this.getValue(this.props.costValues[index])}
              label={this.getLabel(this.props.costValues[index])}
              variant="outlined"
              disabled={this.props.readonly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, index)}
              InputProps={{
                inputComponent: this.NumberFormatCustom,
                style: { fontWeight: 500 }
              }}
            />
          </Grid>
          {this.props.costValues[index].files.length ? (
            <Grid item xs={6} className="mt-2">
              {this.props.costValues[index].files.length !== 0
                ? this.props.costValues[index].files.map((attach, indexAttachment) => {
                    return (
                      <div key={indexAttachment} className="d-flex flex-row w-100">
                        <div className="w-75 text-left">
                          <Button
                            color="primary"
                            className="text-primary text-left"
                            // disabled={!this.state.hasRights}
                            onClick={(e) =>
                              this.downloadVehicleCostFile(attach.fileName, attach.originalFileName)
                            }
                          >
                            <span
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '100px'
                              }}
                            >
                              {attach.originalFileName}
                            </span>
                          </Button>
                        </div>
                        <div className="w-25">
                          <IconButton
                            aria-label="delete"
                            color="inherit"
                            className="text-danger"
                            size="small"
                            // disabled={!this.state.hasRights}
                            onClick={(e) => this.handleDeleteCostAttachment(index, indexAttachment)}
                          >
                            <DeleteOutlineTwoToneIcon />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })
                : null}
            </Grid>
          ) : (
            <Grid item xs={6} className="mt-2">
              <Grid item container justify="flex-end" direction="column">
                <input
                  className="d-none"
                  id={`contained-button-file-${index}`}
                  onChange={(e) => {
                    return this.handleUploadCostAttachment(e, index);
                  }}
                  type="file"
                  // disabled={!this.state.hasRights}
                />
                <label htmlFor={`contained-button-file-${index}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="d-block text-center shadow-none"
                    // disabled={!this.state.hasRights}
                    component="div"
                  >
                    {this.translatorService.Tranlate(
                      'CASE_PAYMENT_DETAILS_FORM_ATTACH',
                      'Ataseaza'
                    )}
                  </Button>
                </label>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  renderCostTypeFromCase = (cost: Cost, index: number) => {
    const indexLang = cost?.costType?.translations.findIndex(
      (lang) => lang.language === this.translatorService.GetLanguage()
    );
    if (indexLang === undefined || indexLang < 0) {
      return <></>;
    }

    return (
      <Grid key={index} container>
        <Grid className="mt-2">
          <Typography>{cost?.costType?.translations[indexLang].name}</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={6} className="mt-2">
            <TextField
              fullWidth
              id={`outlined-${index}`}
              size="small"
              value={cost.value}
              label={this.getLabel(cost)}
              variant="outlined"
              disabled={this.props.readonly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, index)}
            />
          </Grid>
          <Grid item xs={6} className="mt-2">
            {cost.attachments && cost.attachments.length !== 0
              ? cost.attachments.map((attach, index) => {
                  return (
                    <div key={index} className="d-flex flex-row w-100">
                      <div className="w-75 text-left" key={index}>
                        <Button
                          color="primary"
                          className="text-primary text-left"
                          onClick={(e) =>
                            this.downloadVehicleCostFile(attach.fileName, attach.originalFileName)
                          }
                        >
                          <span
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              width: '100px'
                            }}
                          >
                            {attach.originalFileName}
                          </span>
                        </Button>
                      </div>
                    </div>
                  );
                })
              : ''}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderCostDetailsFromCase() {
    return (
      <div className="mt-4">
        {this.props?.costValues &&
          this.props?.costValues?.map((cost, index) => this.renderCostTypeFromCase(cost, index))}
      </div>
    );
  }

  renderCostDetails = () => {
    return (
      <>
        <Grid container alignContent="center" alignItems="center">
          {this.props.showTitle ? (
            <Typography>{this.translatorService.Tranlate('COSTS', 'Costuri')}</Typography>
          ) : null}
        </Grid>

        <Grid container alignContent="center" alignItems="center">
          <Typography>
            {this.translatorService.Tranlate('ADD_COSTS', 'Adauga cost aditional')}{' '}
          </Typography>
          <AddCircleOutlineIcon
            onClick={() => {
              this.setState({ showCostDetails: !this.state.showCostDetails });
            }}
            style={{ marginLeft: 10 }}
            color="primary"
          />
        </Grid>
        {this.state.showCostDetails &&
          this.props?.costValues?.length > 0 &&
          this.state.costTypes?.map((type, index) => this.renderCostType(type, index))}
      </>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.caseService = (this.context as AppContext).caseService;

    return <>{this.props.readonly ? this.renderCostDetailsFromCase() : this.renderCostDetails()}</>;
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCostDetailsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCostDetailsForm as any));
