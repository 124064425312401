import { Log, User, UserManager } from 'oidc-client';
import { IAuthService } from './Interfaces/IAuthService';

import { ClientSettings } from '../helpers/Constants';

export class AuthService implements IAuthService {
  public userManager: UserManager;

  constructor() {
    this.userManager = new UserManager(ClientSettings);
    this.userManager.events.addUserSignedOut(() => {
      this.userManager
        .signoutRedirect()
        .then((resp) => {
          console.log('Success');
        })
        .catch((err) => {
          console.log(err);
        });
    });

    Log.logger = console;
    Log.level = Log.INFO;
  }

  public async getUserAsync(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public async loginAsync(): Promise<void> {
    return this.userManager.signinRedirect({ state: window.location.pathname });
  }

  public async renewTokenAsync(): Promise<User> {
    return this.userManager.signinSilent();
  }

  public async logoutAsync(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  public async checkState(): Promise<void> {
    const user = await this.getUserAsync();

    if (user === null) {
      return this.loginAsync();
    }
    if (user!.id_token === null) {
      return this.loginAsync();
    }
    if (user!.expired) {
      return this.loginAsync();
    }
  }
}
