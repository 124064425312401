import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import EnhancedMarker from 'react-leaflet-enhanced-marker';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GeocoderArcGIS from 'geocoder-arcgis';
import { KeyValuePair } from '../../../interfaces/Dashboard/KeyValuePair';
import Loader from '../../Loader';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Tooltip } from '@material-ui/core';
import { ApplicationContext } from '../../../context/Contexts';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [15, 25]
});

L.Marker.prototype.options.icon = DefaultIcon;

interface CustomMarkerProps {
  count: number;
  selected: boolean;
  name: string;
}

const CustomMarker: React.FC<CustomMarkerProps> = ({ count, selected, name }) => {
  const markerStyle = {
    backgroundColor: selected ? '#00008B' : '#207AC9',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '80px',
    height: '30px',
    alignItems: 'center',
    borderRadius: '10px'
  };

  return (
    <Tooltip title={name}>
      <div style={markerStyle}>{`${count}`}</div>
    </Tooltip>
  );
};

interface MapMarker {
  lat: number;
  long: number;
  count: number;
  id: number;
  name: string;
  address: string;
}

interface Props {
  vehicleLocationCount: KeyValuePair[];
  locationId?: number;
  setLocationId(locationId?: number): void;
  parentCallback: (values: any[]) => any;
}

const VehicleMap: React.FC<Props> = ({
  vehicleLocationCount = [],
  locationId,
  setLocationId,
  parentCallback
}) => {
  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>([]);
  const [mapLoading, setMapLoading] = useState<boolean>(false);

  const context = useContext(ApplicationContext);

  const handleMapMarkerClick = useCallback(
    (id: number) => {
      if (locationId === id) {
        setLocationId(undefined);
      } else {
        setLocationId(id);
      }
    },
    [locationId, setLocationId]
  );

  useEffect(() => {
    const getGeoData = async () => {
      setMapLoading(true);
      const geocoder = new GeocoderArcGIS();
      const markers: MapMarker[] = [];
      for (const item of vehicleLocationCount) {
        if (item.key) {
          const organization = await context.organizationService.GetOrganization(item.key);

          if (!organization) {
            continue;
          }

          const params = {
            countryCode: 'ro',
            city: organization.city,
            address: organization.address
          };

          try {
            const response = await geocoder.findAddressCandidates(params);

            if (response && response.candidates.length) {
              markers.push({
                count: item.value,
                address: response.candidates[0].address,
                lat: response.candidates[0].location.y,
                long: response.candidates[0].location.x,
                name: organization.displayName,
                id: item.key
              });
            }
          } catch {
            continue;
          }
        }
      }
      setMapMarkers(markers);
      parentCallback(markers);
      setMapLoading(false);
    };

    if (vehicleLocationCount.length) {
      getGeoData();
    }
  }, [context.organizationService, vehicleLocationCount]);

  if (mapLoading) {
    return <Loader />;
  }

  return (
    <MapContainer
      // TODO read center from country organization
      center={[45.9442858, 25.0094303]}
      zoom={6}
      scrollWheelZoom={false}
      style={{ height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {mapMarkers.map(({ lat, long, count, id, name }, index) => {
        return (
          <>
            <Marker position={[lat, long]} key={index} />
            <EnhancedMarker
              icon={<CustomMarker count={count} selected={locationId === id} name={name} />}
              position={[lat, long]}
              key={index}
              eventHandlers={{
                click: () => {
                  handleMapMarkerClick(id);
                }
              }}
            />
          </>
        );
      })}
    </MapContainer>
  );
};

export default VehicleMap;
