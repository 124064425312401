import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Button, Card, Tabs, Tab, CardContent } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Organization,
  OrganizationLevelType,
  OrganizationType
} from '../../../interfaces/Organization';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { WrapperSimple } from '../../../layout-components';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode, OrganizationLevelTypeCode } from '../../../helpers/Constants';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import TabPanel from '../../_shared/Tabs';
import OrganizationBankAccounts from './OrganizationBankAccounts';
import OrganizationLogo from './OrganizationLogo';
import OrganizationTokens from './OrganizationTokens';
import { IReferential } from '../../../interfaces/IReferential';
import { ScaleLoader } from 'react-spinners';
import { Switch, FormControlLabel, Typography } from '@material-ui/core';
import * as R from 'ramda';

interface IEditOrganizationState {
  organization: Organization;
  organizationLevelTypes: OrganizationLevelType[];
  parentOrganizations: Organization[];
  organizationTypes: OrganizationType[];
  selectedTab: number;
  countryList: IReferential[];
  regionList: IReferential[];
  cityList: IReferential[];
  isLoading: boolean;
}

export interface ExternalEditOrganizationsProps {
  organizationChanged?: () => void;
  organizationId: number;
}

type EditOrganizationProps = { appState: AppState } & ProviderContext &
  ExternalEditOrganizationsProps &
  RouteComponentProps<{ id: string }>;

class MavoEditOrganization extends React.PureComponent<EditOrganizationProps, IEditOrganizationState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = {
    organization: {
      id: 0,
      name: '',
      organizationType: null,
      organizationLevelType: null,
      country: null,
      region: null,
      parent: null,
      gtId: this.props.appState.appUser!.gtId,
      isActive: true,
      newFeature: false,
      uploadFeature: false,
      cityInfo: null
    } as Organization,
    organizationLevelTypes: [] as OrganizationLevelType[],
    parentOrganizations: [] as Organization[],
    organizationTypes: [] as OrganizationType[],
    selectedTab: 0,
    countryList: [] as IReferential[],
    regionList: [] as IReferential[],
    cityList: [] as IReferential[],
    isLoading: true
  };

  public async componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);

    //let organization = this.state.organization;

    // if (this.props.organizationId !== 0) {
    //     organization = ;
    // }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrganizationType
    );
    const countryRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Country
    );
    const regionRef = Referentials.referential.find((item) => item.code === ReferentialCode.Region);
    const cityRef = Referentials.referential.find((item) => item.code === ReferentialCode.City);

    const [organization, orgLevelList, orgTypes, countryList, regionList, cityList] = await Promise.all([
      this.props.organizationId !== 0
        ? this.organizationService.GetOrganization(this.props.organizationId)
        : this.state.organization,
      this.organizationService.GetOrganizationLevels(hoId),
      this.appReferentialService.Get(ref!.baseUrl),
      this.appReferentialService.Get(countryRef!.baseUrl),
      this.appReferentialService.Get(regionRef!.baseUrl),
      this.appReferentialService.Get(cityRef!.baseUrl)
    ]);
    organization.hoId = hoId;
    const organizationLevelTypes = orgLevelList.map((item) => item.organizationLevelType);

    this.setState(
      {
        organization: organization,
        organizationLevelTypes: organizationLevelTypes.filter(
          (item) => item.code !== OrganizationLevelTypeCode.HO
        ),
        organizationTypes: orgTypes as any as OrganizationType[],
        //parentOrganizations: [],
        regionList: regionList.filter((item) => item.isActive === true),
        countryList: countryList.filter((item) => item.isActive === true),
        cityList: cityList.filter((item) => item.isActive === true),
        isLoading: false
      },
      async () => {
        const organizations =
          this.props.organizationId === 0
            ? ([] as Organization[])
            : await this.organizationService.GetOrganizationsByHO(
                hoId,
                this.state.organization.organizationLevelTypeId
              );

        this.setState({
          parentOrganizations: organizations
        });
      }
    );
  }

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text') {
      value = event.currentTarget.value;
    }
    this.setState({
      organization: {
        ...this.state.organization,
        [event.target.id]: value
      }
    });
  };

  private handleOrganizationLevelTypeChange = async (newValue: OrganizationLevelType | null) => {
    let orgs = [] as Organization[];
    if (newValue !== null) {
      orgs = await this.organizationService.GetOrganizationsByHO(
        this.state.organization.hoId,
        newValue.id
      );
    }
    this.setState({
      organization: {
        ...this.state.organization,
        organizationLevelType: newValue,
        organizationLevelTypeId: newValue !== null ? newValue!.id : 0,
        parent:
          newValue !== null && newValue.code === OrganizationLevelTypeCode.HO
            ? null
            : this.state.organization.parent,
        parentId:
          newValue !== null && newValue.code === OrganizationLevelTypeCode.HO
            ? null
            : this.state.organization.parentId
      },
      parentOrganizations: orgs
    });
  };

  private handleOrganizationTypeChange = (newValue: OrganizationType | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        organizationType: newValue,
        organizationTypeId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  private handleCountryChange = (newValue: IReferential | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        country: newValue,
        countryId: newValue !== null ? newValue!.id : null,
        region: null,
        regionId: null,
        cityInfo: null,
        cityId: null
      }
    });
  };

  private handleRegionChange = (newValue: IReferential | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        region: newValue,
        regionId: newValue !== null ? newValue!.id : null,
        cityInfo: null,
        cityId: null
      }
    });
  };

  private handleCityInfoChange = (newValue: IReferential | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        cityInfo: newValue,
        cityId: newValue !== null ? newValue!.id : null
      }
    });
  };

  private handleOrganizationChange = (newValue: Organization | null) => {
    this.setState({
      organization: {
        ...this.state.organization,
        parent: newValue,
        parentId: newValue !== null ? newValue!.id : 0,
        organizationType: newValue !== null ? newValue.organizationType : null
      }
    });
  };

  public saveOrganization = async () => {
    try {
      if (this.state.organization.id === 0) {
        await this.organizationService.AddOrganization(this.state.organization);
      } else {
        await this.organizationService.UpdateOrganization(this.state.organization);
      }

      if (this.props.organizationChanged) {
        this.props.organizationChanged();
      }

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  private getRegionsOptions(): IReferential[] | undefined {
    const filteredRegions = this.state.regionList.filter(
      (region) => (this.state.organization.countryId && region.dependencyId) && region.dependencyId === this.state.organization.countryId
    );

    return filteredRegions;
  }
  
  private getCitiesOptions(): IReferential[] | undefined {
    const filteredCities = this.state.cityList.filter(
      (city) => (this.state.organization.regionId && city.dependencyId) && city.dependencyId === this.state.organization.regionId
    );

    return filteredCities;
  }

  public renderForm() {
    return (
      <Grid container spacing={4} alignContent="center" alignItems="center">
        <Grid item xs={12} lg={12} className="mt-4">
          <ValidatorForm onSubmit={this.saveOrganization} instantValidate={true}>
            <WrapperSimple
              sectionHeading={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_GENERAL_DATA_SECTION',
                'Date generale'
              )}
            >
              <TextValidator
                fullWidth
                name="name"
                className="m-2"
                id="name"
                value={this.state.organization.name || ''}
                onChange={this.handleTextChange}
                label={this.translatorService.Tranlate('EDIT_ORGANIZATION_NAME_LABEL', 'Nume')}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />

              <Autocomplete
                id="organizationLevelType"
                className="m-2"
                disabled={
                  this.state.organization.organizationLevelType === null
                    ? false
                    : this.state.organization.organizationLevelType.code ===
                      OrganizationLevelTypeCode.HO
                }
                options={this.state.organizationLevelTypes}
                value={this.state.organization.organizationLevelType}
                onChange={(e: any, newValue: OrganizationLevelType | null) =>
                  this.handleOrganizationLevelTypeChange(newValue)
                }
                getOptionLabel={(option: OrganizationLevelType) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationLevelType"
                    value={this.state.organization.organizationLevelType}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_ORGANIZATION_LEVEL_TYPE_LABEL',
                      'Nivel Ierarhic'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="parent"
                className="m-2"
                disabled={
                  this.state.organization.organizationLevelType === null
                    ? false
                    : this.state.organization.organizationLevelType.code ===
                      OrganizationLevelTypeCode.HO
                }
                options={this.state.parentOrganizations}
                value={this.state.organization.parent}
                onChange={(e: any, newValue: Organization | null) =>
                  this.handleOrganizationChange(newValue)
                }
                getOptionLabel={(option: Organization) => option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="parent"
                    value={this.state.organization.parent}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_PARENT_LABEL',
                      'Entitatea Parinte'
                    )}
                    fullWidth
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Autocomplete
                id="organizationType"
                className="m-2"
                options={this.state.organizationTypes}
                value={this.state.organization.organizationType}
                onChange={(e: any, newValue: OrganizationType | null) =>
                  this.handleOrganizationTypeChange(newValue)
                }
                getOptionLabel={(option: OrganizationType) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationType"
                    value={this.state.organization.organizationType}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_ORGANIZATION_TYPE_LABEL',
                      'Tip Organizatie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </WrapperSimple>

            <WrapperSimple
              sectionHeading={this.translatorService.Tranlate(
                'EDIT_ORGANIZATION_CONTACT_SECTION',
                'Date de contact'
              )}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="fullName"
                    className="m-2"
                    id="fullName"
                    value={this.state.organization.fullName || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_FULL_NAME_LABEL',
                      'Nume Complet'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="companyNumber"
                    className="m-2"
                    id="companyNumber"
                    value={this.state.organization.companyNumber || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_COMPANY_NUMBER_LABEL',
                      'CUI'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="registryNumber"
                    className="m-2"
                    id="registryNumber"
                    value={this.state.organization.registryNumber || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_REGISTRY_NUMBER_LABEL',
                      'Nr. Reg. Comertului'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    id="country"
                    className="m-2"
                    options={this.state.countryList}
                    value={this.state.organization.country}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleCountryChange(newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name="country"
                        value={this.state.organization.country || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_ORGANIZATION_COUNTRY_LABEL',
                          'Tara'
                        )}
                        fullWidth
                        validators={[]}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    id="region"
                    className="m-2"
                    options={this.getRegionsOptions()}
                    value={this.state.organization.region}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleRegionChange(newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name="region"
                        value={this.state.organization.region || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_ORGANIZATION_COUNTY_LABEL',
                          'Judet'
                        )}
                        fullWidth
                        validators={[]}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    id="cityInfo"
                    className="m-2"
                    options={this.getCitiesOptions()}
                    value={this.state.organization.cityInfo}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleCityInfoChange(newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName || ''}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name="cityInfo"
                        value={this.state.organization.cityInfo || ''}
                        label={this.translatorService.Tranlate(
                          'EDIT_ORGANIZATION_CITY_1_LABEL',
                          'Oras'
                        )}
                        fullWidth
                        validators={[]}
                        errorMessages={[
                          this.translatorService.Tranlate(
                            'VALIDATORS_REQUIRED',
                            'Campul este obligatoriu'
                          )
                        ]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="city"
                    className="m-2"
                    id="city"
                    value={this.state.organization.city || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_CITY_LABEL',
                      'Localitate'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="address"
                    className="m-2"
                    id="address"
                    value={this.state.organization.address || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_ADDRESS_LABEL',
                      'Adresa'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="postalCode"
                    className="m-2"
                    id="postalCode"
                    value={this.state.organization.postalCode || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_POSTALCODE_LABEL',
                      'Cod Postal'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="contactPerson"
                    className="m-2"
                    id="contactPerson"
                    value={this.state.organization.contactPerson || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_CONTACT_PERSON_LABEL',
                      'Persoana Contact'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="phone"
                    className="m-2"
                    id="phone"
                    value={this.state.organization.phone || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_PHONE_LABEL',
                      'Telefon'
                    )}
                    validators={[]}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="email"
                    className="m-2"
                    id="email"
                    value={this.state.organization.email || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_EMAIL_LABEL',
                      'E-mail'
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextValidator
                    fullWidth
                    name="dealerId"
                    className="m-2"
                    id="dealerId"
                    value={this.state.organization.dealerId || ''}
                    onChange={this.handleTextChange}
                    label={this.translatorService.Tranlate(
                      'EDIT_ORGANIZATION_DEALER_LABEL',
                      'Dealer Id'
                    )}
                  />
                </Grid>
              </Grid>
            </WrapperSimple>

            <div className="text-right">
              <Button
                className="m-2"
                variant="outlined"
                color="primary"
                onClick={this.props.organizationChanged}
              >
                {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
              </Button>
              <Button className="m-2" variant="contained" color="primary" type="submit">
                {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
              </Button>
            </div>
          </ValidatorForm>
        </Grid>
      </Grid>
    );
  }

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="standard"
              onChange={this.onTabChange}
            >
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_EDIT_TAB',
                  'Informatii Entitate'
                )}
              />
              <Tab
                className="text-capitalize"
                label={this.translatorService.Tranlate(
                  'ORGANIZATION_EDIT_BANK_ACCOUNTS_TAB',
                  'Detalii Bancare'
                )}
              />
            </Tabs>
          </CardContent>
        </Card>
        <TabPanel value={this.state.selectedTab} index={0}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderForm() : null}
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <OrganizationBankAccounts {...this.props} organizationId={this.props.organizationId} />
        </TabPanel>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalEditOrganizationsProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(MavoEditOrganization as any));
