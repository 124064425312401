import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsState, AppState, VehicleState } from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Tooltip,
  CardContent,
  Card,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  Input,
  FormControlLabel
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { CaseCalculationHistory, CaseCalculationPart } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import {
  ReferentialCode,
  ParametreCode,
  InsuredTypeCode,
  CalculationSectionCodeEnum,
  CalculationTypeCode,
  OwnStockCodeEnum
} from '../../helpers/Constants';
import { IReferential } from '../../interfaces/IReferential';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  SelectableRows,
  MUIDataTableMeta
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import CloudDownloadTwoToneIcon from '@material-ui/icons/CloudDownloadTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import { Organization } from '../../interfaces/Organization';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { WorkflowCalculation, WorkflowSectionStepForm } from '../../interfaces/Workflow';
import * as XLSX from 'xlsx';
import { ImportCalculationOriginalPartsFileHeader } from '../../helpers/Constants';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import * as R from 'ramda';
import { CalculationParts } from '../../interfaces/CalculationParts';
import { isNullOrUndefined } from 'util';
import _ from 'lodash';
import { Utils } from '../../helpers/Utils';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';

export interface ExternalCaseDetailsCaseTabCalculationDetailsProps {
  calculationHistoryId: number;
  handleModifiedPropChanges: (modified: boolean) => void;
  onSave: () => void;
  stepFormId: number;
  hasRights: boolean;
}

interface ICaseDetailsCaseTabCalculationDetailsState {
  caseCalculationHistoryDetails: CaseCalculationHistory;
  calculationSectionList: IReferential[];
  calculationParts: CaseCalculationPart[];
  newCalculationPart: CaseCalculationPart | null;
  newCalculationPartId: number | null;
  partQualityTypes: IReferential[];
  supplierList: Organization[];
  isDialogOpen: boolean;
  isLoading: boolean;
  currentSection: IReferential | null;
  TVA: string;
  modified: boolean;
  workflowCalculation: WorkflowCalculation | undefined;
  solutionValues: IReferential[];
  costCenter: IReferential | null;
  deliveryAdress: Organization | null;
  maintenancePackage: IReferential | null;
}

type ICaseDetailsCaseTabCalculationDetailsProps =
  ExternalCaseDetailsCaseTabCalculationDetailsProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
    vehicleState: VehicleState;
  } & ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabCalculationDetails extends React.PureComponent<
  ICaseDetailsCaseTabCalculationDetailsProps,
  ICaseDetailsCaseTabCalculationDetailsState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private appuserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;

  static contextType = ApplicationContext;
  state = {
    caseCalculationHistoryDetails: {} as CaseCalculationHistory,
    calculationSectionList: [],
    calculationParts: [],
    newCalculationPart: null,
    newCalculationPartId: null,
    partQualityTypes: [],
    supplierList: [],
    isDialogOpen: false,
    isLoading: true,
    currentSection: null,
    TVA: '',
    modified: false,
    workflowCalculation: undefined,
    solutionValues: [],
    costCenter: null,
    deliveryAdress: null,
    maintenancePackage: null
  } as ICaseDetailsCaseTabCalculationDetailsState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (caseSettings === null || caseSettings === undefined || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCalculationDetails();
      }
    );
  }

  loadCalculationDetails = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (caseSettings === null || caseSettings === undefined || Number.isNaN(caseId)) {
      return;
    }
    const casee = this.props.caseSettingsState.case;
    const TVA = this.props.caseSettingsState.case!.caseParameters.find(
      (item) => item.parameter!.code === ParametreCode.TVA
    )!.value;

    if (casee === null) {
      return;
    }

    const refQualityPart = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarPartCategory
    );
    const currencyRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Currency
    );
    const refSolutionValue = Referentials.referential.find(
      (item) => item.code === ReferentialCode.SolutionValue
    );
    const maintenancePackageRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.MaintenancePackage
    );

    const [
      currencyLIst,
      caseCalculation,
      calculationSections,
      caseCalculationParts,
      partQualityTypes,
      solutionValues,
      maintenancePackages
    ] = await Promise.all([
      this.appReferentialService.Get(currencyRef!.baseUrl),
      this.caseService.GetCaseCalculationHistoryDetails(this.props.calculationHistoryId),
      this.caseService.GetCalculationSections(),
      this.caseService.GetCaseCalculationParts(this.props.calculationHistoryId),
      this.appReferentialService.Get(refQualityPart!.baseUrl),
      this.appReferentialService.Get(refSolutionValue!.baseUrl),
      this.appReferentialService.Get(maintenancePackageRef!.baseUrl)
    ]);
    const workflowSuppliersList =
      this.props.caseSettingsState.caseSettings!.workflow.workflowSuppliers;
    const suppliers =
      workflowSuppliersList.length > 0
        ? await this.organizationService.GetUserOrganizationsByIds(
            Array.from(new Set(workflowSuppliersList.map((item) => item.supplierId)))
          )
        : [];

    caseCalculation.currency = currencyLIst.find(
      (item) => item.code === caseCalculation.currencyCode
    )!;

    const usr = await this.appuserService.GetUserInfo(caseCalculation.userId);
    caseCalculation.user = usr;
    const form = this.getWorkflowForm(this.props.stepFormId);

    const workflowCalculation = form!.workflowCalculations.find(
      (item) =>
        item.workflowPartner!.partnerId === this.props.appState.appUser!.hoId &&
        (item.userRoleId === this.props.appState.appUser!.roleId || R.isNil(item.userRoleId)) &&
        item.caseStatusId === this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    let costCenter = null;
    if (!R.isNil(this.props.caseSettingsState.case!.caseEvent)) {
      if (!isNullOrUndefined(this.props.caseSettingsState.case!.caseEvent.organizationId)) {
        costCenter = await this.workflowService.GetCostCenter(
          this.props.caseSettingsState.case!.caseEvent.organizationId!
        );
      }
    }

    
    let partsLocationOrganization = null;
    if (!isNullOrUndefined(caseCalculation.partsLocationOrganizationId)) {
      partsLocationOrganization = await this.organizationService.GetOrganization(
        caseCalculation.partsLocationOrganizationId!
      );
    }

    let maintenancePackage: IReferential | null = null;
    if (!isNullOrUndefined(caseCalculation.maintenancePackageId)) {
      maintenancePackage = maintenancePackages.find(
        (item) => item.id === caseCalculation.maintenancePackageId
      )!;
    }

    this.setState({
      caseCalculationHistoryDetails: caseCalculation,
      calculationSectionList: calculationSections,
      calculationParts: caseCalculationParts,
      partQualityTypes: partQualityTypes.filter((item) => item.isActive === true),
      TVA: TVA,
      supplierList: suppliers.filter((ws) => ws.isActive === true),
      workflowCalculation: workflowCalculation,
      solutionValues: solutionValues,
      isLoading: false,
      costCenter:  costCenter,
      deliveryAdress: partsLocationOrganization,
      maintenancePackage: maintenancePackage
    });
  };

  getWorkflowForm = (id: number): WorkflowSectionStepForm | null => {
    const sections = this.props.caseSettingsState.caseSettings!.workflow.workflowSections!;
    for (const section of sections) {
      for (const step of section.workflowSectionSteps) {
        const form = step.workflowSectionStepForms.find((form) => form.id === id);
        if (!R.isNil(form)) {
          return form;
        }
      }
    }

    return null;
  };

  displayCalculationPartPrice = (value: number, partId: any) => {
    const calculationPartsFiltered = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === partId);
    const price =
      calculationPart!.alternativePartQualityId === null
        ? value
        : calculationPart!.alternativePrice;
    return (Math.round(price! * 100) / 100).toFixed(2);
  };

  displayCalculationPartDiscount = (value: number, partId: any) => {
    const calculationPartsFiltered = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === partId);
    const discount =
      calculationPart!.alternativePartQualityId === null
        ? R.isNil(value)
          ? 0
          : value
        : R.isNil(calculationPart!.alternativePartDiscount)
        ? 0
        : calculationPart!.alternativePartDiscount;
    return discount;
  };

  calculationPartUnitOEPrice = (calculationPart: CaseCalculationPart) => {
    if (R.isNil(calculationPart!.discount)) {
      return  (
        Number((
          Math.round(calculationPart!.originalPrice * 100) / 100
        ).toFixed(2))
      );
    }
    const value = calculationPart!.originalPrice;
    const discount = calculationPart!.discount;
    
    return (
      Number((Math.round(value * (1 - discount / 100) * 100) / 100).toFixed(2))
    );
  };

  calculationPartUnitSalePrice = (calculationPart: CaseCalculationPart) => {
    if (calculationPart!.alternativePartQualityId === null) {
      if (R.isNil(calculationPart!.discount)) {
        return (
          Number((
            Math.round(calculationPart!.originalPrice * 100) / 100
          ).toFixed(2))
        );
      }
      const value = calculationPart!.originalPrice;
      const discount = calculationPart!.discount;
      return  (
        Number((Math.round(value * (1 - discount / 100) * 100) / 100).toFixed(2))
      );
    } else {
      if (R.isNil(calculationPart!.alternativePartDiscount)) {
        return (
          Number((
            Math.round(calculationPart!.alternativePrice! * (1 + (calculationPart!.additionInsurer ?? 0) / 100) * 100) / 100
          ).toFixed(2))
        );
      }
      const value = calculationPart!.alternativePrice!;
      const discount = calculationPart!.alternativePartDiscount - (calculationPart!.additionInsurer ?? 0);
      return (
        Number((
          Math.round(value * (1 - discount / 100) * 100) / 100
        ).toFixed(2))
      );
    }
  };

  displayCalculationPartUnitSalePrice = (row: any) => {
    const calculationPartsFiltered = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === row[0]);
    if (R.isNil(calculationPart)) {
      return 0;
    }
    
    const saleUnitPrice = this.calculationPartUnitSalePrice(calculationPart);
    return (saleUnitPrice +
      ' ' +
      this.state.caseCalculationHistoryDetails.currency!.displayName
    );
  };

  displayCalculationPartTotalPrice = (row: any) => {
    const calculationPartsFiltered = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === row[0]);
    if (R.isNil(calculationPart)) {
      return 0;
    }
    const saleUnitPrice = this.calculationPartUnitSalePrice(calculationPart);
    console.log('calculationPart', calculationPart);
    console.log('saleUnitPrice', saleUnitPrice, 'pieceNr', calculationPart!.pieceNr, 'total', saleUnitPrice * calculationPart!.pieceNr);
    return (
        (
          Math.round(saleUnitPrice * calculationPart!.pieceNr * 100) / 100
        ).toFixed(2) +
        ' ' +
        this.state.caseCalculationHistoryDetails.currency!.displayName
    );
  };

  calculationTotalPartsOEPrice = () => {
    const calculationPartsFiltered = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === 1
    );
  
    const total = calculationPartsFiltered.reduce((sum, calculationPart: CaseCalculationPart) => {
      if (R.isNil(calculationPart!.discount)) {
        return sum + (
          Number((
            Math.round(calculationPart!.originalPrice * calculationPart!.pieceNr * 100) / 100
          ).toFixed(2))
        );
      }
      const value = calculationPart!.originalPrice * calculationPart!.pieceNr;
      const discount = calculationPart!.discount;

      return sum + (
        Number((Math.round(value * (1 - discount / 100) * 100) / 100).toFixed(2))
      );
    }, 0);
  
    return total;
  };

  calculationTotalPartsAMPrice = () => {
    return this.state.caseCalculationHistoryDetails.amountPiecesWithDiscount;
  };

  displayCalculationTotalPartsEconomy = () => {
    const originalTotal = this.calculationTotalPartsOEPrice(); 
    const alternativeTotal = this.calculationTotalPartsAMPrice(); 
    console.log('originalTotal', originalTotal, 'alternativeTotal', alternativeTotal, 'percentage', ((originalTotal - alternativeTotal) * 100) / originalTotal);
    const percentageEconomy = (((originalTotal - alternativeTotal) * 100) / originalTotal).toFixed(2) + '%';
    return percentageEconomy;
  };

  displayCalculationPartPLPVProcent = (row: any) => {
    const calculationPartsFiltered = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === row[0]);
    if (R.isNil(calculationPart)) {
      return 0;
    }

    const originalPrice = this.calculationPartUnitOEPrice(calculationPart);
    const salePrice = this.calculationPartUnitSalePrice(calculationPart);
    console.log('calculationPart', calculationPart);
    console.log('originalPrice', originalPrice, 'salePrice', salePrice, 'percentage', ((originalPrice - salePrice) * 100) / originalPrice);
    return ((originalPrice - salePrice) * 100) / originalPrice;
  };


  displayCalculationTotalPartsAMPriceBK = () => {
    const calculationPartsFiltered = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === 1
    );
    const total = calculationPartsFiltered.reduce((sum, calculationPart: CaseCalculationPart) => {
      if (calculationPart!.alternativePartQualityId === null) {
        if (R.isNil(calculationPart!.discount)) {
          return sum + (
            Number((
              Math.round(calculationPart!.originalPrice * calculationPart!.pieceNr * 100) / 100
            ).toFixed(2))
          );
        }
        const value = calculationPart!.originalPrice * calculationPart!.pieceNr;
        const discount = calculationPart!.discount;
        return sum + (
          Number((Math.round(value * (1 - discount / 100) * 100) / 100).toFixed(2))
        );
      } else {
        if (R.isNil(calculationPart!.alternativePartDiscount)) {
          return sum + (
            Number((
              Math.round(calculationPart!.alternativePrice! * calculationPart!.pieceNr * (1 + (calculationPart!.additionInsurer ?? 0) / 100) * 100) / 100
            ).toFixed(2))
          );
        }
        const value = calculationPart!.alternativePrice! * calculationPart!.pieceNr;
        const discount = calculationPart!.alternativePartDiscount - (calculationPart!.additionInsurer ?? 0);
        return sum + (
          Number((
            Math.round(value * (1 - discount / 100) * 100) / 100
          ).toFixed(2))
        );
      }
    }, 0);

    return total;
  };

  displayCalculationPartQuality = (value: IReferential, row: any) => {
    const calculationPartsFiltered = this.state.calculationParts.filter(
      (item) => item.calculationSectionId === 1
    );
    const calculationPart = calculationPartsFiltered.find((item) => item.id === row[0]);
    if (calculationPart!.alternativePartQualityId === null) {
      return (
        <Tooltip
          title={
            <div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_PRODUCER_HEADER',
                  'Producator'
                ) +
                  ': ' +
                  (R.isNil(row[6]) ? '' : row[6])}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_DELIVERY_TIME_HEADER',
                  'Timp livrare'
                ) +
                  ': ' +
                  (R.isNil(row[7]) ? '' : row[7])}
              </div>
            </div>
          }
        >
          <div>
            <span>{value.displayName + ' '}</span>
            <FontAwesomeIcon icon={['fas', 'info-circle']} />
          </div>
        </Tooltip>
      );
    } else {
      const partQualityType = this.state.partQualityTypes.find(
        (quality) => quality.id === calculationPart!.alternativePartQualityId
      );
      return (
        <Tooltip
          title={
            <div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_ALT_PART_GN_LABEL',
                  'Cod alternativa'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativeGuideNumber)
                    ? ''
                    : calculationPart!.alternativeGuideNumber)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_ALT_PART_NAME_LABEL',
                  'Descriere alternativa'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativePartName)
                    ? ''
                    : calculationPart!.alternativePartName)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_PRODUCER_HEADER',
                  'Producator'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.producer) ? '' : calculationPart!.producer)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_DELIVERY_TIME_HEADER',
                  'Timp livrare'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.deliveryTime) ? '' : calculationPart!.deliveryTime)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_ALT_PART_QUANTITY_LABEL',
                  'Cantitate disponibila'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativePartStockQuantity)
                    ? ''
                    : calculationPart!.alternativePartStockQuantity)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_STOCK_LOCATION_LABEL',
                  'Locatie'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativePartStockLocation)
                    ? ''
                    : calculationPart!.alternativePartStockLocation)}
              </div>
              <div>
                {this.translatorService.Tranlate(
                  'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_REMARKS_LABEL',
                  'Observatii'
                ) +
                  ': ' +
                  (R.isNil(calculationPart!.alternativePartRemarks)
                    ? ''
                    : calculationPart!.alternativePartRemarks)}
              </div>
            </div>
          }
        >
          <div>
            <span>{partQualityType!.displayName + ' '}</span>
            <FontAwesomeIcon icon={['fas', 'info-circle']} />
          </div>
        </Tooltip>
      );
    }
  };

  displayCalculationPartSupplier = (supplierId: any) => {
    const supplier = this.state.supplierList.find((item) => item.id === supplierId);
    const supplierName = !R.isNil(supplier) ? (supplier.name.toUpperCase() == OwnStockCodeEnum.OWN_STOCK.toString() ? this.translatorService.Tranlate('CALCULATION_DETAILS_OWN_STOCK_HEADER', 'Stoc Propriu') : supplier.name) : '';
    return supplierName;
  };

  handlePartInsurerAdditionChange = (value: number | null, currentPartId: number) => {
    // Find the corresponding part in the calculationParts array
    const updatedCalculationParts = this.state.calculationParts.map((part) => {
      if (part.id === currentPartId) {
        // Reset the additionInsurer for the specific part
        return { ...part, 
          additionInsurer: value
        };
      }
      return part;
    });
  
    // Update the state with the modified calculationParts array
    this.setState({
      calculationParts: updatedCalculationParts,
    },
    () => {
      this.updateAmount();
    });
  };
  
  partDisabled = (part: CaseCalculationPart) => {
    return part.alternativeGuideNumber === null || part.supplierId === null ? true : false;
  };

  viewPrcNewColumns = () => {
    return !R.isNil(this.state.workflowCalculation) && this.state.workflowCalculation!.viewPrcNewColumns && this.state.caseCalculationHistoryDetails.calculationType.code === CalculationTypeCode.ALT;
  };

  getColumns = (sectionId: number) => {
    let columns = [];
    if (sectionId === 1) {
      columns = [
        {
          name: 'id',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'supplierId',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'guideNumber',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_GUIDE_NUMBER_HEADER', 'Cod'),
          options: {
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const item = this.state.calculationParts.find(
                (item) => item.id === tableMeta.rowData[0]
              );
              return (
                <Button
                  color="primary"
                  className="text-primary"
                  onClick={(e) => this.newCalculationPart(tableMeta.rowData[0], sectionId)}
                >
                  <span>{value}</span>
                </Button>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'alternativeGuideNumber',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_ALT_GUIDE_NUMBER_HEADER',
            'Cod Aftermarket'
          ),
          options: { filter: false } as MUIDataTableColumnOptions
        }, 
        {
          name: 'name',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_DESCRIPTION_HEADER',
            'Descriere'
          ),
          options: {
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              const item = this.state.calculationParts.find(
                (item) => item.id === tableMeta.rowData[0]
              );

              if (R.isNil(item)) {
                return '';
              }
              return R.isNil(item!.alternativePartQualityId)
                ? item!.name
                : item!.alternativePartName;
            }
          }
        },
        {
          name: 'alternativePartDescription',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_ALTERNATIVE_PART_DESCRIPTION_HEADER',
            'Descriere piesa alt'
          ),
          options: {
            display: !R.isNil(this.state.workflowCalculation) && this.state.workflowCalculation!.hasAlternativePartDescription ? 'true' : 'false'
          }
        },
        {
          name: '',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_SUPPLIER_HEADER', 'Furnizor'),
          options: {
            filter: false,
            customBodyRender: (value: IReferential, tableMeta: MUIDataTableMeta) => {
              return this.displayCalculationPartSupplier(tableMeta.rowData[1]);
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'producer',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_PRODUCER_HEADER',
            'Producator'
          ),
          options: { filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'deliveryTime',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'alternativePartQuality',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'carPartCategory',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_QUALITY_HEADER', 'Calitate'),
          options: {
            customBodyRender: (value: IReferential, tableMeta: MUIDataTableMeta) => {
              return this.displayCalculationPartQuality(value, tableMeta.rowData);
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'pieceNr',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_UNITS_HEADER', 'Unitati')
        },
        {
          name: 'alternativePrice',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'alternativeNetPrice',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_NET_PRICE_LKQ_HEADER',
            'Pret Net'
          ),
          options: {
            display: !R.isNil(this.state.workflowCalculation) && this.state.workflowCalculation!.alternativeNetPrice ? 'true' : 'false',
            customBodyRender: (value: number | null, tableMeta: MUIDataTableMeta) => {
              return value ? Utils.countryNumberFormat((Math.round(value * 100) / 100).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) : '';
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'originalPrice',
          label: this.viewPrcNewColumns() ? this.translatorService.Tranlate(
            'CALCULATION_DETAILS_UNIT_AQUISITION_PRICE_HEADER',
            'Pret Achizitie'
          ) :
          this.translatorService.Tranlate(
            'CALCULATION_DETAILS_UNIT_PRICE_HEADER',
            'Pret Unitar'
          ),
          options: {
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return Utils.countryNumberFormat(this.displayCalculationPartPrice(value, tableMeta.rowData[0]), this.props.appState.appUser?.organization?.country ?? null);
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'discount',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_DISCOUNT_HEADER',
            'Discount (%)'
          ),
          options: {
            display: this.viewPrcNewColumns() ? 'false' : 'true',
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return this.displayCalculationPartDiscount(value, tableMeta.rowData[0]);
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'additionInsurer',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_INSURER_ADDITION_HEADER',
            'Adaos Asigurator (%)'
          ),
          options: {
            display: this.viewPrcNewColumns() ? 'true' : 'false',
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              const calculationPartsFiltered = this.state.calculationParts.filter(
                (item) => item.calculationSectionId === 1
              );
              const calculationPart = calculationPartsFiltered.find((item) => item.id === tableMeta.rowData[0]);
        
              if (R.isNil(calculationPart)) {
                return '';
              }
        
              return (
                <Grid container>
                  <Grid item xs={6}>
                    <Input
                      className="ml-1"
                      type="text"
                      id="additionInsurer"
                      value={calculationPart.additionInsurer !== null ? calculationPart.additionInsurer : ''}
                      onChange={(e: any) => {
                        const inputValue = e.target.value.trim();
                        const numericRegex = /^[0-9]+$/;
                        if (numericRegex.test(inputValue) || inputValue === '0' || inputValue === '') {
                          const numericValue = inputValue === '' ? null : parseFloat(inputValue);
                          this.handlePartInsurerAdditionChange(numericValue, calculationPart.id);
                        }
                      }}
                      disabled={this.partDisabled(calculationPart) || !this.props.hasRights ? true : false} 
                      inputMode="numeric" 
                    />
                  </Grid>
                </Grid>
              );
            },
          } as MUIDataTableColumnOptions
        },        
        {
          name: '',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_TOTAL_PRICE_HEADER', 'Pret Total'),
          options: {
            display: this.viewPrcNewColumns() ? 'false' : 'true',
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return Utils.countryNumberFormat(this.displayCalculationPartTotalPrice(tableMeta.rowData).toString(), this.props.appState.appUser?.organization?.country ?? null);
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: '',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_TOTAL_SALE_PRICE_HEADER',
            'Pret Vanzare'
          ),
          options: {
            display: this.viewPrcNewColumns() ? 'true' : 'false',
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return this.displayCalculationPartUnitSalePrice(tableMeta.rowData);
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: '',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_PL_PV_PRICE_HEADER',
            'PL/PV'
          ),
          options: {
            display: this.viewPrcNewColumns() ? 'true' : 'false',
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              const percentage = this.displayCalculationPartPLPVProcent(tableMeta.rowData);
              const style = percentage < 0 ? { color: 'red' } : { color: 'green' };
              return (
                <span style={style}>
                  {percentage.toFixed(2) + '%'}
                </span>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: '',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_TOTAL_PRICE_VALUE_HEADER', 'Valoare'),
          options: {
            display: this.viewPrcNewColumns() ? 'true' : 'false',
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return Utils.countryNumberFormat(this.displayCalculationPartTotalPrice(tableMeta.rowData).toString(), this.props.appState.appUser?.organization?.country ?? null);
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'remarks',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_REMARKS_HEADER', 'Observatii')
        },
        {
          name: '',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_SOLUTION_HEADER',
            'Solutie Proprietar'
          ),
          options: {
            display:
              !R.isNil(this.state.workflowCalculation) &&
              (this.state.workflowCalculation!.ownerSolutionView ||
                this.state.workflowCalculation!.ownerSolution)
                ? 'true'
                : 'false',
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              const partId = tableMeta.rowData[0];
              const part = this.state.calculationParts.find((item) => item.id === partId);

              if (part === null) {
                return '';
              }
              if (R.isNil(part!.repairMethod) || part!.repairMethod === '') {
                return '';
              }

              return (
                <React.Fragment>
                  <Autocomplete
                    id="solutionValue"
                    className=""
                    disabled={
                      !this.state.workflowCalculation ||
                      !this.state.workflowCalculation!.ownerSolution!
                    }
                    options={this.state.solutionValues}
                    value={part!.solutionValue}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleSolutionValueChange(part!, newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="solutionValue"
                        value={part!.solutionValue}
                        label={this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_SOLUTION',
                          'Solutie'
                        )}
                        fullWidth
                      />
                    )}
                  />
                  <TextField
                    disabled={
                      !this.state.workflowCalculation ||
                      !this.state.workflowCalculation!.ownerSolution!
                    }
                    value={
                      !R.isNil(part) && !R.isNil(part!.solutionComment) ? part!.solutionComment : ''
                    }
                    onChange={(e) => this.handleSolutionCommentChange(e, part!)}
                    label={this.translatorService.Tranlate(
                      'CALCULATION_DETAILS_SOLUTION_DESCRIPTION',
                      'Descriere'
                    )}
                    fullWidth
                  />
                </React.Fragment>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: '',
          options: {
            viewColumns: false,
            filter: false,
            setCellHeaderProps: () => ({ align: 'center' }),
            setCellProps: () => ({ align: 'center' }),
            customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
              return (
                <div>
                  {!R.isNil(this.state.workflowCalculation) &&
                  !R.isNil(this.state.workflowCalculation.deleteCarParts) &&
                  this.state.workflowCalculation.deleteCarParts ? (
                    <IconButton
                      aria-label="delete"
                      color="inherit"
                      className="text-danger"
                      size="small"
                      onClick={(e) => this.deleteCalculationPart(e, tableMeta)}
                    >
                      <DeleteOutlineTwoToneIcon />
                    </IconButton>
                  ) : null}
                </div>
              );
            }
          }
        }
      ];
    } else {
      columns = [
        {
          name: 'id',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'supplierId',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'alternativeGuideNumber',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'guideNumber',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_GUIDE_NUMBER_HEADER', 'Cod'),
          options: {
            customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
              return (
                <Button
                  color="primary"
                  className="text-primary"
                  onClick={(e) => this.newCalculationPart(tableMeta.rowData[0], sectionId)}
                >
                  <span>{value}</span>
                </Button>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'name',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_DESCRIPTION_HEADER',
            'Descriere'
          )
        },
        {
          name: 'pieceNr',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_UNITS_HEADER', 'Unitati')
        },
        {
          name: 'alternativePrice',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'originalPrice',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_UNIT_PRICE_HEADER',
            'Pret Unitar'
          ),
          options: {
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return Utils.countryNumberFormat((Math.round(value * 100) / 100).toFixed(2), this.props.appState.appUser?.organization?.country ?? null);
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'discount',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_DISCOUNT_HEADER',
            'Discount (%)'
          ),
          options: {
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              return R.isNil(value) ? 0 : value;
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: '',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_TOTAL_PRICE_HEADER',
            'Pret Total'
          ),
          options: {
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              if (R.isNil(tableMeta.rowData[8])) {
                return (
                  Utils.countryNumberFormat((Math.round(tableMeta.rowData[5] * tableMeta.rowData[7] * 100) / 100).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                  ' ' +
                  this.state.caseCalculationHistoryDetails.currency!.displayName
                );
              }
              return (
                Utils.countryNumberFormat((
                  Math.round(
                    (tableMeta.rowData[5] * tableMeta.rowData[7] -
                      (tableMeta.rowData[5] * tableMeta.rowData[7] * tableMeta.rowData[8]) / 100) *
                      100
                  ) / 100
                ).toFixed(2), this.props.appState.appUser?.organization?.country ?? null) +
                ' ' +
                this.state.caseCalculationHistoryDetails.currency!.displayName
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: 'remarks',
          label: this.translatorService.Tranlate('CALCULATION_DETAILS_REMARKS_HEADER', 'Observatii')
        },
        {
          name: '',
          label: this.translatorService.Tranlate(
            'CALCULATION_DETAILS_SOLUTION_HEADER',
            'Solutie Proprietar'
          ),
          options: {
            display:
              !R.isNil(this.state.workflowCalculation) &&
              (this.state.workflowCalculation!.ownerSolutionView ||
                this.state.workflowCalculation!.ownerSolution)
                ? 'true'
                : 'false',
            customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
              const partId = tableMeta.rowData[0];
              const part = this.state.calculationParts.find((item) => item.id === partId);

              if (part === null) {
                return '';
              }
              if (R.isNil(part!.repairMethod) || part!.repairMethod === '') {
                return '';
              }

              return (
                <React.Fragment>
                  <Autocomplete
                    id="solutionValue"
                    className=""
                    disabled={
                      !this.state.workflowCalculation ||
                      !this.state.workflowCalculation!.ownerSolution!
                    }
                    options={this.state.solutionValues}
                    value={part!.solutionValue}
                    onChange={(e: any, newValue: IReferential | null) =>
                      this.handleSolutionValueChange(part!, newValue)
                    }
                    getOptionLabel={(option: IReferential) => option.displayName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="solutionValue"
                        value={part!.solutionValue}
                        label={this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_SOLUTION',
                          'Solutie'
                        )}
                        fullWidth
                      />
                    )}
                  />
                  <TextField
                    disabled={
                      !this.state.workflowCalculation ||
                      !this.state.workflowCalculation!.ownerSolution!
                    }
                    value={
                      !R.isNil(part) && !R.isNil(part.solutionComment) ? part.solutionComment : ''
                    }
                    onChange={(e) => this.handleSolutionCommentChange(e, part!)}
                    label={this.translatorService.Tranlate(
                      'CALCULATION_DETAILS_SOLUTION_DESCRIPTION',
                      'Descriere'
                    )}
                    fullWidth
                  />
                </React.Fragment>
              );
            }
          } as MUIDataTableColumnOptions
        },
        {
          name: '',
          options: {
            viewColumns: false,
            filter: false,
            setCellHeaderProps: () => ({ align: 'center' }),
            setCellProps: () => ({ align: 'center' }),
            customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
              return (
                <div>
                  {!R.isNil(this.state.workflowCalculation) &&
                  !R.isNil(
                    sectionId === 2
                      ? this.state.workflowCalculation.deleteLabor
                      : sectionId === 3
                      ? this.state.workflowCalculation.deletePaintingMaterials
                      : sectionId === 4
                      ? this.state.workflowCalculation.deletePaintingLabor
                      : false
                  ) &&
                  (sectionId === 2
                    ? this.state.workflowCalculation.deleteLabor
                    : sectionId === 3
                    ? this.state.workflowCalculation.deletePaintingMaterials
                    : sectionId === 4
                    ? this.state.workflowCalculation.deletePaintingLabor
                    : false) ? (
                    <IconButton
                      aria-label="delete"
                      color="inherit"
                      className="text-danger"
                      size="small"
                      onClick={(e) => this.deleteCalculationPart(e, tableMeta)}
                    >
                      <DeleteOutlineTwoToneIcon />
                    </IconButton>
                  ) : null}
                </div>
              );
            }
          }
        }
      ];
    }

    return columns;
  };

  updateAmount = () => {
    const calcPartList = new CalculationParts(
      this.state.calculationSectionList,
      this.state.calculationParts,
      parseInt(this.state.TVA),
      this.state.caseCalculationHistoryDetails.smallPartsPercent,
      false
    );

    this.setState({
      caseCalculationHistoryDetails: {
        ...this.state.caseCalculationHistoryDetails,
        amountPieces: calcPartList.GetAmountPieceTotal(),
        amountLabor: calcPartList.GetAmountPart(CalculationSectionCodeEnum.LABOR),
        amountPaintingMaterials: calcPartList.GetAmountPart(CalculationSectionCodeEnum.PAINT),
        amountPaintingLabor: calcPartList.GetAmountPart(CalculationSectionCodeEnum.PAINT_LABOR),
        amountPiecesWithDiscount: calcPartList.GetAmountPartWithDiscount(
          CalculationSectionCodeEnum.PART
        ),
        amountLaborWithDiscount: calcPartList.GetAmountPartWithDiscount(
          CalculationSectionCodeEnum.LABOR
        ),
        amountPaintingMaterialsWithDiscount: calcPartList.GetAmountPartWithDiscount(
          CalculationSectionCodeEnum.PAINT
        ),
        amountPaintingLaborWithDiscount: calcPartList.GetAmountPartWithDiscount(
          CalculationSectionCodeEnum.PAINT_LABOR
        ),
        amountDiscount: calcPartList.TotalDiscount(),
        amountWithoutTVA: calcPartList.TotalWithoutVAT(),
        amountTVA: calcPartList.TotalVAT(),
        amount: calcPartList.Total(),
        additionInsurerParts: calcPartList.GetTotalInsurerAddition(CalculationSectionCodeEnum.PART),
        amountPiecesDiscount: calcPartList.GetTotalPartsDiscount(CalculationSectionCodeEnum.PART),
        smallPartsValue: calcPartList.GetSmallPartsValue()
      }
    });
  };

  newCalculationPart = async (calculationPartId: number | null, sectionId: number) => {
    let newCalculationPart;
    if (calculationPartId === null) {
      newCalculationPart = { ...this.state.newCalculationPart } as CaseCalculationPart;
      newCalculationPart.caseCalculationHistoryId = this.props.calculationHistoryId;
      newCalculationPart.id = -1 * this.state.calculationParts.length;
      newCalculationPart.calculationSectionId = sectionId;
      newCalculationPart.discount = 0;
      newCalculationPart.calculationSection = this.state.calculationSectionList.find(
        (item) => item.id === sectionId
      )!;
    } else {
      newCalculationPart = this.state.calculationParts.find(
        (item) => item.id === calculationPartId
      )!;
      if (newCalculationPart.alternativePartQualityId === null) {
        newCalculationPart.discount = R.isNil(newCalculationPart.discount)
          ? 0
          : newCalculationPart.discount;
      } else {
        newCalculationPart.alternativePartDiscount = R.isNil(
          newCalculationPart.alternativePartDiscount
        )
          ? 0
          : newCalculationPart.alternativePartDiscount;
      }
      if (newCalculationPart.alternativePartQualityId !== null) {
        const alternativePartQualityId = newCalculationPart.alternativePartQualityId;
        const partQualityTypes = this.state.partQualityTypes.sort(function (a, b) {
          return a.displayName.localeCompare(b.displayName);
        });
        const partQualityTypesFilter = partQualityTypes.filter(
          (qualityType) => qualityType.id === alternativePartQualityId
        );
        newCalculationPart.alternativePartQuality = partQualityTypesFilter[0];
      }

      const supplierId = newCalculationPart.supplierId;
      const supplier = this.state.supplierList.find((item) => item.id === supplierId);
      newCalculationPart.supplier = !R.isNil(supplier) ? supplier : null;
    }

    this.setState({
      newCalculationPart: newCalculationPart,
      newCalculationPartId: calculationPartId,
      isDialogOpen: true,
      currentSection: this.state.calculationSectionList.find((item) => item.id === sectionId)!
    });
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false,
      newCalculationPart: null,
      newCalculationPartId: null
    });
  };
  NumberFormatCustomDiscount = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator
        decimalScale={2}
        isNumericString
        suffix=" %"
      />
    );
  };
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;
    if (event.target.type === 'text' || 'password') {
      value = event.currentTarget.value;
    }

    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        [event.target.id]: value
      }
    });
  };

  handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>, propName: string) => {
    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        [propName]: event.target.value === null ? 0 : parseFloat(event.target.value)
      }
    });
  };

  handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>, propName: string) => {
    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        [propName]: event.target.value === null ? 0 : parseFloat(event.target.value)
      }
    });
  };

  deleteCalculationPart = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      const calculationPartIndex = this.state.calculationParts.findIndex((item) => item.id === id);
      const calculationParts = [...this.state.calculationParts];
      calculationParts.splice(calculationPartIndex, 1);

      this.setState(
        {
          calculationParts: calculationParts
        },
        () => {
          this.updateAmount();
          this.props.handleModifiedPropChanges(true);
        }
      );
    } catch (error) {
      // this.props.enqueueSnackbar(this.translatorService.Tranlate("ERROR_MSG", "Eroare"), { variant: 'error' });
    }
  };

  addCalculationPartForm = async () => {
    try {
      const calculationParts = [...this.state.calculationParts];
      if (this.state.newCalculationPartId === null) {
        //add
        // if (this.state.workflowSuppliers.findIndex(item => item.supplierId === this.state.selectedWorkflowSupplierType!.id &&  this.state.selectedWorkflowCarPartCategoryTypeMultiple.findIndex(itemm => itemm.id === item.carPartCategoryId)!==-1 ) !== -1 ) {
        //     this.props.enqueueSnackbar(this.translatorService.Tranlate("ERROR_MSG_WORKFLOW_SUPPLIER_EXISTS", "Furnizorul exista deja!"), { variant: 'error' });
        //     return;
        // }
        calculationParts.push({
          ...this.state.newCalculationPart,
          carPartCategoryId:
            this.state.currentSection!.id === 1
              ? this.state.newCalculationPart!.carPartCategory!.id
              : null,
          carPartCategory:
            this.state.currentSection!.id === 1
              ? this.state.newCalculationPart!.carPartCategory
              : null,
          supplier:
            this.state.currentSection!.id === 1
              ? R.isNil(this.state.newCalculationPart!.supplier)
                ? null
                : this.state.newCalculationPart!.supplier
              : null,
          supplierId:
            this.state.currentSection!.id === 1
              ? R.isNil(this.state.newCalculationPart!.supplier)
                ? null
                : this.state.newCalculationPart!.supplier.id
              : null,
          amount: 0,
          alternativePartQualityId: null
        } as CaseCalculationPart);
      } else {
        // update

        const index = calculationParts.findIndex(
          (item) => item.id === this.state.newCalculationPartId
        )!;
        calculationParts[index] = {
          ...this.state.newCalculationPart!,
          carPartCategoryId:
            this.state.currentSection!.code === CalculationSectionCodeEnum.PART
              ? this.state.newCalculationPart!.carPartCategory!.id
              : null,
          carPartCategory:
            this.state.currentSection!.code === CalculationSectionCodeEnum.PART
              ? this.state.newCalculationPart!.carPartCategory
              : null,
          alternativePartQualityId:
            this.state.currentSection!.code === CalculationSectionCodeEnum.PART
              ? this.state.newCalculationPart!.alternativePartQuality !== null &&
                this.state.newCalculationPart!.alternativePartQualityId !== null
                ? this.state.newCalculationPart!.alternativePartQuality!.id
                : null
              : null,
          alternativePartQuality:
            this.state.currentSection!.code === CalculationSectionCodeEnum.PART
              ? this.state.newCalculationPart!.alternativePartQualityId !== null &&
                this.state.newCalculationPart!.alternativePartQuality !== null
                ? this.state.newCalculationPart!.alternativePartQuality
                : null
              : null,
          supplier:
            this.state.currentSection!.code === CalculationSectionCodeEnum.PART
              ? R.isNil(this.state.newCalculationPart!.supplier)
                ? null
                : this.state.newCalculationPart!.supplier
              : null,
          supplierId:
            this.state.currentSection!.code === CalculationSectionCodeEnum.PART
              ? R.isNil(this.state.newCalculationPart!.supplier)
                ? null
                : this.state.newCalculationPart!.supplier.id
              : null,
          amount: 0
        };
      }

      this.setState(
        {
          calculationParts: calculationParts,
          newCalculationPartId: null,
          newCalculationPart: null,
          // isLoading: true,
          isDialogOpen: false
        },
        () => {
          this.updateAmount();
          this.props.handleModifiedPropChanges(true);
        }
      );
    } catch (error) {}
  };

  handleAutocompletePartQualityChange = async (newValue: IReferential | null, propName: any) => {
    if (newValue === null) {
      this.setState({
        // supplierList: [],
        newCalculationPart: {
          ...this.state.newCalculationPart!,
          [propName]: null
        }
      });
      return;
    }

    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        [propName]: newValue
      }
    });
  };

  handleAutocompleteSupplierChange = (newValue: any | null) => {
    this.setState({
      newCalculationPart: {
        ...this.state.newCalculationPart!,
        supplier: newValue
      }
    });
  };

  setAlternativePartQuality = (
    sectionId: number,
    carPartCategoryOriginal: any,
    alternativePartQualityId: any,
    alternativePartQuality: any,
    newCalculationPartId: any
  ) => {
    if (sectionId === 1) {
      if (alternativePartQualityId === null || newCalculationPartId === null) {
        return carPartCategoryOriginal;
      } else {
        return alternativePartQuality;
      }
    } else {
      return carPartCategoryOriginal;
    }
  };

  public renderAddForm(sectionId: any, newCalcPartId: any) {
    return R.isNil(this.state.newCalculationPart) ? null : (
      <ValidatorForm onSubmit={(e) => this.addCalculationPartForm()}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextValidator
              disabled={
                newCalcPartId !== null
                  ? !R.isNil(this.state.workflowCalculation) &&
                    !R.isNil(
                      sectionId === 1
                        ? this.state.workflowCalculation.editCarParts
                        : sectionId === 2
                        ? this.state.workflowCalculation.editLabor
                        : sectionId === 3
                        ? this.state.workflowCalculation.editPaintingMaterials
                        : sectionId === 4
                        ? this.state.workflowCalculation.editPaintingLabor
                        : false
                    ) &&
                    (sectionId === 1
                      ? this.state.workflowCalculation.editCarParts
                      : sectionId === 2
                      ? this.state.workflowCalculation.editLabor
                      : sectionId === 3
                      ? this.state.workflowCalculation.editPaintingMaterials
                      : sectionId === 4
                      ? this.state.workflowCalculation.editPaintingLabor
                      : false)
                    ? false
                    : true
                  : false
              }
              fullWidth
              name="guideNumber"
              className="m-2"
              id="guideNumber"
              value={this.state.newCalculationPart!.guideNumber}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_GUIDE_NUMBER_HEADER',
                'Cod'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <TextValidator
              disabled={
                newCalcPartId !== null
                  ? !R.isNil(this.state.workflowCalculation) &&
                    !R.isNil(
                      sectionId === 1
                        ? this.state.workflowCalculation.editCarParts
                        : sectionId === 2
                        ? this.state.workflowCalculation.editLabor
                        : sectionId === 3
                        ? this.state.workflowCalculation.editPaintingMaterials
                        : sectionId === 4
                        ? this.state.workflowCalculation.editPaintingLabor
                        : false
                    ) &&
                    (sectionId === 1
                      ? this.state.workflowCalculation.editCarParts
                      : sectionId === 2
                      ? this.state.workflowCalculation.editLabor
                      : sectionId === 3
                      ? this.state.workflowCalculation.editPaintingMaterials
                      : sectionId === 4
                      ? this.state.workflowCalculation.editPaintingLabor
                      : false)
                    ? false
                    : true
                  : false
              }
              fullWidth
              name="name"
              className="m-2"
              id="name"
              value={this.state.newCalculationPart!.name}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_DESCRIPTION_HEADER',
                'Descriere'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />

            <TextValidator
              disabled={
                newCalcPartId !== null
                  ? !R.isNil(this.state.workflowCalculation) &&
                    !R.isNil(
                      sectionId === 1
                        ? this.state.workflowCalculation.editCarParts
                        : sectionId === 2
                        ? this.state.workflowCalculation.editLabor
                        : sectionId === 3
                        ? this.state.workflowCalculation.editPaintingMaterials
                        : sectionId === 4
                        ? this.state.workflowCalculation.editPaintingLabor
                        : false
                    ) &&
                    (sectionId === 1
                      ? this.state.workflowCalculation.editCarParts
                      : sectionId === 2
                      ? this.state.workflowCalculation.editLabor
                      : sectionId === 3
                      ? this.state.workflowCalculation.editPaintingMaterials
                      : sectionId === 4
                      ? this.state.workflowCalculation.editPaintingLabor
                      : false)
                    ? false
                    : true
                  : false
              }
              fullWidth
              name="pieceNr"
              className="m-2"
              id="pieceNr"
              type="number"
              value={this.state.newCalculationPart!.pieceNr}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate('CALCULATION_DETAILS_UNITS_HEADER', 'Unitati')}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
            <TextValidator
              disabled={
                newCalcPartId !== null
                  ? !R.isNil(this.state.workflowCalculation) &&
                    !R.isNil(
                      sectionId === 1
                        ? this.state.workflowCalculation.editCarParts
                        : sectionId === 2
                        ? this.state.workflowCalculation.editLabor
                        : sectionId === 3
                        ? this.state.workflowCalculation.editPaintingMaterials
                        : sectionId === 4
                        ? this.state.workflowCalculation.editPaintingLabor
                        : false
                    ) &&
                    (sectionId === 1
                      ? this.state.workflowCalculation.editCarParts
                      : sectionId === 2
                      ? this.state.workflowCalculation.editLabor
                      : sectionId === 3
                      ? this.state.workflowCalculation.editPaintingMaterials
                      : sectionId === 4
                      ? this.state.workflowCalculation.editPaintingLabor
                      : false)
                    ? false
                    : true
                  : false
              }
              fullWidth
              name="originalPrice"
              className="m-2"
              id="originalPrice"
              value={
                sectionId === 1
                  ? this.state.newCalculationPart.alternativePartQualityId === null ||
                    this.state.newCalculationPartId === null
                    ? this.state.newCalculationPart!.originalPrice
                    : this.state.newCalculationPart!.alternativePrice
                  : this.state.newCalculationPart!.originalPrice
              }
              onChange={(e: any) =>
                this.handlePriceChange(
                  e,
                  this.state.newCalculationPart!.alternativePartQualityId === null ||
                    this.state.newCalculationPartId === null
                    ? 'originalPrice'
                    : 'alternativePrice'
                )
              }
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_UNIT_PRICE_HEADER',
                'Pret Unitar'
              )}
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />

            <TextValidator
              disabled={
                newCalcPartId !== null
                  ? !R.isNil(this.state.workflowCalculation) &&
                    !R.isNil(
                      sectionId === 1
                        ? this.state.workflowCalculation.editCarParts
                        : sectionId === 2
                        ? this.state.workflowCalculation.editLabor
                        : sectionId === 3
                        ? this.state.workflowCalculation.editPaintingMaterials
                        : sectionId === 4
                        ? this.state.workflowCalculation.editPaintingLabor
                        : false
                    ) &&
                    (sectionId === 1
                      ? this.state.workflowCalculation.editCarParts
                      : sectionId === 2
                      ? this.state.workflowCalculation.editLabor
                      : sectionId === 3
                      ? this.state.workflowCalculation.editPaintingMaterials
                      : sectionId === 4
                      ? this.state.workflowCalculation.editPaintingLabor
                      : false)
                    ? false
                    : true
                  : false
              }
              fullWidth
              name="discount"
              className="m-2"
              id="discount"
              value={
                sectionId === 1
                  ? this.state.newCalculationPart.alternativePartQualityId === null ||
                    this.state.newCalculationPartId === null
                    ? this.state.newCalculationPart!.discount
                    : this.state.newCalculationPart!.alternativePartDiscount
                  : this.state.newCalculationPart!.discount
              }
              onChange={(e: any) =>
                this.handleDiscountChange(
                  e,
                  this.state.newCalculationPart!.alternativePartQualityId === null ||
                    this.state.newCalculationPartId === null
                    ? 'discount'
                    : 'alternativePartDiscount'
                )
              }
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_DISCOUNT_HEADER',
                'Discount (%)'
              )}
              validators={['minNumber:0', 'maxNumber:100']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'WORKFLOW_SUPPLIERS_TAB_DISCOUNT_VALIDATORS_NUMBER_BETWENN',
                  'Valoarea discount-ului trebuie sa fie cuprinsa intre 0 si 100'
                ),
                this.translatorService.Tranlate(
                  'WORKFLOW_SUPPLIERS_TAB_DISCOUNT_VALIDATORS_NUMBER_BETWENN',
                  'Valoarea discount-ului trebuie sa fie cuprinsa intre 0 si 100'
                )
              ]}
              InputProps={{
                inputComponent: this.NumberFormatCustomDiscount
              }}
            />

            <TextValidator
              disabled={
                newCalcPartId !== null
                  ? !R.isNil(this.state.workflowCalculation) &&
                    !R.isNil(
                      sectionId === 1
                        ? this.state.workflowCalculation.editCarParts
                        : sectionId === 2
                        ? this.state.workflowCalculation.editLabor
                        : sectionId === 3
                        ? this.state.workflowCalculation.editPaintingMaterials
                        : sectionId === 4
                        ? this.state.workflowCalculation.editPaintingLabor
                        : false
                    ) &&
                    (sectionId === 1
                      ? this.state.workflowCalculation.editCarParts
                      : sectionId === 2
                      ? this.state.workflowCalculation.editLabor
                      : sectionId === 3
                      ? this.state.workflowCalculation.editPaintingMaterials
                      : sectionId === 4
                      ? this.state.workflowCalculation.editPaintingLabor
                      : false)
                    ? false
                    : true
                  : false
              }
              fullWidth
              name="remarks"
              className="m-2"
              id="remarks"
              value={this.state.newCalculationPart!.remarks || ''}
              onChange={this.handleTextChange}
              label={this.translatorService.Tranlate(
                'CALCULATION_DETAILS_REMARKS_HEADER',
                'Observatii'
              )}
            />
          </Grid>
          <Grid item xs={6}>
            {this.state.currentSection!.id === 1 ? (
              <div>
                <Autocomplete
                  disabled={
                    newCalcPartId !== null
                      ? !R.isNil(this.state.workflowCalculation) &&
                        !R.isNil(
                          sectionId === 1
                            ? this.state.workflowCalculation.editCarParts
                            : sectionId === 2
                            ? this.state.workflowCalculation.editLabor
                            : sectionId === 3
                            ? this.state.workflowCalculation.editPaintingMaterials
                            : sectionId === 4
                            ? this.state.workflowCalculation.editPaintingLabor
                            : false
                        ) &&
                        (sectionId === 1
                          ? this.state.workflowCalculation.editCarParts
                          : sectionId === 2
                          ? this.state.workflowCalculation.editLabor
                          : sectionId === 3
                          ? this.state.workflowCalculation.editPaintingMaterials
                          : sectionId === 4
                          ? this.state.workflowCalculation.editPaintingLabor
                          : false)
                        ? false
                        : true
                      : false
                  }
                  id="partQualityTypes"
                  className="m-2"
                  options={this.state.partQualityTypes.sort(function (a, b) {
                    return a.displayName.localeCompare(b.displayName);
                  })}
                  value={this.setAlternativePartQuality(
                    sectionId,
                    this.state.newCalculationPart!.carPartCategory,
                    this.state.newCalculationPart!.alternativePartQualityId,
                    this.state.newCalculationPart!.alternativePartQuality,
                    this.state.newCalculationPartId
                  )}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleAutocompletePartQualityChange(
                      newValue,
                      this.state.newCalculationPart!.alternativePartQualityId === null ||
                        this.state.newCalculationPartId === null
                        ? 'carPartCategory'
                        : 'alternativePartQuality'
                    )
                  }
                  getOptionLabel={(option: IReferential) => option.displayName}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      disabled={
                        newCalcPartId !== null
                          ? !R.isNil(this.state.workflowCalculation) &&
                            !R.isNil(
                              sectionId === 1
                                ? this.state.workflowCalculation.editCarParts
                                : sectionId === 2
                                ? this.state.workflowCalculation.editLabor
                                : sectionId === 3
                                ? this.state.workflowCalculation.editPaintingMaterials
                                : sectionId === 4
                                ? this.state.workflowCalculation.editPaintingLabor
                                : false
                            ) &&
                            (sectionId === 1
                              ? this.state.workflowCalculation.editCarParts
                              : sectionId === 2
                              ? this.state.workflowCalculation.editLabor
                              : sectionId === 3
                              ? this.state.workflowCalculation.editPaintingMaterials
                              : sectionId === 4
                              ? this.state.workflowCalculation.editPaintingLabor
                              : false)
                            ? false
                            : true
                          : false
                      }
                      name="partQualityTypes"
                      value={this.setAlternativePartQuality(
                        sectionId,
                        this.state.newCalculationPart!.carPartCategory,
                        this.state.newCalculationPart!.alternativePartQualityId,
                        this.state.newCalculationPart!.alternativePartQuality,
                        this.state.newCalculationPartId
                      )}
                      label={this.translatorService.Tranlate(
                        'CALCULATION_DETAILS_QUALITY_HEADER',
                        'Calitate'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
                <Autocomplete
                  disabled={
                    newCalcPartId !== null
                      ? !R.isNil(this.state.workflowCalculation) &&
                        !R.isNil(
                          sectionId === 1
                            ? this.state.workflowCalculation.editCarParts
                            : sectionId === 2
                            ? this.state.workflowCalculation.editLabor
                            : sectionId === 3
                            ? this.state.workflowCalculation.editPaintingMaterials
                            : sectionId === 4
                            ? this.state.workflowCalculation.editPaintingLabor
                            : false
                        ) &&
                        (sectionId === 1
                          ? this.state.workflowCalculation.editCarParts
                          : sectionId === 2
                          ? this.state.workflowCalculation.editLabor
                          : sectionId === 3
                          ? this.state.workflowCalculation.editPaintingMaterials
                          : sectionId === 4
                          ? this.state.workflowCalculation.editPaintingLabor
                          : false)
                        ? false
                        : true
                      : false
                  }
                  id="supplierList"
                  className="m-2"
                  options={this.state.supplierList.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                  })}
                  value={this.state.newCalculationPart!.supplier}
                  onChange={(e: any, newValue: Organization | null) =>
                    this.handleAutocompleteSupplierChange(newValue)
                  }
                  getOptionLabel={(option: Organization) => option.name}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      disabled={
                        newCalcPartId !== null
                          ? !R.isNil(this.state.workflowCalculation) &&
                            !R.isNil(
                              sectionId === 1
                                ? this.state.workflowCalculation.editCarParts
                                : sectionId === 2
                                ? this.state.workflowCalculation.editLabor
                                : sectionId === 3
                                ? this.state.workflowCalculation.editPaintingMaterials
                                : sectionId === 4
                                ? this.state.workflowCalculation.editPaintingLabor
                                : false
                            ) &&
                            (sectionId === 1
                              ? this.state.workflowCalculation.editCarParts
                              : sectionId === 2
                              ? this.state.workflowCalculation.editLabor
                              : sectionId === 3
                              ? this.state.workflowCalculation.editPaintingMaterials
                              : sectionId === 4
                              ? this.state.workflowCalculation.editPaintingLabor
                              : false)
                            ? false
                            : true
                          : false
                      }
                      name="supplierList"
                      value={this.state.newCalculationPart!.supplier}
                      label={this.translatorService.Tranlate(
                        'CALCULATION_DETAILS_SUPPLIER_HEADER',
                        'Furnizor'
                      )}
                      fullWidth
                    />
                  )}
                />
                <TextValidator
                  disabled={
                    newCalcPartId !== null
                      ? !R.isNil(this.state.workflowCalculation) &&
                        !R.isNil(
                          sectionId === 1
                            ? this.state.workflowCalculation.editCarParts
                            : sectionId === 2
                            ? this.state.workflowCalculation.editLabor
                            : sectionId === 3
                            ? this.state.workflowCalculation.editPaintingMaterials
                            : sectionId === 4
                            ? this.state.workflowCalculation.editPaintingLabor
                            : false
                        ) &&
                        (sectionId === 1
                          ? this.state.workflowCalculation.editCarParts
                          : sectionId === 2
                          ? this.state.workflowCalculation.editLabor
                          : sectionId === 3
                          ? this.state.workflowCalculation.editPaintingMaterials
                          : sectionId === 4
                          ? this.state.workflowCalculation.editPaintingLabor
                          : false)
                        ? false
                        : true
                      : false
                  }
                  fullWidth
                  name="deliveryTime"
                  className="m-2"
                  id="deliveryTime"
                  value={this.state.newCalculationPart!.deliveryTime || ''}
                  onChange={this.handleTextChange}
                  label={this.translatorService.Tranlate(
                    'CALCULATION_DETAILS_DELIVERY_TIME_HEADER',
                    'Timp livrare'
                  )}
                />
                <TextValidator
                  disabled={
                    newCalcPartId !== null
                      ? !R.isNil(this.state.workflowCalculation) &&
                        !R.isNil(
                          sectionId === 1
                            ? this.state.workflowCalculation.editCarParts
                            : sectionId === 2
                            ? this.state.workflowCalculation.editLabor
                            : sectionId === 3
                            ? this.state.workflowCalculation.editPaintingMaterials
                            : sectionId === 4
                            ? this.state.workflowCalculation.editPaintingLabor
                            : false
                        ) &&
                        (sectionId === 1
                          ? this.state.workflowCalculation.editCarParts
                          : sectionId === 2
                          ? this.state.workflowCalculation.editLabor
                          : sectionId === 3
                          ? this.state.workflowCalculation.editPaintingMaterials
                          : sectionId === 4
                          ? this.state.workflowCalculation.editPaintingLabor
                          : false)
                        ? false
                        : true
                      : false
                  }
                  fullWidth
                  name="producer"
                  className="m-2"
                  id="producer"
                  value={this.state.newCalculationPart!.producer || ''}
                  onChange={this.handleTextChange}
                  label={this.translatorService.Tranlate(
                    'CALCULATION_DETAILS_PRODUCER_HEADER',
                    'Producator'
                  )}
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
        {newCalcPartId !== null ? (
          !R.isNil(this.state.workflowCalculation) &&
          !R.isNil(
            sectionId === 1
              ? this.state.workflowCalculation.editCarParts
              : sectionId === 2
              ? this.state.workflowCalculation.editLabor
              : sectionId === 3
              ? this.state.workflowCalculation.editPaintingMaterials
              : sectionId === 4
              ? this.state.workflowCalculation.editPaintingLabor
              : false
          ) &&
          (sectionId === 1
            ? this.state.workflowCalculation.editCarParts
            : sectionId === 2
            ? this.state.workflowCalculation.editLabor
            : sectionId === 3
            ? this.state.workflowCalculation.editPaintingMaterials
            : sectionId === 4
            ? this.state.workflowCalculation.editPaintingLabor
            : false) ? (
            <div className="text-right">
              <Button className="m-2" variant="contained" color="primary" type="submit">
                {this.translatorService.Tranlate('SAVE', 'Salveaza')}
              </Button>
            </div>
          ) : null
        ) : (
          <div className="text-right">
            <Button className="m-2" variant="contained" color="primary" type="submit">
              {this.translatorService.Tranlate('SAVE', 'Salveaza')}
            </Button>
          </div>
        )}
      </ValidatorForm>
    );
  }

  handleImportOriginalParts = (event: any) => {
    event.preventDefault();

    const files = event.target.files,
      f = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target!.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      const fullParsedContent = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (fullParsedContent.length === 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      const header = fullParsedContent[0] as any;
      if (
        fullParsedContent.length > 0 &&
        (header[0] !== ImportCalculationOriginalPartsFileHeader.H1 ||
          header[1] !== ImportCalculationOriginalPartsFileHeader.H2 ||
          header[2] !== ImportCalculationOriginalPartsFileHeader.H3 ||
          header[3] !== ImportCalculationOriginalPartsFileHeader.H4 ||
          header[4] !== ImportCalculationOriginalPartsFileHeader.H5 ||
          (header[5] !== undefined && header[5] !== ImportCalculationOriginalPartsFileHeader.H6) ||
          (header[6] !== undefined && header[6] !== ImportCalculationOriginalPartsFileHeader.H7) ||
          (header[7] !== undefined && header[7] !== ImportCalculationOriginalPartsFileHeader.H8) ||
          (header[8] !== undefined && header[8] !== ImportCalculationOriginalPartsFileHeader.H9) ||
          (header[9] !== undefined && header[9] !== ImportCalculationOriginalPartsFileHeader.H10))
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }

      /* Convert array to json*/
      const dataParse = XLSX.utils
        .sheet_to_json(ws, {
          header: [
            'guideNumberImported',
            'nameImported',
            'originalPriceImported',
            'qualityImported',
            'unitsImported',
            'discountImported',
            'supplierImported',
            'deliveryTimeImported',
            'producerImported',
            'remarksImported'
          ],
          defval: ''
        })
        .slice(1);

      if (dataParse.length <= 0) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      const partQualityTypes = this.state.partQualityTypes.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName);
      });
      const supplierList = this.state.supplierList;
      const dataParseArr = dataParse as any[];
      let emptyRows = false as boolean;

      dataParseArr.map((item) => {
        if (
          item.guideNumberImported.toString() === '' ||
          !item.guideNumberImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (item.nameImported.toString() === '' || !item.nameImported.toString().trim().length) {
          emptyRows = true;
        }
        if (
          item.originalPriceImported.toString() === '' ||
          !item.originalPriceImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (
          item.qualityImported.toString() === '' ||
          !item.qualityImported.toString().trim().length
        ) {
          emptyRows = true;
        }
        if (item.unitsImported.toString() === '' || !item.unitsImported.toString().trim().length) {
          emptyRows = true;
        }
        const partQualityTypesFilter = partQualityTypes.filter(
          (qualityType) => qualityType.code === item.qualityImported.toString()
        );
        if (partQualityTypesFilter.length <= 0) {
          emptyRows = true;
        }

        const supplierListFilter = supplierList.filter(
          (supplier) => supplier.companyNumber === item.supplierImported.toString()
        );
        if (item.supplierImported.toString() !== '' && supplierListFilter.length <= 0) {
          emptyRows = true;
        }
      });
      if (emptyRows === true) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_IMPORT_PARTS_BY_XLSX_FILE',
            'Importul nu s-a putut efectua! Verificati fisierul!'
          ),
          { variant: 'error' }
        );
        return;
      }
      this.importOriginalParts(dataParse);
    };
    reader.readAsBinaryString(f);
    event.target.value = '';
  };

  importOriginalParts = (importedOriginalParts: any) => {
    try {
      const partQualityTypes = this.state.partQualityTypes.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName);
      });
      const calculationSection = this.state.calculationSectionList.find((item) => item.id === 1);
      const calculationParts = this.state.calculationParts.filter(
        (item) => item.calculationSectionId === 1
      );
      const supplierList = this.state.supplierList;

      [...importedOriginalParts].map((item, index) => {
        const partQualityTypesFilter = partQualityTypes.filter(
          (qualityType) => qualityType.code === item.qualityImported.toString()
        );
        const supplierListFilter = supplierList.filter(
          (supplier) =>
            supplier.companyNumber !== '' &&
            supplier.companyNumber === item.supplierImported.toString()
        );
        calculationParts.push({
          amount: 0,
          calculationSection: calculationSection,
          calculationSectionId: 1,
          carPartCategory: partQualityTypesFilter.length > 0 ? partQualityTypesFilter[0] : null,
          carPartCategoryId:
            partQualityTypesFilter.length > 0 ? partQualityTypesFilter[0].id : null,
          caseCalculationHistoryId: this.props.calculationHistoryId,
          deliveryTime: item.deliveryTimeImported,
          guideNumber: item.guideNumberImported,
          id: -1 * this.state.calculationParts.length - index,
          name: item.nameImported,
          originalPrice: item.originalPriceImported,
          pieceNr: item.unitsImported,
          discount:
            !R.isNil(item.discountImported) && item.discountImported !== ''
              ? item.discountImported
              : 0,
          producer: item.producerImported,
          remarks: item.remarksImported,
          supplierId: supplierListFilter.length > 0 ? supplierListFilter[0].id : null,
          supplier: supplierListFilter.length > 0 ? supplierListFilter[0] : null,
          alternativePartQualityId: null,
          alternativePartQuality: null
        } as CaseCalculationPart);
      });

      this.setState({
        calculationParts: calculationParts
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public renderTable = (sectionId: number) => {
    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      rowHover: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      elevation: 1,
      customToolbar: () => {
        return (
          <div>
            {sectionId === 1 &&
            !R.isNil(this.state.workflowCalculation) &&
            !R.isNil(this.state.workflowCalculation.importParts) &&
            this.state.workflowCalculation.importParts ? (
              <Tooltip title={this.translatorService.Tranlate('IMPORT', 'Importa')}>
                <Button
                  onChange={(e: any) => {
                    this.handleImportOriginalParts(e);
                  }}
                  className="ml-2"
                  component="label"
                  color="primary"
                >
                  <input type="file" style={{ display: 'none' }} />
                  <CloudDownloadTwoToneIcon />
                </Button>
              </Tooltip>
            ) : null}
            {!R.isNil(this.state.workflowCalculation) &&
            !R.isNil(
              sectionId === 1
                ? this.state.workflowCalculation.addCarParts
                : sectionId === 2
                ? this.state.workflowCalculation.addLabor
                : sectionId === 3
                ? this.state.workflowCalculation.addPaintingMaterials
                : sectionId === 4
                ? this.state.workflowCalculation.addPaintingLabor
                : false
            ) &&
            (sectionId === 1
              ? this.state.workflowCalculation.addCarParts
              : sectionId === 2
              ? this.state.workflowCalculation.addLabor
              : sectionId === 3
              ? this.state.workflowCalculation.addPaintingMaterials
              : sectionId === 4
              ? this.state.workflowCalculation.addPaintingLabor
              : false) ? (
              <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
                <IconButton
                  aria-label="add"
                  color="primary"
                  className="m-2"
                  onClick={(e) => this.newCalculationPart(null, sectionId)}
                >
                  <AddCircleTwoToneIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            <Dialog
              onClose={this.closeDialog}
              aria-labelledby="customized-dialog-title"
              open={this.state.isDialogOpen}
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle id="customized-dialog-title">
                <Grid container>
                  <Grid item xs={11}>
                    <Typography variant="h4">
                      {(this.state.newCalculationPartId === null
                        ? this.translatorService.Tranlate(
                            'CALCULATION_DETAILS_NEW_MODAL_TITLE',
                            'Adauga '
                          )
                        : this.translatorService.Tranlate(
                            'CALCULATION_DETAILS_UPDATE_MODAL_TITLE',
                            'Actualizeaza '
                          )) +
                        (R.isNil(this.state.currentSection)
                          ? ' '
                          : this.state.currentSection!.displayName)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent dividers>
                {this.renderAddForm(
                  R.isNil(this.state.currentSection) ? ' ' : this.state.currentSection!.id,
                  this.state.newCalculationPartId
                )}
              </DialogContent>
            </Dialog>
          </div>
        );
      }
    };
    return (
      <div key={sectionId} className="mt-3">
        {sectionId === 1 && this.viewPrcNewColumns() ? (
          <Card className="mb-4">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_TOTAL_PARTS_OE_TITLE',
                          'Total piese OE'
                        ) + ' (' + this.state.caseCalculationHistoryDetails.currency!.displayName + ' ' + this.translatorService.Tranlate('WITHOUT_VAT_VALUE', 'fara TVA') + ')'}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="font-size-sm text-black">
                        {this.calculationTotalPartsOEPrice().toFixed(2)}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_TOTAL_PARTS_AM_TITLE',
                          'Total piese AM'
                        ) + ' (' + this.state.caseCalculationHistoryDetails.currency!.displayName +  ' ' + this.translatorService.Tranlate('WITHOUT_VAT_VALUE', 'fara TVA') + ')'}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="font-size-sm text-black">
                        {this.calculationTotalPartsAMPrice().toFixed(2)}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_TOTAL_PARTS_ECONOMY_TITLE',
                          'Economisire piese (%)'
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="font-size-sm text-black">
                      {this.displayCalculationTotalPartsEconomy()}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : ''}
        <MUIDataTable
          columns={this.getColumns(sectionId)}
          data={this.state.calculationParts.filter(
            (item) => item.calculationSectionId === sectionId
          )}
          title={this.state.calculationSectionList
            .find((item) => item.id === sectionId)!
            .displayName.toUpperCase()}
          options={options}
        />
      </div>
    );
  };

  getPerson = () => {
    if (R.isNil(this.props.caseSettingsState.case!.caseEvent)) {
      return 'N/A';
    }
    if (R.isNil(this.props.caseSettingsState.case!.caseEvent.insuredPersonType)) {
      return 'N/A';
    }

    if (
      this.props.caseSettingsState.case!.caseEvent.insuredPersonType!.code === InsuredTypeCode.PF
    ) {
      return this.props.caseSettingsState.case!.caseEvent.insuredName;
    }

    return this.props.caseSettingsState.case!.caseEvent.companyName;
  };
  
  handleGeneralInsurerAdditionChange = (value: number | null) => {
    const updatedCalculationParts = this.state.calculationParts.map((part) => {
      if (part.calculationSection!.code === CalculationSectionCodeEnum.PART && !this.partDisabled(part)) {
        return { ...part, 
          additionInsurer: value
        };
      }
      return part;
    });

    
    this.setState({
      caseCalculationHistoryDetails: {
        ...this.state.caseCalculationHistoryDetails!,
        additionInsurer: value
      },
      calculationParts: _.cloneDeep(updatedCalculationParts)
    }, 
    () => {
      this.updateAmount();
    });
  };


  renderCalculationGeneralData = () => {
    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h5>
                    {this.state.caseCalculationHistoryDetails.calculationType.displayName.toUpperCase() +
                      ' ' +
                      this.props.caseSettingsState.case!.id}
                  </h5>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={
                      R.isNil(this.state.caseCalculationHistoryDetails.offerNumber)
                        ? ''
                        : this.state.caseCalculationHistoryDetails.offerNumber
                    }
                    onChange={(e) => this.handleOfferNumberChange(e)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="font-size-sm text-black">
                    {this.translatorService.Tranlate(
                      'CALCULATION_DETAILS_CALCULATION_DATE',
                      'Data calculatie'
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.translatorService.Tranlate(
                        'CALCULATION_DETAILS_CALCULATION_VIN_INDENTIFICATION',
                        'Identificare Vin'
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.translatorService.Tranlate('CALCULATION_DETAILS_MADE_BY', 'Efectuat de')}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.translatorService.Tranlate(
                      'CALCULATION_DETAILS_INSURED_NAME',
                      'Proprietar/Utilizator'
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.translatorService.Tranlate(
                      'CASE_EVENT_COST_CENTER',
                      'Centru de cost'
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.translatorService.Tranlate(
                      'CALCULATION_DETAILS_PACKAGE_TYPE',
                      'Tip Pachet'
                    )}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="font-size-sm text-black">
                    {moment
                      .utc(this.state.caseCalculationHistoryDetails.date)
                      .local()
                      .format(this.props.appState.longDateFormat)}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.state.caseCalculationHistoryDetails.vinQuery ? 'GT (VQ)' : 'Local (VD)' }
                  </div>
                  <div className="font-size-sm text-black">
                    {this.state.caseCalculationHistoryDetails.user!.userName}
                  </div>
                  <div className="font-size-sm text-black">{this.getPerson()}</div>
                  <div className="font-size-sm text-black">
                      {!isNullOrUndefined(this.state.costCenter) ? this.state.costCenter.name : 'N/A'}
                  </div>
                  <div className="font-size-sm text-black">
                      {!isNullOrUndefined(this.state.maintenancePackage) ? this.state.maintenancePackage.displayName : 'N/A'}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <h6>
                {VehicleHelper.GetBrandModel(
                  this.props.caseSettingsState.case,
                  this.props.vehicleState.vehicle
                )}
              </h6>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate('CALCULATION_DETAILS_UMC', 'UMC')}
                      </div>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'VIN')}
                      </div>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_VEHICLE_MANUFACTURE_DATE',
                          'Data fabricatie'
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                          {this.translatorService.Tranlate(
                          'CASE_EVENT_LOCATION_LABEL',
                          'Adresa de livrare'
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate(
                          'CASE_EVENT_INSURER_NAME_LABEL',
                          'Nume Asigurator'
                          )
                        }
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="font-size-sm text-black">
                        {VehicleHelper.GetUMC(
                          this.props.caseSettingsState.case,
                          this.props.vehicleState.vehicle
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                        {VehicleHelper.GetVIN(
                          this.props.caseSettingsState.case,
                          this.props.vehicleState.vehicle
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                        {VehicleHelper.GetMakeDate(
                          this.props.caseSettingsState.case,
                          this.props.vehicleState.vehicle,
                          this.props.appState.language
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                      {!isNullOrUndefined(this.state.deliveryAdress)
                          ? 
                            this.state.deliveryAdress.name +
                            '; ' +
                            (!isNullOrUndefined(this.state.deliveryAdress.address)
                              ? 'strada: ' + this.state.deliveryAdress.address
                              : '')
                          : 'N/A'}
                      </div>
                      <div className="font-size-sm text-black">
                        {!R.isNil(this.props.caseSettingsState.case!.caseEvent) ? this.props.caseSettingsState.case!.caseEvent.insurerName : 'N/A'}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_VEHICAL_PLATE_NUMBER',
                          'Numar inmatriculare'
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_VEHICAL_FIRST_REGISTRATION_DATE',
                          'Data primei inmatriculari'
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                        {this.translatorService.Tranlate(
                          'CALCULATION_DETAILS_VEHICAL_NUMBER_OF_KM',
                          'Numar kilometri'
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <div className="font-size-sm text-black">
                        {VehicleHelper.GetPlateNumber(
                          this.props.caseSettingsState.case,
                          this.props.vehicleState.vehicle
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                        {VehicleHelper.GetFirstRegistrationDate(
                          this.props.caseSettingsState.case,
                          this.props.vehicleState.vehicle,
                          this.props.appState.language
                        )}
                      </div>
                      <div className="font-size-sm text-black">
                        {VehicleHelper.GetKmNr(
                          this.props.caseSettingsState.case,
                          this.props.vehicleState.vehicle
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                    {this.viewPrcNewColumns() ? (
                      <>
                        <div className="font-size-sm text-black">
                          <FormControlLabel
                            className="d-flex justify-content-between w-100 m-0 "
                            control={
                              <Input
                                className="ml-1"
                                type="text"
                                id="additionInsurer"
                                value={this.state.caseCalculationHistoryDetails.additionInsurer  !== null ? this.state.caseCalculationHistoryDetails.additionInsurer : ''}
                                onChange={(e: any) => {
                                  const inputValue = e.target.value.trim();
                                  const numericRegex = /^[0-9]+$/;
                              
                                  if (numericRegex.test(inputValue) || inputValue === '0' || inputValue === '') {
                                    const numericValue = inputValue === '' ? null : parseFloat(inputValue);
                                    this.handleGeneralInsurerAdditionChange(numericValue);
                                  }
                                }}
                                inputMode="numeric"
                                disabled={!this.props.hasRights ? true : false}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                className="text-black"
                                align="left"
                              >
                                {this.translatorService.Tranlate(
                                  'CALCULATION_DETAILS_INSURER_ADDITION_HEADER',
                                  'Adaos Asigurator (%)'
                                )}
                              </Typography>
                            }
                            labelPlacement="start"
                          /> 
                        </div> 
                      </>) : ''}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  renderCalculationPart = () => {
    return this.state.calculationSectionList.map((item) => this.renderTable(item.id));
  };

  handleRemarksChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      caseCalculationHistoryDetails: {
        ...this.state.caseCalculationHistoryDetails,
        comment: e.target.value
      }
    });
  };

  handleOfferNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      caseCalculationHistoryDetails: {
        ...this.state.caseCalculationHistoryDetails,
        offerNumber: e.target.value
      }
    });
  };

  handleSolutionCommentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    part: CaseCalculationPart
  ) => {
    const newArray = [...this.state.calculationParts];
    const index = newArray.findIndex((item) => item.id === part.id);
    newArray[index] = {
      ...newArray[index],
      solutionComment: e.target.value
    };
    this.setState({
      calculationParts: newArray
    });
  };

  handleSolutionValueChange = (part: CaseCalculationPart, newVal: IReferential | null) => {
    const newArray = [...this.state.calculationParts];
    const index = newArray.findIndex((item) => item.id === part.id);
    newArray[index] = {
      ...newArray[index],
      solutionValue: newVal,
      solutionValueId: newVal !== null ? newVal.id : null
    };
    this.setState({
      calculationParts: newArray
    });
  };

  displayValueWithCurrency = (value: number | null) => {
    let result = '0';
    if (!R.isNil(value)) {
      result = value.toFixed(2);
    }

    return Utils.countryNumberFormat(result, this.props.appState.appUser?.organization?.country ?? null) + ' ' + this.state.caseCalculationHistoryDetails.currency!.displayName;
  };

  renderCalculationTotal = () => {
    return (
      <Card className="mt-3">
        <CardContent>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <h6 className="text-white">u</h6>
              <div className="font-size-sm text-black mr-1">
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_TOTAL_SMALL_PIECES',
                  'Piese mici'
                )}
              </div>
              <div className="font-size-sm text-black mr-1">
                {this.translatorService.Tranlate(
                  'CALCULATION_DETAILS_TOTAL_WITHOUT_DISCOUNT',
                  'Total fara discount'
                )}
              </div>
              <div className="font-size-sm text-black">
                {this.translatorService.Tranlate('CALCULATION_DETAILS_TOTAL_DISCOUNT', 'Discount')}
              </div>
              {this.viewPrcNewColumns() ? (
                <div className="font-size-sm text-black">
                  {this.translatorService.Tranlate(
                    'CALCULATION_DETAILS_TOTAL_ADAOS',
                    'Adaos Asigurator'
                  )}
                </div>
              ) : ''}
              <div className="font-size-sm text-black">
                {this.translatorService.Tranlate('CALCULATION_DETAILS_TOTAL_SECTION', 'TOTAL')}
              </div>
            </div>
            {this.state.calculationSectionList.map((item) => {
              return item.code === CalculationSectionCodeEnum.PART ? (
                <div className="d-flex flex-column" key={item.id}>
                  <h6>{item.displayName}</h6>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.smallPartsValue
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountPieces
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountPiecesDiscount
                    )}
                  </div>
                  {this.viewPrcNewColumns() ? ( 
                    <div className="font-size-sm text-black">
                      {this.displayValueWithCurrency(
                        this.state.caseCalculationHistoryDetails.additionInsurerParts
                      )}
                    </div>                   
                  ) : ''}
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountPiecesWithDiscount
                    )}
                  </div>
                </div>
              ) : item.code === CalculationSectionCodeEnum.LABOR ? (
                <div className="d-flex flex-column" key={item.id}>
                  <h6>{item.displayName}</h6>
                  <div className="font-size-sm text-black">-</div>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountLabor
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      (this.state.caseCalculationHistoryDetails.amountLabor || 0) -
                        (this.state.caseCalculationHistoryDetails.amountLaborWithDiscount || 0)
                    )}
                  </div>
                  {this.viewPrcNewColumns() ? ( 
                    <div className="font-size-sm text-black">-</div>
                  ) : ''}
                  <div className="font-size-sm text-black">
                    {R.isNil(this.state.caseCalculationHistoryDetails.amountLaborWithDiscount)
                      ? 0
                      : Utils.countryNumberFormat(this.state.caseCalculationHistoryDetails.amountLaborWithDiscount.toFixed(
                          2
                        ), this.props.appState.appUser?.organization?.country ?? null) +
                        ' ' +
                        this.state.caseCalculationHistoryDetails.currency!.displayName}
                  </div>
                </div>
              ) : item.code === CalculationSectionCodeEnum.PAINT ? (
                <div className="d-flex flex-column" key={item.id}>
                  <h6>{item.displayName}</h6>
                  <div className="font-size-sm text-black">-</div>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountPaintingMaterials
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      (this.state.caseCalculationHistoryDetails.amountPaintingMaterials || 0) -
                        (this.state.caseCalculationHistoryDetails
                          .amountPaintingMaterialsWithDiscount || 0)
                    )}
                  </div>
                  {this.viewPrcNewColumns() ? ( 
                    <div className="font-size-sm text-black">-</div>
                  ) : ''}
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountPaintingMaterialsWithDiscount
                    )}
                  </div>
                </div>
              ) : item.code === CalculationSectionCodeEnum.PAINT_LABOR ? (
                <div className="d-flex flex-column" key={item.id}>
                  <h6>{item.displayName}</h6>
                  <div className="font-size-sm text-black">-</div>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountPaintingLabor
                    )}
                  </div>
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      (this.state.caseCalculationHistoryDetails.amountPaintingLabor || 0) -
                        (this.state.caseCalculationHistoryDetails.amountPaintingLaborWithDiscount ||
                          0)
                    )}
                  </div>
                  {this.viewPrcNewColumns() ? ( 
                    <div className="font-size-sm text-black">-</div>
                  ) : ''}
                  <div className="font-size-sm text-black">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountPaintingLaborWithDiscount
                    )}
                  </div>
                </div>
              ) : null;
            })}
            <div className="d-flex flex-column">
              <h6 className="font-weight-bold">
                {this.translatorService.Tranlate('CALCULATION_DETAILS_TOTAL_SECTION', 'TOTAL')}
              </h6>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <div className="font-size-sm text-black mr-2 font-weight-bold">
                    {this.translatorService.Tranlate(
                      'CALCULATION_DETAILS_ADDITION_AMOUNT',
                      'Adaos GTM'
                    )}
                  </div>
                  <div className="font-size-sm text-black mr-2 font-weight-bold">
                    {this.translatorService.Tranlate(
                      'CALCULATION_DETAILS_TOTAL_WITHOUT_VAT',
                      'Total fara TVA'
                    )}
                  </div>
                  <div className="font-size-sm text-black font-weight-bold">
                    {this.state.caseCalculationHistoryDetails.amountTVA != 0
                      ? this.translatorService.Tranlate('CALCULATION_DETAILS_TOTAL_VAT', 'TVA') +
                        ' (' +
                        this.state.TVA +
                        '%)'
                      : this.translatorService.Tranlate('CALCULATION_DETAILS_TOTAL_VAT', 'TVA') +
                        ' (0 %)'}
                  </div>
                  <div className="font-size-sm text-black font-weight-bold">
                    {this.translatorService.Tranlate('CALCULATION_DETAILS_TOTAL_SECTION', 'TOTAL')}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="font-size-sm text-black font-weight-bold">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountAddition
                    )}
                  </div>
                  <div className="font-size-sm text-black font-weight-bold">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountWithoutTVA
                    )}
                  </div>
                  <div className="font-size-sm text-black font-weight-bold">
                    {this.displayValueWithCurrency(
                      this.state.caseCalculationHistoryDetails.amountTVA
                    )}
                  </div>
                  <div className="font-size-sm text-black font-weight-bold">
                    {this.displayValueWithCurrency(this.state.caseCalculationHistoryDetails.amount)}
                  </div>
                </div>
              </div>
            </div>
            {this.state.caseCalculationHistoryDetails.calculationType.code !== CalculationTypeCode.ORG && (
              <div className="d-flex flex-column">
                <TextField
                  fullWidth
                  className="mb-2"
                  id="outlined-multiline-static"
                  label={this.translatorService.Tranlate(
                    'CALCULATION_DETAILS_OTHER_OBSERVATIONS',
                    'Alte observatii'
                  )}
                  multiline
                  rows="4"
                  value={
                    R.isNil(this.state.caseCalculationHistoryDetails.comment)
                      ? ''
                      : this.state.caseCalculationHistoryDetails.comment
                  }
                  onChange={(e) => this.handleRemarksChange(e)}
                  variant="outlined"
                />
              </div>
            )}
          </div>

          <div className="text-right">
            {this.state.caseCalculationHistoryDetails.calculationType.code !== CalculationTypeCode.ORG && (
              <Button className="m-2" variant="contained" color="primary" onClick={this.save}>
                {this.translatorService.Tranlate('SAVE', 'Salveaza')}
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    );
  };

  save = async () => {
    const parts = this.state.calculationParts.map((item) => item);

    parts.forEach((item) => {
      item.calculationSection = null;
      item.carPartCategory = null;
      item.alternativePartQuality = null;
      item.supplier = null;
    });
    try {
      await this.caseService.UpdateCaseCalculationParts(
        this.state.calculationParts,
        this.state.caseCalculationHistoryDetails.comment,
        this.state.caseCalculationHistoryDetails.offerNumber,
        this.props.calculationHistoryId,
        this.state.caseCalculationHistoryDetails.partsLocationOrganizationId,
        this.state.caseCalculationHistoryDetails.partsOrganizationId,
        this.state.caseCalculationHistoryDetails.organizationId,
        this.state.caseCalculationHistoryDetails.organizationName,
        true,
        this.state.caseCalculationHistoryDetails.manipulationPercent,
        this.state.caseCalculationHistoryDetails.additionInsurer,
        this.state.caseCalculationHistoryDetails.formId
      );

      this.props.handleModifiedPropChanges(false);
      this.props.onSave();
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appuserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div>
            {this.renderCalculationGeneralData()}
            {this.renderCalculationPart()}
            {this.renderCalculationTotal()}
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: ExternalCaseDetailsCaseTabCalculationDetailsProps) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle
  }),
  null
)(withSnackbar(CaseDetailsCaseTabCalculationDetails as any));
