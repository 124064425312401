import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  Card,
  Tabs,
  Tab,
  CardContent,
  Box,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent,
  Switch
} from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { Tariff } from '../../../interfaces/Tariff';
import { Organization } from '../../../interfaces/Organization';
import { ITariffService } from '../../../services/Interfaces/ITariffService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableColumnOptions,
  MUIDataTableMeta
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import { isNullOrUndefined } from 'util';
import moment from 'moment';
import EditTariff from './EditTariff';
import { RoleClaimsEnum } from '../../../helpers/Constants';

interface ITariffsState {
  selectedTab: number;
  tariffs: Tariff[];
  isLoading: boolean;
  isOpenDialog: boolean;
  organizationId: number;
  selectedTariffId: number;
}

type ITariffsProps = { appState: AppState } & ProviderContext & RouteComponentProps<{ id: string }>;

class Tariffs extends React.PureComponent<ITariffsProps, ITariffsState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private tariffService!: ITariffService;

  static contextType = ApplicationContext;

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  state = {
    selectedTab: this.hasRight(RoleClaimsEnum.AdminService) ? 1 : 0,
    tariffs: [],
    isLoading: false,
    isOpenDialog: false,
    organizationId: 0,
    selectedTariffId: 0
  } as ITariffsState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData(this.hasRight(RoleClaimsEnum.AdminService) ? 1 : 0);
      }
    );
  };

  loadData = async (selectedTab: number) => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (isNaN(hoId)) {
      return;
    }

    try {
      const data = await this.tariffService.GetTariffs(hoId);
      const newTariffs = data.filter(
        (tariff: Tariff) =>
          (selectedTab === 0 && tariff.ownerId === hoId) ||
          (selectedTab === 1 && tariff.ownerId !== hoId)
      );

      this.setState({
        tariffs: newTariffs,
        isLoading: false,
        organizationId: hoId
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  editTariff = (id: number) => {
    this.setState({ isOpenDialog: true, selectedTariffId: id });
  };

  onRowClick = (e: any, id: number) => {
    this.editTariff(id);
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newTariffs = [...this.state.tariffs];
    const updatedTariffIndex = newTariffs.findIndex((o) => o.id === id);

    if (updatedTariffIndex >= 0) {
      newTariffs[updatedTariffIndex] = {
        ...newTariffs[updatedTariffIndex],
        isActive: event.target.checked
      };

      try {
        await this.tariffService.UpdateTariff(newTariffs[updatedTariffIndex]);

        this.setState({ tariffs: newTariffs });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate('TARIFFS_TARIFF_NAME_HEADER', 'Tarif'),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className=" text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0])}
              >
                <span>{value}</span>
              </Button>
            );
          }
        }
      },
      {
        name: 'owner',
        label: this.translatorService.Tranlate('TARIFFS_TARIFF_OWNER_HEADER', 'Proprietar Tarif'),
        options: {
          sort: false,
          customBodyRender: (value: Organization) => {
            return isNullOrUndefined(value) ? '' : value.name;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'partner',
        label: this.translatorService.Tranlate('TARIFFS_TARIFF_PARTNER_HEADER', 'Partener Tarif'),
        options: {
          sort: false,
          customBodyRender: (value: Organization) => {
            return isNullOrUndefined(value) ? '' : value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'TARIFFS_TARIFF_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment(value).format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      }
    ];

    return columns;
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        isLoading: true
      },
      () => {
        this.loadData(newValue);
      }
    );
  };

  handleClose = (reload: boolean) => {
    if (reload) {
      this.setState({ isOpenDialog: false }, async () => {
        await this.loadData(this.state.selectedTab);
      });
    } else {
      this.setState({ isOpenDialog: false });
    }
  };


  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.tariffService = (this.context as AppContext).tariffService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      // responsive: "stacked",
      customToolbar: () => {
        if (this.hasRight(RoleClaimsEnum.AdminService)) {
          return null;
        }
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={() => {
                this.setState({ selectedTariffId: 0, isOpenDialog: true });
              }}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="standard"
              onChange={this.onTabChange}
            >
              <Tab
                className="text-capitalize "
                label={this.translatorService.Tranlate('TARIFFS_TAB_OWN', 'Proprii')}
                hidden={this.hasRight(RoleClaimsEnum.AdminService)}
              />
              <Tab
                className="text-capitalize "
                label={this.translatorService.Tranlate('TARIFFS_TAB_PARTNERS', 'Parteneri')}
              />
            </Tabs>
          </CardContent>
        </Card>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.tariffs}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={this.state.isOpenDialog}
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate('TARIFFS_EDIT_MODAL_TITLE', 'Editare Tarif')}
                </Typography>
              </Grid>
              <Grid item xs={1} className="text-right">
                <IconButton
                  aria-label="close"
                  onClick={() => this.handleClose(false)}
                  size={'small'}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>

          <DialogContent dividers style={{ backgroundColor: '#fafafa' }}>
            <EditTariff
              {...this.props}
              tariffId={this.state.selectedTariffId}
              tariffChanged={() => this.handleClose(true)}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect((state: ApplicationState) => ({ appState: state.app }), {
  ...AppUserAdminActionCreators,
  ...AppActionCreators
})(withSnackbar(Tariffs as any));
