import { Action, Reducer } from 'redux';
import { CaseSettingsAction } from '../actions/CaseSettings';
import * as ActionTypes from '../actions/ActionTypes';
import { CaseSettings } from '../../interfaces/CaseSettings';
import { Case } from '../../interfaces/Case';

export interface CaseSettingsState {
  caseSettings: CaseSettings | null;
  isLoading: boolean;
  case: Case | null;
}

const initialState: CaseSettingsState = {
  caseSettings: null,
  isLoading: false,
  case: null
};

export const AppCaseSettingsReducer: Reducer<CaseSettingsState | undefined> = (
  state: CaseSettingsState | undefined,
  incomingAction: Action
): CaseSettingsState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as CaseSettingsAction;

  switch (action.type) {
    case ActionTypes.CASE_SETTINGS_SET_CASE:
      return {
        ...state,
        caseSettings: action.caseSettings,
        case: action.case,
        isLoading: false
      };
    case ActionTypes.CASE_SETTINGS_SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case ActionTypes.CASE_SETTINGS_SET_CASE_VEHICLE:
      return {
        ...state,
        case: {
          ...state.case!,
          caseVehicle: action.caseVehicle
        }
      };
    case ActionTypes.CASE_SETTINGS_SET_CASE_EVENT:
      return {
        ...state,
        case: {
          ...state.case!,
          caseEvent: action.caseEvent
        }
      };
    case ActionTypes.CASE_SETTINGS_SET_CASE_VALUATION:
      return {
        ...state,
        case: {
          ...state.case!,
          caseValuation: action.caseValuation
        }
      };
    case ActionTypes.CASE_SETTINGS_SET_CASE_STATUS:
      return {
        ...state,
        case: {
          ...state.case!,
          caseStatus: action.caseStatus
        }
      };

    case ActionTypes.CASE_SETTINGS_ADD_CASE_PARTNER:
      return {
        ...state,
        case: {
          ...state.case!,
          casePartners: [...state.case!.casePartners, action.partner]
        }
      };
    case ActionTypes.CASE_SETTINGS_SET_CLIENT_DATA:
      return {
        ...state,
        case: {
          ...state.case!,
          caseClient: { ...action.caseClientData }
        }
      };
    case ActionTypes.CASE_SETTINGS_SET_VEHICLE_DATA:
      return {
        ...state,
        case: {
          ...state.case!,
          vehicleId: action.vehicleId
        }
      };
    case ActionTypes.CASE_SETTINGS_UPDATE_PARAMETERS:
      const index = state.case!.caseParameters.findIndex(
        (parameter) => parameter.id === action.caseParameter.id
      );
      const newCaseParameters = [...state.case!.caseParameters];

      newCaseParameters[index] = { ...action.caseParameter };

      return {
        ...state,
        case: {
          ...state.case!,
          caseParameters: newCaseParameters
        }
      };
    case ActionTypes.CASE_SETTINGS_UPDATE_TARIFF_VALUE:
      const indexTariffValue = state.case!.caseTariff.caseTariffValuesHistory.findIndex(
        (parameter) => parameter.id === action.tariffValue.id
      );
      const newTariffValues = [...state.case!.caseTariff.caseTariffValuesHistory];

      newTariffValues[indexTariffValue] = { ...action.tariffValue };

      return {
        ...state,
        case: {
          ...state.case!,
          caseTariff: {
            ...state.case!.caseTariff,
            caseTariffValuesHistory: newTariffValues
          }
        }
      };

    case ActionTypes.CASE_SETTINGS_SET_CASE_CALCULATION_HISTORY:
      return {
        ...state,
        case: {
          ...state.case!,
          caseCalculation: {
            ...action.caseCalculationHistory
          }
        }
      };

    case ActionTypes.CASE_SETTINGS_SET_CAR_IN_SERVICE:
      return {
        ...state,
        case: {
          ...state.case!,
          carInService: action.carInService,
          carInServiceDate: action.carInServiceDate
        }
      };

  }

  return state;
};
