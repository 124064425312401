import { ITranslatorService } from './Interfaces/ITranslatorService';
import * as transRO from '../helpers/TransRO.json';
import * as transEN from '../helpers/TransEN.json';
import * as transNL from '../helpers/TransNL.json';
import * as transPL from '../helpers/TransPL.json';
import { isNullOrUndefined } from 'util';
import { API } from '../helpers/API';
import { AxiosResponse } from 'axios';

export class TranslatorService implements ITranslatorService {
  private cookienName = '.AspNetCore.Culture';
  public language = '';
  private baseController = 'case/languages';

  private transRO: { [key: string]: string } = transRO.translation;
  private transEN: { [key: string]: string } = transEN.translation;
  private safetyLanguages: any = {
    ro: transRO.translation,
    en: transEN.translation,
    nl: transNL.translation,
    pl: transPL.translation
  };

  private localStorageLanguages = localStorage.getItem('languages');
  private languages: any =
    this.localStorageLanguages && JSON.parse(this.localStorageLanguages).length
      ? JSON.parse(this.localStorageLanguages).reduce((acc: any, language: any) => {
          acc[language.code] = JSON.parse(language.translations).translation;
          return acc;
        }, {})
      : this.safetyLanguages;
  private defaultLanguage = this.languages['ro'] ? 'ro' : 'en';

  public Tranlate(tag: string, defaulValue: string): string {
    if (this.language === '') {
      this.LoadLanguage();
    }

    let languagesCopy = this.languages;

    if (isNullOrUndefined(this.languages[this.language])) {
      languagesCopy = this.safetyLanguages;
    } else {
      languagesCopy = this.languages;
    }

    const transArray: { [key: string]: string } = languagesCopy[this.language];
    const value = transArray[tag];

    return value === null || value === undefined ? defaulValue : value;
  }

  TranslateSchema = (schema: any): any => {
    if (isNullOrUndefined(schema.properties)) {
      return schema;
    }

    for (const key of Object.keys(schema.properties)) {
      schema.properties[key].title = this.Tranlate(
        schema.properties[key].tag,
        schema.properties[key].title
      );
    }

    if (!isNullOrUndefined(schema.dependencies)) {
      for (const pKey of Object.keys(schema.dependencies)) {
        for (const pKey2 of Object.keys(schema.dependencies[pKey])) {
          for (const x in schema.dependencies[pKey][pKey2]) {
            for (const key of Object.keys(schema.dependencies[pKey][pKey2][x])) {
              for (const keyF of Object.keys(schema.dependencies[pKey][pKey2][x][key])) {
                schema.dependencies[pKey][pKey2][x][key][keyF].title = this.Tranlate(
                  schema.dependencies[pKey][pKey2][x][key][keyF].tag,
                  schema.dependencies[pKey][pKey2][x][key][keyF].title
                );
              }
            }
          }
        }
      }
    }
    return schema;
  };

  TranslateErrors = (errors: any): any => {
    return errors.map((error: any) => {
      if (error.name === 'minLength') {
        error.message = this.Tranlate('FRM_MIN_LENGTH_ERROR', error.message).replace(
          '{0}',
          error.params.limit
        );
      }
      if (error.name === 'maxLength') {
        error.message = this.Tranlate('FRM_MAX_LENGTH_ERROR', error.message).replace(
          '{0}',
          error.params.limit
        );
      }
      if (error.name === 'minimum') {
        error.message = this.Tranlate('FRM_MINIMUM_ERROR', error.message).replace(
          '{0}',
          error.params.limit
        );
      }
      if (error.name === 'maximum') {
        error.message = this.Tranlate('FRM_MAXIMUM_ERROR', error.message).replace(
          '{0}',
          error.params.limit
        );
      }
      if (error.name === 'required') {
        error.message = this.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
      }
      return error;
    });
  };

  public GetLanguage(): string {
    return this.language;
  }

  public LoadLanguage(): void {
    this.language = this.defaultLanguage;
    const cookie = this.getCookie(this.cookienName);

    if (cookie !== '') {
      this.language = cookie.split('|')[0].split('=')[1];
    }

    localStorage.setItem('locale', this.language);
  }

  private getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    let result = '';
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        result = c.substring(name.length, c.length);
      }
    }
    return result;
  }

  public SetLanguage(language: string) {
    this.language = language;
    localStorage.setItem('locale', this.language);
    this.setCookie(this.cookienName, language);
  }

  public setCookie(cname: string, cvalue: string) {
    console.log(document);
    const value = `c=${cvalue}|uic=${cvalue}`;
    document.cookie = `${cname}= ${value};path=/; domain=.${document.domain.replace(
      /([a-zA-Z0-9]+.)/,
      ''
    )}`;
  }

  public async GetLanguagesList(): Promise<AxiosResponse | any> {
    return await API.GetAsync(`${this.baseController}/list`);
  }

  public async GetLanguages(): Promise<AxiosResponse | any> {
    try {
      const response = await API.GetAsync(`${this.baseController}/export`);
      localStorage.setItem('languages', JSON.stringify(response.data));
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async ImportLanguage({ code }: { code: string }): Promise<AxiosResponse | any> {
    return await API.PostAsync(`${this.baseController}/import`, { code });
  }

  public async UpdateLanguage({ code }: { code: string }): Promise<AxiosResponse | any> {
   return await API.PutAsync(`${this.baseController}/update`, { code });
  }

  public async DeleteLanguage({ code }: { code: string }): Promise<AxiosResponse | any> {
    return await API.DeleteAsync(`${this.baseController}/delete`, { code });
  }
}
