import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { TextField, Card, Button, Box, IconButton, CardContent } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { AppUser, AppUserRole } from '../../../interfaces/AppUser';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withSnackbar, ProviderContext } from 'notistack';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  SelectableRows,
  MUIDataTableOptions,
  MUIDataTableMeta
} from 'mui-datatables';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';

interface IUserDetailsUser {
  appUser: AppUser;
}

type EditUserRolesProps = { appState: AppState } & ProviderContext &
  typeof AppUserAdminActionCreators &
  typeof AppActionCreators &
  RouteComponentProps<{ id: string }> &
  IUserDetailsUser;

interface IEditUserRolesState {
  isLoading: boolean;
  userRoles: AppUserRole[];
  selectedRole: AppUserRole | null;
  roles: AppUserRole[];
}

class EditUserRoles extends React.PureComponent<EditUserRolesProps, IEditUserRolesState> {
  private translatorService!: ITranslatorService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    userRoles: [] as AppUserRole[],
    selectedRole: {} as AppUserRole,
    roles: [] as AppUserRole[]
  };

  public async componentDidMount() {
    if (this.props.appUser.id === null) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadUserRoles();
      }
    );
  }

  loadUserRoles = async () => {
    const [userRoleList, roles] = await Promise.all([
      this.appUserService.GetUserAppUserRoles(this.props.appUser.id, 1, 10000),
      this.appUserService.GetAppUserRoles()
    ]);

    const newroles = this.filterRoles(roles.roles, userRoleList.roles);
    this.setState({
      userRoles: userRoleList.roles,
      roles: newroles,
      isLoading: false
    });
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'name',
        label: this.translatorService.Tranlate('USER_ROLE_NAME_HEADER', 'Rol')
      },
      {
        name: 'dateCreation',
        label: this.translatorService.Tranlate('USER_ROLE_DATE_CREATION_HEADER', 'Data Creare'),

        options: {
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'createdByUserFullName',
        label: this.translatorService.Tranlate('USER_ROL_CREATEDBY_HEADER', 'Creat de')
      },
      {
        name: '',
        options: {
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteRole(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  getOptions = () => {
    const options = {
      print: false,
      download: false,
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService)
    } as MUIDataTableOptions;

    return options;
  };

  addRole = async () => {
    const newRole = this.state.selectedRole;
    newRole.dateCreation = moment.utc().toISOString();
    newRole.createdByUserFullName =
      this.props.appState.appUser!.lastName + ' ' + this.props.appState.appUser!.firstName;

    const newUserRoles = [...this.state.userRoles, newRole];
    const newRoles = [...this.state.roles];
    newRoles.splice(
      newRoles.findIndex((i) => i.id === this.state.selectedRole.id),
      1
    );
    try {
      await this.appUserService.AddAppUserRole({
        userId: this.props.appUser.id!,
        roleId: this.state.selectedRole.id!
      });
      // this.setState({
      //   isLoading: true,
      //   selectedRole: null
      // }, () => {
      //   this.loadUserRoles();
      // });
      this.setState({ userRoles: newUserRoles, roles: newRoles, selectedRole: null });
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  deleteRole = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const index = this.state.userRoles.findIndex((i) => i.id === tableMeta.rowData[0]);
      const deletedRole = this.state.userRoles[index];

      const newUserRoleArray = [...this.state.userRoles];
      newUserRoleArray.splice(index, 1);

      await this.appUserService.DeleteAppUserRole({
        userId: this.props.appUser.id!,
        roleId: deletedRole.id!
      });

      if (deletedRole.isActive) {
        this.setState({ userRoles: newUserRoleArray, roles: [...this.state.roles, deletedRole] });
      } else {
        this.setState({ userRoles: newUserRoleArray });
      }

      // this.setState({
      //   isLoading: true,
      //   selectedRole: null
      // }, () => {
      //   this.loadUserRoles();
      // });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleAutocompleteChange = (newValue: AppUserRole | null) => {
    this.setState({ selectedRole: newValue });
  };

  filterRoles = (roles: AppUserRole[], appuserRoles: AppUserRole[]) => {
    return roles.filter(
      (item) => appuserRoles.find((r) => r.id === item.id) === undefined && item.isActive
    );
  };

  public renderAddForm() {
    return (
      <Box m={1} p={1}>
        <Card>
          <CardContent>
            <ValidatorForm onSubmit={this.addRole}>
              <TextField
                fullWidth
                className="m-2"
                id="filled-basic"
                label={this.translatorService.Tranlate(
                  'ADMIN_EDIT_USER_ROLES_USER_LABEL',
                  'Utilizator'
                )}
                variant="filled"
                value={this.props.appUser.userName}
              />
              <Autocomplete
                id="role"
                className="m-2"
                options={this.state.roles}
                value={this.state.selectedRole}
                onChange={(e: any, newValue: AppUserRole | null) =>
                  this.handleAutocompleteChange(newValue)
                }
                getOptionLabel={(option: AppUserRole) => option.name || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="role"
                    value={this.state.selectedRole}
                    label={this.translatorService.Tranlate(
                      'ADMIN_EDIT_USER_ROLES_ROLE_LABEL',
                      'Rol'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <Fragment>
        {this.props.appUser.id !== null ? this.renderAddForm() : null}
        <Box m={1} p={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.userRoles}
              columns={this.getColumns()}
              options={this.getOptions()}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({ appState: state.app }), // Selects which state properties are merged into the component's props
  { ...AppUserAdminActionCreators, ...AppActionCreators } // Selects which action creators are merged into the component's props
)(withSnackbar(EditUserRoles as any));
