import { Utils } from '../helpers/Utils';
import { IReferential } from '../interfaces/IReferential';
import { OrderPart, OrderPartDTO } from '../interfaces/Order';
import { isNullOrUndefined } from 'util';
export const pretUnitarFormat = (
  part: OrderPart | OrderPartDTO | undefined,
  pretOriginal: number,
  currencyCode: string,
  country: IReferential | null
) => {
  if (part!.alternativePartQualityId === null) {
    return (
      Utils.countryNumberFormat((Math.round(pretOriginal * 100) / 100).toFixed(2), country) +
      ' ' +
      (!isNullOrUndefined(currencyCode) && currencyCode !== '' ? currencyCode : '')
    );
  } else {
    if (isNullOrUndefined(part!.alternativePrice)) {
      return 0;
    }
    return (
      Utils.countryNumberFormat((Math.round(part!.alternativePrice * 100) / 100).toFixed(2), country) +
      ' ' +
      (!isNullOrUndefined(currencyCode) && currencyCode !== '' ? currencyCode : '')
    );
  }
};
