import { API } from '../../helpers/API';
import { ValuationCarFile } from './evaluation.types';
import { ValuationVehicleOptions } from '../../interfaces/Case';

interface CarValuationResponse {
  message: string;
  error: boolean;
  data: ValuationCarFile;
}

interface CarValuationListResponse {
  message: string;
  error: boolean;
  data: ValuationVehicleOptions[];
}

interface CarValuationHistoryResponse {
  message: string;
  error: boolean;
  data: ValuationCarFile[];
}

export const getCarValuationDetails = async (id: number): Promise<CarValuationResponse> => {
  try {
    const response = await API.GetAsync(`/case/getValuation/${id}`);
    return {
      message: 'Success',
      error: false,
      data: response.data as ValuationCarFile
    };
  } catch (e) {
    return {
      message: 'Error getting car valuation details',
      error: true,
      data: {} as ValuationCarFile
    };
  }
};

export const getValuationVehicleOptions = async (
  vehicleId: number
): Promise<CarValuationListResponse> => {
  try {
    const response = await API.GetAsync(`/case/valuationOptions/${vehicleId}`);
    return {
      message: 'Success',
      error: false,
      data: [response.data as ValuationVehicleOptions]
    };
  } catch (e) {
    return {
      message: 'Error getting car valuation options',
      error: true,
      data: [] as ValuationVehicleOptions[]
    };
  }
};

export const getValuationVehicleHistory = async (): Promise<CarValuationHistoryResponse> => {
  try {
    const response = await API.GetAsync(`/case/valuation-history`);
    return {
      message: 'Success',
      error: false,
      data: response.data as ValuationCarFile[]
    };
  } catch (e) {
    return {
      message: 'Error getting car valuation history',
      error: true,
      data: [] as ValuationCarFile[]
    };
  }
};
