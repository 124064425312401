import { Action, Reducer } from 'redux';
import { AppUserAction } from '../actions/AppUserAdmin';
import { AppuserList } from '../../interfaces/AppUser';
import * as ActionTypes from '../actions/ActionTypes';

export interface AppUserAdminState {
  userList: AppuserList;
  isLoading: boolean;
}

const initialState: AppUserAdminState = {
  userList: {} as AppuserList,
  isLoading: false
};

export const AppUserAdminReducer: Reducer<AppUserAdminState> = (
  state: AppUserAdminState | undefined,
  incomingAction: Action
): AppUserAdminState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as AppUserAction;

  switch (action.type) {
    case ActionTypes.APPUSER_USER_GET_LIST_START:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.APPUSER_USER_GET_LIST_END:
      return {
        ...state,
        userList: action.userList,
        isLoading: false
      };
  }

  return state;
};
