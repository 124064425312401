import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, Grid} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import {
  WorkflowFormAction,
  WorkflowSectionStepForm
} from '../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import { Appointment, CaseAttachment, CaseRequest } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import { AppUser } from '../../interfaces/AppUser';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import {
  WorkflowFormActionTypeCode
} from '../../helpers/Constants';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import 'react-dropdown-tree-select/dist/styles.css';
import * as R from 'ramda';

interface ICaseDetailsCaseCarfixValidateAppointmentFormState {
  backToServiceFormData: CaseRequest;
  selectedUser: AppUser | null;
  isLoading: boolean;
  isLoading2: boolean;
  hasRights: boolean;
  isUserHidden: boolean;
  executing: boolean;
  formSubmitted: boolean;
  wfTransitionStepAction: WorkflowFormAction;
  appointment: Appointment | null;
}

export interface ExternalCaseDetailsCaseCarfixValidateAppointmentFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseCarfixValidateAppointmentFormProps =
  ExternalCaseDetailsCaseCarfixValidateAppointmentFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseCarfixValidateAppointmentForm extends React.PureComponent<
  ICaseDetailsCaseCarfixValidateAppointmentFormProps,
  ICaseDetailsCaseCarfixValidateAppointmentFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private workflowService!: IWorkflowService;

  static contextType = ApplicationContext;
  state = {
    backToServiceFormData: {} as CaseRequest,
    selectedUser: null,
    isLoading: false,
    isLoading2: false,
    hasRights: false,
    isUserHidden: false,
    executing: false,
    formSubmitted: false,
    wfTransitionStepAction: {} as WorkflowFormAction,
    appointment: null
  } as ICaseDetailsCaseCarfixValidateAppointmentFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }


  loadData = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseStatusId = this.props.caseSettingsState.case?.caseStatus?.caseStatusId;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const [appointment, wfStepActions] = await Promise.all([
      this.caseService.GetAppointmentByCaseId(caseId),
      this.workflowService.GetWorkflowSectionStepFormActions(
        this.props.workflowForm.id
      )
    ]);

    const caseRequest = {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      text: this.translatorService.Tranlate(this.props.workflowForm.comments, ''),
      subject: '',
      to: '',
      clientEmail: '',
      userIdTo: '',
      organizationId: null,
      attachments: [] as CaseAttachment[],
      caseSectionStepFormId: this.props.workflowForm.id
    } as CaseRequest;

    const wfTransitionStepAction =
      wfStepActions.find(
        (item) =>
          item.actionType?.code === WorkflowFormActionTypeCode.STATUS_TRANSITION &&
          caseStatusId === item.fromStatusId
      ) || ({} as WorkflowFormAction);


    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    console.log('appointment', appointment);
  
    this.setState(
      {
        backToServiceFormData: caseRequest,
        isLoading: false,
        hasRights: hasRights,
        appointment: appointment,
        wfTransitionStepAction
      }
    );
  };

  sendEmail = async () => {
    try {
      let request = this.state.backToServiceFormData;
      const { toWorkflowId, toStatusId } = this.state.wfTransitionStepAction;
      const userId = this.props.appState?.appUser?.id || this.state.selectedUser?.id;

      if (isNullOrUndefined(request)) {
        return;
      }

      this.setState({ executing: true, formSubmitted: true });

      request!.id = 0;

      if (toWorkflowId && toStatusId) {
        await this.caseService.ChangeCaseWorkflow(
          toWorkflowId,
          request.caseId,
          userId!,
          toStatusId
        );
      } else {
        request = await this.caseService.AddCaseRequest(request);
      }

      await this.submitForm(request.caseId, request.caseSectionStepFormId, this.state.selectedUser);

      this.setState(
        {
          backToServiceFormData: {
            id: 0,
            caseId: this.props.caseSettingsState.case!.id,
            text: this.translatorService.Tranlate(this.props.workflowForm.comments, ''),
            subject: '',
            to: '',
            clientEmail:  '',
            userId: '',
            organizationId: null,
            attachments: [] as CaseAttachment[],
            caseSectionStepFormId: this.props.workflowForm.id
          } as CaseRequest,
          formSubmitted: false,
          selectedUser: null
        },
      );

      if (toWorkflowId && toStatusId) {
        this.props.SetCaseSettings(request.caseId);
      }
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appuser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appuser);
    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }
    this.props.SetCaseStatus(newStatus);
    if (appuser !== null) {
      this.props.AddPartner(appuser!.hoId === null ? appuser!.organizationId : appuser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  handleUserChange = (newValue: AppUser | null) => {
    this.setState({ selectedUser: newValue });
  };

  onClick = async (id: number) => {
    try {
      this.props.history.push('/appointment/' + id);
    } catch (err) {
      console.log(err);
    }
  };

  rendeBackToServiceForm = () => {
    return isNullOrUndefined(this.state.backToServiceFormData) ? null : (
      <ValidatorForm
        onSubmit={(e) => {
          this.sendEmail();
        }}
      >
        <div className="m-3 text-center">
          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="textComment"
            name="textComment"
            multiline
            rows={8}
            placeholder={this.translatorService.Tranlate(
              'CASE_BACK_TO_SERVICE_FORM_WRITE_MESSAGE_PLACEHOLDER',
              'Write your message...'
            )}
            value={this.state.backToServiceFormData.text}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({
                backToServiceFormData: {
                  ...this.state.backToServiceFormData,
                  text: e.target.value
                }
              });
            }}
            variant="outlined"
            validators={['required']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
          />
        </div>
        {R.isNil(this.state.appointment) ? null : (
          <Grid container spacing={0} alignItems="flex-start" className="m-3">
            <Grid item className="d-inline-flex ml-2 mt-1 pr-3" style={{ display: 'flex' }}>
              <div style={{ marginRight: '10px', fontWeight: 'bold', color: 'black', whiteSpace: 'nowrap' }}>
                {this.translatorService.Tranlate('VALIDATE_APPOINTMENT_ID', 'Id Programare') + ':'}
              </div>
              <div
                className="text-primary p-0 font-weight-bold"
                onClick={(e) => this.onClick(this.state.appointment!.id)}
                style={{ cursor: 'pointer' }}
              >
                {this.state.appointment!.id}
              </div>
            </Grid>
          </Grid>
        )}

      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.workflowService = (this.context as AppContext).workflowService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendeBackToServiceForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseCarfixValidateAppointmentFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseCarfixValidateAppointmentForm as any));
