import * as React from 'react';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Card,
  Grid,
  Tab,
  Tabs,
  AppBar,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Provenance,
  MavoOrdersFilters,
  MavoOrdersList,
  MavoVehicleOrder
} from '../../interfaces/Vehicle';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Loader from '../Loader';
import noop from 'lodash/noop';
import _ from 'lodash';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import NumberFormat from 'react-number-format';
import { VehicleAuctionActionCreators } from '../../store/actions/VehicleAuction';
import { AppUser } from '../../interfaces/AppUser';
import OrdersButtons from './OrdersButtons';
import { MavoVehicleProvenanceType } from '../../helpers/Constants';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import * as R from 'ramda';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core';

type MavoVehiclesUserOrdersProps = {
  appState: AppState;
  mavoVehicleState: MavoVehicleState
} &
  typeof MavoVehicleActionCreators & typeof VehicleAuctionActionCreators &
  ProviderContext &
  RouteComponentProps;

interface IMavoVehiclesUserOrdersState {
  ordersList: MavoOrdersList;
  selectedAgentEntities: AppUser[];
  agentList: AppUser[];
  provenanceEntities: Provenance[];
  selectedProvenanceEntities: Provenance[];
  selectedTab: number;
  isLoading: boolean;
  isLoadingOrders: boolean;
  count: number;
  page: number;
  pageSize: number;
  executing: boolean;
  selectedOrder: MavoVehicleOrder | null;
}

class MavoVehiclesUserOrders extends React.PureComponent<MavoVehiclesUserOrdersProps, IMavoVehiclesUserOrdersState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;
  private appUserService!: IAppUserService;

  state = {
    ordersList: {} as MavoOrdersList,
    selectedAgentEntities: [this.props.appState.appUser],
    agentList: [],
    provenanceEntities: [],
    selectedProvenanceEntities: [],
    selectedTab: 0,
    isLoading: false,
    isLoadingOrders: false,
    count: 1,
    page: 0,
    pageSize: 10,
    executing: false,
    isDialogOpen: false,
    isOfferDialogOpen: false,
    selectedOrder: null
  } as IMavoVehiclesUserOrdersState;

  public async componentDidMount() {
    this.setState({
      isLoading: true    
    });

    const provenanceTypeList =  await this.cbContext.vehicleService.GetProvenances();
    const dvsProvenance = provenanceTypeList.find(x => x.type === MavoVehicleProvenanceType.DVS);

    const mavoVehiclesOrdersFilters = {
      agentIds: this.state.selectedAgentEntities.map((item) => item.id),
      provenanceIds: dvsProvenance != null ? [dvsProvenance.id] : [],
      page: this.state.page + 1,
      pageSize: this.state.pageSize
    } as MavoOrdersFilters;

    const [ordersList, orderFilters] = await Promise.all([
      this.cbContext.vehicleService.GetMavoOrders(mavoVehiclesOrdersFilters),
      await this.cbContext.vehicleService.GetMavoOrdersFilters({
        agentIds: this.state.selectedAgentEntities.map((item) => item.id),
        provenanceIds: dvsProvenance != null ? [dvsProvenance.id] : [],
      } as MavoOrdersFilters)
    ]);

    const [users, createdByList] = await Promise.all([
      this.appUserService.GetUsersInfo(ordersList.orders
        .map((item) => item.userId)
        .filter((userId): userId is string => userId !== null)),
      this.appUserService.GetUsersInfo(orderFilters.agentList)
    ]);

    ordersList.orders.forEach((order) => {
      const user = users.find((item) => item.id === order.userId);
      order.user = R.isNil(user) ? null : user;
    });

    this.setState(
      {
        ordersList: ordersList,
        count: ordersList.total,
        page: ordersList.page - 1,
        selectedProvenanceEntities: dvsProvenance != null ? [dvsProvenance] : [],
        provenanceEntities: orderFilters.provenanceList,
        agentList: createdByList,
        isLoading: false
      });
  }

  isInternal = (): boolean | null => {
    if (this.state.selectedTab === 0) {
      return null;
    }
    if (this.state.selectedTab === 1) {
      return false;
    }

    return true;
  };

  loadOrders = async (page: number, rowsPerPage: number) => {
    this.setState({
      isLoadingOrders: true,
      selectedOrder: null
    });

    const MavoVehiclesUserOrdersFilters = {
      provenanceIds: this.state.selectedProvenanceEntities.map((item) => item.id),
      agentIds: this.state.selectedAgentEntities.map((item) => item.id),
      page: page + 1,
      pageSize: rowsPerPage
    } as MavoOrdersFilters;

    const ordersList = await this.cbContext.vehicleService.GetMavoOrders(MavoVehiclesUserOrdersFilters);

    const [users] = await Promise.all([
      this.appUserService.GetUsersInfo(ordersList.orders
        .map((item) => item.userId)
        .filter((userId): userId is string => userId !== null))
    ]);

    ordersList.orders.forEach((order) => {
      const user = users.find((item) => item.id === order.userId);
      order.user = R.isNil(user) ? null : user;
    });

    this.setState({
      ordersList: ordersList,
      page: ordersList.page - 1,
      pageSize: rowsPerPage,
      count: ordersList.total,
      isLoadingOrders: false
    });
  };


  makeSelectedOrder =  (isSelected: boolean, id: number) => {
    const orders = _.cloneDeep(this.state.ordersList.orders);
    const order = orders.find((item) => item.id === id);
    if (order) {
      order.isSelected = isSelected;
    }

    if (isSelected) {
      orders.forEach((item) => {
        if (item.id !== id) {
          item.isSelected = false;
        }   
      });
    }
    this.setState({
      ordersList: {
        ...this.state.ordersList,
        orders: orders
      },
      selectedOrder: isSelected ? (order ? order : null): null
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          paddingLeft: "2px",
          paddingRight: "2px",
          paddingTop: "2px",
          paddingBottom: "2px"
        },
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          paddingLeft: "2px",
          paddingRight: "2px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "40px !important"
        }
      },
      MuiCheckbox: {
        root: {
          padding: "0px", // Adjust the padding to make the checkbox smaller
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: "#3d4977 !important",
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
          }
        }
      }
    } as any
  });


  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'id',
        label: ' ',
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const row = this.state.ordersList.orders.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    <FormControlLabel
                      className="m-2"
                      control={
                        <Checkbox
                          checked={row!.isSelected || false}
                          onChange={(e) => {
                            this.makeSelectedOrder(e.target.checked, row!.id);
                          }}
                        ></Checkbox>
                        }
                        label=''
                    />
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'mavoVehicle.vin',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VIN_CODE', 'VIN'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.make.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.model.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model'),
        options: {
          sort: false
        }
      },      
      {
        name: 'mavoVehicle.versionItem.displayName',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VERSION', 'Versiune'),
        options: {
          sort: false
        }
      },     
      {
        name: 'mavoVehicle.fuelType.displayName',
        label: this.cbContext.translatorService.Tranlate('CAR_DETAILS_FUEL_TYPE', 'Combustibil'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.colorRCI.displayName',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_COLOR',
          'Culoare'
        ),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.fabricationYear',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLE_FABRICATION_YEAR',
          'An de fabricatie'
        ),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.kilometersNr',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_KM',
          'Km'
        ),
        options: {
          sort: false
        }
      },
      {
        name: 'userId',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLE_ORDER_CLIENT',
          'Client'
        ),  
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const order = this.state.ordersList.orders.find((item) => item.id === tableMeta.rowData[0]);
            if (R.isNil(order)) {
              return '';
            }

            return (
              <Grid container direction="column">
                <Grid item xs={12}>
                  {R.isNil(order.user) ? order.name : order.user!.organization!.name}
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'price',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLE_ORDER_PRICE',
          'Pret'
        ),
      },
      {
        name: 'mavoVehicle.currentStatus.status.displayName',
        label: this.cbContext.translatorService.Tranlate('MAVO_ORDER_VEHICLE_STATE_', 'Stare vehicul'),
        options: {
          sort: false
        }
      },  
      {
        name: 'mavoVehicle.provenance.type',
        label: this.cbContext.translatorService.Tranlate(
          'MENU_NOMENCLATURES_TAB_PROVENANCE',
          'Provenienta'
        ),
        options: {
          sort: false
        }
      }
    ];

    return columns;
  };

  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        page: 0
      },
      async () => await this.loadOrders(0, this.state.pageSize)
    );
  };


  handleProvenanceEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedProvenanceEntities: newValue
      }
    );
  };

  renderSearchButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.loadOrders(this.state.page, this.state.pageSize)}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_SEARCH',
              'Cautare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.resetFilters()}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_RESET',
              'Reset'
            )}
          </Button>
        </div>
      </div>

    );
  }

  resetFilters = async () => {
    this.setState({
      selectedProvenanceEntities: []
    }, async () => {
      await this.loadOrders(this.state.page, this.state.pageSize);
    });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  
  handleAgentChange = (newValue: any[]) => {
    this.setState(
      {
        selectedAgentEntities: newValue
      }
    );
  };

  renderFilters = () => {
    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="provenance"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.provenanceEntities.sort(function (a, b) {
                  return a.type.localeCompare(b.type);
                })}
                value={this.state.selectedProvenanceEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleProvenanceEntitiesChange(newValue)
                }
                getOptionLabel={(option: Provenance) => option.type || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="provenanceEntities"
                    value={this.state.selectedProvenanceEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MENU_NOMENCLATURES_TAB_PROVENANCE',
                      'Provenienta'
                    )}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  };


  tableOptions = () => {
    return {
      sort: true,
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadOrders(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadOrders(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            break;
          default:
        }
      }
    };
  };
  

  public render() {
    this.cbContext = this.context as AppContext;
    this.appUserService = (this.context as AppContext).appUserService;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          <AppBar position="static" elevation={0} color="secondary">
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '4px'
                }
              }}
              value={this.state.selectedTab}
              onChange={this.onTabChange}
              variant="fullWidth"
              centered={true}
              indicatorColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                label={this.cbContext.translatorService.Tranlate('MENU_MAVO_ORDERS', 'Comenzi')}
                />
            </Tabs>
          </AppBar>

          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <div className="m-3">
                <OrdersButtons 
                  {...this.props}
                  selectedOrder={this.state.selectedOrder} 
                  loadOrders={this.loadOrders} 
                  page={this.state.page} 
                  pageSize={this.state.pageSize} 
                />
                {this.renderFilters()}
                {this.renderSearchButtons()}
                {this.state.isLoadingOrders ? (
                  <Loader />
                ) : (
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      title=""
                      data={this.state.ordersList.orders}
                      columns={this.getColumns()}
                      options={this.tableOptions()}
                    />
                  </MuiThemeProvider>
                )}       
              </div>
            </TabPanel>
          </div>
        </Card> 
      </React.Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    mavoVehicleState: state.mavoVehicle
  }),
{ ...MavoVehicleActionCreators }
)(withSnackbar(MavoVehiclesUserOrders as any));