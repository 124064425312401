import { AppContext } from '../../context/Contexts';
import { IReferential } from '../../interfaces/IReferential';
import { Vehicle, VehicleStatusHistory } from '../../interfaces/Vehicle';
import { AppThunkAction } from '.././';
import * as ActionTypes from './ActionTypes';

interface SetVehicleAction {
  type: typeof ActionTypes.VEHICLE_SET_VEHICLE;
  vehicle: Vehicle | null;
}
interface SetVehicleStatusAction {
  type: typeof ActionTypes.VEHICLE_SET_VEHICLE_STATUS;
  status: VehicleStatusHistory;
}
interface ResetVehicleStateAction {
  type: typeof ActionTypes.VEHICLE_RESET_STATE;
}
interface SetVehicleLoadingAction {
  type: typeof ActionTypes.VEHICLE_SET_VEHICLE_LOADING;
  loading: boolean;
}

export type VehicleAction =
  | SetVehicleAction
  | SetVehicleStatusAction
  | SetVehicleLoadingAction
  | ResetVehicleStateAction;

export const VehicleActionCreators = {
  setVehicle:
    (vehicleId: number): AppThunkAction<VehicleAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.VEHICLE_SET_VEHICLE_LOADING, loading: true });
      const vehicleService = (context as AppContext).vehicleService;

      const vehicle = await vehicleService.GetVehicle(vehicleId);
      dispatch({ type: ActionTypes.VEHICLE_SET_VEHICLE, vehicle: vehicle });
      dispatch({ type: ActionTypes.VEHICLE_SET_VEHICLE_LOADING, loading: false });
    },

  setVehicleObject:
    (vehicle: Vehicle | null): AppThunkAction<VehicleAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.VEHICLE_SET_VEHICLE, vehicle: vehicle });
    },

  changeVehicleStatus:
    (statusHis: VehicleStatusHistory): AppThunkAction<VehicleAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.VEHICLE_SET_VEHICLE_STATUS, status: statusHis });
    },

  resetVehicleState: (): AppThunkAction<VehicleAction> => async (dispatch, getState, context) => {
    dispatch({ type: ActionTypes.VEHICLE_RESET_STATE });
  }
};
