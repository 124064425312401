import { ITariffService } from './Interfaces/ITariffService';
import { Tariff, TariffPartner, TariffType, TariffValue } from '../interfaces/Tariff';
import { API } from '../helpers/API';

export class TariffService implements ITariffService {
  private baseController = '/tariff';

  public async GetTariffs(orgId: number): Promise<Tariff[]> {
    return (await API.GetAsync<Tariff[]>(`${this.baseController}/list/${orgId}`)).data;
  }

  public async GetTariff(tarriffId: number): Promise<Tariff> {
    return (await API.GetAsync<Tariff>(`${this.baseController}/${tarriffId}`)).data;
  }

  public async GetUserTariff(tarriffId: number): Promise<Tariff> {
    return (await API.GetAsync<Tariff>(`${this.baseController}/user/${tarriffId}`)).data;
  }
  public async GetUserTariffs(tarriffIds: number[]): Promise<Tariff[]> {
    const idLIst = tarriffIds.join(',');
    return (await API.GetAsync<Tariff[]>(`${this.baseController}/userList/${idLIst}`)).data;
  }

  public async AddTariff(tariff: Tariff): Promise<Tariff> {
    return (await API.PostAsync<Tariff>(`${this.baseController}`, tariff)).data;
  }

  public async UpdateTariff(tariff: Tariff): Promise<void> {
    await API.PutAsync<Tariff>(`${this.baseController}`, tariff);
  }

  public async GetTariffTypes(groupId: number | null): Promise<TariffType[]> {
    return (
      await API.GetAsync<TariffType[]>(
        `${this.baseController}/tariffTypes/${groupId === null ? '' : groupId}`
      )
    ).data;
  }

  public async GetTariffValues(
    tariffId: number,
    tariffTypeGroupId: number | null
  ): Promise<TariffValue[]> {
    return (
      await API.GetAsync<TariffValue[]>(
        `${this.baseController}/${tariffId}/tariffvalues/${
          tariffTypeGroupId === null ? '' : tariffTypeGroupId
        }`
      )
    ).data;
  }

  public async GetTariffPartners(tariffId: number): Promise<TariffPartner[]> {
    return (
      await API.GetAsync<TariffPartner[]>(`${this.baseController}/${tariffId}/tariffPartners`)
    ).data;
  }
  public async AddTariffValues(tariffValues: TariffValue[]): Promise<void> {
    await API.PostAsync<TariffValue[]>(`${this.baseController}/TariffValue`, tariffValues);
  }

  public async UpdateTariffValues(tariffValues: TariffValue[]): Promise<void> {
    await API.PutAsync<TariffValue[]>(`${this.baseController}/TariffValue`, tariffValues);
  }

  public async DeleteTariffValues(tariffId: number, date: Date): Promise<void> {
    const dateString = date.toISOString().replace(/-/g, '').split('T')[0];
    await API.DeleteAsync<void>(
      `${this.baseController}/${tariffId}/TariffValues?date=${dateString}`
    );
  }

  public async GetOwnTariffs(organizationId: number): Promise<Tariff[]> {
    return (await API.GetAsync<Tariff[]>(`${this.baseController}/own/${organizationId}`)).data;
  }
}
