import { ApplicationState, AppThunkAction } from '.././';
import * as ActionTypes from './ActionTypes';
import { User } from 'oidc-client';
import { AppContext } from '../../context/Contexts';
import { AppUser } from '../../interfaces/AppUser';
import { Language } from '../../interfaces/Organization';
import { Appointment, AppointmentFilters, Case, CaseComment, CaseFilters } from '../../interfaces/Case';
import { isNullOrUndefined } from 'util';
import { CurrencyRate } from '../../interfaces/Vehicle';
import isEqual from 'lodash/isEqual';
import { CountryCodeEnum, FilterTypeCodeEnum } from '../../helpers/Constants';
import { useSnackbar } from 'notistack';

interface AppSetUserAction {
  type: typeof ActionTypes.APP_SET_USER;
  user: User;
  appUser: AppUser;
}

interface AppRemoveUserAction {
  type: typeof ActionTypes.APP_REMOVE_USER;
}

interface AppDisplayMessageAction {
  type: typeof ActionTypes.APP_DISPLAY_MESSAGE;
  display: boolean;
}

interface AppSetLanguageAction {
  type: typeof ActionTypes.APP_SET_LANGUAGE;
  language: string;
  dateFormat: string;
  longDateFormat: string;
}

interface AppLoadUserNotifications {
  type: typeof ActionTypes.APP_LOAD_NOTIFICATION;
  newInCases: Case[];
  comments: CaseComment[];
  tasks: Case[];
  newInAppointments: Appointment[];
}

interface AppLoadUserTasks {
  type: typeof ActionTypes.APP_LOAD_TASKS;
  tasks: Case[];
}

interface AppLoadUserComments {
  type: typeof ActionTypes.APP_LOAD_COMMENTS;
  comments: CaseComment[];
}

interface AppRemoveTask {
  type: typeof ActionTypes.APP_REMOVE_TASK;
  id: number;
}
interface AppRemoveComment {
  type: typeof ActionTypes.APP_REMOVE_COMMENT;
  id: number;
}

interface AppRemoveNewInCase {
  type: typeof ActionTypes.APP_REMOVE_NEW_IN_CASE;
  id: number;
}

interface AppRemoveNewInAppointment {
  type: typeof ActionTypes.APP_REMOVE_NEW_IN_APPOINTMENT;
  id: number;
}

interface AppSetNotificationsLoaders {
  type: typeof ActionTypes.APP_SET_NOTIFICATIONS_LOADERS;
  isCommentsLoading: boolean;
  isNewInCasesLoading: boolean;
  isTasksLoading: boolean;
  isNewInAppointmentsLoading: boolean;
}

interface AppSetCurrencyRates {
  type: typeof ActionTypes.APP_SET_CURRENCY_RATES;
  currencyRates: CurrencyRate[];
}

interface AppMarkAsViewedNewInCase {
  type: typeof ActionTypes.APP_MARK_AS_VIEWED_NEW_IN_CASE;
  id: number;
}

interface AppMarkAsViewedNewInAppointment {
  type: typeof ActionTypes.APP_MARK_AS_VIEWED_NEW_IN_APPOINTMENT;
  id: number;
}

export type AppAction =
  | AppSetUserAction
  | AppRemoveUserAction
  | AppDisplayMessageAction
  | AppSetLanguageAction
  | AppLoadUserNotifications
  | AppLoadUserTasks
  | AppRemoveComment
  | AppRemoveNewInCase
  | AppRemoveTask
  | AppLoadUserComments
  | AppSetNotificationsLoaders
  | AppSetCurrencyRates
  | AppRemoveNewInAppointment
  | AppMarkAsViewedNewInCase
  | AppMarkAsViewedNewInAppointment;

const LoadUserNotifications = async (
  dispatch: (Action: AppAction) => void,
  getState: () => ApplicationState,
  context: any
) => {
  const caseService = (context as AppContext).caseService;
  const organizationService = (context as AppContext).organizationService;
  const appUserService = (context as AppContext).appUserService;
  const appState = getState().app;
  // Comment out this loading, since we are continuously pooling the notifications
  // dispatch({
  //   type: ActionTypes.APP_SET_NOTIFICATIONS_LOADERS,
  //   isCommentsLoading: true,
  //   isNewInCasesLoading: true,
  //   isTasksLoading: true
  // });
  const dateFrom = new Date(new Date().setMonth(new Date().getMonth() - 1));
  const dateTo = new Date();

  const [cases, caseNewComments, tasks, appointments] = await Promise.all([
    caseService.GetCases({ 
      isNew: true, 
      isAssignedToId: appState?.appUser?.organization?.country?.code?.toUpperCase() === CountryCodeEnum.CZ.toString() ? false : true ,
      dateFilterStartDate: new Date(dateFrom.setHours(0, 0, 0, 0)).toISOString(),
      dateFilterEndDate: new Date(dateTo.setHours(23, 59, 59, 999)).toISOString(),
      dateFilterCode: FilterTypeCodeEnum.DS.toString()
    } as any as CaseFilters),
    caseService.GetUserComments(),
    caseService.GetTaskList(),
    caseService.GetAppointments({ 
      isNew: true, 
      dateFilterStartDate: new Date(dateFrom.setHours(0, 0, 0, 0)).toISOString(), 
      dateFilterEndDate: new Date(dateTo.setHours(23, 59, 59, 999)).toISOString()
    } as AppointmentFilters),
  ]);

  const org = cases.cases.map((item) => item.organizationOwnerId);
  const taskOrg = tasks.map((item) => item.organizationOwnerId);
  const appointmentOrg = appointments.appointments.map((item) => item.serviceId);
  org.push(...taskOrg);
  org.push(...appointmentOrg);

  const [orgOwners, users] = await Promise.all([
    organizationService.GetUserOrganizationsByIds(Array.from(new Set(org))),
    appUserService.GetUsersInfo(
      Array.from(
        new Set(
          caseNewComments.map((item) => (isNullOrUndefined(item) ? '' : item!.userId.toString()))
        )
      )
    )
  ]);

  cases.cases.forEach((cd) => {
    const organizationOwner = orgOwners.find((item) => item.id === cd.organizationOwnerId);
    cd.organizationOwner = isNullOrUndefined(organizationOwner) ? null : organizationOwner;
  });

  tasks.forEach((cd) => {
    const organizationOwner = orgOwners.find((item) => item.id === cd.organizationOwnerId);
    cd.organizationOwner = isNullOrUndefined(organizationOwner) ? null : organizationOwner;
  });

  appointments.appointments.forEach((ap) => {
    const organizationOwner = orgOwners.find((item) => item.id === ap.serviceId);
    ap.service = isNullOrUndefined(organizationOwner) ? null : organizationOwner;
  });

  for (const cc of caseNewComments) {
    const usr = users.find((item) => item.id === cc.userId.toString());
    cc.user = isNullOrUndefined(usr) ? null : usr;
  }

  const { newInCases, comments, tasks: stateTasks, newInAppointments } = getState().app || {};
  const updateNeeded =
    !isEqual(newInCases, cases.cases) ||
    !isEqual(comments, caseNewComments) ||
    !isEqual(stateTasks, tasks) ||
    !isEqual(newInAppointments, appointments.appointments);

  if (updateNeeded) {
    dispatch({
      type: ActionTypes.APP_LOAD_NOTIFICATION,
      newInCases: cases.cases,
      comments: caseNewComments,
      tasks: tasks,
      newInAppointments: appointments.appointments
    });
  }

  setTimeout(() => LoadUserNotifications(dispatch, getState, context), 60000);
};

export const AppActionCreators = {
  SetUser: (enqueueSnackbar: any): AppThunkAction<AppAction> => async (dispatch, getState, context) => {
    const authService = (context as AppContext).authenticationService;
    const appUserService = (context as AppContext).appUserService;
    const translatorService = (context as AppContext).translatorService;
    const curentUser = await authService.getUserAsync();

    if (curentUser !== null) {
      localStorage.setItem('access_token', curentUser.access_token);
      
      await translatorService.GetLanguages();
      
      try {
        const appUser = await appUserService.GetProfile();
        dispatch({ type: ActionTypes.APP_SET_USER, user: curentUser, appUser: appUser });
  
        const languageCode = translatorService.GetLanguage();
        const language = appUser.organization!.gtOrganization!.languages!.find(
          (item) => item.code === languageCode
        ) as Language;
  
        dispatch({
          type: ActionTypes.APP_SET_LANGUAGE,
          language: languageCode,
          dateFormat: language.dateFormat,
          longDateFormat: language.longDateFormat
        });
      } catch (error) {
        console.log(error);
        enqueueSnackbar(
          translatorService.Tranlate('ERROR_INACTIVE_USER_MSG', 'Utilizator inactiv!'),
          { 
            variant: 'error',
            autoHideDuration: 6000
          }
        );
      }

      // LoadUserNotifications(dispatch, getState, context);
    }
  },

  RemoveUser: (): AppThunkAction<AppAction> => async (dispatch, getState, context) => {
    dispatch({ type: ActionTypes.APP_REMOVE_USER });
  },
  Message:
    (isDisplayed: boolean): AppThunkAction<AppAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.APP_DISPLAY_MESSAGE, display: isDisplayed });
    },
  SetLanguage: (): AppThunkAction<AppAction> => async (dispatch, getState, context) => {
    const translatorService = (context as AppContext).translatorService;
    const languageCode = translatorService.GetLanguage();

    const language = getState().app!.appUser!.organization!.gtOrganization!.languages!.find(
      (item) => item.code === languageCode
    ) as Language;

    dispatch({
      type: ActionTypes.APP_SET_LANGUAGE,
      language: languageCode,
      dateFormat: language.dateFormat,
      longDateFormat: language.longDateFormat
    });
  },
  LoadUserNotifications: (): AppThunkAction<AppAction> => async (dispatch, getState, context) => {
    LoadUserNotifications(dispatch, getState, context);
  },

  LoadTasks: (): AppThunkAction<AppAction> => async (dispatch, getState, context) => {
    const caseService = (context as AppContext).caseService;
    const organizationService = (context as AppContext).organizationService;

    dispatch({
      type: ActionTypes.APP_SET_NOTIFICATIONS_LOADERS,
      isCommentsLoading: false,
      isNewInCasesLoading: false,
      isTasksLoading: true,
      isNewInAppointmentsLoading: false
    });

    const [tasks] = await Promise.all([caseService.GetTaskList()]);

    const taskOrg = tasks.map((item) => item.organizationOwnerId);

    const [orgOwners] = await Promise.all([
      organizationService.GetUserOrganizationsByIds(Array.from(new Set(taskOrg)))
    ]);

    tasks.forEach((cd) => {
      const organizationOwner = orgOwners.find((item) => item.id === cd.organizationOwnerId);
      cd.organizationOwner = isNullOrUndefined(organizationOwner) ? null : organizationOwner;
    });

    dispatch({
      type: ActionTypes.APP_LOAD_TASKS,
      tasks: tasks
    });
  },

  LoadComments: (): AppThunkAction<AppAction> => async (dispatch, getState, context) => {
    const caseService = (context as AppContext).caseService;
    const appUserService = (context as AppContext).appUserService;

    dispatch({
      type: ActionTypes.APP_SET_NOTIFICATIONS_LOADERS,
      isCommentsLoading: true,
      isNewInCasesLoading: false,
      isTasksLoading: false,
      isNewInAppointmentsLoading: false
    });

    const [caseNewComments] = await Promise.all([caseService.GetUserComments()]);

    const [users] = await Promise.all([
      appUserService.GetUsersInfo(
        Array.from(
          new Set(
            caseNewComments.map((item) => (isNullOrUndefined(item) ? '' : item!.userId.toString()))
          )
        )
      )
    ]);

    for (const cc of caseNewComments) {
      const usr = users.find((item) => item.id === cc.userId.toString());
      cc.user = isNullOrUndefined(usr) ? null : usr;
    }

    dispatch({
      type: ActionTypes.APP_LOAD_COMMENTS,
      comments: caseNewComments
    });
  },
  RemoveTask:
    (id: number): AppThunkAction<AppAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.APP_REMOVE_TASK,
        id: id
      });
    },
  RemoveComment:
    (id: number): AppThunkAction<AppAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.APP_REMOVE_COMMENT,
        id: id
      });
    },
  RemoveNewInCase:
    (id: number): AppThunkAction<AppAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.APP_REMOVE_NEW_IN_CASE,
        id: id
      });
    },
  MarkAsViewedNewInCase:
    (id: number): AppThunkAction<AppAction> =>
    async (dispatch, getState, context) => {
      dispatch({
        type: ActionTypes.APP_MARK_AS_VIEWED_NEW_IN_CASE,
        id: id
      });
    },
  LoadCurrencyRates: (): AppThunkAction<AppAction> => async (dispatch, getState, context) => {
    const vehicleService = (context as AppContext).vehicleService;

    const rates = await vehicleService.GetCurrencyRates();

    dispatch({
      type: ActionTypes.APP_SET_CURRENCY_RATES,
      currencyRates: rates
    });
  },
  RemoveNewInAppointment:
  (id: number): AppThunkAction<AppAction> =>
  async (dispatch, getState, context) => {
    dispatch({
      type: ActionTypes.APP_REMOVE_NEW_IN_APPOINTMENT,
      id: id
    });
  },
  MarkAsViewedNewInAppointment:
  (id: number): AppThunkAction<AppAction> =>
  async (dispatch, getState, context) => {
    dispatch({
      type: ActionTypes.APP_MARK_AS_VIEWED_NEW_IN_APPOINTMENT,
      id: id
    });
  }
};
