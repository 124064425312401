import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { withSnackbar } from 'notistack';
import { Card, Tabs, Tab, CardContent, Box } from '@material-ui/core';
import TabPanel from '../../_shared/Tabs';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import OrganizationEntities from '../organizations/OrganizationEntities';
import OrganizationHierarchy from '../organizations/OrganizationHierarchy';
import { RoleClaimsEnum, RoleCodeEnum } from '../../../helpers/Constants';

interface IOrganizationDetailsTabState {
  selectedTab: number;
}
class OrganizationDetailsTab extends React.PureComponent<any, IOrganizationDetailsTabState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;

  state = { selectedTab: 0 };

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={this.state.selectedTab}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                onChange={this.onTabChange}
              >
                <Tab
                  className="text-capitalize "
                  label={this.translatorService.Tranlate(
                    'ORGANIZATION_TAB_ENTITIES',
                    'Entitati Organizatie'
                  )}
                />
                <Tab
                  className="text-capitalize "
                  label={this.translatorService.Tranlate(
                    'ORGANIZATION_TAB_HIERARCHY',
                    'Structura Ierarhica'
                  )}
                  hidden={this.hasRight(RoleClaimsEnum.AdminCountryOrganization) || this.hasRight(RoleClaimsEnum.AdminService)}
                  />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <TabPanel value={this.state.selectedTab} index={0}>
          <OrganizationEntities {...this.props}></OrganizationEntities>
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <OrganizationHierarchy {...this.props}></OrganizationHierarchy>
        </TabPanel>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(OrganizationDetailsTab as any));
