import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { withSnackbar } from 'notistack';
import { Card, Tabs, Tab, CardContent, Box } from '@material-ui/core';
import TabPanel from '../../_shared/Tabs';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import WorkflowCaseStructureSectionTab from './WorkflowCaseStructureSectionTab';
import WorkflowCaseStructureStepsTab from './WorkflowCaseStructureStepsTab';
import WorkflowCaseStructureFormsTab from './WorkflowCaseStructureFormsTab';

interface ICaseStructureState {
  selectedTab: number;
}
class WorkflowCaseStructure extends React.PureComponent<any, ICaseStructureState> {
  private translatorService!: ITranslatorService;

  static contextType = ApplicationContext;

  state = { selectedTab: 0 };

  onTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={this.state.selectedTab}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                onChange={this.onTabChange}
              >
                <Tab
                  className="text-capitalize "
                  label={this.translatorService.Tranlate(
                    'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_SECTION',
                    'Sectiune dosar'
                  )}
                />
                <Tab
                  className="text-capitalize "
                  label={this.translatorService.Tranlate(
                    'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_STEPS',
                    'Pasi'
                  )}
                />
                <Tab
                  className="text-capitalize "
                  label={this.translatorService.Tranlate(
                    'WORKFLOWS_TAB_CASE_STRUCTURE_TAB_FORMS',
                    'Forme'
                  )}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <TabPanel value={this.state.selectedTab} index={0}>
          <WorkflowCaseStructureSectionTab {...this.props} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <WorkflowCaseStructureStepsTab {...this.props} />
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={2}>
          <WorkflowCaseStructureFormsTab {...this.props} />
        </TabPanel>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(WorkflowCaseStructure as any));
