import React, { Fragment, useContext } from 'react';
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ListItemIcon
} from '@material-ui/core';
import projectLogo from '../../assets/images/logo_carfix_partner.png';
import { FiberManualRecord } from '@material-ui/icons';
import { ApplicationContext } from '../../context/Contexts';
import * as R from 'ramda';
import ImageNotAvailable from '../../assets/images/car-image-not-available.jpg';
import moment from 'moment';

const EvaluationReportFile = (reportData: any) => {
  const { carDetails } = reportData;
  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;
  const basicTableCellStyle = {
    fontSize: '13px',
    padding: '0 0 0  16px'
  };
  const leftTableCellStyle = {
    width: '40%',
    fontWeight: 'bold'
  };

  const checkIfImageExists = (url: string | undefined) => {
    if (R.isNil(url) || url.includes('undefined') || url.includes('null') || url === '') {
      return ImageNotAvailable;
    }
    return url;
  };

  function formatDateTime(dateTimeString: string) {
    const date = new Date(dateTimeString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${parseInt(hours) + 3}:${minutes}`;

    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
  }

  return (
    <Fragment>
      <Grid style={{ backgroundColor: '#fff' }}>
        <Grid
          className="mb-4"
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '3px'
          }}
        >
          <img
            src={projectLogo}
            alt="Project Logo"
            style={{
              width: '140px'
            }}
          />
          <Typography
            variant="h3"
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}
          >
            {translatorService.Tranlate('VALUATION_REPORT_TITLE', 'Raport evaluare')}
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="h2"
            className="m-1 mb-4"
            style={{
              fontWeight: '400'
            }}
          >
            {`${!R.isNil(carDetails?.make) ? carDetails.make : ''}  
              ${!R.isNil(carDetails?.model) ? carDetails.model : ''}
              ${!R.isNil(carDetails?.version) ? carDetails.version : ''},
              ${carDetails?.numberOfKilometers ? carDetails.numberOfKilometers : 0} km
            `}
          </Typography>
        </Grid>

        <Grid container spacing={1} className="mb-2">
          <Grid item xs={6}>
            <Typography
              variant="h6"
              className="m-1 mb-4"
              style={{
                backgroundColor: '#d8e8f5',
                color: '#20486a',
                padding: '0.75rem 1.25rem',
                marginBottom: '1rem !important',
                border: '1px solid transparent',
                borderRadius: '0.15rem',
                textTransform: 'uppercase',
                fontSize: '13px',
                fontWeight: '600'
              }}
            >
              {translatorService.Tranlate(
                'VALUATION_REPORT_CAR_DETAILS_LABEL',
                'Specificatii autovehicul'
              )}
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('CAR_DETAILS_CHASIS_CODE', 'Serie sasiu')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.vin ? carDetails.vin : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_MAKE_LABEL', 'Producator')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.make ? carDetails.make : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_MODEL_LABEL', 'Model')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.model ? carDetails.model : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_VERSION_LABEL', 'Versiune')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.version ? carDetails.version : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_EQUIP_LABEL', 'Echipare')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.equipmentVersion ? carDetails.equipmentVersion : '-'}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_FUEL_LABEL', 'Combustibil')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.fuelType ? carDetails.fuelType : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_REPORT_GEARBOX_LABEL',
                      'Cutie de viteze'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.transmissionType ? carDetails.transmissionType : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_HORSE_POWER_LABEL', 'Cai putere')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.horsePower ? carDetails.horsePower : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_BODY_TYPE_LABEL', 'Caroserie')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.bodyType ? carDetails.bodyType : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_DOORS_NUMBER_LABEL', 'Numar usi')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.nrOfDoors ? carDetails.nrOfDoors : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('CAR_DETAILS_COLOR', 'Culoare')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.color ? carDetails.color : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'CAR_DETAILS_DATE_OF_FABRICATION',
                      'Data fabricatie'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.fabricationDate
                      ? new Date(carDetails.fabricationDate).toLocaleDateString('en-GB')
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_REGISTRATION_DATE_LABEL',
                      'Data primei inmatriculari'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.registrationDate
                      ? moment(carDetails.registrationDate).format('MM/YYYY')
                      : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className="p-4">
              <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                <img
                  style={{
                    height: 'auto',
                    width: '100%',
                    maxWidth: '380px',
                    margin: '0 auto',
                    minHeight: '200px'
                  }}
                  src={checkIfImageExists(carDetails.imageUrl)}
                  alt="Car Image"
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="mb-2">
          <Grid item xs={6}>
            <Typography
              variant="h6"
              className="m-1 mb-4"
              style={{
                backgroundColor: '#d8e8f5',
                color: '#20486a',
                padding: '0.75rem 1.25rem',
                marginBottom: '1rem !important',
                border: '1px solid transparent',
                borderRadius: '0.15rem',
                textTransform: 'uppercase',
                fontSize: '13px',
                fontWeight: '600'
              }}
            >
              {translatorService.Tranlate('VALUATION_REPORT_CAR_PRICES_LABEL', 'Detalii evaluare')}
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_VALUATIO_NUMBER', 'Nr. evaluare')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.id ? `VALUATION-${carDetails.id}` : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate('VALUATION_REPORT_VALUATION_DATE', 'Data evaluare')}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.dateCreation ? formatDateTime(carDetails.dateCreation) : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_REPORT_BASE_PRICE_LABEL',
                      'Pret masina noua'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.priceAsNew ? carDetails.priceAsNew.toFixed(2) : '0.00'} €{' '}
                    {translatorService.Tranlate('VALUATION_REPORT_INCLUDING_VAT_LABEL', 'brut')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_REPORT_OPTIONS_PRICE_LABEL',
                      'Pret optionale extra'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.extraOptionsPrice
                      ? carDetails.extraOptionsPrice.toFixed(2)
                      : '0.00'}{' '}
                    € {translatorService.Tranlate('VALUATION_REPORT_INCLUDING_VAT_LABEL', 'brut')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_REPORT_TOTAL_LABEL',
                      'Pret total masina noua'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.priceAsNew >= 0 && carDetails.extraOptionsPrice >= 0
                      ? (carDetails.priceAsNew + carDetails.extraOptionsPrice).toFixed(2)
                      : '0.00'}{' '}
                    € {translatorService.Tranlate('VALUATION_REPORT_INCLUDING_VAT_LABEL', 'brut')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              className="m-1 mb-4"
              style={{
                backgroundColor: '#d8e8f5',
                color: '#20486a',
                padding: '0.75rem 1.25rem',
                marginBottom: '1rem !important',
                border: '1px solid transparent',
                borderRadius: '0.15rem',
                textTransform: 'uppercase',
                fontSize: '13px',
                fontWeight: '600'
              }}
            >
              {translatorService.Tranlate(
                'VALUATION_REPORT_CAR_DETAILS_ESTIMATIONS_LABEL',
                'Valori estimate'
              )}
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_REPORT_TRADE_IN_PRICE_LABEL',
                      'Trade-in price'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.tradeInPrice ? carDetails.tradeInPrice.toFixed(2) : '0.00'} €{' '}
                    {translatorService.Tranlate('VALUATION_REPORT_INCLUDING_VAT_LABEL', 'brut')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_REPORT_OFFER_PRICE_LABEL',
                      'Offer price'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.offerPrice ? carDetails.offerPrice.toFixed(2) : '0.00'} €{' '}
                    {translatorService.Tranlate('VALUATION_REPORT_INCLUDING_VAT_LABEL', 'brut')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      ...basicTableCellStyle,
                      ...leftTableCellStyle
                    }}
                  >
                    {translatorService.Tranlate(
                      'VALUATION_REPORT_RETAIL_PRICE_LABEL',
                      'Retail price'
                    )}
                  </TableCell>
                  <TableCell style={basicTableCellStyle}>
                    {carDetails.retailPrice ? carDetails.retailPrice.toFixed(2) : '0.00'} €{' '}
                    {translatorService.Tranlate('VALUATION_REPORT_INCLUDING_VAT_LABEL', 'brut')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="mb-2">
          <Grid item xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              style={{
                backgroundColor: '#d8e8f5',
                color: '#20486a',
                padding: '0.75rem 1.25rem',
                marginBottom: '1rem !important',
                border: '1px solid transparent',
                borderRadius: '0.15rem',
                textTransform: 'uppercase',
                fontSize: '13px',
                fontWeight: '600'
              }}
            >
              {`${translatorService.Tranlate(
                'VALUATION_CAR_FILE_STANDARD_OPTIONS',
                'Optionale standard selectate'
              )}:`}
            </Typography>
            {R.isNil(carDetails.options) ||
            R.isNil(carDetails.options.standardOptions) ||
            carDetails.options.standardOptions.length === 0 ? (
              <ListItem>
                <ListItemText
                  secondary={translatorService.Tranlate(
                    'VALUATION_CAR_FILE_EMPTY_LIST',
                    'Nu exista optiuni selectate'
                  )}
                />
              </ListItem>
            ) : (
              <List
                style={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {carDetails.options?.standardOptions.map((item: any, index: any) => (
                  <ListItem
                    key={index}
                    style={{ width: '50%', alignItems: 'baseline', padding: '0px 0px 0px 16px' }}
                  >
                    <ListItemIcon style={{ padding: '0px', minWidth: '20px' }}>
                      <FiberManualRecord
                        style={{
                          fontSize: '5px',
                          color: 'black'
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        padding: '0px',
                        margin: '0px'
                      }}
                      primary={`${item?.content}`}
                      primaryTypographyProps={{
                        style: { fontSize: '13px', lineHeight: '1' }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              style={{
                backgroundColor: '#d8e8f5',
                color: '#20486a',
                padding: '0.75rem 1.25rem',
                marginBottom: '1rem !important',
                border: '1px solid transparent',
                borderRadius: '0.15rem',
                textTransform: 'uppercase',
                fontSize: '13px',
                fontWeight: '600'
              }}
            >
              {`${translatorService.Tranlate(
                'VALUATION_CAR_FILE_EXTRA_OPTIONS',
                'Optionale extra selectate'
              )}:`}
            </Typography>
            {R.isNil(carDetails.options) ||
            R.isNil(carDetails.options.extraOptions) ||
            carDetails.options.extraOptions.length === 0 ? (
              <ListItem>
                <ListItemText
                  secondary={translatorService.Tranlate(
                    'VALUATION_CAR_FILE_EMPTY_LIST',
                    'Nu exista optiuni selectate'
                  )}
                />
              </ListItem>
            ) : (
              <List
                component="ul"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {carDetails.options?.extraOptions.map((item: any, index: any) => (
                  <ListItem
                    key={index}
                    style={{ width: '50%', alignItems: 'baseline', padding: '0px 0px 0px 16px' }}
                  >
                    <ListItemIcon style={{ padding: '0px', minWidth: '20px' }}>
                      <FiberManualRecord
                        style={{
                          fontSize: '5px',
                          color: 'black'
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        padding: '0px',
                        margin: '0px'
                      }}
                      primary={`[${item?.optionCode}] ${item?.description} - ${item?.price} €`}
                      primaryTypographyProps={{
                        style: { fontSize: '13px', lineHeight: '1' }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EvaluationReportFile;
