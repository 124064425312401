import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import {
  Tooltip,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { RouteComponentProps } from 'react-router';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableMeta,
  MUIDataTableColumnOptions
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { IReferential } from '../../../interfaces/IReferential';
import moment from 'moment';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NumberFormat from 'react-number-format';
import { IVehicleService } from '../../../services/Interfaces/IVehicleService';
import {  Provenance } from '../../../interfaces/Vehicle';

interface IProvenanceListState {
  newProvenance: Provenance;
  provenances: Provenance[];
  isLoading: boolean;
  isDialogOpen: boolean;
  newProvenanceId: number | null;
}


type IProvenanceListProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class ProvenanceList extends React.PureComponent<
  IProvenanceListProps,
  IProvenanceListState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;


  static contextType = ApplicationContext;
  private newProvenance = {
    id: 0,
    type: null,
    account: null
  } as unknown as Provenance;

  state = {
    provenances: [],
    isLoading: false,
    selectedFilter: 0,
    isDialogOpen: false,
    newProvenance: this.newProvenance,
    newProvenanceId: null
  } as IProvenanceListState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadProvenance();
      }
    );
  }

  loadProvenance = async () => {
    const provenances = await this.vehicleService.GetProvenances();

    this.setState({
      provenances: provenances,
      isLoading: false,
      newProvenance: { ...this.newProvenance }
    });
  };


  public saveProvenance = async () => {
    if (this.state.newProvenanceId === null) {
      try {
        await this.vehicleService.AddProvenance(this.state.newProvenance);

        await this.loadProvenance();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    } else {
      try {

        await this.vehicleService.UpdateProvenance(this.state.newProvenance);

        await this.loadProvenance();

        this.closeDialog();

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  onChange =
  (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => {
    isNumber
      ? this.setState({
        newProvenance: {
            ...this.state.newProvenance,
            [fieldName]: parseFloat(newValue || e.target.value)
          }
        })
      : this.setState({
        newProvenance: {
            ...this.state.newProvenance,
            [fieldName]: newValue || e.target.value
          }
        });
  };
  
  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };


  public renderAddForm() {
    return (
      <ValidatorForm onSubmit={this.saveProvenance} instantValidate={true}>
        <TextValidator
          fullWidth
          id="type"
          className="m-2 pr-3 mt-0"
          name="type"
          placeholder={this.translatorService.Tranlate('PROVENANCE_TYPE', 'Tip')}
          value={this.state.newProvenance.type}
          onChange={(e: any) =>
            this.onChange('type', false)(e)
          }
          validators={['required']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
          label={this.translatorService.Tranlate('PROVENANCE_TYPE', 'Tip')}
        />

        <TextValidator
          fullWidth
          id="account"
          className="m-2 pr-3"
          name="account"
          placeholder={this.translatorService.Tranlate('PROVENANCE_ACCOUNT', 'Cont Contabil')}
          value={this.state.newProvenance.account || '' }
          onChange={(e: any) => this.onChange('account', false)(e)}
          validators={['required']}
          errorMessages={[
            this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
          ]}
          label={this.translatorService.Tranlate('PROVENANCE_ACCOUNT', 'Cont Contabil')}
        />
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit">
            {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
          </Button>
        </div>
    </ValidatorForm>
    );
  }

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'type',
        label: this.translatorService.Tranlate('PROVENANCE_TYPE', 'Tip'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'account',
        label: this.translatorService.Tranlate('PROVENANCE_ACCOUNT', 'Cont contabil'),
        options: {
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return value;
          }
        }
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate('USERS_DATE_MODIFICATION_HEADER', 'Data Modificare'),
        options: {
          filter: false,
          sortDirection: 'desc',
          customBodyRender: (value: Date) =>
            moment.utc(value).local().format(this.props.appState.longDateFormat)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          viewColumns: false,
          filter: false,
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  className="text-primary"
                  size="small"
                  onClick={(e) => this.newProvenancePopup(tableMeta.rowData[0])}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  color="inherit"
                  className="text-danger"
                  size="small"
                  onClick={(e) => this.deleteProvenance(e, tableMeta)}
                >
                  <DeleteOutlineTwoToneIcon />
                </IconButton>
              </>
            );
          }
        }
      }];

    return columns;
  };

  deleteProvenance = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];
      await this.vehicleService.RemoveProvenance(id);

      await this.loadProvenance();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };


  newProvenancePopup = async (provenanceId: number | null) => {
    let newProvenance: Provenance;
    if (provenanceId == null) {
      newProvenance = { ...this.state.newProvenance } as Provenance;
    } else {
      newProvenance = this.state.provenances.find((item) => item.id === provenanceId)!;
    }
    this.setState({
      isDialogOpen: true,
      newProvenance: newProvenance,
      newProvenanceId: provenanceId
    });
  };

  closeDialog = async () => {
    this.setState(
      { 
        isDialogOpen: false, 
        newProvenance: this.newProvenance,
        newProvenanceId: null
      }
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton
              aria-label="add"
              color="primary"
              className="m-2"
              onClick={(e) => this.newProvenancePopup(null)}
            >
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
    
       <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.provenances}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>

        {this.state.isDialogOpen ? (
          <Dialog
            onClose={this.closeDialog}
            aria-labelledby="customized-dialog-title"
            open={this.state.isDialogOpen}
            fullScreen={false}
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title">
              <Grid container>
                <Grid item xs={11}>
                  <Typography className="MuiTypography-h4">
                  {!this.state.newProvenanceId ? 
                  this.translatorService.Tranlate(
                      'PROVENANCE_NEW_MODAL_TITLE',
                      'Adauga tip provenienta si cont contabil'
                    ) : this.translatorService.Tranlate(
                      'PROVENANCE_UPDATE_MODAL_TITLE',
                      'Actualizeaza tip provenienta si cont contabil'
                    )}        
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderAddForm()}
            </DialogContent>
          </Dialog>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(ProvenanceList as any));
