import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import axios from './AxiosInstance';

export class API {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async GetAsync<T, R = AxiosResponse<T>>(url: string, params?: any): Promise<R> {
    const headers = await this.GetHeaders();
    return axios.get(url, { ...headers, params });
  }

  public static async GetFileAsync<T, R = AxiosResponse<T>>(url: string): Promise<R> {
    const headers = await this.GetHeaders();
    headers.responseType = 'blob';

    return axios.get(url, headers);
  }

  public static async PostFileAsync<T, R>(url: string, data: T): Promise<AxiosResponse<R>> {
    const headers = await this.GetHeaders();
    headers.responseType = 'blob';

    return axios.post(url, data, headers);
  }

  // public async GetAsync(url: string): Promise<any> {

  //     let user = await this.authService.getUserAsync();

  //     if (!user) {
  //       throw new Error('user is not logged in');
  //     }

  //     if (!user.access_token) {
  //       user = await this.authService.renewTokenAsync();
  //     }

  //     try {
  //       return this.axiosInstance.get(url, this.GetHeaders(user.access_token));
  //     }
  //     catch (error) {
  //       if (error.response.status === 401) {
  //         let renewedUser = await this.authService.renewTokenAsync();
  //         return this.axiosInstance.get(url, this.GetHeaders(renewedUser.access_token))
  //       }
  //       throw error;
  //     }
  //   }

  public static async PostAsync<T, R = AxiosResponse<T>>(url: string, data: T): Promise<R> {
    return axios.post(url, data, await this.GetHeaders());
  }

  public static async Post2Async<T, R>(url: string, data: T): Promise<AxiosResponse<R>> {
    return axios.post(url, data, await this.GetHeaders());
  }

  public static async Post2WithCancelAsync<T, R>(
    url: string,
    data: T,
    cancelToken: CancelToken
  ): Promise<AxiosResponse<R>> {
    const headers = await this.GetHeaders();
    return axios.post(url, data, {
      ...headers,
      cancelToken
    });
  }

  public static async DeleteAsync<T, R = AxiosResponse<T>>(url: string, data?: T): Promise<R> {
    const headers = await this.GetHeaders();
    return axios.delete(url, { ...headers, data });
  }

  public static async PutAsync<T, R = AxiosResponse<T>>(url: string, data: T): Promise<R> {
    return axios.put(url, data, await this.GetHeaders());
  }

  private static async GetHeaders(): Promise<AxiosRequestConfig> {
    const access_token = localStorage.getItem('access_token');
    return {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token
      }
    } as AxiosRequestConfig;
  }
}
