import { Action, AnyAction, Reducer } from 'redux';
import * as ActionTypes from '../actions/ActionTypes';
import { AppointmentType } from '../../types/Agenda';

export interface InitialState {
  selectedRange: {
    start_date: Date;
    end_date: Date;
  } | null;
  appointments: AppointmentType[];
  selectedAppointment: AppointmentType | null;
  statusList: any,
  categoryType:any
}

const initialState: InitialState = {
  selectedRange: null,
  appointments: [],
  selectedAppointment: null,
  statusList:{},
  categoryType:[]
};

export const AgendaReducer: Reducer<InitialState | undefined> = (
  state: InitialState | undefined,
  incomingAction: Action
): InitialState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as AnyAction;

  switch (action.type) {
    case ActionTypes.AGENDA_SET_SELECTED_RANGE:
      return {
        ...state,
        selectedRange: action.payload
      };

    case ActionTypes.AGENDA_SET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload
      };

    case ActionTypes.AGENDA_SET_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.payload
      };
    case ActionTypes.AGENDA_SET_STATUS_LIST:
      return {
        ...state,
        statusList:action.payload
      }
    case ActionTypes.AGENDA_SET_CATEGORY_TYPE:
      return {
        ...state,
        categoryType: action.payload
      }
  }

  return state;
};
