import React, { useContext, useMemo } from 'react';
import MaterialTable from '@material-table/core';
import { getLocalization, MaterialTableIcons } from '../../_shared/TableSettings';
import { Paper } from '@material-ui/core';
import { AcquisitionBuyerStats } from '../../../interfaces/Dashboard/AcquisitionBuyerStats';
import { numberFormat } from '../../../utils/numberFormat';

import { ApplicationContext } from '../../../context/Contexts';

interface Props {
  title: string;
  topBuyers: AcquisitionBuyerStats[];
  includeVat: boolean;
  isTentative?: boolean;
}

const TopBuyers: React.FC<Props> = ({ topBuyers, includeVat, title, isTentative }) => {
  const context = useContext(ApplicationContext);

  const columns = useMemo(() => {
    const mainColumns: any = [
      {
        title: context.translatorService.Tranlate('DASHBOARD_ORGANIZATION', 'Organizatie'),
        field: 'organizationName'
      },
      {
        title: context.translatorService.Tranlate('DASHBOARD_BUYER_NAME', 'Cumparator'),
        field: 'buyerName'
      }
    ];

    if (isTentative) {
      mainColumns.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_IN_PROGRESS_TABLE_HEADER',
          'Achizitii in desfasurare'
        ),
        field: 'inProgressPurchasesCount'
      });
      mainColumns.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_ESTIMATED_VALUE',
          'Pret mediu estimat'
        ),
        render: (rowData: AcquisitionBuyerStats) => {
          if (!includeVat) {
            return rowData.inProgressPurchasesEstimatedAveragePrice
              ? numberFormat(rowData.inProgressPurchasesEstimatedAveragePrice, 0, undefined, ' EUR')
              : 0;
          } else {
            return rowData.inProgressPurchasesEstimatedAveragePriceInclVat
              ? numberFormat(
                  rowData.inProgressPurchasesEstimatedAveragePriceInclVat,
                  0,
                  undefined,
                  ' EUR'
                )
              : 0;
          }
        }
      });
    } else {
      mainColumns.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_FINALIZED_TABLE_HEADER',
          'Achizitii Finalizate'
        ),
        field: 'completedPurchases.count'
      });
      mainColumns.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_TOTAL_PAID',
          'Total Achitat'
        ),
        render: (rowData: AcquisitionBuyerStats) =>
          includeVat
            ? numberFormat(rowData.completedPurchases.amountInclVat, 0, undefined, ' EUR')
            : numberFormat(rowData.completedPurchases.amount, 0, undefined, ' EUR')
      });
      mainColumns.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_DAMAGE_AND_OTHER',
          'Daune + Alte costuri'
        ),
        render: (rowData: AcquisitionBuyerStats) =>
          includeVat
            ? numberFormat(rowData.damageAndOtherCostsInclVat, 0, undefined, ' EUR')
            : numberFormat(rowData.damageAndOtherCosts, 0, undefined, ' EUR')
      });
      mainColumns.push({
        title: context.translatorService.Tranlate(
          'DASHBOARD_ACQUISITION_AVERAGE_PRICE',
          'Pret Mediu Achizitie'
        ),
        render: (rowData: AcquisitionBuyerStats) => {
          const price = includeVat
            ? rowData.completedPurchases.amountInclVat
            : rowData.completedPurchases.amount;

          const averagePrice = price / rowData.completedPurchases.count;

          return numberFormat(averagePrice, 0, undefined, ' EUR');
        }
      });
    }

    return mainColumns;
  }, [includeVat, isTentative]);

  return (
    <>
      <h4>{title}</h4>
      <div style={{ maxWidth: '100%', height: '100%' }}>
        <MaterialTable
          icons={MaterialTableIcons}
          localization={getLocalization(context.translatorService)}
          columns={columns}
          data={topBuyers}
          title=""
          options={{
            search: false,
            toolbar: false
          }}
          components={{
            Container: (props) => <Paper {...props} className="h-100" />,
            Pagination: () => null
          }}
        />
      </div>
    </>
  );
};

export default TopBuyers;
