import React, { useMemo, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApplicationContext } from '../../../context/Contexts';
import { SalesOfferAndEmail } from '../../../interfaces/Dashboard/SalesOfferAndEmail';

interface Props {
  title: string;
  items: SalesOfferAndEmail[];
}

const SalesOfferEmailBarChart: React.FC<Props> = ({ title, items }) => {
  const context = useContext(ApplicationContext);
  const series = useMemo(() => {
    const offerGeneratedCountArray: number[] = [];
    const emailSentCountArray: number[] = [];
    items.forEach((item) => {
      offerGeneratedCountArray.push(item.offerGeneratedCount);
      emailSentCountArray.push(item.emailSentCount);
    });
    return [
      {
        name: context.translatorService.Tranlate('DASHBOARD_GENERATED_OFFERS', 'Oferte generate'),
        data: offerGeneratedCountArray
      },
      {
        name: context.translatorService.Tranlate(
          'DASHBOARD_GENERATED_OFFERS_EMAILS',
          'Oferte/Email transmise'
        ),
        data: emailSentCountArray
      }
    ];
  }, [context.translatorService, items]);

  const options = useMemo(() => {
    const categories = items.map((i) => i.date);
    return {
      chart: {
        type: 'bar',
        height: 500,
        toolbar: {
          show: false
        }
      },
      legend: {
        show: false
      },
      plotOptions: {
        bar: {
          borderRadius: 4
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return value.toFixed(0);
          }
        }
      }
    };
  }, [items]);

  return (
    <div>
      <h6 className="mt-3 ml-3 mb-0">{title}</h6>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default SalesOfferEmailBarChart;
