import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { CaseEventHistory } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import Form from '@rjsf/material-ui';
import { ISubmitEvent, FormValidation } from '@rjsf/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { FormButton } from '../../helpers/forms/FormButton';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { EventDetailsFieldsCode, ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import { AppUser } from '../../interfaces/AppUser';
import { CNP } from 'romanian-personal-identity-code-validator';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { Organization } from '../../interfaces/Organization';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isEmpty } from 'lodash';

interface ICaseDetailsCaseTabEventDetailsFormState {
  formData: CaseEventHistory;
  isLoading: boolean;
  personTypes: IReferential[];
  policyTypes: IReferential[];
  organizations: Organization[];
  hasRights: boolean;
  executing: boolean;
}
export interface ExternalCaseDetailsCaseTabEventDetailsFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabEventDetailsFormProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ExternalCaseDetailsCaseTabEventDetailsFormProps &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabEventDetailsForm extends React.PureComponent<
  ICaseDetailsCaseTabEventDetailsFormProps,
  ICaseDetailsCaseTabEventDetailsFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private organizationService!: IOrganizationService;

  static contextType = ApplicationContext;
  state = {
    formData: {} as CaseEventHistory,
    isLoading: true,
    personTypes: [],
    policyTypes: [],
    organizations: [],
    hasRights: false,
    executing: false
  } as ICaseDetailsCaseTabEventDetailsFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        console.log('je2re');

        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);

    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const refpersonType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PersonType
    );
    const refpolicyType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PolicyType
    );

    const [personTypeList, policyTypeLIst, organizationsList] = await Promise.all([
      this.appReferentialService.Get(refpersonType!.baseUrl),
      this.appReferentialService.Get(refpolicyType!.baseUrl),
      this.organizationService.GetOrganizationHierarchy(
        this.props.caseSettingsState.caseSettings?.workflow.organizationOwnerId || -1
      )
    ]);
    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    if (isNullOrUndefined(this.props.caseSettingsState.case!.caseEvent)) {
      this.setState({
        formData: {
          id: 0,
          caseId: caseId
        } as CaseEventHistory,
        isLoading: false,
        personTypes: personTypeList,
        policyTypes: policyTypeLIst,
        organizations: organizationsList,
        hasRights: hasRights
      });

      return;
    }

    const caseEvent = this.props.caseSettingsState.case!.caseEvent;

    if (!isNullOrUndefined(caseEvent.eventDate)) {
      caseEvent.eventDate = moment(caseEvent.eventDate).format(moment.HTML5_FMT.DATE);
    }
    if (!isNullOrUndefined(caseEvent.noticeDate)) {
      caseEvent.noticeDate = moment(caseEvent.noticeDate).format(moment.HTML5_FMT.DATE);
    }
    if (!isNullOrUndefined(caseEvent.claimReviewDate)) {
      caseEvent.claimReviewDate = moment(caseEvent.claimReviewDate).format(moment.HTML5_FMT.DATE);
    }

    for (const [key, value] of Object.entries(caseEvent)) {
      if (isNullOrUndefined(value)) {
        caseEvent[key] = undefined;
      }
    }

    this.setState({
      formData: caseEvent,
      isLoading: false,
      personTypes: personTypeList,
      policyTypes: policyTypeLIst,
      organizations: organizationsList,
      hasRights: hasRights
    });
  };

  handleArraySchema = (schema: any) => {
    if (isNullOrUndefined(schema.properties)) {
      return schema;
    }

    for (const key of Object.keys(schema.properties)) {
      if (
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf[0].title === 'CAR_POLICY_TYPE_ENUM'
      ) {
        schema.properties[key].anyOf = this.state.policyTypes.map((item) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
      }
      if (
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf[0].title === 'CAR_INSURED_PERSON_TYPE_ENUM'
      ) {
        schema.properties[key].anyOf = this.state.personTypes.map((item) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
      }
      if (
        schema.properties[key].anyOf !== undefined &&
        schema.properties[key].anyOf[0].title === 'ORGANIZATIONS_ENUM'
      ) {
        schema.properties[key].anyOf = this.state.organizations.map((item) => {
          return {
            type: 'number',
            title: item.displayName,
            enum: [item.id]
          };
        });
      }
    }

    return schema;
  };

  validate = (formData: CaseEventHistory, errors: FormValidation, formCode: string) => {
    if (new Date(formData.noticeDate) > new Date()) {
      errors.noticeDate.addError(
        this.translatorService.Tranlate(
          'FRM_EVENT_DATA_NOTICE_DATE_LESS_CURRENT_DATE_ERROR',
          'Data avizarii trebuie sa fie mica sau egala cu data curenta!'
        )
      );
    }
    if (new Date(formData.noticeDate) > new Date(formData.claimReviewDate)) {
      errors.noticeDate.addError(
        this.translatorService.Tranlate(
          'FRM_EVENT_DATA_NOTICE_DATE_LESS_CLAIM_REVIEW_DATE_ERROR',
          'Data avizarii trebuie sa fie mica sau egala cu data constatarii!'
        )
      );
    }
    if (new Date(formData.eventDate) > new Date(formData.noticeDate)) {
      errors.eventDate.addError(
        this.translatorService.Tranlate(
          'FRM_EVENT_DATA_EVENT_DATE_LESS_NOTICE_DATE_ERROR',
          'Data evenimentului trebuie sa fie mai mica sau egala cu data avizarii!'
        )
      );
    }

    if (!isNullOrUndefined(formData.pin)) {
      const cnp = new CNP(formData.pin);

      if (!cnp.isValid()) {
        errors.pin.addError(
          this.translatorService.Tranlate('FRM_EVENT_DATA_PIN_ERROR', 'CNP invalid!')
        );
      }
    }

    return errors;
  };

  validateCaseEventDetails = (schema: any) => {
    if (schema.required.includes(EventDetailsFieldsCode.CASENUMBER)) {
      if (
        isNullOrUndefined(this.state.formData.caseNumber) ||
        isEmpty(this.state.formData.caseNumber)
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'WARNING_COPY_CASE',
            'Va rog sa introduceti noul numar de dosar'
          ),
          {
            variant: 'warning'
          }
        );
      }
    }
  };

  onSubmit = async (e: ISubmitEvent<any>, code: string) => {
    try {
      this.setState({ executing: true });

      let data = e.formData as CaseEventHistory;
      data.caseId = this.props.caseSettingsState.case!.id;
      data.insuredPersonType = null;
      if (isNullOrUndefined(data.id)) {
        data.id = 0;
      }

      if (data.id === 0) {
        data = await this.caseService.AddCaseEvent(data);
      } else {
        await this.caseService.UpdateCaseEvent(data);
      }
      if (this.props.workflowForm.form.hasActions) {
        await this.submitForm(data.caseId, this.props.workflowForm.id, null);
      }

      this.props.SetCaseEvent(data);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }
    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  CustomDatePicker = (props: any) => {
    const { value, label, onChange, required } = props;
    const { language, dateFormat } = this.props.appState;
    return (
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
        <DatePicker
          className="m-0 pb-0"
          variant="inline"
          fullWidth
          label={label}
          autoOk={true}
          format={dateFormat.toUpperCase()}
          value={value || null}
          onChange={(date: MaterialUiPickersDate) => onChange(date?.format('YYYY-MM-DD'))}
          required={required}
        />
      </MuiPickersUtilsProvider>
    );
  };

  renderFormData = (form: WorkflowSectionStepForm) => {
    let jsonSchema = {};
    let jsonGUI = {};
    const widgets = {
      DateWidget: this.CustomDatePicker
    };
    try {
      jsonSchema = JSON.parse(form.json1);
      jsonGUI = JSON.parse(form.json2);
    } catch {
      console.log('json schema/gui error');
    }

    let schema = this.translatorService.TranslateSchema(jsonSchema);
    schema = this.handleArraySchema(jsonSchema);

    return (
      <div className="mx-5 px-5 pb-3">
        <Form
          schema={schema}
          disabled={!this.state.hasRights}
          uiSchema={jsonGUI}
          validate={(formData: CaseEventHistory, errors: FormValidation) =>
            this.validate(formData, errors, form.form.code)
          }
          liveValidate={true}
          showErrorList={false}
          formData={this.state.formData}
          onChange={(e) => this.setState({ formData: e.formData })}
          onSubmit={async (e: ISubmitEvent<any>) => await this.onSubmit(e, form.form.code)}
          transformErrors={this.translatorService.TranslateErrors}
          widgets={widgets}
        >
          <div
            className="text-center"
            onClick={() => {
              this.validateCaseEventDetails(schema);
            }}
          >
            {FormButton.GetButtons(
              form.form.code,
              this.translatorService,
              this.state.hasRights,
              this.state.executing
            )}
          </div>
        </Form>
      </div>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? <div>{this.renderFormData(this.props.workflowForm)}</div> : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabEventDetailsFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabEventDetailsForm as any));
