import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { Sidebar, Header } from '../../layout-components';

const LeftSidebar = (props) => {
  const { children, sidebarToggle, sidebarFixed, contentBackground } = props;

  return (
    <Fragment>
      <div className={clsx('app-wrapper', contentBackground)}>
        <Header />
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed
          })}
        >
          <Sidebar />
          <div
            className={clsx('app-content', {
              'app-content-sidebar-collapsed': sidebarToggle,
              'app-content-sidebar-fixed': sidebarFixed
            })}
          >
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.themeOptions.sidebarToggle,
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile,
  sidebarFixed: state.themeOptions.sidebarFixed,

  headerFixed: state.themeOptions.headerFixed,
  headerSearchHover: state.themeOptions.headerSearchHover,

  contentBackground: state.themeOptions.contentBackground,
  appState: state.app
});

export default connect(mapStateToProps)(LeftSidebar);
