import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsState, AppState, VehicleState } from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  CardContent,
  Card,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Divider,
  Input
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { CaseCalculationHistory, CaseCalculationPart, ManipulationTariffPercentResponse, TariffPercentResponse } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import Referentials from '../../helpers/Referentials.json';
import {
  ReferentialCode,
  ParametreCode,
  CalculationSectionCodeEnum
} from '../../helpers/Constants';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import {
  WorkflowCalculation,
  WorkflowOptimization,
  WorkflowSectionStepForm,
  WorkflowSupplier
} from '../../interfaces/Workflow';
import CaseDetailsAlternativeCalculationPieces from './CaseDetailsAlternativeCalculationPieces';
import { CalculationParts } from '../../interfaces/CalculationParts';
import * as R from 'ramda';
import { IReferential } from '../../interfaces/IReferential';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import { Order } from '../../interfaces/Order';
import { Utils } from '../../helpers/Utils';

export interface ExternalCaseDetailsAlternativeCalculationDetailsProps {
  calculationHistoryId: number;
  stepFormId: number;
  orderList: Order[];
  closeDialog: () => void;
  manipulationTariff: ManipulationTariffPercentResponse;
  insurerList: IReferential[];
  serviceId: number | null;
  additionTariff: TariffPercentResponse;
  discountTariff: TariffPercentResponse;
  packageCalculationId: number | null;
  nonStockSuppliers: WorkflowSupplier[];
}

interface ICaseDetailsAlternativeCalculationDetailsState {
  caseCalculationHistoryDetails: CaseCalculationHistory;
  isLoading: boolean;
  modified: boolean;
  workflowCalculation: WorkflowCalculation | undefined;
  workflowOptimization: WorkflowOptimization[];
  openMenuItem: any | null;
  totalAlternativeCalculation: number;
  totalAlternativeCalculationSmallParts: number;
  totalPartsAlternativeCalculation: number;
  TVA: string;
  calculationSectionList: IReferential[];
  setOptimizationType: number;
}

type ICaseDetailsAlternativeCalculationDetailsProps =
  ExternalCaseDetailsAlternativeCalculationDetailsProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
    vehicleState: VehicleState;
    workflowCalculation: WorkflowCalculation;
  } & ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsAlternativeCalculationDetails extends React.PureComponent<
  ICaseDetailsAlternativeCalculationDetailsProps,
  ICaseDetailsAlternativeCalculationDetailsState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;
  private appuserService!: IAppUserService;

  static contextType = ApplicationContext;
  state = {
    caseCalculationHistoryDetails: {} as CaseCalculationHistory,
    isLoading: true,
    modified: false,
    workflowCalculation: undefined,
    workflowOptimization: [],
    openMenuItem: null,
    totalAlternativeCalculation: 0,
    totalAlternativeCalculationSmallParts: 0,
    totalPartsAlternativeCalculation: 0,
    TVA: '',
    calculationSectionList: [],
    setOptimizationType: 0
  } as ICaseDetailsAlternativeCalculationDetailsState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (caseSettings === null || caseSettings === undefined || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCalculationDetails();
      }
    );
  }

  loadCalculationDetails = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (caseSettings === null || caseSettings === undefined || Number.isNaN(caseId)) {
      return;
    }
    const TVA = this.props.caseSettingsState.case!.caseParameters.find(
      (item) => item.parameter!.code === ParametreCode.TVA
    )!.value;
    const currencyRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.Currency
    );
    const [currencyLIst, caseCalculation, calculationSections] = await Promise.all([
      this.appReferentialService.Get(currencyRef!.baseUrl),
      this.props.calculationHistoryId
        ? this.caseService.GetCaseCalculationHistoryDetails(this.props.calculationHistoryId)
        : ({} as CaseCalculationHistory),
      this.caseService.GetCalculationSections()
    ]);

    const form = this.getWorkflowForm(this.props.stepFormId);

    const workflowCalculation = form!.workflowCalculations.find(
      (item) =>
        item.workflowPartner!.partnerId === this.props.appState.appUser!.hoId &&
        (item.userRoleId === this.props.appState.appUser!.roleId || R.isNil(item.userRoleId)) &&
        item.caseStatusId === this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    const workflowOptimization = form!.workflowOptimizations.filter(
      (item) =>
        item.workflowPartner!.partnerId === this.props.appState.appUser!.hoId &&
        (item.userRoleId === this.props.appState.appUser!.roleId || R.isNil(item.userRoleId)) &&
        item.caseStatusId === this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    caseCalculation.currency = currencyLIst.find(
      (item) => item.code === caseCalculation.currencyCode
    )!;

    this.setState({
      caseCalculationHistoryDetails: caseCalculation,
      workflowCalculation: workflowCalculation,
      workflowOptimization: workflowOptimization,
      totalAlternativeCalculation: caseCalculation.amountWithoutTVA,
      totalPartsAlternativeCalculation: caseCalculation.amountPiecesWithDiscount,
      totalAlternativeCalculationSmallParts: caseCalculation.smallPartsValue,
      TVA: TVA,
      calculationSectionList: calculationSections,
      isLoading: false
    });
  };

  getWorkflowForm = (id: number): WorkflowSectionStepForm | null => {
    const sections = this.props.caseSettingsState.caseSettings!.workflow.workflowSections!;
    for (const section of sections) {
      for (const step of section.workflowSectionSteps) {
        const form = step.workflowSectionStepForms.find((form) => form.id === id);
        if (!R.isNil(form)) {
          return form;
        }
      }
    }

    return null;
  };

  closeDialog = async () => {
    this.props.closeDialog();
  };

  handleTml = (e: React.ChangeEvent<HTMLInputElement>) => {
    const workflowCalculation = {
      ...this.state.workflowCalculation,
      defaultMaxDeliveryTime: Number.parseInt(e.target.value)
    } as WorkflowCalculation;
    this.setState({ workflowCalculation: workflowCalculation });
  };

  displayValueWithCurrency = (value: number | null) => {
    let result = '0';
    if (!R.isNil(value)) {
      result = value.toFixed(2);
    }
    if (isNaN(Number(result))) {
      result = '0';
    }
    let currency = '';
    if (this.state.caseCalculationHistoryDetails.currency) {
      currency = this.state.caseCalculationHistoryDetails.currency?.displayName;
    }

    return Utils.countryNumberFormat(result, this.props.appState.appUser?.organization?.country ?? null) + ' ' + currency;
  };

  renderSection1 = () => {
    return (
      <Card>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div>
            <CardContent className="p-3">
              <div className="d-flex align-items-center p-0">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex flex-column">
                            <div className="text-black font-weight-bold">
                              <FormControlLabel
                                className="d-flex justify-content-between w-100 m-0 "
                                control={
                                  <Input
                                    className="ml-1"
                                    disabled={
                                      R.isNil(this.state.workflowCalculation)
                                        ? true
                                        : this.state.workflowCalculation.tmlEditable
                                        ? false
                                        : true
                                    }
                                    type="number"
                                    id="defaultMaxDeliveryTime"
                                    value={
                                      R.isNil(this.state.workflowCalculation) ||
                                      R.isNil(this.state.workflowCalculation.defaultMaxDeliveryTime)
                                        ? 0
                                        : this.state.workflowCalculation.defaultMaxDeliveryTime
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      this.handleTml(e);
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body2"
                                    className="font-weight-bold text-black"
                                    align="left"
                                  >
                                    {this.translatorService.Tranlate(
                                      'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_TML_LABEL',
                                      'Timp maxim de livrare'
                                    )}
                                  </Typography>
                                }
                                labelPlacement="start"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} className="align-left">
                    <h6>
                      {VehicleHelper.GetBrandModel(
                        this.props.caseSettingsState.case,
                        this.props.vehicleState.vehicle
                      )}
                    </h6>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className="font-size-sm text-black">
                              {this.translatorService.Tranlate('CALCULATION_DETAILS_UMC', 'UMC')}
                            </div>
                            <div className="font-size-sm text-black">
                              {this.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'VIN')}
                            </div>
                            <div className="font-size-sm text-black">
                              {this.translatorService.Tranlate(
                                'CALCULATION_DETAILS_VEHICLE_MANUFACTURE_DATE',
                                'Data fabricatie'
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="font-size-sm text-black">
                              {VehicleHelper.GetUMC(
                                this.props.caseSettingsState.case,
                                this.props.vehicleState.vehicle
                              )}
                            </div>
                            <div className="font-size-sm text-black">
                              {VehicleHelper.GetVIN(
                                this.props.caseSettingsState.case,
                                this.props.vehicleState.vehicle
                              )}
                            </div>
                            <div className="font-size-sm text-black">
                              {VehicleHelper.GetMakeDate(
                                this.props.caseSettingsState.case,
                                this.props.vehicleState.vehicle,
                                this.props.appState.language
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={7}>
                            <div className="font-size-sm text-black">
                              {this.translatorService.Tranlate(
                                'CALCULATION_DETAILS_VEHICAL_PLATE_NUMBER',
                                'Numar inmatriculare'
                              )}
                            </div>
                            <div className="font-size-sm text-black">
                              {this.translatorService.Tranlate(
                                'CALCULATION_DETAILS_VEHICAL_FIRST_REGISTRATION_DATE',
                                'Data primei inmatriculari'
                              )}
                            </div>
                            <div className="font-size-sm text-black">
                              {this.translatorService.Tranlate(
                                'CALCULATION_DETAILS_VEHICAL_NUMBER_OF_KM',
                                'Numar kilometri'
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={5}>
                            <div className="font-size-sm text-black">
                              {VehicleHelper.GetPlateNumber(
                                this.props.caseSettingsState.case,
                                this.props.vehicleState.vehicle
                              )}
                            </div>
                            <div className="font-size-sm text-black">
                              {VehicleHelper.GetFirstRegistrationDate(
                                this.props.caseSettingsState.case,
                                this.props.vehicleState.vehicle,
                                this.props.appState.language
                              )}
                            </div>
                            <div className="font-size-sm text-black">
                              {VehicleHelper.GetKmNr(
                                this.props.caseSettingsState.case,
                                this.props.vehicleState.vehicle
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="font-weight-bold text-warning font-size-xl ml-4">
                  {this.state.workflowOptimization.length !== 0 ? (
                    <div>
                      {this.state.workflowOptimization.map((item) => {
                        return (
                          <Button
                            onClick={() =>
                              this.setState({ setOptimizationType: item.optimizationTypeId })
                            }
                            key={item.id}
                            className="m-2"
                            variant="contained"
                            color="primary"
                          >
                            {item.optimizationType!.displayName}
                          </Button>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </CardContent>
            <Divider />

            <CardContent className="p-3">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-black mr-2 font-weight-bold">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_ORIGINAL_CALC_TOTAL',
                              'Total calculatie originala'
                            )}
                            :
                          </div>
                          <div className="text-black font-weight-bold">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_ALT_CALC_TOTAL',
                              'Total calculatie alternativa'
                            )}
                            :
                          </div>
                          <div className="text-black font-weight-bold">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CALC_ECONOMY',
                              'Economisire calculatie'
                            )}
                            :
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="text-black font-weight-bold">
                            {this.displayValueWithCurrency(
                              Math.round(
                                this.state.caseCalculationHistoryDetails.amountWithoutTVA * 100
                              ) / 100
                            )}
                          </div>
                          <div className="text-black font-weight-bold">
                            {this.displayValueWithCurrency(this.state.totalAlternativeCalculation)}
                          </div>
                          <div
                            className={`font-weight-bold text-${
                              parseInt(
                                (
                                  (this.state.totalAlternativeCalculation /
                                    this.state.caseCalculationHistoryDetails.amountWithoutTVA -
                                    1) *
                                  100 *
                                  -1
                                ).toFixed(2)
                              ) >= 0
                                ? 'success'
                                : 'danger'
                            }`}
                          >
                            {isNaN(
                              parseInt(
                                (
                                  (this.state.totalAlternativeCalculation /
                                    this.state.caseCalculationHistoryDetails.amountWithoutTVA -
                                    1) *
                                  100 *
                                  -1
                                ).toFixed(2)
                              )
                            )
                              ? 0.0
                              : (
                                  (this.state.totalAlternativeCalculation /
                                    this.state.caseCalculationHistoryDetails.amountWithoutTVA -
                                    1) *
                                  100 *
                                  -1
                                ).toFixed(2)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="d-flex justify-content-between float-right">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                          <div className="text-black mr-2 font-weight-bold">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_SMALL_PIECES_ORIGINAL',
                              'Piese mici originala'
                            )}
                            :
                          </div>
                          <div className="text-black mr-2 font-weight-bold">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_SMALL_PIECES',
                              'Piese mici alternativa'
                            )}
                            :
                          </div>
                          <div className="text-black mr-2 font-weight-bold">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_ORIGINAL_CALC_PARTS_TOTAL',
                              'Total piese calculatie originala'
                            )}
                            :
                          </div>
                          <div className="text-black font-weight-bold">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_ALT_CALC_PARTS_TOTAL',
                              'Total piese calculatie alternativa'
                            )}
                            :
                          </div>
                          <div className="text-black font-weight-bold">
                            {this.translatorService.Tranlate(
                              'CASE_CALCULATION_ALTERNATIVE_CALCULATION_DETAILS_CALC_PARTS_ECONOMY',
                              'Economisire piese'
                            )}
                            :
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="text-black font-weight-bold">
                            {this.displayValueWithCurrency(
                              this.state.caseCalculationHistoryDetails.smallPartsValue
                            )}
                          </div>
                          <div className="text-black font-weight-bold">
                            {this.displayValueWithCurrency(
                              this.state.totalAlternativeCalculationSmallParts
                            )}
                          </div>
                          <div className="text-black font-weight-bold">
                            {this.displayValueWithCurrency(
                              this.state.caseCalculationHistoryDetails.amountPiecesWithDiscount
                            )}
                          </div>
                          <div className="text-black font-weight-bold">
                            {this.displayValueWithCurrency(
                              this.state.totalPartsAlternativeCalculation
                            )}
                          </div>
                          <div
                            className={`font-weight-bold text-${
                              parseInt(
                                (
                                  (this.state.totalPartsAlternativeCalculation /
                                    this.state.caseCalculationHistoryDetails
                                      .amountPiecesWithDiscount -
                                    1) *
                                  100 *
                                  -1
                                ).toFixed(2)
                              ) >= 0
                                ? 'success'
                                : 'danger'
                            }`}
                          >
                            {isNaN(
                              parseInt(
                                (
                                  (this.state.totalPartsAlternativeCalculation /
                                    this.state.caseCalculationHistoryDetails
                                      .amountPiecesWithDiscount -
                                    1) *
                                  100 *
                                  -1
                                ).toFixed(2)
                              )
                            )
                              ? 0.0
                              : (
                                  (this.state.totalPartsAlternativeCalculation /
                                    this.state.caseCalculationHistoryDetails
                                      .amountPiecesWithDiscount -
                                    1) *
                                  100 *
                                  -1
                                ).toFixed(2)}
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        ) : null}
      </Card>
    );
  };

  getPackageFromCalculationParts = (calculationParts: CaseCalculationPart[]) => {
    const newcalculationParts = [];
    for (let i = 0; i < calculationParts.length; i++) {
      if (calculationParts[i].package && calculationParts[i].package.length) {
        for (let j = 0; j < calculationParts[i].package.length; j++) {
          newcalculationParts.push(calculationParts[i].package[j]);
        }
      }
    }
    return newcalculationParts;
  };

  updateAmount = (calculationParts: CaseCalculationPart[]) => {
    const originalCalculationPartsList = new CalculationParts(
      this.state.calculationSectionList,
      calculationParts,
      parseInt(this.state.TVA),
      this.state.caseCalculationHistoryDetails.smallPartsPercent,
      true
    );

    const alternativeCalculationPartsList = new CalculationParts(
      this.state.calculationSectionList,
      calculationParts.concat(this.getPackageFromCalculationParts(calculationParts)),
      parseInt(this.state.TVA),
      this.state.caseCalculationHistoryDetails.smallPartsPercent,
      false
    );

    this.setState({
      caseCalculationHistoryDetails: {
        ...this.state.caseCalculationHistoryDetails,
        amountPieces: originalCalculationPartsList.GetAmountPieceTotal(),
        amountLabor: originalCalculationPartsList.GetAmountPart(CalculationSectionCodeEnum.LABOR),
        amountPaintingMaterials: originalCalculationPartsList.GetAmountPart(
          CalculationSectionCodeEnum.PAINT
        ),
        amountPaintingLabor: originalCalculationPartsList.GetAmountPart(
          CalculationSectionCodeEnum.PAINT_LABOR
        ),
        amountPiecesWithDiscount: originalCalculationPartsList.GetAmountPartWithDiscount(
          CalculationSectionCodeEnum.PART
        ),
        amountLaborWithDiscount: originalCalculationPartsList.GetAmountPartWithDiscount(
          CalculationSectionCodeEnum.LABOR
        ),
        amountPaintingMaterialsWithDiscount: originalCalculationPartsList.GetAmountPartWithDiscount(
          CalculationSectionCodeEnum.PAINT
        ),
        amountPaintingLaborWithDiscount: originalCalculationPartsList.GetAmountPartWithDiscount(
          CalculationSectionCodeEnum.PAINT_LABOR
        ),
        amountDiscount: originalCalculationPartsList.TotalDiscount(),
        amountWithoutTVA: originalCalculationPartsList.TotalWithoutVAT(),
        amountTVA: originalCalculationPartsList.TotalVAT(),
        amount: originalCalculationPartsList.Total()
      },
      totalAlternativeCalculation: alternativeCalculationPartsList.TotalWithoutVAT(),
      totalPartsAlternativeCalculation: alternativeCalculationPartsList.GetAmountPartWithDiscount(
        CalculationSectionCodeEnum.PART
      ),
      totalAlternativeCalculationSmallParts: alternativeCalculationPartsList.GetSmallPartsValue()
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appuserService = (this.context as AppContext).appUserService;

    return (
      <Fragment>
        {this.renderSection1()}
        <Card className="mt-2">
          <CaseDetailsAlternativeCalculationPieces
            {...this.props}
            calculationHistoryId={this.props.calculationHistoryId}
            workflowCalculation={this.state.workflowCalculation}
            handleUpdateAlternativeCalcAmount={(calculationParts) =>
              this.updateAmount(calculationParts)
            }
            defaultMaxDeliveryTime={
              R.isNil(this.state.workflowCalculation) ||
              R.isNil(this.state.workflowCalculation.defaultMaxDeliveryTime)
                ? 0
                : this.state.workflowCalculation.defaultMaxDeliveryTime
            }
            optmizationType={this.state.setOptimizationType}
            resetOptimizatonType={() => this.setState({ setOptimizationType: 0 })}
            onSave={this.closeDialog}
            orderList={this.props.orderList}
            manipulationTariff={this.props.manipulationTariff}
            insurerList={this.props.insurerList}
            serviceId={this.props.serviceId}
            additionTariff={this.props.additionTariff}
            discountTariff={this.props.discountTariff}
            packageCalculationId={this.props.packageCalculationId}
            nonStockSuppliers={this.props.nonStockSuppliers}
          />
        </Card>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState, ownProps: ExternalCaseDetailsAlternativeCalculationDetailsProps) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  null
)(withSnackbar(CaseDetailsAlternativeCalculationDetails as any));
