import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import moment from 'moment';
import { CalculationTypeCode } from '../../../helpers/Constants';
import { RouteComponentProps } from 'react-router';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { CaseCalculationHistory } from '../../../interfaces/Case';
import FileSaver from 'file-saver';

interface IGtXMLState {
  caseId: string;
  isLoading: boolean;
  calculations: CaseCalculationHistory[];
}

type GtXMLProps = { appState: AppState } & ProviderContext & RouteComponentProps<{ id: string }>;

class GtXML extends React.PureComponent<GtXMLProps, IGtXMLState> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    caseId: '',
    calculations: [] as CaseCalculationHistory[]
  } as IGtXMLState;

  public componentDidMount() {
    this.setState({
      isLoading: false
    });
  }

  private handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      caseId: event.target.value
    });
  };

  renderCalculations = () => {
    return (
      <Grid container>
        {this.state.calculations.map((calc) => (
          <React.Fragment key={calc.id}>
            <Grid
              item
              xs={2}
              style={{ color: '#007bff', cursor: 'pointer' }}
              onClick={(e) => this.downloadXml(calc.id)}
            >
              <span className="font-size-sm">{calc.id}</span>
            </Grid>
            <Grid item xs={3}>
              {moment.utc(calc.date).local().toDate().toLocaleString(this.props.appState.language)}
            </Grid>
            <Grid item xs={7}>
              {calc.amount}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  downloadXml = async (id: number) => {
    const fileBlob = await this.caseService.GetCaseCalculationXML(
      Number.parseInt(this.state.caseId),
      id
    );

    FileSaver.saveAs(fileBlob, `GtResponse.xml`);
  };

  renderForms = () => {
    return (
      <React.Fragment>
        <TextField
          name="caseId"
          className="m-2"
          id="caseId"
          value={this.state.caseId}
          onChange={this.handleTextChange}
          label={this.translatorService.Tranlate('CaseID_LABEL', 'CaseID')}
        />
        <Button className="m-2" variant="outlined" color="primary" onClick={this.loadCalculations}>
          {this.translatorService.Tranlate('LOAD_CALCULATIONS_BTN', 'Load')}
        </Button>
        {this.renderCalculations()}
      </React.Fragment>
    );
  };

  loadCalculations = async () => {
    const calculations = await this.caseService.GetAdminCaseCalculationHistories(
      Number.parseInt(this.state.caseId)
    );

    this.setState({
      isLoading: false,
      calculations: calculations.filter(
        (item) => item.calculationType.code === CalculationTypeCode.ORG
      )
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box m={1} p={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderForms() : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(GtXML as any));
