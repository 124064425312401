import { Action, Reducer } from 'redux';
import { User } from 'oidc-client';
import { AppAction } from '../actions/App';
import * as ActionTypes from '../actions/ActionTypes';
import { AppUser } from '../../interfaces/AppUser';
import { Appointment, Case, CaseComment } from '../../interfaces/Case';
import { CurrencyRate } from '../../interfaces/Vehicle';

export interface AppState {
  user: User | null;
  appUser: AppUser | null;
  showMessage: boolean;
  language: string;
  dateFormat: string;
  longDateFormat: string;
  tasks: Case[];
  comments: CaseComment[];
  newInCases: Case[];
  isCommentsLoading: boolean;
  isTasksLoading: boolean;
  isNewInCasesLoading: boolean;
  currencyRates: CurrencyRate[];
  newInAppointments: Appointment[];
  isNewInAppointmentsLoading: boolean;
}

const initialState: AppState = {
  user: null,
  appUser: null,
  showMessage: false,
  language: '',
  dateFormat: '',
  longDateFormat: '',
  tasks: [],
  comments: [],
  newInCases: [],
  isCommentsLoading: false,
  isTasksLoading: false,
  isNewInCasesLoading: false,
  currencyRates: [],
  newInAppointments: [],
  isNewInAppointmentsLoading: false
};

export const AppReducer: Reducer<AppState | undefined> = (
  state: AppState | undefined,
  incomingAction: Action
): AppState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as AppAction;

  switch (action.type) {
    case ActionTypes.APP_SET_USER:
      return {
        ...state,
        user: action.user,
        appUser: action.appUser
      };
    case ActionTypes.APP_DISPLAY_MESSAGE:
      return {
        ...state,
        showMessage: action.display
      };
    case ActionTypes.APP_REMOVE_USER:
      return {
        ...state,
        user: null,
        appUser: null
      };

    case ActionTypes.APP_SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
        dateFormat: action.dateFormat,
        longDateFormat: action.longDateFormat
      };
    case ActionTypes.APP_LOAD_NOTIFICATION:
      return {
        ...state,
        tasks: action.tasks,
        comments: action.comments,
        newInCases: action.newInCases,
        newInAppointments: action.newInAppointments,
        isTasksLoading: false,
        isCommentsLoading: false,
        isNewInCasesLoading: false,
        isNewInAppointmentsLoading: false
      };
    case ActionTypes.APP_LOAD_TASKS:
      return {
        ...state,
        tasks: action.tasks,
        isTasksLoading: false
      };
    case ActionTypes.APP_LOAD_COMMENTS:
      return {
        ...state,
        comments: action.comments,
        isCommentsLoading: false
      };
    case ActionTypes.APP_REMOVE_COMMENT:
      const commentList = [...state.comments];
      const index = commentList.findIndex((item) => item.id === action.id);
      commentList.splice(index, 1);

      return {
        ...state,
        comments: commentList
      };
    case ActionTypes.APP_REMOVE_NEW_IN_CASE:
      const newInCases = [...state.newInCases];
      const indexNewIn = newInCases.findIndex((item) => item.id === action.id);
      newInCases.splice(indexNewIn, 1);

      return {
        ...state,
        newInCases: newInCases
      };
    case ActionTypes.APP_MARK_AS_VIEWED_NEW_IN_CASE:
        const cases = [...state.newInCases];
        const caseIndex = cases.findIndex((item) => item.id === action.id);
        cases[caseIndex].caseStatus.isViewed = true;
  
        return {
          ...state,
          newInCases: cases
    };
    case ActionTypes.APP_REMOVE_NEW_IN_APPOINTMENT:
      const newInAppointments = [...state.newInAppointments];
      const indexNewInAppointment = newInAppointments.findIndex((item) => item.id === action.id);
      newInAppointments.splice(indexNewInAppointment, 1);

      return {
        ...state,
        newInAppointments: newInAppointments
      };
    case ActionTypes.APP_MARK_AS_VIEWED_NEW_IN_APPOINTMENT:
      const appointments = [...state.newInAppointments];
      const appointmentIndex = appointments.findIndex((item) => item.id === action.id);
      appointments[appointmentIndex].appointmentStatusHistory.isViewed = true;

      return {
        ...state,
        newInAppointments: appointments
      };
    case ActionTypes.APP_REMOVE_TASK:
      const tasks = [...state.tasks];
      const indexTask = tasks.findIndex((item) => item.id === action.id);
      tasks.splice(indexTask, 1);

      return {
        ...state,
        tasks: tasks
      };
    case ActionTypes.APP_SET_NOTIFICATIONS_LOADERS:
      return {
        ...state,
        isCommentsLoading: action.isCommentsLoading,
        isTasksLoading: action.isTasksLoading,
        isNewInCasesLoading: action.isNewInCasesLoading,
        isNewInAppointmentsLoading: action.isNewInAppointmentsLoading
      };
    case ActionTypes.APP_SET_CURRENCY_RATES:
      return {
        ...state,
        currencyRates: action.currencyRates
      };
  }

  return state;
};
