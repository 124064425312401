import React from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { IconButton } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { OrganizationCaseTypeStepForm } from '../../interfaces/Organization';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import DoubleArrowTwoTone from '@material-ui/icons/DoubleArrowTwoTone';
import { SearchClientFormData } from '../../interfaces/FormData';
import { FormButton } from '../../helpers/forms/FormButton';
import { ClientDto } from '../../interfaces/Case';
import Form from '@rjsf/material-ui';
import { ISubmitEvent } from '@rjsf/core';
import { IReferential } from '../../interfaces/IReferential';
import { ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { CNP } from 'romanian-personal-identity-code-validator';
import * as R from 'ramda';
import { Button } from 'reactstrap';

interface ISearchClientFormState {
  clientDtoList: ClientDto[];
  isLoading: boolean;
  isLoadingData: boolean;
  formData: SearchClientFormData;
  personTypes: IReferential[];
  displayNextActionBtn: boolean;
}

export interface ExternalSearchClientFormProps {
  onClientSelected: (clientDto: ClientDto) => void;
  organizationCaseTypeStepForm: OrganizationCaseTypeStepForm;
}

type ISearchClientFormProps = ExternalSearchClientFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class SearchClientForm extends React.PureComponent<ISearchClientFormProps, ISearchClientFormState> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    clientDtoList: [],
    isLoading: true,
    isLoadingData: false,
    vehicleDtoList: [],
    formData: {} as SearchClientFormData,
    personTypes: [],
    displayNextActionBtn: false
  } as ISearchClientFormState;

  public async componentDidMount() {
    this.setState(
      {
        isLoading: false
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const refpersonType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.PersonType
    );
    const [personTypeList] = await Promise.all([
      this.appReferentialService.Get(refpersonType!.baseUrl)
    ]);

    this.setState({
      personTypes: personTypeList
    });
  };

  onSubmit = async () => {
    if (R.isNil(this.state.formData.cnpCui) && R.isNil(this.state.formData.personTypeId)) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(
          'SEARCH_NOTICE_EMPTY_FIELDS_MSG',
          'Completati cel putin un criteriu de cautare'
        ),
        { variant: 'info' }
      );
      return;
    }

    if (this.state.formData.personTypeId === 1) {
      if (!R.isNil(this.state.formData.cnpCui)) {
        const cnp = new CNP(this.state.formData.cnpCui);

        if (!cnp.isValid()) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate('FRM_EVENT_DATA_PIN_ERROR', 'CNP invalid!'),
            { variant: 'error' }
          );
          return;
        }
      }
    }

    this.setState({ isLoadingData: true });

    const clients = await this.caseService.SearchClient(
      this.state.formData.personTypeId,
      this.state.formData.cnpCui,
      this.state.formData.clientName
    );
    this.setState({
      isLoadingData: false,
      clientDtoList: clients,
      displayNextActionBtn: clients.length === 0
    });
  };

  onRowlick = async (e: any, rowData: any[]) => {
    const client = this.state.clientDtoList.find((item) => item.id === rowData[0]);
    this.props.onClientSelected(client!);
  };

  getColumns = () => {
    let columns;
    if (this.state.formData.personTypeId === 1) {
      columns = [
        {
          name: 'id',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: '',
          label: '',
          options: {
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
              return (
                <IconButton
                  className="m-0"
                  size="small"
                  onClick={async (e) => await this.onRowlick(e, tableMeta.rowData)}
                >
                  <DoubleArrowTwoTone />
                </IconButton>
              );
            }
          }
        },
        {
          name: 'personType.displayName',
          label: this.translatorService.Tranlate(
            'CLIENT_SEARCH_FORM_PERSON_TYPE_LABEL',
            'Tip Persoana'
          ),
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'pin',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_PIN_LABEL', 'CNP')
        },
        {
          name: 'firstName',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_FIRST_NAME_LABEL', 'Nume'),
          options: {
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta, updateValue: any) => {
              const client = this.state.clientDtoList.find(
                (item) => item.id === tableMeta.rowData[0]
              );
              return <div>{client!.firstName + ' ' + client!.lastName}</div>;
            }
          }
        },
        {
          name: 'lastName',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_LAST_NAME_LABEL', 'Nume'),
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'email',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_EMAIL_LABEL', 'E-mail')
        },
        {
          name: 'phone',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_PHONE_LABEL', 'Telefon')
        }
      ];
    } else {
      columns = [
        {
          name: 'id',
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: '',
          label: '',
          options: {
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
              return (
                <IconButton
                  className="m-0"
                  size="small"
                  onClick={async (e) => await this.onRowlick(e, tableMeta.rowData)}
                >
                  <DoubleArrowTwoTone />
                </IconButton>
              );
            }
          }
        },
        {
          name: 'personType.displayName',
          label: this.translatorService.Tranlate(
            'CLIENT_SEARCH_FORM_PERSON_TYPE_LABEL',
            'Tip Persoana'
          ),
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'companyNumber',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_COMPANY_NUMBER_LABEL', 'CUI')
        },
        {
          name: 'firstName',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_FIRST_NAME_LABEL', 'Nume'),
          options: {
            filter: false,
            customBodyRender: (value: any, tableMeta: MUIDataTableMeta, updateValue: any) => {
              const client = this.state.clientDtoList.find(
                (item) => item.id === tableMeta.rowData[0]
              );
              return <div>{client!.companyName}</div>;
            }
          }
        },
        {
          name: 'lastName',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_LAST_NAME_LABEL', 'Nume'),
          options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
        },
        {
          name: 'email',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_EMAIL_LABEL', 'E-mail')
        },
        {
          name: 'phone',
          label: this.translatorService.Tranlate('CLIENT_SEARCH_FORM_PHONE_LABEL', 'Telefon')
        }
      ];
    }

    return columns;
  };

  renderResults = () => {
    const options = {
      filter: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      // responsive: "stacked",
      elevation: 0,
      search: false,
      viewColumns: false,
      pagination: false
    };

    return (
      <div className="m-2">
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingData} />
        </div>
        {this.state.isLoadingData ? (
          ''
        ) : (
          <MUIDataTable
            title={''}
            data={this.state.clientDtoList}
            columns={this.getColumns()}
            options={options}
          />
        )}
      </div>
    );
  };

  handleArraySchema = (schema: any) => {
    if (R.isNil(schema.properties)) {
      return schema;
    }

    if (this.state.personTypes.length > 0) {
      for (const key of Object.keys(schema.properties)) {
        if (
          schema.properties[key].anyOf !== undefined &&
          schema.properties[key].anyOf[0].title === 'PERSON_TYPE_ENUM'
        ) {
          schema.properties[key].anyOf = this.state.personTypes.map((item) => {
            return {
              type: 'number',
              title: item.displayName,
              enum: [item.id]
            };
          });
        }
      }
    }

    return schema;
  };

  renderSelectedForm = () => {
    let jsonSchema = {};
    let jsonGUI = {};
    try {
      jsonSchema = JSON.parse(this.props.organizationCaseTypeStepForm.json1);
      jsonGUI = JSON.parse(this.props.organizationCaseTypeStepForm.json2);
    } catch {
      console.log('json schema/gui error');
    }
    let schema = this.translatorService.TranslateSchema(jsonSchema);
    schema = this.handleArraySchema(jsonSchema);

    return (
      <Form
        schema={schema}
        uiSchema={jsonGUI}
        liveValidate
        showErrorList={false}
        formData={this.state.formData}
        onChange={(e) => this.setState({ formData: e.formData })}
        onSubmit={async (e: ISubmitEvent<any>) => await this.onSubmit()}
        transformErrors={this.translatorService.TranslateErrors}
      >
        <div>
          {FormButton.GetButtons(
            this.props.organizationCaseTypeStepForm.form.code,
            this.translatorService,
            true,
            false
          )}
          {this.state.displayNextActionBtn ? (
            <Button
              className="m-2"
              variant="contained"
              color="primary"
              type="button"
              onClick={(e) => {
                this.props.onClientSelected({} as ClientDto);
              }}
            >
              {this.translatorService.Tranlate('SEARCH_NewClient', 'Client Nou')}
            </Button>
          ) : null}
        </div>
      </Form>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    return (
      <React.Fragment>
        {this.renderSelectedForm()}
        {this.state.isLoading ? '' : this.renderResults()}
      </React.Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalSearchClientFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(SearchClientForm as any));
