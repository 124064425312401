import React, { useContext, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApplicationContext } from '../../../context/Contexts';
import { SalesAdvertAndVehicleList } from '../../../interfaces/Dashboard/SalesAdvertAndVehicleList';

interface Props {
  title: string;
  items: SalesAdvertAndVehicleList[];
}

const VehicleAddSalesChart: React.FC<Props> = ({ title, items }) => {
  const context = useContext(ApplicationContext);
  const series = useMemo(() => {
    const advertsCountArray: number[] = [];
    const soldVehiclesCountArray: number[] = [];
    items.forEach((item) => {
      advertsCountArray.push(item.advertCount);
      soldVehiclesCountArray.push(item.soldVehicleCount);
    });
    return [
      {
        name: context.translatorService.Tranlate(
          'PUBLISHED_ANNOUNCEMENTS',
          'Anunturi publicate'
        ),
        data: advertsCountArray
      },
      {
        name: context.translatorService.Tranlate(
          'CARS_SOLD',
          'Masini vandute',
        ),
        data: soldVehiclesCountArray
      }
    ];
  }, [items]);

  const options = useMemo(() => {
    const categories = items.map((i) => i.date);
    return {
      chart: {
        type: 'line',
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories
      },
      yaxis: {
        labels: {
          formatter: (value: any) => {
            return value.toFixed(0);
          }
        }
      }
    };
  }, [items, 
    context.translatorService.Tranlate(
    'PUBLISHED_ANNOUNCEMENTS',
    'Anunturi publicate'
  ),
  context.translatorService.Tranlate(
    'CARS_SOLD',
    'Masini vandute',
  )]);

  return (
    <div className="pb-2">
      <h5 className="mt-3 ml-3 mb-0">{title}</h5>
      <ReactApexChart options={options} series={series} type="line" height={600} />
    </div>
  );
};

export default VehicleAddSalesChart;
