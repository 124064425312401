import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Typography, Box } from '@material-ui/core';
import { getClientListRequest } from '../agenda/agenda.requests';
import { Client } from '../agenda/agenda.types'
import { TranslatorService } from '../../services/TranslatorService';

interface ClientAutocompleteProps {
  value: Client | null,
  disabled?:boolean,
  error?:boolean;
  onChange?: (event: React.ChangeEvent<unknown>, selectedOption: Client | null) => void;
}

const ClientAutocomplete: React.FC<ClientAutocompleteProps> = ({value, onChange, disabled, error }) => {
  const translatorService = new TranslatorService();
  const [clients, setClients] = useState<Client[]>([]);
  const getClientList = async () => {
    const response = await getClientListRequest();
    const responseData = response.data as Client[];
    if(value && !value?.id){
      setClients([...responseData, value])
    }else{
      setClients(responseData);
    }
    
  };

  const getLabel = (option:Client) =>{
    if (option.personTypeId === 1) {
      return !option.firstName && !option.lastName ? `${option.email}` : `${option.firstName} ${option.lastName || ''}`
    } else if (option.personTypeId === 2) {
        return  option.companyName
    }
    return '';
  }

  useEffect(() => {
    getClientList();
  }, []);

  return (
    <Box>
      <Typography variant="h5" style={{ marginBottom: '10px', color: error ? '#f83245':'black'}}>
        {translatorService.Tranlate('APPOINTMENT_CLIENT_TITLE','Client')}
      </Typography>
    
      <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <Autocomplete
          id="selected-client"
          disabled={disabled}
          value={value}
          options={clients}
          getOptionLabel={(client) => getLabel(client) ||""}
          onChange={(e: any, newValue: Client | null)=>{ onChange && onChange(e, newValue)}}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translatorService.Tranlate('APPOINTMENT_SELECT_CLIENT_LABEL', 'Select client')}
              helperText={error ? translatorService.Tranlate(
                'VALIDATORS_REQUIRED',
                'Campul este obligatoriu'
              ) :''}
              error={error}
              variant="outlined"
              style={{ width: 300 }}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default ClientAutocomplete;
