import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DonutChartItem } from '../../interfaces/Radar/DonutChartItem';
import { MarketData } from '../../interfaces/Radar/MarketData';
import uniqBy from 'lodash/uniqBy';

interface Props {
  title: string;
  property: string;
  filteredMarketData: MarketData[];
  handleDonutClick(dataIndex?: number): void;
}

const RadarDonutChart: React.FC<Props> = ({
  title,
  property,
  filteredMarketData,
  handleDonutClick
}) => {
  const items = useMemo(() => {
    const sources = uniqBy(filteredMarketData, property);

    const items: DonutChartItem[] = [];
    for (const item of sources) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = item[property];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const count = filteredMarketData.filter((x: MarketData) => x[property] === value).length;

      items.push({ id: item.id, name: value, value: count });
    }

    return items;
  }, [filteredMarketData, property]);

  const series = useMemo(() => {
    return items.map((i) => i.value);
  }, [items]);

  const options = useMemo(() => {
    const labels = items.map((i) => i.name);
    return {
      chart: {
        type: 'donut',
        events: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            if (!isNil(config.dataPointIndex)) {
              handleDonutClick(config.dataPointIndex);
            }
          }
        }
      },
      labels,
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };
  }, [handleDonutClick, items]);

  return (
    <div className="pb-2">
      <h5 className="mt-3 ml-3 mb-0">{title}</h5>
      <ReactApexChart options={options} series={series} type="donut" height={500} />
    </div>
  );
};

export default RadarDonutChart;
