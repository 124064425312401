import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, Typography, Grid, Box } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { Workflow } from '../../interfaces/Workflow';
import {  OrganizationTypeCode } from '../../helpers/Constants';
import { Organization, OrganizationCaseType } from '../../interfaces/Organization';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { Case, ClientDto, VehicleDto } from '../../interfaces/Case';
import { IReferential } from '../../interfaces/IReferential';
import _ from 'lodash';

interface IWorkflowstate {
  scopeWorkflows: Workflow[];
  selectedWorkflow: Workflow | null;
  isLoading: boolean;
}
export interface ExternalWorkflowsProps {
  selectedOrganizationCaseType: OrganizationCaseType | null;
  selectedNotice: { id: number; orgId: number; companyNumber: string } | null;
  selectedCase: Case | null;
  selectedVehicle: VehicleDto | null;
  selectedClient: ClientDto | null;
  workflows: Workflow[];
  caseStatuses: IReferential[];
  organization: Organization | null;
}

type IWorkflowsProps = {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ExternalWorkflowsProps &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class Workflows extends React.PureComponent<IWorkflowsProps, IWorkflowstate> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;
  state = {
    scopeWorkflows: [],
    selectedWorkflow: null,
    isLoading: true
  } as IWorkflowstate;

  public async componentDidMount() {
    let scopeWorkflows = this.props.workflows.filter(
      (item) => item.caseTypeId === this.props.selectedOrganizationCaseType!.caseTypeId
    );

    if (!isNullOrUndefined(this.props.selectedNotice)) {
      scopeWorkflows = scopeWorkflows.filter(
        (item) => item.organizationOwnerId === this.props.selectedNotice!.orgId
      );
      if (
        !isNullOrUndefined(this.props.selectedNotice!.companyNumber) &&
        this.props.selectedNotice!.companyNumber !== ''
      ) {
        const organization = await this.organizationService.GetByCompanyNumber(
          this.props.selectedNotice.companyNumber
        );
        if (
          !isNullOrUndefined(organization) &&
          organization.organizationType!.code === OrganizationTypeCode.LEASING
        ) {
          scopeWorkflows = scopeWorkflows.filter(
            (item) =>
              item.organizationOwnerId === organization.id ||
              item.workflowPartners.findIndex(
                (item) => item.partnerId === organization.id && item.isActive
              ) !== -1
          );
        }
      }

      this.setState({
        scopeWorkflows: scopeWorkflows,
        isLoading: false
      });
      return;
    }

    if (this.props.organization == null) {
      this.setState({
        scopeWorkflows: scopeWorkflows,
        isLoading: false
      });
      return;
    }

    console.log('before scopeWorkflows', scopeWorkflows);
    console.log('organization', this.props.organization);

    //Acolo unde  o organizatie are mai multe fluxuri de lucru dar are si parteneri,
    //parteneri care nu sunt adaugati  pe toate aceste fluxuri  trebuie sa avem  posibilitatea de setare pentru afisare 
    //sau nu a fluxurilor organizatiei proprietare
    if (this.props.organization.hideOrganizationOwnerWorkflows)  {
      scopeWorkflows = scopeWorkflows.filter(
        (item) =>
          item.workflowPartners.findIndex(
            (item) => item.partnerId === this.props.organization!.id && item.isActive && !item.hideWorkflow
          ) !== -1
      );
    } else {
      scopeWorkflows = scopeWorkflows.filter(
        (item) =>
          //nu e partener pe fluxul de lucru
          item.workflowPartners.findIndex(
            (item) => item.partnerId === this.props.organization!.id
          ) === -1           
          ||
          //este partener pe fluxul de lucru si este activ si nu are flag-ul de ascundere setat
          item.workflowPartners.findIndex(
            (item) => item.partnerId === this.props.organization!.id && item.isActive && !item.hideWorkflow
          ) !== -1 
      );
    }
    console.log('after scopeWorkflows', scopeWorkflows);

    this.setState({
      scopeWorkflows: scopeWorkflows,
      isLoading: false
    });
  }

  createCase = async () => {
    if (isNullOrUndefined(this.state.selectedWorkflow)) {
      return;
    }

    console.log('selectedWorkflow', this.state.selectedWorkflow);
    console.log('is PRC Integration', this.state.selectedWorkflow.isPrcIntegration);
    try {
      this.setState({ isLoading: true });
      let newCase = this.props.selectedCase;
      if (this.props.selectedCase !== null) {
        console.log(this.props.selectedCase);
        await this.caseService.ChangeCaseWorkflow(
          this.state.selectedWorkflow.id,
          this.props.selectedCase.id,
          this.props.appState.appUser!.id!,
          this.props.selectedCase.caseStatus.caseStatusId
        );
        
      } else {
        console.log('Selected Vehicle', this.props.selectedVehicle);
        newCase = await this.caseService.CreateCase(
          this.state.selectedWorkflow.id,
          isNullOrUndefined(this.props.selectedNotice) ? null : this.props.selectedNotice.id,
          this.props.selectedVehicle,
          isNullOrUndefined(this.props.selectedClient) ? null : this.props.selectedClient.id
          
        );
      }

      this.props.history.push('/cases/' + newCase!.id, 'Case xx');
    } catch (ex) {
      this.setState({ isLoading: false });
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate(ex.response.data, 'Eroare creare dosar!'),
        { 
          variant: 'error' , 
          autoHideDuration: 5000
        }
      );
      //console.log(ex.response.data);
    }
  };

  renderWorkflowList = () => {
    let selectedItem = -1;
    if (!isNullOrUndefined(this.state.selectedWorkflow)) {
      selectedItem = this.state.selectedWorkflow.id;
    }

    const organizations: Organization[] = [];
    this.state.scopeWorkflows.forEach((w) => {
      if (organizations.find((item) => item.id === w.organizationOwnerId) === undefined) {
        organizations.push(w.organizationOwner!);
      }
    });

    return organizations.map((org, index) => (
      <React.Fragment key={index}>
        <Typography className="text-left m-2 " component="h5" variant="h5" color="secondary">
          {org.displayName}
        </Typography>
        <Grid container spacing={0}>
          {this.state.scopeWorkflows
            .filter((w) => w.organizationOwnerId === org.id)
            .map((item, index) => (
              <Grid item sm={3} xl={3} key={index} className="m-2">
                <Box
                  height={80}
                  key={index}
                  border={1}
                  borderColor="text.disabled"
                  width="95%"
                  borderRadius="0%"
                  bgcolor={selectedItem === item.id ? 'secondary.main' : ''}
                >
                  <Button
                    key={index}
                    className={selectedItem === item.id ? 'text-white h-100 ' : 'text-dark h-100'}
                    onClick={() => {
                      this.setState({ selectedWorkflow: item });
                    }}
                  >
                    <b>{item.displayName}</b>
                  </Button>
                </Box>
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    ));
  };

  renderWorkflows = () => {
    return (
      <React.Fragment>
        {this.renderWorkflowList()}
        <Button className="m-2" variant="contained" color="primary" onClick={this.createCase}>
          {this.translatorService.Tranlate('NEW_CASE_CREATE_CASE', 'Creeaza dosar')}
        </Button>
      </React.Fragment>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? <div>{this.renderWorkflows()}</div> : null}
      </Fragment>
    );
  }
}

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: ExternalWorkflowsProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(Workflows as any));
