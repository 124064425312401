import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  CaseSettingsState
} from '../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Card, Grid } from '@material-ui/core';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { withSnackbar, ProviderContext } from 'notistack';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { ReferentialCode } from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { CaseTariffValueHistory } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { ScaleLoader } from 'react-spinners';

interface ITariffPropertiesState {
  caseTariffValues: CaseTariffValueHistory[];
  tariffTypeGroupList: IReferential[];
  isLoading: boolean;
}

export interface ExternalEditTariffProps {
  caseTariffHistoryId: number;
}

type TariffPropertiesProps = { caseSettingsState: CaseSettingsState } & ProviderContext &
  ExternalEditTariffProps &
  RouteComponentProps<{ id: string }>;

class TariffProperties extends React.PureComponent<TariffPropertiesProps, ITariffPropertiesState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;

  defaultBrand = {
    name: 'All', //this.translatorService.Tranlate("SELECT_ALL_OPTION", "Toate"),
    displayName: 'All', //this.translatorService.Tranlate("SELECT_ALL_OPTION", "Toate"),
    id: -1,
    isActive: true
  } as IReferential;

  state = {
    caseTariffValues: [],
    tariffTypeGroupList: [],
    isLoading: false
  } as ITariffPropertiesState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  compareName = (a: IReferential, b: IReferential) => {
    const nameA = a === null ? '' : a.displayName!.toUpperCase();
    const nameB = b === null ? '' : b.displayName!.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  loadData = async () => {
    try {
      if (this.props.caseTariffHistoryId === 0) {
        return;
      }
      this.defaultBrand.name = this.translatorService.Tranlate('SELECT_ALL_OPTION', 'Toate');
      this.defaultBrand.displayName = this.translatorService.Tranlate('SELECT_ALL_OPTION', 'Toate');

      const tariffGroupTypeRef = Referentials.referential.find(
        (item) => item.code === ReferentialCode.TariffTypeGroup
      );

      const tariffGroupTypeList = await this.appReferentialService.Get(tariffGroupTypeRef!.baseUrl);

      this.setState({
        caseTariffValues: this.props.caseSettingsState.case!.caseTariff.caseTariffValuesHistory,
        isLoading: false,
        tariffTypeGroupList: tariffGroupTypeList.filter((item) => item.isActive === true)
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderTariffGroupTypes = () => {
    const tariffGroupTypes = [] as any[];
    for (const tariffGroupType of this.state.tariffTypeGroupList) {
      tariffGroupTypes.push(
        <div key={tariffGroupType.id} className="pt-4">
          <div className="custom-timeline-list-icon" />
          <h4 className="custom-timeline-list-label mb-2 font-weight-bold">
            {tariffGroupType.displayName}
          </h4>
          <Grid container className="mx-4">
            {this.renderCaseTariffValues(tariffGroupType.id)}
          </Grid>
        </div>
      );
    }
    return tariffGroupTypes;
  };

  renderCaseTariffValues = (tariffGroupTypeId: number) => {
    const caseTariffValuess = [] as any[];
    const caseTariffValues = this.state.caseTariffValues.filter(
      (item) => item.tariffType!.tariffTypeGroupId === tariffGroupTypeId
    );
    this.getDistinctTariffTypes(caseTariffValues).map((tt) => {
      const tariffValues = caseTariffValues.filter((item) => item.tariffTypeId === tt!.id);

      caseTariffValuess.push(
        <Grid item xs={2} key={tt!.id} style={{ color: '#7a7b97' }}>
          <h6> {tt!.displayName} </h6>
          {this.renderTariffValues(tariffValues)}
        </Grid>
      );
    });
    return caseTariffValuess;
  };

  getDistinctTariffTypes = (tariffValues: CaseTariffValueHistory[]) => {
    return tariffValues
      .map((item) => item.tariffType)
      .filter((tt, index, arr) => arr.findIndex((i) => i!.id === tt!.id) == index);
  };

  renderTariffValues = (list: CaseTariffValueHistory[]) => {
    list = list.sort((a: CaseTariffValueHistory, b: CaseTariffValueHistory) => {
      return this.compareName(a.brand!, b.brand!);
    });
    return list.map((item) => (
      <p className="m-0" key={item.id}>
        {(item.brand === null ? this.defaultBrand.displayName : item.brand.displayName) +
          ' ' +
          this.renderVehicleAge(item) +
          ' - ' +
          item.value +
          ' (' +
          item.offerValue +
          ')'}
      </p>
    ));
  };

  renderVehicleAge = (tv: CaseTariffValueHistory) => {
    if (tv.startVehicleYear === null && tv.endVehicleYear === null) {
      return '';
    }
    const start = tv.startVehicleYear === null ? '0' : tv.startVehicleYear;
    const end = tv.endVehicleYear === null ? '' : tv.endVehicleYear;

    return '(' + start + '<' + end + ')';
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <Fragment>
        <Card>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <div className="mx-4 mb-4 px-4">{this.renderTariffGroupTypes()}</div>
          ) : null}
        </Card>
      </Fragment>
    );
  }
}

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: ExternalEditTariffProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => {
    return { caseSettingsState: state.caseSettings };
  },
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(TariffProperties as any));
