import React, { Fragment, ChangeEvent } from 'react';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { ScaleLoader } from 'react-spinners';
import { Box, Switch, Card, TextField } from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { ITranslation, IReferential, IReferentialConfig } from '../../../interfaces/IReferential';
import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import MaterialTable, { Icons, EditComponentProps, Column } from 'material-table';
import Referentials from '../../../helpers/Referentials.json';
import { withSnackbar, ProviderContext } from 'notistack';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { Language } from '../../../interfaces/Organization';
import * as R from 'ramda';
import { MavoLanguageTranslationEnum, ReferentialCode } from '../../../helpers/Constants';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

type ColorListProps = { appState: AppState } & ProviderContext;

interface IColorListState{
  dependentReferencials: IReferential[];
  referentials: IReferential[];
  filteredReferentials: IReferential[];
  isLoading: boolean;
  selectedReferential: IReferentialConfig | null;
  languages: Language[];
}

class ColorList extends React.PureComponent<ColorListProps, IColorListState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    referentials: [],
    isLoading: false,
    selectedReferential: null,
    languages: [] as Language[],
    dependentReferencials: [],
    filteredReferentials: []
  } as IColorListState;

  componentDidMount = () => {
    const referential =  Referentials.referential.find(x => x.code === ReferentialCode.ColorRCR) as IReferentialConfig;
    this.setState({
      isLoading: true,
      languages: this.activeLanguages().filter((lang) => lang.code === MavoLanguageTranslationEnum.RO),
    },
    async () => {
      this.loadReferentials(referential);
    });
  };

  loadReferentials = async (value: IReferentialConfig | null) => {
    let referentialList: IReferential[] = [];
    if (value !== null && value.getUrl !== '') {
      try {
        referentialList = await this.appReferentialService.GetFromDB(value.getUrl);

        referentialList.forEach((refItem) => {
          this.state.languages.forEach((lang) => {
            const translation = refItem.translations.find((item) => item.language === lang.code);
            if (R.isNil(translation)) {
              refItem.translations.push({
                id: 0,
                name: '',
                language: lang.code,
                referentialTypeId: refItem.id
              } as ITranslation);
            }
          });
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }

    this.setState({
      referentials: referentialList,
      isLoading: false,
      selectedReferential: value!
    });
  };

  handleIsActiveChange = async (event: ChangeEvent<HTMLInputElement>, rowData: any) => {
    const id = rowData.id;
    const newReferentials = [...this.state.filteredReferentials];
    const updatedReferential = newReferentials.find((ref) => ref.id === id);
    if (updatedReferential !== undefined) {
      updatedReferential.isActive = event.target.checked;

      try {
        await this.appReferentialService.Update(
          updatedReferential,
          this.state.selectedReferential!.updateUrl
        );
        this.setState({
          referentials: newReferentials
        });
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  activeLanguages = () => {
    const activeLanguages =
      this.props.appState.appUser!.organization!.gtOrganization!.languages!.filter((language) => {
        if (language.isActive === true) {
          return language;
        }
      });
    activeLanguages.forEach((language) => {
      language.displayName =
        language.displayName.charAt(0).toUpperCase() + language.displayName.slice(1);
    });
    return activeLanguages;
  };

  handleLocListChange = (event: any, gridProps: any, code: string | null) => {
    let newLocList = [] as ITranslation[];
    if (gridProps.rowData.translations) {
      newLocList = gridProps.rowData.translations.map((loc: any) => {
        if (loc.language === code) {
          loc.name = event.target.value;
        }
        return loc;
      });
    }

    gridProps.onRowDataChange({
      ...gridProps.rowData,
      translations: newLocList
    });
  };

  getColumns = () => {
    return [
      {
        field: 'code',
        title: this.translatorService.Tranlate('LIST_OF_VALUES_COD_HEADER', 'Cod'),
        searchable: true
        // initialEditValue: Guid.create().toString()
      } as Column<any>,
      {
        field: 'translations',
        title: this.translatorService.Tranlate('LIST_OF_VALUES_HEADER', 'Nume'),
        searchable: true,
        render: (rowData: any) => {
          return rowData.translations.map((val: ITranslation, index: number) => {
            const language = this.state.languages.find((language: any) => {
              return language.code === val.language;
            });
            return language ? (
              <>
                <TextField
                  id="standard-basic"
                  // label={language.displayName}
                  fullWidth
                  value={val.name}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                  size="small"
                />
              </>
            ) : null;
          });
        },
        customFilterAndSearch: (value, rowData) => {
          let matchFilter = false;
          rowData.translations.map((val: any) => {
            const findMatch = val.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
            if (findMatch) {
              matchFilter = true;
            }
          });
          return matchFilter;
        },
        editComponent: (props: any) => {
          if (!R.isNil(props.value)) {
            return props.value.map((val: ITranslation, index: number) => {
              const language = this.state.languages.find(
                (language: any) => language.code === val.language
              );
      
              return language ? (
                <>
                  <TextField
                    id="standard-basic"
                    size="small"
                    name={'name_' + index}
                    fullWidth
                    value={val.name}
                    // label={language.displayName}
                    onChange={(e) => this.handleLocListChange(e, props, val.language)}
                  />
                </>
              ) : null;
            });
          } else {
            // Add new translations
            const newLocList = [] as ITranslation[];
            for (let i = 0; i < this.state.languages!.length; i++) {
              const locListItem = {} as ITranslation;
              locListItem.language = this.state.languages[i].code;
              locListItem.name = '';
              newLocList.push(locListItem);
            }
      
            props.onChange(newLocList);
      
            return null;
          }
        }
      } as Column<any>,
      {
        field: 'dateModification',
        title: this.translatorService.Tranlate('LIST_OF_VALUES_DATE_HEADER', 'Data'),
        defaultSort: 'desc',
        searchable: true,
        render: (rowData: any) => {
          return moment
            .utc(rowData.dateModification)
            .local()
            .format(this.props.appState.longDateFormat);
        },
        editComponent: (props: EditComponentProps<any>) => {
          return <Label>{moment(new Date()).format(this.props.appState.longDateFormat)}</Label>;
        }
      } as Column<any>,
      {
        field: 'isActive',
        title: this.translatorService.Tranlate('LIST_OF_VALUES_ISACTIVE_HEADER', 'Stare'),
        render: (rowData: any) => {
          return (
            <Switch
              checked={rowData.isActive}
              onChange={(e) => this.handleIsActiveChange(e, rowData)}
              value={rowData.isActive}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          );
        },

        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Switch
              checked={props.value || false}
              value={props.value || false}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={(e) => props.onChange(e.target.checked)}
            />
          );
        }
      }
    ];
    
  };
  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza')
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };

  onRowUpdate = (newData: IReferential, oldData?: IReferential): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (!this.validateRef(newData)) {
        reject();
        return;
      }

      if (oldData) {
        this.setState((prevState: IColorListState) => {
          const newReferentials = !R.isEmpty(this.state.filteredReferentials)
            ? [...this.state.filteredReferentials]
            : [...this.state.referentials];
          newReferentials[newReferentials.indexOf(oldData)] = newData;

          return { ...prevState, referentials: newReferentials };
        });

        try {
          await this.appReferentialService.Update(
            newData,
            this.state.selectedReferential!.updateUrl
          );
          await this.loadReferentials(this.state.selectedReferential);
          resolve();
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  validateRef = (referential: IReferential): boolean => {
    if (referential.code === '' || referential.code === undefined || referential.code === null) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_CODE_EMPTY', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    const referentials = !R.isEmpty(this.state.filteredReferentials)
      ? this.state.filteredReferentials
      : this.state.referentials;
    let resultRef = referentials.find((item) => item.code === referential.code);
    if (referential.id !== undefined) {
      resultRef = referentials.find(
        (item) => item.code === referential.code && item.id !== referential.id
      );
    }
    if (resultRef !== undefined) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_CODE_UNIQUE', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    if (referential.translations === undefined) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_NAME', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    for (const item of referential.translations) {
      if (item.name === '' || item.name === undefined || item.name === null) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_NAME', 'Eroare'),
          { variant: 'error' }
        );
        return false;
      }
    }

    return true;
  };

  onRowAdd = (newData: IReferential): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (!this.validateRef(newData)) {
        reject();
        return;
      }
      try {
        newData.name = newData.translations[0].name;
        newData.gtId = this.props.appState.appUser!.gtId!;
        await this.appReferentialService.Add(newData, this.state.selectedReferential!.addUrl);
        await this.loadReferentials(this.state.selectedReferential);
        resolve();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });

  public renderTable = () => {
    if (this.state.selectedReferential === null) {
      return;
    }

    return (
      <MaterialTable
        icons={tableIcons}
        title={this.translatorService.Tranlate('NOMENCLATURE_COLOR_LIST', 'Lista Culori')}
        columns={this.getColumns()}
        data={
          !R.isEmpty(this.state.filteredReferentials)
            ? this.state.filteredReferentials
            : this.state.referentials
        }
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: IReferential) => this.onRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData)
        }}
      />
    );
  };
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Box m={1} p={1}>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? this.renderTable() : null}
      </Box>
    );
  }
}

export default connect((state: ApplicationState) => ({ appState: state.app }))(
  withSnackbar(ColorList as any)
);
