import * as React from 'react';
import { ApplicationState, AppState, CaseSettingsActionCreators, CaseSettingsState, VehicleActionCreators, VehicleState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { withSnackbar, ProviderContext } from 'notistack';
import { IOrderService } from '../../services/Interfaces/IOrderService';
import {
  TariffPercentResponse
} from '../../interfaces/Case';
import {
  Order
} from '../../interfaces/Order';
import 'moment/locale/en-gb';
import 'moment/locale/ro';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import _ from 'lodash';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import OrderDetails from './OrderDetails';

type OrderDetailsPageProps = {
  caseSettingsState: CaseSettingsState;
  vehicleState: VehicleState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  typeof VehicleActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

interface IOrderDetailsPageState {
  order: Order | null;
}

class OrderDetailsPage extends React.PureComponent<OrderDetailsPageProps, IOrderDetailsPageState> {
  private orderService!: IOrderService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  static contextType = ApplicationContext;
  
  state = {
    order: null,
  } as IOrderDetailsPageState;

  public componentDidMount = async ()  =>{
    const orderId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(orderId)) {
      return;
    }

    const order = await this.orderService.GetOrderWithVehicle(orderId);
    const [users, clients, suppliersInit] = await Promise.all([
      this.appUserService.GetUsersInfo(
       [order.createdBy]
      ),
      this.organizationService.GetUserOrganizationsByIds(
        [order.clientId]
      ),
      this.organizationService.GetUserOrganizationsByIds(
        [order.supplierId]
      ),
    ]);

    const supplier = suppliersInit.find((item) => item.id === order.supplierId);
    const user = users.find((item) => item.id === order.createdBy);
    const client = clients.find((item) => item.id === order.clientId);

    order.createdByUser = user === undefined ? null : user;
    order.supplier = supplier === undefined ? null : supplier;
    order.client = client === undefined ? null : client;

    this.setState({ order });
  }

  public orderChange = (order: Order) => {
    this.setState({
      order: 
        {
          ...this.state.order!,
          amount: order.amount,
          amountTVA: order.amountTVA,
          amountWithoutTVA: order.amountWithoutTVA,
          orderParts: [...order.orderParts!],
          orderStatus: order.orderStatus,
          orderStatusId: order.orderStatusId
        }
    });
  };

  public render() {
    this.orderService = (this.context as AppContext).orderService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;

    return (
      <React.Fragment>
        {this.state.order !== null && (
          <OrderDetails
            {...this.props}
            onOrderChange={this.orderChange}
            orderDetails={this.state.order}
            hasRights={true}
            additionTariff={{ id: 0, value: 0 } as TariffPercentResponse}
            discountTariff={{ id: 0, value: 0 } as TariffPercentResponse}
            automatePlaceOrder={false}
            workflowAutomatePlaceOrder={false}
            automatePlaceOrderChange={async () => {console.log('automatePlaceOrderChange')}}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => {
    return { caseSettingsState: state.caseSettings, vehicleState: state.vehicle, appState: state.app };
  },
  { ...CaseSettingsActionCreators, ...VehicleActionCreators }
)(withSnackbar(OrderDetailsPage as any));

