import React, { Fragment, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { AGENDA_SET_APPOINTMENTS, AGENDA_SET_CATEGORY_TYPE } from '../../store/actions/ActionTypes';
import { Box, } from '@material-ui/core';
import { Button } from 'reactstrap';
// Components
import ClientAutocomplete from './ClientAutocomplete';
import CarAutocomplete from './CarAutocomplete';
import CarDetails from './CarDetails';
import JobDetails from './JobDetails';
import JobsMultipleSelection from './JobsMultipleSelection';
import ContactForm from './ContactForm';
import MileageInput from './MileageInput';
import moment from 'moment';
import AddNewClientModal from './AddNewClientModal';
import { AppointmentCalendar } from './AppointmentCalendar';
import { getCategoryTypeList, getHour, getHourForAppointment } from '../../utils/appointments';
import { ResponseAppointmentsData } from '../../types/Agenda';
import { addAppointment, getAppointmentsRequest } from '../agenda/agenda.requests';
import { Car, Client, ContactFormData } from '../agenda/agenda.types';
import { useHistory } from 'react-router';
import Loader from '../Loader';
import { TranslatorService } from '../../services/TranslatorService';
import { PageTitle } from '../../layout-components';
import { useSnackbar } from 'notistack';
import { LinkButton } from './LinkButton';
import AddNewCarModal from './AddNewCarModal';

const AddNewAppointmentPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const translatorService = new TranslatorService();
  const selectedRange = useAppSelector((state) => state.agenda?.selectedRange)
  const categoryType = useAppSelector((state)=>state.agenda?.categoryType)
  const user = useAppSelector((state) => state.app?.user)
  const [selectedClient, setSelectedClient] = React.useState<Client | null>(null);
  const [selectedCar, setSelectedCar] = React.useState<Car | null>(null);
  const [carMileage, setCarMileage] = React.useState<string>('');
  const [selectedJobs, setSelectedJobs] = React.useState<any>([]);
  const [jobDetails, setJobDetails] = React.useState<string>('');
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [addCarModal, setAddCarModal] = React.useState<boolean>(false)
  const [date, setDate] = React.useState<Date>(new Date());
  const [hour, setHour] = React.useState<string>(getHourForAppointment())
  const [canContinue, setCanContinue] = useState<boolean>(true);
  const [contactFormData, setContactFormData] = React.useState<ContactFormData>({
    firstName: '',
    lastName: '',
    phoneNo: '',
    email: '',
    username: ''
  });

  React.useEffect(() => {  
    if(categoryType?.length === 0){
      getCategoryTypeList(setIsLoading, (value: any) => {
        dispatch({
          type: AGENDA_SET_CATEGORY_TYPE,
          payload: value
        })
      }, enqueueSnackbar)
    }
  }, []);

  const fetchAppointments = async (filters: any) => {
    setIsLoading(true);

    const response = await getAppointmentsRequest(filters);

    const responseData = response.data as ResponseAppointmentsData;

    if (!responseData.appointments) {
      return
    }

    dispatch({
      type: AGENDA_SET_APPOINTMENTS,
      payload: responseData.appointments
    });
    setIsLoading(false);
  };

  const handleClientChange = (e: any, newValue: Client | null) => {
    setSelectedClient(newValue);
  };

  const handleCarChange = (e: any, newValue: Car | null) => {
    setSelectedCar(newValue);
  };

  const handleMileageChange = (mileage: string) => {
    setCarMileage(mileage);
  };

  const handelDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setJobDetails(newValue);
  };

  const handleContactFormChange = (formData: ContactFormData) => {
    setContactFormData(formData);
  };
  const getRequestPayload = async () => {
    const finalDate = date;
    const splitHour = hour.split(':');
    const indexCategory = categoryType.findIndex((categoryItem: any) => categoryItem.code ==='EVALUATION')
    finalDate.setHours(parseInt(splitHour[0]));
    finalDate.setMinutes(parseInt(splitHour[1]))
    const payload = {
      serviceId: parseInt(user?.profile.organization_id),
      appointmentClient: selectedClient,
      vehicle: {
        vin: selectedCar?.vin || null,
        plateNumber: selectedCar?.plateNumber || null,
        make: selectedCar?.model.brand || null,
        model: selectedCar?.model.description || null,
        numberOfKilometers: carMileage,
        firstPlateDate: selectedCar?.firstPlateDate || null
      },
      date: moment(finalDate).format(),
      appointmentJobTypes: selectedJobs,
      jobDetails: jobDetails,
      appFirstName: contactFormData.firstName,
      appLastName: contactFormData.lastName,
      appEmail: contactFormData.email,
      appPhone: contactFormData.phoneNo,
      appUsername: contactFormData.username,
      appointmentCategoryTypeId: indexCategory !==-1 ? categoryType[indexCategory].id : 0
    }
    return payload;
  }

  const validateForm = () => {
    let futureCanContinue = true;
    if (!selectedCar || !selectedClient) {
      futureCanContinue = false;
    }
    setCanContinue(futureCanContinue)
    if (futureCanContinue) {
      saveData();
    }

  }

  const saveData = async () => {
    const payload = await getRequestPayload();
    const res = await addAppointment(payload)
    if (res.error) {
      return;
    }
    fetchAppointments({
      dateFilterStartDate: selectedRange?.start_date,
      datefilterEndDate: selectedRange?.end_date
    })
    history.goBack();
  }
  return (<>{isLoading ? <Loader /> :
      <Fragment>
        <PageTitle
          titleHeading={translatorService.Tranlate('ADD_APPOINTMENT', 'Adaugă programare')}
          backButtonName={translatorService.Tranlate('BACK_TO_APPOINTMENTS_LIST', 'Programari')}
          onClickBack={() => { history.push('/agenda') }} />
          <Fragment>
            <Box style={{ display: 'flex', gap: '20px' }}>
              <ClientAutocomplete error={!canContinue && !selectedClient} value={selectedClient} onChange={handleClientChange} />
              <LinkButton onClickMethod={() => setOpenModal(true)} text='add new client' />
            </Box>
            <Box style={{ display: 'flex', gap: '30px', marginTop: 16 }}>
              <Box>
                <Box style={{ display: 'flex', gap: '20px' }}>
                  <CarAutocomplete error={!canContinue && !selectedCar} value={selectedCar} onChange={handleCarChange} />
                  {!selectedCar ? <LinkButton onClickMethod={() => setAddCarModal(true)} text='add new car' /> :null}
                </Box>
                <MileageInput onChange={handleMileageChange} />
              </Box>
              {selectedCar && <CarDetails selectedCar={selectedCar} />}
            </Box>
            <Box style={{ display: 'flex', flexWrap: 'wrap', marginTop: 16 }}>
              <JobsMultipleSelection
                selectedValues={selectedJobs}
                onChange={(selectedValues) => {
                  setSelectedJobs(selectedValues);
                }}
              />
              <JobDetails onChange={handelDescriptionChange} />
            </Box>
            <AppointmentCalendar day={date} setDay={setDate} hour={hour} setHour={setHour} minDate={new Date()} showDefaultCalendar={false}/>
            <ContactForm formData={contactFormData} onChange={handleContactFormChange} />

            <Button
              color="primary"
              style={{ width: '100px', marginTop: 16 }}
              onClick={validateForm}
            >
              {translatorService.Tranlate('CASE_COMMENT_SEND', 'Save')}
            </Button>
          </Fragment>
        <AddNewClientModal onSave={(client: Client) => setSelectedClient(client)} open={openModal} onClose={() => { setOpenModal(false) }} />
        {addCarModal ?<AddNewCarModal onSave={(car:Car) => setSelectedCar(car)} open={addCarModal} onClose={() => { setAddCarModal(false) }} /> :null}
      </Fragment>
    }</>
  );
};

export default AddNewAppointmentPage;
