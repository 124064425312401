import { Action, Reducer } from 'redux';
import { ThemeAction } from '../actions/ThemeOptions';
import * as ActionTypes from '../actions/ActionTypes';

export interface ThemeState {
  // Sidebar

  sidebarShadow: boolean;
  sidebarFixed: boolean;
  sidebarToggleMobile: boolean;
  // sidebarUserbox: boolean,
  sidebarToggle: boolean;
  sidebarHover: boolean;
  // Header

  headerFixed: boolean;
  headerShadow: boolean;
  headerSearchHover: boolean;

  // Main content

  contentBackground: string;
  themeConfiguratorToggle: boolean;

  // Page title

  pageTitleStyle: string;
  pageTitleBackground: string;
  pageTitleShadow: boolean;
  pageTitleBreadcrumb: boolean;
  pageTitleIconBox: boolean;
  pageTitleDescription: boolean;
  pageTitleBackButton: boolean;
}

const initialState: ThemeState = {
  sidebarShadow: false,
  sidebarFixed: true,
  sidebarToggleMobile: false,
  // sidebarUserbox: true,
  sidebarToggle: false,
  sidebarHover: false,
  // Header

  headerFixed: true,
  headerShadow: true,
  headerSearchHover: false,

  // Main content

  contentBackground: '',
  themeConfiguratorToggle: false,

  // Page title

  pageTitleStyle: '',
  pageTitleBackground: '',
  pageTitleShadow: false,
  pageTitleBreadcrumb: false,
  pageTitleIconBox: false,
  pageTitleDescription: true,
  pageTitleBackButton: true
};

export const ThemeOptionsReducer: Reducer<ThemeState | undefined> = (
  state: ThemeState | undefined,
  incomingAction: Action
): ThemeState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as ThemeAction;

  switch (action.type) {
    case ActionTypes.THEME_SET_SIDEBAR_HOVER:
      return {
        ...state,
        sidebarHover: action.hover
      };
    case ActionTypes.THEME_SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: action.toggle
      };
    case ActionTypes.THEME_SET_SIDEBAR_TOGGLE_MOBILE:
      return {
        ...state,
        sidebarToggleMobile: action.toggle
      };
  }

  return state;
};
