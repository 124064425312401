import React, { ChangeEvent } from 'react';
import { Box, Typography, TextField } from '@material-ui/core';
import { TranslatorService } from '../../services/TranslatorService';

interface JobDetailsProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  details?:string;
  disabled?:boolean;
  type?:string;
  inputProps?:any
}

const JobDetails: React.FC<JobDetailsProps> = ({ onChange, details, disabled, type, inputProps }) => {
  const translatorService = new TranslatorService();
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };


  return (
    <Box style={{ width: '100%', height:'100%' }}>
      <Typography variant="h5" style={{ marginBottom: '10px', textAlign: type ==='details' ? 'left' : 'unset' }}>
        {translatorService.Tranlate('APPOINTMENT_JOB_DETAILS_TITLE', 'Job details')}
      </Typography>
        <Box style={{ height: 'calc(90%)' }}>
          <TextField
            label={disabled ? null : translatorService.Tranlate('APPOINTMENT_JOB_DETAILS_LABEL', 'Type details here...')}
            variant="outlined"
            multiline
            rows={4}
            disabled={disabled}
            defaultValue={details}
            onChange={handleDescriptionChange}
            fullWidth
            style={{ height: '100%'}}
            InputProps={inputProps ? inputProps :{
              style: { height: '100%', alignItems: 'baseline', }
            }}
          />
        </Box>
    </Box>
  );
};

export default JobDetails;
