import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import EnhancedMarker from 'react-leaflet-enhanced-marker';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GeocoderArcGIS from 'geocoder-arcgis';
import Loader from '../Loader';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import icon from 'leaflet/dist/images/marker-icon.png';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Tooltip } from '@material-ui/core';
import { ApplicationContext } from '../../context/Contexts';
import { MarketData } from '../../interfaces/Radar/MarketData';
import { RadarMapMarker } from '../../interfaces/Radar/RadarMapMarker';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [15, 25]
});

L.Marker.prototype.options.icon = DefaultIcon;

interface CustomMarkerProps {
  price: number;
  name: string;
  selected: boolean;
}
const CustomMarker: React.FC<CustomMarkerProps> = ({ price, name, selected }) => {
  const markerStyle = {
    backgroundColor: selected ? '#00008B' : '#207AC9',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '80px',
    height: '30px',
    alignItems: 'center',
    borderRadius: '10px'
  };

  return (
    <Tooltip title={name}>
      <div style={markerStyle}>{`${price} EUR`}</div>
    </Tooltip>
  );
};

interface Props {
  filteredMarketData: MarketData[];
  selectedMarketData?: MarketData;
  setSelectedMarketData(marketData?: MarketData): void;
}

const RadarMap: React.FC<Props> = ({
  filteredMarketData = [],
  selectedMarketData,
  setSelectedMarketData
}) => {
  const [mapMarkers, setMapMarkers] = useState<RadarMapMarker[]>([]);
  const [mapLoading, setMapLoading] = useState<boolean>(false);

  const context = useContext(ApplicationContext);

  const handleMapMarkerClick = useCallback(
    (id: string) => {
      if (selectedMarketData?.id === id) {
        setSelectedMarketData(undefined);
      } else {
        const item = filteredMarketData.find((item) => item.id === id);
        if (item) {
          setSelectedMarketData(item);
        }
      }
    },
    [filteredMarketData, selectedMarketData, setSelectedMarketData]
  );

  useEffect(() => {
    const getGeoData = async () => {
      setMapLoading(true);
      const geocoder = new GeocoderArcGIS();
      const markers: RadarMapMarker[] = [];
      for (const item of filteredMarketData) {
        // TODO read country from organization
        const params = {
          country: 'ro',
          address: `${item.county ? item.county + ',' : ''} ${
            item.postalCode ? item.postalCode : ''
          }`
        };

        try {
          const response = await geocoder.findAddressCandidates(params);

          if (response && response.candidates.length) {
            markers.push({
              price: item.price,
              lat: response.candidates[0].location.y,
              long: response.candidates[0].location.x,
              name: item.name,
              id: item.id
            });
          }
        } catch {
          continue;
        }
      }

      setMapMarkers(markers);
      setMapLoading(false);
    };

    if (filteredMarketData.length) {
      getGeoData();
    }
  }, [context.organizationService, filteredMarketData]);

  if (mapLoading) {
    return <Loader />;
  }

  return (
    <MapContainer
      // TODO read center from country organization
      center={[45.9442858, 25.0094303]}
      zoom={7}
      scrollWheelZoom={false}
      style={{ height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {mapMarkers.map(({ lat, long, price, id, name }, index) => {
        return (
          <>
            <Marker position={[lat, long]} key={index} />
            <EnhancedMarker
              icon={
                <CustomMarker price={price} selected={selectedMarketData?.id === id} name={name} />
              }
              position={[lat, long]}
              key={index}
              eventHandlers={{
                click: () => {
                  handleMapMarkerClick(id);
                }
              }}
            />
          </>
        );
      })}
    </MapContainer>
  );
};

export default RadarMap;
