import { IAppUserService } from './Interfaces/IAppUserService';
import {
  AppUser,
  AppuserList,
  AppUserRoleList,
  AppUserAppUserRole,
  AppUserRole,
  AppUserRoleClaimList,
  AppUserRoleClaim
} from '../interfaces/AppUser';
import { API } from '../helpers/API';

export class AppUserService implements IAppUserService {
  private baseAdminController = '/IdentityAdmin';
  private baseController = '/Identity';

  public async ResetPassword(userId: string): Promise<void> {
    await API.PostAsync<any>(`${this.baseAdminController}/resetpassword/${userId}`, null);
  }

  public async AddUser(appuser: AppUser): Promise<AppUser> {
    return (await API.PostAsync<AppUser>(`${this.baseAdminController}/add`, appuser)).data;
  }

  public async UpdateUser(appUser: AppUser): Promise<void> {
    await API.PutAsync<AppUser>(`${this.baseAdminController}/update`, appUser);
  }

  public async GetAppUsers(
    search: string,
    page?: number,
    rowsPerPage?: number
  ): Promise<AppuserList> {
    const params = `searchText=${search === undefined ? '' : search}&page=${
      page === undefined ? '' : page
    }&pageSize=${rowsPerPage === undefined ? '' : rowsPerPage}`;
    return (
      await API.GetAsync<AppuserList>(
        `${this.baseAdminController}/SearchWithOrganization?${params}`
      )
    ).data;
  }

  public async GetUserAppUsers(
    search: string,
    orgIds?: string,
    page?: number,
    rowsPerPage?: number
  ): Promise<AppuserList> {
    const params = `searchText=${search === undefined ? '' : search}&orgIds=${
      orgIds === undefined ? '' : orgIds
    }&page=${page === undefined ? '' : page}&pageSize=${
      rowsPerPage === undefined ? '' : rowsPerPage
    }`;
    return (
      await API.GetAsync<AppuserList>(
        `${this.baseAdminController}/SearchInUserOrgWithOrganization?${params}`
      )
    ).data;
  }

  public async GetMavoUserAppUsers(
    search: string,
    orgIds?: string,
    page?: number,
    rowsPerPage?: number
  ): Promise<AppuserList> {
    const params = `searchText=${search === undefined ? '' : search}&orgIds=${
      orgIds === undefined ? '' : orgIds
    }&page=${page === undefined ? '' : page}&pageSize=${
      rowsPerPage === undefined ? '' : rowsPerPage
    }`;
    return (
      await API.GetAsync<AppuserList>(
        `${this.baseAdminController}/SearchInMavoUserOrgWithOrganization?${params}`
      )
    ).data;
  }

  public async GetAppUser(id: string): Promise<AppUser> {
    return (await API.GetAsync<AppUser>(`${this.baseAdminController}/get/${id}`)).data;
  }
  public async GetUserInfo(id: string): Promise<AppUser> {
    return (await API.GetAsync<AppUser>(`${this.baseAdminController}/UserInfo/${id}`)).data;
  }

  public async GetUsersInfo(ids: string[]): Promise<AppUser[]> {
    if (ids.length === 0) {
      return [];
    }

    return (await API.Post2Async<string[], AppUser[]>(`${this.baseAdminController}/UsersInfo`, ids))
      .data;
  }

  public async GetProfile(): Promise<AppUser> {
    return (await API.GetAsync<AppUser>(`${this.baseAdminController}/profile`)).data;
  }

  public async GetUserAppUserRoles(
    userId: string,
    page: number,
    rowsPerPage: number
  ): Promise<AppUserRoleList> {
    return (
      await API.GetAsync<AppUserRoleList>(
        `${this.baseAdminController}/GetUserRoles?userId=${userId}&searchText=&page=${page}&pageSize=${rowsPerPage}`
      )
    ).data;
  }

  public async AddAppUserRole(userUserRole: AppUserAppUserRole): Promise<void> {
    await API.PostAsync<AppUserAppUserRole>(
      `${this.baseAdminController}/AddUserRole`,
      userUserRole
    );
  }

  public async DeleteAppUserRole(userUserRole: AppUserAppUserRole): Promise<void> {
    await API.DeleteAsync<AppUserAppUserRole>(
      `${this.baseAdminController}/RemoveUserRole?userId=${userUserRole.userId}&roleId=${userUserRole.roleId}`
    );
  }

  public async GetAppUserRoles(
    search?: string,
    page?: number,
    rowsPerPage?: number
  ): Promise<AppUserRoleList> {
    const params = `searchText=${search === undefined ? '' : search}&page=${
      page === undefined ? '' : page
    }&pageSize=${rowsPerPage === undefined ? '' : rowsPerPage}`;
    return (await API.GetAsync<AppUserRoleList>(`${this.baseAdminController}/GetRoles?${params}`))
      .data;
  }

  public async AddRole(role: AppUserRole): Promise<AppUserRole> {
    return (await API.PostAsync<AppUserRole>(`${this.baseAdminController}/addRole`, role)).data;
  }

  public async UpdateRole(role: AppUserRole): Promise<void> {
    await API.PutAsync<AppUserRole>(`${this.baseAdminController}/updateRole`, role);
  }

  public async GetAppUserRole(id: string): Promise<AppUserRole> {
    return (await API.GetAsync<AppUserRole>(`${this.baseAdminController}/getrole/${id}`)).data;
  }

  public async GetAppUserRoleClaims(
    roleId: string | null,
    page: number,
    rowsPerPage: number
  ): Promise<AppUserRoleClaimList> {
    return (
      await API.GetAsync<AppUserRoleClaimList>(
        `${this.baseAdminController}/GetRoleClaims?roleId=${roleId}&searchText=&page=${page}&pageSize=${rowsPerPage}`
      )
    ).data;
  }

  public async AddRoleClaim(roleClaim: AppUserRoleClaim): Promise<void> {
    await API.PostAsync<AppUserRoleClaim>(`${this.baseAdminController}/addRoleClaim`, roleClaim);
  }

  public async DeleteRoleClaim(roleClaim: AppUserRoleClaim): Promise<void> {
    await API.DeleteAsync<AppUserRoleClaim>(
      `${this.baseAdminController}/RemoveRoleClaim?roleid=${roleClaim.roleId}&claimid=${roleClaim.claimId}`
    );
  }
}
