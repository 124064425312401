import { FormCode } from './FormCode';
import { Button } from 'reactstrap';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import React from 'react';

export class FormButton {
  public static GetButtons(
    code: string,
    translatorService: ITranslatorService,
    hasRights: boolean,
    executing: boolean
  ) {
    if (
      code === FormCode.SEARCH_CLIENT ||
      code === FormCode.SEARCH_NOTICE ||
      code === FormCode.SEARCH_CLAIM_REVIEW ||
      code === FormCode.SEARCH_VEHICLE
    ) {
      return (
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!hasRights || executing}
        >
          {translatorService.Tranlate('SEARCH_BTN', 'CAUTA')}
        </Button>
      );
    }
    if (code === FormCode.CASE_CAR_DATA || code === FormCode.CASE_EVENT_DATA) {
      return (
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!hasRights || executing}
        >
          {translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      );
    }
    if (code === FormCode.CASE_CAR_DATA) {
      return (
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!hasRights || executing}
        >
          {translatorService.Tranlate('SAVE_BTN', 'Salveaza')}
        </Button>
      );
    }

    return null;
  }
}
