import React, { useReducer, useCallback, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import isNil from 'lodash/isNil';
import { Card, Grid, Paper, Button } from '@material-ui/core';

import SalesOfferEmailBarChart from './SalesOfferEmailBarChart';
import SalesHeader from './SalesHeader';
import AcquistionDonutChart from './SalesDonutChart';
import VehicleAdSalesChart from './VehicleAddSalesChart';
import SalesSummaryCard from './SalesSummaryCard';
import SalesAggregateTable from './SalesAggregateTable';
import DashboardFilters from '../DashboardFilters';
import { ApplicationContext } from '../../../context/Contexts';
import { SalesResult } from '../../../interfaces/Dashboard/SalesResult';
import { SalesFilter } from '../../../interfaces/Dashboard/SalesFilter';
import {
  salesDashboardReducer,
  SET_VAL_BY_NAME,
  SET_PERSON_TYPE_ID,
  SET_SALE_PERSON_ID,
  RESET_SALES_FILTERS
} from './salesDashboardReducer';
import Loader from '../../Loader';
import SalesDashboardAppliedFilters from './SalesDashboardAppliedFilters';
import { numberFormat } from '../../../utils/numberFormat';
import { PptService } from '../../../services/PptService';
import { MUITranslations } from '../../../helpers/MUITableTranslations';

const SalesDashboard: React.FC = () => {
  const context = useContext(ApplicationContext);
  const [filters, dispatch] = useReducer(salesDashboardReducer, {
    startDate: moment().add(-3, 'month'),
    endDate: moment(),
    selectedOrganisations: [],
    clientTypeId: undefined,
    locationId: undefined,
    salePersonId: undefined
  });

  const constantsData = MUITranslations.GetDashboardTranslations(context.translatorService);

  const [data, setData] = useState<SalesResult>();
  const setFiltersValueByName = useCallback((name: string, value: any) => {
    dispatch({ type: SET_VAL_BY_NAME, name, value });
  }, []);

  const setPersonTypeId = useCallback((clientTypeId?: number) => {
    dispatch({ type: SET_PERSON_TYPE_ID, clientTypeId });
  }, []);

  const setSalePersonId = useCallback((salePersonId?: string) => {
    dispatch({ type: SET_SALE_PERSON_ID, salePersonId });
  }, []);

  const resetFilters = useCallback(() => {
    dispatch({ type: RESET_SALES_FILTERS });
  }, []);

  const getSalesFormatTable = () => {
    const averageAge = context.translatorService.Tranlate(
      'DASHBOARD_AVERAGE_VEHICLE_AGE',
      'Varsta medie'
    );
    const total = context.translatorService.Tranlate('DASHBOARD_TOTAL', 'Total achizitie');
    const fixesAndOtherCosts = context.translatorService.Tranlate(
      'DASHBOARD_FIXES_AND_OTHER',
      'Reparatii + alte costuri'
    );
    const salePrice = context.translatorService.Tranlate(
      'DASHBOARD_SALE_PRICE',
      'Pret Total vanzare'
    );
    const profit = context.translatorService.Tranlate('DASHBOARD_PROFIT', 'Profit %');
    const totalProfit = context.translatorService.Tranlate(
      'DASHBOARD_TOTAL_PROFIT',
      'Profit (valoare absoluta)'
    );
    return [
      {
        title: averageAge,
        render: (rowData: any) => numberFormat(rowData.avgOfYear, 1)
      },
      {
        title: total,
        render: (rowData: any) => numberFormat(rowData.purchaseValue, 0, undefined, ' EUR')
      },
      {
        title: fixesAndOtherCosts,
        render: (rowData: any) => numberFormat(rowData.damageValue, 0, undefined, ' EUR')
      },
      {
        title: salePrice,
        render: (rowData: any) => numberFormat(rowData.soldValue, 0, undefined, ' EUR')
      },
      {
        title: profit,
        render: (rowData: any) => numberFormat(rowData.profitPercent)
      },
      {
        title: totalProfit,
        render: (rowData: any) => numberFormat(rowData.profitValue, 0, undefined, ' EUR')
      }
    ];
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getData = async () => {
      try {
        const filter: SalesFilter = {
          startDate: moment(new Date(filters.startDate.utc().format()).setHours(0, 0, 0))
            .utc()
            .toDate(),
          endDate: moment(new Date(filters.endDate.utc().format()).setHours(23, 59, 59))
            .utc()
            .toDate(),
          organizationIds: !isNil(filters.locationId)
            ? [filters.locationId]
            : filters.selectedOrganisations.map(({ id }) => id),
          personTypeIds: !isNil(filters.clientTypeId) ? [filters.clientTypeId] : [],
          salePersonIds: filters.salePersonId ? [filters.salePersonId] : []
        };

        if (filter.startDate && filter.endDate && filter.organizationIds.length) {
          const result = await context.vehicleService.GetDashboardSalesData(filter, source.token);
          if (result) {
            setData(result);
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    };

    getData();

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.endDate,
    filters.startDate,
    filters.selectedOrganisations,
    context.vehicleService,
    filters.locationId,
    filters.clientTypeId,
    filters.salePersonId
  ]);

  const handlePersonTypeDonutClick = useCallback(
    (dataIndex?: number) => {
      if (!isNil(dataIndex)) {
        const clientTypeId = data?.vehiclesGroupedByClientType[dataIndex].id;
        if (!isNil(clientTypeId)) {
          setPersonTypeId(clientTypeId);
        }
      }
    },
    [data?.vehiclesGroupedByClientType, setPersonTypeId]
  );

  return (
    <div style={{ paddingBottom: '40px' }}>
      <Paper className="p-4 d-flex justify-content-center">
        <DashboardFilters filters={filters} setFiltersValueByName={setFiltersValueByName} />
        <Button
          className="m-2"
          variant="outlined"
          color="primary"
          onClick={() => {
            context.pptService = new PptService(context.translatorService, data);
            context.pptService.addFirstPage(filters, 'sales');
            context.pptService.addVerticalChart2(
              data?.groupedOfferAndSentEmail,
              context.translatorService.Tranlate(
                'DASHBOARD_GENERATED_OFFERS_TITLE',
                'Oferte Generate/Email'
              ),
              constantsData.groupedOfferAndSentEmail
            );
            context.pptService.addCircleChart(
              data?.vehiclesGroupedByClientType,
              context.translatorService.Tranlate('DASHBOARD_CLIENT_TYPE', 'Tip client')
            );
            context.pptService.addScatterChar(
              data?.advertListAndSoldVehicleListGrouped,
              context.translatorService.Tranlate('DASHBOARD_SALES_CHART_TITLE', 'Anunturi/Vanzari'),
              constantsData.advertListAndSoldVehicleListGrouped,
              'Anunturi publicate',
              'Masini vandute'
            );
            context.pptService.salesProfit();
            context.pptService.addTable(
              [...constantsData.salesTable, ...getSalesFormatTable()],
              data?.vehiclesGroupedByMakerAndModel,
              context.translatorService.Tranlate('DASHBOARD_SALES_TABLE_TITLE', 'Vanzari')
            );
            context.pptService.lastSalePage(data!.vehiclesGroupedByMakerAndModel[0]);
            context.pptService.exportPpt();
          }}
        >
          Export
        </Button>
      </Paper>
      {data ? (
        <Grid container spacing={2} className="mt-2">
          <SalesDashboardAppliedFilters
            filters={filters}
            data={data}
            resetFilters={resetFilters}
            setFiltersValueByName={setFiltersValueByName}
          />
          {(data.salesChannels.length > 0 || data.topSellersBasedOnClosedFolders.length > 0) && (
            <Grid item xs={12}>
              <SalesHeader
                channels={data.salesChannels}
                topSellers={data.topSellersBasedOnClosedFolders}
                onClickSeller={setSalePersonId}
              />
            </Grid>
          )}
          {data.groupedOfferAndSentEmail.length > 0 && (
            <Grid item xs={8}>
              <Card className="h-100">
                <SalesOfferEmailBarChart
                  title={context.translatorService.Tranlate(
                    'DASHBOARD_GENERATED_OFFERS_TITLE',
                    'Oferte Generate/Email'
                  )}
                  items={data.groupedOfferAndSentEmail}
                />
              </Card>
            </Grid>
          )}
          {data.vehiclesGroupedByClientType.length > 0 && (
            <Grid item xs={4}>
              <Card className="h-100">
                <AcquistionDonutChart
                  title={context.translatorService.Tranlate('DASHBOARD_CLIENT_TYPE', 'Tip client')}
                  items={data.vehiclesGroupedByClientType}
                  handleDonutClick={handlePersonTypeDonutClick}
                />
              </Card>
            </Grid>
          )}
          {data.advertListAndSoldVehicleListGrouped.length > 0 && (
            <Grid item xs={8}>
              <Card className="h-100">
                <VehicleAdSalesChart
                  title={context.translatorService.Tranlate(
                    'DASHBOARD_SALES_CHART_TITLE',
                    'Anunturi/Vanzari'
                  )}
                  items={data.advertListAndSoldVehicleListGrouped}
                />
              </Card>
            </Grid>
          )}
          <Grid item xs={4}>
            <Card
              className="h-100 p-4 text-center d-flex justify-content-center"
              style={{ flexDirection: 'column' }}
            >
              <h4 className="mb-4">
                {context.translatorService.Tranlate(
                  'DASHBOARD_SALES_WARRANTIES',
                  'Garantii vandute'
                )}
              </h4>
              <div>
                <div className="mt-4">
                  <h2>{data.guaranteeCount}</h2>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="h-auto p-2 d-flex">
              <SalesSummaryCard
                title={context.translatorService.Tranlate('DASHBOARD_SALES_TABLE_TITLE', 'Vanzari')}
                value={numberFormat(
                  data.soldVehicleInformation.amountInclVat,
                  0,
                  undefined,
                  ' EUR'
                )?.toString()}
                adsValue={numberFormat(
                  data.addsInformation.amountInclVat,
                  0,
                  undefined,
                  ' EUR'
                )?.toString()}
              />
              <SalesSummaryCard
                title={context.translatorService.Tranlate('DASHBOARD_PROFIT_VALUE', 'Profit')}
                value={numberFormat(
                  data.soldVehicleInformation.profitValue,
                  0,
                  undefined,
                  ' EUR'
                )?.toString()}
                adsValue={numberFormat(
                  data.addsInformation.profitValue,
                  0,
                  undefined,
                  ' EUR'
                )?.toString()}
              />
              <SalesSummaryCard
                title={context.translatorService.Tranlate('DASHBOARD_PROFIT_RATIO', 'PROFIT %')}
                value={`${numberFormat(data.soldVehicleInformation.profitPercent)?.toString()} %`}
                adsValue={`${numberFormat(data.addsInformation.profitPercent)?.toString()} %`}
              />
              <SalesSummaryCard
                title={context.translatorService.Tranlate(
                  'DASHBOARD_QUANTITY',
                  'Nr. Unitati vandute'
                )}
                value={numberFormat(data.soldVehicleInformation.count)?.toString()}
                adsValue={numberFormat(data.addsInformation.count)?.toString()}
              />
              <SalesSummaryCard
                title={context.translatorService.Tranlate('DASHBOARD_SALES_COST', 'Cost')}
                value={numberFormat(
                  data.soldVehicleInformation.damageValue,
                  0,
                  undefined,
                  ' EUR'
                )?.toString()}
                adsValue={numberFormat(
                  data.addsInformation.damageValue,
                  0,
                  undefined,
                  ' EUR'
                )?.toString()}
              />
            </Card>
          </Grid>
          {data.vehiclesGroupedByMakerAndModel?.length > 0 && (
            <SalesAggregateTable vehicles={data.vehiclesGroupedByMakerAndModel} />
          )}
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SalesDashboard;
