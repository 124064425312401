import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Modal,
  Grid,
} from '@material-ui/core';
import * as R from 'ramda';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CloseIcon from '@material-ui/icons/Close';
import moment, { Moment } from 'moment';
import { CarDetailsSettings } from '../../helpers/Constants';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Utils } from '../../helpers/Utils';

const initialVehicle = {
  plateNumber: '',
  firstRegistrationDate: null,
  kilometersNr: 0,
  vin: '',
  accountingValue: 0,
  rcrEmployeeEmail: '',
  rcrEmployeePhone: '',
  rcrEmployeeFirstName: '',
  rcrEmployeeLastName: ''
}
interface Props {
  isOpen: boolean;
  handleClose: () => void;
  translatorService: ITranslatorService;
  vehicleService: IVehicleService;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enqueueSnackbar(param1: any, param2: any): void;
  language:string;
  dateFormat:string;
}


export interface ImportVehicle {
  plateNumber: string;
  firstRegistrationDate: Moment | null;
  kilometersNr: number;
  vin: string;
  accountingValue: number;
  rcrEmployeeEmail: string;
  rcrEmployeePhone: string;
  rcrEmployeeFirstName: string;
  rcrEmployeeLastName: string
}

class LocalizedUtils extends MomentUtils { }

const VehicleImportOneCarModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  translatorService,
  vehicleService,
  enqueueSnackbar,
  language,
  dateFormat
}) => {

  const [vehicleData, setVehicleData] = useState<ImportVehicle>(initialVehicle)
  const [datePickerFirstRegistrationError, setDatePickerFirstRegistrationError] = useState<string>('')
  const [plateNumberError, setPlateNumberError] = useState(false);

  const addVehicle = async () => {
    try {
      const result = await vehicleService.SaveManualdVehicles({vehicles:[vehicleData]});
      if (result.importedVehicleVins.length > 0){
        enqueueSnackbar(translatorService.Tranlate('VEHICLE_ADD_VEHICLE_SUCCES', 'Vehicului a fost adaugat cu succes'), {
          variant: 'success',
          autoHideDuration: 6000
        });
      }else{
        enqueueSnackbar(translatorService.Tranlate('VEHICLE_ADD_VEHICLE_ALREADY_EXISTS', 'Vehicului exista deja'), {
          variant: 'info',
          autoHideDuration: 6000
        });
      }

      setVehicleData({ ...initialVehicle })
      handleClose()
    } catch {
      enqueueSnackbar(translatorService.Tranlate('VEHICLE_ADD_VEHICLE_ERROR', 'There was an unexpected error while importing the vehicles'), {
        variant: 'error',
        autoHideDuration: 6000
      });
      handleClose()
    }
  };

  useEffect(()=>{
    ValidatorForm.addValidationRule('isValidNumberOfKm', (value) => {
      if (value >= CarDetailsSettings.numberOfKm) {
        return false;
      }
      return true;
    });
  },[])

  const checkIfDataIsValid = (): boolean => {
    if (
      vehicleData &&
      vehicleData.firstRegistrationDate &&
      vehicleData.accountingValue &&
      vehicleData.rcrEmployeeEmail &&
      vehicleData.rcrEmployeeLastName && 
      vehicleData.rcrEmployeeFirstName &&
      vehicleData.rcrEmployeePhone &&
      vehicleData.kilometersNr &&
      vehicleData.plateNumber &&
      vehicleData.vin &&
      R.test(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/, vehicleData.vin)
    ) {
      return true;
    }

    return false;
  };

  const handleCloseModal = () => {
    setVehicleData({ ...initialVehicle })
    handleClose();
  };

  const onChange = (attributeName: keyof ImportVehicle, newValue: any) => {
    setVehicleData((prevState) => ({
      ...prevState,
      [attributeName]: newValue
    }));

    const isPlateNumberValid = Utils.validatePlateNumber(newValue);
    if (!isPlateNumberValid) {
      setPlateNumberError(true); 
    } else {
      setPlateNumberError(false);
    }
  };

  const handleFirstRegistrationDateChange = (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    let error = '';
    const selectedDate = date ? date.format('YYYY-MM-DD') : undefined;

    if (!selectedDate) {
      error = translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
    } else {
      const currentDate = moment().format('YYYY-MM-DD');
      if (currentDate && selectedDate > currentDate) {
        error = translatorService.Tranlate(
          'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION_ERROR',
          'Data primei inmatriculari trebuie sa fie mai mica decat data curenta'
        );
      }
    }

    onChange('firstRegistrationDate', date || '')
    setDatePickerFirstRegistrationError(error);
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleCloseModal}>
        <div className="vehicle-import-modal">
          <div className="bg-theme-primary text-center p-3" style={{ color: 'white', margin: -10, marginBottom:20 }}>
            <h5  style={{ color: 'white',marginTop:10 }}>
              {translatorService.Tranlate('VEHICLES_VEHICLE_IMPORT_MANUAL', 'Importa Vehicul Manual')}
            </h5>
            <div style={{ position:'absolute', right:0, marginTop:-45}}>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon  style={{color:'white'}}/>
              </IconButton>
            </div>
          </div>
          <ValidatorForm onSubmit={addVehicle} instantValidate={true}>
            <div className="mt-2 mb-2">
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <TextValidator
                    fullWidth
                    id="plate-number"
                    className="m-2 pr-3"
                    name="plateNumber"
                    placeholder={translatorService.Tranlate(
                      'CAR_DETAILS_PLATE_NUMBER',
                      'Numar inmatriculare'
                    )}
                    value={vehicleData?.plateNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange('plateNumber', e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ''));
                    }}
                    error={plateNumberError} 
                    helperText={plateNumberError ? translatorService.Tranlate(
                      'VEHICLES_VEHICLE_SEND_TO_CARFIX_INVALID_PLATE_NUMBER',
                      'Numar de inmatriculare incorect'
                    ): ""}
                    validators={['required']}
                    errorMessages={[
                      translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                    ]}
                    label={translatorService.Tranlate(
                      'CAR_DETAILS_PLATE_NUMBER',
                      'Numar inmatriculare'
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={LocalizedUtils}
                    locale={language}
                  >
                    <DatePicker
                      fullWidth
                      className="mt-2 ml-2 pr-2"
                      openTo="date"
                      views={['year', 'month', 'date']}
                      variant="inline"
                      format={dateFormat.toUpperCase()}
                      margin="normal"
                      id="firstRegistrationDate"
                      label={translatorService.Tranlate(
                        'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION',
                        'Data primei inmatriculari'
                      )}
                      value={vehicleData.firstRegistrationDate || null}
                      onChange={handleFirstRegistrationDateChange}
                      autoOk={true}
                      error={datePickerFirstRegistrationError ? true : false}
                      helperText={datePickerFirstRegistrationError}
                      
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    fullWidth
                    id="km-number"
                    className="m-2 pr-3"
                    name="numberOfKilometers"
                    placeholder={translatorService.Tranlate(
                      'CAR_DETAILS_NUMBER_OF_KILOMETERS',
                      'Numar kilometri'
                    )}
                    value={vehicleData.kilometersNr || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('kilometersNr', parseInt(e.target.value))}
                    validators={['required', 'isValidNumberOfKm']}
                    errorMessages={[
                      translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                      translatorService.Tranlate(
                        'CAR_DETAILS_NUMBER_OF_KILOMETERS_ERROR',
                        'Numarul de kilometri nu trebuie sa fie mai mare de 1000000'
                      )
                    ]}
                    label={translatorService.Tranlate(
                      'CAR_DETAILS_NUMBER_OF_KILOMETERS',
                      'Numar kilometri'
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    fullWidth
                    id="chasis-code"
                    className="m-2 pr-0 mr-0"
                    name="chasisCode"
                    placeholder={translatorService.Tranlate(
                      'CAR_DETAILS_CHASIS_CODE',
                      'Serie sasiu'
                    )}
                    value={vehicleData.vin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('vin', e.target.value)}
                    validators={[
                      'required',
                      'matchRegexp:^(?=.*[0-9])(?=.*[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ])[0-9abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ-]{17}$'
                    ]}
                    errorMessages={[
                      translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      ),
                      translatorService.Tranlate('VIN_INVALID', 'Numarul VIN nu este valid')
                    ]}
                    label={translatorService.Tranlate('CAR_DETAILS_CHASIS_CODE', 'Serie sasiu')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    fullWidth
                    id="accounting value"
                    className="m-2 pr-3"
                    name="accountingValue"
                    placeholder={translatorService.Tranlate(
                      'CAR_DETAILS_ACCOUNTING_VALUE',
                      'Valoarea contabila'
                    )}
                    value={vehicleData.accountingValue || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('accountingValue', parseInt(e.target.value))}
                    validators={['required']}
                    errorMessages={[
                      translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                    ]}
                    label={translatorService.Tranlate(
                      'CAR_DETAILS_ACCOUNTING_VALUE',
                      'Valoarea contabila'
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    fullWidth
                    id="last Name"
                    className="m-2 pr-3"
                    name="rcrEmployeeLastName"
                    placeholder={translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_LAST_NAME',
                      'Nume'
                    )}
                    value={vehicleData.rcrEmployeeLastName || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('rcrEmployeeLastName', e.target.value)}
                    validators={['required']}
                    errorMessages={[
                      translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                    ]}
                    label={translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_LAST_NAME',
                      'Nume'
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    fullWidth
                    id="First Name"
                    className="m-2 pr-3"
                    name="rcrEmployeeFirstName"
                    placeholder={translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_FIRST_NAME',
                      'Prenume'
                    )}
                    value={vehicleData.rcrEmployeeFirstName || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('rcrEmployeeFirstName', e.target.value)}
                    validators={['required']}
                    errorMessages={[
                      translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                    ]}
                    label={translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_FIRST_NAME',
                      'Prenume'
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    fullWidth
                    id="Email"
                    type='email'
                    className="m-2 pr-3"
                    name="rcrEmployeeEmail"
                    placeholder={translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_EMAIL',
                      'Email'
                    )}
                    value={vehicleData.rcrEmployeeEmail || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('rcrEmployeeEmail', e.target.value)}
                    validators={[
                      'required',
                      'isEmail'
                    ]}
                    errorMessages={[
                      translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                      translatorService.Tranlate('EMAIL_INVALID', 'Email-ul nu este valid')
                    ]}
                    label={translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_EMAIL',
                      'Email'
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    fullWidth
                    id="Phone"
                    className="m-2 pr-3"
                    name="rcrEmployeePhone"
                    placeholder={translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_PHONE',
                      'Phone'
                    )}
                    value={vehicleData.rcrEmployeePhone || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('rcrEmployeePhone', e.target.value)}
                    validators={['required']}
                    errorMessages={[
                      translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                    ]}
                    label={translatorService.Tranlate(
                      'CAR_DETAILS_EMPLOYEE_PHONE',
                      'Phone'
                    )}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ display:'flex', justifyContent:'center', marginTop:36, marginBottom:36}}>
              <Button variant="contained" color="primary" type="submit" disabled={!checkIfDataIsValid()}>
                {translatorService.Tranlate('VEHICLES_VEHICLE_ADD_VEHICLE', 'Adauga Vehicul')}
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </Modal>
    </div>
  );
};

export default VehicleImportOneCarModal;
