import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Organization } from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, Card, CardContent, Tabs, Tab, Switch } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { WorkflowPartner } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined } from 'util';
import _ from 'lodash';
import { RoleClaimsEnum, RoleCodeEnum } from '../../../helpers/Constants';

interface IWorkflowPartnersState {
  workflowPartners: WorkflowPartner[];
  isLoading: boolean;
  workflowOwnerList: Organization[];
  //  selectedWorkflowOwner: Organization | null,
  workflowPartnerList: Organization[];
  selectedWorkflowPartner: Organization[];
  workflowId: number;
}

type IWorkflowPartnersProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowPartners extends React.PureComponent<IWorkflowPartnersProps, IWorkflowPartnersState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowPartners: [],
    isLoading: false,
    workflowOwnerList: [],
    // selectedWorkflowOwner: null,
    workflowPartnerList: [],
    selectedWorkflowPartner: [],
    workflowId: 0
  } as IWorkflowPartnersState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowPartners();
      }
    );
  }
  
  public hasRole = (roleCode: string): boolean => {
    return this.props.appState.appUser?.userRole?.code === roleCode;
  };

  loadWorkflowPartners = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const [workflowPartnersList, workflowOrgTypes, organizations] = await Promise.all([
      this.workflowService.GetWorkflowPartners(workflowId),
      this.workflowService.GetWorkflowOrganizationTypes(workflowId),
      this.organizationService.GetHOOrganizations(this.hasRight(RoleClaimsEnum.AdminCountryOrganization) ? this.props.appState.appUser!.countryId : null),
    ]);

    const workflowOwner = organizations.find((item) => item.id === hoId);

    //let workflowPartners = [] as Organization[];

    workflowPartnersList.forEach((wp) => {
      const partner = organizations.find((item) => item.id === wp.partnerId);

      wp.partner = isNullOrUndefined(partner) ? null : partner;
    });

    const partners = _.cloneDeep(organizations);
    if (
      !isNullOrUndefined(workflowOwner) &&
      isNullOrUndefined(partners.find((item) => item.id === workflowOwner!.id))
    ) {
      partners.push(workflowOwner);
    }

    this.setState({
      workflowPartners: workflowPartnersList,
      //workflowOwnerList: workflowOwners.filter(item => item.isActive === true),
      workflowPartnerList: partners.filter((item) => item.isActive === true),
      isLoading: false,
      workflowId: workflowId
    });
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const workflowPartners = [...this.state.workflowPartners];
    const updatedWorkflowPartnerIndex = workflowPartners.findIndex((o) => o.id === id);

    if (updatedWorkflowPartnerIndex >= 0) {
      workflowPartners[updatedWorkflowPartnerIndex] = {
        ...workflowPartners[updatedWorkflowPartnerIndex],
        isActive: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowPartner(
          workflowPartners[updatedWorkflowPartnerIndex]
        );

        this.setState({ workflowPartners: workflowPartners });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleIsOwnerTariffChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const workflowPartners = [...this.state.workflowPartners];
    const updatedWorkflowPartnerIndex = workflowPartners.findIndex((o) => o.id === id);

    if (updatedWorkflowPartnerIndex >= 0) {
      workflowPartners[updatedWorkflowPartnerIndex] = {
        ...workflowPartners[updatedWorkflowPartnerIndex],
        isOwnerTariff: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowPartner(
          workflowPartners[updatedWorkflowPartnerIndex]
        );

        this.setState({ workflowPartners: workflowPartners });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleHideWorkflowChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const workflowPartners = [...this.state.workflowPartners];
    const updatedWorkflowPartnerIndex = workflowPartners.findIndex((o) => o.id === id);

    if (updatedWorkflowPartnerIndex >= 0) {
      workflowPartners[updatedWorkflowPartnerIndex] = {
        ...workflowPartners[updatedWorkflowPartnerIndex],
        hideWorkflow: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflowPartner(
          workflowPartners[updatedWorkflowPartnerIndex]
        );

        this.setState({ workflowPartners: workflowPartners });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };



  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      // {
      //     name: "owner",
      //     label: this.translatorService.Tranlate("WORKFLOW_PARTNERS_TAB_ENTITY_WORKFLOW_OWNER_HEADER", "Entitate Proprietar Flux"),
      //     options: {
      //         sort: false,
      //         customBodyRender: (value: Organization) => { return (value.displayName) }
      //     } as MUIDataTableColumnOptions
      // },
      {
        name: 'partner',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PARTNERS_TAB_ENTITY_WORKFLOW_PARTNER_HEADER',
          'Entitate Partener Flux'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Organization) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_PARTNERS_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'isOwnerTariff',
        label: this.translatorService.Tranlate('WORKFLOW_PARTNERS_OWNER_TARIFF_HEADER', 'Proprietar Actualizare Tarif'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value ? value : false}
                onChange={(e) => this.handleIsOwnerTariffChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'hideWorkflow',
        label: this.translatorService.Tranlate('WORKFLOW_PARTNERS_HIDE_WORKFLOW_HEADER', 'Ascunde posibilitate dosar nou'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(viewWorkflow: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && viewWorkflow === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && viewWorkflow === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value ? value : false}
                onChange={(e) => this.handleHideWorkflowChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value ? value : false}
                onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      }
    ];

    return columns;
  };

  addWorkflowPartner = async () => {
    try {
      if (
        this.state.workflowPartners.findIndex(
          (item) =>
            this.state.selectedWorkflowPartner.findIndex((itemm) => itemm.id === item.partnerId) !==
            -1
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_PARTNER_EXISTS', 'Partenerul exista deja!'),
          { variant: 'error' }
        );
        return;
      }

      const workflowPartners = [];
      for (const partner of this.state.selectedWorkflowPartner) {
        workflowPartners.push({
          id: 0,
          workflowId: this.state.workflowId,
          //   ownerId: this.state.selectedWorkflowOwner!.id,
          partnerId: partner!.id,
          partner: partner,
          isActive: true
        } as WorkflowPartner);
      }

      await this.workflowService.AddWorkflowPartner(workflowPartners);

      this.setState(
        {
          isLoading: true,
          //selectedWorkflowOwner: null,
          selectedWorkflowPartner: []
        },
        () => {
          this.loadWorkflowPartners();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  // handleWorkflowOwnerChange = (newValue: Organization | null) => {
  //     this.setState({ selectedWorkflowOwner: newValue });
  // }

  handleWorkflowPartnerChange = (newValue: any[]) => {
    this.setState({ selectedWorkflowPartner: newValue });
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addWorkflowPartner}>
              {/* <Autocomplete
                                id="workflowOwner"
                                className="m-2"
                                options={this.state.workflowOwnerList.sort(function (a, b) { return a.displayName.localeCompare(b.displayName) })}
                                value={this.state.selectedWorkflowOwner}
                                onChange={(e: any, newValue: Organization | null) => this.handleWorkflowOwnerChange(newValue)}
                                getOptionLabel={(option: Organization) => option.displayName || ""}
                                renderInput={params => (
                                    <TextValidator {...params}
                                        name="workflowOwner"
                                        value={this.state.selectedWorkflowOwner}
                                        label={this.translatorService.Tranlate("WORKFLOW_PARTNERS_TAB_WORKFLOW_OWNER_LABEL", "Entitate Proprietar Flux")}
                                        fullWidth
                                        validators={['required']}
                                        errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu")]}
                                    />
                                )}
                            /> */}

              <Autocomplete
                id="workflowPartner"
                className="m-2"
                multiple
                options={this.state.workflowPartnerList.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedWorkflowPartner}
                onChange={(e: any, newValue: any[]) => this.handleWorkflowPartnerChange(newValue)}
                getOptionLabel={(option: Organization) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="workflowPartner"
                    value={this.state.selectedWorkflowPartner}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_PARTNERS_TAB_WORKFLOW_PARTNER_LABEL',
                      'Entitate Partener Flux'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate('WORKFLOWS_TAB_PARTNERS', 'Parteneri')}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowPartners}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(WorkflowPartners as any));
