import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsActionCreators,
  VehicleActionCreators
} from '../../store';
import { withSnackbar } from 'notistack';
import {
  CardContent,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { ButtonGroup, Card, CardFooter } from 'reactstrap';
import { ScaleLoader } from 'react-spinners';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { MavoImageUploadSizeLimit, MavoVehicelAttachementTypeCodeEnum, MavoVehicleStatusCodeEnum, RefConstants, disabledMavoVehicleStatuses} from '../../helpers/Constants';
import DescriptionIcon from '@material-ui/icons/Description';
import { IReferential } from '../../interfaces/IReferential';
import _ from 'lodash';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { MavoVehicleAttachment } from '../../interfaces/Vehicle';
import { MavoVehicleDetailsProps } from '../cases/vehicleDetails/MavoVehicleDetailsProps';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { IMavoAttachmentDataContainerProps } from '../cases/vehicleDetails/IMavoAttachmentDataContainerProps';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { isNullOrUndefined } from 'util';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileViewer from 'react-file-viewer';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileSaver from 'file-saver';


interface IMavoAttachmentDataContainerState {
  isLoading: boolean;
  executing: boolean;
  attachmentTypeList: IReferential[];
  previewAttachFile: any;
  previewAttachFileExtension: string;
  isDialogOpen: boolean;
}

class MavoAttachmentDataContainer extends React.PureComponent<
  IMavoAttachmentDataContainerProps,
  IMavoAttachmentDataContainerState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;
  
  static contextType = ApplicationContext;
  static displayName = 'MavoAttachmentDataContainer';

  state = {
    isLoading: false,
    executing: false,
    isValuationOpen: false,
    isOpen: false,
    dialogVisible: false,
    attachmentTypeList: [],
    previewAttachFile: '',
    previewAttachFileExtension: '',
    isDialogOpen: false
  } as IMavoAttachmentDataContainerState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );
  }

  loadData = async () => {
    const {
      vehicleAttachmentTypeRef
    } = RefConstants;

    const [
      vehicleAttachmentTypeList,
      vehicleAttachmentList
    ] = await Promise.all([
      this.appReferentialService.Get(vehicleAttachmentTypeRef?.baseUrl),
      this.vehicleService.GetMavoVehicleAttachments(this.props.mavoVehicleState.mavoVehicle.id)
    ]);

    this.props.setMavoVehicleObject({
      ...this.props.mavoVehicleState.mavoVehicle,
      mavoVehicleAttachments: vehicleAttachmentList
    })

    vehicleAttachmentTypeList.forEach((attachmentType) => {
      attachmentType.isLoading = false;
    });

    this.setState({
      isLoading: false,
      attachmentTypeList: vehicleAttachmentTypeList
    });
  };
    

  isDisabled = (): boolean => {
    //1.3	Actualizare/modificare
    if (this.props.mavoVehicleState.mavoVehicle.id !== 0) {
      //daca vehiculul este in stare “Licitatie”, se pot actualiza doar informatiile «Stare vehicul», «Despre vehicul (Dotari)» si «Poze» 
      if (this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code === MavoVehicleStatusCodeEnum.LICITATIE.toString()) {
        return false;
      }
      //daca vehiculul este in orice alta stare («Comanda», «Aviz», «Factura»), nu se pot actualiza nici un fel de informatii referitoare la acest vehicul
      return  disabledMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    } 
    return false;
  };


  handlePreviewAttachment = async (originalFileName: string, filename: string) => {
    try {
      const fileBlob = await this.vehicleService.GetMavoVehicleAttachment(filename);
      const url = URL.createObjectURL(fileBlob);
      const extension = originalFileName.split('.').pop();

      if (isNullOrUndefined(extension)) {
        return;
      }

      this.setState({
        previewAttachFile: url,
        previewAttachFileExtension: extension!.toLowerCase(),
        isDialogOpen: true
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  renderPreviewAttachment = () => {
    return (
      <FileViewer
        fileType={this.state.previewAttachFileExtension}
        filePath={this.state.previewAttachFile}
      />
    );
  };

  canUpload = (attachemntType: IReferential): boolean => {
    const files = this.props.mavoVehicleState.mavoVehicle.mavoVehicleAttachments.filter(
      (attach) => attach.attachmentTypeId === attachemntType.id
    );

    if (attachemntType.code == MavoVehicelAttachementTypeCodeEnum.IMG) {
      if (files.length >= 10) {
        return false;
      }
    }
    
    if (attachemntType.code == MavoVehicelAttachementTypeCodeEnum.OTHER) {
      if (files.length >= 2) {
        return false;
      }
    }

    return true;
  };

  
  setAttachmentTypeList = (attachmentType: IReferential, value: boolean) => {
    const attachmentTypeList = _.cloneDeep(this.state.attachmentTypeList);
    for (let i = 0; i < attachmentTypeList.length; i++) {
      if (attachmentTypeList[i].id === attachmentType.id) {
        attachmentTypeList[i].isLoading = value;
      }
    }

    return attachmentTypeList;
  };

  /**
   * Get dimensions of any image by url
   * @param {string} url
   * @param {number} [rejectTimeout] timeout for reject
   * @return {Promise<{ width: number, height: number }>}
   */
  reactImageSize = (
    url: string,
    rejectTimeout: number
  ): Promise<{ width: number; height: number }> =>
    new Promise((resolve, reject) => {
      let timer: NodeJS.Timeout | null = null;

      const img = new Image();

      img.addEventListener('load', () => {
        if (timer) {
          clearTimeout(timer);
        }

        resolve({ width: img.width, height: img.height });
      });

      img.addEventListener('error', (event) => {
        if (timer) {
          clearTimeout(timer);
        }

        reject(`${event.type}: ${event.message}`);
      });

      img.src = url;

      if (rejectTimeout) {
        timer = setTimeout(() => reject('Timeout exception'), rejectTimeout);
      }
  });

  handleUploadAttachment = async (
    e: React.ChangeEvent<HTMLInputElement>,
    attachmentType: IReferential
  ) => {
    try {
      if (e.target.files === null) {
        return;
      }
      e.persist();

      this.setState({
        attachmentTypeList: this.setAttachmentTypeList(attachmentType, true)
      });

      const attachments: MavoVehicleAttachment[] = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files![i];
        if (attachmentType.code == MavoVehicelAttachementTypeCodeEnum.IMG) {
          const fileSizeKB = file.size / 1024;
          if (fileSizeKB > MavoImageUploadSizeLimit.MaxSizeKB) {
            this.props.enqueueSnackbar(
              this.translatorService.Tranlate('MAVO_VEHICLE_IMG', 'Imaginea ') + 
                file.name + 
                this.translatorService.Tranlate('MAVO_VEHICLE_MAX_SIZE', 'depaseste dimensiunea maxima de 1MB.'),
              {
                variant: 'warning',
                autoHideDuration: 5000
              }
            );
            continue;
          }
        }

        attachments.push({
          id: 0,
          mavoVehicleId: this.props.mavoVehicleState.mavoVehicle.id,
          attachmentTypeId: attachmentType.id,
          attachmentType: attachmentType,
          file: file,
          originalFileName: file.name
        } as MavoVehicleAttachment);
      }

      if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
        this.props.setMavoVehicleObject({
          ...this.props.mavoVehicleState.mavoVehicle,
          mavoVehicleAttachments: [
            ...this.props.mavoVehicleState.mavoVehicle.mavoVehicleAttachments,
            ...attachments
          ]
        });
      } else {
        if (attachments.length > 0) {
          const newFiles = await this.vehicleService.AddMavoVehicleAttachments(attachments);
          this.props.setMavoVehicleObject({
            ...this.props.mavoVehicleState.mavoVehicle,
            mavoVehicleAttachments: [
              ...this.props.mavoVehicleState.mavoVehicle.mavoVehicleAttachments,
              ...newFiles
            ]
          });        
        }
      }

      this.setState({
        attachmentTypeList: this.setAttachmentTypeList(attachmentType, false)
      });

      e.target.value = '';
    } catch (error) {
      console.log(error);
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
      e.target.value = '';
    }
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      await this.vehicleService.RemoveMavoVehicleAttachment(fileName);

      const mavoVehicleAttachments = [...this.props.mavoVehicleState.mavoVehicle.mavoVehicleAttachments];
      const index = mavoVehicleAttachments.findIndex((item) => item.id === id);
      mavoVehicleAttachments.splice(index, 1);

      this.props.setMavoVehicleObject({
        ...this.props.mavoVehicleState.mavoVehicle,
        mavoVehicleAttachments: [...mavoVehicleAttachments]
      });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.vehicleService.GetMavoVehicleAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  renderAttachments = (attachemntType: IReferential) => {
    const attachments = this.props.mavoVehicleState.mavoVehicle.mavoVehicleAttachments.filter(
      (item) => item.attachmentTypeId === attachemntType.id
    );
    const attachmentsToRender = [] as any[];

    attachments.forEach((attach, index) => {
      attachmentsToRender.push(
        <React.Fragment key={index}>
          <Grid container spacing={0}>
            <Grid item xs={1}>
              <div className="d-flex align-items-center">
               {attach.id ? (
                  <IconButton
                    aria-label="preview"
                    color="inherit"
                    className="text-primary"
                    size="small"
                    onClick={(e) => {
                      this.handlePreviewAttachment(attach.originalFileName, attach.fileName);
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton> ) 
                : null} 
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className="align-box-row text-wrap " style={{ wordBreak: 'break-all' }}>
                <small>
                  <Button
                    color="primary"
                    className="m-2 text-primary"
                    onClick={() => {
                      if (attach.id !== 0) {
                        this.downloadFile(attach.fileName, attach.originalFileName)
                      }
                    }}
                  >
                    <span>{attach.originalFileName}</span>
                  </Button>{' '}
                </small>
              </div>
            </Grid>
            {attach.id !== 0 && !this.isDisabled() && !this.props.disabled  ? (
              <Grid item xs={1}>
                <div className="d-flex align-items-center">
                  <IconButton
                    aria-label="delete"
                    color="inherit"
                    className="text-danger"
                    size="small"
                    onClick={(e) => {
                      this.handleDeleteAttachment(
                        attach.id,
                        attach.fileName
                      );
                    }}
                  >
                    <DeleteOutlineTwoToneIcon />
                  </IconButton>
                </div>
             </Grid>
            ) : null}
          </Grid>
        </React.Fragment>
      );
    });
    return attachmentsToRender;
  };

  renderWorkflowAttashmentTypes = () => {
    const attachmentTypes = [] as any[];
    const attachmentTypeList = this.state.attachmentTypeList;
    if (attachmentTypeList.length !== 0) {
      attachmentTypeList.forEach((attach, index) => {
        attach.fileInputKey = Date.now();
        attachmentTypes.push(
          <div key={index} className="p-1 w-50 d-flex justify-content-center">
            <Card className="card-box w-100 shadow-none">
              <div className="card-header text-center">
                <div className="card-header--title">
                  <b>
                    {attach!.displayName}
                  </b>
                </div>
              </div>

              <CardContent className="p-3 flex-grow-1">
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={attach.isLoading} />
                </div>
                {!attach.isLoading ? (
                  <div className="file-manager-wrapper h-100">
                    {this.props.mavoVehicleState.mavoVehicle.mavoVehicleAttachments.filter(
                      (item) => item.attachmentTypeId === attach.id
                    ).length !== 0 ? (
                      <div className="table-responsive">
                        <table className="table table-striped table-hover text-nowrap mb-0">
                          <tbody>
                            <tr>
                              <td>{this.renderAttachments(attach)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="h-100 align-self-center text-center">
                        <DescriptionIcon
                          className="h-100"
                          color="secondary"
                          fontSize="large"
                        ></DescriptionIcon>
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </CardContent>
              <CardFooter className="p-3 border-0">
                <ButtonGroup className="w-100">
                  <Button
                    variant="contained"
                    color="primary"
                    className="d-block w-100 text-center shadow-none"
                    disabled={this.isDisabled() || !this.canUpload(attach) || this.props.disabled}
                  > 
                    <input
                      className="d-none"
                      key={attach.fileInputKey}
                      id={'contained-button-file' + attach.id}
                      disabled={this.isDisabled() || !this.canUpload(attach) || this.props.disabled}
                      onChange={(e) => {
                        return this.handleUploadAttachment(e, attach);
                      }}
                      type="file"
                      accept={attach!.code == MavoVehicelAttachementTypeCodeEnum.IMG ? 'image/*' : '*'}
                      multiple={true}
                    />
                    <label
                      htmlFor={'contained-button-file' + attach.id}
                      className="w-100 mb-0"
                    >
                      Upload
                    </label>
                  </Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          </div>
        );
      });
    }
    return attachmentTypes;
  };

  closeDialog = async () => {
    this.setState({
      isDialogOpen: false
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;


    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="d-flex flex-wrap m-1">{this.renderWorkflowAttashmentTypes()}</div>
        ) : null}
         <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          maxWidth="md"
          fullWidth
          fullScreen={false}
          classes={{ paper: 'dialogPaper' }}
          scroll="body"
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate('CASE_PREVIEW_DOC', 'Previzualizare document')}
                </Typography>
              </Grid>
              <Grid className="text-right" item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container alignContent="center">
              <Grid item xs={12}>
                <Box height="65vh" className="text-center">
                  {this.renderPreviewAttachment()}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: MavoVehicleDetailsProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicle: state.mavoVehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators }
)(withSnackbar(MavoAttachmentDataContainer as any));
