import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Box, Select, MenuItem, Card, CardContent, Tabs, Tab } from '@material-ui/core';
import { ITranslatorService } from '../../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../../context/Contexts';
import { OrganizationFormAction } from '../../../../interfaces/Organization';
import { IOrganizationService } from '../../../../services/Interfaces/IOrganizationService';
import { withSnackbar, ProviderContext } from 'notistack';
import Referentials from '../../../../helpers/Referentials.json';
import { ReferentialCode, WorkflowFormActionTypeCode } from '../../../../helpers/Constants';
import { IReferentialService } from '../../../../services/Interfaces/IReferentialService';
import { ScaleLoader } from 'react-spinners';
import MaterialTable, { Column, EditComponentProps, Icons } from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { forwardRef } from 'react';
import { IReferential } from '../../../../interfaces/IReferential';
import { isNullOrUndefined } from 'util';
import { IWorkflowService } from '../../../../services/Interfaces/IWorkflowService';
import { WorkflowFormAction, Workflow } from '../../../../interfaces/Workflow';
import { FormCode } from '../../../../helpers/forms/FormCode';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

interface IWorkflowFormActionTransStatusState {
  transitionStatus: WorkflowFormAction[];
  isLoading: boolean;
  statusTypeList: IReferential[];
  tranStatusActionType: IReferential | null;
  workflows: Workflow[] | null;
}
export interface ExternalWorkflowFormActionTransStatusProps {
  workflowFormId: number;
  workflowFormCode: string | null;
  currentWorkflowId: number | null;
}

type WorkflowFormActionTransStatusProps = ExternalWorkflowFormActionTransStatusProps & {
  appState: AppState;
} & ProviderContext &
  RouteComponentProps<{ id: string }>;

class WorkflowFormActionTransStatus extends React.PureComponent<
  WorkflowFormActionTransStatusProps,
  IWorkflowFormActionTransStatusState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    transitionStatus: [],
    isLoading: true,
    statusTypeList: [],
    tranStatusActionType: null,
    workflows: null
  } as IWorkflowFormActionTransStatusState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData();
      }
    );
  };

  loadData = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    try {
      const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseStatus);
      const refActionTypes = Referentials.referential.find(
        (item) => item.code === ReferentialCode.ActionType
      );
      const [data, caseStatus, actionTypes] = await Promise.all([
        this.workflowService.GetWorkflowSectionStepFormActions(this.props.workflowFormId),
        this.appReferentialService.Get(ref!.baseUrl),
        this.appReferentialService.Get(refActionTypes!.baseUrl)
      ]);

      const workflows =
        this.props.workflowFormCode === FormCode.CASE_BACK_TO_SERVICE
          ? await this.workflowService.GetWorkflows(parseInt(this.props.match.params.id))
          : [];

      const tranStatusActionType = actionTypes.find(
        (item) => item.code === WorkflowFormActionTypeCode.STATUS_TRANSITION
      );

      // filter out inactive workflows and the current workflow
      const activeWf = workflows.filter(
        (wf) => wf.isActive && wf.id !== this.props.currentWorkflowId
      );
      data.forEach((item) => {
        const fromCs = caseStatus.find((itemCS) => itemCS.id === item.fromStatusId);
        const toCs = caseStatus.find((itemCS) => itemCS.id === item.toStatusId);
        const toWf = workflows.find((wf) => wf.id === item.toWorkflowId);
        item.fromStatus = isNullOrUndefined(fromCs) ? null : fromCs;
        item.toStatus = isNullOrUndefined(toCs) ? null : toCs;
        item.toWorkflow = toWf || null;
      });

      this.setState({
        transitionStatus: data.filter((item) => item.actionTypeId === tranStatusActionType!.id),
        isLoading: false,
        statusTypeList: caseStatus.filter((item) => item.isActive == true),
        tranStatusActionType: isNullOrUndefined(tranStatusActionType) ? null : tranStatusActionType,
        workflows: activeWf
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const lookup = this.state.statusTypeList.reduce(function (
      acc: any,
      cur: { id: number; displayName: string },
      i: number
    ) {
      acc[cur.id] = cur.displayName;

      return acc;
    },
    {});

    return [
      {
        field: 'fromStatusId',
        title: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_ACTION_TRANS_STATUS_FROM_STATUS_HEADER',
          'Din status'
        ),
        searchable: true,
        lookup: lookup,
        render: (rowData: WorkflowFormAction) => {
          return rowData.fromStatus!.displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              fullWidth={true}
              required
              value={props.value || ''}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.statusTypeList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'toStatusId',
        title: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_ACTION_TRANS_STATUS_TO_STATUS_HEADER',
          'In status'
        ),
        searchable: true,
        lookup: lookup,
        render: (rowData: WorkflowFormAction) => {
          return rowData.toStatus!.displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              required
              fullWidth={true}
              value={props.value || ''}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.statusTypeList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'toWorkflowId',
        title: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_ACTION_TRANS_STATUS_TO_WORKFLOW_HEADER',
          'In workflow'
        ),
        hidden: this.props.workflowFormCode !== FormCode.CASE_BACK_TO_SERVICE,
        searchable: true,
        lookup: lookup,
        render: (rowData: WorkflowFormAction) => {
          return rowData.toWorkflow?.displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              fullWidth={true}
              required
              value={props.value || ''}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.workflows?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'dateModification',
        title: this.translatorService.Tranlate(
          'EDIT_ORGANIZATION_FORM_ACTION_TRANS_STATUS_MODIFIED_DATE_HEADER',
          'Data modificare'
        ),
        searchable: true,
        render: (rowData: any) => {
          return moment
            .utc(rowData.dateModification)
            .local()
            .toDate()
            .toLocaleString(this.props.appState.language);
        },
        editComponent: (props: EditComponentProps<any>) => {
          return <Label>{new Date().toLocaleString(this.props.appState.language)}</Label>;
        }
      } as Column<any>
    ];
  };

  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        deleteTooltip: this.translatorService.Tranlate('DELETE', 'Sterge'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza'),
          deleteText: this.translatorService.Tranlate(
            'DELETE_TEXT',
            'Sigur doriti sa stergeti aceasta inregistrare?'
          )
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };

  // onRowUpdate = (newData: OrganizationFormTransactionStatus, oldData?: OrganizationFormTransactionStatus): Promise<void> =>
  //     new Promise(async (resolve, reject) => {
  //         debugger
  //         if (oldData) {

  //             try {
  //                 debugger
  //                 newData.fromStatus = null;
  //                 newData.toStatus = null;
  //                 await this.organizationService.UpdateOrganizationFormC(newData);
  //                 await this.loadData();

  //                 this.props.enqueueSnackbar(this.translatorService.Tranlate("SUCCES_MSG", "OK"), { variant: 'success' });
  //                 resolve();

  //             } catch (error) {
  //                 this.props.enqueueSnackbar(this.translatorService.Tranlate("ERROR_MSG", "Eroare"), { variant: 'error' });
  //                 reject();
  //             };

  //         }

  //     });

  onRowDelete = (oldData: OrganizationFormAction): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.workflowService.RemoveWorkflowSectionStepFormAction(oldData.id);
          await this.loadData();

          // const orgFormtransitionStatusList = [...this.state.transitionStatus];
          // const index = orgFormtransitionStatusList.findIndex(item => item.id === oldData.id);
          // orgFormtransitionStatusList.splice(index,1);
          // this.setState({transitionStatus: orgFormtransitionStatusList});

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });

          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowAdd = (newData: WorkflowFormAction): Promise<void> =>
    new Promise(async (resolve, reject) => {
      try {
        if (isNullOrUndefined(newData.fromStatusId) || isNullOrUndefined(newData.toStatusId)) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ERROR_MSG_MANDATORY_FIELDS',
              'Campurile sunt obligatorii'
            ),
            { variant: 'error' }
          );
          reject();
          return;
        }

        if (
          this.state.transitionStatus.findIndex(
            (item) =>
              item.fromStatusId === newData.fromStatusId && item.toStatusId === newData.toStatusId
          ) !== -1
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ERROR_MSG_FORM_ACTION_TRANS_STATUS_EXISTS',
              'Tranzitia de stare exista deja!'
            ),
            { variant: 'error' }
          );
          reject();
          return;
        }

        newData.workflowSectionStepFormId = this.props.workflowFormId;
        newData.actionTypeId = this.state.tranStatusActionType!.id;
        // newData.workflowPartnerId = null;
        // newData.emailRecipientTypeId = null;
        // newData.emailTemplateId = null;
        // newData.sendMailActionTypeId = null;
        // newData.sendMailStatusId= null;

        await this.workflowService.AddWorkflowSectionStepFormAction(newData);
        await this.loadData();
        // const orgFormtransitionStatusList = [...this.state.transitionStatus];
        // orgFormtransitionStatusList.push({
        //     id: this.state.transitionStatus.length,
        //    // formId: this.props.organizationFormId,
        //     actionTypeId: 1,
        //     fromStatusId: newData.fromStatusId,
        //     fromStatus: this.state.statusTypeList.find(item=>item.id === newData.fromStatusId)!,
        //     toStatusId: newData.toStatusId,
        //     toStatus: this.state.statusTypeList.find(item=>item.id === newData.toStatusId)!,
        //     isLastUser: null,
        //  //   organizationId:null,
        //   //  organization: null,
        //     userId:null,
        //     user: null,
        //   //  stateId: null,
        //    // state: null,
        //     //sendEmailActionId: null,
        //    // sendEmailAction: null,
        //     //sendEmailToId: null,
        //     //sendEmailTo: null,
        //      emailTemplate:null,
        //      emailTemplateId:null

        // }  );
        //this.setState({transitionStatus: orgFormtransitionStatusList});

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });

  public renderTable = () => {
    return (
      <MaterialTable
        icons={tableIcons}
        columns={this.getColumns()}
        data={this.state.transitionStatus}
        title=" "
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: any) => this.onRowAdd(newData),
          // onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData),
          onRowDelete: (oldData: any) => this.onRowDelete(oldData)
        }}
      />
    );
  };
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate(
                    'EDIT_ORGANIZATION_FORM_ACTION_TRANS_STATUS_TAB',
                    'Status tranzactie'
                  )}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderTable() : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalWorkflowFormActionTransStatusProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(WorkflowFormActionTransStatus as any));
