import React, { useState, useContext } from 'react';

import { Tab, Tabs } from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import VehicleDashboard from './vehicles/VehicleDashboard';
import AquisitionDashboard from './acquisitions/AcquisitionDashboard';
import SalesDashboard from './sales/SalesDashboard';
import { ApplicationContext } from '../../context/Contexts';

enum DashboardTab {
  VEHICLES = 0,
  AQUISITIONS = 1,
  SALES = 2
}

const Dashboards: React.FC = () => {
  const context = useContext(ApplicationContext);
  const [selectedTab, setSelectedTab] = useState<DashboardTab>(DashboardTab.VEHICLES);

  return (
    <div>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="secondary"
        variant="standard"
        onChange={(e: any, newTab: DashboardTab) => setSelectedTab(newTab)}
      >
        <Tab
          className="text-capitalize"
          label={context.translatorService.Tranlate('MENU_VEHICLES', 'Stoc vehicule')}
        />
        <Tab
          className="text-capitalize"
          label={context.translatorService.Tranlate('DASHBOARD_ACQUISITION_TAB', 'Achizitii')}
        />
        <Tab
          className="text-capitalize"
          label={context.translatorService.Tranlate('DASHBOARD_SALES_TABLE_TITLE', 'Vanzari')}
        />
      </Tabs>
      <div className="mt-4">
        <TabPanel value={selectedTab} index={0}>
          <VehicleDashboard />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <AquisitionDashboard />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <SalesDashboard />
        </TabPanel>
      </div>
    </div>
  );
};

export default Dashboards;
