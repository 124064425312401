import { Car, Client } from "../components/agenda/agenda.types";
import { ITranslation } from "../interfaces/IReferential";

export type AppointmentStatusType = {
  code: string;
  name: string,
  id: number;
  translations: ITranslation[];
}

export type AppointmentHistoryType = {
  date:string;
  appointmentStatusId:number;
  modifiedBy:string;
  modifiedByUser?:any;
  appointmentStatus?: AppointmentStatusType;
  comment:string;

}

export type AppointmentFiltersPayloadType = {
  appointmentIds: string[];
  formatedDateList: string[];
  formatedHourList: string[];
  makeList: string[];
  modelList: string[];
  plateNumberList: string[];
  formattedDateCreationList: string[];
  formattedHourCreationList: string[];
  appointmentStatuses: AppointmentStatusType[];
  appAppointmentIds: number[];
  appointmentCategoryTypes: AppointmentCategoryType[];
}

export type AppointmentCategoryType = {
  code: string;
  name: string,
  id: number;
  translations: ITranslation[];
}

export type AppointmentType = {
  id: number;
  appAppointmentId?:number;
  id_service: number;
  id_user: number;
  id_car: number;
  nr_km: number;
  type: string;
  datetime: string;
  confirmation: boolean;
  status: number;
  created_at: string;
  updated_at: string;
  reschedule: string;
  final: string;
  schedule_rem: number;
  schedule_notif: number;
  cancel: string;
  callresch: number;
  final_date_time: string;
  obs: string;
  contactp: string;
  date: string;
  appointmentStatus?: AppointmentStatusType
  appointmentClient?:Client
  vehicle: Car
  appointmentJobTypes: Option[];
  jobDetails?:string,
  appEmail?:string,
  appFirstName?:string,
  appLastName?:string,
  appPhone?:string,
  appUsername?:string
  appointmentStatusHistories?:AppointmentHistoryType[]
  caseId?:number;
  appointmentStatusId?:number;
  appointmentClientSelections: AppointmentClientSelection[],
  serviceId:number,
  parentId?:number;
  appointmentCategoryType: AppointmentCategoryType;
  hideCancelButton: boolean;
};

export interface Option {
  name: string;
  id: number;
  jobTypeId?:number;
  translations: ITranslation[];
}
export type AppointmentsFiltersType = {
  dateFilterStartDate?: string,
  datefilterEndDate?: string,
  text?: string,
  page?:number,
  pageSize?:number;
  formatedDateList?:string;
  includedAdditionalData?:boolean;
}

export type ResponseAppointmentsData =  {
  appointments: AppointmentType[];
  page: number;
  total: number;
}

export type CarRequestType = {
  vin: string | null;
  firstPlateDate: string | null;
  plateNumber: string | null;
  numberOfKilometers: string | null;
  model: string | null;
  make: string | null
}

export type AppointmentRequestPayload ={
  serviceId?: number,
  appointmentClient?: Client | null,
  vehicle: CarRequestType,
  date: string,
  appointmentJobTypes?: Array<{id:number, name:string}>,
  jobDetails?: string,
  appFirstName: string,
  appLastName: string,
  appEmail: string,
  appPhone: string,
  appUsername: string,
  inAppointment?: boolean,
  appointmentCategoryTypeId:number
  parentId?:number;
  workflowId?:number;
  caseId?:number

}

export type ChangeAppointmentStatusPayload = {
  appointmentId:number,
  statusId:number,
  date?:string
  appointmentClientSelectionId?: number;
  comment?:string
}

export interface AppointmentClientSelection {
  id: number;
  appointmentId: number;
  date: string;
  isSelected: boolean;
  isClientSelection: boolean;
}

export enum AppointmentFilterStateKeys {
  appointmentIds = 0,
  formattedDateCreationList = 1,
  formattedHourCreationList = 2,
  makeList = 3,
  modelList = 4,
  plateNumberList = 5,
  formatedDateList = 6,
  formatedHourList = 7,
  appointmentStatuses = 8,
  appAppointmentIds = 9,
  appointmentCategoryTypes = 10
}
