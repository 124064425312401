import React, { Fragment, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { AGENDA_SET_STATUS_LIST } from '../../store/actions/ActionTypes';
import { Box, Button, IconButton, Tab, Tabs } from '@material-ui/core';
import { Card } from 'reactstrap';
// Components
import {  getStatusList } from '../../utils/appointments';
import {  AppointmentHistoryType, AppointmentStatusType, AppointmentType } from '../../types/Agenda';
import {  getAppointment } from '../agenda/agenda.requests';
import { useHistory, useParams } from 'react-router';
import Loader from '../Loader';
import { TranslatorService } from '../../services/TranslatorService';
import { PageTitle } from '../../layout-components';
import TabPanel from '../_shared/Tabs';
import { AppUserService } from '../../services/AppUserService';
import { useSnackbar } from 'notistack';
import AppointmentDetails from './AppointmentDetails';
import AppointmentHistory from './AppointmentHistory';
import { CreateNewFolder, Folder } from '@material-ui/icons';
import { NewCaseAppointment } from '../agenda/NewCaseAppointment';
import AppointmentTabDocumentsForm from './AppointmentTabDocumentsForm';

const AppointmentPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const translatorService = new TranslatorService();
  const statusList = useAppSelector((state) => state.agenda?.statusList)
  const [appointment, setAppointment] = React.useState<AppointmentType>();
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [appointmentHistory, setAppointmentHistory] = useState<Array<AppointmentHistoryType>>()
  const [appointmentId, setAppointmentId] = useState<number | null>(null);

  const loadAppointmentStatusHistory = async (appointmentHistoryList?:Array<AppointmentHistoryType>) => {
    if (!appointmentHistoryList) {
      return;
    }
    const appUserServiceInstance = new AppUserService();
    const futureAppointmentHistory: Array<AppointmentHistoryType> = [];
    appointmentHistoryList?.forEach(async (appointmentObj:AppointmentHistoryType)=>{
      const user = await appUserServiceInstance.GetUserInfo(appointmentObj.modifiedBy);
      const arrayOfStatus: Array<AppointmentStatusType> = Object.values(statusList);
      const desiredStatus: AppointmentStatusType | undefined = arrayOfStatus.find((status: any) => status.id === appointmentObj.appointmentStatusId);
      futureAppointmentHistory.push({
        ...appointmentObj,
        modifiedByUser:user,
        appointmentStatus:desiredStatus
      })
    })
    setAppointmentHistory(futureAppointmentHistory);
  };

  const getSelectedAppointment = async () =>{
    const res = await getAppointment(id);
    const appointment = res.data as AppointmentType
    if(res.error || !appointment){
      setIsLoading(false);
      return;
    }
    loadAppointmentStatusHistory(appointment?.appointmentStatusHistories);
    setAppointment(appointment);
    setIsLoading(false);
  }

  React.useEffect(() => {
    if(!statusList.INITIATA){
      getStatusList(setIsLoading, (value:any)=>{
        dispatch({
          type: AGENDA_SET_STATUS_LIST,
          payload: value,
        });
      }, enqueueSnackbar)
    }else if (id) {
      setIsLoading(true);
      getSelectedAppointment();
    }
  }, [statusList]);

  const onTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };


  return (
    <>{isLoading ? <Loader/>:<Fragment>
        <PageTitle
        titleHeading={`${translatorService.Tranlate('APPOINTMENT_DETAILS', 'Detalii programare')}${appointment?.vehicle?.plateNumber ? ` - ${appointment?.vehicle?.plateNumber}` :''} - ${appointment?.id}`}
          backButtonName={translatorService.Tranlate('BACK_TO_APPOINTMENTS_LIST', 'Programari')}
          onClickBack={()=>{history.push('/agenda')}}
          actionButton={appointment && appointment?.appointmentStatus?.code === statusList.PAYMENT_CONFIRMATION?.code || appointment?.caseId ?
            <Button
              aria-label="add"
              style={{ backgroundColor: '#f67346', color:'white' }}
              onClick={async () => {
                if (appointment?.caseId) {
                  history.push('/cases/' + appointment?.caseId, 'Case xx');
                }
                else {
                  setAppointmentId(appointment?.id);
                }
              }}
            >
              {appointment?.caseId ? <Folder style={{ color: 'white', marginRight: 4 }} /> : <CreateNewFolder style={{ color: 'white', marginRight: 4 }} />}
              {appointment?.caseId ? translatorService.Tranlate('APPOINTMENT_VIEW_CASE', 'Vezi dosar') : translatorService.Tranlate('APPOINTMENT_CREATE_CASE', 'Creeaza dosar') }
            </Button> : null}
          />
          
        <Fragment>
          <Card>
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '6px'
                }
              }}
              value={selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="fullWidth"
              centered={true}
              color="primary"
              onChange={onTabChange}
              aria-label="disabled tabs example"
            >
              <Tab label={translatorService.Tranlate('APPOINTMENT_DETAILS_TAB', 'Programare')} />
              <Tab label={translatorService.Tranlate('APPOINTMENT_DOCUMENTS_TAB', 'Documente')} />
              <Tab label={translatorService.Tranlate('APPOINTMENT_HISTORY_TAB', 'Istoric')} />
            </Tabs>
          </Card>
          <div>
            <TabPanel value={selectedTab} index={0}>
              <AppointmentDetails appointment={appointment} 
                  setIsLoading={setIsLoading}
                  getSelectedAppointment={getSelectedAppointment}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <AppointmentTabDocumentsForm
                appointmentId={id}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <AppointmentHistory appointmentHistory={appointmentHistory} isLoading={isLoading}/>
            </TabPanel>
          </div>
        </Fragment>
      {appointmentId ? <NewCaseAppointment appAppointmentId={appointmentId} close={() => setAppointmentId(null)} /> : null}
    </Fragment>
  }</>
  );
};

export default AppointmentPage;
