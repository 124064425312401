import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';
import { ApplicationState, CaseSettingsActionCreators, VehicleActionCreators } from '../../store';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { MavoVehicleOptionDetailsProps } from '../cases/vehicleDetails/MavoVehicleDetailsProps';
import { IMavoVehicleOptionsContainerProps } from '../cases/vehicleDetails/IMavoVehicleOptionsContainerProps';
import { Checkbox, Grid } from '@material-ui/core';
import { MavoVehicleStatusCodeEnum, RefConstants, disabledMavoVehicleStatuses, disabledNewMavoVehicleStatuses } from '../../helpers/Constants';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Autocomplete } from '@material-ui/lab';
import { IReferential } from '../../interfaces/IReferential';
import * as R from 'ramda';
import { Utils } from '../../helpers/Utils';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import NumberFormat from 'react-number-format';

interface IMavoVehicleOptionsContainerState {
  isLoading: boolean;
  executing: boolean;
  isOpen: boolean;
  dialogVisible: boolean;
  isDisabled: boolean;
  vehicleTypes: IReferential[];
}

class MavoVehicleOptionsContainer extends React.PureComponent<
  IMavoVehicleOptionsContainerProps,
  IMavoVehicleOptionsContainerState
> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    isLoading: false,
    executing: false,
    isValuationOpen: false,
    isOpen: false,
    dialogVisible: false,
    isDisabled: false,
    vehicleTypes: [] as IReferential[]
  } as IMavoVehicleOptionsContainerState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadData();
      }
    );

  }

  loadData = async () => {
    const {
      vehicleTypeRef
    } = RefConstants;

    const vehicleTypes = await this.appReferentialService.Get(vehicleTypeRef!.baseUrl);
    
    this.setState(
      {
        isLoading: false,
        isDisabled: this.isDisabled(),
        vehicleTypes: vehicleTypes
      }
    );

  };

  isDisabled = (): boolean => {
    if (this.props.disabled) {
      return true;
    }
    
    //1.2	Actualizare/adaugare
    if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
      //Nu pot fi modificate informatii vehicul din tab-urile «Informatii generale», «Stare vehicul», 
      //«Despre vehicul (Dotari)» si «Date STOC» daca vehiculul este in una din starile : «Licitatie», «Comanda», «Aviz», «Factura»
      return  disabledNewMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    } else  {
      //daca vehiculul este in stare “Licitatie”, se pot actualiza doar informatiile «Stare vehicul», «Despre vehicul (Dotari)» si «Poze» 
      if (this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code === MavoVehicleStatusCodeEnum.LICITATIE.toString()) {
        return false;
      }
      //daca vehiculul este in orice alta stare («Comanda», «Aviz», «Factura»), nu se pot actualiza nici un fel de informatii referitoare la acest vehicul
      return  disabledMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    }
  };

  onChange =
    (fieldName: string, fieldNameId?: string) => (e: any, newValue?: IReferential | null) => {
      !R.isNil(fieldNameId)
        ? 
          this.props.setMavoVehicleObject({ 
            ...this.props.mavoVehicleState.mavoVehicle, 
            vehicleOptionInfo: {
              ...this.props.mavoVehicleState.mavoVehicle.vehicleOptionInfo, 
              [fieldName]: newValue || e.target.value,
              [fieldNameId]: Utils.GetIdOrNull(newValue)
            }
          })
        : 
          this.props.setMavoVehicleObject({ 
            ...this.props.mavoVehicleState.mavoVehicle, 
            vehicleOptionInfo: {
              ...this.props.mavoVehicleState.mavoVehicle.vehicleOptionInfo, 
              [fieldName]: newValue || e.target.value
            }
          })
  };

  onCheckBoxChange =
    (fieldName: string) => (checked: boolean) => {
      this.props.setMavoVehicleObject({ 
        ...this.props.mavoVehicleState.mavoVehicle, 
        vehicleOptionInfo: {
          ...this.props.mavoVehicleState.mavoVehicle.vehicleOptionInfo, 
          [fieldName]: checked
        }
      })
  };


  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };

  onVehicleChange =
  (fieldName: string, fieldNameId?: string) => (e: any, newValue?: IReferential | null) => {
    !R.isNil(fieldNameId)
      ? 
        this.props.setMavoVehicleObject({ 
          ...this.props.mavoVehicleState.mavoVehicle, 
          [fieldName]: newValue || e.target.value,
          [fieldNameId]: Utils.GetIdOrNull(newValue)
        })
      : 
        this.props.setMavoVehicleObject({ 
          ...this.props.mavoVehicleState.mavoVehicle, 
          [fieldName]: newValue || e.target.value
        })
  };

  rendeVehicleOptionForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          // this.submitCaseVehicleDetailsData(e);
        }}
      >
        <div className="m-3 text-center">
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Autocomplete
                disabled={this.state.isDisabled}
                id="vehicleType"
                options={this.state.vehicleTypes}
                value={this.props.mavoVehicleState.mavoVehicle.vehicleType}
                onChange={this.onVehicleChange('vehicleType', 'vehicleTypeId')}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="vehicleType"
                    value={this.props.mavoVehicleState.mavoVehicle.vehicleType}
                    label={this.translatorService.Tranlate(
                      'VEHICLE_OPTIONS_VECHICLE_TYPE',
                      'Tip vehicul'
                    )}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('alarm')(!this.props.vehicleOptionInfo.alarm)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_ALARM', 'Alarma')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.alarm}
                onChange={(e) => this.onCheckBoxChange('alarm')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('towHook')(!this.props.vehicleOptionInfo.towHook)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_TOW_HOOK', 'Carlig de remorcare')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.towHook}
                onChange={(e) => this.onCheckBoxChange('towHook')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('heatedSeats')(!this.props.vehicleOptionInfo.heatedSeats)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_HEATED_SEATS', 'Scaune incalzite')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.heatedSeats}
                onChange={(e) => this.onCheckBoxChange('heatedSeats')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="cylinderCapacity"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_ENGINE_CAPACITY',
                  'Cilindree'
                )}
                value={this.props.mavoVehicleState.mavoVehicle.cylinderCapacity}
                onChange={(e: any) => {
                  this.onVehicleChange('cylinderCapacity')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_ENGINE_CAPACITY', 'Cilindree')}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('antiTheft')(!this.props.vehicleOptionInfo.antiTheft)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_ANTI_THEFT', 'Antifurt')}              
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.antiTheft}
                onChange={(e) => this.onCheckBoxChange('antiTheft')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('protectionFrame')(!this.props.vehicleOptionInfo.protectionFrame)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_PROTECTION_FRAME', 'Cablu de protectie')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.protectionFrame}
                onChange={(e) => this.onCheckBoxChange('protectionFrame')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('rainSensors')(!this.props.vehicleOptionInfo.rainSensors)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_RAIN_SENSORS', 'Senzori de ploaie')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.rainSensors}
                onChange={(e) => this.onCheckBoxChange('rainSensors')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="emissionsClass"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_EMISSIONS_CLASS',
                  'Clasa de poluare'
                )}
                value={this.props.vehicleOptionInfo.emissionsClass}
                onChange={(e: any) => {
                  this.onChange('emissionsClass')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_EMISSIONS_CLASS', 'Clasa de poluare')}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('abs')(!this.props.vehicleOptionInfo.abs)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_ABS', 'ABS')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.abs}
                onChange={(e) => this.onCheckBoxChange('abs')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('steeringWheelControls')(!this.props.vehicleOptionInfo.steeringWheelControls)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_STEERING_WHEEL_CONTROLS', 'Comenzi pe volan')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.steeringWheelControls}
                onChange={(e) => this.onCheckBoxChange('steeringWheelControls')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('parkingSensors')(!this.props.vehicleOptionInfo.parkingSensors)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_PARKING_SENSORS', 'Senzori de parcare')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.parkingSensors}
                onChange={(e) => this.onCheckBoxChange('parkingSensors')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="doorCount"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_DOOR_COUNT',
                  'Numar portiere'
                )}
                value={this.props.vehicleOptionInfo.doorCount}
                onChange={(e: any) => {
                  this.onChange('doorCount')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_DOOR_COUNT', 'Numar portiere')}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('esp')(!this.props.vehicleOptionInfo.esp)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_ESP', 'ESP')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.esp}
                onChange={(e) => this.onCheckBoxChange('esp')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('onBoardComputer')(!this.props.vehicleOptionInfo.onBoardComputer)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_ON_BOARD_COMPUTER', 'Computer pe bord')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.onBoardComputer}
                onChange={(e) => this.onCheckBoxChange('onBoardComputer')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('powerSteering')(!this.props.vehicleOptionInfo.powerSteering)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_POWER_STEERING', 'Servodirectie')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.powerSteering}
                onChange={(e) => this.onCheckBoxChange('powerSteering')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="seatCount"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_SEAT_COUNT',
                  'Numar locuri'
                )}
                value={this.props.vehicleOptionInfo.seatCount}
                onChange={(e: any) => {
                  this.onChange('seatCount')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_SEAT_COUNT', 'Numar locuri')}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('driverFrontAirbag')(!this.props.vehicleOptionInfo.driverFrontAirbag)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_DRIVER_FRONT_AIRBAG', 'Airbag frontal sofer')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.driverFrontAirbag}
                onChange={(e) => this.onCheckBoxChange('driverFrontAirbag')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('cruiseControl')(!this.props.vehicleOptionInfo.cruiseControl)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_CRUISE_CONTROL', 'Cruise control')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.cruiseControl}
                onChange={(e) => this.onCheckBoxChange('cruiseControl')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('sunroof')(!this.props.vehicleOptionInfo.sunroof)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_SUN_ROOF', 'Trapa')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.sunroof}
                onChange={(e) => this.onCheckBoxChange('sunroof')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="driveType"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_DRIVE_TYPE',
                  'Tractiune'
                )}
                value={this.props.vehicleOptionInfo.driveType}
                onChange={(e: any) => {
                  this.onChange('driveType')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_DRIVE_TYPE', 'Tractiune')}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('passengerFrontAirbag')(!this.props.vehicleOptionInfo.passengerFrontAirbag)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_PASSENGER_FRONT_AIRBAG', 'Airbag frontal pasager')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.passengerFrontAirbag}
                onChange={(e) => this.onCheckBoxChange('passengerFrontAirbag')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('lockableDifferential')(!this.props.vehicleOptionInfo.lockableDifferential)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_LOCKABLE_DIFFERENTIAL', 'Diferential blocabil')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.lockableDifferential}
                onChange={(e) => this.onCheckBoxChange('lockableDifferential')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('rightHandDrive')(!this.props.vehicleOptionInfo.rightHandDrive)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_RIGHT_HAND_DRIVE', 'Volan dreapta')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.rightHandDrive}
                onChange={(e) => this.onCheckBoxChange('rightHandDrive')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="registration"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_REGISTRATION',
                  'Inmatriculare'
                )}
                value={this.props.vehicleOptionInfo.registration}
                onChange={(e: any) => {
                  this.onChange('registration')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_REGISTRATION', 'Inmatriculare')}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('frontSideAirbags')(!this.props.vehicleOptionInfo.frontSideAirbags)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_FRONT_SIDE_AIRBAGS', 'Airbag-uri lateral fata')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.frontSideAirbags}
                onChange={(e) => this.onCheckBoxChange('frontSideAirbags')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('particleFilter')(!this.props.vehicleOptionInfo.particleFilter)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_PARTICLE_FILTER', 'Filtru particule')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.particleFilter}
                onChange={(e) => this.onCheckBoxChange('particleFilter')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('fogLights')(!this.props.vehicleOptionInfo.fogLights)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_FOG_LIGHTS', 'Proiectoare de ceata')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.fogLights}
                onChange={(e) => this.onCheckBoxChange('fogLights')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="vehicleCondition"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_VECHICLE_CONDITION',
                  'Stare vehicul'
                )}
                value={this.props.vehicleOptionInfo.vehicleCondition}
                onChange={(e: any) => {
                  this.onChange('vehicleCondition')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_VECHICLE_CONDITION', 'Stare vehicul')}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('frontCurtainAirbag')(!this.props.vehicleOptionInfo.frontCurtainAirbag)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_FRONT_CURTAIN_AIRBAG', 'Airbag cortina fata')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.frontCurtainAirbag}
                onChange={(e) => this.onCheckBoxChange('frontCurtainAirbag')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('electricMirrors')(!this.props.vehicleOptionInfo.electricMirrors)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_ELECTRIC_MIRRORS', 'Oglinzi electrice')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.electricMirrors}
                onChange={(e) => this.onCheckBoxChange('electricMirrors')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('airConditioning')(!this.props.vehicleOptionInfo.airConditioning)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_AIR_CONFITIONING', 'Aer conditionat')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.airConditioning}
                onChange={(e) => this.onCheckBoxChange('airConditioning')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="metallicColor"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_METALLIC_COLOR',
                  'Culoare metalizata'
                )}
                value={this.props.vehicleOptionInfo.metallicColor}
                onChange={(e: any) => {
                  this.onChange('metallicColor')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_METALLIC_COLOR', 'Culoare metalizata')}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('xenonHeadlights')(!this.props.vehicleOptionInfo.xenonHeadlights)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_XENON_HEADLIGHTS', 'Faruri xenon')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.xenonHeadlights}
                onChange={(e) => this.onCheckBoxChange('xenonHeadlights')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('frontElectricWindows')(!this.props.vehicleOptionInfo.frontElectricWindows)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_FRONT_ELECTRIC_WINDOWS', 'Geamuri electrice fata')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.frontElectricWindows}
                onChange={(e) => this.onCheckBoxChange('frontElectricWindows')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('radio')(!this.props.vehicleOptionInfo.radio)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_RADIO', 'Radio')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.radio}
                onChange={(e) => this.onCheckBoxChange('radio')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="upholstery"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_UPHOLSTERY',
                  'Tapiterie'
                )}
                value={this.props.vehicleOptionInfo.upholstery}
                onChange={(e: any) => {
                  this.onChange('upholstery')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_UPHOLSTERY', 'Tapiterie')}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('alloyWheels')(!this.props.vehicleOptionInfo.alloyWheels)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_ALLOY_WHEELS', 'Jante aliaj')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.alloyWheels}
                onChange={(e) => this.onCheckBoxChange('alloyWheels')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('rearElectricWindows')(!this.props.vehicleOptionInfo.rearElectricWindows)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_REAR_ELECTRIC_WINDOWS', 'Geamuri electrice spate')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.rearElectricWindows}
                onChange={(e) => this.onCheckBoxChange('rearElectricWindows')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('cdPlayer')(!this.props.vehicleOptionInfo.cdPlayer)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_CD_PLAYER', 'CD')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.cdPlayer}
                onChange={(e) => this.onCheckBoxChange('cdPlayer')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="upholsteryColor"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_UPHOLSTERY_COLOR',
                  'Culoare tapiterie'
                )}
                value={this.props.vehicleOptionInfo.upholsteryColor}
                onChange={(e: any) => {
                  this.onChange('upholsteryColor')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_UPHOLSTERY_COLOR', 'Culoare tapiterie')}
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('glassRoof')(!this.props.vehicleOptionInfo.glassRoof)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_GLASS_ROOF', 'Acoperis sticla')}             
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.glassRoof}
                onChange={(e) => this.onCheckBoxChange('glassRoof')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('tintedWindows')(!this.props.vehicleOptionInfo.tintedWindows)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_TINTED_WINDOWS', 'Geamuri cu tente/colorate')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.tintedWindows}
                onChange={(e) => this.onCheckBoxChange('tintedWindows')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('videoScreen')(!this.props.vehicleOptionInfo.videoScreen)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_VIDEO_SCREEN', 'Ecran video tetiere')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.videoScreen}
                onChange={(e) => this.onCheckBoxChange('videoScreen')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="previousOwnersCount"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_PREVIOUS_OWNERS_COUNT',
                  'Nr proprietari anteriori'
                )}
                value={this.props.vehicleOptionInfo.previousOwnersCount}
                onChange={(e: any) => {
                  this.onChange('previousOwnersCount')(e);
                }}
                label={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_PREVIOUS_OWNERS_COUNT',
                  'Nr proprietari anteriori'
                )}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
            </Grid>

            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', paddingLeft: '5%'}}>
              <div 
                onClick={() => !this.state.isDisabled && this.onCheckBoxChange('gps')(!this.props.vehicleOptionInfo.gps)} 
                style={{
                  cursor: this.state.isDisabled ? 'not-allowed' : 'pointer',
                  userSelect: 'none'
                }}
              >
                {this.translatorService.Tranlate('VEHICLE_OPTION_GPS', 'GPS')}
              </div>
              <Checkbox
                checked={this.props.vehicleOptionInfo.gps}
                onChange={(e) => this.onCheckBoxChange('gps')(e.target.checked)}
                disabled={this.state.isDisabled}
                edge="end"
                className="checkbox-no-padding"
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <TextValidator
                fullWidth
                className='mt-1'
                disabled={this.state.isDisabled}
                name="countryOfOrigin"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_OPTIONS_COUNTRY_OF_ORIGIN',
                  'Tara de origine'
                )}
                value={this.props.vehicleOptionInfo.countryOfOrigin}
                onChange={(e: any) => {
                  this.onChange('countryOfOrigin')(e);
                }}
                label={this.translatorService.Tranlate('VEHICLE_OPTIONS_COUNTRY_OF_ORIGIN', 'Tara de origine')}
              />
            </Grid>
          </Grid>
        </div>
      </ValidatorForm>
    );
  }


  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        <div className="standard-car-options">
          {!this.state.isLoading ? this.rendeVehicleOptionForm() : null}
          </div>
      </Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: MavoVehicleOptionDetailsProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicle: state.mavoVehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators }
)(withSnackbar(MavoVehicleOptionsContainer as any));
