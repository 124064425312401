import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Box, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Label } from 'reactstrap';
import { ApplicationContext } from '../../context/Contexts';
import { ValuationHistory } from '../../interfaces/Case';
import { ScaleLoader } from 'react-spinners';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useAppSelector } from '../../store/configureStore';

const CaseValuationHistory = ({ isOpen, close, id }: { isOpen: boolean, close:()=>void, id:string}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const context = useContext(ApplicationContext);
  const [historyList, setHistoryList] = useState<ValuationHistory[]>([]);
  const caseService = context.caseService;
  const translatorService = context.translatorService;
  const appUserService = context.appUserService;
  const language = useAppSelector((state) => state.app?.language);
  const longDateFormat = useAppSelector((state) => state.app?.longDateFormat);

  const fetchHistory = async () => {
    setIsLoading(true);
    const response = await caseService.GetValuationHistory(Number.parseInt(id))

    if (!response?.length) {
      setIsLoading(false)
      return
    }
    const userIds = response.map((item) => item.modifiedBy);
    const users = await appUserService.GetUsersInfo(Array.from(new Set(userIds)));
    const futureHistoryList = [...response]
    
    for (let i=0; i< futureHistoryList?.length; i++) {
      futureHistoryList[i].updatedByUserFullName = users[i]?.updatedByUserFullName;
      futureHistoryList[i].email = users[i]?.email;
    }
    setHistoryList(futureHistoryList);
    setIsLoading(false);
  };

  useEffect(()=>{
    fetchHistory();
  },[])

  const showHistory = () => {
    if (historyList.length === 0) {
      return (
        <b>
          {translatorService.Tranlate('TARIFF_EDIT_LABOR_TAB_NO_DATA', 'Nu exista date de afisat!')}
        </b>
      );
    }

    const labelStyle = {
      fontSize: '0.8rem !important',
      fontWeight: '500',
      padding: '0px',
      margin: '0px 6px 0px 0px'
    };
    const priceStyle = {
      fontSize: '0.8rem',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center'
    };
    const boxStyle = {
      fontSize: '0.8rem',
      display: 'flex',
      alignItems: 'center'
    };

    return historyList.map((item, index) => (
      <div key={item.date.toString()} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon " />
          <Box>
            <Typography className="timeline-item--label mb-2 font-weight-bold">
              {item.updatedByUserFullName + ' (' + item.email + ')'}
            </Typography>
            <Typography className="timeline-item--content font-weight-bold">
              {moment
                .utc(item.date)
                .local()
                .format(longDateFormat || 'DD/MM/YYYY HH:mm')}
            </Typography>
          </Box>

          <Box style={{ marginTop: '8px' }}>
            {index === 0 ? (
              <Box style={boxStyle}>
                <Label style={labelStyle}>
                  {`${translatorService.Tranlate(
                    'VALUATION_BASE_PRICE_LABEL',
                    'Pret de Baza (incl. TVA)'
                  )}:`}
                </Label>
                <Typography style={priceStyle}>
                  {item.priceAsNew ? item.priceAsNew.toFixed(2) : 0} €
                </Typography>
              </Box>
            ) : null}

            <Box style={boxStyle}>
              <Label style={labelStyle}>
                {`${translatorService.Tranlate(
                  'VALUATION_TRADE_IN_PRICE_LABEL',
                  'Trade-in price (incl. TVA)'
                )}:`}
              </Label>
              <Typography style={priceStyle}>
                {item.tradeInPrice ? item.tradeInPrice.toFixed(2) : 0} €
              </Typography>
            </Box>

            <Box style={boxStyle} className="text-success">
              <Label style={labelStyle}>
                {`${translatorService.Tranlate(
                  'VALUATION_OFFER_PRICE_LABEL',
                  'Offer price (incl. TVA)'
                )}:`}
              </Label>
              <Typography style={priceStyle} className="text-success">
                {item.offerPrice ? item.offerPrice.toFixed(2) : 0} €
              </Typography>
            </Box>

            <Box style={boxStyle} className="text-success">
              <Label style={labelStyle}>
                {`${translatorService.Tranlate(
                  'VALUATION_RETAIL_PRICE_LABEL',
                  'Retail price (incl. TVA)'
                )}:`}
              </Label>
              <Typography style={priceStyle} className="text-success">
                {item.retailPrice ? item.retailPrice.toFixed(2) : 0} €
              </Typography>
            </Box>
          </Box>
          <div></div>
        </div>
      </div>
    ));
  };
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle
        id="customized-dialog-title"
        style={{
          color: '#ffffff',
          backgroundColor: '#1F2857'
        }}
      >
        <Typography component="div" variant="h4" align="center">
          {translatorService.Tranlate('CASE_CALCULATION_EVALUATION_HISTORY', 'Istoric Evaluari')}
        </Typography>
        <IconButton
          aria-label="close"
          className="position-absolute"
          style={{ right: 12, top: 12, color: '#ffffff' }}
          onClick={close}
          size={'small'}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ backgroundColor: '#fafafa', minHeight:500 }}>
          <Fragment>
            <div className="d-flex flex-row text-center flex-wrap justify-content-center">
              <ScaleLoader color={'var(--primary)'} loading={isLoading} />
            </div>
            {!isLoading ? (
              <Box className="pt-3">
                <Card className="card-box  overflow-visible">
                  <CardContent className="p-3">
                    <div className="m-4 p-4">
                      <div className="timeline-list  timeline-list--primary">
                        {' '}
                        {showHistory()}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Box>
            ) : null}
          </Fragment>
        </DialogContent>
    </Dialog>
  );
};

export default CaseValuationHistory;
