export const mailPlaceHolders = [
  'CaseId',
  'CaseLink',
  'CaseStatus',
  'CurrentAssignee',
  'CaseCreatedBy',
  'AssignedBy',
  'CaseType',
  'CaseCreationDate',
  'ClaimNumber',
  'CarRegistrationNumber',
  'Vin',
  'CalculationDate',
  'CalculationBy',
  'CalculationAmount',
  'ServiceInDate',
  'ServiceOutDate',
  'ServiceContractNumber',
  'CaseRequestText',
  'VehicleBrand',
  'VehicleModel',
  'VehicleFabricationDate',
  'VehiclePowerKw',
  'VehicleEngineSizeCc',
  'VehicleEngineCode',
  'OrganizationAddress',
  'UserFirstName',
  'UserLastName',
  'UserPhoneNumber',
  'UserName',
  'ClientFirstName',
  'ClientLastName',
  'ClientCompanyName',
  'ClientContactPerson',
  'OrganizationName',
  'OrganizationCity',
  'OrganizationPhone',
  'OrganizationLogo',
  'VehicleVersion',
  'VehicleEquipmentVersion',
  'CaseClient',
  'NumberOfKilometers',
  'CostCenter',
  'DeliveryAdress',
  'AppointmentId',
  'AppointmentLink',
  'RcrEmployeeEmail',
  'RcrEmployeePhone',
  'RcrEmployeeFirstName',
  'RcrEmployeeLastName',
  'AlertTypeName',
  'AlertExpirationDate'
];
