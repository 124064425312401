import { Moment } from 'moment';
import { VehicleInventoryAgeFilterEnum } from '../../enums/VehicleInventoryAgeFilterEnum';
import { FiltersSelectItem } from './FiltersSelectItem';

export interface DashboardFiltersState {
  startDate: Moment;
  endDate: Moment;
  selectedOrganisations: FiltersSelectItem[];
  makeId?: number;
  modelId?: number;
  fuelId?: number;
  locationId?: number;
  transmissionTypeId?: number;
  tractionTypeId?: number;
  inventoryAgeFilter?: VehicleInventoryAgeFilterEnum;
  statusId?: number;
  technicalConditionId?: number;
  includeVat?: boolean;
  offerTypeId?: number;
  clientTypeId?: number;
}

export enum DashboardMainFilterStateKeys {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  SELECTED_ORGANIZATIONS = 'selectedOrganisations',
  INCLUDE_VAT = 'includeVat'
}

export enum DashboardFilterStateKeys {
  MAKE_ID = 'makeId',
  LOCATION_ID = 'locationId',
  MODEL_ID = 'modelId',
  TECHNICAL_CONDITION_ID = 'technicalConditionId',
  STATUS_ID = 'statusId',
  INVENTORY_AGE_FILTER = 'inventoryAgeFilter',
  FUEL_ID = 'fuelId',
  TRANSMISSION_TYPE_ID = 'transmissionTypeId',
  TRACTION_TYPE_ID = 'tractionTypeId',
  CLIENT_TYPE_ID = 'clientTypeId',
  OFFER_TYPE_ID = 'offerTypeId'
}
