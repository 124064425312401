import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/Folder';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DescriptionIcon from '@material-ui/icons/Description';

import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { RoleClaimsEnum } from './../../helpers/Constants';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import BuildIcon from '@material-ui/icons/Build';
import CommuteOutlinedIcon from '@material-ui/icons/CommuteOutlined';

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  HomeIcon: HomeIcon,
  LocalShippingIcon: LocalShippingIcon,
  DescriptionIcon: DescriptionIcon,
  DirectionsCarIcon: DirectionsCarIcon,
  AirportShuttleIcon: AirportShuttleIcon,
  BuildIcon: BuildIcon,
  CommuteOutlinedIcon: CommuteOutlinedIcon
};

export default [
  {
    label: '   ',
    content: JSON.parse(
      `[ 
    {
      "label": "Acasa",
      "tag": "MENU_HOME",
      "icon": "HomeIcon",
      "to": "/",
      "right": "${RoleClaimsEnum.Home}"
    },
    {
      "label": "Sample Page V3",
      "tag": "MENU_SAMPLEPAGEV3",
      "icon": "CommuteOutlinedIcon",
      "needToken":"true",
      "redirectTo":"${window.location.origin}/v3/sample-page?",
      "right": "${RoleClaimsEnum.SAMPLEPAGEV3}"
    }, 
    {
      "label": "Dosar nou",
      "tag": "MENU_NEW_CASE",
      "icon": "DescriptionIcon",
      "to": "/newCase",
      "right": "${RoleClaimsEnum.NewCase}"
    },
      {
      "label": "Dashboards",
      "tag": "MENU_DASHBOARDS",
      "icon": "DashboardTwoToneIcon",
      "to": "/dashboards",
      "right": "${RoleClaimsEnum.Dashboard}"
      },
      {
      "label": "Dosare",
      "tag": "MENU_FILES",
      "icon": "FolderIcon",
      "to": "/cases",
      "right": "${RoleClaimsEnum.Case}"
    },
    {
      "label": "Evaluare",
      "tag": "EVALUATION",
      "icon": "FolderIcon",
      "to": "/valuation",
      "right": "${RoleClaimsEnum.Evaluation}"
    },
      {
      "label": "Agenda",
      "tag": "AGENDA",
      "icon": "CalendarTodayIcon",
      "to": "/agenda",
      "right": "${RoleClaimsEnum.Agenda}"
    },
    {
      "label": "Vehicles History",
      "tag": "MENU_HISTORY_VEHICLES",
      "icon": "FolderIcon",
      "to": "/vehicles-history",
      "right": "${RoleClaimsEnum.Vehicle}"
    },
    {
      "label": "Vehicles",
      "tag": "MENU_VEHICLES",
      "icon": "DirectionsCarIcon",
      "to": "/vehicles",
      "right": "${RoleClaimsEnum.Vehicle}"
    },
    {
      "label": "Comenzi",
      "tag": "MENU_ORDERS",
      "icon": "LocalShippingIcon",
      "to": "/orders",
      "right": "${RoleClaimsEnum.Order}"
    },
    {
      "label": "Istoric Comanda Auto",
      "tag": "MENU_ORDER_PARTS_HISTORY",
      "icon": "BuildIcon",
      "to": "/order-parts-history",
      "right": "${RoleClaimsEnum.Order}"
    },
    {
      "label": "Raportare",
      "tag": "MENU_BILLING",
      "icon": "FolderIcon",
      "to": "/billing",
      "right": "${RoleClaimsEnum.ManagerExport}"
    },
    {
      "label": "Raportare DGPCI",
      "tag": "MENU_BILLING_DGPCI",
      "icon": "FolderIcon",
      "to": "/billing-dgpci",
      "right": "${RoleClaimsEnum.ManagerExport}"
    },
    {
      "label": "Raportare WS",
      "tag": "MENU_BILLING_GT_WS",
      "icon": "FolderIcon",
      "to": "/billing-ws",
      "right": "${RoleClaimsEnum.ManagerExport}"
    },
    {
      "label": "Raportare Piese Non Stoc",
      "tag": "MENU_BILLING_NON_STOCK_PART",
      "icon": "FolderIcon",
      "to": "/billing-non-stock",
      "right": "${RoleClaimsEnum.ManagerExport}"
    },
    {
      "label": "Mavo Vehicles",
      "tag": "MENU_MAVO_VEHICLES_RECORD",
      "icon": "DirectionsCarIcon",
      "to": "/mavo-vehicles",
      "right": "${RoleClaimsEnum.MavoAdministrator}"
    },
    {
      "label": "Licitatii",
      "tag": "MENU_VO_AVAILABLE_AUCTIONS",
      "icon": "FolderIcon",
      "right": "${RoleClaimsEnum.MavoVehicle}",
      "rights" : ["${RoleClaimsEnum.MavoAgent}", "${RoleClaimsEnum.MavoAdministrator}"],
      "content": [
        {
          "label": "Licitatii active",
          "tag": "MENU_ACTIVE_AUCTIONS",
          "description": "Licitatii active",
          "to": "/auctions",
          "right": "${RoleClaimsEnum.MavoVehicle}",
          "rights" : ["${RoleClaimsEnum.MavoAgent}", "${RoleClaimsEnum.MavoAdministrator}"]
        },
        {
          "label": "Licitatiile mele",
          "tag": "MENU_USER_AUCTIONS",
          "description": "Licitatiile mele",
          "to": "/user/auctions",
          "right": "${RoleClaimsEnum.MavoVehicle}",
          "rights" : ["${RoleClaimsEnum.MavoAgent}"]
        },
        {
          "label": "Administrare licitatii",
          "tag": "MENU_ADMINISTRATION_AUCTIONS",
          "description": "Administrare licitatii",
          "to": "/admin/auctions",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        }
      ]
    },
    {
      "label": "Comenzi",
      "tag": "MENU_MAVO_ORDERS",
      "icon": "LocalShippingIcon",
      "to": "/mavo/orders",
      "right": "${RoleClaimsEnum.MavoVehicle}",
      "rights" : ["${RoleClaimsEnum.MavoAdministrator}"]

    },
    {
      "label": "Comenzile mele",
      "tag": "MENU_MAVO_USER_ORDERS",
      "icon": "LocalShippingIcon",
      "to": "/mavo/user/orders",
      "right": "${RoleClaimsEnum.MavoVehicle}",
      "rights" : ["${RoleClaimsEnum.MavoAgent}"]

    },
    {
      "label": "Istoric VO facturat",
      "tag": "MENU_VO_INVOICE_HISTORY",
      "icon": "FolderIcon",
      "to": "/invoice-history",
      "right": "${RoleClaimsEnum.MavoVehicle}",
      "rights" : ["${RoleClaimsEnum.MavoAgent}", "${RoleClaimsEnum.MavoAdministrator}", "${RoleClaimsEnum.MavoContab}"]
    },
    {
      "label": "Nomeclatoare",
      "tag": "MENU_NOMENCLATURES",
      "icon": "SettingsIcon",
      "right": "${RoleClaimsEnum.MavoAdministrator}",
      "rights" : ["${RoleClaimsEnum.MavoAdministrator}", "${RoleClaimsEnum.MavoContab}"],
      "content": [
        {
          "label": "Agent",
          "tag": "MENU_NOMENCLATURES_TAB_AGENT",
          "description": "Configurarea agentilor, a rolurilor si a permisiunilor.",
          "to": "/admin/mavo/users",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Organizatii",
          "tag": "MENU_ADMIN_TAB_ORGANIZATIONS",
          "description": "Adaugarea, stergerea, editarea si configurarea unei organizatii.",
          "to": "/admin/mavo/hoorganizations",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Versiune",
          "tag": "MENU_NOMENCLATURES_TAB_VERSION",
          "description": "Configurarea versiunilor",
          "to": "/nomenclature/version",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Culori",
          "tag": "MENU_NOMENCLATURES_TAB_COLOR",
          "description": "Configurarea culorilor",
          "to": "/nomenclature/color",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Caroserie",
          "tag": "MENU_NOMENCLATURES_TAB_BODY_TYPE",
          "description": "Configurarea caroseriei",
          "to": "/nomenclature/bodyType",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Stare componente",
          "tag": "MENU_NOMENCLATURES_TAB_BODY_CONDITION_TYPE",
          "description": "Configurare tipuri de stari caroserie",
          "to": "/nomenclature/bodyConditionType",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Stare roata",
          "tag": "MENU_NOMENCLATURES_TAB_WHEEL_CONDITION_TYPE",
          "description": "Configurare tipuri de stari roata",
          "to": "/nomenclature/wheelConditionType",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Parametri financiari",
          "tag": "MENU_NOMENCLATURES_TAB_FINANCIAL_PARAMETERS",
          "description": "Configurare Parametrii financiari",
          "to": "/nomenclature/financial-parameter",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Plaje avize/facturi",
          "tag": "MENU_NOMENCLATURES_TAB_INVOICE_RANGE",
          "description": "Configurare Plaje avize/facturi",
          "to": "/nomenclature/invoice-range",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Provenienta",
          "tag": "MENU_NOMENCLATURES_TAB_PROVENANCE",
          "description": "Configurare Provenienta",
          "to": "/nomenclature/provenance",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Curs valutar",
          "tag": "MENU_NOMENCLATURES_TAB_CURRENCY",
          "description": "Configurare Curs valutar",
          "to": "/nomenclature/currency",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        },
        {
          "label": "Erori",
          "tag": "MENU_NOMENCLATURES_TAB_ALCOR_ERROR",
          "description": "Alcor Error",
          "to": "/nomenclature/alcor-error-log",
          "right": "${RoleClaimsEnum.MavoVehicle}",
          "rights" : ["${RoleClaimsEnum.MavoAdministrator}", "${RoleClaimsEnum.MavoContab}"]
        },
        {
          "label": "Parametrare Alcor",
          "tag": "MENU_NOMENCLATURES_TAB_ALCOR_PARAMETERS",
          "description": "Parametrare Alcor",
          "to": "/nomenclature/alcor-parameters",
          "right": "${RoleClaimsEnum.MavoVehicle}",
          "rights" : ["${RoleClaimsEnum.MavoAdministrator}", "${RoleClaimsEnum.MavoContab}"]
        },
        {
          "label": "Date vanzator",
          "tag": "MENU_NOMENCLATURES_TAB_SELLER_DATA",
          "description": "Configurare date vanzator",
          "to": "/nomenclature/seller-data",
          "right": "${RoleClaimsEnum.MavoAdministrator}"
        }
      ]
    },
    {
      "label": "Admin",
      "tag": "MENU_ADMIN",
      "icon": "SettingsIcon",
      "right": "${RoleClaimsEnum.Admin}",
      "rights" : ["${RoleClaimsEnum.Admin}", "${RoleClaimsEnum.AdminCountryOrganization}", "${RoleClaimsEnum.AdminService}"],
      "content": [
        {
          "label": "Utilizatori",
          "tag": "MENU_ADMIN_TAB_USERS",
          "description": "Configurarea utilizatorilor, a rolurilor si a permisiunilor.",
          "to": "/admin/users",
          "right": "${RoleClaimsEnum.AdminUsers}"
        },
        {
          "label": "Liste de valori",
          "tag": "MENU_ADMIN_TAB_LIST_OF_VALUES",
          "description": "Adaugarea, stergerea si modificarea listelor de valori.",
          "to": "/admin/listOfValues",
          "right": "${RoleClaimsEnum.AdminValueList}"
        },
        {
          "label": "Alte liste de valori",
          "tag": "MENU_ADMIN_TAB_OTHER_LIST_OF_VALUES",
          "description": "Adaugarea, stergerea si modificarea altor listelor de valori.",
          "to": "/admin/otherListOfValues",
          "right": "${RoleClaimsEnum.AdminValueList}"
        },
        {
          "label": "Modele",
          "tag": "NOMENCLATURE_MODEL_LIST",
          "description": "Modele",
          "to": "/admin/model-car-class",
          "right": "${RoleClaimsEnum.AdminValueList}"
        },
        {
          "label": "Preturi inchiriere/zi",
          "tag": "CAR_CLASS_RENTAL_PRICE",
          "description": "Preturi inchiriere/zi",
          "to": "/admin/car-class-rent-price",
          "right": "${RoleClaimsEnum.Admin}"
        },
        {
          "label": "Cupi Piese Optionale Revizie",
          "tag": "MAINTENANCE_CUPI_PART_OPTIONAL",
          "description": "Cupi Piese Optionale Revizie",
          "to": "/admin/maintenance-cupi-part",
          "right": "${RoleClaimsEnum.AdminValueList}"
        },
        {
          "label": "Producatori Mentenanta",
          "tag": "MAINTENANCE_PRODUCER",
          "description": "Producatori Mentenanta",
          "to": "/admin/maintenance-producer",
          "right": "${RoleClaimsEnum.AdminValueList}"
        },
        {
          "label": "Tampon tari",
          "tag": "COUNTRY_BUFFER",
          "description": "Tampon tari",
          "to": "/admin/country-buffer",
          "right": "${RoleClaimsEnum.AdminValueList}"
        },
        {
          "label": "XML",
          "tag": "MENU_ADMIN_TAB_XML",
          "description": "Download XML.",
          "to": "/admin/xml",
          "right": "${RoleClaimsEnum.Admin}"
        },
        {
          "label": "Organizatii",
          "tag": "MENU_ADMIN_TAB_ORGANIZATIONS",
          "description": "Adaugarea, stergerea, editarea si configurarea unei organizatii.",
          "to": "/admin/hoorganizations",
          "right": "${RoleClaimsEnum.AdminOrganization}",
          "rights" : ["${RoleClaimsEnum.AdminOrganization}", "${RoleClaimsEnum.AdminCountryOrganization}"]
        },        
        {
          "label": "Organizatia mea",
          "tag": "MENU_ADMIN_SERVICE_TAB_ORGANIZATIONS",
          "description": "Configurarea unei organizatii.",
          "to": "/admin/hoorganizations/",
          "right": "${RoleClaimsEnum.AdminService}"
        },
        {
          "label": "Poeditor",
          "tag": "MENU_ADMIN_TAB_POEDITOR",
          "description": "Poeditor",
          "to": "/admin/poeditor",
          "right": "${RoleClaimsEnum.Admin}",
          "rights" : ["${RoleClaimsEnum.Admin}", "${RoleClaimsEnum.AdminCountryOrganization}"]
        }
      ]
      },
      {
        "label": "Setari",
        "tag": "MENU_SETTINGS",
        "icon": "SettingsIcon",
        "right": "${RoleClaimsEnum.Settings}",
        "content": [
            {
              "label": "Interfete",
              "tag": "MENU_SETTINGS_TAB_INTERFACES",
              "description": "Configurarea utilizatorilor pentru interfetele organizatiei.",
              "to": "/settings/interfaces",
              "right": "${RoleClaimsEnum.Settings}"
            }
        ]
          }  
]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
