import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { isNullOrUndefined } from 'util';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { ReferentialCode } from '../../helpers/Constants';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Referentials from '../../helpers/Referentials.json';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { VehicleQualityControlCert, QCItem } from '../../interfaces/Vehicle';
interface ICertificateOfControlFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isUserHidden: boolean;
  qualityControlItems: VehicleQualityControlCert[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExternalCertificateOfControlFormProps {
  // workflowForm: WorkflowSectionStepForm
}

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

type ICertificateOfControlFormProps = ExternalCertificateOfControlFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
} & AppState &
  typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CertificateOfControlForm extends React.PureComponent<
  ICertificateOfControlFormProps,
  ICertificateOfControlFormState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    isLoading: true,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    qualityControlItems: [] as VehicleQualityControlCert[]
  } as ICertificateOfControlFormState;

  public async componentDidMount() {
    if (isNullOrUndefined(this.props.caseSettingsState.case!.vehicleId)) {
      return;
    }

    const refQcItem = Referentials.referential.find((item) => item.code === ReferentialCode.QCItem);
    const refQcItemCateg = Referentials.referential.find(
      (item) => item.code === ReferentialCode.QCItemCategory
    );
    const [vehicleQCList, qcItems, qcItemCateg] = await Promise.all([
      this.vehicleService.VehicleQualityControlCert.GetUser(
        this.props.caseSettingsState.case!.vehicleId
      ),
      this.appReferentialService.Get(refQcItem!.baseUrl),
      this.appReferentialService.Get(refQcItemCateg!.baseUrl)
    ]);

    if (vehicleQCList.length === 0) {
      qcItems
        .filter((item) => item.isActive)
        .forEach((qcItem) => {
          // const categ = qcItemCateg.find(item=> item.id === (qcItem as QCItem).qcItemCategoryId);

          const newItem = {
            id: 0,
            vehicleId: this.props.caseSettingsState.case!.vehicleId,
            isChecked: false,
            isReplaced: false,
            qcItemId: qcItem.id,
            qcItem: qcItem
          } as VehicleQualityControlCert;

          //newItem.qCItem!.qcItemCategory = categ!;

          vehicleQCList.push(newItem);
        });
    }

    vehicleQCList.forEach((itemQC) => {
      const categ = qcItemCateg.find(
        (item) => item.id === (itemQC.qcItem as QCItem).qcItemCategoryId
      );
      itemQC.qcItem!.qcItemCategory = categ!;
    });

    this.setState(
      {
        isLoading: true,
        qualityControlItems: vehicleQCList
      },
      async () => {
        this.setState({
          isLoading: false
        });
      }
    );
  }

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  submitData = async (e: any) => {
    try {
      this.setState({ executing: true });

      await this.vehicleService.VehicleQualityControlCert.SaveList(this.state.qualityControlItems);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({
        executing: false
      });
    }
  };

  rendeCertificateOfControlForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitData(e);
        }}
      >
        <div className="m-3 text-center"></div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('CASE_COMMENT_SEND', 'Trimite')}
        </Button>
      </ValidatorForm>
    );
  };

  renderVehicleInfo = () => {
    const vehicle = this.props.vehicleState.vehicle!;
    if (isNullOrUndefined(vehicle)) {
      return;
    }

    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <h5 className="bg-first text-left mt-2 p-1 mr-2" style={{ color: 'white' }}>
              {this.translatorService.Tranlate('CERTIFICATE_OF_CONTROL_TITLE', 'Detalii vehicul')}
            </h5>
            <div className="d-flex flex-wrap flex-row">
              <div className="text-left">
                <div>{this.translatorService.Tranlate('CERTIFICATE_OF_CONTROL_MAKE', 'Make')}</div>
                <div>
                  {this.translatorService.Tranlate('CERTIFICATE_OF_CONTROL_MODEL', 'Model')}
                </div>
                <div>{this.translatorService.Tranlate('CERTIFICATE_OF_CONTROL_VIN', 'VIN')}</div>
                <div>{this.translatorService.Tranlate('CERTIFICATE_OF_CONTROL_KM', 'Km')}</div>
                <div>
                  {this.translatorService.Tranlate(
                    'CERTIFICATE_OF_CONTROL_FIRST_REGISTRATION_DATE',
                    'Data primei inmatriculari'
                  )}
                </div>
                <div>
                  {this.translatorService.Tranlate(
                    'CERTIFICATE_OF_CONTROL_PLATE_NUMBER',
                    'Nr. inmatriculare'
                  )}
                </div>
                <div>
                  {this.translatorService.Tranlate(
                    'CERTIFICATE_OF_CONTROL_RADIO_CODE',
                    'Nr. cod radio'
                  )}
                </div>
              </div>
              <div className="ml-3 text-left">
                <div>{vehicle!.make!.displayName}</div>
                <div>{vehicle!.model!.displayName}</div>
                <div>{vehicle!.vin}</div>
                <div>{vehicle!.kilometersNr}</div>
                <div>
                  {moment
                    .utc(vehicle!.firstRegistrationDate)
                    .local()
                    .format(this.props.dateFormat)}
                </div>
                <div>{vehicle!.plateNumber}</div>
                <div>{vehicle!.radioCode}</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  handleToggleIsCheckedOption = (e: any, id: number, qcItemId: number) => {
    const indexValue = this.state.qualityControlItems.findIndex(
      (item) => item.id === id && item.qcItemId === qcItemId
    );
    const newQualityControlItems = [...this.state.qualityControlItems];

    newQualityControlItems[indexValue].isChecked = e.currentTarget.checked;
    newQualityControlItems[indexValue].isReplaced = !e.currentTarget.checked;

    this.setState({
      ...this.state,
      qualityControlItems: newQualityControlItems
    });
  };

  handleToggleIsReplacedOption = (e: any, id: number, qcItemId: number) => {
    const indexValue = this.state.qualityControlItems.findIndex(
      (item) => item.id === id && item.qcItemId === qcItemId
    );
    const newQualityControlItems = [...this.state.qualityControlItems];

    newQualityControlItems[indexValue].isReplaced = e.currentTarget.checked;
    newQualityControlItems[indexValue].isChecked = !e.currentTarget.checked;

    this.setState({
      ...this.state,
      qualityControlItems: newQualityControlItems
    });
  };

  renderCertificateOfControlList = () => {
    let valuesCategories = [];
    valuesCategories = this.state.qualityControlItems
      .map((val) => val.qcItem!.qcItemCategory!.displayName)
      .filter((v, i, a) => a.indexOf(v) === i);

    return (
      <div>
        <div>{this.renderVehicleInfo()}</div>
        <Grid container spacing={0}>
          {valuesCategories.map((val, index) => {
            return (
              <Grid item xs={6} key={index}>
                <h5 className="bg-first text-left mt-2 p-1 mr-2" style={{ color: 'white' }}>
                  {val}
                </h5>
                <div className="d-flex flex-wrap flex-row justify-content-end">
                  <div> {this.translatorService.Tranlate('CONTROLLED_LABEL', 'Controlat')}</div>
                  <div className="ml-2">{this.translatorService.Tranlate('REPLACED_LABEL', 'Inlocuit')}</div>
                </div>
                <List>
                  {this.state.qualityControlItems
                    .filter((item) => item.qcItem!.qcItemCategory!.displayName === val)
                    .map((val, index) => {
                      return (
                        <ListItem key={val.id}>
                          <ListItemText primary={val.qcItem!.displayName} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              className="ml-2"
                              name="isChecked"
                              edge="end"
                              onChange={(e: any) =>
                                this.handleToggleIsCheckedOption(e, val.id, val.qcItemId)
                              }
                              checked={val.isChecked as any}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                            <Checkbox
                              name="isReplaced"
                              edge="end"
                              onChange={(e: any) =>
                                this.handleToggleIsReplacedOption(e, val.id, val.qcItemId)
                              }
                              checked={val.isReplaced as any}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                </List>
              </Grid>
            );
          })}
        </Grid>
        <Button
          className="mt-3 float-right"
          variant="contained"
          color="primary"
          type="submit"
          onClick={this.submitData}
          disabled={false}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </div>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-4">{this.renderCertificateOfControlList()}</div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCertificateOfControlFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CertificateOfControlForm as any));
