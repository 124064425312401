import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { OrganizationType } from '../../../interfaces/Organization';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { Box, Button, IconButton, Card, CardContent, Tabs, Tab } from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import moment from 'moment';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import { RouteComponentProps } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import { WorkflowPartnerType } from '../../../interfaces/Workflow';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined } from 'util';

interface IWorkflowOrganizationTypesState {
  workflowOrganizationTypes: WorkflowPartnerType[];
  isLoading: boolean;
  organizationTypes: OrganizationType[];
  selectedOrganizationType: OrganizationType | null;
  workflowId: number;
}

type IWorkflowOrganizationTypesProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowOrganizationTypes extends React.PureComponent<
  IWorkflowOrganizationTypesProps,
  IWorkflowOrganizationTypesState
> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowOrganizationTypes: [],
    isLoading: false,
    organizationTypes: [],
    selectedOrganizationType: null,
    workflowId: 0
  } as IWorkflowOrganizationTypesState;

  public componentDidMount() {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadWorkflowOrganizationTypes();
      }
    );
  }

  loadWorkflowOrganizationTypes = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);

    if (Number.isNaN(hoId) || Number.isNaN(workflowId)) {
      return;
    }

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrganizationType
    );
    const [orgTypes, workflowOrgTypes] = await Promise.all([
      this.appReferentialService.Get(ref!.baseUrl),
      this.workflowService.GetWorkflowOrganizationTypes(workflowId)
    ]);

    workflowOrgTypes.forEach((item) => {
      const type = orgTypes.find((orgType) => orgType.id === item.organizationTypeId);
      item.organizationType = isNullOrUndefined(type) ? null : type;
    });

    this.setState({
      workflowOrganizationTypes: workflowOrgTypes,
      organizationTypes: orgTypes.filter(
        (item) => item.isActive === true
      ) as any as OrganizationType[],
      isLoading: false,
      workflowId: workflowId
    });
  };

  deleteWorkflowOrgType = async (event: any, tableMeta: MUIDataTableMeta) => {
    try {
      const id = tableMeta.rowData[0];

      await this.workflowService.RemoveWorkflowOrganizationType(id);

      this.setState(
        {
          isLoading: true
        },
        () => {
          this.loadWorkflowOrganizationTypes();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'organizationType',
        label: this.translatorService.Tranlate(
          'WORKFLOW_ORGANIZATION_TYPE_TAB_ORG_TYPE_HEADER',
          'Tip Organizatie'
        ),
        options: {
          sort: false,
          customBodyRender: (value: OrganizationType) => {
            return value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOW_ORGANIZATION_TYPE_TAB_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Date) =>
            moment.utc(value).local().toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: '',
        options: {
          setCellHeaderProps: () => ({ align: 'center' }),
          setCellProps: () => ({ align: 'center' }),
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <IconButton
                aria-label="delete"
                color="inherit"
                className="text-danger"
                size="small"
                onClick={(e) => this.deleteWorkflowOrgType(e, tableMeta)}
              >
                <DeleteOutlineTwoToneIcon />
              </IconButton>
            );
          }
        }
      }
    ];

    return columns;
  };

  addWorkflowOrgType = async () => {
    try {
      if (
        this.state.workflowOrganizationTypes.findIndex(
          (item) => item.organizationTypeId === this.state.selectedOrganizationType!.id
        ) !== -1
      ) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'ERROR_MSG_ORGANIZATION_TYPE_EXISTS',
            'Tipul de organizatie exista deja!'
          ),
          { variant: 'error' }
        );
        return;
      }

      await this.workflowService.AddWorkflowOrganizationType({
        id: 0,
        organizationTypeId: this.state.selectedOrganizationType!.id,
        workflowId: this.state.workflowId
      } as WorkflowPartnerType);

      this.setState(
        {
          isLoading: true,
          selectedOrganizationType: null
        },
        () => {
          this.loadWorkflowOrganizationTypes();
        }
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleOrganizationTypeChange = (newValue: OrganizationType | null) => {
    this.setState({ selectedOrganizationType: newValue });
  };

  renderAddForm() {
    return (
      <Box mt={1} pt={1}>
        <Card elevation={1}>
          <CardContent>
            <ValidatorForm onSubmit={this.addWorkflowOrgType}>
              <Autocomplete
                id="organizationType"
                className="m-2"
                options={this.state.organizationTypes}
                value={this.state.selectedOrganizationType}
                onChange={(e: any, newValue: OrganizationType | null) =>
                  this.handleOrganizationTypeChange(newValue)
                }
                getOptionLabel={(option: OrganizationType) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="organizationType"
                    value={this.state.selectedOrganizationType}
                    label={this.translatorService.Tranlate(
                      'WORKFLOW_ORGANIZATION_TYPE_TAB_ORG_TYPE_LABEL',
                      'Tip Organizatie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <div className="text-right">
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('ADMIN_EDIT_USER_ROLES_BTN_ADD', 'ADAUGA')}
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </Box>
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    const options = {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      elevation: 1
    };

    return (
      <Fragment>
        <Box>
          <Card>
            <CardContent>
              <Tabs
                value={0}
                indicatorColor="primary"
                textColor="secondary"
                variant="standard"
                color="primary"
                aria-label="disabled tabs example"
              >
                <Tab
                  className="text-capitalize"
                  label={this.translatorService.Tranlate(
                    'WORKFLOWS_TAB_ORGANIZATION_TYPES',
                    'Tip organizatii'
                  )}
                />
              </Tabs>
            </CardContent>
          </Card>
        </Box>
        {this.renderAddForm()}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflowOrganizationTypes}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>
      </Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({
    appState: state.app
  }),
  null
)(withSnackbar(WorkflowOrganizationTypes as any));
