import React, { Fragment } from 'react';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Box, Select, MenuItem } from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { WorkflowDocumentType } from '../../../interfaces/Workflow';
import { withSnackbar, ProviderContext } from 'notistack';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode } from '../../../helpers/Constants';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { ScaleLoader } from 'react-spinners';
import MaterialTable, { Column, EditComponentProps, Icons } from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { forwardRef } from 'react';
import { IReferential } from '../../../interfaces/IReferential';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { isNullOrUndefined, isBoolean } from 'util';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

export interface ExternalorkflowDocumentTypesProps {
  workflowFormId: number;
}

interface IWorkflowDocumentTypesState {
  workflowDocumentTypes: WorkflowDocumentType[];
  isLoading: boolean;
  workflowId: number;
  documentTypeList: IReferential[];
  booleanList: {
    id: number;
    displayName: string;
  }[];
}

type WorkflowDocumentTypesProps = { appState: AppState } & ExternalorkflowDocumentTypesProps &
  ProviderContext &
  RouteComponentProps<{ id: string; workflowId: string }>;

class WorkflowDocumentTypes extends React.PureComponent<
  WorkflowDocumentTypesProps,
  IWorkflowDocumentTypesState
> {
  private translatorService!: ITranslatorService;
  private workflowService!: IWorkflowService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    workflowDocumentTypes: [],
    isLoading: true,
    workflowId: 0,
    documentTypeList: [],
    booleanList: []
  } as IWorkflowDocumentTypesState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData();
      }
    );
  };

  loadData = async () => {
    const hoId = Number.parseInt(this.props.match.params.id);
    const workflowId = Number.parseInt(this.props.match.params.workflowId);
    if (isNaN(hoId) || isNaN(workflowId)) {
      return;
    }

    try {
      const ref = Referentials.referential.find(
        (item) => item.code === ReferentialCode.AttachmentType
      );
      const [data, documentTypeList] = await Promise.all([
        this.workflowService.GetWorkflowDocumentTypes(this.props.workflowFormId),
        this.appReferentialService.Get(ref!.baseUrl)
      ]);

      const booleanList = [
        {
          id: 1,
          displayName: this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_YES', 'Da')
        },
        {
          id: 0,
          displayName: this.translatorService.Tranlate('WORKFLOW_DOCUMENTS_TYPE_TAB_NO', 'NU')
        }
      ];

      data.forEach((wdt) => {
        const dt = documentTypeList.find((item) => item.id === wdt.documentTypeId);
        wdt.documentType = isNullOrUndefined(dt) ? null : dt;
      });

      this.setState({
        workflowDocumentTypes: data,
        isLoading: false,
        workflowId: workflowId,
        documentTypeList: documentTypeList, //.filter(item => item.isActive === true),
        booleanList: booleanList
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const lookupDocumentTypes = this.state.documentTypeList.reduce(function (
      acc: any,
      cur: any,
      i: number
    ) {
      acc[cur.id] = cur.displayNAme;

      return acc;
    },
    {});
    const lookupBoolean = this.state.booleanList.reduce(function (acc: any, cur: any, i: number) {
      acc[cur.id] = cur.displayName;

      return acc;
    }, {});

    return [
      {
        field: 'documentTypeId',
        title: this.translatorService.Tranlate(
          'WORKFLOW_DOCUMENTS_TYPE_TAB_DOCUMENT_TYPE_HEADER',
          'Tip Document'
        ),
        lookup: lookupDocumentTypes,
        sorting: false,
        render: (rowData: WorkflowDocumentType) => {
          return rowData.documentType!.displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          return (
            <Select
              fullWidth={true}
              value={isNullOrUndefined(props.value) ? '' : props.value}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.documentTypeList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'isMandatory',
        title: this.translatorService.Tranlate(
          'WORKFLOW_DOCUMENTS_TYPE_TAB_MANDATORY_HEADER',
          'Obligatoriu'
        ),
        lookup: lookupBoolean,
        sorting: false,
        render: (rowData: WorkflowDocumentType) => {
          return rowData.isMandatory
            ? this.state.booleanList[0].displayName
            : this.state.booleanList[1].displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          let value = 0;

          if (isBoolean(props.value)) {
            if (props.value) {
              value = this.state.booleanList[0].id;
            } else {
              value = this.state.booleanList[1].id;
            }
          } else {
            value = props.value;
          }

          return (
            <Select
              fullWidth={true}
              value={isNullOrUndefined(value) ? '' : value}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.booleanList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'isMultiple',
        title: this.translatorService.Tranlate(
          'WORKFLOW_DOCUMENTS_TYPE_TAB_MULTPLE_HEADER',
          'Multiple'
        ),
        lookup: lookupBoolean,
        sorting: false,
        render: (rowData: WorkflowDocumentType) => {
          return rowData.isMultiple
            ? this.state.booleanList[0].displayName
            : this.state.booleanList[1].displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          let value = 0;

          if (isBoolean(props.value)) {
            if (props.value) {
              value = this.state.booleanList[0].id;
            } else {
              value = this.state.booleanList[1].id;
            }
          } else {
            value = props.value;
          }

          return (
            <Select
              fullWidth={true}
              value={isNullOrUndefined(value) ? '' : value}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.booleanList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'isSelectionGroup',
        title: this.translatorService.Tranlate(
          'WORKFLOW_DOCUMENTS_TYPE_TAB_SELECTION_HEADER',
          'Selection'
        ),
        lookup: lookupBoolean,
        sorting: false,
        render: (rowData: WorkflowDocumentType) => {
          return rowData.isSelectionGroup
            ? this.state.booleanList[0].displayName
            : this.state.booleanList[1].displayName;
        },
        editComponent: (props: EditComponentProps<any>) => {
          let value = 0;

          if (isBoolean(props.value)) {
            if (props.value) {
              value = this.state.booleanList[0].id;
            } else {
              value = this.state.booleanList[1].id;
            }
          } else {
            value = props.value;
          }

          return (
            <Select
              fullWidth={true}
              value={isNullOrUndefined(value) ? '' : value}
              onChange={(e) => props.onChange(e.target.value)}
            >
              {this.state.booleanList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } as Column<any>,
      {
        field: 'dateModification',
        title: this.translatorService.Tranlate(
          'WORKFLOW_DOCUMENTS_TYPE_TAB_MODIFIED_DATE_HEADER',
          'Data modificare'
        ),
        render: (rowData: any) => {
          return moment
            .utc(rowData.dateModification)
            .local()
            .format(this.props.appState.longDateFormat);
        },
        editComponent: (props: EditComponentProps<any>) => {
          return <Label>{moment(new Date()).format(this.props.appState.longDateFormat)}</Label>;
        }
      } as Column<any>
    ];
  };

  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        deleteTooltip: this.translatorService.Tranlate('DELETE', 'Sterge'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza'),
          deleteText: this.translatorService.Tranlate(
            'DELETE_TEXT',
            'Sigur doriti sa stergeti aceasta inregistrare?'
          )
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };

  onRowUpdate = (newData: WorkflowDocumentType, oldData?: WorkflowDocumentType): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          if (isNullOrUndefined(newData.documentTypeId) || isNullOrUndefined(newData.isMandatory)) {
            this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
              variant: 'error'
            });
            reject();
            return;
          }

          if (
            this.state.workflowDocumentTypes.findIndex(
              (item) => item.documentTypeId === newData.documentTypeId && item.id !== newData.id
            ) !== -1
          ) {
            this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'ERROR_MSG_DOCUMENT_TYPE_EXISTS',
                'Tipul de document exista deja!'
              ),
              { variant: 'error' }
            );
            reject();
            return;
          }

          await this.workflowService.UpdateWorkflowDocumentType(newData);
          await this.loadData();
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowDelete = (oldData: WorkflowDocumentType): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          await this.workflowService.RemoveWorkflowDocumentType(oldData.id);
          await this.loadData();

          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });

          resolve();
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  onRowAdd = (newData: WorkflowDocumentType): Promise<void> =>
    new Promise(async (resolve, reject) => {
      try {
        if (isNullOrUndefined(newData.documentTypeId) || isNullOrUndefined(newData.isMandatory)) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
          return;
        }

        if (
          this.state.workflowDocumentTypes.findIndex(
            (item) => item.documentTypeId === newData.documentTypeId
          ) !== -1
        ) {
          this.props.enqueueSnackbar(
            this.translatorService.Tranlate(
              'ERROR_MSG_DOCUMENT_TYPE_EXISTS',
              'Tipul de document exista deja!'
            ),
            { variant: 'error' }
          );
          reject();
          return;
        }

        newData.workflowSectionStepFormId = this.props.workflowFormId;

        await this.workflowService.AddWorkflowDocumentType(newData);
        await this.loadData();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });

  public renderTable = () => {
    return (
      <MaterialTable
        icons={tableIcons}
        columns={this.getColumns()}
        data={this.state.workflowDocumentTypes}
        title=" "
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          search: false
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: any) => this.onRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData),
          onRowDelete: (oldData: any) => this.onRowDelete(oldData)
        }}
      />
    );
  };
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Fragment>
        {/* <Box>
                    <Card>
                        <CardContent>
                            <Tabs
                                value={0}
                                indicatorColor="primary"
                                textColor="secondary"
                                variant="standard"
                                color="primary"
                                aria-label="disabled tabs example">
                                <Tab className="text-capitalize" label={this.translatorService.Tranlate("WORKFLOWS_TAB_DOCUMENT_TYPES", "Tipuri de documente")} />
                            </Tabs>
                        </CardContent>
                    </Card>
                </Box> */}
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? this.renderTable() : null}
        </Box>
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalorkflowDocumentTypesProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});
export default connect(
  (state: ApplicationState) => ({ appState: state.app }),
  { ...AppUserAdminActionCreators, ...AppActionCreators },
  mergeProps
)(withSnackbar(WorkflowDocumentTypes as any));
