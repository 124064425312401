import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, Typography } from '@material-ui/core';
import { getJobListRequest } from '../agenda/agenda.requests';
import { Job } from '../agenda/agenda.types';
import { useAppSelector } from '../../store/configureStore';
import { ITranslation } from '../../interfaces/IReferential';
import { Option } from '../../types/Agenda';
import { TranslatorService } from '../../services/TranslatorService';


interface JobsMultipleSelectionProps {
  selectedValues: Option[];
  onChange?: (selectedValues: Option[]) => void;
  disabled?: boolean,
  error?:boolean
}

const JobsMultipleSelection: React.FC<JobsMultipleSelectionProps> = ({
  selectedValues,
  onChange,
  disabled,
  error
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const language = useAppSelector((state)=>state.app?.language)
  const [selected, setSelected] = useState<Option[]>(selectedValues || []);
  const translatorService = new TranslatorService();

  const fetchJobOptions = async () => {
    const response = await getJobListRequest();
    const responseData = response.data as Job[];

    const jobOptions: Option[] = responseData.map((job) => ({
      translations: job.translations,
      name: job.name,
      id: job.id,
      
    }));
    setOptions(jobOptions);
  };

  const getTranslations = (status: any) => {
    const indexLang = status.translations.findIndex((trans: any) => trans.language === language);
    return indexLang !== -1 ? status.translations[indexLang].name : status.name;
  }

  useEffect(() => {
    fetchJobOptions();
  }, []);

  const handleChange = (value: Option) => {
    if(!onChange){
      return;
    }
    const isSelected = selected.some((selectedOption) => selectedOption.id === value.id || selectedOption.jobTypeId === value.id);

    if (isSelected) {
      const newSelected = selected.filter((selectedOption) => selectedOption.id !== value.id || selectedOption.jobTypeId === value.id);
      setSelected(newSelected);
      onChange(newSelected);
    } else {
      const newSelected = [...selected, value];
      setSelected(newSelected);
      onChange(newSelected);
    }
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" style={{ marginBottom: '10px', color: error ? '#f83245' : 'black' }}>
        {translatorService.Tranlate('APPOINTMENT_JOB_TYPE_TITLE', 'Job type')}
      </Typography>
      <FormGroup>
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            minWidth: '350px',
            maxWidth: '500px',
            gap: '5px',
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              style={{ minWidth: '140px', }}
              key={option.id}
              disabled={disabled}
              control={
                <Checkbox
                  checked={selected.some((selectedOption) => selectedOption.id === option.id || selectedOption.jobTypeId === option.id)}
                  onChange={() => handleChange(option)}
                  value={option.id}
                />
              }
              label={getTranslations(option)}
            />
          ))}
        </Box>
      </FormGroup>
    </Box>
  );
};

export default JobsMultipleSelection;
