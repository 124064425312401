import React, { Fragment, useRef, useState, useContext } from 'react';

import { ClickAwayListener, InputBase, Popper, Paper } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import { ApplicationContext } from '../../context/Contexts';
import SearchCase from '../../components/cases/SearchCase';
import SearchOrder from '../../components/orders/SearchOrder';
import { Guid } from 'guid-typescript';

const WAIT_INTERVAL = 750;

const HeaderSearch = () => {
  const searchRef = useRef(null);

  const handleKeyDown = (e) => {
    clearTimeout(timer);
  };

  const triggerChange = (e) => {
    setSearchId(Guid.create().toString());
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);

    clearTimeout(timer);
    setTimer(setTimeout(triggerChange, WAIT_INTERVAL));

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchId, setSearchId] = useState('');
  let [timer, setTimer] = useState(null);
  const context = useContext(ApplicationContext);
  const translatorService = context.translatorService;

  return (
    <Fragment>
      <div className="app-search-wrapper" ref={searchRef}>
        <SearchIcon className="app-search-icon" />
        <InputBase
          placeholder={translatorService.Tranlate(
            'SEARCH_FILE',
            'Cauta dupa id dosar, vin sau numar inmatriculare...'
          )}
          classes={{
            root: 'app-search-icon',
            input: 'app-search-input'
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          value={searchValue}
          inputMode="search"
        />
      </div>
     {window.location.href.indexOf('orders') == -1 ?  <Popper
        anchorEl={searchRef.current}
        className="app-search-popper"
        open={openSearchPopover}
        transition
      >
        <ClickAwayListener onClickAway={handleSearchPopverClose}>
          <Paper className="app-search-popper-content" elevation={6}>
            <div className="dropdown-menu-xxxl">
              <SearchCase
                handleSearchPopverClose={handleSearchPopverClose}
                searchValue={searchValue}
                searchId={searchId}
              ></SearchCase>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper> :  <Popper
        anchorEl={searchRef.current}
        className="app-search-popper"
        open={openSearchPopover}
        transition
      >
        <ClickAwayListener onClickAway={handleSearchPopverClose}>
          <Paper className="app-search-popper-content" elevation={6}>
            <div className="dropdown-menu-xxxl">
              <SearchOrder
                handleSearchPopverClose={handleSearchPopverClose}
                searchValue={searchValue}
                searchId={searchId}
              ></SearchOrder>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>}
     
    </Fragment>
  );
};

export default HeaderSearch;
