import { isNil } from 'lodash';
import React, { useContext, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApplicationContext } from '../../../context/Contexts';
import { DashboardItem } from '../../../interfaces/Dashboard/DashboardItem';

interface Props {
  title: string;
  items: DashboardItem[];
  handleDonutClick(dataIndex?: number): void;
}

const AcquistionDonutChart: React.FC<Props> = ({ title, items, handleDonutClick }) => {
  const context = useContext(ApplicationContext);

  const series = useMemo(() => {
    return items.map((i) => i.value);
  }, [items]);

  const translateDisplayName = (displayName: string) : string => {
    if (displayName == 'PJ') {
      return context.translatorService.Tranlate(
        'PJ_LABEL',
        'PJ'
      );
    }
    if (displayName == 'PF') {
      return context.translatorService.Tranlate(
        'PF_LABEL',
        'PF'
      );
    }

    if (displayName == 'Achizitie Renault Selection') {
      return context.translatorService.Tranlate(
        'ACQUISITION_OF_RENAULT_SELECTION',
        'Achizitie Renault Selection'
      );
    }

    if (displayName == 'Achizitie Libera') {
      return context.translatorService.Tranlate(
        'ACQUISITION_NON_RENAULT_SELECTION',
        'Achizitie Libera'
      );
    }

    return displayName;
  }
  const options = useMemo(() => {
    const labels = items.map((i) => translateDisplayName(i.displayName));
    return {
      chart: {
        type: 'donut',
        events: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            if (!isNil(config.dataPointIndex)) {
              handleDonutClick(config.dataPointIndex);
            }
          }
        }
      },
      labels,
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };
  }, [handleDonutClick, items, , 
    context.translatorService.Tranlate(
    'PJ_LABEL',
    'PJ'
    ),
    context.translatorService.Tranlate(
      'PF_LABEL',
      'PF'
    ),
    context.translatorService.Tranlate(
      'ACQUISITION_OF_RENAULT_SELECTION',
      'Achizitie Renault Selection'
    ),
    context.translatorService.Tranlate(
      'ACQUISITION_NON_RENAULT_SELECTION',
      'Achizitie Libera'
    )]);

  return (
    <div className="pb-2">
      <h6 className="mt-3 ml-3 mb-0">{title}</h6>
      <ReactApexChart options={options} series={series} type="donut" />
    </div>
  );
};

export default AcquistionDonutChart;
