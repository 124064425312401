import { ReportParameter } from '../../../interfaces/Reports';

export enum ControlType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean',
  PERSONAL_DATA = 'personal_data',
  ORIGINAL_COPY = 'original_copy'
}

export interface ReportParameterExtended extends ReportParameter {
  label: string;
  type: ControlType;
  onlyForDisplay?: boolean;
  onlyForDisplayEditable?: boolean;
  notMandatory?: boolean;
}

export interface ReportParametersGroup {
  name: string;
  label: string;
  parameters: ReportParameterExtended[];
}
export interface State {
  header: ReportParametersGroup;
  seller: ReportParametersGroup;
  buyer: ReportParametersGroup;
  vehicle: ReportParametersGroup;
  price: ReportParametersGroup;
  others: ReportParametersGroup;
  footer: ReportParametersGroup;
}

export const initialStateValue: State = {
  header: {
    name: 'header',
    label: 'Antet',
    parameters: [
      {
        label: 'Organ fiscal',
        name: 'A_Name',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'CIF/Cod SIRUTA',
        name: 'A_CIF_SIRUTA',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Adresa',
        name: 'A_Address',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Tel/fax/e-mail',
        name: 'A_Tel_Fax_Email',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'REMTII Nr.',
        name: 'A_REMTII_Number',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'REMTII Data',
        name: 'A_REMTII_Date',
        value: '',
        type: ControlType.DATE,
        notMandatory: true
      },
      {
        label: 'REMTII Rol nr.',
        name: 'A_REMTII_Role_Number',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Original/copie',
        name: 'A_REMTII_Is_Original',
        value: '',
        type: ControlType.ORIGINAL_COPY,
        notMandatory: true
      },
      {
        label: 'Organ fiscal',
        name: 'B_Name',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Functia',
        name: 'B_Position',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Nume',
        name: 'B_FirstName',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Prenume',
        name: 'B_LastName',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      }
    ]
  },
  seller: {
    name: 'seller',
    label: 'Vanzator',
    parameters: [
      {
        label: 'Tip vanzator',
        name: 'Seller_Type',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Nume/Nume companie',
        name: 'Seller_Name_Company',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Domiciliu/Sediu',
        name: 'Seller_Address',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Date personale',
        name: 'Seller_ID',
        value: '',
        type: ControlType.PERSONAL_DATA
      },
      {
        label: 'CNP/CIF',
        name: 'Seller_PIN_CIF',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Tel/Fax',
        name: 'Seller_Tel_Fax',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Email',
        name: 'Seller_Email',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Domiciliu fiscal',
        name: 'Seller_Fiscal_Domicile',
        value: '',
        type: ControlType.STRING
      },
      {
        label: 'Nume reprezentant',
        name: 'Seller_Rep_Name',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Date personale reprezentat',
        name: 'Seller_Rep_ID',
        value: '',
        type: ControlType.PERSONAL_DATA,
        notMandatory: true
      },
      {
        label: 'CIF reprezentant',
        name: 'Seller_Rep_CIF',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Tel/Fax reprezentant',
        name: 'Seller_Rep_Tel_Fax',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Email reprezentant',
        name: 'Seller_Rep_Email',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      }
    ]
  },
  buyer: {
    name: 'buyer',
    label: 'Cumparator',
    parameters: [
      {
        label: 'Tip cumparator',
        name: 'Buyer_Type',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Nume/Nume companie',
        name: 'Buyer_Name_Company',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Domiciliu/Sediu',
        name: 'Buyer_Address',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Date personale',
        name: 'Buyer_ID',
        value: '',
        type: ControlType.PERSONAL_DATA
      },
      {
        label: 'CNP/CIF',
        name: 'Buyer_PIN_CIF',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Tel/Fax',
        name: 'Buyer_Tel_Fax',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Email',
        name: 'Buyer_Email',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Domiciliu fiscal',
        name: 'Buyer_Fiscal_Domicile',
        value: '',
        type: ControlType.STRING
      },
      {
        label: 'Nume reprezentant',
        name: 'Buyer_Rep_Name',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Date personale reprezentat',
        name: 'Buyer_Rep_ID',
        value: '',
        type: ControlType.PERSONAL_DATA,
        notMandatory: true
      },
      {
        label: 'CIF reprezentant',
        name: 'Buyer_Rep_CIF',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Tel/Fax reprezentant',
        name: 'Buyer_Rep_Tel_Fax',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Email reprezentant',
        name: 'Buyer_Rep_Email',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      }
    ]
  },
  vehicle: {
    name: 'vehicle',
    label: 'Vehicul',
    parameters: [
      {
        label: 'Marca',
        name: 'Vehicle_Brand',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Model',
        name: 'Vehicle_Model',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'VIN',
        name: 'Vehicle_VIN',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Serie Motor',
        name: 'Engine_Serial_Number',
        value: '',
        type: ControlType.STRING
      },
      {
        label: 'Capacitate cilindrica',
        name: 'Engine_Size',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Greutate maxima',
        name: 'Maximum_Weight',
        value: '',
        type: ControlType.NUMBER
      },
      {
        label: 'Numar inmatriculare',
        name: 'Plate_Number',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Data expirare revizie',
        name: 'Next_PTI_Date',
        value: '',
        type: ControlType.DATE
      },
      {
        label: 'Numar CIV',
        name: 'ID_CIV_Number',
        value: '',
        type: ControlType.STRING
      },
      {
        label: 'An fabricatie',
        name: 'Make_Year',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Norma poluare',
        name: 'Pollution_Norm',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Data dobandirii',
        name: 'Purchase_Date',
        value: '',
        type: ControlType.DATE
      },
      {
        label: 'Numar act dobandire',
        name: 'Purchase_Doc_Number',
        value: '',
        type: ControlType.STRING
      },
      {
        label: 'Data act dobandire',
        name: 'Purchase_Doc_Date',
        value: '',
        type: ControlType.DATE
      }
    ]
  },
  price: {
    name: 'price',
    label: 'Pret',
    parameters: [
      {
        label: 'Pret in RON',
        name: 'Price_Ron',
        value: '',
        type: ControlType.STRING,
        onlyForDisplay: true
      },
      {
        label: 'Pret in litere',
        name: 'Price_Ron_Words',
        value: '',
        type: ControlType.STRING
      }
    ]
  },
  others: {
    name: 'others',
    label: 'Altele',
    parameters: [
      {
        label: 'Anexe',
        name: 'Has_Appendix',
        value: '',
        type: ControlType.BOOLEAN
      },
      {
        label: 'Data contract',
        name: 'Signing_Date',
        value: '',
        type: ControlType.DATE
      },
      {
        label: 'Loc contract',
        name: 'Signing_Location',
        value: '',
        type: ControlType.STRING
      }
    ]
  },
  footer: {
    name: 'footer',
    label: 'Subsol',
    parameters: [
      {
        label: 'Organ fiscal',
        name: 'C_Name',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'CIF/Cod SIRUTA',
        name: 'C_CIF_SIRUTA',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Adresa',
        name: 'C_Address',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Tel/fax/e-mail',
        name: 'C_Tel_Fax_Email',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'REMTII Nr.',
        name: 'C_REMTII_Number',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'REMTII Data',
        name: 'C_REMTII_Date',
        value: '',
        type: ControlType.DATE,
        notMandatory: true
      },
      {
        label: 'Functia',
        name: 'D_Position',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Nume',
        name: 'D_FirstName',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      },
      {
        label: 'Prenume',
        name: 'D_LastName',
        value: '',
        type: ControlType.STRING,
        notMandatory: true
      }
    ]
  }
};
