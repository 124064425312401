import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import {
  Vehicle,
  VehicleStatusHistory,
  VehicleStatusHistoryExtended
} from '../../interfaces/Vehicle';
import * as R from 'ramda';
import { VehicleUpdateHistory } from '../../interfaces/VehicleUpdateHistory';

interface IVehicleHistoryTimelineState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  isUserHidden: boolean;
  vehicleStatusHistory: VehicleStatusHistoryExtended[];
}

export interface ExternalVehicleHistoryTimelineProps {
  workflowForm: WorkflowSectionStepForm;
  vehicleDetails: Vehicle;
}

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

type IVehicleHistoryTimelineProps = ExternalVehicleHistoryTimelineProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
  vehicleState: VehicleState;
} & AppState &
  typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class VehicleHistoryTimeline extends React.PureComponent<
  IVehicleHistoryTimelineProps,
  IVehicleHistoryTimelineState
> {
  private vehicleService!: IVehicleService;
  private appUserService!: IAppUserService;

  static contextType = ApplicationContext;

  state = {
    isLoading: true,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    vehicleStatusHistory: []
  } as IVehicleHistoryTimelineState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadVehicleHistoryTimeline();
      }
    );
  }

  loadVehicleHistoryTimeline = async () => {
    const vehicleId = this.props.vehicleState.vehicle!.id;
    const [vehicleStatusHistory, vehicleFieldsUpdateHistory] = await Promise.all([
      this.vehicleService.GetVehicleStatuses(vehicleId),
      this.vehicleService.GetVehicleFieldsHistory(vehicleId)
    ]);

    const vehicleStatusHistoryDetailedList: VehicleStatusHistoryExtended[] = vehicleStatusHistory;

    const [users] = await Promise.all([
      this.appUserService.GetUsersInfo(
        Array.from(
          new Set([
            ...vehicleStatusHistory.map((item) => item.userId),
            ...vehicleFieldsUpdateHistory.map((item) => item.userId)
          ])
        )
      )
    ]);

    vehicleStatusHistoryDetailedList.forEach((cd) => {
      const vehicleUpdateHistory = vehicleFieldsUpdateHistory.filter(
        (item) => item.vehicleStatusHistoryId === cd.id
      );
      if (vehicleUpdateHistory.length) {
        vehicleUpdateHistory.forEach((item) => {
          item.user = users.find((u) => u.id === item.userId);
        });
      }
      const createdBy = users.find((item) => item.id === cd.userId);
      cd.user = R.isNil(createdBy) ? null : createdBy;
      cd.vehicleUpdateHistory = vehicleUpdateHistory || undefined;
    });

    this.setState({
      isLoading: false,
      vehicleStatusHistory: vehicleStatusHistoryDetailedList
    });
  };

  showVehicleStatusHistoryTimeline = () => {
    return this.state.vehicleStatusHistory.map((status, index) => (
      <div key={index} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon " />
          <p className="timeline-item--content font-weight-bold">{status.status!.displayName}</p>
          <p className="timeline-item--label mb-2 font-weight-bold">{status.comment}</p>
          <p>
            {moment.utc(status.date).local().format(this.props.appState.longDateFormat)}{' '}
            {' (' + status.user!.email + ')'}
          </p>
          {status.vehicleUpdateHistory ? (
            <div className="ml-4 p-4">
              <div className="timeline-list  timeline-list--dark">
                {this.renderFieldsUpdateHistory(status.vehicleUpdateHistory)}
              </div>
            </div>
          ) : undefined}
        </div>
      </div>
    ));
  };

  renderFieldsUpdateHistory(vehicleUpdateHistory: VehicleUpdateHistory[]) {
    return vehicleUpdateHistory.map((vehicleUpdate) => {
      return vehicleUpdate.vehicleUpdateHistoryFields.map((item, index) => (
        <div key={index} className="timeline-item">
          <div className="timeline-item--content">
            <div className="timeline-item--icon " />
            <p className="timeline-item--content font-weight-bold">
              {moment.utc(vehicleUpdate.date).local().format(this.props.appState.longDateFormat) +
                ' (' +
                vehicleUpdate.user?.userName +
                ')'}{' '}
            </p>
            <p className="timeline-item--label">
              <span className="font-weight-bold">{item.vehicleFieldNameLocalized}:</span>&nbsp;
            </p>
            {item.oldValueLocalized && item.oldValueLocalized.length < 60 ? (
              <div>
                <span style={{ textDecoration: 'line-through' }}>{item.oldValueLocalized}</span>
                &nbsp;{'->'}&nbsp;
                <span className="font-weight-bold">{item.newValueLocalized}</span>
              </div>
            ) : (
              <div>
                <div>
                  <span style={{ textDecoration: 'line-through' }}>{item.oldValueLocalized}</span>
                </div>
                <div>&nbsp;{'->'}&nbsp;</div>
                <div>
                  <span className="font-weight-bold">{item.newValueLocalized}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ));
    });
  }

  public render() {
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appUserService = (this.context as AppContext).appUserService;

    return (
      <div>
        <div className="ml-4 mt-4 timeline-container timeline-list timeline-list--dark text-left">
          {this.showVehicleStatusHistoryTimeline()}
        </div>
      </div>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalVehicleHistoryTimelineProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicelState: state.vehicle
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(VehicleHistoryTimeline as any));
