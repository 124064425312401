import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography, Tabs, Tab, Card } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const LivePreviewExample = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Card className="card-box mb-4">
            <div className="card-header py-0 pr-0 pl-4">
              <div className="card-header--actions">
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  onChange={handleChange}
                >
                  <Tab className="text-capitalize p-4" label="Week" />
                  <Tab className="text-capitalize p-4" label="Month" />
                </Tabs>
              </div>
            </div>
            <TabPanel value={value} index={0}></TabPanel>
            <TabPanel value={value} index={1}>
              sasa
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TabPanel;
