import React, { useCallback, useContext, useMemo } from 'react';

import MaterialTable from '@material-table/core';
import { DashboardItem } from '../../../interfaces/Dashboard/DashboardItem';
import { getLocalization, MaterialTableIcons } from '../../_shared/TableSettings';
import { ApplicationContext } from '../../../context/Contexts';
import { Paper } from '@material-ui/core';
import sumBy from 'lodash/sumBy';

interface Props {
  byInventoryAge: DashboardItem[];
  withMissingInfo: number;
  items: DashboardItem[];
  statusId?: number;
  setStatusId(statusId?: number): void;
}

const VehicleStockTable: React.FC<Props> = ({
  items,
  statusId,
  setStatusId,
  byInventoryAge,
  withMissingInfo
}) => {
  const context = useContext(ApplicationContext);

  const vehicleCount = context.translatorService.Tranlate(
    'DASHBOARD_VEHICLE_COUNT',
    'Numar vehicule'
  );
  const dashboardState = context.translatorService.Tranlate('DASHBOARD_STATE', 'Status');
  const lackingInformation = context.translatorService.Tranlate(
    'DASHBOARD_LACKING_INFORMATION',
    'Cu informatii lipsa'
  );
  const moreThan90Days = context.translatorService.Tranlate(
    'DASHBOARD_INVENTORY_MORE_THAN_90_DAYS',
    'In stoc de peste 90 zile'
  );

  const columns = useMemo(() => {
    return [
      {
        title: vehicleCount,
        field: 'value'
      },
      {
        title: dashboardState,
        field: 'displayName'
      }
    ];
  }, [dashboardState, vehicleCount]);

  const data = useMemo(() => {
    const nrOfVehiclesOlderThan90Days = sumBy(
      byInventoryAge.filter(({ id }) => id >= 4),
      'value'
    );
    return [
      {
        id: 99,
        displayName: lackingInformation,
        value: withMissingInfo
      },
      {
        id: 98,
        displayName: moreThan90Days,
        value: nrOfVehiclesOlderThan90Days
      },
      ...items
    ];
  }, [byInventoryAge, items, lackingInformation, moreThan90Days, withMissingInfo]);

  const handleBarClick = useCallback(
    (rowId?: number) => {
      if (rowId === 99 || rowId === 98) {
        return;
      }

      setStatusId(rowId);
    },
    [setStatusId]
  );

  const getRowBackgroundColor = useCallback(
    (rowId: number) => {
      if (rowId == 98 || rowId === 99) {
        return '#EBECF0';
      }

      return statusId === rowId ? '#267DC3' : '#FFF';
    },
    [statusId]
  );

  const getRowColor = useCallback(
    (rowId: number) => {
      if (rowId == 98 || rowId === 99) {
        return '#000';
      }

      return statusId === rowId ? '#FFF' : '#000';
    },
    [statusId]
  );

  return (
    <div style={{ maxWidth: '100%', height: '100%' }}>
      <MaterialTable
        icons={MaterialTableIcons}
        localization={getLocalization(context.translatorService)}
        columns={columns}
        data={data}
        title={context.translatorService.Tranlate('DASHBOARD_INVENTORY_DETAILS', 'Detalii stoc')}
        options={{
          search: false,
          rowStyle: (rowData) => ({
            backgroundColor: getRowBackgroundColor(rowData.id),
            color: getRowColor(rowData.id)
          })
        }}
        components={{
          Container: (props) => <Paper {...props} className="h-100" />,
          Pagination: () => null
        }}
        onRowClick={(evt, selectedRow) => handleBarClick(selectedRow?.id)}
      />
    </div>
  );
};

export default VehicleStockTable;
