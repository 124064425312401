import { WeatherForecastsReducer, WeatherForecastsState } from './reducers/WeatherForecasts';
import { AppReducer, AppState } from './reducers/App';
import { ThemeState, ThemeOptionsReducer } from './reducers/ThemeOptions';
import { AppUserAdminState, AppUserAdminReducer } from './reducers/AppUserAdmin';
import { CaseSettingsState, AppCaseSettingsReducer } from './reducers/CaseSettings';
import { VehicleState, VehicleReducer } from './reducers/Vehicle';
import { MavoVehicleReducer } from './reducers/MavoVehicle';
import { VehicleAuctionReducer, VehicleAuctionState } from './reducers/VehicleAuction';
import { AgendaReducer } from './reducers/Agenda';

export * from './reducers/WeatherForecasts';
export * from './actions/WeatherForecasts';
export * from './reducers/App';
export * from './actions/App';
export * from './reducers/ThemeOptions';
export * from './actions/ThemeOptions';
export * from './reducers/AppUserAdmin';
export * from './actions/AppUserAdmin';
export * from './reducers/CaseSettings';
export * from './actions/CaseSettings';
export * from './reducers/Vehicle';
export * from './actions/Vehicle';

// state object
export interface ApplicationState {
  weatherForecasts: WeatherForecastsState | undefined;
  app: AppState | undefined;
  themeOptions: ThemeState | undefined;
  appUserAdmin: AppUserAdminState | undefined;
  caseSettings: CaseSettingsState | undefined;
  vehicle: VehicleState | undefined;
  mavoVehicle: VehicleState | undefined;
  mavoVehicleAuction: VehicleAuctionState | undefined;
}

// reducers
export const reducers = {
  weatherForecasts: WeatherForecastsReducer,
  app: AppReducer,
  themeOptions: ThemeOptionsReducer,
  appUserAdmin: AppUserAdminReducer,
  caseSettings: AppCaseSettingsReducer,
  vehicle: VehicleReducer,
  mavoVehicle: MavoVehicleReducer,
  mavoVehicleAuction: VehicleAuctionReducer,
  agenda: AgendaReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState, context: any): void;
}
